import styled, { css } from "styled-components"
import { styleIfProp, BlurredStyle, Flex, _FlexContainer } from "../../styles/common"
import { _TextArea } from "../form/Input.styles"

export const _CommentWrapper = styled(Flex)<{ blurred?: boolean }>`
    word-break: break-all;
    ${({ blurred }) => (blurred ? BlurredStyle : null)}
    flex-shrink: 0;
`

export const _CommentInnerWrapper = styled(_FlexContainer).attrs(() => ({ grow: 1 }))``

export const _CommentNewWrapper = styled.span`
    color: #4ece3d;
`

export const _CommentAvatar = styled.div<{ editable?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    flex: 0 0 auto;
    width: 42px;
    height: 42px;
    border-radius: 21px;
    background-color: ${({ editable }) => (editable ? "#0093ee" : "#2c405a")};
`

export const _CommentActions = styled(Flex)``

export const _CommentInfo = styled(Flex)``

export const _CommentText = styled.span<{ tilted?: boolean }>`
    ${styleIfProp("tilted", "font-style: italic;")}
`

export const _CommentAuthor = styled.div`
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
`

export const _CommentDetails = styled(Flex)`
    height: 19px;
    font-size: 13px;
    letter-spacing: normal;
    color: #8dabc4;
    ${_CommentAuthor} {
        margin-left: 4px;
    }
`

type CommentButtonType = "Info" | "Warning"
const commentButtonColor: Record<CommentButtonType, string> = {
    Info: "#0093ee",
    Warning: "#ff151f"
}

export const _CommentButton = styled.button<{ severity: CommentButtonType }>`
    outline: none;
    cursor: pointer;
    border: none;
    background: none;
    color: ${({ severity }) => commentButtonColor[severity]};
    font-size: 13px;
`

export const commentsGrid = css`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 32px;
`

export const _RadarItemComments = styled.div`
    flex-grow: 1;
    overflow: hidden;

    ${commentsGrid}

    & ${_TextArea}${_TextArea} {
        border: solid 1px #8dabc4;
        min-height: 170px;
    }
`

export const _CommentFormActionButtons = styled(Flex).attrs(() => ({ justify: "flex-end" }))``

export const _CreateCommentContainer = styled(_FlexContainer)`
    ${_CommentFormActionButtons} {
        justify-content: flex-start;
    }
`

export const CommentsFormWrapper = styled(_FlexContainer)``

export const _CardCommentsHeader = styled.div`
    ${commentsGrid}
`
