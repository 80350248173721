import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { styleFromProp } from "../styles/common"

const placeHolder =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN89R8AAtkB6zy+wn8AAAAASUVORK5CYII="

const transparentPlaceholder =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="

const Image = styled.img`
    display: block;
    ${p => styleFromProp(p.width, `width: ${p.width}px;`)}
    ${p => styleFromProp(p.height, `height: ${p.height}px;`)}
    border: none;
    outline: none;
    max-width: 100%;

    @keyframes loaded {
        0% {
            opacity: 0.1;
        }
        100% {
            opacity: 1;
        }
    }
    &.loaded:not(.has-error) {
        animation: loaded 300ms ease-in-out;
    }
    &.has-error {
        content: url(${placeHolder});
    }
`

const onLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.classList.add("loaded")
}

const onError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.classList.add("has-error")
}

export const LazyImage = ({
    src,
    alt,
    withPlaceholder = true,
    ...rest
}: React.ImgHTMLAttributes<HTMLImageElement> & { withPlaceholder?: boolean }) => {
    const [imageSrc, setImageSrc] = useState(withPlaceholder ? placeHolder : transparentPlaceholder)
    const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null)

    useEffect(() => {
        let observer: IntersectionObserver
        let didCancel = false

        if (imageRef && imageSrc !== src && src) {
            if (IntersectionObserver) {
                observer = new IntersectionObserver(
                    entries => {
                        entries.forEach(entry => {
                            if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                                setImageSrc(src)
                                observer.unobserve(imageRef)
                            }
                        })
                    },
                    {
                        threshold: 0.01,
                        rootMargin: "75%"
                    }
                )
                observer.observe(imageRef)
            } else {
                setImageSrc(src)
            }
        }
        return () => {
            didCancel = true
            if (observer && observer.unobserve) {
                if (imageRef) observer.unobserve(imageRef)
            }
        }
    }, [src, imageSrc, imageRef])
    return <Image {...rest} ref={setImageRef} src={imageSrc} alt={alt} onLoad={onLoad} onError={onError} />
}
