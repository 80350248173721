import { asyncConnect } from "../../store/async/asyncConnect"
import React from "react"
import {
    _DashboardSummary,
    _DashboardSummaryItem,
    StyledDashboardSummaryNumber,
    _DashboardSummarySA,
    _DashboardSummaryFetchError,
    _DashboardSummaryTitle
} from "./DashboardSummary.styles"
import { assertNever } from "../../../functions/src/utils"
import { _TagFont } from "../../styles/typography"
import { Flex, _HorizontalSpace } from "../../styles/common"
import { Loader } from "../common/Loader"
import { isOk } from "../../../functions/src/utils/validators"
import { usePrevious } from "../../utils/hooks/usePrevious"
import { isEqual } from "../../utils"
import { getCopy } from "../../../functions/src/services/copy"
import { IconSvg } from "../IconSvg"

const DashboardSummaryItem: React.FC<{
    title: string
    subtitle?: string
    number: number | string
    loading?: boolean
}> = p => (
    <_DashboardSummaryItem data-cy={`${p.title}-summary-counter`}>
        {p.loading ? <Loader size="small" /> : <StyledDashboardSummaryNumber>{p.number}</StyledDashboardSummaryNumber>}
        <_HorizontalSpace base="15px" />
        <_TagFont>{p.title}</_TagFont>
    </_DashboardSummaryItem>
)

export type DashboardSummaryData = {
    sectorsCount: number | string
    startupsCount: number | string
    starredObjectsCount: number | string
}

const getDashboardSummaryData = (data: Async<Result<DashboardSummaryData>>): DashboardSummaryData => {
    const defaultValue = {
        sectorsCount: "-",
        startupsCount: "-",
        starredObjectsCount: "-"
    }
    switch (data.type) {
        case "Fetching":
        case "NotFetched":
        case "FetchError":
            return defaultValue
        case "Fetched":
            return isOk(data.value) ? data.value.value : defaultValue
    }
    assertNever(data)
}

export const DashboardSummary = asyncConnect()({
    data: ["dashboardSummary", "currentRadarConfig", "filters", "availableCollections"],
    fetchHandlers: {
        fetchSummary: {
            resolver: "dashboardSummary",
            getStateKey: () => "dashboardSummary"
        }
    }
})(p => {
    const counter = getDashboardSummaryData(p.dashboardSummary)
    const loading = p.dashboardSummary.type === "Fetching" // Wrong types with isFetching...
    const searchAreas = p.filters.searchArea || ""

    const prevFilters = usePrevious(p.filters)

    React.useEffect(() => {
        if (!isEqual(p.filters, prevFilters)) setTimeout(() => p.fetchSummary(p.filters), 500)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.filters])

    return (
        <Flex align="baseline">
            <_DashboardSummaryTitle>Dashboard Summary</_DashboardSummaryTitle>
            <_DashboardSummary>
                {p.availableCollections.includes("sectors") && (
                    <DashboardSummaryItem title="Sectors" loading={loading} number={counter.sectorsCount} />
                )}
                {p.availableCollections.includes("startups") && (
                    <DashboardSummaryItem title="Startups" loading={loading} number={counter.startupsCount} />
                )}
                {p.currentRadarConfig.withStars ? (
                    <DashboardSummaryItem
                        title="Prioritized leads"
                        loading={loading}
                        number={counter.starredObjectsCount}
                    />
                ) : null}
                {searchAreas && (
                    <_DashboardSummarySA>
                        <_TagFont>{getCopy("searchArea")}:</_TagFont>
                        <_HorizontalSpace base="5px" />
                        {searchAreas}
                    </_DashboardSummarySA>
                )}
                {p.dashboardSummary.type === "FetchError" && (
                    <_DashboardSummaryFetchError onClick={() => p.fetchSummary(p.filters)}>
                        <div title="Error while fetching data">
                            <IconSvg name="wrong" width={16} height={16} />
                        </div>
                        <_HorizontalSpace base="8px" />
                        Click to retry.
                    </_DashboardSummaryFetchError>
                )}
            </_DashboardSummary>
        </Flex>
    )
})
