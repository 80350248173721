import { Firestore } from "../../firebase"
import { validateUser, validateClaim } from "../../../models/User"
import { toArray, toMap, identity } from "../../../utils/map"
import { Logger } from "../../logging"
import { isErr } from "../../../utils/validators"

const { error } = Logger("PREPARE_USERS::")

export const prepareUsers = (firestore: Firestore, radarId: string) => async (
    claims: SMap<Claim>
): Promise<SMap<RadarUser>> => {
    const usersResult = await Promise.all<RadarUser | null>(
        toArray(claims, async (claim, uid) => {
            try {
                const user = await firestore.fetchDoc(firestore.ref("users", uid), validateUser)
                if (isErr(user)) return null
                const extUser: RadarUser = { ...user.value, ...claim, radarId }
                return extUser
            } catch (e) {
                error(e, uid)
                return null
            }
        })
    )
    return toMap(usersResult.filter(Boolean) as RadarUser[], v => v!.userId || v!.email, identity)
}

export const fetchRadarUsers = async (radarId: RadarId, firestore: Firestore): Promise<TMap<UserId, RadarUser>> => {
    const { valid: claims } = await firestore.fetchCol(firestore.ref("radarsClaims", radarId, "claims"), validateClaim)
    return prepareUsers(firestore, radarId)(claims)
}
