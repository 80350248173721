/* eslint-disable max-lines */
import * as React from "react"
import styled from "styled-components"
import { CheckboxDropdown, RadioDropdown, DropdownProps } from "../Dropdown"
import { toDateRange } from "../../models/dateTime"
import { isEmpty } from "../../../functions/src/utils/validators"
import { isFetched, Loaded, Loading, toOption, toStringOption } from "../../../functions/src/utils/types"
import { Filters, FilterType, isCollectionFilter, StarFilterType } from "../../../functions/src/models/filtering"
import { _Input } from "../form/Input.styles"
import { getAvailableCollections } from "../../../functions/src/models/collections"
import {
    getPipelineStageValues,
    getPriorityRankValues,
    getAssignmentDecoratorOptions,
    getUserOptionByUserId
} from "../../../functions/src/models/decoratorValues"
import { collections } from "../../../functions/src/models/schemas"
import { byNameAsc, byAreaOrder } from "../../../functions/src/models/searchAreas"
import {
    byCompareName,
    tagToOption,
    displaySegmentTag,
    getAreaKindFromTag,
    segmentTagSectionDisplayMap
} from "../../../functions/src/models/tags"
import { getCopy } from "../../../functions/src/services/copy"
import { assertNever } from "../../../functions/src/utils"
import { values, arrify, filterObject, keys } from "../../../functions/src/utils/map"
import { getDefaultSearchAreaFilter, getStarsFilterAll } from "../../models/collections"
import { getDataByKeys } from "../../store/data/dataSelectors"
import { RootState } from "../../store/store"
import { CollectionFieldsFilter } from "./controls/CollectionFieldsFilter"
import { _FlexContainer, _FlexRow, _VerticalSpace } from "../../styles/common"
import { _TagFont } from "../../styles/typography"
import { _FilterContainer } from "./Filter.styles"
import { RangeControlProps, RangeControl } from "./controls/RangeControl"
import { DatePicker, DatePickerProps } from "./controls/DatePicker"
import { SearchInputControl } from "./controls/SearchInputControl"
import { IconSvg } from "../IconSvg"

export const FiltersLabelMap: OMap<FilterType, string> = {
    date: "Creation date"
}

export type Props = FilterProps & FilterActions & { column?: boolean; label?: React.ReactNode }

type Hideable = { isHidden?: boolean }

export type CheckboxFilterProps = ValueState<
    "checkbox",
    Hideable &
        Pick<
            DropdownProps<string>,
            "options" | "selected" | "title" | "buttonText" | "searchable" | "sections" | "getSection"
        > & {
            buttonTextSelected: string
        }
>
type RadioFilterProps = ValueState<
    "radio",
    Hideable &
        Pick<DropdownProps<string>, "options" | "selected" | "title" | "searchable"> & {
            allOptionsText: string
        }
>

type DateFilterProps = ValueState<"dateRange", Hideable & Pick<DatePickerProps, "value">>
type InputFilterProps = ValueState<"input", Hideable & { value: string }>
type SearchFilterProps = ValueState<"search", Hideable & { value: string }>
type CollectionFieldsFilterProps = ValueState<"collection", Hideable & { value: CName & keyof Filters; isSet: boolean }>
// Could be improved by having flavor as a list of allowed values.
type RangeFilterProps = ValueState<
    "range",
    Hideable & {
        value: RangeControlProps["value"]
        min: number | undefined
        max: number | undefined
        flavor?: string // could use a type annotation here, but it's internal to RangeControl.
    }
>
export type FilterProps =
    | CheckboxFilterProps
    | RadioFilterProps
    | DateFilterProps
    | InputFilterProps
    | SearchFilterProps
    | CollectionFieldsFilterProps
    | RangeFilterProps

export const CheckboxFilterProps = (value: Value<CheckboxFilterProps>): FilterProps => ({ type: "checkbox", value })
export const RadioFilterProps = (value: Value<RadioFilterProps>): FilterProps => ({ type: "radio", value })
export const DateFilterProps = (value: Value<DateFilterProps>): FilterProps => ({ type: "dateRange", value })
export const SearchFilterProps = (value: Value<SearchFilterProps>): FilterProps => ({ type: "search", value })
export const InputFilterProps = (value: Value<InputFilterProps>): FilterProps => ({ type: "input", value })
export const RangeFilterProps = (value: Value<RangeFilterProps>): FilterProps => ({ type: "range", value })
export const CollectionFieldsFilterProps = (value: Value<CollectionFieldsFilterProps>): FilterProps => ({
    type: "collection",
    value
})

type ChangeEvent =
    | { type: Type<CheckboxFilterProps>; v: string }
    | { type: Type<RadioFilterProps>; v: string | null }
    | { type: Type<DateFilterProps>; v: Pick<Filters, "date"> }
    | { type: Type<InputFilterProps>; v: string }
    | { type: Type<SearchFilterProps>; v: string }
    | { type: Type<RangeFilterProps>; v: RangeControlProps["value"] | null }
    | { type: Type<CollectionFieldsFilterProps>; v: Filters[CName & keyof Filters] }

export type FilterActions = {
    onChange: F1<ChangeEvent>
    onClear: F1<ChangeEvent>
}

const getFilterComponent = (p: Props, setDirty: F1<boolean>) => {
    switch (p.type) {
        case "radio":
            if (p.value.options.length <= 1) return null
            const allOption = toOption(p.value.allOptionsText, null)
            return (
                <RadioDropdown
                    full
                    onSelect={v => {
                        setDirty(true)
                        p.onChange({ type: "radio", v: v.value as string })
                    }}
                    title={p.value.title}
                    options={[allOption, ...p.value.options]}
                    selected={isEmpty(p.value.selected) ? [allOption] : p.value.selected}
                />
            )

        case "checkbox":
            if (!p.value.options.length) return null
            return (
                <CheckboxDropdown
                    full
                    {...p.value}
                    onSelect={v => {
                        setDirty(true)
                        p.onChange({ type: "checkbox", v: v.value })
                    }}
                />
            )

        case "dateRange":
            return (
                <DatePicker
                    {...p}
                    onChange={v => {
                        setDirty(true)
                        p.onChange(v)
                    }}
                    value={p.value.value}
                />
            )

        case "input":
            return (
                <SearchInputControl
                    value={p.value.value}
                    onChange={v => {
                        setDirty(true)
                        p.onChange(v)
                    }}
                />
            )

        case "search":
            return (
                <SearchInputControl
                    search
                    value={p.value.value}
                    onChange={v => {
                        setDirty(true)
                        p.onChange(v)
                    }}
                />
            )

        case "collection":
            return <CollectionFieldsFilter onChange={p.onChange} onClear={p.onClear} collection={p.value.value} />

        case "range":
            return (
                <RangeControl
                    value={p.value.value}
                    min={p.value.min}
                    max={p.value.max}
                    onChange={v => {
                        setDirty(true)
                        p.onChange({ type: "range", v })
                    }}
                    flavor={p.value.flavor ?? "plain"}
                />
            )
    }
    assertNever(p)
}

const _RowComponent = styled.div`
    flex-grow: 1;
`

type RowIconProps = {
    visible: boolean
}

const _RowIcon = styled.a<RowIconProps>`
    flex-grow: 0;
    padding-top: 12px;
    padding-left: 12px;
    cursor: pointer;
    visibility: ${props => (props.visible ? "" : "hidden")};
`

const _Row = styled(_FlexRow)``

const isDirty = (p: Props) => {
    switch (p.type) {
        case "collection":
            // Collection filters aren't dirty by themselves
            return false
        case "radio":
        case "checkbox":
            return !isEmpty(p.value.selected)
        case "dateRange":
            return !isEmpty(p.value?.value?.[0]) || !isEmpty(p.value?.value?.[1])
        case "search":
        case "input":
            return !isEmpty(p.value.value)
        case "range":
            const knownBounds = !isEmpty(p.value.min) || !isEmpty(p.value.max)
            const v = p.value.value
            const hasValue = !(v[0] == Number.MIN_SAFE_INTEGER || v[1] == Number.MAX_SAFE_INTEGER)
            return knownBounds || hasValue
    }

    assertNever(p)
}

export const Filter = (p: Props) => {
    const [dirty, setDirty] = React.useState(false)

    // TODO adjust label display to horizontal FiltersBar
    const component = getFilterComponent(p, setDirty)

    const onClickClear = () => {
        setDirty(false)
        // NOTE: Using as any with p.type. TS does not know that we always match types for it
        p.onClear({ type: p.type as any, v: null })
    }

    React.useLayoutEffect(() => {
        setDirty(isDirty(p))
    }, [p])

    if (p.value.isHidden) return null

    return (
        <_FilterContainer data-cy="filter-container">
            {p.label ? (
                <>
                    <_TagFont>{p.label}</_TagFont>
                    <_VerticalSpace base="12px" />
                </>
            ) : null}
            <_Row>
                <_RowComponent>{component}</_RowComponent>
                {p.type !== "collection" && (
                    <_RowIcon visible={dirty}>
                        <IconSvg name="close" width={12} height={12} onClick={onClickClear} />
                    </_RowIcon>
                )}
            </_Row>
        </_FilterContainer>
    )
}

const mutationTypes: TMap<MutationType, string> = { create: "Created", delete: "Deleted", update: "Updated" }
const availableStarFilters: StarFilterType[] = ["Starred", "Not starred"]

export const getPropsForFilter = (state: RootState, radarId: string, cname?: CName) => (
    key: FilterType
): Loadable<FilterProps> => {
    const { filters } = state.ui
    const isSearchAreaSelected = filters.searchArea !== null

    if (isCollectionFilter(key))
        return Loaded(
            CollectionFieldsFilterProps({
                value: key,
                isSet: !!filters[key]
            })
        )

    switch (key) {
        case "pipelines": {
            const deps = getDataByKeys(["pipelines"], state.data[radarId])
            if (deps.loading) return Loading()
            const { pipelines: psv } = deps

            return Loaded(
                CheckboxFilterProps({
                    options: getPipelineStageValues(psv, cname).map(v => toStringOption(v.label)),
                    selected: filters.pipelines?.map(toStringOption) || [],
                    buttonTextSelected: "Pipelines",
                    buttonText: "All Pipelines",
                    title: "Filter by pipeline stage",
                    isHidden: !isSearchAreaSelected
                })
            )
        }

        case "priorities": {
            return Loaded(
                CheckboxFilterProps({
                    options: getPriorityRankValues().map(v => toStringOption(v.label)),
                    selected: filters.priorities?.map(toStringOption) || [],
                    title: "Filter by priority rank",
                    buttonTextSelected: "Priority Rank",
                    buttonText: "All Priorities",
                    isHidden: !isSearchAreaSelected
                })
            )
        }

        case "searchArea": {
            const deps = getDataByKeys(["searchAreas"], state.data[radarId])
            if (deps.loading) return Loading()
            const { searchAreas } = deps
            return Loaded(
                RadioFilterProps({
                    allOptionsText: getDefaultSearchAreaFilter(),
                    options: values(searchAreas || {})
                        .sort(byNameAsc)
                        .sort(byAreaOrder)
                        .map(s => toStringOption(s.name)),
                    selected: arrify(filters.searchArea).filter(Boolean).map(toStringOption),
                    title: getCopy("filterBySearchArea")
                })
            )
        }

        case "searchAreas": {
            const deps = getDataByKeys(["searchAreas"], state.data[radarId])
            if (deps.loading) return Loading()
            const { searchAreas } = deps
            return Loaded(
                CheckboxFilterProps({
                    options: values(searchAreas || {})
                        .sort(byNameAsc)
                        .sort(byAreaOrder)
                        .map(s => toStringOption(s.name)),
                    selected: arrify(filters.searchAreas).filter(Boolean).map(toStringOption),
                    title: getCopy("filterBySearchArea"),
                    buttonTextSelected: getCopy("searchAreas"),
                    buttonText: `All ${getCopy("searchAreas")}`
                })
            )
        }

        case "stars": {
            return Loaded(
                RadioFilterProps({
                    allOptionsText: getStarsFilterAll(),
                    options: availableStarFilters.map(toStringOption),
                    selected: arrify(filters.stars!).filter(Boolean).map(toStringOption),
                    title: "Filter by stars"
                })
            )
        }

        case "date": {
            return Loaded(
                DateFilterProps({
                    value: toDateRange({ from: filters.date?.from || null, to: filters.date?.to || null }) as any
                })
            )
        }

        case "tags": {
            const deps = getDataByKeys(["tags"], state.data[radarId])
            if (deps.loading) return Loading()
            return Loaded(
                CheckboxFilterProps({
                    options: values(deps.tags).sort(byCompareName).map(tagToOption(true)),
                    selected: filters.tags?.map(toStringOption) || [],
                    title: "Filter by Tags",
                    buttonText: "All Tags",
                    buttonTextSelected: "Tags",
                    searchable: true
                })
            )
        }

        case "segmentTags": {
            const deps = getDataByKeys(["segmentTags", "searchAreas"], state.data[radarId])
            if (deps.loading) return Loading()

            const areaTags = filterObject(deps.segmentTags, (_, st) =>
                filters.searchArea ? filters.searchArea.includes(deps.searchAreas[st.areaId]?.name) : true
            )

            const segmentTagsSectionMap: Record<string, SearchAreaKind> = values(areaTags).reduce(
                (acc, t) => ({
                    ...acc,
                    [displaySegmentTag(deps.searchAreas)(t)]: getAreaKindFromTag(deps.searchAreas)(t)
                }),
                {}
            )

            return Loaded(
                CheckboxFilterProps({
                    title: "Filter by Segment Tags",
                    buttonTextSelected: "Segment Tags",
                    buttonText: "All Segment Tags",
                    options: keys(segmentTagsSectionMap).map(t => toStringOption(t)) || [],
                    selected: filters.segmentTags?.map(toStringOption) || [],
                    sections: segmentTagSectionDisplayMap,
                    getSection: o => segmentTagsSectionMap[o.value],
                    searchable: true
                })
            )
        }

        case "collections": {
            const configs = state.auth.configs
            if (!isFetched(configs)) return Loading()
            const availableCollections = getAvailableCollections(configs.value[radarId])
            return Loaded(
                CheckboxFilterProps({
                    buttonTextSelected: "Object types",
                    buttonText: "Object type",
                    options: availableCollections.map(k => toOption(collections[k].displayName, k)),
                    selected:
                        filters.collections
                            ?.filter(k => availableCollections.includes(k as any))
                            .map((k: CName) => toOption(collections[k].displayName, k)) || []
                })
            )
        }
        case "mutationType": {
            return Loaded(
                CheckboxFilterProps({
                    options: keys(mutationTypes).map(k => toOption(mutationTypes[k], k as any)),
                    selected:
                        filters.mutationType?.map((k: MutationType) => toOption(mutationTypes[k], k as any)) || [],
                    buttonTextSelected: "Type",
                    buttonText: "Operation types",
                    title: "Filter by type"
                })
            )
        }
        case "assignments": {
            const deps = getDataByKeys(["users"], state.data[radarId])
            if (deps.loading) return Loading()
            const { users } = deps
            return Loaded(
                CheckboxFilterProps({
                    options: getAssignmentDecoratorOptions(users),
                    selected: filters.assignments?.map(getUserOptionByUserId(users)) || [],
                    buttonTextSelected: "Assigned",
                    buttonText: "Relationship manager",
                    title: "Filter by primary manager",
                    searchable: true
                })
            )
        }
    }
    assertNever(key)
}
