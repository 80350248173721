import * as React from "react"
import { getAssignmentDecoratorOptions, getUserOptionByUserId } from "../../../../functions/src/models/decoratorValues"
import { asyncWithDefault } from "../../../../functions/src/utils/types"
import { asyncConnect } from "../../../store/async/asyncConnect"
import { Flex } from "../../../styles/common"
import { RadioDropdown } from "../../Dropdown"
import { _AssignedUserCardSectionContainer } from "./AssignedUserCardSection.styles"
import { _CardSectionContainer, _CardSectionTitle } from "./CardSection.styles"

type OwnProps = {
    objectId: ObjectId
}

export const AssignedUserCardSection = asyncConnect<OwnProps>()({
    data: ["approvedUsers", "viewModels"],
    actions: ["openPopup"]
})(p => {
    const { decorators } = p.viewModels
    const approvedUsers = React.useMemo(() => asyncWithDefault(p.approvedUsers, {}), [p.approvedUsers])
    const userOptions = React.useMemo(() => getAssignmentDecoratorOptions(approvedUsers), [approvedUsers])
    const currentUser = asyncWithDefault(decorators, {})[p.objectId]?.assignment?.userId
    const selected = React.useMemo(() => getUserOptionByUserId(approvedUsers)(currentUser), [
        currentUser,
        approvedUsers
    ])

    return (
        <_AssignedUserCardSectionContainer data-cy="prm-card-section">
            <_CardSectionContainer>
                <_CardSectionTitle>Relationship Manager</_CardSectionTitle>
                <Flex>
                    <RadioDropdown
                        options={userOptions}
                        onSelect={({ value }) =>
                            p.openPopup("mandatoryComment", {
                                type: "assignment",
                                value: {
                                    type: "assignment",
                                    objectId: p.objectId,
                                    newValue: value,
                                    oldValue: selected.value
                                }
                            })
                        }
                        searchable
                        selected={[selected]}
                        title="Assign to user"
                        alignToLeft
                    />
                </Flex>
            </_CardSectionContainer>
        </_AssignedUserCardSectionContainer>
    )
})
