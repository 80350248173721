// TODO Fix this
/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react"
import ScrollLock from "react-scrolllock"

import { _VerticalSpace } from "../../styles/common"
import { _Tooltip } from "./Tooltip.styles"
import { Join } from "../../utils/reactUtils"
import { isEmpty } from "../../../functions/src/utils/validators"
import { SectionComponent } from "./Section"
import { Section } from "./Sections.types"

type TooltipViewProps = {
    items: Section[]
    position: TopLeft
    isOpened: boolean
    triggerFocus: F1<boolean>
}

const TooltipView = React.memo(
    React.forwardRef<HTMLDivElement, TooltipViewProps>(({ items, triggerFocus, ...styledProps }, ref) => {
        const [internalRef, setInternalRef] = React.useState<HTMLElement | null>(null)
        return (
            <_Tooltip
                {...styledProps}
                toRight={
                    styledProps.position.left + 458 > window.innerWidth
                        ? styledProps.position.left + 458 - window.innerWidth
                        : undefined
                }
                toBottom={
                    styledProps.position.top + (internalRef?.clientHeight || 0) > window.innerHeight
                        ? styledProps.position.top + (internalRef?.clientHeight || 0) - window.innerHeight
                        : undefined
                }
                onMouseEnter={() => triggerFocus(true)}
                onMouseLeave={() => triggerFocus(false)}
                ref={r => {
                    if (typeof ref === "function") ref(r)
                    else if (ref) ref.current = r
                    setInternalRef(r)
                }}>
                <Join items={items} renderJoining={() => <_VerticalSpace base="16px" />}>
                    {t => <SectionComponent {...t} />}
                </Join>
            </_Tooltip>
        )
    })
)

type TooltipProps = {
    items: React.MutableRefObject<Section[]>
    position: React.MutableRefObject<TopLeft>
    visible: boolean
}

export const Tooltip: React.FC<TooltipProps> = ({ visible, ...p }) => {
    if (isEmpty(p.items.current)) return null
    const [isFocused, setIsFocused] = React.useState(false)
    return visible || isFocused ? (
        <ScrollLock isActive={isFocused}>
            <TooltipView
                isOpened={visible || isFocused}
                triggerFocus={setIsFocused}
                items={p.items.current}
                position={p.position.current}
            />
        </ScrollLock>
    ) : null
}
