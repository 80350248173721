import { getFirebase } from "../../services/firebase"
import { CloudActions } from "../../../functions/src/actions/actionCreators"
import { actions } from "../data/actions"
import { actions as cfActions } from "../cloud/actions"
import { QueryWhere, ColRef } from "../../../functions/src/services/firebase/firestore"
import { getMillisFromPast } from "../../models/dateTime"
import { getStore } from ".."
import { validateActionResult } from "../../../functions/src/actions/validators"
import { getPerf } from "../../services/performance"
import { actOnFirestoreError } from "../auth/authCmds"
import { values, keys } from "../../../functions/src/utils/map"
import { DecoratingDataFetchMap } from "../../dependencies"
import { ViewModels, DependencySchema } from "../../../functions/src/models/ViewModels"
import { RELATION_CURRENT_OBJECT_INDEX } from "./dataUtils"
import { actions as asyncActions } from "../../store/async/actions"
import { getCollectionFromId } from "../../../functions/src/models/schemas"
import { AuthState } from "../store"
import { getCurrentRadar, getUserRadars, isDemoRadarLocation } from "../../models/LocationType"

export const subscribeOnUserActionResults = (userId: string) =>
    subscribeOnActionResults(getFirebase().firestore.ref("userActionsResults", userId, "results"))

export const subscribeOnRadarBasedActionResults = (radarId: string, isAdmin: boolean) => {
    const { firestore } = getFirebase()
    const radarResultsRef = firestore.ref("radarActionsResults", radarId, "results")
    const adminResultsRef = firestore.ref("adminActionsResults", radarId, "results")

    if (isAdmin && !firestore.isSubscribedOnRef(adminResultsRef)) {
        subscribeOnActionResults(adminResultsRef)
    }
    if (!firestore.isSubscribedOnRef(radarResultsRef)) {
        subscribeOnActionResults(radarResultsRef)
    }
}

export const subscribeOnPublicActionResults = () => {
    subscribeOnActionResults(getFirebase().firestore.ref("publicActionsResults"), {
        limit: 5,
        orderBy: "createdTs",
        orderDir: "desc"
    })
}

const _subscribedResults: SMap<boolean> = {}
const reactToResults = (path: string, results: SMap<CloudActionResult>, authState: AuthState): TypedAction[] => {
    const radars = getUserRadars(authState)
    const current = getCurrentRadar(authState)
    const isDemo = isDemoRadarLocation(authState.params)

    const currentRadar = current
        ? isDemo
            ? (current as LocationParams)
            : radars.find(r => r.radarId === current.radarId)
        : undefined

    if (!_subscribedResults[path]) {
        _subscribedResults[path] = true
        return [cfActions._setActionResults(results)]
    }

    const actionsToDealWith: TypedAction[] = []
    values(results).forEach(result => {
        const { action } = result as CloudActionResult<CloudActions>
        const fetchAction = <T extends keyof ViewModels>(d: DependencySchema<T>, radarId: string, objectId: string) =>
            actions._fetchMerge(radarId, currentRadar?.radarName || null, currentRadar?.hubName || null, d, {
                type: "single",
                value: objectId
            })
        switch (action.type) {
            case "addRelation": {
                const { radarId, relation } = action.payload
                const collection = getCollectionFromId(relation[RELATION_CURRENT_OBJECT_INDEX]) as CName
                if (!collection) return
                return actionsToDealWith.push(
                    fetchAction(
                        { relations: "single", [collection]: "single" } as DependencySchema,
                        radarId,
                        relation[RELATION_CURRENT_OBJECT_INDEX]
                    )
                )
            }
            case "mutateDecorator": {
                const { radarId, objectId, type } = action.payload
                // TODO: What if we refresh star on listing?
                if (type === "star") actionsToDealWith.push(asyncActions.refreshAsyncData("dashboardSummary"))
                actionsToDealWith.push(fetchAction(DecoratingDataFetchMap({ decorators: "single" }), radarId, objectId))
                return
            }
            case "createAndAssignTag":
            case "assignTag": {
                const { radarId, objectId } = action.payload
                return actionsToDealWith.push(
                    fetchAction(DecoratingDataFetchMap({ tagsAssignments: "single" }), radarId, objectId)
                )
            }
            case "createAndAssignSegmentTag":
            case "assignSegmentTag": {
                const { radarId, objectId } = action.payload
                return actionsToDealWith.push(
                    fetchAction(DecoratingDataFetchMap({ segmentTagsAssignments: "single" }), radarId, objectId)
                )
            }
            case "createComment":
            case "editComment":
            case "deleteComment": {
                const { radarId, objectId } = action.payload
                return actionsToDealWith.push(
                    fetchAction(DecoratingDataFetchMap({ comments: "single" }), radarId, objectId)
                )
            }
            case "assignSearchArea": {
                const { radarId, objectId } = action.payload
                return actionsToDealWith.push(
                    fetchAction(DecoratingDataFetchMap({ searchAreasAssignments: "single" }), radarId, objectId)
                )
            }
            case "uploadPrioritization": {
                const { radarId, sectorId } = action.payload
                // XXX: This pulls old data. How do we refresh?
                return actionsToDealWith.push(fetchAction({ sectors: "single", config: "all" }, radarId, sectorId))
            }
        }
    })
    return actionsToDealWith.concat(cfActions._setActionResults(results))
}

const LimitedActionResultsQuery: QueryOptions = {
    limit: 10,
    orderBy: "createdTs",
    orderDir: "desc",
    where: QueryWhere<CloudActionResult>("createdTs", ">=", getMillisFromPast({ months: 1 }))
}

export const subscribeOnActionResults = (
    ref: ColRef<SMap<CloudActionResult>>,
    queryOptions: QueryOptions = LimitedActionResultsQuery
) => {
    const { dispatch, getState } = getStore()
    const { firestore } = getFirebase()
    _subscribedResults[ref.path] = false
    firestore.subscribeOnCol(
        ref,
        validateActionResult,
        valids => {
            keys(valids).forEach(actionId => getPerf().stopTraceById(actionId))
            reactToResults(ref.path, valids, getState().auth).map(dispatch)
        },
        e => dispatch(actOnFirestoreError(`action results - ${ref.path}`)(e)),
        { ...queryOptions, onlyNew: true }
    )
}
