import { validate, validNumber, validString, validArrayStringDef, validStringDef } from "../utils/validators"
import { mapToDisplay } from "./common"
import { RFields } from "./RFields"
import {
    DEFAULT_PLACEHOLDER,
    sharedRequiredRadarFieldsValidationMap,
    sharedOptionalRadarFieldsValidationMap,
    sharedVMMetaValidationMap
} from "./shared"
import { TechTransferVM } from "./ViewModels"

const techTransferRequiredFieldsValidationMap: ValidationMap<Required<TechTransferFieldsRequired>> = {
    tech_of_interest: validString,
    ...sharedRequiredRadarFieldsValidationMap
}

const techTransferOptionalFieldsValidationMap: ValidationMap<Required<TechTransferFieldsOptional>> = {
    ...sharedOptionalRadarFieldsValidationMap,
    location: validStringDef,
    research_hub: validStringDef,
    tech_transfer_url: validStringDef
}

export const techTransferRelationsValidationMap: ValidationMap<Required<TechTransferRelations>> = {
    startups: validArrayStringDef,
    companies: validArrayStringDef,
    influencers: validArrayStringDef,
    patents: validArrayStringDef,
    research_hubs: validArrayStringDef,
    research_papers: validArrayStringDef,
    sectors: validArrayStringDef,
    tech_experts: validArrayStringDef
}

export const techTransferFieldsValidation: ValidationMap<Required<TechTransferFields>> = {
    ...techTransferRequiredFieldsValidationMap,
    ...techTransferOptionalFieldsValidationMap
}
export const techTransferValidationMap: ValidationMap<Required<TechTransfer>> = {
    ...techTransferFieldsValidation,
    techTransferId: validString,
    createdTs: validNumber
}
export const techTransferFields: CFields<"tech_transfers"> = RFields<
    TechTransferFieldsRequired,
    TechTransferFieldsOptional
>(techTransferRequiredFieldsValidationMap, techTransferOptionalFieldsValidationMap)

export const techTransferRelations: RFields<"tech_transfers"> = RFields<unknown, TechTransferRelations>(
    {},
    techTransferRelationsValidationMap
)

export const validateTechTransferRelations = validate<TechTransferRelations>(techTransferRelationsValidationMap)
export const validateTechTransferFields = validate<TechTransferFields>(techTransferFieldsValidation)
export const validateTechTransfer = validate<TechTransfer>(techTransferValidationMap)
export const validateTechTransferVM = validate<TechTransferVM>({
    ...techTransferValidationMap,
    ...sharedVMMetaValidationMap
})

export const displayTechTransfer = (r: TechTransferVM, ph: string = DEFAULT_PLACEHOLDER): Displayable<TechTransferVM> =>
    mapToDisplay(r, ph)

export const TechTransferFieldsDisplay: Displayable<TechTransferVM> = {
    tech_of_interest: "Tech of interest",
    description: "Description",
    location: "Location",
    research_hub: "Research hub",
    tech_transfer_url: "Transfer url",
    source: "Source",
    blurOnListing: "",
    createdTs: "Created At",
    commentsCount: "No. of comments",
    contactedLeadsCount: "No. of contacted leads",
    techTransferId: "ID",
    filterBounds: ""
}
