export type SearchParams<TSchema> = {
    set: <T extends keyof TSchema>(k: T, v: TSchema[T] | null) => void
    get: (k: keyof TSchema) => string | null
    toURL: () => string
}

export const SearchParams = <TSchema extends SMap<string>>(path?: string): SearchParams<TSchema> => {
    const _params = new URLSearchParams(path)
    return {
        set: <T extends keyof TSchema>(k: T, v: TSchema[T] | null) => {
            if (v) _params.set(k.toString(), v)
            else _params.delete(k.toString())
        },
        get: (k: keyof TSchema) => _params.get(k.toString()),
        toURL: () => _params.toString()
    }
}

export const browserDownloadByUrl = (url: string, name: string) => {
    const a = document.createElement("a")
    a.style.display = "none"
    document.body.appendChild(a)
    a.href = url

    a.setAttribute("download", name)

    a.click()

    window.URL.revokeObjectURL(a.href)
    document.body.removeChild(a)
}

export const getBlobFromUrl = (url: string): Promise<Blob> =>
    new Promise(res => {
        const xhr = new XMLHttpRequest()
        xhr.responseType = "blob"
        xhr.onload = () => {
            const blob: Blob = xhr.response
            res(blob)
        }
        xhr.open("GET", url)
        xhr.send()
    })
