import styled from "styled-components"

export const _TagsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const _TagBase = styled.div`
    margin: 2.5px;
    line-height: 24px;
    font-size: 14px;
    padding: 0 20px;
    border-radius: 15px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
`
