export type UniqueValue<T extends string> = State<"uniqueValue", { value: T }>
export type UniqueOR<
    T extends string,
    L extends UniqueValue<T> | UniqueOR<T, any, any> | UniqueAND<T, any, any>,
    R extends UniqueValue<T> | UniqueOR<T, any, any> | UniqueAND<T, any, any>
> = L extends never
    ? L
    : R extends never
    ? never
    : State<
          "uniqueOR",
          {
              left: L
              right: R
              __valueType: T
          }
      >
export type UniqueAND<
    T extends string,
    L extends UniqueValue<T> | UniqueOR<T, any, any> | UniqueAND<T, any, any>,
    R extends UniqueValue<T> | UniqueOR<T, any, any> | UniqueAND<T, any, any>
> = L extends never
    ? L
    : R extends never
    ? never
    : State<
          "uniqueAND",
          {
              left: L
              right: R
              __valueType: T
          }
      >
export type UniqueConstraint<
    T extends string,
    L extends UniqueValue<T> | UniqueOR<T, any, any> | UniqueAND<T, any, any> = never,
    R extends UniqueValue<T> | UniqueOR<T, any, any> | UniqueAND<T, any, any> = never
> = UniqueValue<T> | UniqueOR<T, L, R> | UniqueAND<T, L, R>

export const mkUniqueAND = <
    T extends string,
    L extends UniqueValue<T> | UniqueOR<T, any, any> | UniqueAND<T, any, any>,
    R extends UniqueValue<T> | UniqueOR<T, any, any> | UniqueAND<T, any, any>
>(
    left: L,
    right: R
) =>
    ({
        type: "uniqueAND",
        left,
        right
    } as UniqueAND<T, L, R>)

export const mkUniqueOR = <
    T extends string,
    L extends UniqueValue<T> | UniqueOR<T, any, any> | UniqueAND<T, any, any>,
    R extends UniqueValue<T> | UniqueOR<T, any, any> | UniqueAND<T, any, any>
>(
    left: L,
    right: R
) =>
    ({
        type: "uniqueOR",
        left,
        right
    } as UniqueOR<T, L, R>)

export const mkUniqueValue = <T extends string>(value: T) =>
    ({
        type: "uniqueValue",
        value
    } as UniqueValue<T>)

export const isUniqueValue = <T extends UniqueConstraint<any, any, any>>(
    c: T
): c is T extends UniqueValue<any> ? T : never => c.type === "uniqueValue"

export const isUniqueAND = <T extends UniqueConstraint<any, any, any>>(
    c: T
): c is T extends UniqueAND<any, any, any> ? T : never => c.type === "uniqueAND"

export const isUniqueOR = <T extends UniqueConstraint<any, any, any>>(
    c: T
): c is T extends UniqueOR<any, any, any> ? T : never => c.type === "uniqueOR"
