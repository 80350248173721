import * as React from "react"
import * as Papa from "papaparse"

export type SimpleError = {
    row?: string | number
    message: string | React.ReactElement
}

export type AnyError = SimpleError | Papa.ParseError

export const validateRequiredColumns = (results: Papa.ParseResult): AnyError[] => {
    const errs: AnyError[] = []

    const fields = results.meta.fields
    if (fields.indexOf("Startup Name") != 0)
        errs.push({
            message: (
                <>
                    A <em> Startup Name</em> column must be present, and first in the data.
                </>
            )
        })
    if (fields.indexOf("Crunchbase URL") != 1)
        errs.push({
            message: (
                <>
                    A <em> Crunchbase URL</em> column must be present, and second in the data.
                </>
            )
        })

    return errs
}

export const validateSeparators = (results: Papa.ParseResult): AnyError[] => {
    const errs: AnyError[] = []
    const fields = results.meta.fields
    const cols = fields.slice(2)
    const badCols = cols.filter(text => text.indexOf("::") == -1)
    for (const col of badCols)
        errs.push({
            message: (
                <>
                    Column <em>{col}</em> is missing the hierarchy delimiter "::"
                </>
            )
        })

    return errs
}

export const validateDuplicates = (results: Papa.ParseResult): AnyError[] => {
    const errs: AnyError[] = []
    const fields = results.meta.fields
    // This reduce operation uses the comma operator to update and return the accumulator
    const counters = fields.reduce((acc, val) => ((acc[val] = (acc[val] || 0) + 1), acc), {} as Record<any, number>)
    const dupes = Object.entries(counters).filter(([, cnt]) => cnt > 1)

    if (dupes.length > 0) {
        const dupeCols = dupes.map(([col]) => col).join(", ")
        errs.push({
            message: <>Column names that appear more than once: {dupeCols}</>
        })
    }
    return errs
}

export const validateInterleave = (results: Papa.ParseResult): AnyError[] => {
    const errs: AnyError[] = []
    const fields = results.meta.fields.slice(2)
    const lows = {} as Record<string, number>
    const highs = {} as Record<string, number>
    const cats = [] as Array<string>

    for (let i = 0; i < fields.length; i++) {
        const [cat] = fields[i].split("::")
        if (cats.indexOf(cat) == -1) cats.push(cat)

        const lo = lows[cat] ?? 1e6
        const hi = highs[cat] ?? -1e6
        if (i < lo) lows[cat] = i

        if (i > hi) highs[cat] = i
    }

    while (cats.length > 0) {
        const cat = cats.shift()!
        const lo = lows[cat]
        const hi = highs[cat]
        for (const other of cats) {
            const clo = lows[other]
            const chi = highs[other]
            if (Math.max(lo, clo) - Math.min(hi, chi) <= 0) {
                errs.push({
                    message: (
                        <>
                            Top-level categories <em>{cat}</em> and <em>{other}</em> have interleaving columns.
                        </>
                    )
                })
            }
        }
    }

    return errs
}

export const validateBlanks = (results: Papa.ParseResult): AnyError[] => {
    const errs: AnyError[] = []

    let countBlankEntries = 0
    results.data.forEach((row, i) => {
        const entries = Object.entries(row)
        const blankCols = entries.filter(([, value]) => value === "")
        if (blankCols.length > 0) countBlankEntries += 1
        if (blankCols.length > 0 && countBlankEntries < 10) {
            const colNames = blankCols.map(v => v[0]).join(", ")
            errs.push({
                row: i + 2,
                message: (
                    <>
                        Column(s) <em>{colNames}</em> contain empty values
                    </>
                )
            })
        }
    })

    if (countBlankEntries > 0) errs.push({ row: 0, message: `Total number of lines with blanks: ${countBlankEntries}` })

    return errs
}
