import * as React from "react"

export const useDebounce = <F extends (...args: any[]) => any>(f: F, waitMs = 50) => {
    const id = React.useRef<ReturnType<typeof setTimeout> | undefined>(undefined)
    // const [returnValue, setReturnValue] = React.useState<ReturnType<F> | null>(null)
    const run = (...args: Parameters<F>) => {
        cancel()
        return new Promise<ReturnType<F>>(resolve => {
            id.current = setTimeout(async () => {
                id.current = undefined
                resolve(f(...args))
            }, waitMs)
        })
    }
    const cancel = () => {
        if (!id.current) return
        clearTimeout(id.current)
        id.current = undefined
    }
    const forceRun = (...args: Parameters<F>) => {
        cancel()
        return new Promise<ReturnType<F>>(resolve => resolve(f(...args)))
    }
    return { run, cancel, forceRun }
}
