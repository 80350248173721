import styled from "styled-components"
import { styleIfProp } from "../../../styles/common"

export const _RangePickerIconContainer = styled.div``

export const _RangePickerWrapper = styled.div<{ minWidth?: boolean }>`
    .ant-calendar-picker:focus {
        outline: none;
    }
    .ant-calendar-range-picker-separator {
        min-width: 2px;
        width: 2px;
        height: 1px;
    }
    .ant-calendar-picker-input {
        height: 34px;
        line-height: 34px;
        display: flex;
        align-items: center;
        & * {
            &::placeholder {
                color: ${p => p.theme.colors.primary};
            }
        }
        & > * + * {
            color: ${p => p.theme.colors.primary};
            margin-left: 3px;
        }
    }
    .ant-calendar-range-picker-input {
        font-size: 14px;
        border: 1px solid #edeff2;
        ${styleIfProp("minWidth", "min-width: 150px;")}
        cursor: pointer;
        border-radius: 4px;
        outline: none;
        position: relative;
        background: #fff;
    }
`
