/* eslint-disable max-lines */
import React, { useEffect, useLayoutEffect, useState } from "react"
import { connect } from "react-redux"
import Draggable, { DraggableData } from "react-draggable"
import { MapColumn, SectorMapContainer, MapWrapper, LoaderContainer } from "./SectorsMap.styles"
import { calculateBounds } from "./SectorsMapController"
import { HexMap } from "../components/HexMap"
import { MouseWheelScroll } from "../components/MouseWheelScroll"
import { DraggableBounds } from "../components/DraggableBounds"
import { YAxis, XAxis } from "../components/Axis"
import { Sidebar } from "../components/Sidebar"
import { Filter } from "../styles/buttons"
import { toArray, extend, remap, values, keys, identity } from "../../functions/src/utils/map"
import { NavInline, NavButton } from "../styles/navigations"
import { Point, Bounds } from "../../functions/src/models/common"
import { actions } from "../store/auth/actions"
import { actions as uiActions } from "../store/ui/actions"
import { Loaded, isFetching } from "../../functions/src/utils/types"
import { detailsPaths } from "../paths"
import { _Spacer, Flex, Margin, FlexInline } from "../styles/common"
import { _TagFont } from "../styles/typography"
import { getTagNamesByObjectIdMap } from "../../functions/src/models/tags"
import { LoadableView } from "../utils/reactUtils"
import { ViewLoader } from "../containers/ViewRenderer"
import { getDataByPath } from "../store/data/dataSelectors"
import { SectorVM } from "../../functions/src/models/ViewModels"
import { Popup } from "../store/store"
import { NavigationParams } from "../utils/router.types"
import { MapDispatch } from "../utils/redux.types"
import { Loader } from "../components/common/Loader"
import { byNameAsc, byAreaOrder } from "../../functions/src/models/searchAreas"
import { RadarLocationState, AuthenticationState } from "../models/auth"
import { IconSvg } from "../components/IconSvg"
import { FiltersContainer } from "../containers/Filters"
import { Fog, FogState, configureFog } from "../components/Fog"
import { FilterType } from "../../functions/src/models/filtering"
import { all } from "lodash/fp"
import { PrettyCheckbox } from "../components/PrettyCheckbox"

type StateProps = {
    radar: RadarDetails
    sectors: SectorVM[]
    areasBySectorId: TMap<ObjectId, SearchArea[]>
    tagNamesByObjectId: SMap<string[]>
    loadingSectors: boolean
    selectedTags: string[]
    searchAreas: SMap<SearchArea>
    config: LocationParams
    authState: AuthenticationState
}

type ActionProps = { navigate: F1<NavigationParams>; openPopup: F1<Type<Popup>> }

type Props = StateProps & ActionProps

const DraggableSectorsMapLoaded: React.FC<Props> = p => {
    const radar = p.radar
    const [sectorId, setSectorId] = useState<string | null>(null)

    const [zoom, setZoom] = useState(1)
    const [bounds, setBounds] = useState(Bounds(0, 0, 0, 0))
    const [draggingPoint, setDraggingPoint] = useState(Point(0, 0))
    const [isDragging, setIsDragging] = useState(false)
    const [xmoved, setXMoved] = useState(false)
    const [ymoved, setYMoved] = useState(false)
    const [displayFog, setDisplayFog] = useState(true)
    const [hfogstate, setHFog] = useState("visible" as FogState)
    const [vfogstate, setVFog] = useState("visible" as FogState)

    useEffect(() => {
        if (draggingPoint.x < bounds.left) setDraggingPoint(pos => extend(pos)({ x: 0 }))
        if (draggingPoint.y < bounds.top) setDraggingPoint(pos => extend(pos)({ y: 0 }))
    }, [draggingPoint, bounds])

    const handleDraggableStart = (e: any) => {
        if (e.persist !== undefined) e.persist()
        setIsDragging(true)
    }

    const handleDraggableStop = () => setIsDragging(false)
    const handleDrag = (_: any, position: DraggableData) => {
        if (position.deltaX != 0) setXMoved(true)
        if (position.deltaY != 0) setYMoved(true)
        return setDraggingPoint(() => position)
    }

    const handleMouseWheel = (newPt: Point) =>
        setDraggingPoint(oldPt => {
            const delta = Point(oldPt.x - newPt.x, oldPt.y - newPt.y)
            const approachingLeft = oldPt.x + delta.x >= 0
            const approachingRight = delta.x <= bounds.left

            if (delta.x != 0) setXMoved(true)
            if (delta.y != 0) setYMoved(true)
            return Point(
                delta.x < 0 && delta.x > bounds.left
                    ? delta.x
                    : approachingLeft
                    ? 0
                    : approachingRight
                    ? bounds.left
                    : oldPt.x,
                delta.y < 0 && delta.y > bounds.top ? delta.y : oldPt.y
            )
        })

    const handleMoveLeft = React.useCallback(() => {
        const x = draggingPoint.x + 100
        setDraggingPoint(({ y }) => Point(x < 0 ? x : 0, y))
        setXMoved(true)
    }, [draggingPoint])

    const handleMoveRight = React.useCallback(() => {
        const x = draggingPoint.x - 100
        setDraggingPoint(({ y }) => Point(x > bounds.left ? x : bounds.left, y))
        setXMoved(true)
    }, [draggingPoint, bounds])

    useLayoutEffect(() => configureFog(setDisplayFog), [])
    useEffect(() => {
        if (xmoved) setVFog("invisible")
        if (ymoved) setHFog("invisible")
        if (displayFog && (xmoved || ymoved)) {
            window.localStorage.setItem("radar-show-fog", JSON.stringify(false))
        }
    }, [xmoved, ymoved, displayFog])

    useEffect(() => setBounds(calculateBounds()), [p.sectors, zoom])
    useEffect(() => {
        if (!sectorId) return
        const sector = p.sectors.find(s => s.sectorId === sectorId)
        const sectorUnlocked = !p.radar.demo || (sector !== undefined && Boolean(sector.sector_completed))
        if (!sectorUnlocked) p.openPopup("showContact")
        else
            p.navigate({
                path: detailsPaths["details/sectorsMap"].path,
                slugs: { sectorId }
            })

        setSectorId(null)
    }, [sectorId]) // eslint-disable-line react-hooks/exhaustive-deps

    const selectSector = (sid: string) => {
        setSectorId(sid)
    }

    const [filters, setFilters] = React.useState<SMap<boolean>>(
        remap(
            p.searchAreas,
            (_, v) => v.name,
            () => true
        )
    )

    useEffect(
        () =>
            setFilters(
                remap(
                    p.searchAreas,
                    (_, v) => v.name,
                    () => true
                )
            ),
        [p.searchAreas, setFilters]
    )
    const filtersBar: FilterType[] = React.useMemo(() => ["tags"], [])

    const navPlusOnClick = React.useCallback(() => setZoom(1.5), [])
    const navMinusOnClick = React.useCallback(() => setZoom(1), [])

    const [allFiltersOn, setAllFiltersOn] = React.useState(true)
    const toggleFilter = (name: string, checked: boolean) => {
        setFilters(f => ({ ...f, [name]: checked }))
    }
    // Detect if all filters are enabled. Fires as an effect after setFilter() above.
    useEffect(() => setAllFiltersOn(all(v => v, values(filters))), [filters])
    // Set filters object to all-true or all-false, just like its useState does initially.
    const toggleAllFilters = (checked: boolean) =>
        setFilters(
            remap(
                p.searchAreas,
                (_, v) => v.name,
                () => checked
            )
        )

    const saFilters = React.useMemo(
        () =>
            values(p.searchAreas)
                .sort(byNameAsc)
                .sort(byAreaOrder)
                .map(sa => (
                    <Filter key={sa.name} color={sa.color} off={!filters[sa.name]}>
                        <PrettyCheckbox
                            checked={filters[sa.name]}
                            onChange={(event: any) => toggleFilter(sa.name, event.target.checked)}>
                            {sa.name}
                        </PrettyCheckbox>
                    </Filter>
                )),
        [filters, p.searchAreas]
    )

    const allFiltersCheckbox = (
        <PrettyCheckbox
            style={{ margin: " 0 0 16px 10px" }}
            checked={allFiltersOn}
            onChange={(event: any) => toggleAllFilters(event.target.checked)}>
            <_TagFont>Select all</_TagFont>
        </PrettyCheckbox>
    )

    if (p.loadingSectors)
        return (
            <LoaderContainer data-cy="loader-container">
                <Loader fill loadingText="Fetching sectors" />
            </LoaderContainer>
        )
    return (
        <SectorMapContainer>
            <FiltersContainer filters={filtersBar} withClear>
                <_Spacer />
                <Flex>
                    <Margin left="5px" right="5px">
                        <NavInline>
                            <NavButton onClick={handleMoveLeft} disabled={draggingPoint.x === 0}>
                                <IconSvg name="arrow-left" width={5} height={10} />
                            </NavButton>
                            <NavButton onClick={handleMoveRight} disabled={draggingPoint.x === bounds.left}>
                                <IconSvg name="arrow-right" width={5} height={10} />
                            </NavButton>
                        </NavInline>
                    </Margin>
                    <Margin left="5px" right="5px">
                        <NavButton onClick={navPlusOnClick} disabled={zoom === 1.5}>
                            <IconSvg name="plus" width={14} height={14} />
                        </NavButton>
                    </Margin>
                    <Margin left="5px" right="5px">
                        <NavButton onClick={navMinusOnClick} disabled={zoom === 1}>
                            <IconSvg name="minus" width={14} height={2} />
                        </NavButton>
                    </Margin>
                </Flex>
            </FiltersContainer>
            <Flex grow={1}>
                <Sidebar>
                    {allFiltersCheckbox}
                    {saFilters}
                </Sidebar>
                <MapWrapper>
                    <MapColumn className="hex-map-container">
                        {displayFog && (
                            <Fog mode="horizontal" size="200px" state={hfogstate}>
                                <div className="down-arrow">
                                    <IconSvg name="encircled-arrow-right" width={49} height={49} rotate={90} />
                                </div>
                            </Fog>
                        )}
                        {displayFog && (
                            <Fog mode="vertical" size="200px" state={vfogstate}>
                                <div className="right-arrow">
                                    <IconSvg name="encircled-arrow-right" width={49} height={49} />
                                </div>
                            </Fog>
                        )}
                        <FlexInline>
                            <YAxis labels={radar.yAxisLabels} draggingPosition={draggingPoint} />
                            <div>
                                <XAxis labels={radar.xAxisLabels} draggingPosition={draggingPoint} />
                                <DraggableBounds setBounds={setBounds}>
                                    <MouseWheelScroll onPositionChange={handleMouseWheel} draggableBounds={bounds}>
                                        <Draggable
                                            allowAnyClick
                                            bounds={bounds}
                                            onDrag={handleDrag}
                                            position={draggingPoint}
                                            onStop={handleDraggableStop}
                                            onStart={handleDraggableStart}>
                                            <div>
                                                <HexMap
                                                    columns={radar.columns}
                                                    rows={radar.rows}
                                                    sectors={p.sectors}
                                                    zoom={zoom}
                                                    selectedTags={p.selectedTags}
                                                    searchAreasFilter={filters}
                                                    searchAreasBySectorId={p.areasBySectorId}
                                                    tagNamesByObjectId={p.tagNamesByObjectId}
                                                    isDragging={isDragging}
                                                    onClick={selectSector}
                                                    indicatorsVisible={radar.demo}
                                                />
                                            </div>
                                        </Draggable>
                                    </MouseWheelScroll>
                                </DraggableBounds>
                            </div>
                        </FlexInline>
                    </MapColumn>
                </MapWrapper>
            </Flex>
        </SectorMapContainer>
    )
}

const mapState = getDataByPath<StateProps>()(
    "radar/radar",
    ({ radar, sectors, searchAreas, searchAreasAssignments, tags, tagsAssignments }, { ui, auth }, _, models) =>
        Loaded({
            radar,
            tagNamesByObjectId: getTagNamesByObjectIdMap(tags, tagsAssignments),
            loadingSectors: isFetching(models.sectors),
            sectors: toArray(sectors!),
            selectedTags: ui.filters.tags || [],
            areasBySectorId: remap(sectors!, identity, (_x, sid) =>
                keys(searchAreasAssignments[sid] || {})
                    .map(aid => searchAreas[aid])
                    .filter(Boolean)
            ),
            searchAreas,
            config: (auth.params as RadarLocationState).locationParams,
            authState: auth.authentication
        })
)

const mapDispatch: MapDispatch<ActionProps> = d => ({
    navigate: params => d(actions.navigate(params)),
    openPopup: popupKey => d(uiActions.openPopup(popupKey))
})

export const SectorsMap = connect(mapState, mapDispatch)(LoadableView(ViewLoader, DraggableSectorsMapLoaded))
