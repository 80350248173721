import * as React from "react"
import { getFirebase } from "../../services/firebase"
import * as Sentry from "@sentry/browser"
import { Loader } from "../../components/common/Loader"
import { _FlexContainer } from "../../styles/common"
import { useHistory } from "react-router-dom"
import { _BodyFont } from "../../styles/typography"
import styled from "styled-components"
import { asyncConnect } from "../../store/async/asyncConnect"
import { isLoginError } from "../../models/LoginStatus"

export const EMAIL_FOR_SIGN_IN = "emailForSignIn"
export const SIGN_IN_RADAR = "signInRadar"

export const _ErrorWrapper = styled.div`
    display: flex;
    align-items: center;
    max-width: 500px;
    margin: 0 auto;
    height: 100vh;
`

export const SignInWithEmailLink = asyncConnect()({
    data: ["authentication"]
})(p => {
    const history = useHistory()
    const [error, setError] = React.useState<React.ReactNode>(null)

    React.useEffect(() => {
        const auth = getFirebase().auth
        const emailLink = location.href

        const logInUser = async () => {
            const isSignIn = await auth.isSignInWithEmailLink(emailLink)
            if (isSignIn) {
                let email = localStorage.getItem(EMAIL_FOR_SIGN_IN)
                // eslint-disable-next-line no-alert
                if (!email) email = prompt("Please provide your email for confirmation") || ""
                try {
                    await auth.signInWithEmailLink(email, emailLink)
                    localStorage.removeItem(EMAIL_FOR_SIGN_IN)
                    history.replace("/app/user/sign-in-with-email-link")
                } catch (err) {
                    Sentry.captureException(err)
                    setError(
                        <div>
                            <_BodyFont s17 bold align="center">
                                There was an error while signing in.
                            </_BodyFont>
                            <_BodyFont s17 bold align="center">
                                The link may have expired.
                            </_BodyFont>
                            <_BodyFont s17 bold align="center">
                                If&nbsp;you have used a valid link and you see this error, then please contact us on{" "}
                                <a
                                    href="mailto:stu@radicleinsights.com"
                                    style={{ color: "#0093ee", fontWeight: "normal" }}>
                                    stu@radicleinsights.com
                                </a>
                                .
                            </_BodyFont>
                        </div>
                    )
                }
            }
        }

        logInUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (isLoginError(p.authentication)) {
            Sentry.captureException({
                message: p.authentication.error,
                obj: p.authentication
            })
            setError(
                <div>
                    <_BodyFont s17 bold align="center">
                        There was an error with signing in.
                    </_BodyFont>
                    <_BodyFont s17 bold align="center">
                        Please contact us on{" "}
                        <a href="mailto:stu@radicleinsights.com" style={{ color: "#0093ee", fontWeight: "normal" }}>
                            stu@radicleinsights.com
                        </a>
                        .
                    </_BodyFont>
                </div>
            )
        }
    }, [p.authentication])

    return (
        <_FlexContainer grow={1} align="center" justify="center">
            {error ? <_ErrorWrapper>{error}</_ErrorWrapper> : <Loader fill loadingText="Authenticating" />}
        </_FlexContainer>
    )
})
