import * as React from "react"
import { _FlexContainer } from "../../styles/common"
import { _TagFont } from "../../styles/typography"
import { _CardTooltipContainer } from "./CardTooltip.styles"

export type CardTooltipProps = {
    title?: string
    content: React.ReactNode
    show: boolean
    position: {
        left?: string
        right?: string
        top?: string
        bottom?: string
    }
}
export const CardTooltip = (p: CardTooltipProps) => {
    return p.show ? (
        <_CardTooltipContainer position={p.position}>
            {p.title && <_TagFont>{p.title}</_TagFont>}
            <_FlexContainer>{p.content}</_FlexContainer>
        </_CardTooltipContainer>
    ) : null
}
