import { validString, validStringDef, validate, validNumber } from "../utils/validators"
import { mapToDisplay } from "./common"
import { RFields } from "./RFields"
import {
    DEFAULT_PLACEHOLDER,
    sharedRequiredRadarFieldsValidationMap,
    sharedOptionalRadarFieldsValidationMap,
    sharedVMMetaValidationMap
} from "./shared"
import { ClinicalTrialVM } from "./ViewModels"

export const clinicalTrialRequiredFieldsValidationMap: ValidationMap<Required<ClinicalTrialFieldsRequired>> = {
    trial_title: validString,
    ...sharedRequiredRadarFieldsValidationMap
}

export const clinicalTrialOptionalFieldsValidationMap: ValidationMap<Required<ClinicalTrialFieldsOptional>> = {
    ...sharedOptionalRadarFieldsValidationMap,
    location: validStringDef,
    sponsor: validStringDef,
    start_date: validStringDef,
    end_date: validStringDef,
    trial_url: validStringDef,
    affiliations: validStringDef,
    authors: validStringDef,
    study_phase: validStringDef
}

export const clinicalTrialFieldsValidationMap: ValidationMap<Required<ClinicalTrialFields>> = {
    ...clinicalTrialRequiredFieldsValidationMap,
    ...clinicalTrialOptionalFieldsValidationMap
}

export const clinicalTrialFields: CFields<"clinical_trials"> = RFields<
    ClinicalTrialFieldsRequired,
    ClinicalTrialFieldsOptional
>(clinicalTrialRequiredFieldsValidationMap, clinicalTrialOptionalFieldsValidationMap)

const clinicalTrialRequiredRelationsValidationMap: ValidationMap<Required<ClinicalTrialRelationsRequired>> = {}

const clinicalTrialOptionalRelationsValidationMap: ValidationMap<Required<ClinicalTrialRelationsOptional>> = {}

export const clinicalTrialRelationsValidationMap: ValidationMap<Required<ClinicalTrialRelations>> = {
    ...clinicalTrialRequiredRelationsValidationMap,
    ...clinicalTrialOptionalRelationsValidationMap
}

export const clinicalTrialRelations: RFields<"clinical_trials"> = RFields<
    ClinicalTrialRelationsRequired,
    ClinicalTrialRelationsOptional
>(clinicalTrialRequiredRelationsValidationMap, clinicalTrialOptionalRelationsValidationMap)

export const clinicalTrialValidationMap: ValidationMap<Required<ClinicalTrial>> = {
    ...clinicalTrialFieldsValidationMap,
    trialId: validString,
    createdTs: validNumber
}

export const validateClinicalTrial = validate<ClinicalTrial>(clinicalTrialValidationMap)
export const validateClinicalTrialFields = validate<ClinicalTrialFields>(clinicalTrialFieldsValidationMap)
export const validateClinicalTrialRelations = validate<ClinicalTrialRelations>(clinicalTrialRelationsValidationMap)
export const validateClinicalTrialVM = validate<ClinicalTrialVM>({
    ...clinicalTrialValidationMap,
    ...sharedVMMetaValidationMap
})

export const displayClinicalTrial = (
    c: ClinicalTrialVM,
    ph: string = DEFAULT_PLACEHOLDER
): Displayable<ClinicalTrialVM> => mapToDisplay(c, ph)

export const ClinicalTrialFieldsDisplay: Displayable<ClinicalTrialVM> = {
    trial_title: "Trial Title",
    trial_url: "Trial URL",
    location: "Location",
    source: "Source",
    sponsor: "Sponsor",
    start_date: "Study Start Date (last 10 years)",
    trialId: "Trial ID",
    description: "Description",
    createdTs: "Created At",
    commentsCount: "No. of comments",
    contactedLeadsCount: "No. of contacted leads",
    end_date: "End Date",
    affiliations: "Affiliations",
    authors: "Authors",
    study_phase: "Study phase",
    blurOnListing: "",
    filterBounds: ""
}
