import * as React from "react"
import { connect } from "react-redux"
import { actions as cfActions } from "../store/cloud/actions"
import { actions as uiActions } from "../store/ui/actions"
import { actions as dataActions } from "../store/data/actions"
import { isLoggedIn, isRoot } from "../models/LoginStatus"
import { Loaded, LoadingWithPayload, isFetching, isFetched } from "../../functions/src/utils/types"
import { identity, groupBy, keys } from "../../functions/src/utils/map"
import { useCloudAction } from "../utils/hooks/useCloudAction"
import { _VerticalSpace, _Center } from "../styles/common"
import { _NewsfeedWrapper } from "../components/newsfeed/Newsfeed.styles"
import { _BodyFont, Prominent } from "../styles/typography"
import { LoadableView } from "../utils/reactUtils"
import { ViewLoader } from "../containers/ViewRenderer"
import { getDataByPath } from "../store/data/dataSelectors"
import { NewsDTO, DependencySchema } from "../../functions/src/models/ViewModels"
import { AuthorizationType, ValidLocationState } from "../models/auth"
import { MapDispatch } from "../utils/redux.types"
import { Dispatch } from "../store/middlewares/authMiddleware.types"
import { getDependenciesForPath } from "../dependencies"
import { PageCursor } from "../store/data/dataUtils"
import { roundToDay } from "../models/dateTime"
import { EmptyNewsfeedView, NewsDay } from "../components/newsfeed/Newsfeed"
import { SubpageLayout } from "../components/Page"
import { Loader } from "../components/common/Loader"
import { isEmpty } from "../../functions/src/utils/validators"
import { Filters, FilterType } from "../../functions/src/models/filtering"
import { NavigationParams } from "../utils/router.types"
import { getUserRadars, isDemoRadarLocation, getCurrentRadar } from "../models/LocationType"
import { FiltersContainer } from "../containers/Filters"
import { track, identify } from "../tracking"

type StateProps = {
    news: TMap<Timestamp, NewsDTO[]>
    authorization: AuthorizationType
    current?: LocationParams
    radarId: RadarId
    loadingMore: boolean
    isAllLoaded: boolean
    flags: Partial<LocationParams>
    isRoot: boolean
    isDemo: boolean
    onLoadMore: F1<Dispatch>
}

type ActionProps = {
    setNewsfeedVisit: F3<ActionId, RadarId, Timestamp>
    dispatch: Dispatch
    updateParamsAndNavigate: F2<Partial<Filters>, NavigationParams>
}
type Props = StateProps & ActionProps

export const Newsfeed: React.FC<Props> = p => {
    const [, setNewsfeedVisit] = useCloudAction(p.setNewsfeedVisit, {})
    React.useEffect(() => {
        if (p.current) {
            identify(p.current.radarName, p.current.hubName)
            track("newsfeed", "view", { demo: p.isDemo, root: p.isRoot, hub: p.flags.hubId })
        }
        setNewsfeedVisit(p.radarId, Date.now())
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const filters: FilterType[] = ["searchArea", "collections"]
    if (p.flags.withSegmentTags && !p.isDemo) filters.push("segmentTags")
    filters.push("tags")
    if (!p.isRoot) filters.push("pipelines")
    if (p.flags.withPriorityRanks) filters.push("priorities")
    filters.push("date", "mutationType")
    if (p.flags.withAssignments && !p.isRoot && !p.isDemo) filters.push("assignments")

    return (
        <>
            <FiltersContainer filters={filters} withClear wrap />
            {isEmpty(p.news) && !p.loadingMore ? (
                <EmptyNewsfeedView />
            ) : (
                <SubpageLayout>
                    <_VerticalSpace base="24px" />
                    <_NewsfeedWrapper>
                        {keys(p.news).map(k => (
                            <NewsDay
                                news={p.news[k]}
                                auth={p.authorization}
                                key={k}
                                timestamp={k}
                                navigateOnClick={p.updateParamsAndNavigate}
                            />
                        ))}
                    </_NewsfeedWrapper>
                    <_Center>
                        <_VerticalSpace base="24px" />
                        {p.loadingMore ? (
                            <Loader loadingText="Fetching news" />
                        ) : p.isAllLoaded ? (
                            <_BodyFont s17>All news fetched</_BodyFont>
                        ) : (
                            <Prominent onClick={() => p.onLoadMore(p.dispatch)}>Load more</Prominent>
                        )}
                    </_Center>
                    <_VerticalSpace base="24px" />
                </SubpageLayout>
            )}
        </>
    )
}

export const groupByDays = (items: NewsDTO[]): TMap<Timestamp, NewsDTO[]> =>
    groupBy(items, ({ timestamp }) => roundToDay(timestamp) as any, identity)

const mapState = getDataByPath<StateProps>()("radar/newsfeed", (deps, { auth, ui }, _, models) => {
    if (!isLoggedIn(auth.authentication)) return LoadingWithPayload({ stateDescription: "Authenticating" })

    const radars = getUserRadars(auth)
    const current = getCurrentRadar(auth)
    const isDemo = isDemoRadarLocation(auth.params)

    const currentRadar = current
        ? isDemo
            ? (current as LocationParams)
            : radars.find(r => r.radarId === current.radarId)
        : undefined

    return Loaded({
        news: groupByDays(deps.news),
        authorization: auth.authentication.authorization,
        current: currentRadar,
        radarId: deps.radar.radarId,
        isAllLoaded: isFetched(models.news) && models.news.fetchLevel === "all",
        loadingMore: isFetching(models.news),
        onLoadMore: (d: Dispatch) => {
            const cursor = ui.cursor
            const schema = getDependenciesForPath("radar/newsfeed") as DependencySchema
            const newCursor = PageCursor(
                cursor.type === "page" ? (cursor.value.startingAfter || 0) + cursor.value.limit : 0
            )
            d(
                dataActions._fetchMerge(
                    deps.radar.radarId,
                    currentRadar?.radarName || null,
                    currentRadar?.hubName || null,
                    schema,
                    newCursor
                )
            )
        },
        flags: (auth.params as ValidLocationState).locationParams || {},
        isRoot: isRoot(auth.authentication),
        isDemo: isDemoRadarLocation(auth.params)
    })
})

const mapDispatch: MapDispatch<ActionProps> = d => ({
    dispatch: d,
    setNewsfeedVisit: (actionId, radarId, timestamp) => d(cfActions.setNewsfeedVisit(actionId, radarId, timestamp)),
    updateParamsAndNavigate: (filters: Partial<Filters>, params: NavigationParams) =>
        d(uiActions.updateParamsAndNavigate(filters, params))
})

export const NewsfeedView = connect(mapState, mapDispatch)(LoadableView(ViewLoader, Newsfeed))
