import { values, keys } from "../../../functions/src/utils/map"
import { getCollectionSchema } from "../../../functions/src/models/schemas"
import { isEqual } from "../../utils"
import { priorityRankOptions } from "../../../functions/src/models/decoratorValues"
import { ViewModelsBase, ViewModelsMap } from "../../../functions/src/models/ViewModels"
import { PriorityPayload } from "../../../functions/src/models/importing.types"
import { isErr } from "../../../functions/src/utils/validators"
import { getFirebase } from "../../services/firebase"
import { actions } from "../../../functions/src/services/httpEndpoint/actions"

export const matchPayloadWithCollection = <C extends CName>(vms: Pick<ViewModelsBase, CName>, cname: C) => (
    p: PriorityPayload<C>
): string | null => {
    const collection = values((vms[cname] || {}) as SMap<ViewModelsMap[C]>)
    const idField = getCollectionSchema(cname).idField as keyof ViewModelsMap[C]
    const result = collection.find(c => keys(p.matchers).every(key => c[key] === p.matchers[key]))
    return result ? (result[idField] as any) : null
}

export const findDuplicates = <C extends CName>(ps: PriorityPayload<C>[]): number[] => {
    const dups: number[] = []
    ps.forEach((p, id) =>
        ps.map((_, i) => i).filter(p2i => isEqual(p.matchers, ps[p2i].matchers)).length > 2 ? dups.push(id) : null
    )
    return dups
}

export const matchPayloadWithSearchAreas = <C extends CName>(vms: Pick<ViewModelsBase, "searchAreas">) => (
    p: PriorityPayload<C>
) => Boolean(values(vms.searchAreas).find(s => s.name === p.searchArea))

export const checkPriorityValues = (p: PriorityPayload<CName>) =>
    Boolean(priorityRankOptions.find(pr => pr.value === p.priority))

export const validateItemsExistence = async <C extends CName>(
    radarId: string,
    payloads: PriorityPayload<C>[],
    cname: C
): Promise<Result<ObjectId>[]> => {
    const res = await getFirebase().functions.callFunctions(
        actions.importValidatePriorities({ radarId, cname, payloads })
    )
    if (isErr(res)) return []
    return res.value.results
}

export type PriorityCheckResult<C extends CName> = {
    searchArea: string
    collection: string
    priority: string
    duplicates: string
    correct: SMap<PriorityPayload<C>[]>
}
export const checkPriorityPayload = async <C extends CName>(
    radarId: string,
    cname: C,
    vms: Pick<ViewModelsBase, "radar" | "searchAreas">,
    ps: PriorityPayload<C>[]
): Promise<PriorityCheckResult<C>> => {
    const searchAreaChecker = matchPayloadWithSearchAreas(vms)
    const indices = ps.map((_, i) => i)

    const collectionValues = await validateItemsExistence(radarId, ps, cname)
    const incorrectColIndices = indices.filter(i => isErr(collectionValues[i]))
    // console.log(indices)
    const incorrectSAIndices = indices.filter(i => !searchAreaChecker(ps[i]))
    const incorrectPRIndices = indices.filter(i => !checkPriorityValues(ps[i]))
    const wrongIndices = Array.from(new Set([...incorrectColIndices, ...incorrectPRIndices, ...incorrectPRIndices]))
    const correct: SMap<PriorityPayload<C>[]> = {}

    ps.forEach((p, i) => {
        if (wrongIndices.includes(i)) return
        const id = collectionValues[i]?.value
        if (!id || isErr(id)) return
        if (!correct[id]) correct[id] = []
        correct[id].push({ ...p, searchAreaId: values(vms.searchAreas).find(sa => sa.name === p.searchArea)?.areaId })
    })
    return {
        searchArea: incorrectSAIndices.join(", "),
        collection: incorrectColIndices.join(", "),
        priority: incorrectPRIndices.join(", "),
        duplicates: findDuplicates(ps).join(", "),
        correct
    }
}
