import { transparentize } from "polished"
import * as React from "react"
import styled, { css } from "styled-components"
import { values } from "../../../functions/src/utils/map"
import { styleIfProp, _VerticalSpace, _FlexRow, _HorizontalSpace, _FlexColumn } from "../../styles/common"
import { _h1 } from "../../styles/typography"
import { _IconDropdownIconContainer } from "../Dropdown.styles"
import { _IconSvgContainer } from "../IconSvg.styles"
import { ColGridSize } from "./utils"
import { PinnedColumns, PinnedColumnsProperties } from "./utils/pinnedColumns"

export const _TableContainer = styled.div`
    flex: 1 1 auto;
`

export const parseColumnSize = (t = 200) => {
    if (t === 0)
        return `
    min-width: 44px;
    padding: 0;
    padding-right: 4px;
    justify-content: center;
    `
    return `flex: ${t};`
}

type TableCellStyleProps = {
    lastCellSeparator?: boolean
    background?: string
    pinnedStyles?: PinnedColumnsProperties
    clickable?: boolean
}
export const _TableCell = styled.div.attrs<TableCellStyleProps>(p => ({
    ...p,
    background: p.background || "#fff"
}))<TableCellStyleProps>`
    display: flex;
    position: relative;
    ${styleIfProp("clickable", "cursor: pointer;")}
    background: ${p => p.background};

    ${p => {
        return (
            p.pinnedStyles &&
            css`
                position: sticky;
                z-index: 1;
                left: ${p.pinnedStyles.left};
                right: ${p.pinnedStyles.right};
            `
        )
    }}

    ${p =>
        p.lastCellSeparator &&
        css`
            /* Last child is _RowScrollingFade... */
            &:nth-last-child(2) {
                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: calc(100% + 1px);
                    border-left: solid 2px black;
                }
            }
        `}
    

    // Pinned columns border styles
    & {
        border-right: 1px solid #edeff2;
        border-left: 1px solid #edeff2;
        margin-right: -1px;
        margin-left: -1px;
    }

    &.pinned-right {
        border-left: none;
    }

    &.pinned-left {
        border-right: none;
    }
`
type RowScrollingFadeProps = {
    side: "right" | "left"
    pinnedColumns: PinnedColumns
    background?: string
}
export const _RowScrollingFade = styled.div.attrs<RowScrollingFadeProps>(p => ({
    ...p,
    background: p.background || "#fff"
}))<RowScrollingFadeProps>`
    position: sticky;
    width: 0;
    ${p => `${p.side}: ${values(p.pinnedColumns[p.side]).reduce((acc, c) => acc + c.size, 0)}px`};
    z-index: 1;

    ${p =>
        css`
            &:after {
                content: "";
                width: 50px;
                left: -49px;
                top: 0;
                bottom: 0;
                position: absolute;
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
                ${p.side}: 0;
                background: linear-gradient(
                    ${p.side === "left" ? "90deg" : "-90deg"},
                    ${p.background!} 5%,
                    ${transparentize(0.999)(p.background!)} 100%
                ); // Safari bug - cannot be transparent
            }
        `}

    .scroll-middle &,
    .scroll-${p => (p.side === "left" ? "right" : "left")} & {
        &:after {
            opacity: 1;
            visibility: visible;
        }
    }
`

type TableCellContentProps = {
    rowHeight: number
    justify?: "flex-start" | "flex-end" | "center"
    nopadding?: boolean
}
export const _TableCellContent = styled.div<TableCellContentProps>`
    display: flex;
    flex-grow: 1;
    align-items: center;
    padding: ${p => (p.nopadding ? "1px" : "11px")};
    max-height: ${p => `${p.rowHeight - 1}px`};
    max-width: 100%;
    flex-shrink: 0;
    justify-content: ${p => p.justify || "flex-start"};
    overflow: hidden;
`

export const _NoRowsRow = styled.div<{ rowHeight: number; minWidth: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${p => p.rowHeight}px;
    min-width: ${p => p.minWidth + 2}px;
    border-bottom: 1px solid #edeff2;
    border-left: 1px solid #edeff2;
    border-right: 1px solid #edeff2;
`

export type TableRowStyleProps = {
    clickable?: boolean
    rowHeight: number
    blurred?: boolean
    topSeparator?: boolean
    colSizes: ColGridSize[]
    background?: string
    hasOptions?: boolean
}
export const _TableRow = styled.div.attrs<TableRowStyleProps>(p => ({
    ...p,
    background: p.background || "#fff"
}))<TableRowStyleProps>`
    height: ${p => p.rowHeight}px;
    display: grid;
    grid-template-columns: ${p => [0, ...p.colSizes.map(cs => `minmax(${cs.min}, ${cs.max})`), 0].join(" ")};
    flex-grow: 1;
    border-bottom: solid 1px #edeff2;
    background: ${p => p.background};
    ${styleIfProp("blurred", "filter: blur(5px);")}
    ${styleIfProp("clickable", "cursor: pointer;")}
    ${styleIfProp("topSeparator", "border-top: solid 2px black;")}
    ${styleIfProp("topSeparator", "margin-top: -1px;")}
    ${styleIfProp("topSeparator", "z-index: 1;")}

    border-right: 1px solid #edeff2;
    border-left: 1px solid #edeff2;
`

export const _TablePinnedRow = styled.div<{ sticky: boolean }>`
    ${styleIfProp(
        "sticky",
        css`
            position: sticky;
            top: 0;
            z-index: 3;
        `
    )}
`

export const _TableHead = styled.div`
    ${_TableRow} {
        border-top: solid 1px #edeff2;
        background: #ecf5fd;
    }
`

export const _TableStickyRowsContainer = styled.div`
    display: flex;
    position: sticky;
    top: 0;
    z-index: 2;

    ${_TableRow} {
        min-height: 56px;
        border-radius: 20px 20px 0 0;

        & > ${_TableCell}:first-child {
            border-radius: 20px 0 0 0;
        }

        & > ${_TableCell}:last-child {
            border-radius: 0 20px 0 0;
        }
    }
`

export const _TableBody = styled(_FlexColumn)<{ virtualized?: boolean; pinnedRows?: boolean }>`
    ${p =>
        !p.virtualized &&
        css`
            overflow: auto;
        `};
    background-color: #fff;
    flex: 1 0 auto;

    ${p =>
        p.pinnedRows &&
        css`
            ${_TableHead} ${_TableRow} {
                border-radius: 0;

                & > ${_TableCell}:first-child {
                    border-radius: 0;
                }

                & > ${_TableCell}:last-child {
                    border-radius: 0;
                }
            }
        `}
`

export const TableHeader: React.FC<{ title: string }> = ({ title }) => (
    <>
        <_VerticalSpace base="8px" />
        <_FlexRow>
            <_HorizontalSpace base="24px" />
            <_h1>{title}</_h1>
        </_FlexRow>
        <_VerticalSpace base="12px" />
    </>
)

export const _TableView = styled(_FlexColumn)`
    flex: 1 0 auto;
    // Fix subpixel overflow bugs...
    max-width: calc(100% - 0.5px);
    min-width: calc(100% - 0.5px);
`

export const _MeatballMenuContainer = styled.div<{ size: number }>`
    ${_IconDropdownIconContainer} {
        padding: 0;
    }

    ${_IconSvgContainer} {
        position: relative;

        &:hover {
            svg {
                position: absolute;
                z-index: 1;
            }

            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: ${p => p.size * 2}px;
                height: ${p => p.size * 2}px;
                border-radius: 50%;
                background: #ecf5fd;
                transform: translate3d(-${p => p.size / 2}px, -${p => p.size / 2}px, 0);
            }
        }
    }
`
