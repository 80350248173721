import * as React from "react"
import { Icon } from "../../functions/src/models/icons"
import { ThemeColor } from "../styles/styled"
import { _IconSvgContainer } from "./IconSvg.styles"

export type IconSvgProps = {
    name: Icon
    width?: number
    height?: number
    color?: ThemeColor
    pathColors?: Record<string, string>
    inline?: boolean
    rotate?: number
    alt?: string
    onClick?: (e: unknown) => void
}

export function IconSvg(p: IconSvgProps) {
    return (
        <_IconSvgContainer {...p}>
            <svg>
                {p.alt && <title>{p.alt}</title>}
                <use xlinkHref={`#${p.name}`} />
            </svg>
        </_IconSvgContainer>
    )
}
