import { getReducer, cmdList } from "../../utils/redux"
import { extend, toMap, keys, identity, omitObject } from "../../../functions/src/utils/map"
import { cnames } from "../../../functions/src/models/schemas"
import {
    NotFetched,
    Fetched,
    isFetched,
    mergeAsync,
    isFetchingOrFetched,
    Fetching,
    mergeAsyncArray
} from "../../../functions/src/utils/types"
import { upsertDecorator, fetchRequiredData as getFetchActions, upsertRelationRemoval } from "./dataUtils"
import { PermissionsActions, PermissionsChanged } from "../middlewares/authMiddleware"
import { isValidLocation } from "../../models/LocationType"
import { setSubscriptions, fetchDataCmd } from "./dataCmds"
import { getRadarFetchAttributes } from "./subscriptionSchema"
import { DataState, RootContext } from "../store"
import { ViewModels } from "../../../functions/src/models/ViewModels"
import { AuthReason } from "../middlewares/authMiddleware.types"
import { AppLocationState } from "../../models/auth"
import { FlushStateAction } from "../auth/actions"
import { Actions } from "./actions"
import { track, identify } from "../../tracking"

export const initialState: DataState = {}

export const initialViewModels: ViewModels = {
    ...(toMap(cnames, k => k, NotFetched) as TMap<CName, AsyncNotFetched>),
    radar: NotFetched(),
    secureKeys: NotFetched(),
    users: NotFetched(),
    subscribers: NotFetched(),
    comments: NotFetched(),
    decorators: NotFetched(),
    pipelines: NotFetched(),
    relations: NotFetched(),

    tags: NotFetched(),
    tagsAssignments: NotFetched(),

    segmentTags: NotFetched(),
    segmentTagsAssignments: NotFetched(),

    searchAreas: NotFetched(),
    searchAreasAssignments: NotFetched(),
    news: NotFetched(),
    config: NotFetched()
}

const getCurrentRadarFromPermissions = (
    action: PermissionsActions<AppLocationState, AuthReason>
): LocationParams | Partial<LocationParams> | null => {
    const { params, permissions } = action.payload
    if (!isValidLocation(params) || permissions.status !== "Allowed") return null
    const { radarId } = params.locationParams
    if (!radarId) return null
    return params.locationParams
}

export const reducer = getReducer<DataState, Actions, RootContext>((state, action, context) => {
    function extendVM(radarId: string, part: Partial<ViewModels>) {
        return extend(state[radarId])(extend({ ...initialViewModels, ...(state[radarId] || {}) })(part))
    }
    const getRadarState = (rid: string) => state[rid] || initialViewModels
    switch (action.type) {
        case PermissionsChanged: {
            const currentRadar = getCurrentRadarFromPermissions(action)
            if (!currentRadar || !currentRadar.radarId || action.payload.params.type === "UserLocation") return {}
            return cmdList([
                ...getFetchActions(
                    currentRadar.radarId,
                    action.payload.params,
                    currentRadar?.radarName,
                    currentRadar?.hubName
                ),
                setSubscriptions(
                    currentRadar.radarId,
                    getRadarFetchAttributes(action.payload.params, context),
                    state[currentRadar.radarId]
                )
            ])
        }

        case "_fetch": {
            const { schema, cursor, radarId, radarName, hubName } = action.payload

            if (radarName && hubName) {
                identify(radarName, hubName)
                track("data", "fetch")
            }
            return fetchDataCmd(radarId, schema, cursor, context, false)
        }
        case "_fetchMerge": {
            const { schema, cursor, radarId, radarName, hubName } = action.payload
            if (radarName && hubName) {
                identify(radarName, hubName)
                track("data", "fetch-merge")
            }
            return fetchDataCmd(radarId, schema, cursor, context, true)
        }

        case "_setData": {
            const { radarId } = action.payload
            return { [radarId]: extendVM(radarId, action.payload.data) }
        }

        case "_mergeData": {
            const { radarId, data } = action.payload
            const result: Partial<ViewModels> = toMap(keys(data), identity, key => {
                const radarState = getRadarState(radarId)[key]
                return key === "news"
                    ? mergeAsyncArray<any>(radarState, data[key])
                    : mergeAsync<any>(radarState, data[key])
            })

            return { [radarId]: extendVM(radarId, result) }
        }

        case "_setFetching": {
            const { radarId, models, empty } = action.payload

            return {
                [radarId]: extendVM(
                    radarId,
                    toMap(models, identity, model => {
                        const currentState = getRadarState(radarId)[model]
                        return isFetchingOrFetched<any>(currentState) && !empty
                            ? Fetching(currentState.value)
                            : Fetching(model === "news" ? [] : {})
                    }) as Partial<ViewModels>
                )
            }
        }

        case "_removeData": {
            const { radarId, key, ids } = action.payload
            const currentData = getRadarState(radarId)[key]
            if (!isFetched<any>(currentData)) return {}
            return {
                [radarId]: extendVM(radarId, {
                    [key]: Fetched(omitObject(currentData.value, ids as any), {
                        fetchLevel: currentData.fetchLevel,
                        itemsCount: currentData.itemsCount && currentData.itemsCount - ids.length,
                        filterBounds: currentData.filterBounds
                    })
                })
            }
        }

        case "_upsertDecorator": {
            const { radarId, objectId } = action.payload
            const radarState = getRadarState(radarId)
            if (!isFetched(radarState.decorators)) return {}
            const decorators = radarState.decorators
            const newDecorator = upsertDecorator(decorators.value, action.payload)
            return {
                [radarId]: extendVM(radarId, {
                    decorators: Fetched(
                        { ...decorators.value, [objectId]: newDecorator },
                        { fetchLevel: decorators.fetchLevel }
                    )
                })
            }
        }
        case "_upsertRelationRemoval": {
            const { radarId, relation } = action.payload
            const radarState = getRadarState(radarId)
            if (!isFetched(radarState.relations)) return {}
            const current = radarState.relations
            const newRelations = upsertRelationRemoval(current.value, relation)
            return {
                [radarId]: extendVM(radarId, {
                    relations: Fetched({ ...current.value, ...newRelations }, { fetchLevel: current.fetchLevel })
                })
            }
        }

        case FlushStateAction:
            return { ...toMap(keys(state), identity, () => ({})) } as Partial<DataState>
    }
})
