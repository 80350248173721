import styled from "styled-components"
import { _FlexColumn, _FlexRow, styleIfProp } from "../../styles/common"
import { LinkButton } from "../../styles/buttons"

export const _NewsfeedWrapper = styled.div`
    width: 100%;
    max-width: 830px;
    margin: 0 auto;

    border: 1px solid #edeff2;
    & > * + * {
        border-top: 1px solid #edeff2;
    }
`

export const _DayHeader = styled.div`
    display: flex;
    align-items: center;

    height: 42px;
    background-color: #fafbfc;
    padding: 14px 19px;

    font-size: 11px;
    font-weight: bold;
    letter-spacing: 2px;
    color: ${({ theme }) => theme.colors.primary};
`

export const _DayItem = styled(_FlexRow)`
    min-height: 100px;
    padding: 18px 0;
    ${styleIfProp("onClick", "cursor: pointer")}
`
export const _DayItemDetails = styled(_FlexColumn)`
    & > * + * {
        margin-top: 8px;
    }
`
export const _NewsfeedPillWrapper = styled(_FlexRow)`
    flex-wrap: wrap;
    & * {
        margin: 2px 4px;
    }

    &.collapsed {
        max-height: 78px;
        overflow: hidden;
    }
`

export const _DayWrapper = styled.div`
    & > * + * {
        border-top: 1px solid #edeff2;
    }

    ${LinkButton} {
        padding: 4px 0;
    }
`

export const _DayContent = styled.div``
export const _ItemDetails = styled.div`
    padding: 24px;
`
export const _UnreadWrapper = styled.div`
    width: 50px;
    padding: 0 12px;
`
export const _UnreadIndicator = styled.div`
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background: #4ece3d;
`
