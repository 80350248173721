import * as React from "react"
import { connect } from "react-redux"

import { actions } from "../../store/auth/actions"
import * as Sentry from "@sentry/browser"

import { _BodyFont } from "../../styles/typography"
import { _VerticalSpace, _FlexContainer, styleIfProp } from "../../styles/common"
import { PG_RADAR_SLUG, getFederatedUrl } from "../../../functions/src/models/Radar"
import { AuthenticationState, UserLocationState } from "../../models/auth"
import { MapState, MapDispatch } from "../../utils/redux.types"
import styled, { css } from "styled-components"
import { _ErrorLabel, _Input } from "../../components/form/Input.styles"
import { isErr, isOk, validateEmail } from "../../../functions/src/utils/validators"
import { EMAIL_FOR_SIGN_IN, SIGN_IN_RADAR } from "./SignInWithEmailLink"
import {
    Fetched,
    FetchError,
    Fetching,
    isDone,
    isFetched,
    isFetchedError,
    isFetching,
    NotFetched
} from "../../../functions/src/utils/types"
import { Loader } from "../../components/common/Loader"
import { HexLayout } from "../layouts/HexLayout"
import { useLocation } from "react-router-dom"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { nonExistingUserMessage } from "../../../functions/src/actions/public/const"

type LoginState = {
    authentication: AuthenticationState
    params: UserLocationState
    host: string
    results: SMap<CloudActionResult>
}
type LoginActions = {
    resetAuthStatuses: F0
    signInViaEmail: F2<string, string>
}

export const _LoginFormTitle = styled.div<{ bold?: boolean }>`
    font-size: 45px;
    letter-spacing: -0.8px;
    font-weight: ${p => (p.bold ? "bold" : "normal")};
    text-align: center;
`

export const _LoginFormInput = styled(_Input)`
    border: solid 1px ${styleIfProp("validationError", "#ff151f", "#a0a5ae")};
    font-size: 16px;
    letter-spacing: -0.29px;
    max-width: unset;
    min-width: unset;
    width: 100%;
`

const baseLoginButton = css`
    outline: none;
    cursor: pointer;
    border: none;
    padding: 12px 43px;
    border-radius: 24px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.1px;
    font-family: inherit;
    color: #fff;
`

export const _DarkBlueButton = styled.button`
    ${baseLoginButton}
    background: ${({ theme }) => theme.colors.theme12};
`

const Login = (p: LoginActions & LoginState) => {
    const [email, setEmail] = React.useState("")
    const [sendResult, setSendResult] = React.useState<Async<string>>(NotFetched())
    const [actionsContainerRef, setActionsContainerRef] = React.useState<HTMLDivElement | null>(null)
    const [signInState, signInViaEmail] = useCloudAction(p.signInViaEmail, p.results)
    const location = useLocation()

    const sendEmailSignInLink = React.useCallback(async () => {
        setSendResult(Fetching(""))
        const vr = validateEmail(email)
        if (isOk(vr)) {
            signInViaEmail(email)
        } else {
            setSendResult(FetchError(vr.value))
        }
    }, [email, signInViaEmail])

    const validateEmailInput = (e: any) => {
        const vr = validateEmail(e.target.value)
        if (isErr(vr)) setSendResult(FetchError(vr.value))
        else setSendResult(NotFetched())
    }

    React.useEffect(() => {
        if (isDone(signInState)) {
            const r = signInState.value.result
            if (isErr(r)) {
                if (r.value === nonExistingUserMessage) {
                    setSendResult(FetchError(nonExistingUserMessage))
                } else {
                    Sentry.captureException(r.value)
                    setSendResult(FetchError("Could not send a link. Try again later."))
                }
            } else {
                localStorage.setItem(EMAIL_FOR_SIGN_IN, email)
                if (p.params.locationParams.radarSlug)
                    localStorage.setItem(SIGN_IN_RADAR, p.params.locationParams.radarSlug)
                setSendResult(Fetched(""))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signInState.type])

    React.useEffect(() => {
        const params = new URLSearchParams(location.search)
        if (!email) setEmail(atob(params.get("invited") || ""))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    React.useEffect(() => {
        if (actionsContainerRef) actionsContainerRef.style.height = `${actionsContainerRef.offsetHeight}px`
    }, [actionsContainerRef])

    return (
        <HexLayout
            main={
                <>
                    <h1>
                        <_LoginFormTitle>Generate your</_LoginFormTitle>
                        <_LoginFormTitle bold>sign-in link</_LoginFormTitle>
                    </h1>
                    <_VerticalSpace base="14px" />
                    <_BodyFont s16 color="theme11" align="center">
                        We will send a sign-in link to your e-mail.
                    </_BodyFont>
                    <_BodyFont s16 color="theme11" align="center">
                        Just click on that link to sign-in.
                    </_BodyFont>
                    <_VerticalSpace base="35px" />
                    <_LoginFormInput
                        name="email"
                        type="email"
                        placeholder="Your e-mail"
                        value={email}
                        validationError={isFetchedError(sendResult)}
                        onChange={e => {
                            setEmail(e.target.value)
                            if (isFetchedError(sendResult)) validateEmailInput(e)
                        }}
                        onBlur={e => {
                            validateEmailInput(e)
                        }}
                    />
                    <_ErrorLabel>
                        <div>
                            <_BodyFont s14 align="center">
                                {isFetchedError(sendResult) ? sendResult.value : null}
                            </_BodyFont>
                        </div>
                    </_ErrorLabel>
                    <_VerticalSpace base="16px" />
                    <_FlexContainer align="center" ref={setActionsContainerRef}>
                        {isFetching(sendResult) ? (
                            <Loader loadingText="Sending email" />
                        ) : isFetched(sendResult) ? (
                            <>
                                <_BodyFont align="center" bold>
                                    Link was sent
                                </_BodyFont>
                                <_VerticalSpace base="5px" />
                                <_BodyFont data-cy="check-inbox-copy" align="center">
                                    Check your inbox and click the link to sign-in
                                </_BodyFont>
                            </>
                        ) : (
                            <_DarkBlueButton data-cy="generateLoginLinkButton" onClick={() => sendEmailSignInLink()}>
                                Generate
                            </_DarkBlueButton>
                        )}
                    </_FlexContainer>
                </>
            }
        />
    )
}

const LoginFedauthWrapper = (p: LoginActions & LoginState) => {
    React.useEffect(() => {
        if (p.params.locationParams.radarSlug === PG_RADAR_SLUG)
            window.location.href = getFederatedUrl(process.env.DEPLOYMENT_MODE)
    }, [p.params.locationParams.radarSlug])

    return p.params.locationParams.radarSlug === PG_RADAR_SLUG ? null : <Login {...p} />
}

const mapState: MapState<LoginState> = ({ auth: { authentication, params }, cloud }) => ({
    authentication,
    params: params as UserLocationState,
    host: location.origin,
    results: cloud.actionsResults
})

const mapDispatch: MapDispatch<LoginActions> = d => ({
    resetAuthStatuses: () => d(actions.resetAuthStatuses()),
    signInViaEmail: (actionId, email) => d(actions.signInViaEmail(actionId, email))
})

export const LoginView = connect(mapState, mapDispatch)(LoginFedauthWrapper)
