import * as React from "react"
import styled from "styled-components"
import { Flex } from "../../styles/common"
import { Join } from "../../utils/reactUtils"

export const _Column = styled(Flex).attrs(() => ({ direction: "column", justify: "flex-start" }))`
    & > *:not(:last-child) {
        margin-bottom: ${p => 0.8 * p.theme.sizes.defaultPadding}px;
    }
`

export const _ColumnLayoutContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: ${p => 0.8 * p.theme.sizes.defaultPadding}px;
`

// TODO Make it configurable
export const ColumnLayout = <T extends any>(p: { items: T[][]; renderElement?: (e: T) => React.ReactNode }) => (
    <_ColumnLayoutContainer data-cy="column-layout">
        <Join items={p.items}>
            {c => (
                <_Column data-cy="column">
                    <Join items={c}>{e => (p.renderElement ? p.renderElement(e) : (e as React.ReactNode))}</Join>
                </_Column>
            )}
        </Join>
    </_ColumnLayoutContainer>
)
