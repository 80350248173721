import { performance } from "firebase/app"
import { getFirebase } from "./firebase"

type TraceName =
    | "LOGIN"
    | "DATA_FETCH"
    | "CLOUD_ACTION"
    | "AUTHENTICATION"
    | "FEDERATED_LOGIN"
    | "SEARCH"
    | "LIST_RECALCULATION"
    | "VIEW_MODELS_LOAD"
    | "STAR_LIST"
    | "STAR_DASHBOARD"
    | "LIST_SORT_FILTER"

export interface PerformanceService {
    startSingleInstanceTrace: F1<TraceName>
    stopSingleInstanceTrace: F1<TraceName>
    startSpecificTrace: F2<TraceName, string>
    startSpecificTraceOnce: F2<TraceName, string>
    stopSpecificTrace: F2<TraceName, string>
    startTraceById: (tn: TraceName, id: string, subName: string) => void
    stopTraceById: F1<string>
}
const getSpecificTraceName = (traceName: TraceName, subName: string) => `${traceName}-${subName}`

const init = (instance: performance.Performance): PerformanceService => {
    const traces: SMap<performance.Trace | undefined> = {}
    return {
        startSingleInstanceTrace: (traceName: TraceName) => {
            // console.log("startSingleInstanceTrace", traceName)
            if (traces[traceName]) traces[traceName]?.stop()
            const tracer = instance.trace(traceName)
            traces[traceName] = tracer
            tracer.start()
        },
        stopSingleInstanceTrace: (traceName: TraceName) => {
            // console.log("stopSingleInstanceTrace", traceName)
            const tracer = traces[traceName]
            if (!tracer) return
            tracer.stop()
            traces[traceName] = undefined
        },
        startSpecificTrace: (traceName: TraceName, subName: string) => {
            // console.log("startSpecificTrace", traceName, subName)
            const name = getSpecificTraceName(traceName, subName)
            if (traces[name]) traces[name]?.stop()
            const tracer = instance.trace(name)
            traces[name] = tracer
            tracer.start()
        },
        startSpecificTraceOnce: (traceName: TraceName, subName: string) => {
            const name = getSpecificTraceName(traceName, subName)
            if (traces[name]) return
            // console.log("startSpecificTraceOnce", traceName, subName)
            const tracer = instance.trace(name)
            traces[name] = tracer
            tracer.start()
        },
        stopSpecificTrace: (traceName: TraceName, subName) => {
            // console.log("stopSpecificTrace", traceName, subName)
            const name = getSpecificTraceName(traceName, subName)
            const tracer = traces[name]
            if (!tracer) return
            tracer.stop()
            traces[name] = undefined
        },
        startTraceById: (traceName: TraceName, id: string, subName: string) => {
            if (traces[id]) return
            // console.log("startSpecificTraceOnce", traceName, subName)
            const name = getSpecificTraceName(traceName, subName)
            const tracer = instance.trace(name)
            traces[id] = tracer
            tracer.start()
        },
        stopTraceById: (id: string) => {
            // console.log("stopSpecificTrace", traceName, subName)
            const tracer = traces[id]
            if (!tracer) return
            tracer.stop()
            traces[id] = undefined
        }
    }
}

let _performanceService: PerformanceService
export const getPerf = () => {
    if (!_performanceService) {
        getFirebase()
        _performanceService = init(performance())
    }
    return _performanceService
}
