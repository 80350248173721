import * as aesjs from "aes-js"

export const getEncryptedText = (data: string, key: EncryptionKey) => {
    const cBytes = aesjs.utils.utf8.toBytes(data)
    const aesCtr = new aesjs.ModeOfOperation.ctr(key)
    const res = aesjs.utils.hex.fromBytes(aesCtr.encrypt(cBytes))
    return res
}

const promiseFileRead = (instance: FileReader, eventType: keyof FileReaderEventMap) =>
    new Promise(resolve => {
        instance.addEventListener(eventType, resolve)
    })

export const encryptFile = async (file: Blob, key: EncryptionKey): Promise<Blob> => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    await promiseFileRead(reader, "load")
    const textBytes = new Uint8Array(reader.result as ArrayBuffer)
    const aesCtr = new aesjs.ModeOfOperation.ctr(key)
    const paddedData = aesjs.padding.pkcs7.pad(textBytes)
    const encryptedBytes = aesCtr.encrypt(paddedData)
    const buffer = Buffer.from(encryptedBytes).buffer
    return new Blob([buffer], { type: "application/octet-stream" })
}

export const decryptFile = async (file: Blob, key: EncryptionKey): Promise<Blob> => {
    const reader = new FileReader()
    reader.readAsArrayBuffer(file)
    await promiseFileRead(reader, "load")
    const textBytes = new Uint8Array(reader.result as ArrayBuffer)
    const aesCtr = new aesjs.ModeOfOperation.ctr(key)
    const decryptedBytes = aesCtr.decrypt(textBytes)
    const unpaddedData = aesjs.padding.pkcs7.strip(decryptedBytes)
    const buffer = Buffer.from(unpaddedData).buffer
    return new Blob([buffer], { type: "application/octet-stream" })
}

export const getDecryptedText = (data: string, ctr: EncryptionKey) => {
    const encryptedBytes = aesjs.utils.hex.toBytes(data)
    try {
        const aesCtr = new aesjs.ModeOfOperation.ctr(ctr)
        return aesjs.utils.utf8.fromBytes(aesCtr.decrypt(encryptedBytes))
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Skipping decryption", { e })
        return data
    }
}

export const generateRandomKey = (): EncryptionKey => Array.from({ length: 32 }, () => Math.floor(Math.random() * 255))
