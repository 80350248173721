import React from "react"
import styled from "styled-components"
import findKey from "lodash/findKey"

import { StartupVM } from "../../../functions/src/models/ViewModels"
import { asyncWithDefault, isFetching } from "../../../functions/src/utils/types"
import { Loader } from "../../components/common/Loader"
import { asyncConnect } from "../../store/async/asyncConnect"
import { detailsPaths } from "../../paths"

import { _VerticalSpace, _AbsPosElement, _HorizontalSpace } from "../../styles/common"
import {
    _CardSectionContainer,
    _CardSectionTableContainer,
    _CardSectionTitle
} from "../../components/newCards/sections/CardSection.styles"
import { _BodyFont, _TagFont } from "../../styles/typography"

import { PrioritizationWrapper } from "./prioritization/styles"
import { GridTable } from "./prioritization"

export type PrioritizationProps<C extends CName> = {
    objectId: string
    cname: C
}

const asyncWrap = asyncConnect<PrioritizationProps<CName>>()
export const CardPrioritization = asyncWrap({
    data: ["viewModels", "isDemo"],
    actions: ["navigate"]
})(props => {
    const models = props.viewModels

    if (isFetching(models.sectors) || isFetching(models.startups)) return <Loader />

    const sectors = asyncWithDefault(models.sectors, {})
    const startups = asyncWithDefault(models.startups, {})
    const sector = sectors[props.objectId]

    if (sector == undefined) return <Loader />

    const navigateToStartup = (url: string) => {
        const startupId = findKey(startups, (obj: StartupVM) => obj?.crunchbase_url == url)
        if (startupId === undefined) return
        props.navigate({ path: detailsPaths["details/startups"].path, slugs: { startupId } })
    }

    return (
        <PrioritizationWrapper>
            <StyledGridTable csv={sector.prioritization} navigateToStartup={navigateToStartup} />
        </PrioritizationWrapper>
    )
})

export const StyledGridTable = styled(GridTable)`
    display: grid;
    grid-auto-rows: max-content;
    position: relative;
    border: none;
    padding: 0;

    .col {
        padding: 6px;
        white-space: pre-line;
    }

    .headCol {
        border-bottom: 2px solid black;
        margin: 0 0.5em;
    }

    .groupCol {
        /* Center cell contents horizontally and vertically */
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
    }

    .tintedCol {
        background: ${p => p.theme.colors.theme1};
    }

    .sticky-left {
        position: sticky;
        left: 0;
    }

    .dataCol {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid #ecf5fd;
        background: white;

        svg {
            margin-right: 10px;
        }
        &.left {
            justify-content: left;
            text-align: left;
            margin-left: 31px;
        }
        &.rank-high .circle {
            fill: #68e2a7;
        }
        &.rank-medium .circle {
            fill: #ffe14e;
        }
        &.rank-low .circle {
            fill: #ff151f;
        }
        &.tall {
            height: 82px;
        }
    }

    .round-tl {
        border-radius: 27px 0 0 0;
    }
`
