/* eslint-disable max-lines */
import * as React from "react"
import { connect } from "react-redux"
import { _AbsPosContainer, _AbsPosElement, _HorizontalSpace, _Spacer } from "../../styles/common"
import { isFetched, Loaded, Loading } from "../../../functions/src/utils/types"
import { LoadableView } from "../../utils/reactUtils"
import { TabText, TabRenderable, Tab } from "../../components/tabs/Tabs"
import { getCopy } from "../../../functions/src/services/copy"
import { assertNever } from "../../../functions/src/utils"
import { getMapDispatch2 } from "../../utils/redux"
import { actions as authActions } from "../../store/auth/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { isErr, isEmpty } from "../../../functions/src/utils/validators"
import { ViewLoader } from "../../containers/ViewRenderer"
import { Reports, PipelineReports } from "../../../functions/src/services/httpEndpoint/reports"
import { getFirebase } from "../../services/firebase"
import { actions } from "../../../functions/src/services/httpEndpoint/actions"
import {
    pipelineVsObjectTypeProps,
    pipelineVsSearchAreaProps,
    pipelineVsMonthlyProgressionProps,
    leadProgressionVsCollectionProps
} from "./VsSearchArea"
import { Table } from "../../components/table/TableView"
import { Loader } from "../../components/common/Loader"
import { cancellable } from "../../utils/cancellable"
import { usePrevious } from "../../utils/hooks/usePrevious"
import { isEqual, printPage } from "../../utils"
import { getDataByPath } from "../../store/data/dataSelectors"
import { Filters, FilterType } from "../../../functions/src/models/filtering"
import { UIState } from "../../store/store"
import { ViewModelsBase } from "../../../functions/src/models/ViewModels"
import { _ReportsViewContainer, _ReportsPlaceholder, _PrintTitle } from "./reports.styles"
import { SmallButton } from "../../styles/buttons"
import CommentsView from "./CommentsView"
import { keys } from "../../../functions/src/utils/map"
import { LeadProgressionCounter, PipelineStageCounter } from "../../../functions/src/models/reports"
import { getPipelineStagesMap } from "../../../functions/src/models/decoratorValues"
import { URLTabs, getTabFromLocation } from "../../components/tabs/URLTabs"
import { exportReport } from "../../services/export"
import { goBackByMonths, getLastDayOfMonth } from "../../../functions/src/models/dateTime"
import { getTimezone } from "../../models/dateTime"
import { useLocation } from "react-router-dom"
import { isRoot } from "../../models/LoginStatus"
import { IconSvg } from "../../components/IconSvg"
import { FiltersContainer } from "../../containers/Filters"

const fetchResults = async <T extends Type<Reports>>(type: T, radarId: string, filters: Required<Filters>) => {
    const fetchAction = (t: Type<Reports>) => {
        switch (t) {
            case "pipelineVsSearchArea":
                return actions.pipelineStageVsSearchAreaReport({ radarId, filters })
            case "leadMonthlyProgression":
                return actions.leadProgressionVsMonthReport({ radarId, filters, timezone: getTimezone() })
            case "leadProgression":
                return actions.leadProgressionVsCollectionReport({ radarId, filters })
            case "archiveRationale":
                return actions.archiveRationaleReport({ radarId, filters })
            case "movedAlongThePipeline":
                return actions.movedAlongThePipelineReport({ radarId, filters })
            case "pipelineVsCollection":
                return actions.pipelineStageVsCollectionReport({ radarId, filters })
        }
        assertNever(t)
    }
    return getFirebase().functions.callFunctions(fetchAction(type))
}

const aggregatePS = (
    items: PipelineStageCounter,
    pipelines: TMap<PipelineStageId, PipelineStageValue>
): PipelineStageCounter => {
    const staticPipelines = getPipelineStagesMap()
    return keys(items).reduce<PipelineStageCounter>((acc, psid) => {
        const name = pipelines[psid]?.name
        if (!name) {
            const sname = staticPipelines[psid]?.name
            if (sname) acc[sname] = items[psid]
            // TODO is it possible to go here?
        } else if (isEmpty(items[psid])) {
            if (isEmpty(acc[name])) acc[name] = items[psid]
        } else {
            acc[name] = (acc[name] || 0) + items[psid]!
        }
        return acc
    }, {} as any)
}

const aggregatePSPosNeg = (
    items: LeadProgressionCounter,
    pipelines: TMap<PipelineStageId, PipelineStageValue>
): LeadProgressionCounter => {
    const staticPipelines = getPipelineStagesMap()
    return keys(items).reduce<LeadProgressionCounter>((acc, psid) => {
        const name = pipelines[psid]?.name
        if (!name) {
            const sname = staticPipelines[psid]?.name
            if (sname) acc[sname] = items[psid]
            // TODO is it possible to go here?
        } else if (isEmpty(items[psid])) {
            if (isEmpty(acc[name])) acc[name] = items[psid]
        } else {
            acc[name] = acc[name] || { positive: 0, negative: 0 }
            acc[name]!.positive += items[psid]!.positive
            acc[name]!.negative += items[psid]!.negative
        }
        return acc
    }, {} as any)
}

const prepareResponseForAggregatingPipelines = (
    report: PipelineReports,
    pipelines: TMap<PipelineStageId, PipelineStageValue>,
    aggregateFn: (items: any, ps: TMap<PipelineStageId, PipelineStageValue>) => any
) => ({
    type: report.type,
    items: (report.items as any).map((item: any) => ({
        ...item,
        data: aggregateFn(item.data, pipelines)
    })),
    summary: {
        ...report.summary,
        data: aggregateFn(report.summary.data as any, pipelines)
    }
})

const mapApiResult = (report: Reports, pipelines: TMap<PipelineStageId, PipelineStageValue>) => {
    switch (report.type) {
        case "pipelineVsCollection":
        case "pipelineVsSearchArea":
        case "leadMonthlyProgression":
            return prepareResponseForAggregatingPipelines(report, pipelines, aggregatePS)
        case "leadProgression":
            return prepareResponseForAggregatingPipelines(report, pipelines, aggregatePSPosNeg)
    }
    return report
}

const request = <T extends Type<Reports>>(
    type: T,
    radarId: string,
    filters: Filters,
    setApiResult: F1<Loadable<Reports>>,
    pipelines: TMap<PipelineStageId, PipelineStageValue>
) => {
    const { start, cancel } = cancellable(() => fetchResults(type, radarId, filters as any))
    const value = start()

    setApiResult(Loading())
    value
        .then(res => {
            if (res.type === "resolved") {
                if (isErr(res.value)) {
                    setApiResult(Loaded({ type: "error" } as any))
                    return
                }

                const result = mapApiResult({ type, ...res.value.value } as Reports, pipelines)
                setApiResult(Loaded(result) as Loaded<Reports>)
            }
        })
        .catch(e => {
            if (e.type !== "cancelled") throw e
        })

    return { value, cancel }
}

const objectTypePlaceholder = (
    <_ReportsPlaceholder>
        This report is not available for all object types. Please select object type.
    </_ReportsPlaceholder>
)
const loaderPlaceholder = (
    <_ReportsPlaceholder>
        <Loader loadingText="Generating Report" />
    </_ReportsPlaceholder>
)
const noCommentPlaceholder = <_ReportsPlaceholder>No comments available with selected filters.</_ReportsPlaceholder>

const errorPlaceholder = <_ReportsPlaceholder>Something went wrong with the server response</_ReportsPlaceholder>

export type ReportsProps = { radarId: string; isRoot: boolean } & Pick<UIState, "filters"> &
    Pick<ViewModelsBase, "pipelines" | "searchAreas"> & { config: LocationParams }

type SetFiltersAfterTabChangeProps = {
    tab: Type<Reports>
    filtersOnEnter?: Partial<Filters>
    filtersOnLeave?: Partial<Filters>
    selectedTab: Type<Reports>
    previousTab: Type<Reports> | undefined
    updateFilters: ActionProps["updateFilters"]
}

const setFiltersAfterTabChange = ({
    tab,
    filtersOnEnter,
    filtersOnLeave,
    updateFilters,
    selectedTab,
    previousTab
}: SetFiltersAfterTabChangeProps) => {
    if (tab === selectedTab && filtersOnEnter) {
        updateFilters(filtersOnEnter)
    } else if (tab === previousTab && filtersOnLeave) {
        updateFilters(filtersOnLeave)
    }
}

const tabIds: Type<Reports>[] = [
    "pipelineVsSearchArea",
    "pipelineVsCollection",
    "leadProgression",
    "leadMonthlyProgression",
    "movedAlongThePipeline",
    "archiveRationale"
]

const ReportsCard: React.FC<ReportsProps & ActionProps> = p => {
    const location = useLocation()
    const [apiResult, setApiResult] = React.useState<Loadable<Reports>>(Loading())
    const [requestBox, setRequestBox] = React.useState<any | null>(null)
    const [selectedTab, setSelectedTab] = React.useState<Type<Reports>>(
        getTabFromLocation(
            tabIds.map(t => ({ id: t } as Tab<Type<Reports>>)),
            location
        ) || "pipelineVsSearchArea"
    )

    const previousFilters = usePrevious(p.filters)
    const previousTab = usePrevious(selectedTab)

    React.useEffect(() => {
        if (!isEqual(previousFilters, p.filters) || selectedTab !== previousTab) {
            if (requestBox) requestBox.cancel()
            setRequestBox(request(selectedTab, p.radarId, p.filters, setApiResult, p.pipelines))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTab, previousTab, p.filters, previousFilters, p.radarId, p.pipelines])

    React.useEffect(() => {
        const now = Date.now()
        setFiltersAfterTabChange({
            tab: "leadMonthlyProgression",
            filtersOnEnter: {
                date: {
                    from: goBackByMonths(now, 12, getTimezone()).toString(),
                    to: getLastDayOfMonth(now, getTimezone()).toString()
                }
            },
            filtersOnLeave: { date: { from: null, to: null } },
            updateFilters: p.updateFilters,
            selectedTab,
            previousTab
        })
    }, [selectedTab]) // eslint-disable-line react-hooks/exhaustive-deps

    const pipelineVsSearchArea: Tab<Type<Reports>> = {
        id: "pipelineVsSearchArea",
        title: TabText(`Pipeline stage vs ${getCopy("searchArea")}`),
        content: TabRenderable(() => {
            if (p.filters.collections?.length !== 1) return objectTypePlaceholder
            if (apiResult.loading) return loaderPlaceholder
            if (apiResult.type !== "pipelineVsSearchArea") return errorPlaceholder
            return (
                <_AbsPosContainer>
                    <_AbsPosElement>
                        <Table
                            {...pipelineVsSearchAreaProps({ response: apiResult, ...p })}
                            headerRowEffects={["disabled", "last-cell-separator"]}
                            pinnedColumns={{ left: ["name"] }}
                            virtualized={false}
                        />
                    </_AbsPosElement>
                </_AbsPosContainer>
            )
        })
    }

    const pipelineVsObjectType: Tab<Type<Reports>> = {
        id: "pipelineVsCollection",
        title: TabText(`Pipeline stage vs object type`),
        content: TabRenderable(() => {
            if (apiResult.loading) return loaderPlaceholder
            if (apiResult.type !== "pipelineVsCollection") return errorPlaceholder
            return (
                <_AbsPosContainer>
                    <_AbsPosElement>
                        <Table
                            {...pipelineVsObjectTypeProps({ response: apiResult, ...p })}
                            headerRowEffects={["disabled", "last-cell-separator"]}
                            pinnedColumns={{ left: ["name"] }}
                            virtualized={false}
                        />
                    </_AbsPosElement>
                </_AbsPosContainer>
            )
        })
    }

    const leadProgression: Tab<Type<Reports>> = {
        id: "leadProgression",
        title: TabText(`Lead progression (net change)`),
        content: TabRenderable(() => {
            if (apiResult.loading) return loaderPlaceholder
            if (apiResult.type !== "leadProgression") return errorPlaceholder
            return (
                <_AbsPosContainer>
                    <_AbsPosElement>
                        <Table
                            {...leadProgressionVsCollectionProps({ response: apiResult, ...p })}
                            headerRowEffects={["disabled", "last-cell-separator"]}
                            pinnedColumns={{ left: ["name"] }}
                            virtualized={false}
                        />
                    </_AbsPosElement>
                </_AbsPosContainer>
            )
        })
    }

    const leadMonthlyProgression: Tab<Type<Reports>> = {
        id: "leadMonthlyProgression",
        title: TabText(`Lead progression (months)`),
        content: TabRenderable(() => {
            if (p.filters.collections?.length !== 1) return objectTypePlaceholder
            if (apiResult.loading) return loaderPlaceholder
            if (apiResult.type !== "leadMonthlyProgression") return errorPlaceholder
            return (
                <_AbsPosContainer>
                    <_AbsPosElement>
                        <Table
                            {...pipelineVsMonthlyProgressionProps({ response: apiResult, ...p })}
                            headerRowEffects={["disabled", "last-cell-separator"]}
                            pinnedColumns={{ left: ["name"] }}
                            virtualized={false}
                        />
                    </_AbsPosElement>
                </_AbsPosContainer>
            )
        })
    }

    const movedAlong: Tab<Type<Reports>> = {
        id: "movedAlongThePipeline",
        title: TabText(`Moved along the pipeline`),
        content: TabRenderable(() => {
            if (apiResult.loading) return loaderPlaceholder
            if (apiResult.type !== "movedAlongThePipeline") return errorPlaceholder
            if (!apiResult.items.length) return noCommentPlaceholder
            return (
                <CommentsView
                    pipelines={p.pipelines}
                    report={apiResult}
                    searchAreas={p.searchAreas}
                    config={p.config}
                />
            )
        })
    }

    const archiveRationale: Tab<Type<Reports>> = {
        id: "archiveRationale",
        title: TabText(`Archive rationale`),
        content: TabRenderable(() => {
            if (apiResult.loading) return loaderPlaceholder
            if (apiResult.type !== "archiveRationale") return errorPlaceholder
            if (!apiResult.items.length) return noCommentPlaceholder
            return (
                <CommentsView
                    pipelines={p.pipelines}
                    report={apiResult}
                    searchAreas={p.searchAreas}
                    config={p.config}
                />
            )
        })
    }

    const data: Tab<Type<Reports>>[] = [
        pipelineVsSearchArea,
        pipelineVsObjectType,
        leadProgression,
        leadMonthlyProgression,
        movedAlong,
        archiveRationale
    ]
    const filters: FilterType[] = ["collections", "searchAreas"]
    if (p.config.withSegmentTags) filters.push("segmentTags")
    filters.push("tags")
    if (p.config.withPriorityRanks) filters.push("priorities")
    filters.push("date")

    return (
        <_ReportsViewContainer>
            <_PrintTitle>{(data.find(t => t.id === selectedTab)?.title as TabText).value || ""}</_PrintTitle>
            <FiltersContainer filters={filters} withClear>
                <_Spacer />
                <SmallButton disabled={apiResult.loading} onClick={printPage}>
                    <IconSvg name="export" width={16} height={16} />
                    <_HorizontalSpace base="12px" />
                    PDF
                </SmallButton>
                <SmallButton
                    disabled={apiResult.loading}
                    onClick={() => exportReport("xlsx", apiResult, p.searchAreas, p.pipelines, p.filters)}>
                    <IconSvg name="export" width={16} height={16} />
                    <_HorizontalSpace base="12px" />
                    XLSX
                </SmallButton>
                <SmallButton
                    disabled={apiResult.loading}
                    onClick={() => exportReport("csv", apiResult, p.searchAreas, p.pipelines, p.filters)}>
                    <IconSvg name="export" width={16} height={16} />
                    <_HorizontalSpace base="12px" />
                    CSV
                </SmallButton>
            </FiltersContainer>
            <URLTabs data={data} onChange={id => setSelectedTab(id)} scrollable />
        </_ReportsViewContainer>
    )
}

const mapState = getDataByPath<ReportsProps>()("radar/reports", (deps, state) => {
    const configs = state.auth.configs
    if (!isFetched(configs)) return Loading()

    return Loaded({
        filters: state.ui.filters,
        radarId: deps.radar.radarId,
        ...deps,
        isRoot: isRoot(state.auth.authentication),
        config: configs.value[deps.radar.radarId]
    })
})

export type ActionProps = ReturnType<typeof mapDispatch>
const mapDispatch = getMapDispatch2(authActions, ["navigate"], uiActions, [
    "updateParamsAndNavigate",
    "updateFilters",
    "openPopup"
])

export const ReportsView = connect(mapState, mapDispatch)(LoadableView(ViewLoader, ReportsCard))
