import * as React from "react"
import { ColumnDefinition } from "../../components/table/TableView.types"
import { _VerticalSpace } from "../../styles/common"
import { _BodyFont } from "../../styles/typography"

const definitions = {
    ics: {
        header: "Definition of investor quality score",
        content:
            "The Investor Quality Score (Percentile) measures the selectivity and success of the investors participating in an investment round."
    },
    fundingMomentum: {
        header: "definition of Funding momentum",
        content:
            "Funding momentum measures the velocity and magnitude of capital flowing into the sector as a function of the time between financing rounds and the amount of capital raised."
    },

    prorityScore: {
        header: "definition of Priority Score",
        content:
            "The Radicle Priority Score is a measure used to rank sectors and companies. It is a function of both qualitative and quantitative measures. Qualitative measures include Business Model Fit score. Quantitative measures include total funding, funding momentum, the Investor Quality Score (Percentile), and nascency."
    },
    probOfExit: {
        header: "definition of Probability of Exit",
        content:
            "The Probability of Exit estimates the likelihood of an IPO or acquisition. The likelihood is based on a state-of-the-art machine learning algorithm that takes into account hundreds of different data points."
    },
    sectorConcentration: {
        header: "definition of Sector Concentration",
        content: (
            <>
                <_BodyFont>
                    Sector Concentration measures the degree to which venture capital dollars are consolidated among
                    competing startups in a sector. It is a measure of a sector's competition.
                </_BodyFont>
                <_VerticalSpace base="8px" />
                <_BodyFont>
                    We consider Sector Concentration to have six ranges of values that facilitate interpretation:
                </_BodyFont>
                <_VerticalSpace base="8px" />
                <_BodyFont bold>0-500</_BodyFont>
                <_VerticalSpace base="2px" />
                <_BodyFont s11>An abundance of startups with more or less similar levels of capital.</_BodyFont>
                <_VerticalSpace base="8px" />
                <_BodyFont bold>500-1500&nbsp;–&nbsp;Wide Open</_BodyFont>
                <_VerticalSpace base="2px" />
                <_BodyFont s11>
                    A healthy mix of competing startups, with more or less similar levels of capital.
                </_BodyFont>
                <_VerticalSpace base="8px" />
                <_BodyFont bold>1500-2500&nbsp;–&nbsp;Competitive</_BodyFont>
                <_VerticalSpace base="2px" />
                <_BodyFont s11>One or a few startups are mildly differentiated in terms of capital traction.</_BodyFont>
                <_VerticalSpace base="8px" />
                <_BodyFont bold>2500-5,000&nbsp;–&nbsp;Lightly Concentrated</_BodyFont>
                <_VerticalSpace base="2px" />
                <_BodyFont s11>
                    One or a few startups have a moderate competitive advantage in terms of capital traction.
                </_BodyFont>
                <_VerticalSpace base="8px" />
                <_BodyFont bold>5000-7,500&nbsp;–&nbsp;Moderately Concentrated</_BodyFont>
                <_VerticalSpace base="2px" />
                <_BodyFont s11>
                    One or a few startups have a significant competitive advantage in terms of capital traction.
                </_BodyFont>
                <_VerticalSpace base="8px" />
                <_BodyFont bold>7500-10,000&nbsp;–&nbsp;Highly Concentrated</_BodyFont>
                <_VerticalSpace base="2px" />
                <_BodyFont s11>One or two startups dominate the sector.</_BodyFont>
            </>
        )
    }
}

const definitionsMap: { [P in keyof RadarCollections]: SCasted<RCollection<P>, ColumnDefinition> } = {
    patents: {},
    influencers: {},
    research_hubs: {},
    research_papers: {},
    tech_experts: {},
    tech_transfers: {},
    sectors: {
        investor_quality: definitions.ics,
        funding_momentum: definitions.fundingMomentum,
        sector_funding_momentum: definitions.fundingMomentum,
        priority_score: definitions.prorityScore,
        sector_concentration: definitions.sectorConcentration
    },
    startups: {
        investor_quality: definitions.ics
    },
    investors: {
        investor_quality: definitions.ics
    },
    patent_holders: {},
    grants: {},
    clinical_trials: {},
    companies: {}
}

export const getDefinitionForKey = <T extends keyof RadarCollections>(collectionName: T, key: keyof RCollection<T>) =>
    (definitionsMap[collectionName] as SCasted<RCollection<T>, ColumnDefinition>)[key]
