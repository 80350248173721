import { isFunction } from "./validators"
import { keys } from "./map"

export const setPromiseTimeout = <T>(cb: F0<T>, ms: number): Promise<T> =>
    new Promise((res: F1<T>) => setTimeout(() => res(cb()), ms))

export const usleep = (ms = 0) => setPromiseTimeout(() => null, ms)

export const mkString = (v: any = ""): string => (isFunction(v?.toString) ? v.toString() : `${v ?? ""}`)
export const unifyAphostrophe = (s: string) => s.replace(/’|'/gi, "'")

export const asyncForEachObject = async <T, S>(
    vs: SMap<T>,
    cb: (key: string, val: T, i: number) => Promise<S>,
    delay = 0
) => {
    const ks = keys(vs)
    for (let i = 0; i < ks.length; i++) {
        await usleep(delay)
        await cb(ks[i], vs[ks[i]], i)
    }
}

export const parenthesize = (s: string, cond: boolean) => (cond ? `(${s})` : s)

export const asyncForEach = async <T, S>(vs: T[], cb: (val: T, i: number) => Promise<S>, delay = 0) => {
    for (let i = 0; i < vs.length; i++) {
        await usleep(delay)
        await cb(vs[i], i)
    }
}

export const toggleArray = <T>(ts: T[], t: T): T[] => ((ts || []).includes(t) ? ts.filter(e => e !== t) : ts.concat(t))
export const replaceInArray = <T>(ts: T[], newT: T, find: F1<T, boolean>): T[] => {
    if (!ts.find(find)) return ts
    const indexOfReplacedItem = ts.findIndex(find)
    ts.splice(indexOfReplacedItem, 1)
    return [newT, ...ts]
}

export const chunkArr = <T>(array: T[], count: number): T[][] => {
    if (count < 1) return []
    const result = []
    let i = 0
    const length = array.length
    while (i < length) {
        result.push(Array.prototype.slice.call(array, i, (i += count)))
    }
    return result
}

export const hasField = <T>(t: T, field: keyof T) => t[field] !== undefined

export const findIndex = <T>(vs: T[], findFunc: F1<T, boolean>, ifNot: number) => {
    const res = vs.findIndex(findFunc)
    if (res === -1) return ifNot
    return res
}

export const assertNever = (v: never, shouldThrow = false) => {
    if (!shouldThrow) return v
    throw new Error(`Unhandled discriminated union member: ${JSON.stringify(v)}`)
}
export const capitalize = (s: string) => s.charAt(0).toLocaleUpperCase() + s.slice(1)
export const labelize = (s: string) => capitalize(`${s}`.toLocaleLowerCase())
export const camelCased = (s: string): string => s.replace(/_(.)/g, (_, g) => g.toUpperCase())
export const snakeCaseToCapitalized = (s: string): string =>
    capitalize(s.replace(/_(.)/g, (_, g) => ` ${g.toUpperCase()}`))

export const _noop: F0<any> = () => undefined
export const _anything: any = {}

export function call<T>(f?: () => T): T | undefined
export function call<T, T2>(f?: (arg: T) => T2, arg?: T): T2 | undefined
export function call<T, T2, T3>(f?: (arg: T, arg2: T2) => T3, arg?: T, arg2?: T2): T3 | undefined
export function call(f?: any, arg?: any, arg2?: any): void {
    if (isFunction(f)) return f(arg, arg2)
}

export const tap = (msg?: string) => <T>(obj: T): T => {
    // eslint-disable-next-line no-console
    console.log(msg, obj)
    return obj
}

// TODO Think about making `common`/`shared` folder for keeping all things shared between frontend and backend
export const cutString = (v: string, len: number, endString = "..."): string =>
    v.length < len ? v : v.slice(0, len - endString.length) + endString

export const cutNonLatinString = (v: string, latinLen: number, endString = "...", nonLatinLen = 15): string => {
    const isLatin = v.match(/[A-Za-z0-9]/)
    if (!isLatin) return cutString(v, nonLatinLen, endString)
    else return cutString(v, latinLen, endString)
}

export const inRange = <T extends number>(value: T, start: T, end: T) => value >= start && value <= end

export function ab2str(buf: ArrayBuffer) {
    let res = ""
    const bytes = new Uint8Array(buf)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
        res += String.fromCharCode(bytes[i])
    }
    return res
}

export function str2ab(s: string) {
    const buf = new ArrayBuffer(s.length)
    const bufView = new Uint8Array(buf)
    for (let i = 0, strLen = s.length; i < strLen; i++) {
        bufView[i] = s.charCodeAt(i)
    }
    return buf
}

export const primitiveCompareAsc = (a: any, b: any) => (a === b ? 0 : a > b ? 1 : -1)
export const primitiveCompareDesc = (a: any, b: any) => (a === b ? 0 : a < b ? 1 : -1)

type FSKey = keyof FirestoreSchema
type FSValue<T extends FSKey> = keyof ValueOf<FirestoreSchema[T]>

export function path<K extends FSKey>(node: K, doc: string): string
export function path<K extends FSKey, V extends FSValue<K>>(node: K, doc: string, subNode: V, subDoc: string): string
export function path(node: string, doc: string, subNode?: string, subDoc?: string) {
    return `/${node}/{${doc}}` + (subNode ? `/${subNode}/{${subDoc}}` : "")
}
