import { auth } from "firebase/app"
import { call } from "../../functions/src/utils"

export const init = () => {
    let _authUnsubscribe: F0 | undefined

    const instance = auth()
    const onAuthStateChanged = (callback: F1<firebase.User | null>) => {
        call(_authUnsubscribe)
        _authUnsubscribe = instance.onAuthStateChanged(callback)
        return _authUnsubscribe
    }

    const refreshToken = async () => {
        if (!instance.currentUser) return
        await instance.currentUser.getIdTokenResult(true)
        await instance.currentUser.reload()
    }

    const getCurrentUser = () => {
        return instance.currentUser
    }

    return {
        instance,
        getCurrentUser,
        onAuthStateChanged,
        refreshToken,
        sendSignInLinkToEmail: async (email: string, actionCodeSettings: auth.ActionCodeSettings) =>
            instance.sendSignInLinkToEmail(email, actionCodeSettings),
        signInWithEmailLink: async (email: string, emailLink: string | undefined) =>
            instance.signInWithEmailLink(email, emailLink),
        isSignInWithEmailLink: async (emailLink: string) => instance.isSignInWithEmailLink(emailLink),
        generateSignInWithEmailLink: async (
            email: string,
            actionCodeSettings: auth.ActionCodeSettings
        ): Promise<string> => (instance as any).generateSignInWithEmailLink(email, actionCodeSettings)
    }
}

export type AuthService = ReturnType<typeof init>
