// TODO Fix this
/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react"
import { connect } from "react-redux"
import { _BodyFont } from "../../styles/typography"
import { _FormViewWrapper } from "../../styles/forms"
import { _VerticalSpace } from "../../styles/common"
import { Button } from "../../styles/buttons"
import { validString, isOk } from "../../../functions/src/utils/validators"
import { FormView } from "../../components/form/FormView"
import { actions } from "../../store/auth/actions"
import { actions as cloudActions } from "../../store/cloud/actions"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { useFormHook } from "../../utils/hooks/useFormHook"
import { Loader } from "../../components/common/Loader"
import { MapState, MapDispatch } from "../../utils/redux.types"
import { isProcessing, isDone } from "../../../functions/src/utils/types"
import { isLoggedIn } from "../../models/LoginStatus"

type StateProps = {
    user: User | null
    actionsResults: SMap<CloudActionResult>
}
type ActionProps = {
    resetAuthState: F0
    updateProfile: F2<string, Pick<User, "displayName" | "userId">>
}

const displayNameSchema: FormSchema<Pick<User, "displayName">> = {
    displayName: { name: "Name", validators: validString, type: "text" }
}
export const DisplayNameChangeForm: React.FC<{
    user: User
    results: SMap<CloudActionResult>
    onSubmit: F1<Pick<User, "displayName" | "userId">>
}> = p => {
    const { formViewProps, onSubmitClick } = useFormHook({
        schema: displayNameSchema,
        onSubmit: v => p.onSubmit({ userId: p.user!.userId, displayName: v.displayName }),
        initialValue: {
            displayName: p.user.displayName
        }
    })
    return (
        <_FormViewWrapper>
            <FormView {...formViewProps} />
            <Button wide onClick={onSubmitClick}>
                Update profile
            </Button>
        </_FormViewWrapper>
    )
}

const EditProfilePure: React.FC<StateProps & ActionProps> = p => {
    if (!p.user) return null
    const [profileState, onUpdate] = useCloudAction(p.updateProfile, p.actionsResults, p.resetAuthState)

    React.useEffect(() => {
        p.resetAuthState()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    if (isProcessing(profileState)) return <Loader loadingText="Processing request" />

    if (isDone(profileState))
        return isOk(profileState.value.result) ? (
            <_BodyFont s14>Your profile has been updated</_BodyFont>
        ) : (
            <_BodyFont s14>Error occured: {profileState.value.result.value}</_BodyFont>
        )

    return (
        <>
            <DisplayNameChangeForm onSubmit={onUpdate} results={p.actionsResults} user={p.user} />
            <_VerticalSpace base="16px" />
        </>
    )
}

const mapState: MapState<StateProps> = ({ auth: { authentication }, cloud: { actionsResults } }) => ({
    actionsResults,
    user: isLoggedIn(authentication) ? authentication.user : null
})

const mapDispatch: MapDispatch<ActionProps> = d => ({
    updateProfile: (actionId, user) => d(cloudActions.updateProfile(actionId, user)),
    resetAuthState: () => d(actions.resetAuthStatuses())
})

export const EditProfile = connect(mapState, mapDispatch)(EditProfilePure)
