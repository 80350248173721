import React from "react"
import { _AbsPosElement } from "../../../styles/common"
import {
    _CardSectionContainer,
    _CardSectionTableContainer,
    _CardSectionTitle
} from "../../../components/newCards/sections/CardSection.styles"

export const PrioritizationWrapper = (p: Record<string, any>) => (
    <_CardSectionContainer big>
        <_CardSectionTitle>Prioritization criteria</_CardSectionTitle>
        <_CardSectionTableContainer>
            <_AbsPosElement>{p.children}</_AbsPosElement>
        </_CardSectionTableContainer>
    </_CardSectionContainer>
)
