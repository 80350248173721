import styled from "styled-components"
import { _FlexColumn, Grid, Flex } from "../../styles/common"
import { CardActionButton } from "../../components/newCards/Card.styles"
import { _BodyFont } from "../../styles/typography"

export const _LeadDescriptionWrapper = styled(_FlexColumn)`
    max-width: 480px;
`

export const ContactedButton = styled(CardActionButton)`
    color: #fff;
    background: rgb(0, 147, 238);
    &:hover {
        background: rgba(0, 147, 238, 0.6);
    }
`

export const LeadsTableWrapper = styled(Flex).attrs(() => ({ direction: "column" }))`
    ${_BodyFont} {
        padding: 0 16px;
    }
`

export const LeadContentWrapper = styled(Grid).attrs(() => ({
    columns: "1fr 2fr",
    gap: "34px"
}))``
