import styled from "styled-components"
import { ModalCloseButton } from "./Modal.styles"
import { styleIfProp, styleFromProp, Flex, _Center } from "../styles/common"

export const PopupContainer = styled(Flex)`
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
`

export const _Popup = styled(Flex)<{ full?: boolean }>`
    width: ${styleIfProp("full", "95vw", " 600px")};
    max-height: 90vh;
    border-radius: 8px;
    box-shadow: 0 1px 4px 0 rgba(44, 64, 90, 0.1);
    border: 1px solid ${({ theme }) => theme.colors.theme2};
    background-color: #fff;
    position: relative;
`

export const PopupInner = styled(Flex)<{ column?: boolean; minHeight?: number; noPadding?: boolean }>`
    ${styleIfProp("noPadding", "padding: 42px 0 22px;", "padding: 42px 60px 22px;")};
    ${p => styleFromProp(p.minHeight, `min-height: ${p.minHeight}vh;`)};
    align-content: stretch;
    width: 100%;
    flex: 1;
    flex-direction: ${styleIfProp("column", "column", "row")};
`

export const PopupHeader = styled(_Center)`
    padding-bottom: 12px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.theme2};
`

export const PopupContent = styled(_Center)<{ long?: boolean }>`
    padding: 12px 60px 22px 60px;
    flex: 1;
    ${styleIfProp("long", "justify-content: flex-start;")}
`

export const CloseButton = styled(ModalCloseButton)`
    position: absolute;
    top: 13px;
    right: 13px;
`

export const RadicleMiniLogo = styled(_Center)`
    border: 1px solid black;
    position: absolute;
    border-radius: 12px;
    box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.2);
    border: 1px solid ${({ theme }) => theme.colors.theme2};
    background-color: #fff;
    top: -32px;
    left: 0;
    right: 0;
    margin: auto;
    width: 64px;
    height: 64px;
    z-index: 11;
`
