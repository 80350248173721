import * as React from "react"
import { Button } from "../../styles/buttons"
import { _BodyFont } from "../../styles/typography"
import { _Center } from "../../styles/common"
import { FormView } from "../../components/form/FormView"
import { validBoolean, validEmail, validString } from "../../../functions/src/utils/validators"
import { useFormHook } from "../../utils/hooks/useFormHook"
import { Loader } from "../../components/common/Loader"
import { isProcessing, isNotStarted, isDone } from "../../../functions/src/utils/types"

export const isAdmin: Tuples<boolean> = [
    ["Normal", false],
    ["Admin", true]
]
export const isDeactivated: Tuples<boolean> = [
    ["Active", false],
    ["Deactivated", true]
]

export const editUserFormSchema = (isUpdate: boolean): FormSchema<UserEditPayload> => ({
    displayName: { type: "text", name: "Name", validators: validString },
    email: { type: "text", name: "Email", disabled: isUpdate, validators: validEmail },
    isAdmin: {
        name: "User type",
        type: "switch",
        validators: validBoolean,
        toValue: v => Boolean(v === "true"),
        values: isAdmin
    },
    isDeactivated: {
        name: "User status",
        type: "switch",
        validators: validBoolean,
        toValue: v => Boolean(v === "true"),
        values: isDeactivated
    }
})

type Props = {
    user: UserEditPayload
    actionName: string
    type: "create" | "update"
    state: AsyncAction<CloudActionResult>
    sendAction: F1<UserEditPayload>
}

const renderResult = (type: "create" | "update", res: CloudActionResult) => {
    switch (res.result.type) {
        case "Err":
            return <_BodyFont s14> We encountered an error: {res.result.value}</_BodyFont>
        case "Ok":
            return (
                <_BodyFont s14>
                    {type === "create"
                        ? "Done! User has been informed about the invitation"
                        : "Done! User updated successfully"}
                </_BodyFont>
            )
    }
}

export const RadarUserEdit: React.FC<Props> = p => {
    const { formViewProps, onSubmitClick } = useFormHook<UserEditPayload>({
        schema: editUserFormSchema(p.type === "update"),
        onSubmit: p.sendAction,
        initialValue: p.user
    })
    return (
        <_Center>
            {isNotStarted(p.state) && (
                <>
                    <FormView {...formViewProps} />
                    <Button onClick={onSubmitClick}>{p.actionName}</Button>
                </>
            )}
            {isProcessing(p.state) && <Loader />}
            {isDone(p.state) && renderResult(p.type, p.state.value)}
        </_Center>
    )
}
