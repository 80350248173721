import { remap, identity, values, pickObject } from "../../utils/map"
import { getCollectionSchema, getNameField, getIdField } from "../schemas"
import { isErr, isEmpty } from "../../utils/validators"
import { PreloadedCollections, PreloadedCollection, FileFields } from "../importing.types"

export const remapAssignmentsToNames = (
    searchAreas: SMap<SearchArea>,
    searchAreasAssignments: TMap<ObjectId, Assignments<AreaId, SearchAreaAssignmentKind>>
) => remap(searchAreasAssignments, identity, areaMap => remap(areaMap, aid => searchAreas[aid]?.name || aid, identity))

export const getIdFromRadarByName = <C extends CName>(
    cname: C,
    rcols: RadarCollections,
    value: string
): string | null => {
    if (!rcols[cname]) return null
    const { nameField, idField } = getCollectionSchema(cname)

    const resFromId = rcols[cname]![value]
    if (resFromId) return value
    const res = values(rcols[cname]).find((i: any) => i[nameField] === value)
    if (!res) return null
    return res[idField as keyof ValueOf<RadarCollections[C]>] as any
}

export const getIdFromPCByName = <C extends CName>(
    cname: C,
    pc: PreloadedCollections,
    value: string
): string | null => {
    if (!pc[cname]) return null
    const { nameField, idField } = getCollectionSchema(cname)
    const availableResults = (pc[cname]!.value as CollectionItem<C>[]).map(v => getPreloadedItemValues("fields", v))
    const resFromId = availableResults.find(i => (i[idField] as any) === value)
    if (resFromId) return value
    const res = availableResults.find(i => (i[nameField] as any) === value)
    if (!res) return null
    return (res[idField] as any) || "noId"
}

export const getPreloadedItemValues = <
    C extends CName,
    K extends keyof OmitStrict<CollectionItem<C>, "type" | "edited">
>(
    key: K,
    pi: OmitStrict<CollectionItem<C>, "type" | "edited">
): CollectionItem<C>[K] extends Result<infer T> ? T : CollectionItem<C>[K] => {
    const value = pi[key]
    return isErr(value) ? value.obj : value.value
}

export type RelationMap = SMap<string[]>
export type RelationArray = [string, string][]

export const toRelationArray = (ar: RelationTuple[]): RelationArray => ar.reduce((acc, el) => [...acc, el.relation], [])
export const toRelationMap = (list: RelationArray): RelationMap => {
    const res: RelationMap = {}
    list.forEach(([i1, i2]) => {
        res[i1] = !isEmpty(res[i1]) ? [...res[i1], i2].sort() : [i2]
        res[i2] = !isEmpty(res[i2]) ? [...res[i2], i1].sort() : [i1]
    })
    return res
}

export const getNameFromVM = <C extends CName>(cname: C, cols: RadarCollections, id: string): string | null => {
    if (isEmpty(cols[cname])) return null
    const res = cols[cname] as SMap<RCollection<C>>
    if (!res[id]) return null
    return res[id][getNameField(cname)] as any
}

export const getNameFromPC = <C extends CName>(cname: C, id: string, pc?: PreloadedCollection<C>): string | null => {
    if (!pc || isEmpty(pc.value)) return null
    const res = pc.value.find(v => (getPreloadedItemValues("fields", v)[getIdField(cname)] as any) === id)
    return res ? (getPreloadedItemValues("fields", res)[getNameField(cname)] as any) : null
}

export const mergeOverwriteFields = <C extends CName>(
    dbFields: RCollection<C>,
    overwriteFields: RCollection<C>,
    fieldsFromFile: FileFields<C>
): RCollection<C> => {
    const filteredOverwriteFields = pickObject(overwriteFields, fieldsFromFile as any)
    return { ...dbFields, ...filteredOverwriteFields }
}

export const mergeOverwriteClassifiers = <C extends CName>(
    dbClassifiers: ItemClassifiersInput,
    overwriteClassifiers: ItemClassifiersInput,
    fieldsFromFile: FileFields<C>
): ItemClassifiersInput => {
    const filteredOverwriteFields = pickObject(overwriteClassifiers, fieldsFromFile as any)
    return { ...dbClassifiers, ...filteredOverwriteFields }
}

export const mergeOverwriteRelations = <C extends CName>(
    dbRelations: RCollectionRelations<C>,
    overwriteRelations: RCollectionRelations<C>,
    fieldsFromFile: FileFields<C>
): RCollectionRelations<C> => {
    const filteredOverwriteFields = pickObject(overwriteRelations, fieldsFromFile as any)
    return { ...dbRelations, ...filteredOverwriteFields }
}
