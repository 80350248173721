import * as React from "react"
import { identity, remap } from "../../../functions/src/utils/map"
import { addDecoratorCells, addCommentCells, decoratorCellSizes, insertCells } from "../../models/collections"
import { HeaderTitle, mkRow } from "../../components/table/TableViewController"
import { getDefinitionForKey } from "./definitions"
import { Sections } from "../../components/tooltip/SectionController"
import { DecoratorsDisplayNames } from "../../../functions/src/models/decoratorValues"
import { getCollectionSchema } from "../../../functions/src/models/schemas"
import { schemas } from "./ListSchemas"
import { collectionsVMConverters } from "../../../functions/src/models/collections"
import {
    ViewModelsMap,
    ViewModelsBase,
    DisplayModelsMap,
    DisplayConverter
} from "../../../functions/src/models/ViewModels"
import { UIState } from "../../store/store"
import { SectionSchema } from "../../components/tooltip/Sections.types"
import { RowSchema, HeaderCell, Row } from "../../components/table/TableView.types"
import { assertNever } from "../../../functions/src/utils"
import { IconSvg } from "../../components/IconSvg"
import { Flex } from "../../styles/common"

export const filterTooltipSections = <C extends CName>(o: {
    isRoot: boolean
    isDemo: boolean
    config: LocationParams
}) => (s: SectionSchema<ViewModelsMap[C]>) => {
    switch (s.type) {
        case "s-assignment":
            return !o.isRoot && !o.isDemo && o.config.withAssignments
        case "s-segmentTags":
            return o.config.withSegmentTags
        case "s-badges":
        case "s-comment":
        case "s-description":
        case "s-tags":
            return true
    }
    assertNever(s)
}

export const getTooltipContents = <C extends CName>(
    cname: C,
    cs: SMap<ViewModelsMap[C]>,
    vms: Pick<
        ViewModelsBase,
        | "searchAreas"
        | "searchAreasAssignments"
        | "tags"
        | "tagsAssignments"
        | "segmentTags"
        | "segmentTagsAssignments"
        | "users"
        | "decorators"
        | "comments"
    >,
    isRoot: boolean,
    isDemo: boolean,
    config: LocationParams
) =>
    remap(cs, identity, (v, id) =>
        Sections(
            (schemas[cname].tooltipSchema as SectionSchema<ViewModelsMap[C]>[]).filter(
                filterTooltipSections({ isRoot, isDemo, config })
            ),
            v,
            id,
            vms
        )
    )

export const getHeaderRow = <
    T extends any,
    ID extends (keyof T & string) | "assignment" | "star" | "comments" | "pipelineStage" | "priorityRank"
>(p: {
    isDemo: boolean
    flags: ConfigFeatureFlags
    cname: CName
    isRoot: boolean
    ui: UIState
    rowSchema: RowSchema<T>
    withDecorators?: boolean
    withComments?: boolean
}): HeaderCell<ID>[] => {
    const { withComments = true, withDecorators = true } = p
    const headerDecoratorCells: HeaderCell<ID>[] = []
    if (!p.isDemo && !p.isRoot && p.flags.withAssignments)
        headerDecoratorCells.push(
            HeaderTitle("assignment" as ID, "Relationship manager", decoratorCellSizes.assignment, "assignment")
        )

    if (p.ui.filters.searchArea) {
        const decoratorsToAttach: ListDecorators[] = []
        if (!p.isDemo && !p.isRoot) decoratorsToAttach.push("pipelineStage")
        if (p.flags.withPriorityRanks && !p.isDemo) decoratorsToAttach.push("priorityRank")

        decoratorsToAttach.forEach(dec =>
            headerDecoratorCells.push(HeaderTitle(dec as ID, DecoratorsDisplayNames[dec], decoratorCellSizes[dec], dec))
        )
    }
    if (!p.isRoot && !p.isDemo && withComments)
        headerDecoratorCells.push(HeaderTitle("comments" as ID, "", decoratorCellSizes.comments, ""))
    if (p.flags.withStars && !p.isDemo)
        headerDecoratorCells.push(
            HeaderTitle(
                "star" as ID,
                <Flex align="center" justify="center" grow={1}>
                    <IconSvg name="star" width={15} height={14} color="theme15" />
                </Flex>,
                decoratorCellSizes.star,
                "star"
            )
        )

    const schema = p.rowSchema.map(v =>
        HeaderTitle(v.key as ID, v.label, v.size, v.key as any, getDefinitionForKey(p.cname, v.key as any))
    )

    if (withDecorators) return insertCells(p.cname, schema, headerDecoratorCells) as HeaderCell<ID>[]
    else return schema
}

export const getRow = <C extends CName>(p: {
    rowSchema: RowSchema<DisplayModelsMap[C]>
    cname: C
    decoratorsToAttach: ListDecorators[]
    deps: Pick<ViewModelsBase, "decorators" | "pipelines" | "users">
    isDemo: boolean
    isRoot: boolean
    searchAreaId?: string
    withComments?: boolean
}) => (item: ViewModelsMap[C]): Row => {
    const { withComments = true } = p
    const schema = getCollectionSchema(p.cname)
    const idKey = schema.idField as keyof ViewModelsMap[C]
    const converter = collectionsVMConverters[p.cname] as DisplayConverter<C>
    const baseRow = mkRow<DisplayModelsMap[C]>(
        item[idKey] as any,
        p.rowSchema,
        converter(item),
        p.isDemo && item.blurOnListing ? ["blurred", "disabled"] : []
    )
    const decoratedRow = addDecoratorCells(p.decoratorsToAttach, p.deps, p.cname, p.searchAreaId || "")(baseRow)
    return !p.isRoot && !p.isDemo && withComments ? addCommentCells(item)(decoratedRow) : decoratedRow
}
