import * as React from "react"
import { _Center } from "../../styles/common"
import { ExportList } from "../../containers/ExportList"
import { Popup } from "../Popup"
import { PopupInner } from "../Popup.styles"

type OwnProps = { onClose: F0 }

export const ExportPopup: React.FC<OwnProps> = p => (
    <Popup logo onClose={p.onClose} full>
        <PopupInner>
            <_Center>
                <ExportList />
            </_Center>
        </PopupInner>
    </Popup>
)
