import { exhaustiveStringTuple } from "../utils/types"
import {
    validStringDef,
    validNumberDef,
    validNumberDefNull,
    validBooleanDef,
    validString,
    validMapDef
} from "../utils/validators"
import { CollectionVMMeta } from "./ViewModels"

// HERE WE PUT ONLY STATIC VARIABLES OR FUNCTIONS THAT ONLY USE
// - TYPES
// - VALIDATORS
// - UTILS

export const DEFAULT_PLACEHOLDER = "–"

export const classifiersFields: (keyof ItemClassifiersInput)[] = exhaustiveStringTuple<keyof ItemClassifiersInput>()(
    "primary_search_areas",
    "secondary_search_areas",
    "tags",
    "segment_tags"
)

export const DATA_SEPARATOR = "; "
export const DATA_PRESENTER = ": "
export const sharedRequiredRadarFieldsValidationMap: ValidationMap<Required<SharedRequiredFields>> = {
    source: validString
}

export const sharedOptionalRadarFieldsValidationMap: ValidationMap<Required<SharedOptionalFields>> = {
    description: validStringDef
}

export const sharedVMMetaValidationMap: ValidationMap<Required<CollectionVMMeta>> = {
    commentsCount: validNumberDef,
    contactedLeadsCount: validNumberDef,
    blurOnListing: validBooleanDef,
    filterBounds: validMapDef(validNumberDefNull)
}
