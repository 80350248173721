/*
"Startup Name","Crunchbase URL","Overall Scoring::Strat. Relevance","Overall Scoring::Scale","Overall Scoring::Technology","Overall Scoring::Usability","Overall Scoring::Overall","Strategic Relevance::Predictive/Personalized Insights","Strategic Relevance::Behavior & Emotions","Scale::Users/Subscribers","Scale/Number of Markets"
"Miaofang Qingyan","http://crunchbase.com/1",HIGH,MEDIUM,HIGH,HIGH,HIGH,HIGH,MEDIUM,"50k+ units sold, 6k subscribers",Undisclosed
ParamERP Solutions India Pet PBL,HIGH,HIGH,MEDIUM,HIGH,HIGH,HIGH,MEDIUM,Undisclosed,"Not currently commercial"
Kensta Group,MEDIUM,MEDIUM,HIGH,MEDIUM,HIGH,HIGH,MEDIUM,Undisclosed,"US, Brazil, Canada, Japan, Europe"
*/

import { parse } from "papaparse"
import { Ok, Err } from "../../functions/src/utils/validators"

export type ColumnHierarchy = { [index: string]: string[] }
type RankPoint = "*HIGH" | "*MEDIUM" | "*LOW"

type DataRow = (RankPoint | string)[]
type Prioritization = {
    hierarchy: ColumnHierarchy
    data: DataRow[]
}

const buildHierarchy = (head: string[]): ColumnHierarchy => {
    const hierarchy: ColumnHierarchy = {}

    // The first 2 columns are name and crunchbase url. Don't build categories out of that.
    for (const header of head.slice(2)) {
        const [group, cat] = header.split("::")
        const cols = hierarchy[group] || []
        cols.push(cat)
        hierarchy[group] = cols
    }

    return hierarchy
}
export const loadPrioritizationFrom = (inputRows: string[]): Result<Prioritization> => {
    const result = parse(inputRows.join("\n"))

    if (result.errors.length > 0) {
        return Err("Parse error")
    }

    const [head, ...rows] = result.data
    return Ok({
        hierarchy: buildHierarchy(head),
        data: rows
    })
}
