import { asyncForEach } from "."
import { FieldsOf } from "../models/importing.types"
import { isNil } from "lodash/fp"

export const isKeyOf = <T, TK extends keyof T>(key: TK | string, v: T): key is TK => keys(v).includes(key as any)

export const keys = <T>(t: T): (keyof T)[] => (t && typeof t === "object" ? (Object.keys(t) as any) : [])
export const values = <T>(t: T) => keys(t || (({} as any) as T)).map(k => t[k])

export const iterateMap = <T, TK extends string | number = string>(
    map: TMap<TK, T>,
    cb: (key: TK, v: T, index: number) => void
) => keys(map).forEach((key, index) => cb(key, map[key], index))

export const mapObject = <T, TV2 extends Casted<T, any>>(
    o: T,
    toValue: <TKey extends keyof T>(key: TKey, value: T[TKey], index: number) => TV2[TKey]
): TV2 => {
    const res: TV2 = {} as any
    keys(o).forEach((k, i) => (res[k] = toValue(k, o[k], i)))
    return res
}

export const usleep = (ms = 1000) => new Promise(resolve => setTimeout(() => resolve(), ms))

export const asyncMap = async <T, S>(vs: T[], cb: (val: T, i: number) => Promise<S>, delay = 0): Promise<S[]> => {
    const result: S[] = []
    for (let i = 0; i < vs.length; i++) {
        await usleep(delay)
        result.push(await cb(vs[i], i))
    }
    return result
}
export const asyncMapObject = async <T, TV2 extends Casted<T, any>>(
    o: T,
    toValue: <TKey extends keyof T>(key: TKey, value: T[TKey]) => Promise<TV2[TKey]>
): Promise<TV2> => {
    const res: TV2 = {} as any
    await asyncForEach(keys(o), async k => (res[k] = await toValue(k, o[k])))
    return res
}

export const remap = <T, S = any>(
    vs: SMap<T>,
    getKey: F3<string, T, number, string>,
    getValue: F3<T, string, number, S>,
    filterValue?: F2<string, S, boolean>
): SMap<S> => {
    if (!vs) return {} as any
    const res: SMap<S> = {} as any
    keys(vs).forEach((k, index) => {
        const value = getValue(vs[k], k, index)
        if (!filterValue || filterValue(k, value)) res[getKey(k, vs[k], index)] = value
    })
    return res
}

export const groupBy = <T, T2 = T>(ts: T[], toKey: F2<T, number, string | null>, toValue: F1<T, T2>): SMap<T2[]> => {
    const res: SMap<T2[]> = {}
    ;(ts || []).forEach((t, index) => {
        const key = toKey(t, index)
        if (!key) return
        if (!res[key]) res[key] = []
        res[key].push(toValue(t))
    })
    return res
}
type MapOptions = { skipNullKeys?: boolean; skipNullValues?: boolean }
export const toMap = <E, V = E, K extends string = string>(
    ts: E[],
    toKey: F2<E, number, K>,
    toValue: F1<E, V>,
    options: MapOptions = {}
): TMap<K, V> => {
    const res: TMap<K, V> = {} as any
    ;(ts || []).forEach((t, index) => {
        const k = toKey(t, index)
        const v = toValue(t)
        if (options.skipNullKeys && k === null) return
        if (options.skipNullValues && v === null) return
        res[k!] = v
    })
    return res
}

export const filterObject = <K extends string, V>(
    o: TMap<K, V>,
    condition: (key: K, value: V) => boolean
): TMap<K, V> => {
    const result: TMap<K, V> = {} as any
    const f = (field: K) => {
        if (condition(field, o[field] as V)) result[field] = o[field]
    }
    Object.keys(o || {}).forEach(f)
    return result
}

export const partializeObjectByKeys = <T>(o: T, ks: FieldsOf<T>): Partial<T> => {
    const res: Partial<T> = {}
    keys(o).forEach(k => {
        if (ks.includes(k)) res[k] = o[k]
    })
    return res
}

export const copyDefinedFields = <T, V extends T>(keysSrc: Casted<T, any>, src: V, delta: Partial<T> = {}): T => {
    const res: T = {} as any
    keys(keysSrc).forEach(k => (res[k] = src[k]))
    return { ...res, ...delta }
}

export const sortByOrder = <T>(arr: T[], getOrder: F1<T, number>) => {
    const newArr = arr.filter(i => getOrder(i) === -1)
    arr.filter(i => getOrder(i) !== -1)
        .sort((i1, i2) => getOrder(i1) - getOrder(i2))
        .forEach(i => newArr.splice(getOrder(i), 0, i))
    return newArr
}

export const toArray = <T extends SMap<any>, K extends keyof T & string, V extends ValueOf<T>, NV = V>(
    map: T,
    toValue: (t: V, key: K, index: number) => NV = v => (v as any) as NV
) => {
    const result: NV[] = []
    keys(map || {}).forEach((field, index) => result.push(toValue(map[field], field as K, index)))
    return result
}

export const extend = <T>(o: T) => (delta: Partial<T>): T => Object.assign({}, o, delta)
export const extendSkipNulls = <T>(o: T) => (delta: Partial<T>): T => {
    const filtered = filterObject(delta, (_, v) => !isNil(v))
    return Object.assign({}, o, filtered)
}
export const extMap = <T>(vs: SMap<T>, id: keyof SMap<T>, v: T) => Object.assign({}, vs, { [id]: v })

export const factory = <T>(defaults: T) => (params: Partial<T> = {}) =>
    (({ ...(defaults as any), ...(params as any) } as any) as T)

export const toRegExp = (query: string): RegExp => {
    try {
        return new RegExp("^" + query)
    } catch (e) {
        return new RegExp(query.replace(/^[.*+?^${}()|[\]\\]/g, "\\$&"))
    }
}

export const joinArrays = <T>(arr1: T[], arr2: T[], compare: (a: T, b: T) => boolean = (a, b) => a === b): T[] => {
    const res: T[] = [...arr1]
    arr2.forEach(a2 => {
        if (!arr1.find(a1 => compare(a1, a2))) res.push(a2)
    })
    return res
}

export const areArraysSame = <T>(
    arr1: T[],
    arr2: T[],
    compare: (a: T, b: T) => boolean = (a, b) => a === b
): boolean => {
    const s1 = arr1.sort()
    const s2 = arr2.sort()
    return arr1.length === arr2.length && s1.every((e, i) => compare(s2[i], e))
}

const isArray = <T>(v: any | T[]): v is T[] => Array.isArray(v)
export const flatten = <T>(vs: (T | T[])[], depth = 2, filter: (v: T | T[]) => boolean = () => true): T[] =>
    vs.reduce(
        (acc: any, toFlatten) =>
            isArray(toFlatten) && depth - 1 > 0
                ? acc.concat(flatten(toFlatten, depth - 1, filter))
                : acc.concat(isArray(toFlatten) ? toFlatten : filter(toFlatten) ? [toFlatten] : []),
        [] as T[]
    )

export const replace = <T>(vs: T[], index: number, v: T): T[] => {
    if (!vs || index >= vs.length || index < 0) return vs
    const cp = [...vs]
    cp[index] = v
    return cp
}

export const arrify = <T>(ts: T[] | T): T[] => (Array.isArray(ts) ? ts : [ts])

export const match = <T>(cond: F1<T, boolean>) => (map: SMap<T>): T | null => values(map).find(cond) || null

export const matchOnValue = <T>(fname: keyof T) => (map: SMap<T>, v: T[typeof fname]): T | null =>
    match<T>(e => e[fname] === v)(map)

export const pickObject = <T, K extends keyof T>(source: T, keysToPick: K[]): Pick<T, K> => {
    const res: Pick<T, K> = {} as any
    keysToPick.forEach(k => {
        if (k in source) res[k] = source[k]
    })
    return res
}

export const omitObject = <T, K extends keyof T>(source: T, keysToOmit: K[]): OmitStrict<T, K> => {
    const res: T = {} as any
    ;(keys(source) as K[]).filter(k => !keysToOmit.includes(k)).forEach(k => (res[k] = source[k]))
    return res
}

export const pickIntersect = <T2>() => <T, K extends keyof Intersect<T, T2>>(source: T, keysToPick: K[]) =>
    pickObject(source, keysToPick)

export const relativeComplement = <T>(a1: T[], a2: T[]) => a1.filter(id => !a2.includes(id))

export const intersect = <T>(a1: T[], a2: T[]): T[] =>
    Array.from(new Set([...a1.filter(id => a2.includes(id)), ...a2.filter(id => a1.includes(id))]))

export const identity = <T>(arg: T): T => arg

export const mapOptionsMapToValuesMap = <C, T>(optionsMap: Casted<C, ROptions<T>>): Casted<C, T[]> =>
    mapObject(optionsMap, (_, options) => (options || []).map(o => o.value))

export const mapAreasToAssignments = (
    primarySearchAreas: string[],
    secondarySearchAreas: string[]
): Assignments<AreaId, SearchAreaAssignmentKind> => ({
    ...toMap(secondarySearchAreas, identity, () => "secondary"),
    ...toMap(primarySearchAreas, identity, () => "primary")
})

export const mapStringsToAssignments = (existingAreas: string[]): Assignments =>
    toMap(existingAreas, identity, () => true)

export const unique = <T extends string | number>(arr: T[]) => Array.from(new Set([...arr]))

export const last = <T>(a: T[]): T | null => (a.length > 0 ? a[a.length - 1] : null)

export const insert = <T>(i: number, item: T | T[]) => (arr: T[]): T[] => [
    ...arr.slice(0, i),
    ...arrify(item),
    ...arr.slice(i)
]
