import styled from "styled-components"
import { Flex } from "../../styles/common"
import { _TabContentWrapper } from "../../components/tabs/Tabs.styles"
import { _LayoutContainer } from "../../components/Page"

export const _RadarViewWrapper = styled(Flex)`
    flex-grow: 1;
`
export const _RadarDetails = styled(Flex)`
    flex-grow: 1;
    flex-direction: column;
    max-width: calc(100% - 208px);

    & > ${_TabContentWrapper} {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        position: relative;

        & > ${_LayoutContainer} {
            position: relative;
        }
    }
`

export const _RadarTabContainer = styled(Flex)`
    flex-grow: 1;
`

export const _DataContainer = styled(Flex)<{ overflowHidden?: boolean }>`
    flex-grow: 1;
    overflow: ${p => (p.overflowHidden ? "hidden" : "auto")};
    position: relative;
    padding: 0 25px;

    & > * {
        position: absolute;
        width: calc(100% - 50px);
        min-width: calc(100% - 50px);
        min-height: 100%;
    }
`
