import styled, { css } from "styled-components"
import { styleIfProp, Flex, _FlexContainer } from "../../styles/common"

type Legacy = { legacy: boolean }
type TabProps = { disabled?: boolean; selected: boolean; onClick: F0 } & Legacy
export const _Tab = styled.button<TabProps>`
    ${styleIfProp(
        "legacy",
        css<TabProps>`
            position: relative;
            color: rgb(44, 64, 90);
            background: transparent;
            border: none;
            outline: none;
            font-size: 11px;
            letter-spacing: 2px;
            padding: 10px 8px;
            cursor: pointer;
            font-size: 11px;
            text-transform: uppercase;
            color: #2c405a;
            padding: 10px 8px;

            &:disabled {
                cursor: default;
                color: rgba(44, 64, 90, 0.5);
            }
            &:after {
                position: absolute;
                content: "";
                bottom: 0;
                left: 0;
                right: 0;
                height: 2px;
                background: ${({ selected }) => (selected ? "#2c405a" : "white")};
            }
        `,
        css<TabProps>`
            position: relative;
            border: none;
            outline: none;
            font-family: Lato, sans-serif;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.88px;
            text-transform: uppercase;
            background: ${({ selected }) => (selected ? "#ecf5fd" : "white")};
            color: #2c405a;
            height: 59px;
            padding: 20px;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin: 0;

            &:disabled {
                cursor: default;
                color: rgba(44, 64, 90, 0.5);
            }
        `
    )}
`

type TabContentWrapperProps = { scrollable?: boolean }
export const _TabContentWrapper = styled(_FlexContainer)<TabContentWrapperProps>`
    position: relative;
    ${styleIfProp("scrollable", "overflow: auto;")};
    flex-grow: 1;
`

export const _TabSelector = styled(Flex)<Legacy>`
    position: relative;

    ${styleIfProp(
        "legacy",
        css`
            flex-shrink: 0;
            border-bottom: 1px solid #979797;
        & > ${_Tab} + ${_Tab} {
            border: none;
        }
    }
        `,
        css`
            align-items: center;
            & > ${_Tab} + ${_Tab} {
                border-left: solid 0.5px rgba(88, 105, 126, 0.28);
            }

            // Could not use overflow: auto, because of hiddenSubMenu
            & > :first-child {
                border-radius: 12px 0 0 12px;
            }

            & > ${_Tab}:not([style*="display: none"]):last-of-type {
                border-radius: 0 12px 12px 0;
            }
        `
    )}
`

export const _TabSubmenuContainer = styled(_FlexContainer)<{ open: boolean }>`
    position: absolute;
    display: ${p => (p.open ? "flex" : "none")};
    right: 0;
    top: 100%;
    margin-top: 7px;
    border-radius: 12px;
    box-shadow: 0 2px 4px 0 rgba(44, 64, 90, 0.07);
    border: solid 0.2px #d1d5db;
    background-color: white;
    overflow: hidden;
    z-index: 4;

    & > * {
        padding: 17px 24px;
    }
`
