import { _noop } from "../../functions/src/utils"
import React from "react"
import styled from "styled-components"
import { _HorizontalSpace, styleFromProp, Flex, FlexProps } from "../styles/common"
import { Logo } from "./common"
import { _DropdownTrigger } from "./Dropdown.styles"

export const _Headline = styled.h2`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    font-size: 16px;
    line-height: 22px;
    font-weight: normal;
    color: #fff;
`

export const Divider = styled.span`
    margin: 2px 0;
`

export const HeaderTop = styled.div`
    height: 56px;
    padding: 0 15px;
    background-color: ${p => p.theme.colors.primary};
    display: flex;
    align-items: center;
`

export const FILTER_BAR_HEIGHT = 66
export const HEADER_HEIGHT = 56

export const _FilterBarContainer = styled(Flex).withConfig<{ wrap?: boolean } & FlexProps>({
    shouldForwardProp: (p, d) => !["wrap"].includes(p) && d(p)
})`
    position: sticky;
    /* Hack for 4k screens 1px transparent bar... */
    top: ${HEADER_HEIGHT - 1}px;
    padding: 15px 15px 7px;
    flex-shrink: 0;
    z-index: 5;
    background: #fff;

    ${p => (p.wrap ? `flex-wrap: wrap;` : `height: ${FILTER_BAR_HEIGHT + 1}px;`)};

    & > * {
        margin-bottom: 8px;

        &:not(:last-child) {
            margin-right: 8px;
        }
    }

    ${_DropdownTrigger} {
        min-width: 50px;
    }
`

export const _HeaderContainer = styled.div.attrs(() => ({
    "data-cy": "_HeaderContainer"
}))<{ noBorder?: boolean }>`
    ${p => styleFromProp(!p.noBorder, `border-bottom: solid 1px ${p.theme.colors.theme4};`)}
    @media print {
        display: none;
    }
    position: sticky;
    top: -1px;
    z-index: 6;
`

export const _LogoButton = styled.div`
    ${p => p.onClick && "cursor: pointer;"};
`

export const Header: React.FC<{
    hideLogo?: boolean
    noLogo?: boolean
    hideBorder?: boolean
    onClickLogo?: F0
}> = ({ children, hideLogo, hideBorder, onClickLogo = _noop }) => (
    <_HeaderContainer noBorder={hideBorder}>
        <HeaderTop data-cy="header-top">
            {hideLogo ? null : (
                <>
                    <_LogoButton onClick={onClickLogo}>
                        <Logo white width={91} height={28} />
                    </_LogoButton>
                    <_HorizontalSpace base="23px" />
                </>
            )}
            {children}
        </HeaderTop>
    </_HeaderContainer>
)
