import { ContentBlock, EditorState } from "draft-js"

export const getSelectedBlockType = (state: EditorState) => {
    const selection = state.getSelection()
    const block = state.getCurrentContent().getBlockForKey(selection.getStartKey())
    return block && block.getType()
}

export const getSelectedEntityType = (state: EditorState) => {
    const selection = state.getSelection()
    const content = state.getCurrentContent()
    const entityKey = content.getBlockForKey(selection.getStartKey()).getEntityAt(selection.getStartOffset())
    return entityKey && content.getEntity(entityKey).getType()
}

export const getBlockEntityRange = (block: ContentBlock, entityKey: string): [number, number] | null => {
    let range = null

    block.findEntityRanges(
        charData => {
            const ek = charData.getEntity()
            if (!ek) return false
            return ek === entityKey
        },
        (start, end) => {
            range = [start, end]
        }
    )

    return range
}

export const selectionContainsEntity = (editorState: EditorState, predicate: (e: Draft.EntityInstance) => boolean) => {
    const content = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    const blockMap = content.getBlockMap()
    const startKey = selection.getStartKey()
    const endKey = selection.getEndKey()
    const startOffset = selection.getStartOffset()
    const endOffset = selection.getEndOffset()
    return blockMap
        .skipUntil((_, k) => k === startKey)
        .takeUntil((_, k) => k === endKey)
        .concat(new Map([[endKey, blockMap.get(endKey)]]))
        .some((block: ContentBlock) => {
            let charList = block!.getCharacterList()
            if (block.getKey() === startKey) charList = charList.slice(startOffset).toList()
            if (block.getKey() === endKey) charList = charList.slice(0, endOffset).toList()
            return charList.some(c => {
                const entityKey = c!.getEntity()
                return !!entityKey && predicate(content.getEntity(entityKey))
            })
        })
}
