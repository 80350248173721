import {
    validString,
    validStringDef,
    validNumberDef,
    validate,
    validNumber,
    validArrayStringDef,
    validDef,
    validateNumber,
    isArray,
    validateOr,
    validArrayDef,
    validateSpecialNumber,
    validateURLStartingWithHTTP,
    isString
} from "../utils/validators"
import { mapToDisplay, validateFileMetadata, parseString } from "./common"
import { formatDecimal, formatMillions, specialValues } from "./converters"
import { RFields } from "./RFields"
import {
    DEFAULT_PLACEHOLDER,
    sharedRequiredRadarFieldsValidationMap,
    sharedOptionalRadarFieldsValidationMap,
    sharedVMMetaValidationMap
} from "./shared"
import { StartupVM, DisplayModelsMap } from "./ViewModels"

export const startupRequiredFieldsValidationMap: ValidationMap<Required<StartupFieldsRequired>> = {
    startup_name: validString,
    ...sharedRequiredRadarFieldsValidationMap
}
export const startupOptionalFieldsValidationMap: ValidationMap<Required<StartupFieldsOptional>> = {
    ...sharedOptionalRadarFieldsValidationMap,
    stage: validStringDef,
    startup_completed: validDef(1, validateNumber),
    crunchbase_url: validStringDef,
    funding: validDef(specialValues["–"], validateSpecialNumber(specialValues)),
    investor_quality: validNumberDef,
    headquarters: validStringDef,
    funding_momentum: validDef(specialValues["–"], validateSpecialNumber(specialValues)),
    homepage_url: validStringDef,
    img_fallback: validDef(
        "",
        validateOr<string>([validDef("", validateURLStartingWithHTTP), validArrayDef([validateFileMetadata])])
    ),
    lead_investors: validArrayStringDef,
    logo_url: validStringDef,
    founders_and_executives: validArrayStringDef
}

export const startupFieldsValidation: ValidationMap<Required<StartupFields>> = {
    ...startupRequiredFieldsValidationMap,
    ...startupOptionalFieldsValidationMap
}

export const startupFields: CFields<"startups"> = RFields<StartupFieldsRequired, StartupFieldsOptional>(
    startupRequiredFieldsValidationMap,
    startupOptionalFieldsValidationMap
)

const startupRequiredRelationsValidationMap: ValidationMap<Required<StartupRelationsRequired>> = {}

const startupOptionalRelationsValidationMap: ValidationMap<Required<StartupRelationsOptional>> = {
    influencers: validArrayStringDef,
    investors: validArrayStringDef,
    patents: validArrayStringDef,
    research_hubs: validArrayStringDef,
    research_papers: validArrayStringDef,
    tech_experts: validArrayStringDef,
    tech_transfers: validArrayStringDef,
    sectors: validArrayStringDef
}

export const startupRelations: RFields<"startups"> = RFields<StartupRelationsRequired, StartupRelationsOptional>(
    startupRequiredRelationsValidationMap,
    startupOptionalRelationsValidationMap
)

export const validateStartupFields = validate<StartupFields>(startupFieldsValidation)
export const startupRelationsValidationMap: ValidationMap<Required<StartupRelations>> = {
    ...startupRequiredRelationsValidationMap,
    ...startupOptionalRelationsValidationMap
}
export const validateStartupRelations = validate<StartupRelations>(startupRelationsValidationMap)
export const startupValidationMap: ValidationMap<Required<Startup>> = {
    ...startupFieldsValidation,
    startupId: validString,
    createdTs: validNumber
}
export const validateStartup = validate<Startup>(startupValidationMap)
export const validateStartupVM = validate<StartupVM>({
    ...startupValidationMap,
    ...sharedVMMetaValidationMap,
    all_investors: validArrayStringDef,
    sectors: validArrayStringDef
})

const extractUrlFromFileMetadata = (v: FileMetadata | string): string => (!v || isString(v) ? v : v.url)

export const transformStartup = (c: StartupVM): DisplayModelsMap["startups"] => ({
    ...c,
    funding: formatMillions(c.funding, false),
    funding_momentum: formatDecimal(c.funding_momentum),
    investor_quality: formatDecimal(c.investor_quality, undefined, undefined, true),
    img_fallback: isArray(c.img_fallback) ? extractUrlFromFileMetadata(c.img_fallback[0]) : c.img_fallback,
    description: parseString(c.description)
})

export const displayStartup = (c: StartupVM, ph: string = DEFAULT_PLACEHOLDER): Displayable<StartupVM> =>
    mapToDisplay(c, ph)

export const StartupFieldsDisplay: Displayable<StartupVM> = {
    startup_name: "Startup name",
    crunchbase_url: "Crunchbase URL",
    funding: "Funding ($m)",
    headquarters: "Headquarters",
    homepage_url: "Homepage URL",
    investor_quality: "Investor quality (Percentile)",
    lead_investors: "Lead investors",
    stage: "Stage",
    description: "Description",
    source: "Source",
    startup_completed: "Is startup completed?",
    funding_momentum: "Funding Momentum (Percentile)",
    logo_url: "Logo url",
    img_fallback: "Image fallback",
    sectors: "Sectors",
    startupId: "ID",
    createdTs: "Created at",
    all_investors: "All Investors",
    founders_and_executives: "Founders and executives",
    commentsCount: "No. of comments",
    contactedLeadsCount: "No. of contacted leads",
    blurOnListing: "",
    filterBounds: ""
}
