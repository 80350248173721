import { DEFAULT_PLACEHOLDER } from "../../../functions/src/models/shared"
import {
    TableTitleSchema,
    TableTextSchema,
    TableNumberSchema,
    TableCommentBubbleSchema,
    TableComputedSchema,
    TableIconTitleSchema,
    TablePillSchema,
    TableFileSchema,
    CellSize
} from "./TableView.types"
import { _noop } from "../../../functions/src/utils"

export const mkTableTitleSchema = <T>(
    label: string,
    key: keyof T & string,
    size = { min: 200 }
): TableTitleSchema<T> => ({
    type: "ts-title",
    label,
    key,
    size
})

export const mkTableTextSchema = <T>(
    label: string,
    key: keyof T & string,
    size = { min: 200 },
    placeholder = ""
): TableTextSchema<T> => ({
    type: "ts-text",
    label,
    key,
    size,
    placeholder
})

export const mkTableNumberSchema = <T>(
    label: string,
    key: keyof T & string,
    size = { min: 200 },
    placeholderIfZero: string = DEFAULT_PLACEHOLDER,
    decimals?: number
): TableNumberSchema<T> => ({
    type: "ts-number",
    label,
    key,
    placeholderIfZero,
    size,
    decimals
})

export const mkTableCommentBubbleSchema = <T>(key: keyof T & string): TableCommentBubbleSchema<T> => ({
    type: "ts-comment",
    key,
    label: "",
    getValue: _noop,
    size: { min: 0, max: 0 }
})

export function mkTableComputedSchema<T>(
    label: string,
    key: keyof T & string,
    type: "number",
    getValue: F1<T, number>,
    size?: CellSize
): TableComputedSchema<T>
export function mkTableComputedSchema<T>(
    label: string,
    key: keyof T & string,
    type: "text" | "title",
    getValue: F1<T, string>,
    size?: CellSize
): TableComputedSchema<T>
export function mkTableComputedSchema<T>(
    label: string,
    key: keyof T & string,
    type: "text" | "title" | "number",
    getValue: F1<T, any>,
    size = { min: 200 }
): TableComputedSchema<T> {
    if (type === "title") return { type: "ts-computed-title", key, label, getValue, size }
    if (type === "number") return { type: "ts-computed-number", key, label, getValue, size }
    return { type: "ts-computed-text", key, label, getValue, size }
}

export const mkTableIconTitleSchema = <T>(
    label: string,
    key: keyof T & string,
    urlKeys: (keyof T & string)[],
    size = { min: 200 }
): TableIconTitleSchema<T> => ({
    type: "ts-icon-title",
    label,
    key,
    urlKeys,
    size
})

export const mkTablePillSchema = <T>(
    label: string,
    key: keyof T & string,
    size = { min: 200 }
): TablePillSchema<T> => ({
    type: "ts-pill",
    key,
    label,
    size
})
export const mkTableFileSchema = <T>(
    label: string,
    key: keyof T & string,
    size = { min: 200 }
): TableFileSchema<T> => ({
    type: "ts-file",
    key,
    label,
    size
})
