// TODO Fix this
/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react"
import CreatableSelect from "react-select/creatable"

import { getTagSelectStyles } from "../reactSelectUtils"
import { connect } from "react-redux"
import { actions } from "../../store/cloud/actions"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { keys, filterObject } from "../../../functions/src/utils/map"
import { Loading, Loaded, toOption } from "../../../functions/src/utils/types"
import { getCurrentRadarId } from "../../models/LocationType"
import { getMapDispatch } from "../../utils/redux"
import { _TagsContainer } from "./SegmentTags.styles"
import { displaySegmentTag, getAssignableSegmentTag } from "../../../functions/src/models/tags"
import { Loader } from "../common/Loader"
import { getDataByKeys } from "../../store/data/dataSelectors"
import { CloudState } from "../../store/store"
import { MapState } from "../../utils/redux.types"
import { RadarLocationState } from "../../models/auth"
import { mkClosed, mkOpen, ModalState, isOpen } from "../../models/modal"
import { SegmentTagCreateForm } from "./SegmentTagCreateForm"
import { Modal } from "../Modal"
import { ReactSelectAction } from "../form/FormlessInputs"

export type SegmentTagsEditorOwnProps = { objectId: string; objectSearchAreas: TMap<AreaId, SearchArea> }
export type SegmentTagsEditorStateProps = Loadable<
    {
        allTags: SegmentTag[]
        assignedTags: SegmentTag[]
        flags: ConfigFeatureFlags
        searchAreas: SMap<SearchArea>
        segmentTags: SMap<SegmentTag>
    } & Pick<CloudState, "actionsResults"> &
        Pick<RadarDetails, "radarId">
>

const _SegmentTagsEditor: React.FC<SegmentTagsEditorOwnProps & ActionProps & SegmentTagsEditorStateProps> = p => {
    if (p.loading) return <Loader />

    const [isLoading, setIsLoading] = React.useState(false)
    const [modalState, setModalState] = React.useState<ModalState<string>>(mkClosed())

    const [, onAssign] = useCloudAction(p.assignSegmentTag, p.actionsResults, () => setIsLoading(false))
    const [createState, onCreateAssign, resetCreateState] = useCloudAction(
        p.createAndAssignSegmentTag,
        p.actionsResults,
        () => {
            setIsLoading(false)
            resetCreateState()
            setModalState(mkClosed())
        }
    )

    const handleChange = (_: any, actionMeta: ReactSelectAction) => {
        setIsLoading(true)
        const partialPayload: OmitStrict<SegmentTagAssignmentPayload, "segmentId" | "assign"> = {
            objectId: p.objectId,
            radarId: p.radarId
        }
        switch (actionMeta.action) {
            case "remove-value":
            case "pop-value":
                if (!actionMeta?.removedValue?.value) return
                return onAssign({ ...partialPayload, segmentId: actionMeta.removedValue.value, assign: false })
            case "select-option":
                if (!actionMeta?.option?.value) return
                return onAssign({ ...partialPayload, segmentId: actionMeta.option.value, assign: true })
        }
    }

    const onCreateOption = (rawName: string) => {
        setModalState(mkOpen(rawName))
    }
    const onCreate = (value: SegmentTagCreatePayload) => {
        setIsLoading(true)
        const payload: SegmentTagMutationPayload & OmitStrict<SegmentTagAssignmentPayload, "segmentId" | "assign"> = {
            radarId: p.radarId,
            objectId: p.objectId,
            type: "create",
            value
        }
        onCreateAssign(payload)
    }
    return (
        <>
            <Modal isOpen={isOpen(modalState)} onClose={() => setModalState(mkClosed())} title="Create Segment Tag">
                <SegmentTagCreateForm
                    tagName={isOpen(modalState) ? modalState.value : ""}
                    onSubmit={onCreate}
                    segmentTags={p.segmentTags}
                    searchAreas={p.objectSearchAreas}
                    state={createState}
                />
            </Modal>
            <_TagsContainer>
                <div style={{ width: "100%" }}>
                    <CreatableSelect
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        onChange={handleChange}
                        onCreateOption={onCreateOption}
                        isMulti
                        createOptionPosition="first"
                        formatCreateLabel={v => `Create new Segment Tag "${v}"...`}
                        isClearable={false}
                        closeMenuOnSelect={true}
                        value={p.assignedTags.map(t =>
                            toOption(displaySegmentTag(p.objectSearchAreas)(t), t.segmentId)
                        )}
                        options={p.allTags.map(t => toOption(displaySegmentTag(p.objectSearchAreas)(t), t.segmentId))}
                        styles={getTagSelectStyles(false)}
                    />
                </div>
            </_TagsContainer>
        </>
    )
}

export const segmentTagEditorMapState: MapState<SegmentTagsEditorStateProps, SegmentTagsEditorOwnProps> = (
    { cloud: { actionsResults }, auth, data },
    op
) => {
    const radarId = getCurrentRadarId(auth)!
    const deps = getDataByKeys(
        ["segmentTags", "segmentTagsAssignments", "searchAreasAssignments", "searchAreas"],
        data[radarId]
    )
    if (deps.loading) return Loading()
    const { segmentTags, segmentTagsAssignments, searchAreasAssignments, searchAreas } = deps

    const allTags = getAssignableSegmentTag(searchAreasAssignments[op.objectId] || {}, segmentTags)
    const alreadyassignedSegmentTags = filterObject(segmentTagsAssignments[op.objectId], (_, v) => v)
    const assignedSegmentTags = keys(alreadyassignedSegmentTags).flatMap(id => segmentTags[id] ?? [])

    return Loaded({
        actionsResults,
        radarId,
        allTags,
        segmentTags,
        assignedTags: assignedSegmentTags,
        flags: (auth.params as RadarLocationState).locationParams,
        searchAreas
    })
}

type ActionProps = ReturnType<typeof mapDispatch>
const mapDispatch = getMapDispatch(actions, ["assignSegmentTag", "createAndAssignSegmentTag"])

export const CardSegmentTagsEditor = connect(segmentTagEditorMapState, mapDispatch)(_SegmentTagsEditor)
