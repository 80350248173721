import he from "he"
import {
    validate,
    validString,
    validNumber,
    defualtV,
    isArray,
    isEmpty,
    Err,
    Ok,
    isErr,
    validArrayStringDef,
    isObject,
    isNumber,
    validatePartial,
    validArrayString,
    validDef,
    validateNumber
} from "../utils/validators"
import { mapObject, remap, identity, values, isKeyOf } from "../utils/map"
import { mkString } from "../utils"
import { classifiersFields, DATA_SEPARATOR } from "./shared"
import { specialValuesRev } from "./converters"

export const validateThumnbail = validate<Thumbnail>({ url: validString, width: validNumber, height: validNumber })

export const validateThumnbailsMap = validate<Thumbnails>({
    small: [validateThumnbail],
    large: [validateThumnbail],
    full: [validateThumnbail]
})

export const validateFileMetadata = validate<FileMetadata>({
    filename: validString,
    id: validString,
    size: validNumber,
    thumbnails: [defualtV({})(validateThumnbailsMap)],
    type: validString,
    url: validString
})

export const validateRelation = validate<RelationTuple>({
    relation: validArrayString
})

export const validatePipelineStage = validate<PipelineStageValue>({
    collection: validString,
    name: validString,
    pipelineId: validString,
    order: validDef(-1, validateNumber)
})

export const validateHub = validate<Hub>({
    hubId: validString,
    hubSlug: validString,
    name: validString
})

export const validateHubPayload = validate<HubPayload>({
    hubSlug: validString,
    name: validString
})

export const itemClassifiersValidationMap: ValidationMap<Required<ItemClassifiersInput>> = {
    primary_search_areas: validArrayStringDef,
    secondary_search_areas: validArrayStringDef,
    tags: validArrayStringDef,
    segment_tags: validArrayStringDef
}

export const validateItemClassifiersInput = validate<ItemClassifiersInput>(itemClassifiersValidationMap)
export const validatePartialItemClassifiersInput = validatePartial<ItemClassifiersInput>(itemClassifiersValidationMap)

export const Point = <T = number>(x: T, y: T): Point<T> => ({ x, y })
export const Bounds = <T = number>(top: T, right: T, bottom: T, left: T): Bounds<T> => ({ top, bottom, left, right })
export const CRSize = (columns: number, rows: number): CRSize => ({ columns, rows })
export const Size = (width: number, height: number): Size => ({ width, height })

export const mapToDisplay = <T>(o: T, def: string): Displayable<T> =>
    mapObject(o, (_, v) => {
        return isArray(v)
            ? v
                  .filter(Boolean)
                  .map(s => `${s}`)
                  .join(DATA_SEPARATOR)
            : isKeyOf(mkString(v), specialValuesRev)
            ? specialValuesRev[mkString(v)]
            : mkString(v) === "-"
            ? def
            : mkString(v) || def
    })

const parseStringURL = (src = "") => (src.match(/(https?:\/\/[^\s]+)/g) || [])[0] || mkString(src)

export const parseString = (v = "") => {
    const trimmed = mkString(v).trim().replace(/’/g, "'").replace(/‚/g, "'") // its a single quote, not comma!
    return he.decode(trimmed) // remove html-specific characters
}
export const prepareStringArray = (v: string): string[] =>
    v
        .replace(/„/g, '"')
        .replace(/”/g, '"')
        .replace(/‚/g, "&lsquo;") // escape loose quote marks
        .replace(/’/g, "&rsquo;") // escape loose quote marks
        .replace(/'/g, "&squo;") // escape loose quote marks
        .replace(/([^, ])"([^,])/g, "$1&quot;$2") // escape loose quote marks
        .split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
        // split by comma but ignore commas in quote marks, ex. "stuff, otherstuff" is not split
        .map(spl => spl.replace(/"/g, "")) // remove quotes
        .map(spl => spl.replace(/&quot;/g, '"')) // re-escape loose quote marks
        .map(spl => spl.replace(/&lsquo;/g, "‚"))
        .map(spl => spl.replace(/&rsquo;/g, "’"))
        .map(spl => spl.replace(/&squo;/g, "'"))
        .map(spl => spl.trim())
        .filter(Boolean)

// eslint-disable-next-line @typescript-eslint/ban-types
export const parseField = <T extends string | number | object | string[]>(
    v: string | undefined,
    pattern: any
): Result<T | undefined, string> => {
    if (isArray(pattern)) {
        const resValue: string = mkString(v)
        if (!resValue.trim()) return Ok([] as T)
        return Ok(prepareStringArray(resValue).map(parseString) as T)
    }
    if (isObject(pattern)) return Err("Cannot import data as objects", v)

    // If the value is not a number, we still pass it down to show the users the mistake he has done
    if (isNumber(pattern)) return Ok((isNaN(parseFloat(v!)) ? (v ? mkString(v) : undefined) : parseFloat(v!)) as T)
    return Ok(parseString(v) as T)
}

export const parseObjectFields = <T extends CName, O>(cname: T, fixture: O) => (value: SMap<any>): Result<O> => {
    const errors: ExtErrors<O> = {}
    const result = remap(
        value,
        identity,
        (v, k) => {
            const key: keyof O = k as any
            const fixtureValue = fixture[key]
            if (fixtureValue === undefined) return null

            // exceptions
            if (cname === "sectors" && key === "capital_traction_url") return parseStringURL(mkString(v))
            if (cname === "startups" && key === "img_fallback") return parseStringURL(mkString(v))

            const parsedValue = parseField(v, fixtureValue)
            if (isErr(parsedValue)) {
                errors[key] = parsedValue.value
                return parsedValue.obj
            }

            return parsedValue.value
        },
        (_, v) => v !== null
    ) as any
    if (!isEmpty(errors)) return Err(errors, result)
    return Ok(result)
}

export const classifiersParser = (value?: ResultErrType<CollectionItemClassifiers>): CollectionItemClassifiers => {
    const errors: ExtErrors<ItemClassifiersInput> = {}
    const result = classifiersFields.reduce((acc, k) => {
        if (!value) return acc
        const parsedValue = parseField(value[k], [])
        if (isErr(parsedValue)) errors[k] = parsedValue.value
        acc[k] = isErr(parsedValue) ? (value[k] as any) : (parsedValue.value as string[])
        return acc
    }, {} as ItemClassifiersInput)
    if (!isEmpty(errors)) return Err(errors, result)
    return Ok(result)
}

export const narrowConfigFromLocationParamsMap = ({
    locationParams,
    hubSlug,
    radarSlug
}: {
    radarSlug: string
    hubSlug: string
    locationParams: TMap<RadarId, LocationParams>
}): LocationParams | null =>
    values(locationParams).find(p => p.hubSlug === hubSlug && p.radarSlug === radarSlug) ?? null

export const isDemo = (params: LocationParams) => params.restriction === "demo"
