/* eslint-disable max-lines */
import * as React from "react"
import { identity } from "../../../functions/src/utils/map"
import { Join } from "../../utils/reactUtils"
import { RichTextDescription } from "../../components/form/RichEditor"
import { isEmpty } from "../../../functions/src/utils/validators"
import { cutString, _noop } from "../../../functions/src/utils"
import { collectionsListFieldsDisplayNames } from "../../../functions/src/models/collections"
import { getCollectionSchema, getNameField } from "../../../functions/src/models/schemas"
import { ViewModelsMap } from "../../../functions/src/models/ViewModels"
import { _FlexContainer, _VerticalSpace } from "../../styles/common"
import { getDefinitionForKey } from "../lists/definitions"
import { DisplayRecommendedField } from "./CardDetails"
import { DEFAULT_PLACEHOLDER } from "../../../functions/src/models/shared"
import { pipe } from "lodash/fp"
import { Icon } from "../../../functions/src/models/icons"

// Snapshot config

export type SnapshotInfoElementField<C extends CName, K extends keyof ViewModelsMap[C]> = {
    name: string
    valueDisplay: (v: ViewModelsMap[C][K]) => React.ReactNode
    getInfo?: (o: ViewModelsMap[C]) => React.ReactNode
    icon?: Icon
    isVertical?: (v: ViewModelsMap[C][K]) => boolean
    visible: (v: any) => boolean
}
export type SnapshotInfoElementFields<C extends CName, F extends keyof ViewModelsMap[C]> = {
    [K in F]?: SnapshotInfoElementField<C, F>
}

export type SnapshotInfoElement<C extends CName, F extends keyof ViewModelsMap[C]> = {
    type: "info"
    getTitle: (o: ViewModelsMap[C]) => string
    getDescription?: (o: ViewModelsMap[C]) => React.ReactNode
    fields: SnapshotInfoElementFields<C, F>
    getRecommended?: (o: ViewModelsMap[C]) => React.ReactNode
}

export type SnapshotImageElement<C extends CName> = {
    type: "image"
    getTitle: (o: ViewModelsMap[C]) => string
    getSrc: (o: ViewModelsMap[C]) => string | undefined
}

type UniqueSnapshotElement = "assignedUser" | "searchAreas" | "tags" | "segmentTags" | "radicleInsights"

export type SnapshotElement<C extends CName, SIEF extends keyof ViewModelsMap[C] = keyof ViewModelsMap[C]> =
    | State<UniqueSnapshotElement>
    | SnapshotInfoElement<C, SIEF>
    | SnapshotImageElement<C>

export type SnapshotColumn<C extends CName, SIEF extends keyof ViewModelsMap[C]> = SnapshotElement<C, SIEF>[]
export type CardSchema<C extends CName, SIEF extends keyof ViewModelsMap[C] = keyof ViewModelsMap[C]> = SnapshotColumn<
    C,
    SIEF
>[]

const mkSnapshotInfoField = <C extends CName, FN extends keyof ViewModelsMap[C]>(
    cname: C,
    field: FN,
    o: Partial<SnapshotInfoElementField<C, FN>>
): SnapshotInfoElementField<C, FN> => {
    return {
        ...o,
        icon: o.icon || getCollectionSchema(cname).icons[field],
        name: isEmpty(o.name) ? (collectionsListFieldsDisplayNames[cname] as any)[field] : o.name,
        valueDisplay: isEmpty(o.valueDisplay) ? identity : o.valueDisplay,
        getInfo: o.getInfo || (() => getDefinitionForKey(cname, field as any)?.content),
        visible: o.visible === undefined ? () => true : o.visible
    }
}

const mkSnapshotInfoElement = <
    C extends CName,
    FN extends keyof ViewModelsMap[C],
    FE extends SnapshotInfoElementFields<C, FN>
>(
    cname: C,
    fields: FE,
    opts: Partial<OmitStrict<SnapshotInfoElement<C, FN>, "fields">> = {}
): SnapshotInfoElement<C, FN> => ({
    type: "info",
    ...opts,
    fields,
    getTitle: opts.getTitle
        ? opts.getTitle
        : (obj: ViewModelsMap[C]) => (obj[getNameField(cname)] as unknown) as string,
    getDescription: opts.getDescription
        ? opts.getDescription
        : obj => obj.description && <RichTextDescription value={obj.description} />,
    getRecommended: opts.getRecommended ? opts.getRecommended : _noop
})

const mkSnapshotImageElement = <C extends CName>(
    _: C,
    getTitle: SnapshotImageElement<C>["getTitle"],
    getSrc: SnapshotImageElement<C>["getSrc"]
): SnapshotImageElement<C> => ({
    type: "image",
    getTitle,
    getSrc
})

export const mkSnapshotElement = (type: UniqueSnapshotElement) => ({ type })

export const displayWithEmptyPlaceholder = (v: React.ReactNode) => (isEmpty(v) ? DEFAULT_PLACEHOLDER : v)
export const displayBold = (v: React.ReactNode) => <b>{displayWithEmptyPlaceholder(v)}</b>

export const displayArray = <T extends React.ReactNode>(e?: (v: T) => React.ReactNode) => (v: T[] | undefined) =>
    isEmpty(v) ? (
        DEFAULT_PLACEHOLDER
    ) : (
        <_FlexContainer>
            <Join items={v} renderJoining={() => <_VerticalSpace base="8px" />}>
                {i => <div>{e ? e(i) : i}</div>}
            </Join>
        </_FlexContainer>
    )

export const displayCommaSeparatedArray = <T extends string>(e?: (v: T) => React.ReactNode) => (v: T[] | undefined) =>
    isEmpty(v) ? DEFAULT_PLACEHOLDER : <_FlexContainer>{e ? e(v.join(", ") as any) : v.join(", ")}</_FlexContainer>

export const displayLink = (v: string, href?: string) => (
    <a href={href ? href : v.startsWith("http") ? v : `http://${v}`} target="_blank" rel="noreferrer noopener">
        {cutString(v, 60)}
    </a>
)

export const displayRichText = (v: string) => <RichTextDescription value={v} />

export const checkVisibility: { [key: string]: (v: any) => boolean } = {
    string: (v: any) => !!v,
    number: (v: any) => !!+v,
    placeholder: (v: any) => v !== DEFAULT_PLACEHOLDER
}

// TODO Array/number values display
export const CardSchemas: { [C in CName]: CardSchema<C> } = {
    startups: [
        [
            mkSnapshotInfoElement("startups", {
                sectors: mkSnapshotInfoField("startups", "sectors", {
                    valueDisplay: displayCommaSeparatedArray(displayBold)
                }),
                funding: mkSnapshotInfoField("startups", "funding", {
                    valueDisplay: displayBold
                }),
                headquarters: mkSnapshotInfoField("startups", "headquarters", {
                    valueDisplay: displayBold
                }),
                stage: mkSnapshotInfoField("startups", "stage", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                lead_investors: mkSnapshotInfoField("startups", "lead_investors", {
                    valueDisplay: displayCommaSeparatedArray(displayBold)
                }),
                investor_quality: mkSnapshotInfoField("startups", "investor_quality", {
                    valueDisplay: displayBold
                }),
                homepage_url: mkSnapshotInfoField("startups", "homepage_url", {
                    valueDisplay: displayLink
                }),
                crunchbase_url: mkSnapshotInfoField("startups", "crunchbase_url", {
                    valueDisplay: displayLink
                }),
                all_investors: mkSnapshotInfoField("startups", "all_investors", {
                    valueDisplay: displayCommaSeparatedArray(displayBold)
                }),
                founders_and_executives: mkSnapshotInfoField("startups", "founders_and_executives", {
                    valueDisplay: displayCommaSeparatedArray(displayBold)
                }),
                funding_momentum: mkSnapshotInfoField("startups", "funding_momentum", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.placeholder
                })
            })
        ],
        [mkSnapshotElement("assignedUser"), mkSnapshotElement("searchAreas")],
        [mkSnapshotElement("segmentTags"), mkSnapshotElement("tags")]
    ],
    sectors: [
        [
            mkSnapshotInfoElement(
                "sectors",
                {
                    funding: mkSnapshotInfoField("sectors", "funding", {
                        valueDisplay: displayBold
                    }),
                    primary_customer: mkSnapshotInfoField("sectors", "primary_customer", {
                        valueDisplay: displayCommaSeparatedArray(displayBold)
                    }),
                    product: mkSnapshotInfoField("sectors", "product", {
                        valueDisplay: displayCommaSeparatedArray(displayBold)
                    }),
                    geography: mkSnapshotInfoField("sectors", "geography", {
                        valueDisplay: displayCommaSeparatedArray(displayBold)
                    }),
                    business_model: mkSnapshotInfoField("sectors", "business_model", {
                        valueDisplay: displayCommaSeparatedArray(displayBold)
                    }),
                    jobs_to_be_done: mkSnapshotInfoField("sectors", "jobs_to_be_done", {
                        valueDisplay: pipe(displayWithEmptyPlaceholder, displayRichText),
                        isVertical: v => !isEmpty(v)
                    })
                },
                { getRecommended: o => DisplayRecommendedField(o) }
            ),
            mkSnapshotElement("radicleInsights")
        ],
        [
            mkSnapshotImageElement(
                "sectors",
                () => "Sector funding over time",
                o => o.capital_traction_url
            ),
            mkSnapshotElement("assignedUser"),
            mkSnapshotElement("searchAreas")
        ],
        [
            mkSnapshotInfoElement(
                "sectors",
                {
                    investor_quality: mkSnapshotInfoField("sectors", "investor_quality", {
                        valueDisplay: displayBold
                    }),
                    sector_concentration: mkSnapshotInfoField("sectors", "sector_concentration", {
                        valueDisplay: displayBold
                    }),
                    avg_company_age: mkSnapshotInfoField("sectors", "avg_company_age", {
                        valueDisplay: displayBold
                    }),
                    num_rounds: mkSnapshotInfoField("sectors", "num_rounds", {
                        valueDisplay: displayBold
                    }),
                    recent_funding: mkSnapshotInfoField("sectors", "recent_funding", {
                        valueDisplay: displayBold
                    }),
                    funding_momentum: mkSnapshotInfoField("sectors", "funding_momentum", {
                        valueDisplay: displayBold,
                        visible: checkVisibility.placeholder
                    }),
                    sector_funding_momentum: mkSnapshotInfoField("sectors", "sector_funding_momentum", {
                        valueDisplay: displayBold,
                        visible: checkVisibility.placeholder
                    }),
                    priority_score: mkSnapshotInfoField("sectors", "priority_score", {
                        valueDisplay: displayBold,
                        visible: checkVisibility.placeholder
                    })
                },
                { getTitle: () => "Evaluation criteria", getDescription: () => "" }
            ),
            mkSnapshotElement("segmentTags"),
            mkSnapshotElement("tags")
        ]
    ],
    influencers: [
        [
            mkSnapshotInfoElement(
                "influencers",
                {
                    influencer_name: mkSnapshotInfoField("influencers", "influencer_name", {
                        valueDisplay: displayBold
                    }),
                    location: mkSnapshotInfoField("influencers", "location", {
                        valueDisplay: displayBold,
                        visible: checkVisibility.string
                    }),
                    type: mkSnapshotInfoField("influencers", "type", {
                        valueDisplay: displayBold,
                        visible: checkVisibility.string
                    }),
                    num_news_sources: mkSnapshotInfoField("influencers", "num_news_sources", {
                        valueDisplay: displayBold,
                        visible: checkVisibility.placeholder
                    }),
                    google_ranking: mkSnapshotInfoField("influencers", "google_ranking", {
                        valueDisplay: displayBold,
                        visible: checkVisibility.placeholder
                    }),
                    influencer_url: mkSnapshotInfoField("influencers", "influencer_url", {
                        valueDisplay: displayLink,
                        visible: checkVisibility.string
                    }),
                    num_publications: mkSnapshotInfoField("influencers", "num_publications", {
                        valueDisplay: displayBold,
                        visible: checkVisibility.string
                    }),
                    num_patents: mkSnapshotInfoField("influencers", "num_patents", {
                        valueDisplay: displayBold,
                        visible: checkVisibility.string
                    }),
                    num_instagram: mkSnapshotInfoField("influencers", "num_instagram", {
                        valueDisplay: displayBold,
                        visible: checkVisibility.string
                    }),
                    num_twitter: mkSnapshotInfoField("influencers", "num_twitter", {
                        valueDisplay: displayBold,
                        visible: checkVisibility.string
                    })
                },
                {
                    getTitle: o =>
                        o.influencer_org && o.influencer_org !== DEFAULT_PLACEHOLDER
                            ? o.influencer_org
                            : o.influencer_name
                }
            )
        ],
        [mkSnapshotElement("assignedUser"), mkSnapshotElement("searchAreas")],
        [mkSnapshotElement("segmentTags"), mkSnapshotElement("tags")]
    ],
    investors: [
        [
            mkSnapshotInfoElement("investors", {
                number_of_investment_rounds: mkSnapshotInfoField("investors", "number_of_investment_rounds", {
                    valueDisplay: displayBold
                }),
                amount_invested: mkSnapshotInfoField("investors", "amount_invested", {
                    valueDisplay: displayBold
                }),
                total_unique_investments: mkSnapshotInfoField("investors", "total_unique_investments", {
                    valueDisplay: displayBold
                }),
                amount_per_investment: mkSnapshotInfoField("investors", "amount_per_investment", {
                    valueDisplay: displayBold
                }),
                investor_quality: mkSnapshotInfoField("investors", "investor_quality", {
                    valueDisplay: displayBold
                }),
                investor_url: mkSnapshotInfoField("investors", "investor_url", {
                    valueDisplay: displayLink
                }),
                investments: mkSnapshotInfoField("investors", "investments", {
                    valueDisplay: displayBold
                })
            })
        ],
        [mkSnapshotElement("assignedUser"), mkSnapshotElement("searchAreas")],
        [mkSnapshotElement("segmentTags"), mkSnapshotElement("tags")]
    ],
    patents: [
        [
            mkSnapshotInfoElement("patents", {
                holder: mkSnapshotInfoField("patents", "holder", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                num_citations: mkSnapshotInfoField("patents", "num_citations", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                filing_location: mkSnapshotInfoField("patents", "filing_location", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                publication_number: mkSnapshotInfoField("patents", "publication_number", {
                    valueDisplay: v => displayLink(v, `https://patents.google.com/patent/${v}/`)
                }),
                patent_url: mkSnapshotInfoField("patents", "patent_url", {
                    valueDisplay: displayLink,
                    visible: checkVisibility.string
                }),
                patent_url_2: mkSnapshotInfoField("patents", "patent_url_2", {
                    valueDisplay: displayLink,
                    visible: checkVisibility.string
                }),
                patent_type: mkSnapshotInfoField("patents", "patent_type", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                original_assignee: mkSnapshotInfoField("patents", "original_assignee", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                current_assignee: mkSnapshotInfoField("patents", "current_assignee", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                application_date: mkSnapshotInfoField("patents", "application_date", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                publication_date: mkSnapshotInfoField("patents", "publication_date", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                estimated_expiration_date: mkSnapshotInfoField("patents", "estimated_expiration_date", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                })
            })
        ],
        [mkSnapshotElement("assignedUser"), mkSnapshotElement("searchAreas")],
        [mkSnapshotElement("segmentTags"), mkSnapshotElement("tags")]
    ],
    research_hubs: [
        [
            mkSnapshotInfoElement("research_hubs", {
                university: mkSnapshotInfoField("research_hubs", "university", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                location: mkSnapshotInfoField("research_hubs", "location", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                num_publications: mkSnapshotInfoField("research_hubs", "num_publications", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.placeholder
                }),
                num_citations: mkSnapshotInfoField("research_hubs", "num_citations", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.placeholder
                }),
                citations_per_publication: mkSnapshotInfoField("research_hubs", "citations_per_publication", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.placeholder
                }),
                num_patents: mkSnapshotInfoField("research_hubs", "num_patents", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.placeholder
                }),
                active_funding: mkSnapshotInfoField("research_hubs", "active_funding", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.number
                }),
                grant_funding: mkSnapshotInfoField("research_hubs", "grant_funding", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.number
                }),
                research_hub_url: mkSnapshotInfoField("research_hubs", "research_hub_url", {
                    valueDisplay: displayLink
                }),
                num_patent_families: mkSnapshotInfoField("research_hubs", "num_patent_families", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                num_clinical_trials: mkSnapshotInfoField("research_hubs", "num_clinical_trials", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                num_experts: mkSnapshotInfoField("research_hubs", "num_experts", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                })
            })
        ],
        [mkSnapshotElement("assignedUser"), mkSnapshotElement("searchAreas")],
        [mkSnapshotElement("segmentTags"), mkSnapshotElement("tags")]
    ],
    research_papers: [
        [
            mkSnapshotInfoElement("research_papers", {
                research_group: mkSnapshotInfoField("research_papers", "research_group", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                university: mkSnapshotInfoField("research_papers", "university", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                location: mkSnapshotInfoField("research_papers", "location", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                publication_year: mkSnapshotInfoField("research_papers", "publication_year", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                actively_involved: mkSnapshotInfoField("research_papers", "actively_involved", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                paper_url: mkSnapshotInfoField("research_papers", "paper_url", {
                    valueDisplay: displayLink
                })
            })
        ],
        [mkSnapshotElement("assignedUser"), mkSnapshotElement("searchAreas")],
        [mkSnapshotElement("segmentTags"), mkSnapshotElement("tags")]
    ],
    tech_experts: [
        [
            mkSnapshotInfoElement("tech_experts", {
                sector_option: mkSnapshotInfoField("tech_experts", "sector_option", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }), // TODO
                association: mkSnapshotInfoField("tech_experts", "association", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                num_publications: mkSnapshotInfoField("tech_experts", "num_publications", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.placeholder
                }),
                num_citations: mkSnapshotInfoField("tech_experts", "num_citations", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.placeholder
                }),
                citations_per_publication: mkSnapshotInfoField("tech_experts", "citations_per_publication", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.placeholder
                }),
                num_patent_families: mkSnapshotInfoField("tech_experts", "num_patent_families", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                num_patents: mkSnapshotInfoField("tech_experts", "num_patents", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.placeholder
                }),
                active_funding: mkSnapshotInfoField("tech_experts", "active_funding", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.number
                }),
                grant_funding: mkSnapshotInfoField("tech_experts", "grant_funding", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.number
                }),
                funding_type: mkSnapshotInfoField("tech_experts", "funding_type", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                tech_expert_url: mkSnapshotInfoField("tech_experts", "tech_expert_url", {
                    valueDisplay: displayLink,
                    visible: checkVisibility.string
                }),
                num_clinical_trials: mkSnapshotInfoField("tech_experts", "num_clinical_trials", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                })
            })
        ],
        [mkSnapshotElement("assignedUser"), mkSnapshotElement("searchAreas")],
        [mkSnapshotElement("segmentTags"), mkSnapshotElement("tags")]
    ],
    tech_transfers: [
        [
            mkSnapshotInfoElement("tech_transfers", {
                research_hub: mkSnapshotInfoField("tech_transfers", "research_hub", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                location: mkSnapshotInfoField("tech_transfers", "location", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                tech_transfer_url: mkSnapshotInfoField("tech_transfers", "tech_transfer_url", {
                    valueDisplay: displayLink,
                    visible: checkVisibility.string
                })
            })
        ],
        [mkSnapshotElement("assignedUser"), mkSnapshotElement("searchAreas")],
        [mkSnapshotElement("segmentTags"), mkSnapshotElement("tags")]
    ],
    patent_holders: [
        [
            mkSnapshotInfoElement("patent_holders", {
                num_citations: mkSnapshotInfoField("patent_holders", "num_citations", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                num_patents: mkSnapshotInfoField("patent_holders", "num_patents", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                industry: mkSnapshotInfoField("patent_holders", "industry", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                num_patent_families: mkSnapshotInfoField("patent_holders", "num_patent_families", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                num_patent_family_citations: mkSnapshotInfoField("patent_holders", "num_patent_family_citations", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                patent_holder_url: mkSnapshotInfoField("patent_holders", "patent_holder_url", {
                    valueDisplay: displayLink,
                    visible: checkVisibility.string
                })
            })
        ],
        [mkSnapshotElement("assignedUser"), mkSnapshotElement("searchAreas")],
        [mkSnapshotElement("segmentTags"), mkSnapshotElement("tags")]
    ],
    grants: [
        [
            mkSnapshotInfoElement("grants", {
                funding: mkSnapshotInfoField("grants", "funding", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.number
                }),
                grant_url: mkSnapshotInfoField("grants", "grant_url", {
                    valueDisplay: displayLink,
                    visible: checkVisibility.string
                }),
                group_url: mkSnapshotInfoField("grants", "group_url", {
                    valueDisplay: displayLink,
                    visible: checkVisibility.string
                }),
                location: mkSnapshotInfoField("grants", "location", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                research_group: mkSnapshotInfoField("grants", "research_group", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                university: mkSnapshotInfoField("grants", "university", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                years_funded: mkSnapshotInfoField("grants", "years_funded", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                })
            })
        ],
        [mkSnapshotElement("assignedUser"), mkSnapshotElement("searchAreas")],
        [mkSnapshotElement("segmentTags"), mkSnapshotElement("tags")]
    ],
    clinical_trials: [
        [
            mkSnapshotInfoElement("clinical_trials", {
                location: mkSnapshotInfoField("clinical_trials", "location", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                sponsor: mkSnapshotInfoField("clinical_trials", "sponsor", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                trial_url: mkSnapshotInfoField("clinical_trials", "trial_url", {
                    valueDisplay: displayLink,
                    visible: checkVisibility.string
                }),
                start_date: mkSnapshotInfoField("clinical_trials", "start_date", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                end_date: mkSnapshotInfoField("clinical_trials", "end_date", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                authors: mkSnapshotInfoField("clinical_trials", "authors", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                affiliations: mkSnapshotInfoField("clinical_trials", "affiliations", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                study_phase: mkSnapshotInfoField("clinical_trials", "study_phase", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                })
            })
        ],
        [mkSnapshotElement("assignedUser"), mkSnapshotElement("searchAreas")],
        [mkSnapshotElement("segmentTags"), mkSnapshotElement("tags")]
    ],
    companies: [
        [
            mkSnapshotInfoElement("companies", {
                company_type: mkSnapshotInfoField("companies", "company_type", {
                    valueDisplay: displayBold
                }),
                company_name: mkSnapshotInfoField("companies", "company_name", {
                    valueDisplay: displayBold
                }),
                company_url: mkSnapshotInfoField("companies", "company_url", {
                    valueDisplay: displayLink
                }),
                product_technology_name: mkSnapshotInfoField("companies", "product_technology_name", {
                    valueDisplay: displayBold
                }),
                product_technology_url: mkSnapshotInfoField("companies", "product_technology_url", {
                    valueDisplay: displayLink
                }),
                active_ingredient: mkSnapshotInfoField("companies", "active_ingredient", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                other_ingredients: mkSnapshotInfoField("companies", "other_ingredients", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                efficacy_ranking: mkSnapshotInfoField("companies", "efficacy_ranking", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                efficacy_source: mkSnapshotInfoField("companies", "efficacy_source", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                efficacy_source_url: mkSnapshotInfoField("companies", "efficacy_source_url", {
                    valueDisplay: displayLink,
                    visible: checkVisibility.string
                }),
                moa: mkSnapshotInfoField("companies", "moa", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                }),
                crunchbase_url: mkSnapshotInfoField("companies", "crunchbase_url", {
                    valueDisplay: displayLink
                }),
                safety_source_url: mkSnapshotInfoField("companies", "safety_source_url", {
                    valueDisplay: displayLink,
                    visible: checkVisibility.string
                }),
                safety_ranking: mkSnapshotInfoField("companies", "safety_ranking", {
                    valueDisplay: displayBold,
                    visible: checkVisibility.string
                })
            })
        ],
        [mkSnapshotElement("assignedUser"), mkSnapshotElement("searchAreas")],
        [mkSnapshotElement("segmentTags"), mkSnapshotElement("tags")]
    ]
}
