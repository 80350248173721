import styled from "styled-components"
import { Flex } from "../styles/common"
import { _IconSvgContainer } from "./IconSvg.styles"

export const _MenuContainer = styled.div`
    user-select: none;
`
export const _MenuItems = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;
`
export const _MenuItemContainer = styled.li``
export const _MenuItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 8px;

    &:hover {
        background-color: #f0f0f4;
    }

    ${_MenuItemContainer} ${_MenuItemContainer} & {
        &:hover {
            margin-right: 8px;
            border-radius: 0 4px 4px 0;
        }
    }
`
export const _MenuItemLabelText = styled.span`
    word-break: break-word;
`

export const _MenuItemLabel = styled(Flex)`
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    padding: 8px 12px 8px 8px;
    color: ${({ theme }) => theme.colors.primary};
    flex-grow: 1;
    align-items: center;
    cursor: pointer;

    & > * + * {
        padding-left: 8px;
    }

    ${_IconSvgContainer} {
        transition: transform 0.1s ease-in-out;
    }

    &[data-folded="true"] ${_IconSvgContainer} {
        transform: rotate(-90deg);
    }
    &[data-disabled="true"] {
        opacity: 0.53;
    }

    ${_MenuItemContainer} ${_MenuItemContainer} & {
        padding: 4px 8px 4px 40px;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;

        &:before {
            display: none;
        }

        &[data-active="true"] {
            background: #f0f0f4;
            border-left: 4px solid #9494a8;
            border-radius: 0 4px 4px 0;
            padding-left: 36px;
        }
    }
`
export const _MenuActions = styled.div`
    display: none;

    ${_MenuItem}:hover & {
        display: block;
    }
`
export const _MenuAction = styled.div`
    cursor: pointer;
`
export const _MenuItemItemsCount = styled.span`
    color: #aaa;
    padding-left: 4px;
`
