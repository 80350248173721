import { assertNever } from "../../../functions/src/utils"
import {
    Section,
    Badge,
    SectionSchema,
    AssignmentSectionSchema,
    BadgesSectionSchema,
    TagsSectionSchema,
    CommentSectionSchema,
    DescriptionSectionSchema,
    SegmentTagSectionSchema
} from "./Sections.types"
import { CommentsVM, ViewModelsBase } from "../../../functions/src/models/ViewModels"
import { ColumnDefinition } from "../table/TableView.types"

export const TooltipDescription = (value: string): Section => ({ type: "Description", value })
export const TooltipBadges = (values: Badge[]): Section => ({ type: "Badges", values })
export const TooltipTags = (values: Tag[]): Section => ({
    type: "Tags",
    values
})
export const TooltipSegmentTags = (values: SegmentTag[]): Section => ({
    type: "Segment Tags",
    values
})
export const TooltipAssignment = (value: string): Section => ({ type: "Assignment", value })
export const TooltipDefinition = (value: ColumnDefinition): Section => ({ type: "Definition", ...value })
export const TooltipComment = (values: CommentsVM): Section => ({ type: "Comment", values })

export const Sections = <T>(
    schema: SectionSchema<T>[],
    item: T,
    itemId: string,
    vms: Pick<
        ViewModelsBase,
        | "searchAreas"
        | "searchAreasAssignments"
        | "tags"
        | "tagsAssignments"
        | "segmentTags"
        | "segmentTagsAssignments"
        | "users"
        | "decorators"
        | "comments"
    >
): Section[] =>
    schema.map(s => {
        switch (s.type) {
            case "s-description":
                return TooltipDescription(item[s.valueKey] as any)
            case "s-badges":
                return TooltipBadges(s.getValues(vms.searchAreas, vms.searchAreasAssignments[itemId]))
            case "s-tags":
                return TooltipTags(s.getValues(vms.tags, vms.tagsAssignments[itemId]))
            case "s-segmentTags":
                return TooltipSegmentTags(s.getValues(vms.segmentTags, vms.segmentTagsAssignments[itemId]))
            case "s-assignment":
                return TooltipAssignment(s.getValue(vms.users, vms.decorators[itemId]?.assignment))
            case "s-comment":
                return TooltipComment(s.getComments(vms.comments[itemId]))
        }
        assertNever(s)
    })

export const mkDescriptionSectionSchema = <T>(key: keyof T): DescriptionSectionSchema<T> => ({
    type: "s-description",
    valueKey: key
})

export const mkBadgesSectionSchema = (
    getValues: (sa: SMap<SearchArea>, assignments?: Assignments<AreaId, SearchAreaAssignmentKind>) => Badge[]
): BadgesSectionSchema => ({
    type: "s-badges",
    getValues
})

export const mkTagsSectionSchema = (
    getValues: (tags: SMap<Tag>, assignments?: Assignments) => Tag[]
): TagsSectionSchema => ({
    type: "s-tags",
    getValues
})

export const mkSegmentTagsSectionSchema = (
    getValues: (tags: SMap<SegmentTag>, assignments?: Assignments) => SegmentTag[]
): SegmentTagSectionSchema => ({
    type: "s-segmentTags",
    getValues
})

export const mkAssignmentSectionsSchema = (
    getValue: (users: TMap<UserId, RadarUser>, decorator: AssignmentDecorator) => string
): AssignmentSectionSchema => ({
    type: "s-assignment",
    getValue
})

export const mkCommentSectionSchema = (getComments: F1<CommentsVM, CommentsVM>): CommentSectionSchema => ({
    type: "s-comment",
    getComments
})
