import { flatten } from "../utils/map"
import { Err, foldResult, isEmpty, isErr, Ok } from "../utils/validators"
import { UniqueConstraint, isUniqueOR, isUniqueAND, isUniqueValue } from "./uniqueConstraint.types"

// TODO Add firebase Index for uniqueness checking
export const findDuplicates = <C extends CName, UC extends UniqueConstraint<keyof RCollection<C> & string, any, any>>(
    uc: UC,
    item: RCollection<C>,
    collection: RCollection<C>[]
) => {
    let err: Result<RCollection<C>[], string> | undefined
    const res = collection.filter(v => {
        const r = isDuplicate(uc as any, v, item)
        if (isErr(r)) err = r
        return r.value
    })
    if (err) return err
    return Ok(res)
}

export const isEmptyOrNA = (v: any) => isEmpty(v) || (typeof v === "string" && v.includes("N/A"))

export const isDuplicate = <C extends CName, UC extends UniqueConstraint<keyof RCollection<C> & string, any, any>>(
    uc: UC,
    item1: RCollection<C>,
    item2: RCollection<C>
): Result<boolean, string> => {
    if (isUniqueOR(uc)) {
        const l = isDuplicate(uc.left, item1, item2)
        const r = isDuplicate(uc.right, item1, item2)
        if (isErr(l)) return l
        if (isErr(r)) return r
        return Ok(l.value || r.value)
    } else if (isUniqueAND(uc)) {
        const l = isDuplicate(uc.left, item1, item2)
        const r = isDuplicate(uc.right, item1, item2)
        if (isErr(l)) return l
        if (isErr(r)) return r
        return Ok(l.value && r.value)
    } else if (isUniqueValue(uc)) {
        return Ok(!isEmptyOrNA(item1[uc.value]) && !isEmptyOrNA(item2[uc.value]) && item1[uc.value] === item2[uc.value])
    }
    return Err("Wrong unique constraint")
}

export const getUniqueFields = <T extends string, UC extends UniqueConstraint<T, any, any>>(
    uc: UC
): Result<T[], string> => {
    if (isUniqueValue(uc)) return Ok([uc.value])
    if (isUniqueAND(uc) || isUniqueOR(uc)) {
        const res = foldResult<T[], string>([getUniqueFields(uc.left), getUniqueFields(uc.right)])
        if (isErr(res)) return res
        return Ok(flatten(res.value))
    }
    return Err("Wrong unique constraint")
}

const _getRequiredUniqueFieldsMessage = <UC extends UniqueConstraint<string, any, any>>(
    uc: UC
): Result<string, string> => {
    if (isUniqueValue(uc)) {
        return Ok(`${uc.value}`)
    } else if (isUniqueAND(uc)) {
        const l = _getRequiredUniqueFieldsMessage(uc.left)
        const r = _getRequiredUniqueFieldsMessage(uc.right)
        if (isErr(l)) return l
        if (isErr(r)) return r
        return Ok(`(${l.value} and ${r.value})`)
    } else if (isUniqueOR(uc)) {
        const l = _getRequiredUniqueFieldsMessage(uc.left)
        const r = _getRequiredUniqueFieldsMessage(uc.right)
        if (isErr(l)) return l
        if (isErr(r)) return r
        return Ok(`(${l.value} or ${r.value})`)
    } else {
        return Err("Wrong unique constraint")
    }
}

export const getRequiredUniqueFieldsMessage = <UC extends UniqueConstraint<string, any, any>>(
    uc: UC
): Result<string, string> => {
    const res = _getRequiredUniqueFieldsMessage(uc)
    if (isErr(res)) return res
    res.value = res.value.replace(/^\(|\)$/g, "")
    return res
}

export const validateUniqueFields = <
    C extends CName,
    UC extends UniqueConstraint<keyof RCollection<C> & string, any, any>
>(
    uniqueConstraint: UC,
    item: RCollection<C>
): Result<string, string> => {
    let wrongConstraint = false

    const _validateUniqueFields = <UC2 extends UniqueConstraint<string, any, any>>(uc: UC2): string => {
        if (isUniqueValue(uc)) {
            const v = uc.value as keyof RCollection<C> & string
            return isEmpty(item[v]) ? v : ""
        } else if (isUniqueAND(uc)) {
            const l = _validateUniqueFields(uc.left)
            const r = _validateUniqueFields(uc.right)
            return l ? (r ? `(${l} and ${r})` : l) : r
        } else if (isUniqueOR(uc)) {
            const l = _validateUniqueFields(uc.left)
            const r = _validateUniqueFields(uc.right)
            return l && r ? `(${l} or ${r})` : ""
        } else {
            wrongConstraint = true
            return ""
        }
    }
    const res = _validateUniqueFields(uniqueConstraint).replace(/^\(|\)$/g, "")

    return wrongConstraint ? Err("Wrong unique constraint") : res ? Err(`${res} is required`) : Ok(res)
}
