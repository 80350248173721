import * as React from "react"
import { Loader } from "../components/common/Loader"
import { LoaderPayload } from "../store/data/dataSelectors"

export const ViewLoader = <T extends LoaderPayload>(p: Loadable<unknown, T>) => (
    <Loader
        fill
        show={p.loading}
        startWithAnimation={false}
        loadingText={p.loading && p.stateDescription}
        loadingSubtext={p.loading && p.stepDescription}
    />
)
