import { createAction } from "../../../functions/src/utils/actions"
import { Cancellable } from "../../utils/cancellable"
import { AsyncState, CancellableAsyncType } from "../store"

export const actions = {
    updateAsyncState: <K extends keyof AsyncState>(p: { key: K; value: AsyncState[K] }) =>
        createAction("updateAsyncState", p),
    setAsyncFetching: <K extends keyof AsyncState>(p: {
        key: K
        task: Cancellable<CancellableAsyncType<AsyncState[K]>>
    }) => createAction("setAsyncFetching", p),
    refreshAsyncData: <K extends keyof AsyncState>(k: K) => createAction("refreshAsyncData", { key: k })
}

export type Actions = ReturnType<typeof actions[keyof typeof actions]>
