import * as React from "react"
import { FormHookProps, useFormHook } from "../../utils/hooks/useFormHook"
import { _FormViewWrapper } from "../../styles/forms"
import { _Right } from "../../styles/common"
import { SecondaryButton, Button } from "../../styles/buttons"

import { getFormSchema } from "../../utils/formsSchema"
import { FormView } from "../../components/form/FormView"
import { _noop } from "../../../functions/src/utils"
import { Loader } from "../../components/common/Loader"

type SwitchSchema = { foo: boolean }
const switcher: FormSchema<SwitchSchema> = {
    foo: {
        name: "lalala",
        type: "switch",
        validators: [],
        values: [
            ["foo", true],
            ["bar", false]
        ],
        toValue: v => Boolean(v === "true")
    }
}

export const DemoForms: React.FC = () => {
    const objects = [{ n: 1 }, { s: "" }, { s: "", n: 0 }, { sc: [""] }, { nc: [1] }, { oc: [{ n: 1, s: "" }] }]
    const { formViewProps, result } = useFormHook({
        schema: switcher,
        initialValue: { foo: true },
        // eslint-disable-next-line no-console
        onSubmit: v => console.log("submitted", v)
    })
    return (
        <div>
            <_FormViewWrapper>
                <h1>Switch schema</h1>
                <pre>{JSON.stringify(result, null, 4)}</pre>
                <FormView {...formViewProps} />
            </_FormViewWrapper>
            <br />
            <h1>Forms generated using getSchema()</h1>
            {objects.map(v => {
                return (
                    <div
                        style={{ border: "1px solid #ddd", padding: 10, margin: 10, background: "#fcfcfc" }}
                        key={JSON.stringify(v)}>
                        <h2>{JSON.stringify(v)}</h2>
                        <VForm schema={getFormSchema(v)} initialValue={v} onSubmit={_noop} />
                    </div>
                )
            })}
        </div>
    )
}

export function VForm<T>(p: FormHookProps<T> & { onCancel?: F0; isSubmitting?: boolean }) {
    const { onSubmitClickReset, formViewProps } = useFormHook(p)
    return (
        <>
            <Loader fill show={p.isSubmitting} />
            <_FormViewWrapper>
                <FormView {...formViewProps} />
                <_Right>
                    {p.onCancel ? <SecondaryButton onClick={p.onCancel}>Cancel</SecondaryButton> : null}
                    <Button onClick={onSubmitClickReset}>Submit</Button>
                </_Right>
            </_FormViewWrapper>
        </>
    )
}
