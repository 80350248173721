import {
    ByPipelineStageCount,
    PipelineStageVsCollectionItem,
    PipelineStageVsSearchAreaItem,
    PositiveNegativeCounter,
    PipelineStageVsMonthItem
} from "../../../functions/src/models/reports"
import { Title, HeaderTitle, Text } from "../../components/table/TableViewController"
import { DEFAULT_PLACEHOLDER } from "../../../functions/src/models/shared"
import { mkString } from "../../../functions/src/utils"
import {
    PipelineStageVsSearchAreaReportResponse,
    PipelineStageVsCollectionReportResponse,
    LeadProgressionVsMonthReportResponse,
    LeadProgressionVsCollectionReportResponse
} from "../../../functions/src/services/httpEndpoint/reports"
import { sortByOrder, values } from "../../../functions/src/utils/map"
import { getCopy } from "../../../functions/src/services/copy"
import { getCollectionSchema } from "../../../functions/src/models/schemas"
import { getPipelineStagesMap } from "../../../functions/src/models/decoratorValues"
import { ValueCellMeta } from "../../components/table/TableViewCell"
import { getListPathName, collectionPaths } from "../../paths"
import { ActionProps, ReportsProps } from "./ReportsView"
import { Row, Cell, TableRows, CellSize } from "../../components/table/TableView.types"
import { getMonthDisplay } from "../../../functions/src/models/dateTime"
import { getAreaOrder, byNameAsc } from "../../../functions/src/models/searchAreas"
import { isEmpty } from "../../../functions/src/utils/validators"
import { getAvailableCollections } from "../../../functions/src/models/collections"

export const totalColSize = { min: 100 }
export const nameColSize = { min: 200 }
export const colSize = { min: 200 }

export const getCountCell = <T extends ByPipelineStageCount>(item: T) => (pipelineName: string) =>
    Text(
        pipelineName,
        mkString(isEmpty(item.data[pipelineName]) ? "" : item.data[pipelineName] || DEFAULT_PLACEHOLDER),
        colSize,
        true
    )

export const getPipelineBalanceText = <T extends string>(id: T, c: PositiveNegativeCounter | null, size: CellSize) => {
    if (isEmpty(c)) return Text(id, "", size, true)
    const { positive, negative } = c as PositiveNegativeCounter
    const p = positive ? `+${positive}` : DEFAULT_PLACEHOLDER
    const n = negative ? `-${negative}` : DEFAULT_PLACEHOLDER
    return Text(id, `${p} / ${n}`, size, true)
}

export const getPipelineHeaderRow = (title: string, pipelines: string[]) => [
    HeaderTitle("name", title, nameColSize, title),
    ...pipelines.map(pipelineName => HeaderTitle(pipelineName, pipelineName, colSize, pipelineName)),
    HeaderTitle("total", "Total leads", totalColSize, "total")
]

const getRowByPipeline = <T extends ByPipelineStageCount>(pipelines: string[], getTitle: F1<T, string>) => (
    item: T
): Row => {
    const t = getTitle(item)
    const cells: Cell[] = [
        Title("name", t, nameColSize, false),
        ...pipelines.map(getCountCell(item)),
        Text("total", mkString(item.total), totalColSize, false)
    ]
    return { id: t, cells, effects: ["last-cell-separator"] }
}

export const getPipelineSummaryRow = (summary: ByPipelineStageCount, pipelines: string[]): Row => ({
    id: "summary",
    cells: [
        Title("name", "Total", nameColSize),
        ...pipelines.map(getCountCell(summary)),
        Text("total", mkString(summary.total), totalColSize, false)
    ],
    effects: ["top-separator", "last-cell-separator"]
})

const getPipelines = (pipelines: TMap<PipelineStageId, Required<PipelineStageValue>>, cnames?: CName[]) => {
    let fps = sortByOrder(values(pipelines), psv => psv.order)
    if (cnames && cnames.length) {
        fps = fps.filter(psv => cnames?.includes(psv.collection))
    }

    return [...new Set([...fps, ...values(getPipelineStagesMap())].map(p => p.name))]
}

// ACTUAL PROPS
type ReportsTableProps = Pick<TableRows, "headerRow" | "rows">
type PipelineVsSearchAreaViewProps = {
    response: Loaded<PipelineStageVsSearchAreaReportResponse>
} & Pick<ReportsProps, "filters" | "pipelines" | "searchAreas"> &
    Pick<ActionProps, "updateParamsAndNavigate">
export const pipelineVsSearchAreaProps: F1<PipelineVsSearchAreaViewProps, ReportsTableProps> = p => {
    const { items, summary } = p.response
    const cname = p.filters.collections![0] as CName
    const filteredSearchAreas = p.filters.searchAreas
    const extPipelines = getPipelines(p.pipelines, [cname])

    const rows = items
        .sort((i1, i2) => byNameAsc(p.searchAreas[i1.id], p.searchAreas[i2.id]))
        .sort((s1, s2) => getAreaOrder(p.searchAreas[s1.id]) - getAreaOrder(p.searchAreas[s2.id]))
        .reduce<Row[]>((acc, item) => {
            if (isEmpty(filteredSearchAreas) || filteredSearchAreas.includes(p.searchAreas[item.id]?.name))
                acc.push(
                    getRowByPipeline<PipelineStageVsSearchAreaItem>(extPipelines, i => p.searchAreas[i.id]?.name)(item)
                )

            return acc
        }, [])
    const headerRow = getPipelineHeaderRow(getCopy("searchArea"), extPipelines)
    rows.push(getPipelineSummaryRow(summary, extPipelines))

    const onCellContentClick = (cellMeta: ValueCellMeta<string>) => {
        if (cellMeta.rowIndex === rows.length - 1 || cellMeta.value === DEFAULT_PLACEHOLDER) return

        const path = collectionPaths[getListPathName(cname)].path
        const searchAreas = cellMeta.rowId as AreaId
        const newPipelines =
            cellMeta.cellIndex > 0 && cellMeta.cellIndex - 1 < extPipelines.length
                ? [extPipelines[cellMeta.cellIndex - 1]]
                : extPipelines

        p.updateParamsAndNavigate({ pipelines: newPipelines, searchArea: searchAreas }, { path })
    }
    return { headerRow, rows, onCellContentClick }
}

type PipelineVsObjectTypeViewProps = {
    response: Loaded<PipelineStageVsCollectionReportResponse>
} & Pick<ReportsProps, "pipelines" | "filters" | "config" | "isRoot"> &
    Pick<ActionProps, "updateParamsAndNavigate">
export const pipelineVsObjectTypeProps: F1<PipelineVsObjectTypeViewProps, ReportsTableProps> = p => {
    const { items, summary } = p.response
    const filteredCNames = p.filters.collections as CName[]
    const extPipelines = getPipelines(p.pipelines, filteredCNames)
    const headerRow = getPipelineHeaderRow("Pipeline Stage Summary", extPipelines)
    const availableCollections = getAvailableCollections(p.config)
    const rows = items.reduce<Row[]>((acc, item) => {
        if (availableCollections.includes(item.id) && (filteredCNames.length === 0 || filteredCNames.includes(item.id)))
            acc.push(
                getRowByPipeline<PipelineStageVsCollectionItem>(
                    extPipelines,
                    i => getCollectionSchema(i.id).displayName
                )(item)
            )

        return acc
    }, [])
    rows.push(getPipelineSummaryRow(summary, extPipelines))
    return { headerRow, rows }
}

type LeadProgressionVsCollectionViewProps = {
    response: Loaded<LeadProgressionVsCollectionReportResponse>
} & Pick<ReportsProps, "pipelines" | "filters" | "config" | "isRoot"> &
    Pick<ActionProps, "updateParamsAndNavigate">
export const leadProgressionVsCollectionProps: F1<LeadProgressionVsCollectionViewProps, ReportsTableProps> = p => {
    const { items, summary } = p.response
    const filteredCNames = p.filters.collections as CName[]
    const extPipelines = getPipelines(p.pipelines, filteredCNames)
    const headerRow = getPipelineHeaderRow("Pipeline Stage Summary", extPipelines)
    const availableCollections = getAvailableCollections(p.config)
    const rows = items.reduce<Row[]>((acc, item) => {
        if (
            availableCollections.includes(item.id) &&
            (filteredCNames.length === 0 || filteredCNames.includes(item.id))
        ) {
            const t = getCollectionSchema(item.id).displayName
            const pipelinesWithNetCount = extPipelines.map(psName =>
                getPipelineBalanceText(psName, item.data[psName], colSize)
            )
            const cells: Cell[] = [
                Title("name", t, nameColSize, false),
                ...pipelinesWithNetCount,
                getPipelineBalanceText("total", item.total, totalColSize)
            ]
            acc.push({ id: t, cells, effects: ["last-cell-separator"] })
        }

        return acc
    }, [])
    const pipelinesWithNetCountSummary = extPipelines.map(psName =>
        getPipelineBalanceText(psName, summary.data[psName], colSize)
    )
    rows.push({
        id: "summary",
        cells: [
            Title("name", "Total", nameColSize),
            ...pipelinesWithNetCountSummary,
            getPipelineBalanceText("totalSummary", summary.total, totalColSize)
        ],
        effects: ["top-separator", "last-cell-separator"]
    })
    return { headerRow, rows }
}

type PipelineVsMonthlyProgressionViewProps = {
    response: Loaded<LeadProgressionVsMonthReportResponse>
} & Pick<ReportsProps, "pipelines" | "filters"> &
    Pick<ActionProps, "updateParamsAndNavigate">

export const pipelineVsMonthlyProgressionProps: F1<PipelineVsMonthlyProgressionViewProps, ReportsTableProps> = p => {
    const cname = p.filters.collections![0] as CName
    const extPipelines = getPipelines(p.pipelines, [cname])
    const headerRow = getPipelineHeaderRow(getCopy("searchArea"), extPipelines)
    const rows = p.response.items
        .sort((i1, i2) => +i2.id - +i1.id)
        .map(
            getRowByPipeline<PipelineStageVsMonthItem>(extPipelines, i => getMonthDisplay(new Date(+i.id)))
        )
    return { headerRow, rows }
}
