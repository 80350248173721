import styled from "styled-components"
import { _TableBody } from "../components/table/TableView.styles"
import { _BodyFont } from "../styles/typography"

const headerHeight: string = "168px"

export const _DashboardWrapper = styled.div`
    height: calc(100vh - ${headerHeight});
    overflow: auto;
`

export const StyledDasboardLayout = styled.div`
    width: 100%;
    display: flex;
    overflow: auto;
    flex: 1;
`

export const _DashboardSummaryItem = styled.div`
    height: 96px;
    border-radius: 4px;
    border: solid 1px #edeff2;
    padding: 14px 22px 10px;
`

export const _DashboardSummary = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 92px 92px;
    padding: 20px;
    grid-gap: 30px 10px;
`

export const StyledDashboardSummaryNumber = styled.p`
    width: 39px;
    height: 44px;
    font-size: 34px;
    line-height: 1.29;
    margin: 0;
    letter-spacing: -0.5px;
    color: ${({ theme }) => theme.colors.primary};
`

export const StyledExecutiveSummaryButton = styled.p``

export const _LeftPanel = styled.div`
    background-color: ${p => p.theme.colors.primary};
    width: 175px;
    height: calc(100vh - ${headerHeight});
    flex-shrink: 0;
    color: #fff;
    overflow: auto;
`

export const _LeftPanelContent = styled(_LeftPanel)<{ lowered?: boolean }>`
    padding: 32px 16px;
`

export const StyledSeparator = styled.div`
    height: 1px;
    width: 100%;
    margin: 12px 0;
    background-color: #fff;
`

export const StyledLeftPanelItem = styled(_BodyFont)`
    font-size: 14px;
    cursor: pointer;
    &:hover {
        font-weight: bold;
    }
`

export const StyledTableButton = styled.button`
    height: 41px;
    border-radius: 4px;
    border: solid 1px #edeff2;
    border-top: none;
    background-color: #fafbfc;
    font-size: 14px;
    line-height: 1.36;
    text-align: center;
    color: #0093ee;
    width: 100%;
    outline: none;
    cursor: pointer;
`
