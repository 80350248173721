import { Cmd } from "redux-loop"
import { RadarFetchAttribute, getRequestSchema, DataRequestCallbacks, SubscriptionSchema } from "./subscriptionSchema"
import { firestoreCmd } from "../../utils/reduxLoop"
import { getStore } from ".."
import { asyncForEach, mkString } from "../../../functions/src/utils"
import { actions } from "./actions"
import { actions as httpActions } from "../../../functions/src/services/httpEndpoint/actions"
import { actions as uiActions } from "../ui/actions"
import { getFirebase } from "../../services/firebase"
import { actOnFirestoreError } from "../auth/authCmds"
import { shouldFakeFetch, fakeFetch } from "./dataUtils"
import { Firestore } from "../../../functions/src/services/firebase"
import { Fetched, isNotFetched } from "../../../functions/src/utils/types"
import { keys } from "../../../functions/src/utils/map"
import { DataRequestPayload } from "../../../functions/src/services/httpEndpoint/data"
import { isErr } from "../../../functions/src/utils/validators"
import { DependencySchema, ViewModels } from "../../../functions/src/models/ViewModels"
import { RootContext } from "../store"
import { subscriptionDataTypes } from "../../../functions/src/models/dataSelectors"

export const handleSubscribe = async <T, R>(
    firestore: Firestore,
    schema: SubscriptionSchema<T, R>,
    cbs: DataRequestCallbacks<T>
) => {
    if (schema.type === "document")
        return firestore.subscribeOnDoc(
            schema.ref,
            schema.validator,
            async res => {
                if (isErr(res)) return cbs.onFailure(res.value)
                try {
                    cbs.onSuccess(Fetched(await schema.transform(res.value)))
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e)
                }
            },
            cbs.onFailure
        )
    return firestore.subscribeOnCol(
        schema.ref,
        schema.validator,
        async valids => {
            try {
                cbs.onSuccess(Fetched(await schema.transform(valids)))
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e)
            }
        },
        cbs.onFailure
    )
}

export const setSubscriptions = (radarId: string, radarAttrs: RadarFetchAttribute[], state: Partial<ViewModels> = {}) =>
    firestoreCmd(async (firestore: Firestore) => {
        const { dispatch } = getStore()
        const notFetchedKeys = subscriptionDataTypes.filter(d => isNotFetched(state[d]))
        dispatch(actions._setFetching(radarId, notFetchedKeys))
        await asyncForEach(subscriptionDataTypes, async rsk => {
            const schema = getRequestSchema(firestore, rsk, radarId)
            if (firestore.isSubscribedOnRef(schema.ref) && !notFetchedKeys.includes(rsk)) return
            const callbacks: DataRequestCallbacks = {
                onSuccess: res => dispatch(actions._setData(radarId, { [rsk]: res })),
                onFailure: e => {
                    if (!getFirebase().auth.getCurrentUser()) return
                    dispatch(actOnFirestoreError(`raw data - ${rsk}`)(e))
                }
            }
            if (shouldFakeFetch(radarAttrs, schema.restrictions)) return fakeFetch(callbacks)
            handleSubscribe(firestore, schema, callbacks)
        })
    })

const fetchData = async (
    radarId: string,
    schema: DependencySchema,
    cursor: Cursor,
    context: RootContext,
    merge: boolean
) => {
    const { functions } = getFirebase()
    const { dispatch } = getStore()
    dispatch(
        actions._setFetching(
            radarId,
            keys(schema).filter(k => !subscriptionDataTypes.includes(k as any)),
            !merge
        )
    )
    const payload: DataRequestPayload = {
        radarId,
        cursor,
        schema,
        filters: context.ui.filters,
        sorting: context.ui.sorting
    }
    const result = await functions.callFunctions(httpActions.data(payload))
    if (isErr(result))
        return dispatch(actOnFirestoreError(`Fetch request for ${keys(schema).join(", ")}`)(mkString(result.value)))
    dispatch(uiActions._setCursor(cursor))
    dispatch(merge ? actions._mergeData(radarId, result.value) : actions._setData(radarId, result.value))
}

export const fetchDataCmd = (
    radarId: string,
    schema: DependencySchema,
    cursor: Cursor,
    context: RootContext,
    merge: boolean
) => Cmd.run(() => fetchData(radarId, schema, cursor, context, merge))
