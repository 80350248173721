import styled from "styled-components"
import { styleFromProp } from "../../styles/common"

type TooltipProps = { position: TopLeft; isOpened: boolean; toRight?: number; toBottom?: number }
export const _Tooltip = styled.div<TooltipProps>`
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(44, 64, 90, 0.1);
    border: solid 1px #edeff2;
    background-color: #fff;
    max-width: 458px;
    padding: 20px 40px 20px 10px;
    position: fixed;
    z-index: ${p => (p.isOpened ? 100 : -1)};
    ${p => {
        const left = p.toRight ? p.position.left - p.toRight - 5 : p.position.left
        const top = p.toBottom ? p.position.top - p.toBottom - 5 : p.position.top
        return `
            ${styleFromProp(p.position.top, `top: ${top < 0 ? 5 : top}px;`)}
            ${styleFromProp(p.position.left, `left: ${left < 0 ? 5 : left}px;`)}
        `
    }}
`
