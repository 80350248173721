// TODO Fix this
/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react"
import { RouteChildrenProps } from "react-router"
import { mapObject, mapOptionsMapToValuesMap, keys } from "../../../functions/src/utils/map"
import { connect } from "react-redux"
import { actions as cfActions } from "../../store/cloud/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { collections } from "../../../functions/src/models/schemas"
import { Loaded, isFetching, LoadingWithPayload, isFetched, Loading } from "../../../functions/src/utils/types"
import { getCollectionEditPathName } from "../../paths"
import { EditItemProps, EditCollectionItem } from "../../components/edit/EditCollectionItem"
import { ItemNotFoundCard, CardLoader } from "./Card"
import { mapRelationsToOptions, mapClassifiersToOptions } from "../../../functions/src/models/relations/relations"
import { isErr, Ok } from "../../../functions/src/utils/validators"
import { getCurrentRadarId } from "../../models/LocationType"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { Msg } from "../../models/notifications"
import { LoadableView } from "../../utils/reactUtils"
import { attachIdToCollectionItem } from "../../store/importing/importingUtils"
import { getDataByPath } from "../../store/data/dataSelectors"
import { Dispatch } from "../../store/middlewares/authMiddleware.types"
import { NotificationMsg } from "../../store/store"
import { MapDispatch } from "../../utils/redux.types"
import { isRoot } from "../../models/LoginStatus"
import { ModalRouteProps } from "../../containers/ModalRenderer"
import { getSearchAreasFromAssignments } from "../../../functions/src/models/searchAreas"

type OwnProps<T> = RouteChildrenProps<{ [key in keyof T]: string }>
export const ADDED_OBJECT_NEW_ID = "new"

type EditItemWrapperProps<C extends CName> =
    | State<
          "Ok",
          OmitStrict<EditItemProps<C>, "onClose" | "onSave" | "queueNotification"> & {
              onUpdateItem: F1<Dispatch, F2<string, CollectionItem<C>>>
              onAddItem: F1<Dispatch, F2<string, CollectionItem<C>>>
              actionsResults: SMap<CloudActionResult>
          }
      >
    | State<"NotFound">

type ActionProps = { dispatch: Dispatch; queueNotification: F1<NotificationMsg> }
type LoadableEditItemProps = EditItemWrapperProps<CName> & ModalRouteProps & ActionProps

const EditWrapper: React.FC<LoadableEditItemProps> = p => {
    if (p.type === "NotFound") return <ItemNotFoundCard {...p} />
    const [, onAddItem, clearOnAdd] = useCloudAction(p.onAddItem(p.dispatch), p.actionsResults, ({ result }) => {
        p.queueNotification(Msg("create", { name: "item" }, result))
        clearOnAdd()
        p.onClose()
    })
    const [, onUpdateItem, clearOnUpdate] = useCloudAction(
        p.onUpdateItem(p.dispatch),
        p.actionsResults,
        ({ result }) => {
            p.queueNotification(Msg("update", { name: "item" }, result))
            clearOnUpdate()
            p.onClose()
        }
    )
    return (
        <EditCollectionItem
            {...p}
            onSave={i => {
                p.item ? onUpdateItem(i) : onAddItem(i)
            }}
            onClose={p.onClose}
        />
    )
}
const getEditProps = <C extends CName>(cname: C, idKey: keyof RCollection<C>) =>
    getDataByPath<EditItemWrapperProps<C>, OwnProps<RCollection<C>>>()(
        getCollectionEditPathName(cname),
        (deps, { auth, cloud: { actionsResults } }, { match }, models) => {
            if (!isFetched(auth.configs)) return Loading()
            const radarId = getCurrentRadarId(auth)!
            const objectId = (match ? match.params[idKey] : "") as string

            if (objectId !== ADDED_OBJECT_NEW_ID && !deps[cname]![objectId]) {
                if (isFetching(models[cname])) return LoadingWithPayload({})
                return Loaded({ type: "NotFound" })
            }

            const objectRelations = deps.relations[objectId] || []
            const { primarySearchAreas, secondarySearchAreas } = getSearchAreasFromAssignments(
                deps.searchAreasAssignments[objectId] || {},
                deps.searchAreas
            )
            const objectClassifiers: Required<ItemClassifiersInput> = {
                primary_search_areas: primarySearchAreas.map(sa => sa.areaId),
                secondary_search_areas: secondarySearchAreas.map(sa => sa.areaId),
                tags: keys(deps.tagsAssignments[objectId] || {}),
                segment_tags: keys(deps.segmentTagsAssignments[objectId] || {})
            }
            const relationsVM: Casted<RCollectionRelations<C>, ROptions> = mapRelationsToOptions(
                objectRelations,
                {},
                deps
            )
            const classifiersVM = mapClassifiersToOptions(objectClassifiers, deps, auth.configs.value[radarId])
            const itemRelations = {
                classifiers: Ok(objectClassifiers),
                classifiersVM,
                relations: Ok(mapOptionsMapToValuesMap(relationsVM)),
                relationsVM
            }
            const col = deps[cname] as SMap<RCollection<C>>

            const item: CollectionItemVM<C> | undefined = col[objectId]
                ? { type: "overwrite", fields: Ok(col[objectId]), ...itemRelations }
                : undefined

            return Loaded({
                type: "Ok",
                cname,
                item,
                vms: deps,
                mode: "list",
                isRoot: isRoot(auth.authentication),
                actionsResults,
                config: auth.configs.value[radarId],
                onAddItem: (d: Dispatch) => (actionId: string, i: CollectionItem<C>) => {
                    if (isErr(i.fields) || isErr(i.classifiers)) return
                    const fields = Ok(attachIdToCollectionItem(cname, i.fields.value))
                    const newItem = { ...i, fields }
                    d(cfActions._mutateRadarCollectionItem(actionId, { cname, item: newItem, radarId, type: "create" }))
                },
                onUpdateItem: (d: Dispatch) => (actionId: string, i: CollectionItem<C>) => {
                    if (isErr(i.fields) || isErr(i.classifiers)) return
                    i.fields.value[idKey] = objectId as any
                    d(cfActions._mutateRadarCollectionItem(actionId, { cname, item: i, radarId, type: "update" }))
                }
            })
        }
    )

const mapDispatch: MapDispatch<ActionProps> = dispatch => ({
    dispatch,
    queueNotification: msg => dispatch(uiActions.queueNotification(msg))
})

export const EditViews: { [C in CName]: React.FC } = mapObject(
    collections,
    (k, v) =>
        connect(
            getEditProps(k, v.idField as keyof RCollection<CName>),
            mapDispatch
        )(LoadableView(CardLoader, EditWrapper)) as any
)
