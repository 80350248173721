import * as React from "react"
import { connect } from "react-redux"
import { _VerticalSpace, _Spacer, Flex, _HorizontalSpace, _Center } from "../../styles/common"
import { radarPaths, adminPaths } from "../../paths"
import { MapState } from "../../utils/redux.types"
import { isAdminLocation } from "../../models/LocationType"
import { TabRenderable, Tab, TabText } from "../../components/tabs/Tabs"
import { UsersView } from "./RadarUsers"
import { TagsEditorView } from "./RadarTags"
import { PrioritiesImportView } from "./PrioritiesImport"
import { RadarImportView } from "./RadarImport"
import { EditRadarView } from "./RadarEdit"
import { _RadarViewWrapper, _RadarDetails, _RadarTabContainer } from "./AdminDashboard.styles"
import { Link } from "../../styles/buttons"
import { AdminNavigation } from "../../containers/AdminNavigation"
import { isRoot } from "../../models/LoginStatus"
import { getCopy } from "../../../functions/src/services/copy"
import { RadarSearchAreasView } from "./RadarSearchAreas"
import { RadarBreadcrumbs } from "../../components/radar/RadarBreadcrumbs"
import { RadarPipelinesView } from "./RadarPipeline"
import { matchesPath, materialize } from "../../utils/router"
import { URLTabs } from "../../components/tabs/URLTabs"
import { _BodyFont } from "../../styles/typography"
import { AddRadarView } from "./RadarAdd"
import { getHubFromSlug } from "./utils"
import { getMapDispatch } from "../../utils/redux"
import { actions } from "../../store/auth/actions"
import { SubscribersView } from "./RadarSubscribers"
import { insert } from "../../../functions/src/utils/map"
import { ContactedLeadsView } from "./RadarContactedLeads"
import { SegmentTagsView } from "./SegmentTags"

const withTabContainer = (c: React.ReactNode) => <_RadarTabContainer>{c}</_RadarTabContainer>

type RadarSubView =
    | "radarEdit"
    | "radarAdd"
    | "radarUsers"
    | "radarSubscribers"
    | "radarTags"
    | "radarAreas"
    | "prioritiesImport"
    | "collectionImport"
    | "radarPipelines"
    | "contactedLeads"
    | "segmentTags"

type DashboardTab<T extends RadarSubView> = Tab<T>
// type DashboardTab<T> = T extends RadarSubView ? Tab<T> : never

const RadarView: React.FC<Props> = p => {
    const Add: DashboardTab<"radarAdd"> = {
        id: "radarAdd",
        title: TabText("Overview"),
        content: TabRenderable(() => withTabContainer(<AddRadarView />))
    }
    const Edit: DashboardTab<"radarEdit"> = {
        id: "radarEdit",
        title: TabText("Overview"),
        content: TabRenderable(() => withTabContainer(<EditRadarView />))
    }
    const Users: DashboardTab<"radarUsers"> = {
        id: "radarUsers",
        title: TabText("Users"),
        content: TabRenderable(() => withTabContainer(<UsersView />))
    }
    const Subscribers: DashboardTab<"radarSubscribers"> = {
        id: "radarSubscribers",
        title: TabText("Subscribers"),
        content: TabRenderable(() => withTabContainer(<SubscribersView />))
    }
    const Tags: DashboardTab<"radarTags"> = {
        id: "radarTags",
        title: TabText("Tags"),
        content: TabRenderable(() => withTabContainer(<TagsEditorView />))
    }
    const Areas: DashboardTab<"radarAreas"> = {
        id: "radarAreas",
        title: TabText(getCopy("searchAreas")),
        content: TabRenderable(() => withTabContainer(<RadarSearchAreasView />))
    }
    const Pipelines: DashboardTab<"radarPipelines"> = {
        id: "radarPipelines",
        title: TabText("Pipeline stages"),
        content: TabRenderable(() => withTabContainer(<RadarPipelinesView />))
    }
    const CollectionImport: DashboardTab<"collectionImport"> = {
        id: "collectionImport",
        title: TabText("Import Data"),
        content: TabRenderable(() => <RadarImportView />)
    }
    const PrioritiesImport: DashboardTab<"prioritiesImport"> = {
        id: "prioritiesImport",
        title: TabText("PR Import"),
        content: TabRenderable(() => <PrioritiesImportView />)
    }
    const ContactedLeads: DashboardTab<"contactedLeads"> = {
        id: "contactedLeads",
        title: TabText("Contacted Leads"),
        content: TabRenderable(() => <ContactedLeadsView />)
    }

    const SegmentTags: DashboardTab<"segmentTags"> = {
        id: "segmentTags",
        title: TabText("Segment Tags"),
        content: TabRenderable(() => <SegmentTagsView />)
    }

    const commonTabs: DashboardTab<RadarSubView>[] = [
        !p.isDemo ? Users : Subscribers,
        Tags,
        p.withSegmentTags ? SegmentTags : [],
        Areas,
        CollectionImport,
        p.withContactedLead ? ContactedLeads : []
    ].flat()

    const demoTabs = [Tags, Areas, CollectionImport]
    const adminTabs = insert<Tab<RadarSubView>>(3, Pipelines)(!p.isDemo ? commonTabs : demoTabs)
    const superAdminTabs = [Edit, ...commonTabs, PrioritiesImport]
    const newTabs = [Add]

    return (
        <_RadarViewWrapper>
            <AdminNavigation />
            {p.radarSlug || p.new ? (
                <_RadarDetails>
                    <_VerticalSpace base="16px" />
                    <Flex>
                        <_HorizontalSpace base="16px" />
                        <RadarBreadcrumbs data={[p.hubName || "", p.new ? "New Radar" : p.radarName || ""]} />
                        <_Spacer />
                        {p.radarSlug && (
                            <Link
                                to={materialize(radarPaths["radar/radar"].path, { radarSlug: p.radarSlug })}
                                color="info">
                                View Radar
                            </Link>
                        )}
                        <_HorizontalSpace base="16px" />
                    </Flex>
                    <_VerticalSpace base="16px" />
                    <URLTabs data={p.new ? newTabs : p.root ? superAdminTabs : adminTabs} />
                </_RadarDetails>
            ) : (
                <_Center>
                    <_BodyFont bold s20>
                        Please choose radar
                    </_BodyFont>
                </_Center>
            )}
        </_RadarViewWrapper>
    )
}

export type StateProps = Partial<
    Pick<LocationParams, "withSegmentTags" | "withContactedLead" | "radarSlug" | "hubName" | "radarName"> & {
        root: boolean
        new: boolean
        isDemo: boolean
    }
>

const mapState: MapState<StateProps> = ({ auth: { params, authentication, hubs } }) => {
    // TODO Fix rendering while new location if possible. To reproduce - navigate to radar by View Radar
    if (!isAdminLocation(params)) return {}
    const {
        radarSlug,
        hubName,
        radarName,
        restriction,
        withContactedLead,
        withSegmentTags
    } = params.locationParams as LocationParams
    const searchHubSlug = new URLSearchParams(params.search).get("hub")
    // This works only for SuperAdmin
    const searchHubName = getHubFromSlug(searchHubSlug || "", (hubs as AsyncFetched<SMap<ExtHub>>).value)?.name || ""

    return {
        isDemo: restriction === "demo",
        radarSlug,
        hubName: hubName || searchHubName,
        radarName,
        withContactedLead,
        withSegmentTags,
        root: isRoot(authentication),
        new: matchesPath(adminPaths["admin/newRadar"].path, params.pathname)
    }
}

const mapDispatch = getMapDispatch(actions, ["navigate"])

type ActionProps = ReturnType<typeof mapDispatch>
type Props = StateProps & ActionProps

export const AdminDashboard = connect(mapState, mapDispatch)(RadarView)
