import { getRowSchema } from "../../components/table/TableViewController"
import {
    mkDescriptionSectionSchema,
    mkBadgesSectionSchema,
    mkTagsSectionSchema,
    mkAssignmentSectionsSchema,
    mkCommentSectionSchema,
    mkSegmentTagsSectionSchema
} from "../../components/tooltip/SectionController"
import { getCollectionSchema } from "../../../functions/src/models/schemas"
import { getDisplayNames } from "../../../functions/src/models/collections"
import { keys, identity } from "../../../functions/src/utils/map"
import { getAssignedUserName } from "../../../functions/src/models/decoratorValues"
import { ViewModelsMap } from "../../../functions/src/models/ViewModels"
import { SectionSchema } from "../../components/tooltip/Sections.types"
import { RowSchema } from "../../components/table/TableView.types"
import { schemas as listSchemas } from "../../../functions/src/models/listings/schemas"

type GenericTooltipSchema = SharedOptionalFields
const GeneralTooltipSchema = <T extends GenericTooltipSchema>(): SectionSchema<T>[] => [
    mkDescriptionSectionSchema<T>("description"),
    mkBadgesSectionSchema((sa, assignments = {}) =>
        keys(sa)
            .filter(said => assignments[said])
            .map(said => sa[said])
    ),
    mkSegmentTagsSectionSchema((st, assignments = {}) =>
        keys(st)
            .filter(stid => assignments[stid])
            .map(stid => st[stid])
    ),
    mkTagsSectionSchema((t, assignments = {}) =>
        keys(t)
            .filter(tid => assignments[tid])
            .map(tid => t[tid])
    ),
    mkAssignmentSectionsSchema((us, dec) => getAssignedUserName(us, dec?.userId)),
    mkCommentSectionSchema(identity)
]

type Schema<C extends CName> = {
    columns: (keyof ViewModelsMap[C] & string)[]
    schema: RowSchema<ViewModelsMap[C]>
    tooltipSchema: SectionSchema<RCollection<C>>[]
}

const getCollectionRowSchema = <T extends CName>(
    c: T,
    fields: (keyof ViewModelsMap[T] & string)[],
    iconTitleKeys?: (keyof ViewModelsMap[T] & string)[]
): RowSchema<ViewModelsMap[T]> =>
    getRowSchema<ViewModelsMap[T], keyof ViewModelsMap[T] & string>(
        getCollectionSchema(c).viewFixture,
        getDisplayNames(c)({ mode: "list" }),
        fields,
        { titleKey: fields[0], iconTitleKeys }
    )

const mkCollectionListSchema = <C extends CName>(
    c: C,
    iconTitleKeys?: (keyof ViewModelsMap[C] & string)[]
): Schema<C> => ({
    columns: listSchemas[c] as (keyof ViewModelsMap[C] & string)[],
    schema: getCollectionRowSchema(c, listSchemas[c] as (keyof ViewModelsMap[C] & string)[], iconTitleKeys),
    tooltipSchema: GeneralTooltipSchema<RCollection<C>>()
})

export const schemas: { [C in CName]: Schema<C> } = {
    patents: mkCollectionListSchema("patents"),
    sectors: mkCollectionListSchema("sectors"),
    startups: mkCollectionListSchema("startups", ["logo_url", "img_fallback"]),
    tech_transfers: mkCollectionListSchema("tech_transfers"),
    investors: mkCollectionListSchema("investors"),
    research_hubs: mkCollectionListSchema("research_hubs"),
    research_papers: mkCollectionListSchema("research_papers"),
    tech_experts: mkCollectionListSchema("tech_experts"),
    influencers: mkCollectionListSchema("influencers"),
    patent_holders: mkCollectionListSchema("patent_holders"),
    grants: mkCollectionListSchema("grants"),
    clinical_trials: mkCollectionListSchema("clinical_trials"),
    companies: mkCollectionListSchema("companies", ["logo_url"])
}
