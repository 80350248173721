import React, { useEffect } from "react"

import { calculateBounds } from "../views/SectorsMapController"

type Props = { setBounds: F1<Bounds> }

export const DraggableBounds: React.FC<Props> = p => {
    const ref = React.useRef<HTMLDivElement>(null)
    useEffect(() => {
        const r = ref.current
        const updateDraggableBounds = () => p.setBounds(calculateBounds())

        window.addEventListener("resize", updateDraggableBounds)

        if (r) {
            r.addEventListener("transitionstart", updateDraggableBounds)
            r.addEventListener("transitionend", updateDraggableBounds)
        }

        return () => {
            window.removeEventListener("resize", updateDraggableBounds)
            if (r) {
                r.removeEventListener("transitionstart", updateDraggableBounds)
                r.removeEventListener("transitionend", updateDraggableBounds)
            }
        }
    }, [ref]) // eslint-disable-line react-hooks/exhaustive-deps

    return <div ref={ref}>{p.children}</div>
}
