import * as React from "react"
import { getSearchAreasForObject } from "../../../../functions/src/models/searchAreas"
import { asyncWithDefault, isFetching } from "../../../../functions/src/utils/types"
import { adminPaths } from "../../../paths"
import { asyncConnect } from "../../../store/async/asyncConnect"
import { LinkButton } from "../../../styles/buttons"
import { Flex, _FlexContainer, _VerticalSpace } from "../../../styles/common"
import { Loader } from "../../common/Loader"
import { CardSegmentTagsEditor } from "../../segmentTags/SegmentTagsEditor"
import { _CardSectionContainer, _CardSectionTitle } from "./CardSection.styles"

export type OwnProps = {
    objectId: ObjectId
}

export const SegmentTagsCardSection = asyncConnect<OwnProps>()({
    data: ["currentRadarConfig", "viewModels", "flags", "isAdmin"],
    actions: ["openPopup", "navigate"]
})(p => {
    const { searchAreas, searchAreasAssignments } = p.viewModels
    const validSA = React.useMemo(
        () =>
            getSearchAreasForObject(
                asyncWithDefault(searchAreas, {}),
                asyncWithDefault(searchAreasAssignments, {})[p.objectId]
            ),
        [p.objectId, searchAreas, searchAreasAssignments]
    )

    return (
        <_FlexContainer>
            <_CardSectionContainer data-cy="segment-tags-card-section">
                <_CardSectionTitle>Segment Tags</_CardSectionTitle>
                {isFetching(searchAreas) || isFetching(searchAreasAssignments) ? (
                    <Loader />
                ) : (
                    <div>
                        {/* TODO Refactor this editor */}
                        <CardSegmentTagsEditor objectId={p.objectId} objectSearchAreas={validSA} />
                        <_VerticalSpace base="4px" />
                        {p.isAdmin && (
                            <Flex justify="flex-end">
                                <LinkButton
                                    color="theme6"
                                    onClick={() =>
                                        p.navigate({
                                            path: adminPaths["admin/radarDashboard"].path,
                                            slugs: { radarSlug: p.currentRadarConfig.radarSlug },
                                            searchParams: "tab=segmentTags"
                                        })
                                    }>
                                    Manage segment tags
                                </LinkButton>
                            </Flex>
                        )}
                    </div>
                )}
            </_CardSectionContainer>
        </_FlexContainer>
    )
})
