import styled from "styled-components"
import { _FilterBarContainer } from "../components/Header"
import { FlexInline, _Center } from "../styles/common"

export const SectorMapContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    ${_FilterBarContainer} {
        position: static;
    }
`

export const LoaderContainer = styled(_Center)`
    width: 100vw;
    height: 100vh;
    position: relative;
`

export const SidebarColumn = styled(FlexInline)`
    width: ${({ theme }) => theme.sizes.sidebarWidth};
`

export const MapColumn = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
`

export const MapWrapper = styled.div`
    position: relative;
    flex-grow: 1;
    overflow: hidden;
`
