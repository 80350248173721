import React from "react"
import { IconSvg } from "./IconSvg"
import * as S from "./Popup.styles"

type Props = {
    onClose?: () => void
    logo?: boolean
    full?: boolean
}

export const Popup: React.FC<Props> = ({ children, onClose, logo, full }) => (
    <S.PopupContainer>
        <S._Popup full={full}>
            {onClose && (
                <S.CloseButton onClick={onClose}>
                    <IconSvg data-cy="close-svq" name="close" width={15} height={15} />
                </S.CloseButton>
            )}
            {logo && (
                <S.RadicleMiniLogo>
                    <IconSvg name="mini-logo" width={36} height={36} />
                </S.RadicleMiniLogo>
            )}
            {children}
        </S._Popup>
    </S.PopupContainer>
)
