import { ListSchemas } from "../listings/schemas"

const RangeFlavors = ["range", "fundsRange", "percentileRange"] as const
export type RangeFlavor = typeof RangeFlavors[number]
export function isRangeFlavor(arg: string): arg is RangeFlavor {
    return RangeFlavors.indexOf(arg as RangeFlavor) !== -1
}

const TextFlavors = ["string", "stringSearch"] as const
export type TextFlavor = typeof TextFlavors[number]
export function isTextFlavor(arg: string): arg is TextFlavor {
    return TextFlavors.indexOf(arg as TextFlavor) !== -1
}

export type FieldFilterType = TextFlavor | "timestamp" | RangeFlavor

const getCollectionFieldsFilterTypes = <
    T extends {
        [C in CName]: { [Col in ArrayItem<ListSchemas[C]>]: FieldFilterType }
    }
>(
    p: T
) => p

export const collectionFieldsFilterTypes = getCollectionFieldsFilterTypes({
    startups: {
        startup_name: "stringSearch",
        sectors: "string",
        headquarters: "string",
        funding: "fundsRange",
        stage: "string",
        investor_quality: "range",
        funding_momentum: "range"
    },
    sectors: {
        sector_name: "stringSearch",
        num_startups: "range",
        funding: "fundsRange",
        investor_quality: "range",
        sector_funding_momentum: "range",
        funding_momentum: "range",
        avg_company_age: "range",
        priority_score: "range"
    },
    patents: {
        patent_title: "stringSearch",
        holder: "string",
        publication_number: "string",
        filing_location: "string"
    },
    tech_transfers: {
        tech_of_interest: "stringSearch",
        research_hub: "string",
        location: "string"
    },
    investors: {
        investor_name: "stringSearch",
        total_unique_investments: "range",
        number_of_investment_rounds: "range",
        amount_invested: "fundsRange",
        amount_per_investment: "fundsRange",
        investor_quality: "range"
    },
    research_hubs: {
        research_hub_name: "stringSearch",
        university: "string",
        num_publications: "range",
        num_experts: "range",
        num_patent_families: "range",
        num_clinical_trials: "range"
    },
    research_papers: {
        paper_title: "stringSearch",
        research_group: "string",
        university: "string",
        publication_year: "string"
    },
    tech_experts: {
        tech_expert_name: "stringSearch",
        association: "string",
        num_publications: "range",
        num_patent_families: "range"
    },
    influencers: {
        influencer_name: "stringSearch",
        type: "string",
        num_publications: "range",
        num_instagram: "range",
        num_twitter: "range",
        num_patents: "range",
        num_news_sources: "range"
    },
    patent_holders: {
        patent_holder: "stringSearch",
        industry: "string",
        num_patents: "range",
        num_citations: "range",
        num_patent_families: "range",
        num_patent_family_citations: "range"
    },
    grants: {
        grant_title: "stringSearch",
        research_group: "string",
        university: "string",
        location: "string",
        funding: "fundsRange"
    },
    clinical_trials: {
        trial_title: "stringSearch",
        sponsor: "string",
        location: "string",
        study_phase: "string",
        start_date: "string",
        end_date: "string"
    },
    companies: {
        product_technology_name: "stringSearch",
        company_name: "string",
        company_type: "string",
        active_ingredient: "string"
    }
})

export type CollectionFieldsFilterTypes = typeof collectionFieldsFilterTypes
