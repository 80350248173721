import * as React from "react"
import { Rows, Row, ArrowHead, Columns, Column } from "./Axis.styles"
import { Caption } from "../styles/typography"
import { RenderHTML } from "./common"
import { IconSvg } from "./IconSvg"

type Props = {
    draggingPosition: Point
    labels: Label[]
}

export const YAxis: React.FC<Props> = React.memo(({ draggingPosition, labels }) => (
    <Rows style={{ transform: `translateY(${draggingPosition.y}px)` }} twoColumns={labels.length === 2}>
        <ArrowHead>
            <IconSvg name="arrowhead" width={9} height={9} />
        </ArrowHead>
        {labels.map((row, index) => (
            <Row key={index} height={`${100 / labels.length}%`}>
                <Caption bold>
                    <RenderHTML html={row.name} />
                </Caption>
            </Row>
        ))}
    </Rows>
))

export const XAxis: React.FC<Props> = React.memo(({ draggingPosition, labels }) => (
    <Columns style={{ transform: `translateX(${draggingPosition.x}px)` }} twoColumns={labels.length === 2}>
        <ArrowHead>
            <IconSvg name="arrowhead" width={9} height={9} />
        </ArrowHead>
        {labels.map((column, index) => (
            <Column key={index} width={`${100 / labels.length}%`}>
                <Caption bold>
                    <RenderHTML html={column.name} />
                </Caption>
            </Column>
        ))}
    </Columns>
))
