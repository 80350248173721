import { EditorState, ContentState, SelectionState, Modifier, ContentBlock } from "draft-js"
import React from "react"
import { LinkPopupMeta } from "src/components/popups/editor/LinkPopup"
import { ActionDispatchFn } from "../../../store/actions"
import { getBlockEntityRange } from "./utils"

export const linkUnderlineError = "Links cannot be underlined"

export const findLinkEntities: Draft.DraftDecorator["strategy"] = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity()
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK"
    }, callback)
}

export const handleLinkButtonClick = (
    editorState: EditorState,
    onChange: (v: EditorState) => void,
    openPopup: ActionDispatchFn<"openPopup">
) => {
    const selection = editorState.getSelection()
    const content = editorState.getCurrentContent()
    const startKey = selection.getStartKey()
    const startOffset = selection.getStartOffset()
    const block = content.getBlockForKey(startKey)
    const linkKey = block.getEntityAt(startOffset)
    const entityRange = linkKey ? getBlockEntityRange(block, linkKey) : null
    const textRange = entityRange ? [entityRange[0], entityRange[1]] : [startOffset, selection.getEndOffset()]
    const text = block.getText().slice(...textRange)
    const url = linkKey ? content.getEntity(linkKey)?.getData().url : ""

    openPopup("linkPopup", {
        text,
        url,
        onEdit: (v: LinkPopupMeta) => editLink(v.url, v.text, editorState, content, linkKey, block, onChange),
        onAdd: (v: LinkPopupMeta) => addLink(v.url, v.text, editorState, onChange),
        onRemove: () => removeLink(editorState, content, linkKey, block, onChange)
    })
}

export const addLink = (url: string, text: string, editorState: EditorState, onChange: (v: EditorState) => void) => {
    const contentState = editorState.getCurrentContent()
    const selection = editorState.getSelection()
    const endPosition = selection.getStartOffset() + text.length
    const contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", { url })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const contentWithNewText = Modifier.replaceText(contentStateWithEntity, selection, text, undefined, entityKey)
    const newEditorState = EditorState.set(editorState, {
        currentContent: contentWithNewText,
        selection: selection.merge({ anchorOffset: endPosition, focusOffset: endPosition })
    })
    onChange(newEditorState)
}

export const editLink = (
    url: string,
    text: string,
    editorState: EditorState,
    content: Draft.ContentState,
    linkKey: string,
    block: ContentBlock,
    onChange: (v: EditorState) => void
) => {
    const range = getBlockEntityRange(block, linkKey) || [0, 0]
    const selection = new SelectionState({
        anchorKey: block.getKey(),
        focusKey: block.getKey(),
        anchorOffset: range[0],
        focusOffset: range[1]
    })
    const endPosition = selection.getStartOffset() + text.length
    const newContent = Modifier.replaceText(
        content.mergeEntityData(linkKey, { url }),
        selection,
        text,
        undefined,
        linkKey
    )
    const newEditorState = EditorState.set(editorState, {
        currentContent: newContent,
        selection: selection.merge({ anchorOffset: endPosition, focusOffset: endPosition })
    })
    onChange(newEditorState)
}

export const removeLink = (
    editorState: EditorState,
    content: Draft.ContentState,
    linkKey: string,
    block: Draft.ContentBlock,
    onChange: (v: EditorState) => void
) => {
    let contentWithRemovedLink = content

    block.findEntityRanges(
        charData => {
            const entityKey = charData.getEntity()
            if (!entityKey) return false
            return entityKey === linkKey
        },
        (start, end) => {
            const entitySelection = new SelectionState({
                anchorKey: block.getKey(),
                focusKey: block.getKey(),
                anchorOffset: start,
                focusOffset: end
            })
            contentWithRemovedLink = Modifier.applyEntity(content, entitySelection, null)
        }
    )
    onChange(EditorState.set(editorState, { currentContent: contentWithRemovedLink }))
}

const urlRegex = /^(https?|ftp):\/\//

export const EditorLink = (p: { contentState: ContentState; entityKey: string; children: React.ReactNode }) => {
    const { url } = p.contentState.getEntity(p.entityKey).getData()
    const formattedUrl = url.match(urlRegex) ? url : `http://${url}`
    return (
        <a href={formattedUrl} target="_blank" rel="noreferrer noopener">
            {p.children}
        </a>
    )
}
