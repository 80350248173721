import { isEmpty, Err, Ok } from "../utils/validators"
import { getDecryptedText } from "../utils/crypto"
import { CommentsVM } from "./ViewModels"
import { getUserNameFromId, getAssignedUserName, getPipelineStagesMap, NO_DECORATOR } from "./decoratorValues"
import { getCopy } from "../services/copy"
import { assertNever } from "../utils"
import { decryptLead } from "./decorators"

export const NO_ITEM = "[Deleted Item]"
export const UNKNOWN = "unknown"
export const commentErrors = {
    noComment: "Given comment doesn't exist"
}
export const getLastEditedComment = (commentId: string, comments: ObjectComment[]): Result<ObjectComment> => {
    const foundComments = comments.filter(c => c.commentId === commentId).sort((a, b) => b.updatedTs - a.updatedTs)
    if (isEmpty(foundComments)) return Err(commentErrors.noComment)
    return Ok(foundComments[0])
}

export const getRealComments = (comments: ObjectComment[], key: EncryptionKey | null) =>
    comments
        .filter((c, _, a) => !a.find(ac => ac.commentId === c.commentId && ac.updatedTs > c.updatedTs))
        .filter(c => !c.removed)
        .sort((a, b) => b.updatedTs - a.updatedTs)
        .map(decryptComment(key))

export const decryptComment = (key: EncryptionKey | null) => (comment: ObjectComment): ObjectComment => ({
    ...comment,
    text: key ? getDecryptedText(comment.text, key) : comment.text
})
export const getCommentByMetaType = (type: Type<CommentMeta>) => (cs: ObjectComment[]) =>
    cs?.filter(c => c.meta?.type === type) || []

export const getContactedLeadsCount = (comments: CommentsVM) => getCommentByMetaType("contactedLead")(comments).length

const DisplayComment = (oc: ObjectComment) => (desc: string) => ({ ...oc, desc })

export const prepareCommentToDisplay = (
    pipelines: SMap<PipelineStageValue>,
    searchAreas: SMap<SearchArea>,
    secureKey: EncryptionKey,
    users: TMap<UserId, RadarUser>
) => (comment: ObjectComment): DisplayComment => {
    if (isEmpty(comment.meta)) return comment
    const extendComment = DisplayComment(comment)
    const meta = comment.meta!
    if (meta.type === "contactedLead") {
        if (meta.value.type !== "lead")
            return extendComment(`User <b>${getUserNameFromId(users, comment.userId)}</b> contacted with subject`) // TODO: SOME OF THE NOT MIGRATED DATA
        const leadComment: DisplayComment = {
            ...comment,
            meta: {
                type: "contactedLead",
                ...meta,
                value: {
                    ...meta.value,
                    oldValue: meta.value.oldValue ? decryptLead(meta.value.oldValue, secureKey) : undefined,
                    newValue: meta.value.newValue ? decryptLead(meta.value.newValue, secureKey) : undefined
                }
            },
            desc: `User <b>${getUserNameFromId(users, comment.userId)}</b> contacted with subject`
        }
        return leadComment
    }

    switch (meta.value.type) {
        case "assignment": {
            const previousUser = getAssignedUserName(users, meta.value.oldValue)
            const nextUser = getAssignedUserName(users, meta.value.newValue)
            return extendComment(`Object <b>changed assignment</b> from <b>${previousUser}</b> to <b>${nextUser}</b>`)
        }
        case "pipelineStage": {
            const staticPipelines = getPipelineStagesMap()
            const oldValue = meta.value.oldValue ? pipelines[meta.value.oldValue] : null
            const newValue = meta.value.newValue ? pipelines[meta.value.newValue] : null
            const oldValueName = oldValue
                ? getDecryptedText(oldValue.name, secureKey)
                : staticPipelines[meta.value.oldValue || NO_DECORATOR]?.name
            const newValueName = newValue
                ? getDecryptedText(newValue.name, secureKey)
                : staticPipelines[meta.value.newValue || NO_DECORATOR]?.name
            const searchArea = searchAreas[meta.value.searchAreaId]?.name || "unknown"
            return extendComment(
                `Pipeline stage was updated from <b>${oldValueName}</b> to <b>${newValueName}</b> for ${getCopy(
                    "searchArea"
                )} <b>${searchArea}</b>`
            )
        }
        case "priorityRank":
        case "lead":
            return comment
    }
    assertNever(meta.value)
}
