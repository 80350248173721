import styled from "styled-components"
import { Flex, _FlexColumn } from "../../styles/common"
import { _FormViewWrapper } from "../../styles/forms"
import { _DropdownWrapper } from "../../components/Dropdown.styles"
import { BaseButton } from "../../styles/buttons"
import { _InputWrapper } from "../../components/form/Input.styles"

export const _FormSectionContainer = styled(Flex)`
    padding: 24px;
    border: 1px solid #f0f0f4;
    background: #fafafc;
    border-radius: 8px;
    margin-bottom: 16px;
`

export const _FormSectionTitleContainer = styled(Flex)`
    width: 40%;
    flex-shrink: 0;
`

export const _FlexSectionContentContainer = styled(_FlexColumn)`
    & > * + * {
        margin-top: 24px;
    }
`

// TODO Refactor it to properly scale labels inputs
export const _RadarEditFormWrapper = styled(_FormViewWrapper)`
    padding: 0 16px;
    max-width: unset;

    ${_DropdownWrapper} ${BaseButton} {
        flex-grow: 1;
        width: 360px;
        max-width: 360px;
    }

    input:not([type="checkbox"]):not([type="radio"]),
    ${_InputWrapper} {
        width: 360px;
        max-width: 360px;
    }
`
