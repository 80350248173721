import * as React from "react"
import {
    getPipelineCurrentValue,
    getPipelineStageValues,
    getPriorityCurrentValue,
    getPriorityRankValues
} from "../../../../functions/src/models/decoratorValues"
import { getSearchAreasFromAssignments } from "../../../../functions/src/models/searchAreas"
import { ViewModels } from "../../../../functions/src/models/ViewModels"
import { asyncWithDefault, isFetching } from "../../../../functions/src/utils/types"
import { isEmpty } from "../../../../functions/src/utils/validators"
import { ActionDispatchFn } from "../../../store/actions"
import { asyncConnect } from "../../../store/async/asyncConnect"
import { _FlexContainer, _HRLine } from "../../../styles/common"
import { _BodyFont, _TagFont } from "../../../styles/typography"
import { _SearchAreaBadge } from "../../common"
import { _Copy } from "../../common/Copy"
import { Loader } from "../../common/Loader"
import { RadioDropdown } from "../../Dropdown"
import { TableLayout } from "../../layout/TableLayout"
import { _CardSectionContainer, _CardSectionTitle } from "./CardSection.styles"
import { _SearchAreasCardSectionContainer } from "./SearchAreasCardSection.styles"

type PipelineDecoratorProps<C extends CName> = {
    cname: C
    areaId: AreaId
    viewModels: ViewModels
    objectId: ObjectId
    openPopup: ActionDispatchFn<"openPopup">
}
const PipelineDecorator = <C extends CName>(p: PipelineDecoratorProps<C>) => {
    const { pipelines, decorators } = p.viewModels
    const selected = React.useMemo(
        () =>
            getPipelineCurrentValue(
                asyncWithDefault(pipelines, {}),
                asyncWithDefault(decorators, {})[p.objectId],
                p.areaId
            ),
        [pipelines, decorators, p.objectId, p.areaId]
    )
    const options = React.useMemo(() => getPipelineStageValues(asyncWithDefault(pipelines, {}), p.cname), [
        pipelines,
        p.cname
    ])

    return isFetching(pipelines) || isFetching(decorators) ? (
        <Loader />
    ) : (
        <RadioDropdown
            onSelect={({ value }) =>
                p.openPopup("mandatoryComment", {
                    type: "pipeline",
                    value: {
                        type: "pipelineStage",
                        searchAreaId: p.areaId,
                        objectId: p.objectId,
                        newValue: value,
                        oldValue: selected.value
                    }
                } as ValueState<"pipeline", AreaDecoratorMeta>)
            }
            options={options}
            selected={[selected]}
            full
        />
    )
}

type PriorityRankDecoratorProps = {
    areaId: AreaId
    viewModels: ViewModels
    objectId: ObjectId
    mutateDecoratorDebounced: ActionDispatchFn<"mutateDecoratorDebounced">
}
const PriorityRankDecorator = (p: PriorityRankDecoratorProps) => {
    const { decorators } = p.viewModels
    const selected = React.useMemo(
        () => getPriorityCurrentValue(asyncWithDefault(decorators, {})[p.objectId], p.areaId),
        [decorators, p.objectId, p.areaId]
    )
    const options = React.useMemo(() => getPriorityRankValues(), [])

    return isFetching(decorators) ? (
        <Loader />
    ) : (
        <RadioDropdown
            onSelect={({ value }) =>
                p.mutateDecoratorDebounced({
                    type: "priorityRank",
                    objectId: p.objectId,
                    searchAreaId: p.areaId,
                    newValue: value,
                    oldValue: selected.value
                } as PriorityRankDecoratorMeta)
            }
            options={options}
            selected={[selected!]}
            full
        />
    )
}

export type OwnProps = {
    objectId: ObjectId
    cname: CName
}

const getSARow = (
    area: SearchArea,
    p: {
        flags: FeatureFlags
        objectId: ObjectId
        cname: CName
        viewModels: ViewModels
        isDemo: boolean
        openPopup: ActionDispatchFn<"openPopup">
        mutateDecoratorDebounced: ActionDispatchFn<"mutateDecoratorDebounced">
    }
) =>
    [
        <_FlexContainer
            data-cy={`search-area-name-in-row-${area.name}`}
            key={`${area.areaId}-areaId`}
            align="flex-start">
            <_SearchAreaBadge color={area.color}>{area.name}</_SearchAreaBadge>
        </_FlexContainer>,
        !p.isDemo && p.flags.withPipelines && (
            <_FlexContainer
                data-cy={`search-area-pipeline-stage-in-row-${area.name}`}
                key={`${area.areaId}-pipelineStage`}
                justify="center">
                <PipelineDecorator {...p} areaId={area.areaId} />
            </_FlexContainer>
        ),
        !p.isDemo && p.flags.withPriorityRanks && (
            <_FlexContainer
                data-cy={`search-area-priority-rank-in-row-${area.name}`}
                key={`${area.areaId}-priorityRank`}
                justify="center">
                <PriorityRankDecorator {...p} areaId={area.areaId} />
            </_FlexContainer>
        )
    ].filter(v => !isEmpty(v))

const getSAHeaderRow = (primary: boolean, demo: boolean, flags: FeatureFlags) =>
    [
        <_TagFont key="primaryFocus" bold>
            <_Copy name={primary ? "primarySearchAreas" : "secondarySearchAreas"} />
        </_TagFont>,
        !demo && flags.withPipelines && <_TagFont key="pipelineStage">Pipeline Stage</_TagFont>,
        !demo && flags.withPriorityRanks && <_TagFont key="priorityRank">Priority Ranking</_TagFont>
    ].filter(v => !isEmpty(v))

export const SearchAreasCardSection = asyncConnect<OwnProps>()({
    data: ["approvedUsers", "viewModels", "flags", "isDemo"],
    actions: ["openPopup", "mutateDecoratorDebounced"]
})(p => {
    const { searchAreasAssignments: asyncSearchAreasAssignments, searchAreas: asyncSearchAreas } = p.viewModels
    // TODO Multiple renders with different flags...
    // const pflags = usePrevious(p.flags)

    // React.useEffect(() => {
    //     console.log("FLAGS", pflags, p.flags, pflags === p.flags)
    // }, [p.flags])
    const searchAreas = React.useMemo(() => asyncWithDefault(asyncSearchAreas, {}), [asyncSearchAreas])
    const objectSearchAreasAssignments = React.useMemo(
        () => asyncWithDefault(asyncSearchAreasAssignments, {})[p.objectId],
        [asyncSearchAreasAssignments, p.objectId]
    )
    const validSA = React.useMemo(() => getSearchAreasFromAssignments(objectSearchAreasAssignments, searchAreas), [
        searchAreas,
        objectSearchAreasAssignments
    ])

    // TODO Refactor TableLayout to take better props than rows
    const primaryHeaderRow = React.useMemo(() => getSAHeaderRow(true, p.isDemo, p.flags), [p.flags, p.isDemo])
    const primaryRows = React.useMemo(() => validSA.primarySearchAreas.map(area => getSARow(area, p)), [validSA, p])
    const secondaryHeaderRow = React.useMemo(() => getSAHeaderRow(false, p.isDemo, p.flags), [p.flags, p.isDemo])
    const secondaryRows = React.useMemo(() => validSA.secondarySearchAreas.map(area => getSARow(area, p)), [validSA, p])

    return (
        <_SearchAreasCardSectionContainer data-cy="search-areas-card-section">
            <_CardSectionContainer>
                <_CardSectionTitle>
                    <_Copy name="searchAreas" />
                </_CardSectionTitle>
                {isFetching(asyncSearchAreas) || isFetching(asyncSearchAreasAssignments) ? (
                    <Loader />
                ) : isEmpty(validSA) ? (
                    <_BodyFont s14>
                        <_Copy name="notPublicSearchArea" />
                    </_BodyFont>
                ) : (
                    <>
                        <TableLayout
                            header={primaryHeaderRow}
                            rows={primaryRows}
                            layout={`minmax(100px, auto) ${p.flags.withPipelines ? "minmax(80px, 100px)" : ""} ${
                                p.flags.withPriorityRanks ? "minmax(80px, 100px)" : ""
                            }`}
                            columnGap="8px"
                            rowGap="15px"
                        />
                        {secondaryRows.length ? (
                            <>
                                <_HRLine />
                                <TableLayout
                                    header={secondaryHeaderRow}
                                    rows={secondaryRows}
                                    layout={`minmax(100px, auto) ${
                                        p.flags.withPipelines ? "minmax(80px, 100px)" : ""
                                    } ${p.flags.withPriorityRanks ? "minmax(80px, 100px)" : ""}`}
                                    columnGap="8px"
                                    rowGap="15px"
                                />
                            </>
                        ) : null}
                    </>
                )}
            </_CardSectionContainer>
        </_SearchAreasCardSectionContainer>
    )
})
