import { isEmpty } from "../../utils/validators"
import { mapSegmentTagsByName, mapTagsIdsByName } from "../tags"
import { getPipelineStagesMap, PIPELINE_STAGE_NA, priorityRanks, ASSIGNMENT_NA } from "../decoratorValues"
import { keys, values } from "../../utils/map"
import { mapAreasByName } from "../../models/searchAreas"
import { assertNever, inRange } from "../../utils"
import { getCachedData, fetchSecureKey } from "../../services/httpEndpoint/cache/cache"
import { cnames, getCollectionSchema } from "../schemas"
import { decryptPipelines } from "../decorators"
import { isRoot } from "../User"
import { Firestore } from "../../services/firebase"
import { collectionFieldsFilterTypes, CollectionFieldsFilterTypes, FieldFilterType } from "./collectionFieldsFilters"

export type StarFilterType = "All (starred and not)" | "Starred" | "Not starred"

// TODO unify filters (remove nulls & maybe use arrays everywhere if possible) & add more tests

export type TimestampFilterType = { from: string | null; to: string | null }

type BaseRangeFilterType = { from: number | null; to: number | null; min?: number | null; max?: number | null }
export type PlainRangeFilterType = BaseRangeFilterType & { flavor: "plain" }
export type FundsRangeFilterType = BaseRangeFilterType & { flavor: "funds" }
export type PercentileRangeFilterType = BaseRangeFilterType & { flavor: "percentile" }

export type StringSearchFilter = string

export type RangeFilterType = PlainRangeFilterType | FundsRangeFilterType | PercentileRangeFilterType

export type FilterTypeFromFieldFilter<T extends FieldFilterType> = T extends "string"
    ? StringSearchFilter
    : T extends "stringSearch"
    ? StringSearchFilter
    : T extends "range"
    ? PlainRangeFilterType
    : T extends "fundsRange"
    ? FundsRangeFilterType
    : T extends "percentileRange"
    ? PercentileRangeFilterType
    : T extends "timestamp"
    ? TimestampFilterType
    : never

export type CollectionFieldsFilters = {
    [K in keyof CollectionFieldsFilterTypes]:
        | {
              [FK in keyof CollectionFieldsFilterTypes[K]]: FilterTypeFromFieldFilter<
                  CollectionFieldsFilterTypes[K][FK] & FieldFilterType
              > | null
          }
        | null
}

export type Filters = Partial<
    {
        tags: string[]
        searchArea: string | null
        searchAreas: string[]
        stars: StarFilterType | null
        pipelines: string[]
        priorities: string[]
        date: TimestampFilterType | null
        collections: CName[]
        mutationType: string[]
        assignments: string[]
        segmentTags: string[]
    } & CollectionFieldsFilters
>

export type FilterType = keyof Filters
export type FilterParams = SCasted<Filters, string[]>

export type FilterEssentials = {
    filters: Filters
    tags: SMap<Tag>
    tagsAssignments: TMap<ObjectId, Assignments<TagId>>
    segmentTags: SMap<SegmentTag>
    segmentTagsAssignments: TMap<ObjectId, Assignments<SegmentTag["segmentId"]>>
    decorators: TMap<ObjectId, DecoratorsMap>
    pipelineStages: SMap<PipelineStageValue>
    searchAreas: SMap<SearchArea>
    searchAreasAssignments: TMap<ObjectId, Assignments<AreaId, SearchAreaAssignmentKind>>
    secureKey: EncryptionKey | null
}

type FilterEssentialsProps<C extends CName> = {
    cname: C
    config: LocationParams
    filters: Filters
    userClaims: TokenClaim
    firestore: Firestore
}

export const getFilterEssentials = async <C extends CName>(p: FilterEssentialsProps<C>): Promise<FilterEssentials> => {
    const rid = p.config.radarId
    const shouldFetchDecorators =
        !isEmpty(p.filters.pipelines) ||
        !isEmpty(p.filters.priorities) ||
        !isEmpty(p.filters.assignments) ||
        !isEmpty(p.filters.stars)
    const shouldFetchSearchAreas = !isEmpty(p.filters.searchArea) || !isEmpty(p.filters.segmentTags)

    return {
        filters: p.filters,
        tags: !isEmpty(p.filters.tags) ? (await getCachedData(rid).tags) || {} : {},
        tagsAssignments: !isEmpty(p.filters.tags) ? (await getCachedData(rid).tagsAssignments) || {} : {},
        segmentTags: !isEmpty(p.filters.segmentTags) ? (await getCachedData(rid).segmentTags) || {} : {},
        segmentTagsAssignments: !isEmpty(p.filters.segmentTags)
            ? (await getCachedData(rid).segmentTagsAssignments) || {}
            : {},
        searchAreas: shouldFetchSearchAreas ? (await getCachedData(rid).searchAreas) || {} : {},
        searchAreasAssignments: shouldFetchSearchAreas ? (await getCachedData(rid).searchAreasAssignments) || {} : {},
        decorators: shouldFetchDecorators ? (await getCachedData(rid).decorators) || {} : {},
        pipelineStages: !isEmpty(p.filters.pipelines) ? (await getCachedData(rid).pipelines) || {} : {},
        secureKey: !isEmpty(p.filters.pipelines) ? await fetchSecureKey(rid, p.firestore, isRoot(p.userClaims)) : null
    }
}

export const isCollectionFilter = (t: FilterType): t is CName & FilterType => cnames.includes(t as any)

export const getSearchStringCollectionFieldFilterKey = <C extends CName & keyof Filters>(
    c: C,
    f: keyof CollectionFieldsFilterTypes[C]
) => `${c}__${f}`

export const getFieldsFilteringResult = <T extends FieldFilterType>(
    type: T,
    filterValue: FilterTypeFromFieldFilter<T> | null,
    itemValue: any
): boolean => {
    const t = type as FieldFilterType // Typeguard for all cases is required
    switch (t) {
        case "string":
        case "stringSearch": {
            const iv = Array.isArray(itemValue)
                ? itemValue.map(ai => ai.toString()).join(", ")
                : (itemValue as string | undefined)
            const fv = filterValue as string | null
            return !fv || !!iv?.toLocaleLowerCase().includes(fv.toLocaleLowerCase())
        }
        case "range":
        case "percentileRange":
        case "fundsRange": {
            const iv = itemValue as number | undefined
            const fv = filterValue as BaseRangeFilterType | null
            return !fv || (iv === undefined ? false : inRange(iv, fv.from || 0, fv.to || Number.POSITIVE_INFINITY))
        }
        case "timestamp": {
            const iv = itemValue as number | undefined
            const fv = filterValue as TimestampFilterType | null
            return !fv || (iv === undefined ? false : inRange(iv, fv.from ? +fv.from : 0, fv.to ? +fv.to : Date.now()))
        }
    }
    assertNever(t)
    return true
}

export const filterByFields = <C extends CName & keyof Filters>(
    cname: C,
    filters: Filters[C],
    item: RCollection<C>
): boolean => {
    const filterTypes = collectionFieldsFilterTypes[cname]
    const fields = keys(filterTypes)
    const r = fields.reduce(
        (acc, f) =>
            !acc
                ? acc
                : !filters ||
                  getFieldsFilteringResult(
                      filterTypes[f] as any,
                      (filters as any)[f] as any,
                      item[f as keyof typeof item]
                  ),
        true
    )
    return r
}

export const filterCollection = <C extends CName, T extends RCollection<C>>(
    deps: FilterEssentials,
    cname: C,
    collection: T[]
): T[] => {
    const filteredSearchAreaId = values(deps.searchAreas).find(sa => sa.name === deps.filters.searchArea)?.areaId
    const idField = getCollectionSchema(cname).idField
    const getId = (v: T): ObjectId => v[idField] as any

    const filterFunctions: F1<T, boolean | undefined>[] = []

    const {
        tags: tagsFilter,
        segmentTags: segmentTagsFilter,
        pipelines: pipelinesFilter,
        priorities: prioritiesFilter,
        assignments: assignmentsFilter,
        stars: starsFilter,
        searchAreas: searchAreasFilter,
        date
    } = deps.filters

    if (!isEmpty(tagsFilter)) {
        const tagsByName = mapTagsIdsByName(deps.tags)
        const filterTagIds = tagsFilter.map(tn => tagsByName[tn]).filter(Boolean)
        filterFunctions.push(c => {
            const objectTagAssignments = deps.tagsAssignments[getId(c)]
            if (isEmpty(objectTagAssignments)) return false
            return filterTagIds.some(tid => objectTagAssignments[tid])
        })
    }
    if (!isEmpty(segmentTagsFilter)) {
        const segmentTagsByName = mapSegmentTagsByName(deps.segmentTags, deps.searchAreas)
        const filterTagIds = segmentTagsFilter.map(stn => segmentTagsByName[stn]?.segmentId).filter(Boolean)

        filterFunctions.push(c => {
            const objectTagAssignments = deps.segmentTagsAssignments[getId(c)]
            if (isEmpty(objectTagAssignments)) return false
            return filterTagIds.some(tid => objectTagAssignments[tid])
        })
    }

    if (filteredSearchAreaId)
        filterFunctions.push(
            c => !filteredSearchAreaId || !!deps.searchAreasAssignments[getId(c)]?.[filteredSearchAreaId] || false
        )

    if (!isEmpty(searchAreasFilter)) {
        const searchAreasByName = mapAreasByName(deps.searchAreas)
        const filteredSearchAreaIds = searchAreasFilter.map(san => searchAreasByName[san]?.areaId).filter(Boolean)

        filterFunctions.push(c => {
            const objectSearchAreaAssignments = deps.searchAreasAssignments[getId(c)]
            if (isEmpty(objectSearchAreaAssignments)) return false
            return filteredSearchAreaIds.some(said => objectSearchAreaAssignments[said])
        })
    }

    if (filteredSearchAreaId && !isEmpty(pipelinesFilter)) {
        const pipelines = decryptPipelines(deps.pipelineStages, deps.secureKey)

        filterFunctions.push(c => {
            const decs = deps.decorators[getId(c)]
            const staticPipelines = getPipelineStagesMap()
            if (!decs?.pipelineStage?.[filteredSearchAreaId]) return pipelinesFilter.includes(PIPELINE_STAGE_NA)
            const ref = decs.pipelineStage[filteredSearchAreaId].valueRef
            if (keys(staticPipelines).includes(ref)) return pipelinesFilter.includes(staticPipelines[ref].name)
            return pipelinesFilter.includes(pipelines[ref]?.name)
        })
    }

    if (filteredSearchAreaId && !isEmpty(prioritiesFilter)) {
        filterFunctions.push(c => {
            const decs = deps.decorators[getId(c)]
            if (!decs?.priorityRank?.[filteredSearchAreaId])
                return prioritiesFilter.includes(priorityRanks.notAvailable)
            return prioritiesFilter.includes(decs.priorityRank[filteredSearchAreaId].value)
        })
    }
    if (!isEmpty(assignmentsFilter)) {
        filterFunctions.push(c => {
            const decs = deps.decorators[getId(c)]
            if (!decs?.assignment) return assignmentsFilter.includes(ASSIGNMENT_NA)
            return assignmentsFilter.includes(decs.assignment.userId)
        })
    }
    if (!isEmpty(starsFilter))
        filterFunctions.push(c => Boolean(deps.decorators[getId(c)]?.star?.value) === (starsFilter === "Starred"))

    if (date) {
        const { from: fromFilter, to: toFilter } = date || { from: null, to: null }
        // TODO Change filters from & to from strings to Timestamps(numbers)
        filterFunctions.push(c => inRange(c.createdTs, fromFilter ? +fromFilter : 0, toFilter ? +toFilter : Date.now()))
    }

    type FieldsFilterKey = keyof Filters & C
    const fieldsFilterValue = deps.filters[cname as FieldsFilterKey]

    if (fieldsFilterValue)
        filterFunctions.push(c =>
            filterByFields(cname as keyof Filters & C, fieldsFilterValue as any, c as RCollection<FieldsFilterKey>)
        )

    return filterFunctions.reduce((col, filter) => col.filter(filter), collection)
}

export type FilterDateRange = {
    from: number // TODO Timestamp
    to: number // TODO Timestamp
}

export const getDefaultFilterDateRange = (filters: Filters, override?: Partial<FilterDateRange>): FilterDateRange => {
    const { from, to } = filters.date || { from: null, to: null }
    return {
        from: from ? +from : 0,
        to: to ? +to : Date.now(),
        ...override
    }
}
