import React, { ChangeEvent } from "react"
import styled from "styled-components"

type CheckboxProps = {
    className?: string
    style?: any
    checked?: boolean
    defaultChecked?: boolean
    disabled?: boolean
    onChange: F1<ChangeEvent<HTMLInputElement>>
    children: any
}
const ModernCheckbox = (props: CheckboxProps) => {
    let isChecked = "checked" in props ? props.checked : props.defaultChecked

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (props.disabled) return
        if (!("checked" in props)) isChecked = event.target.checked

        if (props.onChange) props.onChange(event)
    }

    return (
        <label className={props.className} style={props.style}>
            <span className="checkbox__input">
                <input type="checkbox" checked={isChecked} onChange={handleChange} />
                <span className="checkbox__control">
                    <svg viewBox="0 0 9 7">
                        <use xlinkHref="#checkbox-tick" />
                    </svg>
                </span>
            </span>
            <span className="checkbox__label">{props.children}</span>
        </label>
    )
}

export const PrettyCheckbox = styled(ModernCheckbox)`
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
    color: var(--color);

    .checkbox__input {
        display: grid;
        grid-template-areas: "checkbox";
        input {
            opacity: 0;
            width: 18px;
            height: 18px;
        }
        > * {
            grid-area: checkbox;
        }
    }
    .checkbox__control {
        display: inline-grid;
        width: 18px;
        height: 18px;
        border-radius: 4px;
        border: 2px solid #edeff2;
        background: white;
    }
    .checkbox__control svg {
        width: 9px;
        height: 7px;
        margin-top: 3px;
        margin-left: 3px;
        visibility: hidden;
    }
    .checkbox__input input:checked + .checkbox__control svg {
        visibility: visible;
    }
`
