/* eslint-disable max-lines */
import * as React from "react"
import { isErr, validArrayDef, isOk } from "../../../functions/src/utils/validators"
import { SubpageLayout } from "../../components/Page"
import { Button } from "../../styles/buttons"
import { _VerticalSpace, _Right } from "../../styles/common"
import { actions as cloudActions } from "../../store/cloud/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { actions as dataActions } from "../../store/data/actions"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { Msg } from "../../models/notifications"
import { getUserRadars, isDemoRadarLocation, getCurrentRadar } from "../../models/LocationType"
import { Loader } from "../../components/common/Loader"
import { NotificationMsg } from "../../store/store"
import { getDataByDeps } from "../../store/data/dataSelectors"
import { RadarViewFetchMap, getDecoratingFetchMap } from "../../dependencies"
import { isAdminLocation } from "../../models/LocationType"
import {
    LoadingWithPayload,
    isFetched,
    Loading,
    Loaded,
    isProcessing,
    isNotStarted
} from "../../../functions/src/utils/types"
import { MapDispatch } from "../../utils/redux.types"
import { connect, ConnectedComponent } from "react-redux"
import { LoadableView } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import { _DataContainer } from "./AdminDashboard.styles"
import { cnames, getCollectionSchema } from "../../../functions/src/models/schemas"
import { useFormHook, FormSchema, StyledFormSchema, StyledFormView } from "@react-formless/core"
import { customRenderMap, CustomFields, getStyledInputsRenderMap } from "../../components/form/FormlessInputs"
import { validCName } from "../../../functions/src/utils/domainValidators"
import { _RadarEditFormWrapper } from "./RadarEdit.styles"
import { getAvailableCollections } from "../../../functions/src/models/collections"

const cnamesTuples: Tuples<CName> = cnames.map(cname => [getCollectionSchema(cname).displayName, cname])

const getContactedLeadSchema = (p: Pick<StateProps, "availableCollections">): FormSchema<ByRadarSchema["config"]> => ({
    contactedLeads: {
        type: "select",
        name: "Enable Contacted lead on",
        validators: validArrayDef(validCName) as any,
        // TODO Create issue in react-formless
        values: cnamesTuples.filter(v => p.availableCollections.includes(v[1])) as any
    }
})

const styledSchema: StyledFormSchema<ByRadarSchema["config"], CustomFields<ByRadarSchema["config"]>> = [
    {
        type: "Custom",
        value: { type: "selectableChips", name: "contactedLeads" }
    }
]

export const ContactedLeads: React.FC<StateProps & ActionProps> = p => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const schema = React.useMemo(() => getContactedLeadSchema(p), [p.availableCollections, p.radarConfig])
    const styledInputsRenderMap = React.useMemo(() => getStyledInputsRenderMap(schema), [schema])
    const { formViewProps, handleSubmit, result, resetState } = useFormHook({
        schema,
        initialValue: {
            contactedLeads: p.radarConfig.contactedLeads.filter(v => p.availableCollections.includes(v))
        },
        onSubmit: data => update(p.radar.radarId, data)
    })
    const [state, update] = useCloudAction(p.onUpdate, p.results, ({ result: res }) => {
        p.queueNotification(
            Msg(
                "custom",
                { success: "Successfully updated contacted leads", error: "Error while updating contacted leads" },
                res
            )
        )
        if (isOk(res)) p.onFinished(p.radar.radarId, p.current?.radarName || null, p.current?.hubName || null)
    })

    React.useEffect(() => {
        resetState()
    }, [p.radar.radarId]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <_DataContainer>
            <SubpageLayout>
                <Loader show={isProcessing(state)} size="regular" />
                {isNotStarted(state) && (
                    <_RadarEditFormWrapper>
                        <StyledFormView
                            {...formViewProps}
                            styledSchema={styledSchema}
                            styledInputsRenderMap={styledInputsRenderMap}
                            inputsRenderMap={customRenderMap}
                        />
                        <_VerticalSpace base="16px" />
                        <_Right>
                            <Button data-cy="save-contacted-leads" disabled={isErr(result)} onClick={handleSubmit}>
                                Save
                            </Button>
                        </_Right>
                        <_VerticalSpace base="16px" />
                    </_RadarEditFormWrapper>
                )}
            </SubpageLayout>
        </_DataContainer>
    )
}

export type StateProps = {
    radar: Pick<RadarDetails, "radarId">
    radarConfig: ByRadarConfig
    results: SMap<CloudActionResult>
    availableCollections: CName[]
    current?: LocationParams
}

export type ActionProps = {
    onUpdate: F3<string, string, ByRadarConfig>
    onFinished: F3<RadarId, string | null, string | null>
    queueNotification: F1<NotificationMsg>
}

const mapState = getDataByDeps<StateProps>()(
    RadarViewFetchMap({ config: "all" }, getDecoratingFetchMap("all")),
    (deps, { cloud, auth, data }) => {
        if (!isAdminLocation(auth.params)) return LoadingWithPayload({ state: "Wrong location" }) // never
        if (!isFetched(auth.configs)) return Loading()
        if (!isFetched(data[deps.radar.radarId].config)) return Loading()

        const radars = getUserRadars(auth)
        const current = getCurrentRadar(auth)
        const isDemo = isDemoRadarLocation(auth.params)

        const { radar, config } = deps
        return Loaded({
            radar,
            radarConfig: config,
            results: cloud.actionsResults,
            availableCollections: getAvailableCollections(auth.configs.value[radar.radarId]),
            current: current
                ? isDemo
                    ? (current as LocationParams)
                    : radars.find(r => r.radarId === current.radarId)
                : undefined
        })
    }
)

const mapDispatch: MapDispatch<ActionProps> = d => ({
    onUpdate: (actionId, radarId, data) => d(cloudActions.updateRadarConfig(actionId, { ...data, radarId })),
    onFinished: (radarId: string, radarName: string | null, hubName: string | null) =>
        d(dataActions._fetch(radarId, radarName, hubName, { config: "all" }, { type: "none" })),
    queueNotification: msg => d(uiActions.queueNotification(msg))
})

const LoadableContactedLeads = LoadableView(ViewLoader, ContactedLeads)

export const ContactedLeadsView: ConnectedComponent<typeof LoadableContactedLeads, unknown> = connect(
    mapState,
    mapDispatch
)(LoadableContactedLeads)
