import * as React from "react"
import { _h1 } from "../../styles/typography"
import { _FormViewWrapper } from "../../styles/forms"
import { _VerticalSpace, Flex, _HorizontalSpace } from "../../styles/common"
import { Popup } from "../Popup"
import { PopupHeader, PopupContent, PopupInner } from "../Popup.styles"
import {
    ExportIdsRequest,
    ExportAllRequest,
    ExportDataType,
    ExportFormat
} from "../../../functions/src/services/httpEndpoint/export"
import { useFormHook } from "../../utils/hooks/useFormHook"
import { FormView } from "../form/FormView"
import { Button, SecondaryButton, BaseButton } from "../../styles/buttons"
import styled from "styled-components"
import { _DropdownOptions } from "../Dropdown.styles"

export type ExportFn = F3<ExportFormat, ExportIdsRequest | ExportAllRequest, ExportDataType | undefined>
export type ExportPopupMeta = (ExportIdsRequest | ExportAllRequest) & {
    onExport: ExportFn
    withContactedLeads: boolean | null
}
type SelectExportType = { dataType?: ExportDataType; dataFormat: ExportFormat }

type OwnProps = { onClose: F0; meta: ExportPopupMeta }

export const dataTypes: Tuples<ExportDataType> = [
    ["Basic information", "basic"],
    ["Contacted Lead history", "contactedLeads"]
]

export const dataFormats: Tuples<ExportFormat> = [
    ["CSV", "csv"],
    ["XLSX", "xlsx"]
]

const _ButtonsContainer = styled(Flex)`
    ${BaseButton} {
        min-width: unset;
    }
`

const _SelectExportTypeFormWrapper = styled(_FormViewWrapper)`
    ${BaseButton}, ${_DropdownOptions} {
        width: 100%;
    }
`

const getSelectExportTypeSchema = ({ withContactedLeads }: ExportPopupMeta): FormSchema<SelectExportType> => ({
    ...(withContactedLeads && { dataType: { name: "Data", type: "dropdown", values: dataTypes } }),
    dataFormat: { name: "Data format", type: "dropdown", values: dataFormats }
})

export const SelectExportTypePopup: React.FC<OwnProps> = p => {
    const { onExport, ...payload } = p.meta
    const { formViewProps, onSubmitClick } = useFormHook({
        schema: getSelectExportTypeSchema(p.meta),
        onSubmit: v => {
            onExport(v.dataFormat, payload, v.dataType)
            p.onClose()
        },
        initialValue: {
            dataType: "basic",
            dataFormat: "csv"
        } as SelectExportType
    })
    return (
        <Popup logo onClose={p.onClose}>
            <PopupInner column>
                <PopupHeader>
                    <_h1>Choose {p.meta.withContactedLeads ? `the type of data and the ` : ""}document format.</_h1>
                </PopupHeader>
                <PopupContent>
                    <_VerticalSpace base="16px" />
                    <_SelectExportTypeFormWrapper>
                        <FormView {...formViewProps} />
                        <_VerticalSpace base="40px" />
                        <_ButtonsContainer>
                            <SecondaryButton onClick={p.onClose}>Cancel</SecondaryButton>
                            <_HorizontalSpace base="24px" />
                            <Button onClick={onSubmitClick}>Export</Button>
                        </_ButtonsContainer>
                    </_SelectExportTypeFormWrapper>
                </PopupContent>
            </PopupInner>
        </Popup>
    )
}
