import styled from "styled-components"
import { BaseButton } from "./buttons"

export const NavInline = styled.div`
    display: flex;
    button {
        &:first-child {
            border-radius: 4px 0 0 4px;
            border-right: 0;
        }
        &:last-child {
            border-radius: 0 4px 4px 0;
        }
    }
`

export const NavButton = styled(BaseButton)<{ disabled?: boolean }>`
    width: 45px;
    background-repeat: no-repeat;
    background-position: center center;
    transition: background-color 0.2s linear;
    background-color: #fff;
    border: 1px solid ${({ theme }) => theme.colors.theme2};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    cursor: pointer;
    &:not(:disabled):hover {
        background-color: ${({ theme }) => theme.colors.theme4};
    }
    &:disabled {
        cursor: default;
        border: 1px solid ${({ theme }) => theme.colors.theme2};
        svg {
            opacity: 0.15;
        }
    }
`
