import * as React from "react"
import { createPortal } from "react-dom"
import { selectCreateDiv } from "../utils/html5"
import { actions } from "../store/ui/actions"
import { connect } from "react-redux"
import { _Notification, _Spacer, _NotificationTitle, _NotificationClose } from "../components/Notification.styles"
import { NotificationMsg } from "../store/store"
import { MapState, MapDispatch } from "../utils/redux.types"
import { IconSvg } from "../components/IconSvg"

export type StateProps = { msg: Maybe<NotificationMsg> }
export type ActionProps = { onXClick: F0 }
export type NotificationProps = NotificationMsg & ActionProps

export const Notification: React.FC<NotificationProps> = p => (
    <_Notification type={p.type}>
        <_Spacer />
        <_NotificationTitle>{p.text}</_NotificationTitle>
        <_NotificationClose onClick={p.onXClick}>
            <IconSvg name="close-white" width={15} height={15} />
        </_NotificationClose>
    </_Notification>
)

const NotificationPortal: React.FC<StateProps & ActionProps> = ({ msg, onXClick }) =>
    createPortal(
        msg.type === "Just" ? <Notification {...msg.value} onXClick={onXClick} key={msg.value.id} /> : null,
        selectCreateDiv("toasts")
    )

const mapState: MapState<StateProps> = ({ ui }) => ({ msg: ui.currentMsg })
const mapDispatch: MapDispatch<ActionProps> = d => ({ onXClick: () => d(actions.hideMsg()) })

export const NotificationRenderer = connect(mapState, mapDispatch)(NotificationPortal)
