import * as React from "react"
import { Popup } from "../Popup"
import { PopupInner } from "../Popup.styles"
import { ByIdsListData, ByIdsListView } from "../../views/lists/ByIdsList"

type OwnProps = { onClose: F0; meta: ByIdsListData }

export const ByIdsListPopup: React.FC<OwnProps> = p => {
    return (
        <Popup logo full onClose={p.onClose}>
            <PopupInner column minHeight={40} noPadding>
                <ByIdsListView data={p.meta} />
            </PopupInner>
        </Popup>
    )
}
