import * as React from "react"
import styled from "styled-components"
import { _FlexColumn } from "../styles/common"

const _IntersectionWrapper = styled(_FlexColumn)<{ height: string }>`
    min-height: ${p => p.height};
`

type IntersectionLoaderProps = { height?: number }

export const IntersectionLoader = ({ children, height = 100 }: React.PropsWithChildren<IntersectionLoaderProps>) => {
    const [ref, setRef] = React.useState<HTMLElement | null>(null)
    const [show, setShow] = React.useState(false)

    React.useEffect(() => {
        let observer: IntersectionObserver
        if (!ref) return

        if (IntersectionObserver) {
            observer = new IntersectionObserver(entries =>
                entries.forEach(entry => {
                    if (entry.intersectionRatio > 0 || entry.isIntersecting) {
                        setShow(true)
                        observer.unobserve(ref)
                    }
                })
            )
            observer.observe(ref)
        } else {
            setShow(true)
        }
    }, [ref])
    return (
        <_IntersectionWrapper height={show ? "auto" : `${height}px`} ref={setRef}>
            {show && children}
        </_IntersectionWrapper>
    )
}
