/* eslint-disable max-lines */
import * as React from "react"

import { initialFilters } from "../../store/ui"

import {
    Flex,
    _AbsPosContainer,
    _AbsPosElement,
    _FlexContainer,
    _VerticalSpace,
    _FlexColumn
} from "../../styles/common"
import { Filter, FilterProps, FilterActions, FiltersLabelMap } from "./Filter"
import { LinkButton } from "../../styles/buttons"
import { Loader } from "../common/Loader"
import { _FiltersColumnContainer } from "./FiltersColumn.styles"
import { _TagFont } from "../../styles/typography"
import { CommonFilterProps } from "../../containers/Filters"
import { FilterType } from "../../../functions/src/models/filtering"

type FiltersColumnProps = CommonFilterProps & {
    onChange: (key: FilterType) => FilterActions["onChange"]
    onClear: (key: FilterType) => FilterActions["onClear"]
    areFiltersSet: boolean
}

export const FiltersColumn = React.memo((p: FiltersColumnProps) => (
    <_AbsPosContainer>
        <_AbsPosElement>
            <_FlexColumn grow={1} style={{ maxWidth: "100%" }}>
                <_FiltersColumnContainer>
                    {p.filters.map(filterType =>
                        p[filterType].loading ? (
                            <Loader size="small" key={filterType} />
                        ) : (p[filterType] as Loaded<FilterProps>).value.isHidden ? null : (
                            <Filter
                                key={filterType}
                                label={FiltersLabelMap[filterType]}
                                onChange={p.onChange(filterType)}
                                onClear={p.onClear(filterType)}
                                {...(p[filterType] as Loaded<FilterProps>)}
                                column
                            />
                        )
                    )}
                    {p.withClear && p.areFiltersSet && (
                        <Flex>
                            <LinkButton color="theme6" onClick={() => p.resetFilters(initialFilters)} inline>
                                Clear filters
                            </LinkButton>
                        </Flex>
                    )}
                    {p.children}
                </_FiltersColumnContainer>
            </_FlexColumn>
        </_AbsPosElement>
    </_AbsPosContainer>
))
