import { validate, Err, Ok, validString, runValidatorsRaw, validNumber, isErr } from "../utils/validators"
import { ActionsType, Action, CloudActions, PublicCloudActions } from "./actionCreators"
import { userActionsPayloadValidators } from "./user/userValidators"
import { publicActionsPayloadValidators } from "./public/publicValidators"
import { adminActionsPayloadValidators, validateImportChunkResult } from "./admin/adminValidators"
import { radarActionsPayloadValidators } from "./radar/radarValidators"
import { keys } from "../utils/map"

export type CloudActionCtx = { senderId: string; env: Env }
export type PublicActionContext = { actionId: string }

export const userActionErrors = {
    cannotSendEmail: "Cannot send email",
    invitationAlreadyExisting: "Invitation already exists.",
    invitationNotExisting: "Invitation does not exist.",
    radarNotExisting: "Given radar id doesnt match any existsing radars",
    requestNotExisting: "Given request doesnt exist",
    userNotExisting: "Given user doesnt exist"
}
export const actionErrors = {
    invalidPayload: "Invalid action payload",
    invalidMeta: "Invalid action meta",
    notAnAction: "Value is not an action",
    wrongActionType: "Action type not recognized",
    noClaim: "Error fetching claims",
    noPermission: "No permission for this acition",
    noPermissionFor: (who: string) => `No permission of ${who} for this acition`
}

export type CloudActionsValidationMap<T extends CloudActions> = TMap<T["type"], Validators<T["payload"]>>

export const validateAction = <T extends keyof ActionsType, A extends CloudActions>(
    map: CloudActionsValidationMap<A>
) => (v: any): Result<Action<T>> => {
    if (!v.type || `${v.type}`.startsWith("_")) return Err(actionErrors.wrongActionType, v)
    const payload = validateActionPayload(map, v)
    if (isErr(payload)) return Err(actionErrors.invalidPayload, payload)
    const meta = validateActionMeta(v)
    if (isErr(meta)) return Err(actionErrors.invalidMeta, meta)
    return Ok({ type: v.type, meta: meta.value, payload: payload.value }) as Result<Action<T>>
}

export const validateActionMeta = (v: any): Result<CloudActionMeta> => {
    if (!v.meta) return Err(actionErrors.notAnAction)
    const validators: ValidationMap<CloudActionMeta> = { actionId: validString }
    return runValidatorsRaw([validate<CloudActionMeta>(validators)], v.meta)
}

export const validateActionPayload = (
    map: CloudActionsValidationMap<CloudActions>,
    v: any
): Result<CloudActions["payload"]> => {
    if (!v || !v.type) return Err(actionErrors.notAnAction)
    const validators = map[(v as CloudActions).type]
    if (!validators) return Err(actionErrors.wrongActionType)
    return runValidatorsRaw(validators, v.payload)
}

export const makeCloudActionsCtx = (v: any, env: Env): Result<CloudActionCtx> => Ok({ senderId: v.userId, env })
export const validateCFPublicState = (v: any, env: Env): Result<PublicActionContext> =>
    Ok({ actionId: v.actionId, env })

const allPayloadValidators = {
    ...userActionsPayloadValidators,
    ...publicActionsPayloadValidators,
    ...adminActionsPayloadValidators,
    ...radarActionsPayloadValidators
}

const isSpecialValidateAction = (type: Type<CloudActions>) =>
    type === "@importChunk" || keys(publicActionsPayloadValidators).includes(type as Type<PublicCloudActions>)

export const validateActionFromResult = (v: any): Result<CloudAction> => {
    if (!v.type || `${v.type}`.startsWith("_")) return Err(actionErrors.wrongActionType, v)
    if (!isSpecialValidateAction(v.type)) return validateAction(allPayloadValidators)(v) as any
    const payload =
        (v.type as Type<CloudActions>) === "@importChunk"
            ? runValidatorsRaw([validateImportChunkResult], v.payload)
            : Ok({}) // we ignore validation of public actions
    if (isErr(payload)) return Err(actionErrors.invalidPayload, payload)
    const meta = validateActionMeta(v)
    if (isErr(meta)) return Err(actionErrors.invalidMeta, meta)
    const a = v as CloudAction
    return Ok({ type: v.type, meta: meta.value, payload: payload.value, actionId: a.actionId }) as Result<CloudAction>
}
export const validateActionResult = validate<CloudActionResult>({
    action: [validateActionFromResult],
    actionId: validString,
    createdTs: validNumber,
    result: null,
    author: null
})
