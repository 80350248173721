import {
    validString,
    validate,
    validNumber,
    validArrayStringDef,
    validStringDef,
    validNumberDef
} from "../utils/validators"
import { mapToDisplay } from "./common"
import { RFields } from "./RFields"
import {
    DEFAULT_PLACEHOLDER,
    sharedRequiredRadarFieldsValidationMap,
    sharedOptionalRadarFieldsValidationMap,
    sharedVMMetaValidationMap
} from "./shared"
import { PatentVM } from "./ViewModels"

const patentRequiredFieldsValidationMap: ValidationMap<Required<PatentFieldsRequired>> = {
    patent_title: validString,
    publication_number: validStringDef, // TODO legacy
    ...sharedRequiredRadarFieldsValidationMap
}

const patentOptionalFieldsValidationMap: ValidationMap<Required<PatentFieldsOptional>> = {
    ...sharedOptionalRadarFieldsValidationMap,
    filing_location: validStringDef,
    holder: validStringDef,
    num_citations: validNumberDef,
    patent_url: validStringDef,
    patent_url_2: validStringDef,
    application_date: validStringDef,
    current_assignee: validStringDef,
    estimated_expiration_date: validStringDef,
    original_assignee: validStringDef,
    patent_type: validStringDef,
    publication_date: validStringDef
}

export const patentFieldsValidation: ValidationMap<Required<PatentFields>> = {
    ...patentRequiredFieldsValidationMap,
    ...patentOptionalFieldsValidationMap
}

export const patentRelationsValidationMap: ValidationMap<Required<PatentRelations>> = {
    startups: validArrayStringDef,
    companies: validArrayStringDef,
    influencers: validArrayStringDef,
    research_hubs: validArrayStringDef,
    research_papers: validArrayStringDef,
    tech_experts: validArrayStringDef,
    tech_transfers: validArrayStringDef
}

export const patentRelations: RFields<"patents"> = RFields<unknown, PatentRelations>({}, patentRelationsValidationMap)

export const patentFields: CFields<"patents"> = RFields<PatentFieldsRequired, PatentFieldsOptional>(
    patentRequiredFieldsValidationMap,
    patentOptionalFieldsValidationMap
)

export const patentValidationMap: ValidationMap<Required<Patent>> = {
    ...patentFieldsValidation,
    patentId: validString,
    createdTs: validNumber
}

export const validatePatentRelations = validate<PatentRelations>(patentRelationsValidationMap)
export const validatePatentFields = validate<PatentFields>(patentFieldsValidation)
export const validatePatent = validate<Patent>(patentValidationMap)
export const validatePatentVM = validate<PatentVM>({
    ...patentValidationMap,
    ...sharedVMMetaValidationMap
})

export const displayPatent = (p: PatentVM, ph: string = DEFAULT_PLACEHOLDER): Displayable<PatentVM> =>
    mapToDisplay(p, ph)

export const PatentFieldsDisplay: Displayable<PatentVM> = {
    patent_title: "Patent title",
    description: "Description",
    filing_location: "Filing location",
    holder: "Holder",
    num_citations: "Number of citations",
    patent_url: "Patent URL",
    patent_url_2: "Patent URL 2",
    publication_number: "Publication number",
    source: "Source",
    blurOnListing: "",
    createdTs: "Created At",
    commentsCount: "No. of comments",
    contactedLeadsCount: "No. of contacted leads",
    patent_type: "Patent Type",
    original_assignee: "Original assignee",
    current_assignee: "Current assignee",
    application_date: "Application date",
    publication_date: "Publication date",
    estimated_expiration_date: "Estimated expiration date",
    patentId: "ID",
    filterBounds: ""
}
