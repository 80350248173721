import * as React from "react"
import { _RadarNavigationContainer } from "./RadarNavigation.styles"
import { NavigationDropdown } from "./NavigationDropdown"
import { toOption } from "../../../functions/src/utils/types"
import { MapState } from "../../utils/redux.types"
import { radarPaths } from "../../paths"
import { getUserRadars, isDemoRadarLocation, getCurrentRadar } from "../../models/LocationType"
import { connect } from "react-redux"
import { _noop } from "../../../functions/src/utils"
import { materialize } from "../../utils/router"
import { isRoot } from "../../models/LoginStatus"

type RadarNavigationProps = {
    radars: LocationParams[]
    current?: LocationParams
}

const RadarNavigation = ({ current, radars }: RadarNavigationProps) => {
    const options = radars
        .sort((r1, r2) => (r1.radarName.toLocaleLowerCase() < r2.radarName.toLocaleLowerCase() ? -1 : 1))
        .map(r => toOption(r.radarName, materialize(radarPaths["radar/radar"].path, { radarSlug: r.radarSlug })))
    const selected = current && options.find(o => o.label === current.radarName)

    return (
        <_RadarNavigationContainer>
            Radar &nbsp;/&nbsp;
            <NavigationDropdown
                full
                optionsXLocation="left"
                options={options}
                onSelect={_noop}
                selected={selected && [selected]}
            />
        </_RadarNavigationContainer>
    )
}

const mapState: MapState<RadarNavigationProps> = ({ auth }) => {
    const radars = getUserRadars(auth)
    const current = getCurrentRadar(auth)
    const isDemo = isDemoRadarLocation(auth.params)

    return {
        radars: isRoot(auth.authentication) && current ? radars.filter(r => r.hubId === current.hubId) : radars,
        current: current
            ? isDemo
                ? (current as LocationParams)
                : radars.find(r => r.radarId === current.radarId)
            : undefined
    }
}

export default connect(mapState)(RadarNavigation)
