import { validate, validNumber, validString, validStringDef, validArrayStringDef } from "../utils/validators"
import { mapToDisplay } from "./common"
import { RFields } from "./RFields"
import {
    DEFAULT_PLACEHOLDER,
    sharedRequiredRadarFieldsValidationMap,
    sharedOptionalRadarFieldsValidationMap,
    sharedVMMetaValidationMap
} from "./shared"
import { ResearchPaperVM } from "./ViewModels"

const researchPaperRequiredFieldsValidation: ValidationMap<Required<ResearchPaperFieldsRequired>> = {
    paper_url: validString,
    paper_title: validString,
    ...sharedRequiredRadarFieldsValidationMap
}

const researchPaperOptionalFieldsValidation: ValidationMap<Required<ResearchPaperFieldsOptional>> = {
    ...sharedOptionalRadarFieldsValidationMap,
    actively_involved: validStringDef,
    publication_year: validStringDef,
    research_group: validStringDef,
    location: validStringDef,
    university: validStringDef
}

export const researchPaperRelationsValidationMap: ValidationMap<Required<ResearchPaperRelations>> = {
    research_hubs: validArrayStringDef,
    startups: validArrayStringDef,
    companies: validArrayStringDef,
    influencers: validArrayStringDef,
    investors: validArrayStringDef,
    patents: validArrayStringDef,
    sectors: validArrayStringDef,
    tech_experts: validArrayStringDef,
    tech_transfers: validArrayStringDef
}

export const researchPaperRelations: RFields<"research_papers"> = RFields<unknown, ResearchPaperRelations>(
    {},
    researchPaperRelationsValidationMap
)

export const researchPaperFieldsValidation: ValidationMap<Required<ResearchPaperFields>> = {
    ...researchPaperRequiredFieldsValidation,
    ...researchPaperOptionalFieldsValidation
}

export const researchPaperValidationMap: ValidationMap<Required<ResearchPaper>> = {
    ...researchPaperFieldsValidation,
    researchPaperId: validString,
    createdTs: validNumber
}
export const researchPaperFields: CFields<"research_papers"> = RFields<
    ResearchPaperFieldsRequired,
    ResearchPaperFieldsOptional
>(researchPaperRequiredFieldsValidation, researchPaperOptionalFieldsValidation)

export const validateResearchPaperRelations = validate<ResearchPaperRelations>(researchPaperRelationsValidationMap)
export const validateResearchPaperFields = validate<ResearchPaperFields>(researchPaperFieldsValidation)
export const validateResearchPaper = validate<ResearchPaper>(researchPaperValidationMap)
export const validateResearchPaperVM = validate<ResearchPaperVM>({
    ...researchPaperValidationMap,
    ...sharedVMMetaValidationMap
})

export const displayResearchPaper = (
    p: ResearchPaperVM,
    ph: string = DEFAULT_PLACEHOLDER
): Displayable<ResearchPaperVM> => mapToDisplay(p, ph)

export const ResearchPaperFieldsDisplay: Displayable<ResearchPaperVM> = {
    paper_title: "Paper title",
    description: "Description",
    actively_involved: "Actively involved",
    location: "Location",
    paper_url: "Paper link",
    source: "Source",
    publication_year: "Publication year",
    research_group: "Research group",
    university: "University",
    blurOnListing: "",
    createdTs: "Created At",
    commentsCount: "No. of comments",
    contactedLeadsCount: "No. of contacted leads",
    researchPaperId: "ID",
    filterBounds: ""
}
