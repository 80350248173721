import { isFetched, isFetchedError } from "../../../../functions/src/utils/types"
import * as React from "react"
import { getDisplayNames } from "../../../../functions/src/models/collections"
import {
    Filters,
    FilterTypeFromFieldFilter,
    getSearchStringCollectionFieldFilterKey
} from "../../../../functions/src/models/filtering"
import {
    collectionFieldsFilterTypes,
    FieldFilterType
} from "../../../../functions/src/models/filtering/collectionFieldsFilters"
import { FilterBounds } from "../../../../functions/src/models/ViewModels"
import { cnames } from "../../../../functions/src/models/schemas"
import { assertNever } from "../../../../functions/src/utils"
import { identity, keys, remap, toMap } from "../../../../functions/src/utils/map"
import { toDateRange } from "../../../models/dateTime"
import { asyncConnect } from "../../../store/async/asyncConnect"
import { DateFilterProps, Filter, FilterProps, InputFilterProps, RangeFilterProps, SearchFilterProps } from "../Filter"
import { mapFilterValue } from "../utils"
import { isNil } from "lodash/fp"

type CollectionFieldsFilterProps<C extends CName & keyof Filters> = {
    collection: C
    onChange: F1<{ type: "collection"; v: Filters[C] }>
    onClear: F1<{ type: "collection"; v: Filters[C] }>
}

const initialCollectionFilters: {
    [C in CName & keyof Filters]: Record<keyof NonNullable<Required<Filters>[C]>, null>
} = toMap(cnames as (CName & keyof Filters)[], identity, c =>
    remap(collectionFieldsFilterTypes[c], identity, () => null)
) as any

type GetPropsForFilterProps = {
    [FT in FieldFilterType]: { type: FT; value: FilterTypeFromFieldFilter<FT> | null; key?: string }
}[FieldFilterType]

export const typeToFlavor = (type: string): "percentile" | "funds" | "plain" => {
    if (type == "percentileRange") return "percentile"
    else if (type == "fundsRange") return "funds"
    return "plain"
}

export const getPropsForFilter = (p: GetPropsForFilterProps): FilterProps => {
    switch (p.type) {
        case "string":
            return InputFilterProps({ value: p.value || "" })
        case "stringSearch":
            return SearchFilterProps({ value: p.value || "" })
        case "range":
        case "fundsRange":
        case "percentileRange":
            const valueFrom = isNil(p.value?.from) ? Number.MIN_SAFE_INTEGER : (p.value?.from as number)
            const valueTo = p.value?.to ?? p.value?.max ?? Number.MAX_SAFE_INTEGER
            return RangeFilterProps({
                value: [valueFrom, valueTo],
                min: isNil(p.value?.min) ? undefined : p.value?.min, // Never a null, but can be undefined
                max: isNil(p.value?.max) ? undefined : p.value?.max,
                flavor: typeToFlavor(p.type)
            })
        case "timestamp":
            return DateFilterProps({
                value: toDateRange({ from: p.value?.from || null, to: p.value?.to || null }) as any
            })
    }
    assertNever(p)
}

function filtersWithBounds(filters: any, bounds: any) {
    if (!bounds) return filters
    return { ...filters, ...bounds }
}

function asyncFilterBounds<V>(models: Async<V>): FilterBounds {
    if (isFetched(models)) return models.filterBounds
    else if (isFetchedError(models)) return { min: undefined, max: undefined }

    return { min: undefined, max: undefined }
}

export const CollectionFieldsFilter = asyncConnect<CollectionFieldsFilterProps<CName & keyof Filters>>()({
    data: ["filters", "viewModels"]
})(p => {
    const filterTypes = collectionFieldsFilterTypes[p.collection]
    const currentFilters = p.filters[p.collection]
    const displayNames = getDisplayNames(p.collection)({ mode: "list" })
    const bounds = asyncFilterBounds(p.viewModels?.[p.collection] as any)

    return (
        <>
            {keys(filterTypes).map(f => (
                <Filter
                    key={getSearchStringCollectionFieldFilterKey(p.collection, f)}
                    label={displayNames[f]}
                    onChange={v => {
                        p.onChange({
                            type: "collection",
                            v: currentFilters
                                ? { ...currentFilters, [f]: mapFilterValue(v) }
                                : { ...initialCollectionFilters[p.collection], [f]: mapFilterValue(v) }
                        })
                    }}
                    onClear={() => {
                        p.onClear({
                            type: "collection",
                            // Same as onChange. But sets our current field to null, without touching others.
                            v: currentFilters
                                ? { ...currentFilters, [f]: null }
                                : { ...initialCollectionFilters[p.collection], [f]: null }
                        })
                    }}
                    {...getPropsForFilter({
                        key: f,
                        type: filterTypes[f],
                        value: filtersWithBounds(p.filters[p.collection]?.[f] ?? null, bounds?.[f])
                    } as GetPropsForFilterProps)}
                    column
                />
            ))}
        </>
    )
})
