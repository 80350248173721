import { pipe } from "lodash/fp"
import { Filters } from "../../../functions/src/models/filtering"
import { getSortingParams, Sorting } from "../../../functions/src/models/sorting"
import { ViewModelsBase, DependencySchema } from "../../../functions/src/models/ViewModels"
import { DataRequestPayload } from "../../../functions/src/services/httpEndpoint/data"
import { isOk } from "../../../functions/src/utils/validators"
import { getFirebase } from "../../services/firebase"
import { Cancellable, cancellable } from "../../utils/cancellable"
import { dataSelectors } from "./selectors"
import { actions as httpActions } from "../../../functions/src/services/httpEndpoint/actions"
import { AsyncState, CancellableAsyncType, RootState } from "../store"
import { asyncWithDefault, isFetched } from "../../../functions/src/utils/types"
import { getSearchAreaIdFromName } from "../../../functions/src/models/searchAreas"
import { flatten, pickObject, values } from "../../../functions/src/utils/map"

type FetchCollectionDataConfig<C extends CName> = {
    cname: C
    radarId: string
    filters: Filters
    sorting: Sorting
    schema?: DependencySchema
    cursor?: Cursor
}

const fetchCollectionData = async <C extends CName>(
    p: Required<FetchCollectionDataConfig<C>>
): Promise<ViewModelsBase[C]> => {
    const payload: DataRequestPayload = pickObject(p, ["radarId", "cursor", "schema", "filters", "sorting"])
    const res = await getFirebase().functions.callFunctions(httpActions.data(payload))
    if (!isOk(res)) return {}
    const resValue = (res.value[p.cname] as unknown) as Async<ViewModelsBase[C]>
    return isFetched(resValue) ? resValue.value : {}
}

export const dataFetchActions = {
    fetchCollectionData,
    dashboardSummary: async (radarId: string, filters: Filters) =>
        getFirebase().functions.callFunctions(httpActions.dashboardSummary({ radarId, filters }))
}

export const dataResolvers = {
    topObjects: <C extends CName>(s: RootState, p: [OmitStrict<FetchCollectionDataConfig<C>, "radarId">]) =>
        pipe(
            () => ({
                config: dataSelectors.currentRadarConfig(s),
                filters: dataSelectors.filters(s),
                flags: dataSelectors.flags(s),
                searchAreas: asyncWithDefault(dataSelectors.viewModels(s).searchAreas, {})
            }),
            ({ config, flags, filters, searchAreas }) => () => {
                const cname = p[0].cname
                const searchAreaId = getSearchAreaIdFromName(values(searchAreas))(filters.searchArea!)
                const defaultSorting = getSortingParams({
                    cname: cname,
                    config,
                    searchAreaId
                })
                const sorting = p[0].sorting[cname]?.[0]?.sortingKey
                    ? p[0].sorting
                    : {
                          [cname]: flatten([
                              flags.withStars && defaultSorting[0]?.sortingKey !== "star"
                                  ? [
                                        {
                                            sortingKey: "star",
                                            sortingAsc: false
                                        }
                                    ]
                                  : [],
                              getSortingParams({
                                  cname: cname,
                                  config,
                                  searchAreaId
                              })
                          ])
                      }
                return dataFetchActions.fetchCollectionData({
                    radarId: config.radarId!,
                    ...p[0],
                    sorting,
                    cursor: {
                        type: "page",
                        value: {
                            startingAfter: 0,
                            limit: 10
                        }
                    },
                    schema: {
                        [p[0].cname]: "page"
                    } as DependencySchema
                })
            },
            cancellable
        )(),
    objects: <C extends CName>(s: RootState, p: [OmitStrict<FetchCollectionDataConfig<C>, "radarId">]) =>
        pipe(
            () => ({ config: dataSelectors.currentRadarConfig(s) }),
            ({ config }) => () =>
                dataFetchActions.fetchCollectionData({
                    radarId: config.radarId!,
                    ...p[0],
                    schema:
                        p[0].schema ||
                        ({
                            [p[0].cname]: "page"
                        } as DependencySchema),
                    cursor: p[0].cursor || {
                        type: "page",
                        value: {
                            startingAfter: 0,
                            limit: 50
                        }
                    }
                }),
            cancellable
        )(),
    dashboardSummary: (s: RootState, p: [Filters]) =>
        pipe(
            dataSelectors.currentRadarConfig,
            rc => () => dataFetchActions.dashboardSummary(rc.radarId!, p[0]),
            cancellable
        )(s)
}

export type DataResolvers = typeof dataResolvers
export type DataResolverName = keyof DataResolvers

export type DataResolveFn<R extends DataResolverName> = (
    ...args: Parameters<DataResolvers[R]> extends [any, infer P] ? (P extends unknown[] ? P : never) : never
) => void

export const stateDataResolversMap: {
    [K in keyof AsyncState]: (s: RootState) => Cancellable<CancellableAsyncType<AsyncState[K]>>
} = {
    topStartups: s =>
        dataResolvers.topObjects(s, [
            { cname: "startups", filters: dataSelectors.filters(s), sorting: dataSelectors.sorting(s) }
        ]),
    topSectors: s =>
        dataResolvers.topObjects(s, [
            { cname: "sectors", filters: dataSelectors.filters(s), sorting: dataSelectors.sorting(s) }
        ]),
    topCompanies: s =>
        dataResolvers.topObjects(s, [
            { cname: "companies", filters: dataSelectors.filters(s), sorting: dataSelectors.sorting(s) }
        ]),
    topPatents: s =>
        dataResolvers.topObjects(s, [
            { cname: "patents", filters: dataSelectors.filters(s), sorting: dataSelectors.sorting(s) }
        ]),
    topTechTransfers: s =>
        dataResolvers.topObjects(s, [
            { cname: "tech_transfers", filters: dataSelectors.filters(s), sorting: dataSelectors.sorting(s) }
        ]),
    topClinicalTrials: s =>
        dataResolvers.topObjects(s, [
            { cname: "clinical_trials", filters: dataSelectors.filters(s), sorting: dataSelectors.sorting(s) }
        ]),
    topResearchPapers: s =>
        dataResolvers.topObjects(s, [
            { cname: "research_papers", filters: dataSelectors.filters(s), sorting: dataSelectors.sorting(s) }
        ]),
    topGrants: s =>
        dataResolvers.topObjects(s, [
            { cname: "grants", filters: dataSelectors.filters(s), sorting: dataSelectors.sorting(s) }
        ]),
    topInvestors: s =>
        dataResolvers.topObjects(s, [
            { cname: "investors", filters: dataSelectors.filters(s), sorting: dataSelectors.sorting(s) }
        ]),
    topResearchHubs: s =>
        dataResolvers.topObjects(s, [
            { cname: "research_hubs", filters: dataSelectors.filters(s), sorting: dataSelectors.sorting(s) }
        ]),
    topTechExperts: s =>
        dataResolvers.topObjects(s, [
            { cname: "tech_experts", filters: dataSelectors.filters(s), sorting: dataSelectors.sorting(s) }
        ]),
    topInfluencers: s =>
        dataResolvers.topObjects(s, [
            { cname: "influencers", filters: dataSelectors.filters(s), sorting: dataSelectors.sorting(s) }
        ]),
    topPatentHolders: s =>
        dataResolvers.topObjects(s, [
            { cname: "patent_holders", filters: dataSelectors.filters(s), sorting: dataSelectors.sorting(s) }
        ]),
    dashboardSummary: s => dataResolvers.dashboardSummary(s, [dataSelectors.filters(s)]),
    sectorsPositioning: s =>
        dataResolvers.objects(s, [
            {
                cname: "sectors",
                filters: {},
                sorting: {
                    sectors: [
                        {
                            sortingAsc: false,
                            sortingKey: "investor_quality"
                        }
                    ]
                }
            }
        ])
}
