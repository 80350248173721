import styled, { css } from "styled-components"
import { LinkButton } from "../../styles/buttons"
import { styleIfProp, _FlexColumn } from "../../styles/common"
import { FORM_MAX_WIDTH, INPUT_MAX_WIDTH } from "../../styles/forms"
import { ThemeColor } from "../../styles/styled"
import { _IconSvgContainer } from "../IconSvg.styles"

const commonLabelStyles = css<{ error?: boolean; color?: ThemeColor }>`
    display: flex;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 0.3px;
    color: ${({ theme, error, color }) =>
        error ? theme.colors.danger : color ? theme.colors[color] : theme.colors.primary};
    font-weight: 400;
`

export const _Label = styled.label<{ error?: boolean; color?: ThemeColor }>`
    ${commonLabelStyles};

    &:not(:empty) {
        padding-bottom: 15px;
        padding-left: 4px;
    }

    ${_IconSvgContainer} {
        margin-top: -2px;
    }
`

export const _ErrorLabel = styled.div`
    ${commonLabelStyles};

    color: red;
    min-height: 19px;
    padding-left: 4px;
    margin-top: 3px;
    margin-bottom: 8px;
    align-items: flex-start;
`

export const _Input = styled.input<{ validationError?: boolean }>`
    min-width: 280px;
    max-width: ${INPUT_MAX_WIDTH};
    height: 40px;
    border-radius: 20px;
    border: solid 1px ${styleIfProp("validationError", "#ff151f", "#edeff2")};
    &:focus {
        border: solid 1px ${styleIfProp("validationError", "#ff151f", "#617084")};
    }
    background-color: #ffffff;
    padding: 15px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: ${({ theme }) => styleIfProp("validationError", "#ff151f", theme.colors.primary)};
    outline: none;

    &::placeholder {
        color: ${() => styleIfProp("validationError", "#ff151f", "#a0a5ae")};
    }

    &:active {
        border: solid 1px #617084;
    }
`

export const _PointInput = styled(_Input)`
    min-width: 0;
    max-width: none;
    margin: 0;
`

export const _TextArea = styled.textarea<{ validationError: boolean }>`
    min-width: 280px;
    resize: vertical;
    max-width: ${INPUT_MAX_WIDTH};
    min-height: 84px;
    border-radius: 20px;

    border: solid 1px #edeff2;
    border: solid 1px ${styleIfProp("validationError", "#ff151f", "#edeff2")};
    &:focus {
        border: solid 1px ${styleIfProp("validationError", "#ff151f", "#617084")};
    }
    background-color: #ffffff;
    padding: 15px;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal;
    color: ${({ theme }) => theme.colors.primary};
    resize: vertical;
    &:focus {
        border: 1px solid #617084;
    }
`

export const _RadioView = styled.div`
    display: flex;
    flex-direction: column;
`

export const _RadioWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 36px;
    color: ${({ theme }) => theme.colors.primary};
    & label {
        display: flex;
        cursor: pointer;
        align-items: center;
    }
`

export const _InputWrapper = styled.div<{ withMaxWidth?: boolean }>`
    display: flex;
    flex-direction: column;
    ${styleIfProp("withMaxWidth", `max-width: ${FORM_MAX_WIDTH};`)}/* ${_ErrorLabel} {
        align-self: flex-end;
    } */
`

export const _TinyInputWrapper = styled.div<{ rightAlign?: boolean }>`
    display: flex;
    height: 16px;
    width: 66px;
    min-width: 66px;
    max-width: 96px;
    flex-direction: column;

    input {
        border: none;
        padding: 1px 6px 2px 7px;
        ${styleIfProp("rightAlign", "text-align: right")}
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

export const _SearchInputWrapper = styled.div`
    position: relative;
    width: 229px;
    max-width: 229px;
    input {
        width: 100%;
        text-indent: 40px;
        height: 40px;
        border-radius: 20px;
        border: 1px solid #edeff2;
        background-color: #fff;
        &:active {
            border: solid 1px #617084;
        }
        outline: none;
    }
    #search {
        position: absolute;
        top: 10px;
        left: 10px;
    }
    #clearInput {
        position: absolute;
        top: 14px;
        right: 14px;
    }
`

export const _TextAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${_ErrorLabel} {
        align-self: flex-end;
    }
`

export const _SwitchView = styled(_FlexColumn)`
    justify-content: center;
`

export const _SwitchLabelDescription = styled.div`
    color: rgba(44, 64, 90, 0.6);
    max-height: 15px;
`

export const _SwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 36px;
    color: ${({ theme }) => theme.colors.primary};
    & label {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
    }
`

export const _SelectContainer = styled.div``

export const _ListInputContainer = styled.div`
    ${LinkButton} {
        text-decoration: underline;
    }
`

export const _InputHintText = styled.div`
    /* Same color as SwitchLabelDescription. */
    color: rgba(44, 64, 90, 0.6);
    margin-top: 15px;
`
