import uniq from "lodash/fp/uniq"
import { values, keys } from "../../functions/src/utils/map"
import { Ok } from "../../functions/src/utils/validators"
import { Firestore } from "../../functions/src/services/firebase"

export const fetchExtHub = (firestore: Firestore, configs: SMap<LocationParams>) => async (hub: Hub) => {
    const radarIds = values(configs)
        .filter(a => a.hubId === hub.hubId)
        .map(a => a.radarId)

    const userIdsByRadars = await Promise.all(
        radarIds.map(async rId =>
            keys((await firestore.fetchCol(firestore.ref("radarsClaims", rId, "claims"), Ok)).valid)
        )
    )
    const extHub: ExtHub = { ...hub, userCount: uniq(userIdsByRadars.flat()).length, radarCount: radarIds.length }
    return extHub
}
