/* eslint-disable max-lines */
import * as React from "react"
import styled from "styled-components"
import {
    _DayItem,
    _DayWrapper,
    _DayHeader,
    _UnreadWrapper,
    _UnreadIndicator,
    _DayContent,
    _DayItemDetails,
    _NewsfeedPillWrapper
} from "./Newsfeed.styles"
import { _VerticalSpace, _HorizontalSpace, _BlurredSpan, Flex } from "../../styles/common"
import { getCopy } from "../../../functions/src/services/copy"
import { _CenterMiddle } from "../importing/SelectFiles.styles"
import { toDate, toRelativeTime } from "../../models/dateTime"
import { collections, getCollectionSchema, cnames, getCollectionFromId } from "../../../functions/src/models/schemas"
import { ThemeColors } from "../../styles/styled"
import { _BodyFont } from "../../styles/typography"
import { _OutlinedPill } from "../common"
import { NewsDTO, NewsFilterParams } from "../../../functions/src/models/ViewModels"
import { assertNever, capitalize } from "../../../functions/src/utils"
import { _CommentWrapper, _CommentAvatar, _CommentInnerWrapper } from "../comments/comment.styles"
import { CommentPreview, CommentMeta } from "../comments/CommentParts"
import { Join } from "../../utils/reactUtils"
import { AuthorizationType } from "../../models/auth"
import { CloudActions } from "../../../functions/src/actions/actionCreators"
import { actions as uiActions } from "../../store/ui/actions"
import { NavigationParams } from "../../utils/router.types"
import { Filters } from "../../../functions/src/models/filtering"
import { detailsPaths, getCollectionDetailsPathName } from "../../paths"
import { NO_ITEM, UNKNOWN } from "../../../functions/src/models/comments"
import { useDispatch } from "react-redux"
import { Action, Dispatch } from "redux"
import { LinkButton } from "../../styles/buttons"
import { IconSvg } from "../IconSvg"
import { theme } from "../../styles/themes"

const COLLAPSE_SEARCH_AREAS_AFTER = 9

export const getNewsLength = (radarId: string) => (car: CloudActionResult<CloudActions>): number => {
    switch (car.action.type) {
        case "@importChunk":
        case "@mutateRadarCollectionItem":
        case "@removeColItems":
        case "createComment":
        case "addRelation":
        case "removeRelation":
            return car.action.payload.radarId === radarId ? 1 : 0
        case "mutateDecorator":
            return car.action.payload.type === "priorityRank" && car.action.payload.radarId === radarId ? 1 : 0
        default:
            return 0
    }
}

export const mutationTypeActionName: Record<MutationType, string> = {
    update: "updated",
    create: "created",
    delete: "removed"
}

export type NewsfeedPillProps = { text: string; color: keyof ThemeColors }
export const NewsfeedPill: React.FC<NewsfeedPillProps> = p => <_OutlinedPill {...p}>{p.text}</_OutlinedPill>

const _ExpandPill = styled(_OutlinedPill)`
    cursor: pointer;
`

export type NewsItem = {
    renderContent: F0<React.ReactNode>
    renderDetails?: F0<React.ReactNode>
    timestamp: Timestamp
    params: NewsFilterParams
    isRead: boolean
}

type PillGroupSpec = [string[], keyof ThemeColors, F1<any, string>]

function buildPills(groups: PillGroupSpec[]) {
    const pills = []
    for (const group of groups) {
        const [labels, themeColor, lambda] = group
        for (const label of labels) pills.push(<NewsfeedPill key={label} color={themeColor} text={lambda(label)} />)
    }
    return pills
}

const _CollapseControl = styled.a`
    cursor: pointer;
    color: ${theme.colors.primary};
    text-align: center;
    text-decoration: underline;
`

// 78px is the height of three cell rows plus padding. It's also used in NewsfeedPillWrapper's collapsed state.
const _CollapseOverlay = styled.div`
    background-image: linear-gradient(to bottom, #ffffff00, #ffffffff);
    height: 78px;
    width: 792px;
    position: absolute;
    text-align: center;

    & a {
        margin-top: 54px;
    }
`

const NewsItem = ({ params, isRead, renderContent, renderDetails }: NewsItem) => {
    const searchAreas = Array.from(new Set(params.searchAreas || []))
    const objectCollections = Array.from(new Set(params.collections || []))
    const saText = getCopy("searchArea")
    const [showAllPills, revealSA] = React.useState(false)
    const limit = COLLAPSE_SEARCH_AREAS_AFTER
    const pills = buildPills([
        [searchAreas, "theme8", value => `${saText}: ${value}`],
        [objectCollections, "theme7", (value: CName) => `Object type: ${collections[value].displayName}`]
    ])

    let pillsRendered

    if (pills.length > limit) {
        pillsRendered = (
            <>
                <_NewsfeedPillWrapper className={showAllPills ? "full" : "collapsed"}>
                    {!showAllPills && <_CollapseOverlay />}
                    {pills}
                </_NewsfeedPillWrapper>
                {showAllPills ? (
                    <_CollapseControl onClick={_ => revealSA(false)}>See less</_CollapseControl>
                ) : (
                    <_CollapseControl onClick={_ => revealSA(true)}>See more</_CollapseControl>
                )}
            </>
        )
    } else pillsRendered = <_NewsfeedPillWrapper>{pills}</_NewsfeedPillWrapper>
    return (
        <_DayItem data-cy="dayItem">
            <_UnreadWrapper>{!isRead ? <_UnreadIndicator /> : null}</_UnreadWrapper>
            <_DayItemDetails>
                <_DayContent>{renderContent()}</_DayContent>
                {renderDetails ? renderDetails() : <_VerticalSpace base="4px" />}
                {pillsRendered}
            </_DayItemDetails>
        </_DayItem>
    )
}

const renderNews = ({
    item,
    renderContent,
    details
}: {
    item: NewsDTO
    renderContent: F0<React.ReactNode>
    details?: F0<React.ReactNode>
}) => (
    <NewsItem key={`${item.type}-${item.timestamp}`} renderContent={renderContent} renderDetails={details} {...item} />
)

const renderDetails = (p: Pick<NewsDTO, "timestamp" | "authorEmail" | "authorName" | "isRead">) => (
    <CommentMeta time={toRelativeTime(p.timestamp) || ""} {...p} />
)

const getNavigationParamsFromObjectId = (objectId: string): NavigationParams | null => {
    const cname = getCollectionFromId(objectId)
    if (!cname) return null
    const path = detailsPaths[getCollectionDetailsPathName(cname)].path
    const slugKey = `:${getCollectionSchema(cname).idField}`
    return { path, slugs: { [slugKey]: objectId } }
}

const getNewsComponent = (
    item: NewsDTO,
    root: boolean,
    navigate: F2<Partial<Filters>, NavigationParams>,
    dispatch: Dispatch<Action>
) => {
    const itemCName = item.params.collections?.[0] as CName | undefined
    switch (item.type) {
        case "comment": {
            const params = getNavigationParamsFromObjectId(item.meta.comment.objectId)
            return renderNews({
                item,
                renderContent: () => (
                    <LinkButton
                        boldOnHover
                        onClick={
                            item.meta.objectName !== NO_ITEM && params ? () => navigate({}, params) : undefined
                        }>{`Comment was added to ${itemCName ? getCollectionSchema(itemCName).singleName : UNKNOWN} ${
                        item.meta.objectName
                    }`}</LinkButton>
                ),
                details: () => (
                    <_CommentWrapper blurred={root}>
                        <_CommentAvatar>{item.meta.comment.userName[0]}</_CommentAvatar>
                        <_HorizontalSpace base="28px" />
                        <_CommentInnerWrapper>
                            <CommentPreview comment={item.meta.comment} isSubmitting={false} />
                        </_CommentInnerWrapper>
                    </_CommentWrapper>
                )
            })
        }
        case "assignment": {
            const params = getNavigationParamsFromObjectId(item.meta.objectId)
            return renderNews({
                item,
                renderContent: () => {
                    return (
                        <LinkButton
                            boldOnHover
                            onClick={
                                item.meta.objectName !== NO_ITEM && params ? () => navigate({}, params) : undefined
                            }>{`Primary relationship manager was added to ${
                            itemCName ? getCollectionSchema(itemCName).singleName : UNKNOWN
                        } ${item.meta.objectName}`}</LinkButton>
                    )
                },
                details: () => renderDetails(item)
            })
        }
        case "pipeline": {
            const params = getNavigationParamsFromObjectId(item.meta.objectId)
            return renderNews({
                item,
                renderContent: () => (
                    <LinkButton
                        boldOnHover
                        onClick={item.meta.objectName !== NO_ITEM && params ? () => navigate({}, params) : undefined}>
                        {`Pipeline stage of ${item.meta.objectName} was changed`}
                        {root ? (
                            <_BlurredSpan>
                                {` from ${item.meta.oldStageName} to ${item.meta.newStageName}`}
                            </_BlurredSpan>
                        ) : (
                            ` from ${item.meta.oldStageName} to ${item.meta.newStageName}`
                        )}
                    </LinkButton>
                ),
                details: () => (
                    <_CommentWrapper blurred={root}>
                        <IconSvg name="comment-stage" width={36} height={36} />
                        <_HorizontalSpace base="28px" />
                        <_CommentInnerWrapper>
                            <CommentPreview comment={item.meta.comment} isSubmitting={false} />
                        </_CommentInnerWrapper>
                    </_CommentWrapper>
                )
            })
        }
        case "contacted-lead": {
            const params = getNavigationParamsFromObjectId(item.meta.objectId)
            return renderNews({
                item,
                renderContent: () => (
                    <LinkButton
                        boldOnHover
                        onClick={item.meta.objectName !== NO_ITEM && params ? () => navigate({}, params) : undefined}>
                        <span>{`${itemCName ? getCollectionSchema(itemCName).singleName : UNKNOWN} ${
                            item.meta.objectName
                        } was marked as`}</span>
                        <b>contacted lead</b>
                        {root ? "" : <span>by {item.authorName}</span>}.
                    </LinkButton>
                ),
                details: () => renderDetails(item)
            })
        }
        case "priority": {
            const params = getNavigationParamsFromObjectId(item.meta.objectId)
            return renderNews({
                item,
                renderContent: () => (
                    <LinkButton
                        onClick={item.meta.objectName !== NO_ITEM && params ? () => navigate({}, params) : undefined}
                        boldOnHover>{`Priority ranking was changed to ${item.meta.rankName} for entry ${item.meta.objectName}`}</LinkButton>
                ),
                details: () => renderDetails(item)
            })
        }
        case "import": {
            return renderNews({
                item,
                renderContent: () => (
                    <Join
                        items={cnames.filter(cname => item.meta[cname]?.length)}
                        renderJoining={() => <_VerticalSpace base="5px" />}>
                        {cname => {
                            const ids = item.meta[cname]!
                            const isOldImport = ids[0] === "NonExistingId"
                            return (
                                <LinkButton
                                    boldOnHover={!isOldImport}
                                    onClick={
                                        !isOldImport
                                            ? () => dispatch(uiActions.openPopup("byIdsList", { cname, ids }))
                                            : undefined
                                    }>
                                    {`${ids.length} ${ids.length === 1 ? "entry was" : "entries were"} imported in ${
                                        getCollectionSchema(cname).displayName
                                    }`}
                                </LinkButton>
                            )
                        }}
                    </Join>
                ),
                details: () => renderDetails(item)
            })
        }
        case "object-mutated": {
            const params = getNavigationParamsFromObjectId(item.meta.objectId)
            return renderNews({
                item,
                renderContent: () => {
                    const name = item.meta.objectName
                    if (!itemCName) return ""
                    return (
                        <LinkButton
                            onClick={
                                item.meta.objectName !== NO_ITEM && params ? () => navigate({}, params) : undefined
                            }
                            boldOnHover>{`${capitalize(itemCName)} ${name} was ${
                            mutationTypeActionName[item.params.mutationType![0] as MutationType]
                        }`}</LinkButton>
                    )
                },
                details: () => renderDetails(item)
            })
        }
        case "objects-removed":
            return renderNews({
                item,
                renderContent: () => {
                    const names = item.meta.objectNames
                    const amount = names.length
                    if (!amount) return ""
                    if (!itemCName) return ""
                    const cSchema = getCollectionSchema(itemCName)
                    return `${amount} ${
                        amount === 1 ? cSchema.singleName : cSchema.displayName
                    } was deleted: ${names.join(", ")}`
                },
                details: () => renderDetails(item)
            })
        case "relation": {
            const params1 = getNavigationParamsFromObjectId(item.meta.o1Id)
            const params2 = getNavigationParamsFromObjectId(item.meta.o2Id)
            return renderNews({
                item,
                renderContent: () => (
                    <Flex>
                        <_BodyFont>Relation between&nbsp;</_BodyFont>
                        <_BodyFont bold onClick={params1 ? () => navigate({}, params1) : undefined}>
                            {item.meta.o1Name}
                        </_BodyFont>
                        <_BodyFont>&nbsp;and&nbsp;</_BodyFont>
                        <_BodyFont bold onClick={params2 ? () => navigate({}, params2) : undefined}>
                            {item.meta.o2Name}
                        </_BodyFont>
                        <_BodyFont>&nbsp;was added</_BodyFont>
                    </Flex>
                ),
                details: () => renderDetails(item)
            })
        }
    }
    assertNever(item)
}

type NewsDaysProps = {
    news: NewsDTO[]
    auth: AuthorizationType
    timestamp: number
    navigateOnClick: F2<Partial<Filters>, NavigationParams>
}
export const NewsDay: React.FC<NewsDaysProps> = p => {
    const d = useDispatch()
    return (
        <_DayWrapper>
            <_DayHeader>{toDate(p.timestamp)}</_DayHeader>
            {p.news.map(n => getNewsComponent(n, p.auth === "RootAccess", p.navigateOnClick, d))}
        </_DayWrapper>
    )
}

export const EmptyNewsfeedView = () => (
    <_CenterMiddle direction="column">
        <IconSvg name="newsfeed-big" width={80} height={67} />
        <p>No news to display</p>
    </_CenterMiddle>
)
