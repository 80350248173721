// TODO fix this
/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react"
import { connect, ConnectedComponent } from "react-redux"
import styled from "styled-components"
import uniq from "lodash/fp/uniq"

import CreatableSelect from "react-select/creatable"
import { SubpageLayout } from "../../components/Page"
import { Button } from "../../styles/buttons"
import { getCollectionSchema } from "../../../functions/src/models/schemas"
import { keys, values, sortByOrder } from "../../../functions/src/utils/map"
import { Loaded, toOption, toStringOption } from "../../../functions/src/utils/types"
import { _FlexRow, _VerticalSpace, _FlexColumn } from "../../styles/common"
import { getMapDispatch2 } from "../../utils/redux"
import { actions } from "../../store/cloud/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { Modal } from "../../components/Modal"
import { FormView } from "../../components/form/FormView"
import { useFormHook } from "../../utils/hooks/useFormHook"
import { _FormViewWrapper } from "../../styles/forms"
import { validString, Err } from "../../../functions/src/utils/validators"
import { _BodyFont, _h1 } from "../../styles/typography"
import { _InputWrapper } from "../../components/form/Input.styles"
import { Chips } from "../../components/common/Chips"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { _InfoBlock, ImageWithFallback } from "../../components/common"
import { LoadableView } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import { getDataByDeps } from "../../store/data/dataSelectors"
import { PipelineStageValueVM } from "../../../functions/src/models/ViewModels"
import { AdminLocationState } from "../../models/auth"
import { RadarViewFetchMap } from "../../dependencies"
import { PIPELINE_STAGE_ARCHIVED, PIPELINE_STAGE_NA } from "../../../functions/src/models/decoratorValues"
import { Msg } from "../../models/notifications"
import { getAvailableCollections } from "../../../functions/src/models/collections"

type StateProps = {
    radar: RadarDetails
    pipelineStagesByCollection: TMap<CName, PipelineStageValueVM[]>
    pipelines: SMap<PipelineStageValueVM>
    decoratorsAmountByPipelineId: SMap<number>
    results: SMap<CloudActionResult>
}

const valueSchema: FormSchema<Pick<PipelineStageValue, "name">> = {
    name: { type: "text", name: "Stage name", validators: validString }
}

type DecoratorValueFormProps = { psv?: PipelineStageValueVM; onSubmit: F1<PipelineStageValueVM> }
export const DecoratorValueForm: React.FC<DecoratorValueFormProps> = p => {
    if (!p.psv) return null
    const { formViewProps, onSubmitClick } = useFormHook({
        schema: valueSchema,
        initialValue: { name: p.psv.name },
        onSubmit: ({ name }) => p.onSubmit({ ...p.psv!, name })
    })
    return (
        <_FormViewWrapper>
            <FormView {...formViewProps} />
            <_VerticalSpace base="32px" />
            <Button onClick={onSubmitClick}>Change value</Button>
        </_FormViewWrapper>
    )
}

const validatePipelineStageName = (n: string) => n !== PIPELINE_STAGE_ARCHIVED && n !== PIPELINE_STAGE_NA
const wrongPipelineMessage = Msg(
    "custom",
    { success: "", error: "Pipeline name cannot be 'Archived' nor 'No Stage'" },
    Err("")
)

export const RadarPipelines: React.FC<StateProps & ActionProps> = p => {
    const [editedValue, setEditedValue] = React.useState<string | null>(null)
    const [syncingValue, setSyncingValue] = React.useState<CName | null>(null)
    const [, onMutatePipeline] = useCloudAction(p.mutatePipelineStage, p.results, () => setSyncingValue(null))
    const onCreate = (value: Pick<PipelineStageValue, "collection" | "name" | "order">) =>
        onMutatePipeline({ type: "create", radarId: p.radar.radarId, value })
    const onUpdate = (value: PipelineStageValue[]) =>
        onMutatePipeline({ type: "update", radarId: p.radar.radarId, value })
    const onDelete = (value: string) => onMutatePipeline({ type: "delete", radarId: p.radar.radarId, value })

    const addPSV = (cname: CName) => (name: string) => {
        if (validatePipelineStageName(name)) {
            setSyncingValue(cname)
            const order = p.pipelineStagesByCollection[cname].length
            onCreate({ collection: cname, name, order })
        } else {
            p.queueNotification(wrongPipelineMessage)
        }
    }

    return (
        <SubpageLayout>
            <Modal
                isOpen={!!editedValue}
                onClose={() => {
                    setEditedValue(null)
                    setSyncingValue(null)
                }}
                title="Edit pipeline name">
                <DecoratorValueForm
                    psv={p.pipelines[editedValue!]}
                    onSubmit={psv => {
                        if (validatePipelineStageName(psv.name)) {
                            onUpdate([psv])
                        } else {
                            p.queueNotification(wrongPipelineMessage)
                            setSyncingValue(null)
                        }
                        setEditedValue(null)
                    }}
                />
            </Modal>
            <_InfoBlock>
                <_h1>How to reorder stages?</_h1>
                <_BodyFont s14>You can drag the stage to change its position on the dropdown.</_BodyFont>
                <_BodyFont s14>To edit stage name, click on it once.</_BodyFont>
                <ImageWithFallback src="/public/drag-example.png" />
            </_InfoBlock>
            <_FlexColumn>
                {keys(p.pipelineStagesByCollection).map(col => (
                    <PipelineRow key={col}>
                        <_FlexRow spaceBetween>
                            <_BodyFont s17 bold>
                                {getCollectionSchema(col).displayName}
                            </_BodyFont>
                        </_FlexRow>
                        <_VerticalSpace base="24px" />
                        <_InputWrapper withMaxWidth>
                            <CreatableSelect
                                isDisabled={col === syncingValue}
                                isLoading={col === syncingValue}
                                onChange={o => addPSV(col)((o as any).label)}
                                onCreateOption={addPSV(col)}
                                createOptionPosition="first"
                                formatCreateLabel={v => `Create new stage "${v}"...`}
                                placeholder="Add new..."
                                isClearable={false}
                                closeMenuOnSelect={true}
                                value={[]}
                                options={uniq(
                                    values(p.pipelineStagesByCollection)
                                        .flat()
                                        .map(v => v.name)
                                )
                                    .filter(v => !p.pipelineStagesByCollection[col].find(psv => psv.name === v))
                                    .map(v => toStringOption(v))}
                            />
                        </_InputWrapper>
                        <_VerticalSpace base="16px" />
                        <_FlexRow>
                            <Chips
                                draggable
                                numbered
                                values={p.pipelineStagesByCollection[col].map(psv =>
                                    toOption(psv.name, psv.pipelineId)
                                )}
                                onChipClick={o => {
                                    setEditedValue(o.value)
                                    setSyncingValue(col)
                                }}
                                onChipDrag={options => {
                                    const ps = options.map(({ value }, order) => ({ ...p.pipelines[value], order }))
                                    onUpdate(ps)
                                    setSyncingValue(col)
                                }}
                                onChipClose={o => {
                                    const id = o.value
                                    if (!p.pipelines[id]) return
                                    const count = p.decoratorsAmountByPipelineId[id] || 0
                                    if (count) return p.openPopup("pipelineRemoval", { id, count })
                                    setSyncingValue(col)
                                    onDelete(id)
                                }}
                            />
                        </_FlexRow>
                    </PipelineRow>
                ))}
            </_FlexColumn>
        </SubpageLayout>
    )
}

const PipelineRow = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 173px;
    padding: 22px;
    border-bottom: solid 1px ${p => p.theme.colors.theme2};
    border-left: solid 1px ${p => p.theme.colors.theme1};
    border-right: solid 1px ${p => p.theme.colors.theme1};
    &:first-child {
        border-top: solid 1px ${p => p.theme.colors.theme2};
    }
`

const mapState = getDataByDeps<StateProps>()(
    RadarViewFetchMap({}, { decorators: "all" }),
    ({ radar, decorators, pipelines }, state) => {
        const availableCollections = getAvailableCollections((state.auth.params as AdminLocationState).locationParams)

        const decoratorsAmountByPipelineId = values(decorators).reduce<SMap<number>>((acc, decs) => {
            values(decs.pipelineStage || {}).forEach(dec => {
                if (!acc[dec.valueRef]) acc[dec.valueRef] = 0
                acc[dec.valueRef]++
            })
            return acc
        }, {})

        const pipelineStagesByCollection = availableCollections.reduce((acc, v) => {
            acc[v] = sortByOrder(
                values(pipelines).filter(psv => psv.collection === v),
                psv => psv.order
            )
            return acc
        }, {} as TMap<CName, PipelineStageValueVM[]>)

        return Loaded({
            radar,
            pipelines,
            decoratorsAmountByPipelineId,
            results: state.cloud.actionsResults,
            pipelineStagesByCollection
        })
    }
)
const mapDispatch = getMapDispatch2(actions, ["mutatePipelineStage"], uiActions, ["openPopup", "queueNotification"])
type ActionProps = ReturnType<typeof mapDispatch>
const LoadableRadarPipelines = LoadableView(ViewLoader, RadarPipelines)

export const RadarPipelinesView: ConnectedComponent<typeof LoadableRadarPipelines, unknown> = connect(
    mapState,
    mapDispatch
)(LoadableRadarPipelines)
