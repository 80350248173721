import * as React from "react"
import { IconListItemProps, IconList } from "./IconList"
import { keys } from "../../../functions/src/utils/map"
import { SubpageLayout } from "../Page"
import { _ColumnWrapper, _Column } from "./SelectFiles.styles"
import { DNDButton } from "../common/Buttons"
import { isEmpty } from "../../../functions/src/utils/validators"
import { _FlexColumn } from "../../styles/common"
import { HowToModal, ReasonModal } from "./ErrorHintModals"
import { SelectFilesPayload, UnrecognizedFilePayload, SelectFilesState } from "../../store/store"

const mapToFileList = (p: UploadedFilesListsProps, type: keyof SelectFilesPayload): IconListItemProps[] => {
    switch (type) {
        case "recognized":
            return keys(p.uploads.recognized!)
                .filter(Boolean)
                .map<IconListItemProps>(k => {
                    const f = p.uploads.recognized![k]!
                    return {
                        header: k,
                        subHeader: f.name,
                        icon: {
                            name: "tick",
                            width: 30,
                            height: 30
                        },
                        onRemove: () => p.onRemove(k, f.name)
                    }
                })
        case "unrecognized":
            return keys(p.uploads.unrecognized!)
                .filter(Boolean)
                .map<IconListItemProps>(k => {
                    const reason = p.uploads.unrecognized![k]!
                    // TODO: Check if can be undefined
                    return {
                        header: k,
                        subHeader: "",
                        icon: {
                            name: "cross",
                            width: 30,
                            height: 30
                        },
                        onRemove: () => p.onRemove(null as any, k),
                        onShowReason: () => p.onShowReason(reason),
                        description: "What's wrong with the file?"
                    }
                })
    }
}

type UploadedFilesListsProps = {
    uploads: SelectFilesPayload
    onRemove: F2<CName, string>
    onShowReason: F1<UnrecognizedFilePayload>
}
export const UploadedFilesLists: React.FC<UploadedFilesListsProps> = p => (
    <_FlexColumn>
        {p.uploads.recognized != null ? (
            <IconList items={mapToFileList(p, "recognized")} title="Recognized object types" />
        ) : null}
        {p.uploads.unrecognized ? (
            <IconList items={mapToFileList(p, "unrecognized")} title="Unrecognized files" />
        ) : null}
    </_FlexColumn>
)

type ReasonModalState = { isOpen: false } | { isOpen: true; reason: UnrecognizedFilePayload }
export const SelectFiles: React.FC<{
    selectFiles: F1<File[]>
    removeSelectedFile: F2<CName, string>
    importing: SelectFilesState
    config: LocationParams
    isRoot: boolean
}> = p => {
    const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false)
    const [reasonModal, setReasonModal] = React.useState<ReasonModalState>({
        isOpen: false
    })

    return (
        <SubpageLayout>
            <_ColumnWrapper>
                {!isEmpty(p.importing.value.recognized) || !isEmpty(p.importing.value.unrecognized) ? (
                    <_Column width={33}>
                        <UploadedFilesLists
                            uploads={p.importing.value}
                            onRemove={p.removeSelectedFile}
                            onShowReason={reason => setReasonModal({ isOpen: true, reason })}
                        />
                    </_Column>
                ) : null}
                <_Column>
                    <DNDButton onDrop={p.selectFiles} onInfoClick={() => setIsInfoModalOpen(true)} />
                </_Column>
            </_ColumnWrapper>
            <HowToModal
                isOpen={isInfoModalOpen}
                onClose={() => setIsInfoModalOpen(false)}
                config={p.config}
                isRoot={p.isRoot}
            />
            <ReasonModal
                isOpen={reasonModal.isOpen}
                onClose={() => setReasonModal({ isOpen: false })}
                reason={reasonModal.isOpen ? reasonModal.reason : ({} as any)}
            />
        </SubpageLayout>
    )
}
