import * as React from "react"
import { v4 } from "uuid"
import { call } from "../../../functions/src/utils"
import { Done, NotStarted, Processing } from "../../../functions/src/utils/types"

export function useCloudAction(
    onSend: F1<string>,
    results: SMap<CloudActionResult>,
    onDone?: F1<CloudActionResult>
): [AsyncAction<CloudActionResult>, F0, F0]
export function useCloudAction<T1>(
    onSend: F2<string, T1>,
    results: SMap<CloudActionResult>,
    onDone?: F1<CloudActionResult>
): [AsyncAction<CloudActionResult>, F1<T1>, F0]
export function useCloudAction<T1, T2>(
    onSend: F3<string, T1, T2>,
    results: SMap<CloudActionResult>,
    onDone?: F1<CloudActionResult>
): [AsyncAction<CloudActionResult>, F2<T1, T2>, F0]
// eslint-disable-next-line @typescript-eslint/ban-types
export function useCloudAction(onSend: Function, results: SMap<CloudActionResult>, onDone?: F1<CloudActionResult>) {
    const [processingActionId, setProcessingActionId] = React.useState<string | null>(null)

    const sendAction = (v?: any, v2?: any) => {
        const actionId = v4()
        setProcessingActionId(actionId)
        onSend(actionId, v, v2)
    }

    React.useEffect(() => {
        if (processingActionId && results[processingActionId]) call(onDone, results[processingActionId])
    }, [results[processingActionId as keyof typeof results]]) // eslint-disable-line react-hooks/exhaustive-deps

    const actionState: AsyncAction<CloudActionResult> = !processingActionId
        ? NotStarted()
        : !results[processingActionId]
        ? Processing()
        : Done(results[processingActionId])

    return [actionState, sendAction, () => setProcessingActionId(null)]
}
