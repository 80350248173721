import { connect, ConnectedComponent } from "react-redux"
import { StateProps, ActionProps, EditRadar } from "./RadarEdit"
import { actions as cloudActions } from "../../store/cloud/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { actions as authActions } from "../../store/auth/actions"
import { adminPaths } from "../../paths"
import { values } from "../../../functions/src/utils/map"
import { Loaded, isFetched, LoadingWithPayload, Loading } from "../../../functions/src/utils/types"
import { LoadableView } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import { LoaderPayload } from "../../store/data/dataSelectors"
import { MapState, MapDispatch } from "../../utils/redux.types"
import { isAdminLocation } from "../../models/LocationType"
import { getHubFromSlug } from "./utils"

export const radar: RadarDetailsPayload = {
    isDisabled: false,
    restriction: "client",
    name: "",
    radarSlug: "",
    portalOrganizationId: "",
    relationshipsEnabled: false,

    xAxisLabels: [{ name: "" }],
    yAxisLabels: [{ name: "" }],
    withDashboard: false,
    withJobClusters: false,
    withPriorityRanks: false,
    withAssignments: false,
    withStars: false,
    withPipelinesSummary: false,
    withContactedLead: false,
    collections: []
}

const mapState: MapState<Loadable<StateProps, LoaderPayload>> = ({ auth, cloud }) => {
    if (!isAdminLocation(auth.params)) return LoadingWithPayload({ state: "Wrong location" })
    if (!isFetched(auth.configs)) return Loading()
    const hubSlug = new URLSearchParams(auth.params.search).get("hub") || ""
    const hubId = getHubFromSlug(hubSlug, (auth.hubs as AsyncFetched<SMap<ExtHub>>).value)?.hubId || ""
    return Loaded({
        hubId,
        hubSlug,
        radar: { ...radar },
        results: cloud.actionsResults,
        actionName: "Create",
        title: "Add Radar",
        takenSlugs: values(auth.configs.value).map(p => p.radarSlug)
    })
}
const mapDispatch: MapDispatch<ActionProps> = d => ({
    onUpdate: (actionId, _, data) => d(cloudActions.createRadar(actionId, data)),
    queueNotification: msg => d(uiActions.queueNotification(msg)),
    onFinished: radarSlug => {
        d(authActions.navigate({ path: adminPaths["admin/radarDashboard"].path, slugs: { radarSlug } }))
        d(authActions.fetchConfigs())
    }
})
const LoadableAddRadar = LoadableView(ViewLoader, EditRadar)

export const AddRadarView: ConnectedComponent<typeof LoadableAddRadar, unknown> = connect(
    mapState,
    mapDispatch
)(LoadableAddRadar)
