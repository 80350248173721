import styled from "styled-components"
import { _FlexColumn } from "../../styles/common"

export const _FieldsTable = styled.table`
    td:last-child,
    th:last-child {
        text-align: center;
    }
`

export const _HowToImportDataContainer = styled.div`
    .accordion__panel {
        padding: 16px;

        & > * + * {
            margin-top: 16px;
        }
    }
`

export const _HowToImportDataPageContainer = styled(_FlexColumn)`
    padding: 15px;
`

export const _Separator = styled.div`
    width: 100%;
    height: 1px;
    margin: 16px 0;
    background-color: black;
    opacity: 0.1;
`
