import React, { useEffect, useRef } from "react"
import { DraggableBounds } from "react-draggable"

type Props = {
    onPositionChange: (position: Point) => void
    draggableBounds: DraggableBounds
}

export const MouseWheelScroll: React.FC<Props> = ({ onPositionChange, children, draggableBounds }) => {
    const ref = useRef<HTMLDivElement>(null)

    const mousewheelHandler = React.useCallback(
        (e: WheelEvent) => {
            let dx = e.deltaX
            let dy = e.deltaY
            if (e.shiftKey && dx === 0 && dy !== 0) {
                dx = dy
                dy = 0
            }
            onPositionChange({ x: dx, y: dy })
        },
        [onPositionChange]
    )

    useEffect(() => {
        const r = ref.current
        if (r) r.addEventListener("wheel", mousewheelHandler, { passive: false })
        return () => {
            if (r) r.removeEventListener("wheel", mousewheelHandler)
        }
    }, [draggableBounds, mousewheelHandler])

    return <div ref={ref}>{children}</div>
}
