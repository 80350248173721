import { Cmd } from "redux-loop"
import { getReducer, cmdList } from "../../utils/redux"
import { keys, values, filterObject, extend, extMap } from "../../../functions/src/utils/map"
import * as cmds from "./importingCmds"
import { isEmpty, isArray, isOk } from "../../../functions/src/utils/validators"
import { LOCATION_CHANGE } from "connected-react-router"
import { matchesPath } from "../../utils/router"
import { actions as authActions } from "../auth/actions"
import { adminPaths } from "../../paths"
import { toImportableCollections, attachIdToPreloadedItem } from "./importingUtils"
import { ImportingState, UploadingChunksMeta, UploadingChunksState, PreloadFilesState, RootContext } from "../store"
import { ImportData, ImportableCollections } from "../../../functions/src/models/importing.types"
import { isAdminLocation } from "../../models/LocationType"
import { actions, Actions } from "./actions"

export const initialState: ImportingState = { type: "SelectFiles", value: {} }

export const countData = (payload: OMap<string, any[]>) =>
    values(payload).reduce((acc, data) => acc + (isArray(data) ? data : []).length, 0)

export const countImportData = (v: ImportData) => countData(v.create) + countData(v.update)

const Processing = (cs: ImportData, pos?: UploadingChunksMeta): UploadingChunksState => {
    const nextPos = pos
        ? { chunkIndex: pos.chunkIndex + 1, totalSize: pos.totalSize }
        : { chunkIndex: 0, totalSize: countImportData(cs) }
    const collections = pos ? pos.collections : Array.from(new Set([...keys(cs.create), ...keys(cs.update)]))
    return { type: "UploadingChunks", value: { ...cs, ...nextPos, collections } }
}
const isImportingPayloadEmpty = (p: ImportableCollections) => keys(p).every(k => isEmpty(p[k]))

const extendPreloadFilesState = <C extends CName>(state: PreloadFilesState, cname: C, newValue: CollectionItem<C>[]) =>
    extMap(
        state.value,
        cname,
        extend(state.value[cname]!)({
            value: newValue as any
        })
    )

export const reducer = getReducer<ImportingState, Actions, RootContext>((state, action, context) => {
    switch (action.type) {
        case LOCATION_CHANGE:
            return matchesPath(adminPaths["admin/radarDashboard"].path, action.payload.location.pathname) &&
                new URLSearchParams(action.payload.location.search).get("tab-admin") === "collectionImport"
                ? {}
                : { type: "SelectFiles" }

        case "startSelectFiles":
            return { type: "SelectFiles" }

        case "selectFiles":
            return Cmd.list(action.payload.files.map(f => cmds.recognizeFile(f, action.payload.availableCollections)))

        case "_attachFile": {
            if (state.type !== "SelectFiles") return {}
            const { file, result } = action.payload
            return isOk(result)
                ? { value: { ...state.value, recognized: { ...state.value.recognized, [result.value]: file } } }
                : {
                      value: {
                          ...state.value,
                          unrecognized: {
                              ...state.value.unrecognized,
                              [file.name]: result.value
                          }
                      }
                  }
        }

        case "removeSelectedFile": {
            if (state.type !== "SelectFiles") return {}
            const { cname, filename } = action.payload
            return cname
                ? {
                      value: {
                          ...state.value,
                          recognized: state.value.recognized && {
                              ...filterObject(state.value.recognized as TMap<CName, File>, k => k !== cname)
                          }
                      }
                  }
                : {
                      value: {
                          ...state.value,
                          unrecognized: { ...filterObject(state.value.unrecognized!, k => k !== filename) }
                      }
                  }
        }

        case "startPreload": {
            if (state.type !== "SelectFiles") return {}
            if (!isAdminLocation(context)) return {}
            return [
                { type: "PreloadFiles", value: {} },
                cmds.startPreload(context.locationParams.radarId!, state.value)
            ]
        }

        case "_updatePreloadedCollections":
            return { type: "PreloadFiles", value: action.payload }

        case "removeTagFromItems": {
            if (state.type !== "PreloadFiles") return {}
            return cmdList(
                [
                    cmds.removeTagFromCollections(action.payload, state.value),
                    Cmd.action(actions.revalidateCollections())
                ],
                true
            )
        }

        case "removeSegmentTagFromItems": {
            if (state.type !== "PreloadFiles") return {}
            return cmdList(
                [
                    cmds.removeSegmentTagFromCollections(action.payload, state.value),
                    Cmd.action(actions.revalidateCollections())
                ],
                true
            )
        }

        case "updateItem": {
            if (state.type !== "PreloadFiles") return {}
            const { collection, index, value } = action.payload

            const vs = state.value[collection]!.value as CollectionItem<CName>[]
            vs[index] = { ...value, edited: true }
            return [
                { value: extendPreloadFilesState(state, collection, vs) },
                Cmd.action(actions.revalidateCollections())
            ]
        }

        case "removeItems": {
            if (state.type !== "PreloadFiles") return {}
            const { collection, indices } = action.payload
            return [
                {
                    value: extendPreloadFilesState(
                        state,
                        collection,
                        (state.value[collection]!.value as any[]).filter((_, i) => !indices.includes(i))
                    )
                },
                Cmd.action(actions.revalidateCollections())
            ]
        }

        case "addItem": {
            if (state.type !== "PreloadFiles") return {}
            const { collection, value } = action.payload
            value.fields = attachIdToPreloadedItem(collection, value.fields)
            return [
                {
                    value: extendPreloadFilesState(state, collection, [value, ...state.value[collection]!.value])
                },
                Cmd.action(actions.revalidateCollections())
            ]
        }

        case "revalidateCollections": {
            if (state.type !== "PreloadFiles") return {}
            if (!isAdminLocation(context)) return {}
            return cmds.revalidateCollections(context.locationParams.radarId!, state.value)
        }

        case "startImport": {
            if (state.type !== "PreloadFiles") return {}
            if (!isAdminLocation(context)) return {}
            const cs = toImportableCollections(state.value)
            return [Processing(cs), cmds.importNextChunk(context.userId!, context.locationParams.radarId!, cs, 0)]
        }

        case "_chunkImportFinished": {
            if (!isAdminLocation(context) || state.type !== "UploadingChunks") return {}
            if (isImportingPayloadEmpty(action.payload.create) && isImportingPayloadEmpty(action.payload.update))
                return [{ type: "Done" }, cmdList([authActions.fetchConfigs()])]
            const nextState = Processing(action.payload, state.value)
            return [
                nextState,
                cmds.importNextChunk(
                    context.userId!,
                    context.locationParams.radarId!,
                    action.payload,
                    nextState.value.chunkIndex
                )
            ]
        }

        case "_chunkImportFailed":
            return { type: "Error", msg: action.payload }
    }
})
