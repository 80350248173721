/* eslint-disable max-lines */
import * as React from "react"
import { Prompt } from "react-router"
import { connect, ConnectedComponent } from "react-redux"
import { SubpageLayout, _LayoutContainer } from "../../components/Page"
import { collections, getCollectionSchema } from "../../../functions/src/models/schemas"
import { keys, toArray, values } from "../../../functions/src/utils/map"
import { _ColumnWrapper, _Column, _Header, _CenterMiddle } from "../../components/importing/SelectFiles.styles"
import { _noop } from "../../../functions/src/utils"
import { Dropdown } from "../../components/Dropdown"
import { Loaded, Loading, toOption } from "../../../functions/src/utils/types"
import { _FlexBaseItem, _VerticalSpace, _HorizontalSpace, _Right, _Center } from "../../styles/common"
import { _BodyFont } from "../../styles/typography"
import { parsePrioritiesPayload } from "../../services/import"
import { isOk, isEmpty } from "../../../functions/src/utils/validators"
import { StepDescription, Stepper } from "../../components/common/Stepper"
import { Button } from "../../styles/buttons"
import { checkPriorityPayload, PriorityCheckResult } from "./PrioritiesImportController"
import { Progress } from "../../components/common/Progress"
import { actions } from "../../store/cloud/actions"
import { getCurrentRadarId } from "../../models/LocationType"
import { getMapDispatch } from "../../utils/redux"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { DNDButton } from "../../components/common/Buttons"
import { Modal } from "../../components/Modal"
import { _TableContainer } from "../../components/table/TableView.styles"
import { LoadableView } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import { getDataByDeps } from "../../store/data/dataSelectors"
import { ViewModelsBase } from "../../../functions/src/models/ViewModels"
import { PriorityPayload } from "../../../functions/src/models/importing.types"
import { Loader } from "../../components/common/Loader"
import { RadarViewFetchMap } from "../../dependencies"
import { IconSvg } from "../../components/IconSvg"
import { getAvailableCollections } from "../../../functions/src/models/collections"
import { RadarLocationState } from "../../models/auth"

type StateProps = {
    vms: Pick<ViewModelsBase, "decorators" | "radar" | "searchAreas">
    actionResults: SMap<CloudActionResult>
    radarId: string
    currentCollections: CName[]
}

const steps: StepDescription[] = [
    { title: "Select", stepNumber: 1 },
    { title: "Preload", stepNumber: 2 },
    { title: "Upload", stepNumber: 3 },
    { title: "Done", stepNumber: 4 }
]
type ParsedData = Result<PriorityPayload<CName>[]>
const getAvailableSteps = (parsedData: ParsedData | null): number[] => {
    if (parsedData && parsedData.type === "Ok") return [1, 2]
    return []
}

const PriorityImportWrapper: React.FC<StateProps & ActionProps> = p => {
    const [currentStep, setCurrentStep] = React.useState<number>(1)
    const [selectedCollection, setSelectedCollection] = React.useState<CName>(keys(collections)[0])
    const [parsedData, setParsedData] = React.useState<ParsedData | null>(null)
    const [readyData, setReadyData] = React.useState<SMap<PriorityPayload<CName>[]>>({})

    const available = getAvailableSteps(parsedData)
    return (
        <>
            <_Header>
                <Stepper
                    steps={steps}
                    currentStepNumber={currentStep}
                    availableSteps={available}
                    onStepClicked={_noop}
                />
                <_Right>
                    <_HorizontalSpace base="8px" />
                    <Button
                        onClick={() => setCurrentStep(currentStep + 1)}
                        disabled={!available.includes(currentStep + 1)}>
                        Next
                    </Button>
                </_Right>
            </_Header>
            <_LayoutContainer>
                <Prompt
                    when={currentStep !== 1 && currentStep !== 4}
                    message={() =>
                        "Going back will cause losing all your import progress. Press cancel to abort going back"
                    }
                />
                {currentStep === 1 && (
                    <PrioritiesFileImport
                        cname={selectedCollection}
                        setCName={setSelectedCollection}
                        data={parsedData}
                        setData={setParsedData}
                        onNext={() => setCurrentStep(2)}
                        currentCollections={p.currentCollections}
                    />
                )}
                {currentStep === 2 && isOk(parsedData) && (
                    <PrioritiesValidation
                        cname={selectedCollection}
                        radarId={p.radarId}
                        vms={p.vms}
                        data={parsedData.value}
                        onAccept={d => {
                            setReadyData(d)
                            setCurrentStep(3)
                        }}
                    />
                )}
                {currentStep === 3 && (
                    <PrioritiesUpload
                        onEnd={() => setCurrentStep(4)}
                        data={readyData}
                        cname={selectedCollection}
                        {...p}
                    />
                )}
                {currentStep === 4 && (
                    <_Center>
                        Done!
                        <IconSvg name="selected" width={150} height={150} />
                    </_Center>
                )}
            </_LayoutContainer>
        </>
    )
}

const IMPORT_PAYLOAD_SIZE = 50

export const PrioritiesUpload: React.FC<
    {
        data: SMap<PriorityPayload<CName>[]>
        cname: CName
        onEnd: F0
    } & StateProps &
        ActionProps
> = p => {
    const [progress, setProgress] = React.useState(0)
    const datums: PriorityRankDecoratorMeta[] = toArray(p.data, (pds, id) => {
        return pds.map(pd => {
            // eslint-disable-next-line no-console
            if (!pd.searchAreaId) return console.error("Search area not found")
            const res: PriorityRankDecoratorMeta = {
                objectId: id,
                searchAreaId: pd.searchAreaId,
                newValue: pd.priority,
                type: "priorityRank"
            }
            return res
        })
    })
        .flat()
        .filter<PriorityRankDecoratorMeta>((v): v is PriorityRankDecoratorMeta => Boolean(v))
    const [, onNextPayload, reset] = useCloudAction(p.importPrioritiesChunk, p.actionResults, () => {
        const datumsPack = datums.slice(progress + IMPORT_PAYLOAD_SIZE, progress + IMPORT_PAYLOAD_SIZE * 2)
        if (isEmpty(datumsPack)) return p.onEnd()
        reset()
        onNextPayload({
            radarId: p.radarId,
            priorities: datumsPack
        })
        setProgress(Math.min(datums.length, progress + IMPORT_PAYLOAD_SIZE))
    })

    React.useEffect(() => {
        if (datums.length) onNextPayload({ radarId: p.radarId, priorities: datums.slice(0, IMPORT_PAYLOAD_SIZE) })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <_CenterMiddle>
            <Progress title="Uploading" total={datums.length} current={progress} />
        </_CenterMiddle>
    )
}

const matcherFields: { [P in CName]: (keyof RCollection<P>)[] } = {
    startups: ["startup_name"],
    influencers: ["influencer_name"],
    investors: ["investor_name"],
    patent_holders: ["patent_holder"],
    patents: ["publication_number"],
    research_hubs: ["research_hub_url"],
    research_papers: ["paper_title"],
    sectors: ["sector_name"],
    tech_experts: ["tech_expert_name"],
    tech_transfers: ["tech_of_interest"],
    grants: ["grant_title"],
    clinical_trials: ["trial_title"],
    companies: ["company_name"]
}

export const PrioritiesValidation: React.FC<{
    radarId: string
    cname: CName
    vms: Pick<ViewModelsBase, "radar" | "searchAreas">
    data: PriorityPayload<CName>[]
    onAccept: F1<SMap<PriorityPayload<CName>[]>>
}> = p => {
    const [results, setResults] = React.useState<Loadable<PriorityCheckResult<CName>>>(Loading())
    React.useEffect(() => {
        checkPriorityPayload(p.radarId, p.cname, p.vms, p.data).then(rs => setResults(Loaded(rs)))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    if (results.loading) return <Loader loadingText="Validating entries" />
    return (
        <_ColumnWrapper>
            <_Column>
                <_TableContainer>
                    {results.searchArea && (
                        <_BodyFont s14>Rows with incorrect search area: {results.searchArea}</_BodyFont>
                    )}
                    {results.priority && <_BodyFont s14>Rows with incorrect priority: {results.priority}</_BodyFont>}
                    {results.duplicates && (
                        <_BodyFont s14>Rows that are duplicated within the file: {results.duplicates}</_BodyFont>
                    )}
                    {results.collection && (
                        <_BodyFont s14>Rows where object has not been found in radar: {results.collection}</_BodyFont>
                    )}
                </_TableContainer>
                <_VerticalSpace base="12px" />
                <_FlexBaseItem yAlign="center" xAlign="flex-end">
                    <_BodyFont s17>
                        Correct rows {values(results.correct).flat().length}/{p.data.length}
                    </_BodyFont>
                    <_HorizontalSpace base="16px" />
                    <Button
                        disabled={values(results.correct).flat().length === 0}
                        onClick={() => p.onAccept(results.correct)}>
                        Proceed with correct values
                    </Button>
                </_FlexBaseItem>
                <_VerticalSpace base="54px" />
            </_Column>
        </_ColumnWrapper>
    )
}

type PrioritiesFileImportProps = {
    cname: CName
    setCName: F1<CName>
    data: ParsedData | null
    setData: F1<ParsedData | null>
    onNext: F0
    currentCollections: CName[]
}
export const PrioritiesFileImport: React.FC<PrioritiesFileImportProps> = p => {
    const [isInfoModalOpened, setIsInfoModalOpened] = React.useState(false)

    return (
        <SubpageLayout>
            <_VerticalSpace base="16px" />
            <_FlexBaseItem direction="row" yAlign="center" xAlign="center">
                <_BodyFont s14>Select collection for which you are going to import priorities</_BodyFont>
                <_HorizontalSpace base="16px" />
                <Dropdown
                    options={p.currentCollections.map(c => toOption(getCollectionSchema(c).displayName, c))}
                    selected={[toOption(getCollectionSchema(p.cname).displayName, p.cname)]}
                    onSelect={o => {
                        p.setCName(o.value as CName)
                        p.setData(null)
                    }}
                    full
                />
            </_FlexBaseItem>
            <_ColumnWrapper>
                <_Column>
                    <DNDButton
                        onDrop={fs => parsePrioritiesPayload(matcherFields[p.cname] as any[], fs[0]).then(p.setData)}
                        onInfoClick={() => setIsInfoModalOpened(true)}
                    />
                </_Column>
            </_ColumnWrapper>
            <Modal
                isOpen={isInfoModalOpened}
                onClose={() => setIsInfoModalOpened(false)}
                title="Creating priority import CSVs">
                <>
                    <_BodyFont>
                        For all object types, we require two fields:{" "}
                        <_BodyFont as="span" bold>
                            search_areas
                        </_BodyFont>{" "}
                        and{" "}
                        <_BodyFont as="span" bold>
                            priority_rank
                        </_BodyFont>
                        .
                    </_BodyFont>
                    <_BodyFont>
                        Then, to match the records to its radar counterparts, we need row identifiers:
                    </_BodyFont>
                    <_BodyFont></_BodyFont>
                    <_VerticalSpace base="8px" />
                    {keys(matcherFields).map(c => (
                        <_BodyFont key={c}>
                            {`${c}:`}
                            <_BodyFont as="span" bold>
                                {" "}
                                {matcherFields[c]}
                            </_BodyFont>
                        </_BodyFont>
                    ))}
                </>
            </Modal>
            {p.data && (
                <_ColumnWrapper>
                    <_Column>
                        <_Center>
                            <_BodyFont>{isOk(p.data) ? "Data structure is ok" : `Error: ${p.data.value}`}</_BodyFont>
                            <_VerticalSpace base="8px" />
                            <IconSvg name={isOk(p.data) ? "tick" : "cross"} width={30} height={30} />
                        </_Center>
                    </_Column>
                </_ColumnWrapper>
            )}
        </SubpageLayout>
    )
}

const mapState = getDataByDeps<StateProps>()(RadarViewFetchMap({}, { decorators: "all" }), (deps, { cloud, auth }) => {
    const radarId = getCurrentRadarId(auth)!
    const currentCollections = getAvailableCollections((auth.params as RadarLocationState).locationParams)
    return Loaded({
        vms: deps,
        radarId,
        actionResults: cloud.actionsResults,
        currentCollections
    })
})
const mapDispatch = getMapDispatch(actions, ["importPrioritiesChunk"])
type ActionProps = ReturnType<typeof mapDispatch>
const LoadablePrioritiesImportView = LoadableView(ViewLoader, PriorityImportWrapper)
export const PrioritiesImportView: ConnectedComponent<typeof LoadablePrioritiesImportView, unknown> = connect(
    mapState,
    mapDispatch
)(LoadablePrioritiesImportView)
