import { remap, identity, filterObject, values, keys } from "../utils/map"
import { NO_DECORATOR } from "./decoratorValues"

export const mapAreaIdsByName = (searchAreas: SMap<SearchArea>): SMap<AreaId> =>
    remap(
        searchAreas,
        (_, v) => v.name,
        (_, key) => key
    )

export const getSearchAreasFromAssignments = (
    saAssignments: Assignments<AreaId, SearchAreaAssignmentKind>,
    searchAreas: TMap<AreaId, SearchArea>
): { primarySearchAreas: SearchArea[]; secondarySearchAreas: SearchArea[] } => {
    const primarySearchAreas: SearchArea[] = []
    const secondarySearchAreas: SearchArea[] = []

    keys(saAssignments).forEach(aid => {
        if (!searchAreas[aid]) return
        switch (saAssignments[aid]) {
            case "primary":
                primarySearchAreas.push(searchAreas[aid])
                break
            case "secondary":
                secondarySearchAreas.push(searchAreas[aid])
                break
        }
    })

    return {
        primarySearchAreas,
        secondarySearchAreas
    }
}

export const mapAreasByName = (searchAreas: SMap<SearchArea>): SMap<SearchArea> =>
    remap(searchAreas, (_, v) => v.name, identity)

export const getSearchAreasForObject = (
    searchAreas: SMap<SearchArea>,
    assignments: Assignments<AreaId, SearchAreaAssignmentKind> = {}
): SMap<SearchArea> => filterObject(searchAreas, k => !!assignments[k])

export const areaTypes: TMap<SearchAreaKind, SearchAreaType> = {
    business: { id: "business", order: 1, name: "Business" },
    techPlatform: { id: "techPlatform", order: 2, name: "Technology Platform" },
    [NO_DECORATOR]: { id: NO_DECORATOR, order: 3, name: "N/A" }
}

export const getAreaValues = (sas: SMap<SearchArea>): Tuples<string, string> =>
    values(sas).map(sa => [sa.name, sa.areaId])
export const getAreaType = (sa: SearchArea): SearchAreaType => areaTypes[sa.typeId]
export const getAreaOrder = (sa: SearchArea): number => getAreaType(sa).order

export const byNameAsc = (s1: SearchArea, s2: SearchArea) =>
    s1.name.toLocaleLowerCase() > s2.name.toLocaleLowerCase() ? 1 : -1
export const byAreaOrder = (s1: SearchArea, s2: SearchArea) => getAreaOrder(s1) - getAreaOrder(s2)

export const getSearchAreaIdFromName = (searchAreas: SearchArea[]) => (saName?: string) =>
    searchAreas.find(sa => sa.name === saName)?.areaId

export const searchAreaColors = [
    "#1755E3",
    "#F86E5C",
    "#7D9DF8",
    "#68E2A7",
    "#9860FD",
    "#F28D45",
    "#4F5D75",
    "#5C276B",
    "#FF5600",
    "#12A4C1",
    "#D34E24",
    "#38726C",
    "#A4243B",
    "#BA7BA1",
    "#083D77",
    "#A4A4A4",
    "#C1666B",
    "#494949",
    "#EFB42A",
    "#6BC4FF",
    "#FF6BBE",
    "#0E16C0",
    "#A4A616",
    "#FF0065"
]
