import { Cmd } from "redux-loop"
import { PermissionsActions, PermissionsChanged } from "../middlewares/authMiddleware"
import { getReducer, cmdList } from "../../utils/redux"
import * as cmds from "./authCmds"
import { actions as uiActions } from "../ui/actions"
import {
    ProcessingLogin,
    isAccessNotRequested,
    LoggedIn,
    isNotLoggedIn,
    isRoot,
    isLoggedIn
} from "../../models/LoginStatus"
import {
    isNoLocation,
    None,
    mkWaiting,
    isDemoRadarLocation,
    isRadarDisabled,
    isValidLocation,
    isRadarLocation,
    isAdminLocation,
    isUserLocation,
    getCurrentRadar
} from "../../models/LocationType"
import { isEqual } from "../../utils"
import { setCopySource } from "../../../functions/src/services/copy"
import { NotFetched, FetchError, Fetching, isNotFetched, isFetched } from "../../../functions/src/utils/types"
import { subscribeOnRadarBasedActionResults } from "../data/subscriptions"
import { AuthState, RootContext } from "../store"
import { AppLocationState, ValidLocationState } from "../../models/auth"
import { AuthReason } from "../middlewares/authMiddleware.types"
import * as Sentry from "@sentry/browser"
import { isEmpty } from "../../../functions/src/utils/validators"
import { Actions, FlushStateAction, NavigateActionName } from "./actions"

export const initialState: AuthState = {
    params: None(),
    configs: NotFetched(),
    hubs: NotFetched(),
    permissions: mkWaiting(),

    authentication: ProcessingLogin()
}

const setCopiesCmd = (featureFlags: ConfigFeatureFlags) => Cmd.run(() => setCopySource(featureFlags))

export const reducePermissionChanged = getReducer<
    AuthState,
    PermissionsActions<AppLocationState, AuthReason>,
    RootContext
>((state, action, context) => {
    const { params, permissions } = action.payload
    const cs: Parameters<typeof cmdList>[0] = []
    if (
        isDemoRadarLocation(params) &&
        !isRadarDisabled(params) &&
        isNotLoggedIn(state.authentication) &&
        !state.authentication.emailGiven
    )
        cs.push(uiActions.openPopup("showEmail"))

    if (
        !isEqual(params, state.params) ||
        (permissions.status === "Waiting" && permissions.status !== state.permissions.status)
    ) {
        cs.push(cmds.subscribeOnAuthChange(params, state.authentication))
        cmds.subscribeOnClaimChange(params, state.authentication)
    } else if (isRoot(state.authentication) && isFetched(state.configs)) {
        if (isNotFetched(state.hubs) && isRoot) cs.push(cmds.fetchHubs(state.configs.value))
    }

    if (!isValidLocation(params) || permissions.status !== "Allowed") return [action.payload, cmdList(cs)]
    const root = context.authorizationType === "RootAccess"
    const admin = context.authorizationType === "AdminAccess"
    const { radarId } = params.locationParams
    if (!radarId) return [action.payload, cmdList(cs)]

    cs.push(setCopiesCmd(params.locationParams))

    if (!isUserLocation(params) && !isDemoRadarLocation(params))
        subscribeOnRadarBasedActionResults(radarId, root || admin)

    return [action.payload, cmdList(cs)]
})

export const reducer = getReducer<AuthState, Actions, RootContext>((state, action, context) => {
    switch (action.type) {
        case PermissionsChanged:
            return reducePermissionChanged(state, action, context)

        case "fetchConfigs":
            return [{ configs: Fetching({}) }, cmds.fetchConfigs()]
        case "_setConfigs":
            if (isEmpty(action.payload))
                Sentry.captureException(new Error(`Action Payload is EMPTY! ${JSON.stringify(action)}`))
            return { configs: action.payload }

        case "fetchHubs":
            if (!isFetched(state.configs)) return { hubs: FetchError("No configs fetched") }
            return [{ hubs: Fetching({}) }, cmds.fetchHubs(state.configs.value)]
        case "_setHubs":
            return { hubs: action.payload }

        case "_setDemoEmailGiven":
            return cmdList([
                cmds.setEmailGivenToLocalStorageCmd(action.payload.email),
                cmds.subscribeOnAuthChange(state.params, state.authentication)
            ])

        case "_setLoginStatus":
            return [{ authentication: action.payload }, cmds.onUserStatusChange(state.authentication, action.payload)]

        case "_setAccessRequestResult":
            return { accessRequestStatus: action.payload }

        case "tryFederatedLogin":
            return [{ authentication: ProcessingLogin() }, cmds.tryFederatedLoginCmd(action.payload)]

        case "tryLogout":
            return [
                { authentication: ProcessingLogin() },
                cmds.tryLogoutCmd((state.params as ValidLocationState).locationParams)
            ]

        case "signInViaEmail":
            // See src/store/auth/authCmds
            return cmds.signInViaEmail(action.payload.actionId, action.payload)

        case "tryRequestAccess": {
            const { authentication } = state
            if (
                !isLoggedIn(authentication) ||
                !isAccessNotRequested(authentication) ||
                (!isRadarLocation(context) && !isAdminLocation(context))
            )
                return {}
            return [
                { authentication: LoggedIn(authentication.user, "AccessRequested") },
                cmds.tryRequestAccessCmd(
                    action.payload,
                    context.locationParams.radarId!,
                    authentication.user,
                    window.location.origin
                )
            ]
        }

        case "resetAuthStatuses":
            return [{}, cmds.refreshToken(state.params, state.authentication)]

        case "saveDemoEmail": {
            const lParams = getCurrentRadar(state)
            if (!lParams || !(lParams.radarId || lParams.radarName)) return {}
            const { radarId, radarName } = lParams as LocationParams
            return cmds.saveEmail({ radarId, radarName }, action.payload.email)
        }

        case "checkDeliverableEmail":
            return cmds.checkDeliverableEmail(action.payload.actionId, action.payload)

        case FlushStateAction:
            return cmds.flushSubscriptions()

        case NavigateActionName:
            return isNoLocation(state.params) ? {} : cmds.materializedNavigateCmd(state.params, action.payload)
    }
})
