import { UserCloudActions, UserActionPayload } from "../actionCreators"
import { CloudActionsValidationMap } from "../validators"
import { validate, validString, validNumber } from "../../utils/validators"
import { validateHubPayload } from "../../models/common"
import { validateRadarImportPayload } from "../../models/Radar"
import { validateRadarUser } from "../../models/User"

const validateAccessRequest = validate<UserActionPayload<"requestAccess">>({
    radarId: validString,
    hostname: validString,
    email: validString
})

export const validateUserProfile = validate<UserActionPayload<"updateUserProfile">>({
    displayName: validString
})

export const validateUpdateNewsfeedTimestamp = validate<UserActionPayload<"updateNewsfeedTimestamp">>({
    radarId: validString,
    timestamp: validNumber
})

const validateRadarCreate = validate<UserActionPayload<"createRadar">>({
    hubId: validString,
    radar: [validateRadarImportPayload]
})

const validateRadarUpdate = validate<UserActionPayload<"updateRadar">>({
    radar: [validateRadarImportPayload],
    radarId: validString
})

const validateHubCreate = validate<UserActionPayload<"createHub">>({
    hub: [validateHubPayload]
})

const validateHubUpdate = validate<UserActionPayload<"updateHub">>({
    hub: [validateHubPayload],
    hubId: validString
})

const validateLogUserToken = validate<UserActionPayload<"logUserToken">>({
    userId: validString
})

export const userActionsPayloadValidators: CloudActionsValidationMap<UserCloudActions> = {
    updateUserProfile: [validateUserProfile],
    requestAccess: [validateAccessRequest],
    updateNewsfeedTimestamp: [validateUpdateNewsfeedTimestamp],
    "@updateUser": [validateRadarUser],
    "@createRadar": [validateRadarCreate],
    "@updateRadar": [validateRadarUpdate],
    "@createHub": [validateHubCreate],
    "@updateHub": [validateHubUpdate],
    "@logUserToken": [validateLogUserToken]
}
