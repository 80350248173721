import { storage } from "firebase/app"
import { call } from "../../functions/src/utils"
import { getBlobFromUrl } from "./urlService"
import { Ok, Err } from "../../functions/src/utils/validators"

export type FileRef<T> = storage.Reference & { __type: T }
export interface StorageService {
    refFromPath<T = unknown>(path: string): FileRef<T>

    ref<TKey extends keyof StorageSchema>(node: TKey, filename: string): FileRef<TKey>
    ref<TKey extends keyof StorageSchema, TNode extends keyof ValueOf<StorageSchema[TKey]>>(
        node: TKey,
        nodeId: string,
        subNode: TNode,
        filename: string
    ): FileRef<TNode>

    upload<T>(
        r: FileRef<T>,
        file: File | Blob,
        metadata?: storage.UploadMetadata,
        onStateChanged?: F1<storage.UploadTaskSnapshot>
    ): Promise<void>
    deleteByRef<T>(r: FileRef<T>): Promise<Result<string, string>>
    download<T>(r: FileRef<T>): Promise<File | Blob>
}

export const init = (instance: storage.Storage): StorageService => {
    const ref = <TKey extends keyof StorageSchema, TNode extends keyof ValueOf<StorageSchema[TKey]>>(
        node: TKey,
        nodeId: string,
        subnode?: TNode,
        filename?: string
    ) => {
        if (subnode && !filename) throw Error("Cannot reference a file without a name")
        let r: any = instance.ref(node).child(nodeId) as FileRef<TKey> // attachment.
        if (subnode) r = r.child(subnode) as FileRef<TNode>
        if (filename) r = r.child(filename) as FileRef<TNode>
        return r
    }

    const refFromPath = <T = unknown>(path: string) => instance.refFromURL(path) as FileRef<T>

    const upload = async <T>(
        r: FileRef<T>,
        file: File | Blob,
        metadata?: storage.UploadMetadata,
        onStateChanged?: F1<storage.UploadTaskSnapshot>
    ) =>
        new Promise<void>((res, rej) => {
            const process = r.put(file, metadata)
            process.then(() => res())
            process.catch(rej)
            process.on(storage.TaskEvent.STATE_CHANGED, snap => call(onStateChanged, snap))
        })

    const deleteByRef = async <T>(r: FileRef<T>) =>
        r
            .delete()
            .then(() => Ok("File deleted successfully"))
            .catch(() => Err("Error deleting file", r))

    const download = async <T>(r: FileRef<T>) => {
        const url = await r.getDownloadURL()
        return getBlobFromUrl(url)
    }

    return { ref, refFromPath, upload, download, deleteByRef }
}
