import * as React from "react"
import * as Styles from "./Modal.styles"
import { _Spacer } from "../styles/common"
import { ModalContainer } from "./common/ModalContainer"
import { isFunction } from "../../functions/src/utils/validators"
import { IconSvg } from "./IconSvg"

export type ModalProps = {
    title: string
    isOpen: boolean
    onClose: () => void
    full?: boolean
    scrollingControl?: "modal" | "content"
}

export const Modal: React.FC<ModalProps> = p => (
    <ModalContainer isOpened={p.isOpen} onOverlayClick={p.onClose} onClose={p.onClose}>
        {p.isOpen && (
            <Styles.ModalContainer full={p.full}>
                <Styles.ModalHeader data-cy="modal-header">
                    <Styles.ModalTitle bold>{p.title}</Styles.ModalTitle>
                    <_Spacer />
                    <Styles.ModalCloseButton onClick={p.onClose}>
                        <IconSvg name="close" width={15} height={15} />
                    </Styles.ModalCloseButton>
                </Styles.ModalHeader>
                <Styles.ModalContent nonScrollable={p.scrollingControl === "content"} full={p.full}>
                    {isFunction(p.children) ? p.children() : p.children}
                </Styles.ModalContent>
            </Styles.ModalContainer>
        )}
    </ModalContainer>
)
