import { FormSchema, StyledFormSchema } from "@react-formless/core"
import { getCollectionSchema } from "../../../functions/src/models/schemas"
import { CustomFields } from "../form/FormlessInputs"
import { isEmpty, validArrayStringDef, validNotEmptyArrayString } from "../../../functions/src/utils/validators"
import { getFormlessFormSchema, markAsRequired } from "../../utils/formsSchema"
import { filterObject, keys, mapObject } from "../../../functions/src/utils/map"
import { collectionsValidationMaps, getDisplayNames } from "../../../functions/src/models/collections"

export type EditSection<F> = {
    title: string
    styledSchema: StyledFormSchema<F, CustomFields<F>>
}
export type EditColumn<
    C extends CName,
    F extends Partial<RCollectionFields<C>> & Partial<ItemClassifiersInput> = RCollectionFields<C> &
        ItemClassifiersInput
> = EditSection<F>[]
export type EditDeps = {
    availablePrimarySearchAreas: ROptions
    availableSecondarySearchAreas: ROptions
    availableTags: ROptions
    availableSegmentTags: ROptions
    config: FeatureFlags
}

export type CollectionFormSchema<C extends CName> = FormSchema<RCollectionFields<C> & ItemClassifiersInput>

export type EditSchema<C extends CName> = {
    schema: CollectionFormSchema<C>
    styledSchema: EditColumn<C>[]
}

export const getClassifiersSchema = (p: EditDeps): EditColumn<CName, Partial<ItemClassifiersInput>> => {
    const classifiersSchema: (EditSection<Partial<ItemClassifiersInput>> | null)[] = [
        p.config.withSegmentTags
            ? {
                  title: "Segment Tags",
                  styledSchema: [
                      {
                          type: "Custom",
                          value: {
                              type: "selectableChips",
                              name: "segment_tags"
                          }
                      }
                  ]
              }
            : null,
        // TODO Add p.config.withTags flag
        {
            title: "Tags",
            styledSchema: [
                {
                    type: "Custom",
                    value: {
                        type: "selectableChips",
                        name: "tags"
                    }
                }
            ]
        },
        {
            title: "Search areas",
            styledSchema: [
                {
                    type: "Custom",
                    value: {
                        type: "selectableChips",
                        name: "primary_search_areas"
                    }
                },
                {
                    type: "Custom",
                    value: {
                        type: "selectableChips",
                        name: "secondary_search_areas"
                    }
                }
            ]
        }
    ]
    return classifiersSchema.filter(s => !isEmpty(s)) as EditSection<Partial<ItemClassifiersInput>>[]
}

export const getFieldsSchema = <C extends CName>(cname: C, p: EditDeps): CollectionFormSchema<C> => {
    const displayNames = getDisplayNames(cname)({
        mode: "edit"
    })
    const { fixture, icons: iconsMap } = getCollectionSchema(cname)
    const displayNamesWithStars = mapObject(displayNames, (_, display) => markAsRequired(display)) as Displayable<
        RCollectionFieldsRequired<C>
    >
    const validationMaps = collectionsValidationMaps[cname]
    const requiredSchema = getFormlessFormSchema(
        fixture.required,
        displayNamesWithStars,
        validationMaps,
        iconsMap as any
    )
    const optionalSchema = getFormlessFormSchema(fixture.optional, displayNames, validationMaps, iconsMap as any)

    const schema = {
        ...requiredSchema,
        ...optionalSchema,
        segment_tags: {
            type: "multiselect",
            name: displayNames.segment_tags,
            validators: validArrayStringDef,
            values: p.availableSegmentTags.map(v => [v.label, v.value])
        },
        tags: {
            type: "multiselect",
            name: displayNames.tags,
            validators: validArrayStringDef,
            values: p.availableTags.map(v => [v.label, v.value])
        },
        primary_search_areas: {
            type: "multiselect",
            name: markAsRequired(displayNames.primary_search_areas),
            validators: validNotEmptyArrayString,
            values: p.availablePrimarySearchAreas.map(v => [v.label, v.value])
        },
        secondary_search_areas: {
            type: "multiselect",
            name: displayNames.secondary_search_areas,
            validators: validArrayStringDef,
            values: p.availableSecondarySearchAreas.map(v => [v.label, v.value])
        }
    } as CollectionFormSchema<C>

    return filterObject(schema, k => {
        switch (k) {
            case "segment_tags":
                return !!p.config.withSegmentTags
            // TODO Enable filtering tags
            // case "tags":
            //     return !!p.config.withTags
            default:
                return true
        }
    }) as CollectionFormSchema<C>
}

export const getDefaultEditSchema = <C extends CName>(cname: C): ((p: EditDeps) => EditSchema<C>) => {
    const { fixture } = getCollectionSchema(cname)
    const requiredFields = keys(fixture.required)
    const optionalFields = keys(fixture.optional)

    return (p: EditDeps) => ({
        schema: getFieldsSchema(cname, p),
        styledSchema: [
            [
                {
                    title: "Overview",
                    styledSchema: [
                        requiredFields[0],
                        {
                            type: "Custom",
                            value: {
                                type: "richTextarea",
                                name: "description"
                            }
                        },
                        // TODO Add icons here
                        ...requiredFields.slice(1)
                    ]
                }
            ],
            [
                {
                    title: "Other",
                    styledSchema: optionalFields.filter(f => f !== "description")
                }
            ],
            getClassifiersSchema(p)
        ]
    })
}

export const editSchemas: { [C in CName]: (p: EditDeps) => EditSchema<C> } = {
    startups: getDefaultEditSchema("startups"),
    sectors: (p: EditDeps) => {
        const fieldsSchema = getFieldsSchema("sectors", p)
        return {
            schema: {
                ...fieldsSchema,
                recommended: {
                    type: "customOption",
                    name: fieldsSchema.recommended?.name,
                    values: [
                        ["", 0],
                        ["", 1]
                    ],
                    toValue: v => (!v ? 0 : 1),
                    fromValue: v => (!v ? 0 : 1) as any
                }
            },
            styledSchema: [
                [
                    {
                        title: "Overview",
                        styledSchema: [
                            "sector_name",
                            {
                                type: "Custom",
                                value: {
                                    type: "richTextarea",
                                    name: "description"
                                }
                            },
                            {
                                type: "Custom",
                                value: {
                                    type: "switch",
                                    name: "recommended",
                                    fromValue: v => !!v,
                                    toValue: v => (v ? 1 : 0),
                                    inline: true
                                }
                            },
                            {
                                type: "Custom",
                                value: {
                                    type: "richTextarea",
                                    name: "recommended_description"
                                }
                            },
                            "funding",
                            "primary_customer",
                            "product",
                            "geography",
                            "business_model",
                            {
                                type: "Custom",
                                value: {
                                    type: "richTextarea",
                                    name: "jobs_to_be_done"
                                }
                            }
                        ]
                    }
                ],
                [
                    {
                        title: "Evaluation criteria",
                        styledSchema: [
                            "investor_quality",
                            "sector_concentration",
                            "avg_company_age",
                            "num_rounds",
                            "recent_funding",
                            "funding_momentum",
                            "sector_funding_momentum",
                            "priority_score"
                        ]
                    },
                    {
                        title: "Other",
                        styledSchema: [
                            "company_hex_display",
                            "column",
                            "row",
                            "sector_completed",
                            "market_size_url",
                            "market_size",
                            "source",
                            "capital_traction_url",
                            "customer_type",
                            "incumbents",
                            "sub_verticals"
                        ]
                    }
                ],
                getClassifiersSchema(p)
            ]
        }
    },
    influencers: getDefaultEditSchema("influencers"),
    investors: getDefaultEditSchema("investors"),
    patents: getDefaultEditSchema("patents"),
    research_hubs: getDefaultEditSchema("research_hubs"),
    research_papers: getDefaultEditSchema("research_papers"),
    tech_experts: getDefaultEditSchema("tech_experts"),
    tech_transfers: getDefaultEditSchema("tech_transfers"),
    patent_holders: getDefaultEditSchema("patent_holders"),
    grants: getDefaultEditSchema("grants"),
    clinical_trials: getDefaultEditSchema("clinical_trials"),
    companies: getDefaultEditSchema("companies")
}
