import { transparentize } from "polished"

const colors = { jobToBeDone: "#00B8D9", other: "#0052CC" }

export const getColors = (p?: { label: string; isFocused: boolean; isSelected: boolean }, alpha = 0.1) => {
    if (!p || !p.label.startsWith("Job-to-be")) return {}
    const backgroundColor = transparentize(alpha + (p.isSelected ? 0.3 : 0), colors.jobToBeDone)
    return { color: colors.jobToBeDone, backgroundColor }
}

export const getStyle = (hover = true) => (s: any, { data }: any) => ({
    ...s,
    ...getColors(data),
    ...(hover ? { ":hover": { ...getColors(data, 0.3) } } : {})
})

export const getSelectStyles = (isError: boolean) => ({
    control: (provided: any, state: any) => ({
        ...provided,
        border: `1px solid ${isError ? "#ff151f" : state.isFocused ? "#617084" : "#edeff2"}`,
        boxShadow: "none",
        borderRadius: "20px",
        minHeight: "40px",
        fontSize: "13px",
        "&:hover": {}
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: isError ? "#ff151f" : "#617084",
        fontFamily: "'Lato',sans-serif"
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        padding: "8px 15px"
    })
})

export const getTagSelectStyles = (isError: boolean) => ({
    option: getStyle(),
    multiValue: getStyle(false),
    multiValueLabel: getStyle(false),
    multiValueRemove: getStyle(),
    control: (provided: any, state: any) => ({
        ...provided,
        ...getStyle(),
        border: `1px solid ${isError ? "#ff151f" : state.isFocused ? "#617084" : "#edeff2"}`,
        boxShadow: "none",
        borderRadius: "20px",
        minHeight: "40px",
        fontSize: "13px"
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: isError ? "#ff151f" : "#617084",
        fontFamily: "'Lato',sans-serif"
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        padding: "8px 15px"
    })
})
