/* eslint-disable max-lines */
import { formatDecimal } from "../../../functions/src/models/converters"
import { DEFAULT_PLACEHOLDER } from "../../../functions/src/models/shared"
import { isEmpty } from "../../../functions/src/utils/validators"
import { mkString, cutNonLatinString } from "../../../functions/src/utils"
import {
    mkTableIconTitleSchema,
    mkTableTitleSchema,
    mkTableComputedSchema,
    mkTableNumberSchema,
    mkTableTextSchema
} from "./TableViewSchemas"
import {
    ColumnDefinition,
    HeaderCell,
    Cell,
    IconTitleCell,
    StarCell,
    CommentBubbleCell,
    CommmentBubbleDecker,
    PillCell,
    BoxCell,
    ColumnSchema,
    RowEffect,
    Row,
    RowSchema,
    DropdownCell,
    FileCell,
    CellSize
} from "./TableView.types"

export const HeaderTitle = <ID extends string>(
    id: ID,
    value: string | React.ReactElement,
    size: CellSize = {
        min: 200
    },
    key: string,
    definition?: ColumnDefinition
): HeaderCell<ID> => ({
    id,
    value,
    type: "header-title",
    definition,
    size,
    sortingKey: key
})

export const RawHeaderTitle = <ID extends string>(
    id: ID,
    value: string | React.ReactElement,
    size: CellSize = {
        min: 200
    },
    key: string,
    definition?: ColumnDefinition
): HeaderCell<ID> => ({
    id,
    value,
    type: "header-title",
    definition,
    size,
    sortingKey: key,
    rawContent: true
})

const ACCEPTABLE_TITLE_LENGTH = 50
const ACCEPTABLE_TEXT_LENGTH = 32
export const Title = <ID extends string>(
    id: ID,
    v: string,
    size: CellSize = {
        min: 200
    },
    clickable?: boolean
): Cell<ID> => ({
    id,
    value: cutNonLatinString(v, ACCEPTABLE_TITLE_LENGTH),
    type: "title",
    size,
    clickable
})

export const Text = <ID extends string>(
    id: ID,
    v: string,
    size: CellSize = {
        min: 200
    },
    clickable?: boolean
): Cell<ID> => ({
    id,
    value: cutNonLatinString(v, ACCEPTABLE_TEXT_LENGTH),
    type: "text",
    size,
    clickable
})

export const IconTitle = <ID extends string>(
    id: ID,
    v: string,
    url: string,
    size: CellSize = {
        min: 200
    },
    clickable?: boolean
): IconTitleCell<ID> => ({
    id,
    value: cutNonLatinString(v, ACCEPTABLE_TITLE_LENGTH),
    type: "icon-title",
    url,
    size,
    clickable
})

export const Star = <ID extends string>(
    id: ID,
    value: boolean,
    size: CellSize = {
        min: 200
    },
    clickable?: boolean
): StarCell<ID> => ({
    id,
    type: "star",
    size,
    value,
    clickable
})

export const mkDropdownCell = <ID extends string>(
    id: ID,
    selected: ROption,
    options: ROption[],
    meta: AreaDecoratorMeta,
    size: CellSize = {
        min: 200
    },
    clickable?: boolean
): DropdownCell<ID> => ({ id, type: "dropdown", size, meta, value: { selected, options }, clickable })

export const CommentBubble = <ID extends string>(
    id: ID,
    value: number,
    size: CellSize = {
        min: 200
    },
    clickable?: boolean
): CommentBubbleCell<ID> => ({
    id,
    type: "comment",
    size,
    value,
    clickable
})

export const CommentBubbleDecker = <ID extends string>(
    id: ID,
    upper: number,
    lower: number,
    size: CellSize = {
        min: 200
    },
    clickable?: boolean
): CommmentBubbleDecker<ID> => ({
    id,
    type: "comment-bubble-decker",
    size,
    value: { upper, lower },
    clickable
})

export const Pill = <ID extends string>(
    id: ID,
    v: string,
    size: CellSize = {
        min: 200
    },
    clickable?: boolean
): PillCell<ID> => ({
    id,
    type: "pill",
    value: cutNonLatinString(v, ACCEPTABLE_TITLE_LENGTH),
    size,
    clickable
})

export const mkFile = <ID extends string>(
    id: ID,
    v: string,
    size: CellSize = {
        min: 200
    },
    clickable?: boolean
): FileCell<ID> => ({
    id,
    type: "file",
    value: cutNonLatinString(v, ACCEPTABLE_TITLE_LENGTH),
    size,
    clickable
})
export const Box = <ID extends string>(
    id: ID,
    value: string,
    size: CellSize = {
        min: 200
    },
    clickable?: boolean
): BoxCell<ID> => ({
    id,
    type: "box",
    value,
    size,
    clickable
})

export const mkRow = <T extends SMap<unknown>>(
    id: string | number,
    schema: ColumnSchema<T>[],
    item: T,
    effects: RowEffect[] = []
): Row => {
    const cells = schema.map(schemaItem => {
        switch (schemaItem.type) {
            case "ts-number":
                return Text(
                    schemaItem.key,
                    formatDecimal(
                        item[schemaItem.key],
                        schemaItem.placeholderIfZero,
                        schemaItem.decimals,
                        !!schemaItem.decimals
                    ),
                    schemaItem.size,
                    schemaItem.clickable
                )

            case "ts-text":
                return Text(
                    schemaItem.key,
                    mkString(item[schemaItem.key]) || schemaItem.placeholder,
                    schemaItem.size,
                    schemaItem.clickable
                )

            case "ts-computed-title":
                return Title(schemaItem.key, schemaItem.getValue(item), schemaItem.size, schemaItem.clickable)

            case "ts-computed-text":
                return Text(schemaItem.key, schemaItem.getValue(item), schemaItem.size, schemaItem.clickable)

            case "ts-computed-number":
                return Text(
                    schemaItem.key,
                    formatDecimal(schemaItem.getValue(item)),
                    schemaItem.size,
                    schemaItem.clickable
                )

            case "ts-title":
                return Title(schemaItem.key, item[schemaItem.key] as any, schemaItem.size, schemaItem.clickable)

            case "ts-icon-title":
                const icon =
                    schemaItem.urlKeys.reduce((url, key) => (isEmpty(url) ? item[key] || url : url), null) || ""
                return IconTitle(
                    schemaItem.key,
                    item[schemaItem.key] as any,
                    icon as any,
                    schemaItem.size,
                    schemaItem.clickable
                )

            case "ts-pill":
                return Pill(schemaItem.key, item[schemaItem.key] as any, schemaItem.size, schemaItem.clickable)
            case "ts-file":
                return mkFile(schemaItem.key, item[schemaItem.key] as any, schemaItem.size, schemaItem.clickable)

            case "ts-box":
                return Box(schemaItem.key, item[schemaItem.key] as any, schemaItem.size, schemaItem.clickable)
        }
    })
    return { cells, id, effects }
}

export const add = (p1: TopLeft, p2: TopLeft) => ({ left: p1.left + p2.left, top: p1.top + p2.top })

export const calculatePostion = (index: number, offset: Partial<Bounds>, h = 71): TopLeft => ({
    top: index * h + (offset.top || 0),
    left: offset.left || 0
})

type RowSchemaOptions<T extends SMap<unknown>, TKs extends keyof T> = {
    titleKey?: TKs
    iconTitleKeys?: TKs[]
}

const nameColSize: CellSize = {
    min: 200
}
const defaultColSize: CellSize = {
    min: 200
}
const numberColSize: CellSize = {
    min: 150
}

export const getRowSchema = <T extends SMap<unknown>, TKs extends keyof T & string>(
    o: T,
    displayFields: Displayable<T>,
    keysToDisplay: TKs[],
    options: RowSchemaOptions<T, TKs> = {}
): RowSchema<T> => {
    const schema: RowSchema<T> = []
    keysToDisplay.forEach(k => {
        const display = displayFields[k]
        if (options.titleKey === k) {
            return options.iconTitleKeys
                ? schema.push(mkTableIconTitleSchema<T>(displayFields[k], k, options.iconTitleKeys, nameColSize))
                : schema.push(mkTableTitleSchema<T>(displayFields[k], k, nameColSize))
        }
        if (Array.isArray(o[k]))
            return schema.push(
                mkTableComputedSchema(
                    display,
                    k,
                    "text",
                    v => ((v[k] as any) || []).join(", ") || DEFAULT_PLACEHOLDER,
                    defaultColSize
                )
            )
        // TODO Fix those table schemas to take formatted text from object types formatters
        if (typeof o[k] === "number")
            return schema.push(
                mkTableNumberSchema<T>(
                    display,
                    k as any,
                    numberColSize,
                    DEFAULT_PLACEHOLDER,
                    k === "investor_quality" ? 2 : undefined
                )
            )
        return schema.push(mkTableTextSchema<T>(display, k, defaultColSize, DEFAULT_PLACEHOLDER))
    })
    return schema
}
