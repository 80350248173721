import { isCName, getCollectionSchema, getCollectionFromId } from "../../../functions/src/models/schemas"
import { assertNever } from "../../../functions/src/utils"
import { omitObject, extend, remap, identity, keys, values } from "../../../functions/src/utils/map"
import { DataRequestCallbacks, RadarFetchAttribute, DataRestriction } from "./subscriptionSchema"
import { Fetched } from "../../../functions/src/utils/types"
import { getCopy } from "../../../functions/src/services/copy"
import { CmdArg, impossibleStateBreaker } from "../../utils/redux"
import { isValidLocation, isUserLocation, isStaticLocation } from "../../models/LocationType"
import { Paths, paths } from "../../paths"
import { matchesPath } from "../../utils/router"
import { getDependenciesForPath } from "../../dependencies"
import { actions } from "./actions"
import { RadarLocationState, AppLocationState, AdminLocationState } from "../../models/auth"
import {
    DependencySchema,
    PipelineStageValueVM,
    ViewModelsBase,
    RelationsByCName
} from "../../../functions/src/models/ViewModels"
import { getFirebase } from "../../services/firebase"
import { validateSecureKey } from "../../../functions/src/models/User"
import { isOk } from "../../../functions/src/utils/validators"
import { decryptPipelines } from "../../../functions/src/models/decorators"

const RadarUserFromContactRecord = (c: ContactRecord): RadarUser => ({
    ...c,
    displayName: "-",
    isAdmin: false,
    isDeactivated: false,
    type: "Approved",
    userId: c.email
})

export const prepareSubscribers = (records: SMap<ContactRecord>): SMap<RadarUser> =>
    remap(records, (_, v) => v.email, RadarUserFromContactRecord)

export const preparePipelines = (radarId: string) => async (
    records: SMap<PipelineStageValue>
): Promise<SMap<PipelineStageValueVM>> => {
    const { firestore } = getFirebase()
    const secureKey = await firestore.fetchDoc(firestore.ref("secureKeys", radarId), validateSecureKey)
    const encryptionKey = isOk(secureKey) ? secureKey.value.key : null
    return decryptPipelines(
        remap(records, identity, pv => (pv.order ? (pv as PipelineStageValueVM) : { ...pv, order: -1 })),
        encryptionKey
    )
}

// dontFetch cannot be synchronous, because the it will cause race conditions in view model transformations
// (i.e. three transformations relying on each other will fire instantly and none will know about the finish of others)
// therefore by setTimout 0 we delay it in the event loop and ensure async
export const fakeFetch = async (p: DataRequestCallbacks) => setTimeout(() => p.onSuccess(Fetched({} as any)), 0)

export const shouldFakeFetch = (radarAttrs: RadarFetchAttribute[], restrictions: DataRestriction[]) => {
    const abortDemo = radarAttrs.includes("demo") && restrictions.includes("no-demo")
    const abortRoot = radarAttrs.includes("root") && restrictions.includes("no-root")
    const abortNoDemo = !radarAttrs.includes("root") && restrictions.includes("root-only")
    const force = restrictions.includes("force-no-fetch")
    return abortDemo || abortRoot || force || abortNoDemo
}

export const displayFetchingKeys = (key: keyof ViewModelsBase): string | null => {
    if (isCName(key)) return getCollectionSchema(key).displayName
    switch (key) {
        case "comments":
            return "Comments"
        case "decorators":
            return "Decorators"
        case "radar":
            return "Radar"
        case "secureKeys":
            return "Secure keys"
        case "relations":
            return "Relations"
        case "searchAreas":
            return getCopy("searchAreas")
        case "users":
            return "Users"
        case "news":
            return "News"
        case "tags":
            return "Tags"
        case "segmentTags":
            return "Segment tags"
        case "pipelines":
        case "searchAreasAssignments":
        case "tagsAssignments":
        case "segmentTagsAssignments":
        case "subscribers":
        case "config":
            return null
    }
    assertNever(key)
}

export const RELATION_CURRENT_OBJECT_INDEX = 0
export const createRelationTuple = (currentObjectId: string, newRelationId: string): [string, string] => [
    currentObjectId,
    newRelationId
]

export const upsertRelationRemoval = (stateRelations: SMap<RelationsByCName>, newRelation: [string, string]) => {
    const result: SMap<RelationsByCName> = {}
    const removeRelation = (r1: string, r2: string) => {
        if (!stateRelations[r1]) return
        const cname = getCollectionFromId(r2)
        if (!cname) return
        const relation = { ...stateRelations[r1], [cname]: stateRelations[r1][cname]?.filter(r => r !== r2) || [] }
        result[r1] = relation
    }
    removeRelation(newRelation[0], newRelation[1])
    removeRelation(newRelation[1], newRelation[0])
    return result
}

export const upsertDecorator = (decorators: SMap<DecoratorsMap>, payload: DecoratorMutationPayload): DecoratorsMap => {
    const currentDecorator = decorators[payload.objectId] || {}
    const ext = extend(currentDecorator)
    switch (payload.type) {
        case "star":
            return ext({ star: { value: Boolean(payload.newValue) } })
        case "assignment":
            return payload.newValue
                ? ext({ assignment: { userId: payload.newValue } })
                : omitObject(currentDecorator, ["assignment"])
        case "attachment":
            const extAttachments = extend(currentDecorator.attachment)
            return ext({
                attachment: payload.newValue
                    ? extAttachments({ [payload.path]: { path: payload.path, ...payload.newValue } })
                    : omitObject(currentDecorator.attachment || {}, [payload.path])
            })
        case "pipelineStage":
            const extPipelines = extend(currentDecorator.pipelineStage)
            return ext({
                pipelineStage: payload.newValue
                    ? extPipelines({
                          [payload.searchAreaId]: { searchAreaId: payload.searchAreaId, valueRef: payload.newValue }
                      })
                    : omitObject(currentDecorator.pipelineStage || {}, [payload.searchAreaId])
            })
        case "priorityRank":
            const extPriorities = extend(currentDecorator.priorityRank)
            return ext({
                priorityRank: payload.newValue
                    ? extPriorities({
                          [payload.searchAreaId]: { searchAreaId: payload.searchAreaId, value: payload.newValue }
                      })
                    : omitObject(currentDecorator.priorityRank || {}, [payload.searchAreaId])
            })
        case "lead":
            const extLeads = extend(currentDecorator.lead)
            return ext({
                lead: extLeads(payload.newValue as ContactedLeadDecorator)
            })
    }
    assertNever(payload)
}

const getPathFromPathname = (pathname: string): keyof Paths | undefined =>
    keys(paths).find(k => matchesPath(paths[k].path, pathname, true))

const ITEMS_PER_PAGE = 50

export const PageCursor = (startingAfter = 0): Cursor => ({
    type: "page",
    value: {
        limit: ITEMS_PER_PAGE,
        startingAfter
    }
})
const ITEMS_PER_PAGE_DASHBOARD = 10

const getCursorForFetchLevel = (
    fl: FetchLevel,
    location: RadarLocationState | AdminLocationState,
    isDashboard: boolean
): Cursor => {
    if (fl === "page")
        return {
            type: "page",
            value: { limit: isDashboard ? ITEMS_PER_PAGE_DASHBOARD : ITEMS_PER_PAGE, startingAfter: 0 }
        }
    if (fl === "single") {
        const { params } = location as RadarLocationState
        const paramsKeys = keys(params)
        if (!paramsKeys.length || paramsKeys.length > 1)
            impossibleStateBreaker("cursor creation", "There should be exactly one ID param in state")
        return { type: "single", value: values(params)[0] }
    }
    return { type: "none" }
}

export const fetchRequiredData = (
    radarId: string,
    location: AppLocationState,
    radarName?: string,
    hubName?: string
): (F0<CmdArg> | CmdArg)[] => {
    if (!isValidLocation(location) || isUserLocation(location) || isStaticLocation(location)) return []
    const path = getPathFromPathname(location.pathname)
    if (!path) return impossibleStateBreaker("path recognition", "Unrecognized path", [])

    const schema = getDependenciesForPath(path) as DependencySchema

    // TODO This is wrong! what if there is some page and single in there?
    const hasPage = values(schema).some(s => s === "page")
    const hasSingle = values(schema).some(s => s === "single")
    const cursor = getCursorForFetchLevel(
        hasPage ? "page" : hasSingle ? "single" : "all",
        location,
        path.includes("dashboard")
    )
    return [
        hasSingle
            ? actions._fetchMerge(radarId, radarName || null, hubName || null, schema, cursor)
            : actions._fetch(radarId, radarName || null, hubName || null, schema, cursor)
    ]
}
