import * as React from "react"
import {
    EditorState,
    ContentState,
    RichUtils,
    DraftInlineStyleType,
    convertFromRaw,
    convertToRaw,
    Modifier,
    CompositeDecorator,
    getDefaultKeyBinding
} from "draft-js"
import Editor from "draft-js-plugins-editor"
import createAutoListPlugin from "draft-js-autolist-plugin"
const autoListPlugin = createAutoListPlugin()

import { RichTextAreaInputProps } from "../../utils/forms"
import { _Label } from "./Input.styles"
import { _HorizontalSpace, _Spacer } from "../../styles/common"
import { ErrorLabel, getValidationError } from "./Input"
import { _noop } from "../../../functions/src/utils"
import {
    _RichTextAreaWrapper,
    EditorButton,
    _RichTextDescriptionContainer,
    _MaximizedSaveBtn,
    _RichTextAreaError,
    _RichTextAreaToolbar
} from "./RichEditor.styles"
import { CoreDraftBlockType } from "../../../functions/src/utils/richText"
import { SmallButton } from "../../styles/buttons"
import { IconSvg } from "../IconSvg"
import { EditorLink, findLinkEntities, handleLinkButtonClick, linkUnderlineError } from "./editor/link"
import { getSelectedBlockType, getSelectedEntityType, selectionContainsEntity } from "./editor/utils"
import { asyncConnect } from "../../store/async/asyncConnect"
import { isErr, Ok, runValidatorsRaw } from "../../../functions/src/utils/validators"
import { ThemeColor } from "../../styles/styled"
import { Icon } from "../../../functions/src/models/icons"

// remember to update getLegacyTextStateBackend
export const getLegacyTextState = (v: EditorState | string = "", decorator?: Draft.CompositeDecorator) => {
    if (v instanceof EditorState) return v
    try {
        const parsed = JSON.parse(v)
        return EditorState.createWithContent(convertFromRaw(parsed), decorator)
    } catch {
        return EditorState.createWithContent(ContentState.createFromText(v), decorator)
    }
}

const decorator = new CompositeDecorator([
    {
        strategy: findLinkEntities,
        component: EditorLink
    }
])

export const createRichEditorState = (v?: string | EditorState) => {
    if (!v) return EditorState.createEmpty(decorator)
    return getLegacyTextState(v, decorator)
}

export const mapRichTextStateToRaw = (es: EditorState): string => {
    const content = es.getCurrentContent()
    return content.hasText() ? JSON.stringify(convertToRaw(es.getCurrentContent())) : ""
}

export const keyBindingFn = (e: React.KeyboardEvent): string | null => {
    if (e.key === "Tab") return "tab-key"
    return getDefaultKeyBinding(e)
}

export const RichTextAreaInput = asyncConnect<RichTextAreaInputProps<any>>()({
    actions: ["openPopup"]
})(p => {
    const validate = React.useCallback(
        (v: string) => (p.schema.validators ? runValidatorsRaw(p.schema.validators, v) : Ok(v)),
        [p.schema]
    )
    const icon: Icon | undefined = (p.schema as any).icon // TODO Make it better typed
    const isError = !!getValidationError(p.state)
    const [error, setError] = React.useState("")
    const [maximized, setMaximized] = React.useState(false)
    const [editorState, setEditorState] = React.useState(createRichEditorState(p.state.value))
    const onChange = React.useCallback(
        (value: EditorState) => {
            setEditorState(value)
            if (isErr(p.state.validationResult) && editorState.getCurrentContent() !== value.getCurrentContent())
                p.setDelta({ ...p.state, validationResult: undefined })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [p.state, editorState]
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onFocus = React.useCallback(() => p.setDelta({ ...p.state, active: true }), [p.state, p.setDelta])
    const onBlur = React.useCallback(
        () => {
            const newValue = mapRichTextStateToRaw(editorState)
            p.setDelta({ active: false, visited: true, value: newValue, validationResult: validate(newValue) })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [p.state, p.setDelta, editorState]
    )

    const onInlineChange = (s: DraftInlineStyleType) => {
        if (s === "UNDERLINE" && selectionContainsEntity(editorState, e => e.getType() === "LINK")) {
            setError(linkUnderlineError)
            return
        }
        onChange(RichUtils.toggleInlineStyle(editorState, s))
    }
    const onBlockChange = (s: CoreDraftBlockType) => onChange(RichUtils.toggleBlockType(editorState, s))
    const onSoftEnter = () => onChange(RichUtils.insertSoftNewline(editorState))
    const handleKeyCommand = (c: string, s: EditorState) => {
        if (c === "underline" && selectionContainsEntity(s, e => e.getType() === "LINK")) {
            setError(linkUnderlineError)
            return "not-handled"
        }
        const newState = RichUtils.handleKeyCommand(s, c)
        if (newState) {
            onChange(newState)
            return "handled"
        }
        if (c === "tab-key") {
            const contentState = Modifier.insertText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                "\t",
                editorState.getCurrentInlineStyle()
            )
            const newEditorState = EditorState.push(editorState, contentState, "insert-characters")
            onChange(EditorState.forceSelection(newEditorState, contentState.getSelectionAfter()))
            return "handled"
        }
        return "not-handled"
    }

    React.useEffect(() => {
        if (error) setError("")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorState])

    React.useEffect(() => {
        setEditorState(createRichEditorState(p.state.value))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.state.value])

    return (
        <div>
            <_Label error={isError}>
                {icon && (
                    <>
                        <IconSvg name={icon} width={23} height={23} />
                        <_HorizontalSpace base="10px" />
                    </>
                )}
                {p.schema.name}
            </_Label>
            <_RichTextAreaWrapper maximized={maximized} validationError={!!getValidationError(p.state)}>
                <Editor
                    onChange={onChange}
                    editorState={editorState}
                    plugins={[autoListPlugin]}
                    placeholder={p.schema.placeholder}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    handleKeyCommand={handleKeyCommand}
                    keyBindingFn={keyBindingFn}
                />
                <_RichTextAreaToolbar>
                    {error && <_RichTextAreaError>{error}</_RichTextAreaError>}
                    <_HorizontalSpace base="10px" />
                    <EditorButton
                        onMouseDown={(e: React.MouseEvent) => {
                            e.preventDefault()
                            onInlineChange("BOLD")
                        }}
                        title="BOLD"
                        enabled={editorState.getCurrentInlineStyle().has("BOLD")}>
                        <IconSvg name="editor:bold" width={16} height={16} />
                    </EditorButton>
                    <EditorButton
                        onMouseDown={(e: React.MouseEvent) => {
                            e.preventDefault()
                            onInlineChange("UNDERLINE")
                        }}
                        title="UNDERLINE"
                        enabled={editorState.getCurrentInlineStyle().has("UNDERLINE")}>
                        <IconSvg name="editor:underline" width={16} height={16} />
                    </EditorButton>
                    <EditorButton
                        onMouseDown={(e: React.MouseEvent) => {
                            e.preventDefault()
                            onInlineChange("ITALIC")
                        }}
                        title="ITALIC"
                        enabled={editorState.getCurrentInlineStyle().has("ITALIC")}>
                        <IconSvg name="editor:italic" width={16} height={16} />
                    </EditorButton>
                    <EditorButton
                        onMouseDown={(e: React.MouseEvent) => {
                            e.preventDefault()
                            onBlockChange("unordered-list-item")
                        }}
                        title="BULLET LIST"
                        enabled={getSelectedBlockType(editorState) === "unordered-list-item"}>
                        <IconSvg name="editor:bullet-list" width={16} height={16} />
                    </EditorButton>
                    <EditorButton
                        onMouseDown={(e: React.MouseEvent) => {
                            e.preventDefault()
                            handleLinkButtonClick(editorState, onChange, p.openPopup)
                        }}
                        title="LINK"
                        enabled={getSelectedEntityType(editorState) === "LINK"}>
                        <IconSvg name="editor:hyperlink" width={16} height={16} />
                    </EditorButton>
                    <EditorButton onClick={() => onSoftEnter()} title="NEW LINE">
                        <IconSvg name="editor:new-line" width={16} height={16} />
                    </EditorButton>
                    <_Spacer />
                    <EditorButton
                        onClick={() => setMaximized(!maximized)}
                        title={maximized ? "MINIMIZE" : "MAXIMIZE"}
                        enabled={maximized}>
                        <IconSvg name="editor:maximize" width={16} height={16} />
                    </EditorButton>
                    <_HorizontalSpace base="8px" />
                </_RichTextAreaToolbar>
                {maximized && (
                    <_MaximizedSaveBtn>
                        <SmallButton onClick={() => setMaximized(false)}>Save</SmallButton>
                    </_MaximizedSaveBtn>
                )}
            </_RichTextAreaWrapper>
            <ErrorLabel {...p.state} />
        </div>
    )
})

export type RichTextDescriptionProps = { value: string; maxLines?: number; color?: ThemeColor }

export const RichTextDescription = (p: RichTextDescriptionProps) => (
    <_RichTextDescriptionContainer maxLines={p.maxLines} color={p.color}>
        <Editor editorState={createRichEditorState(p.value)} readOnly onChange={_noop} />
    </_RichTextDescriptionContainer>
)
