import styled from "styled-components"
import { Flex } from "../../styles/common"
import { _TagFont } from "../../styles/typography"
import { SubpageH1 } from "../Page"

export const _DashboardSummaryItem = styled(Flex)`
    align-items: baseline;
    ${_TagFont} {
        flex-shrink: 0;
        font-size: 9px;
        line-height: normal;
        font-weight: normal;
        letter-spacing: 1.64;
    }
`

export const _DashboardSummaryTitle = styled(SubpageH1)`
    margin: 0 20px;
`

export const _DashboardSummary = styled(Flex)`
    position: relative;
    margin: 0 20px;
    min-height: 36px;
    align-items: flex-end;

    & > *:not(:last-child) {
        margin-right: 40px;
    }
`

export const StyledDashboardSummaryNumber = styled.p`
    font-size: 24px;
    line-height: 1.17;
    margin: 0;
    font-weight: bold;
    letter-spacing: -0.35px;
    color: ${({ theme }) => theme.colors.primary};
`

export const _DashboardSummarySA = styled(Flex)`
    flex-grow: 1;
    justify-content: flex-end;
    align-items: baseline;
    font-size: 16px;
    line-height: 1.17;
    font-weight: bold;
    letter-spacing: -0.35px;

    ${_TagFont} {
        flex-shrink: 0;
        font-size: 9px;
        line-height: normal;
        font-weight: normal;
        letter-spacing: 1.64;
    }
`

export const _DashboardSummaryFetchError = styled(Flex)`
    position: absolute;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.95);
    font-weight: bold;
    font-size: 16px;
    padding-top: 6px;
    cursor: pointer;
`
