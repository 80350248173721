import { useList } from "./useList"
import React from "react"
import { Table } from "../../components/table/TableView"
import { _BasicListContainer } from "./BasicList.styles"
import { ListParams } from "./Lists"
import { NavigationParams } from "../../utils/router.types"
import { ValueCellMeta } from "../../components/table/TableViewCell"

type BasicListProps = OmitStrict<ListParams<CName>, "loadingMore"> & {
    getItemPath: F1<string, NavigationParams>
    navigate: F1<NavigationParams>
    onCellContentClick: F1<ValueCellMeta<string>>
}

export const BasicList = (p: React.PropsWithChildren<BasicListProps>) => {
    const tableProps = useList(p)

    return (
        <_BasicListContainer>
            <Table
                {...tableProps}
                sortingKey={undefined}
                sortingColumn={undefined}
                sortingAsc={undefined}
                showTooltip={false}
                smallLoader
                onCellContentClick={p.onCellContentClick}
                onRowClick={item => p.navigate(p.getItemPath(item as string))}
            />
        </_BasicListContainer>
    )
}
