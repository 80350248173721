import { Fetching } from "../../../functions/src/utils/types"
import { DashboardSummaryData } from "../../components/dashboard/DashboardSummary"
import { ViewModelsBase } from "../../../functions/src/models/ViewModels"
import { mkInitialState } from "./utils"
import { AsyncState, RootContext } from "../store"
import { getReducer } from "../../utils/redux"
import * as cmds from "./asyncCmds"
import { Actions } from "./actions"

export const initialState: AsyncState = {
    topStartups: mkInitialState<ViewModelsBase["startups"]>(),
    topSectors: mkInitialState<ViewModelsBase["sectors"]>(),
    topCompanies: mkInitialState<ViewModelsBase["companies"]>(),
    topPatents: mkInitialState<ViewModelsBase["patents"]>(),
    topTechTransfers: mkInitialState<ViewModelsBase["tech_transfers"]>(),
    topClinicalTrials: mkInitialState<ViewModelsBase["clinical_trials"]>(),
    topResearchPapers: mkInitialState<ViewModelsBase["research_papers"]>(),
    topGrants: mkInitialState<ViewModelsBase["grants"]>(),
    topInvestors: mkInitialState<ViewModelsBase["investors"]>(),
    topResearchHubs: mkInitialState<ViewModelsBase["research_hubs"]>(),
    topTechExperts: mkInitialState<ViewModelsBase["tech_experts"]>(),
    topInfluencers: mkInitialState<ViewModelsBase["influencers"]>(),
    topPatentHolders: mkInitialState<ViewModelsBase["patent_holders"]>(),
    dashboardSummary: mkInitialState<Result<DashboardSummaryData>>(),
    sectorsPositioning: mkInitialState<ViewModelsBase["sectors"]>()
}

export const reducer = getReducer<AsyncState, Actions, RootContext>((state, action) => {
    switch (action.type) {
        case "updateAsyncState":
            return {
                ...state,
                [action.payload.key]: action.payload.value
            }
        case "setAsyncFetching":
            const cs = state[action.payload.key]
            cs.task?.cancel()
            return {
                ...state,
                [action.payload.key]: {
                    ...Fetching(cs.type === "Fetched" || cs.type === "Fetching" ? (cs as any).value : undefined),
                    task: action.payload.task
                }
            }
        case "refreshAsyncData":
            return cmds.refreshData(action.payload)
    }
    return state
})
