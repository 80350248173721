import styled from "styled-components"
import { ThemeColor } from "../../styles/styled"

export const _Badge = styled.div<{ color: ThemeColor; fontSize?: number; size: number }>`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: ${p => p.theme.colors[p.color]};

    letter-spacing: normal;
    color: #fff;
    border-radius: ${p => p.size / 2}px;
    padding: ${p => 0.1 * p.size}px 5px;
    min-width: ${p => p.size}px;
    min-height: ${p => p.size}px;
    font-size: ${p => (p.fontSize ? `${p.fontSize}px` : "12px")};
    line-height: ${p => 0.8 * p.size}px;
`
