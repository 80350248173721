import { specialValues } from "../converters"
import { keys, values } from "../../utils/map"
import { getPipelineStagesMap, priorityRankSortingLevels, NO_DECORATOR } from "../decoratorValues"
import { getCollectionSchema } from "../schemas"
import { getCachedData } from "../../services/httpEndpoint/cache/cache"
import { PreparationPack } from "../../services/httpEndpoint/data/preparations"
import { ViewModelsMap } from "../ViewModels"
import { isEmpty, isString } from "../../utils/validators"
import { primitiveCompareAsc } from "../../utils"
import { isPG } from "../Radar"

export type GenericSortingKey = OmitStringUnion<Decorators, "attachment" | "lead"> | "star"

export type SortingKey<T extends CName> = keyof ViewModelsMap[T] | GenericSortingKey
export type SortingParams<T extends CName> = {
    sortingAsc?: boolean
    sortingKey?: SortingKey<T>
}
export type Sorting = { [P in CName]?: SortingParams<P>[] }

type SortingParamsPayload<C extends CName, S extends SortingParams<C>[] = SortingParams<C>[]> = {
    cname: C
    config: LocationParams
    sorting?: S
    searchAreaId?: string
}

export const sortingDirection: { [K in SortDirection]: K } = {
    asc: "asc",
    desc: "desc"
}

export const getSortingParams = <C extends CName>({
    cname,
    searchAreaId,
    sorting,
    config
}: SortingParamsPayload<C>): Required<SortingParams<C>>[] => {
    const { defaultSortField, sortDirection } = getCollectionSchema(cname)
    const defaultSorting = [
        isPG(config.hubSlug) && searchAreaId && config.withStars
            ? {
                  sortingKey: "star",
                  sortingAsc: false
              }
            : undefined,
        isPG(config.hubSlug) && searchAreaId
            ? {
                  sortingKey: "pipelineStage",
                  sortingAsc: true
              }
            : undefined,
        isPG(config.hubSlug) && config.withPriorityRanks && searchAreaId
            ? {
                  sortingKey: "priorityRank",
                  sortingAsc: false
              }
            : undefined,
        {
            sortingKey: defaultSortField as SortingKey<C>,
            sortingAsc: sortDirection === "asc"
        }
    ].filter(s => !isEmpty(s)) as Required<SortingParams<C>>[]

    return sorting?.[0]?.sortingKey ? (sorting as Required<SortingParams<C>>[]) : defaultSorting
}

const prepareValueToSort = <C extends CName>(
    cname: C,
    sortingKey: SortingKey<C>,
    searchAreaId = "",
    decorators: TMap<ObjectId, DecoratorsMap>,
    users: TMap<UserId, RadarUser>,
    pipelines: SMap<PipelineStageValue>
): ((v: ViewModelsMap[C]) => string | number) => {
    const getId = (v: ViewModelsMap[C]): string => v[getCollectionSchema(cname).idField] as any
    switch (sortingKey) {
        case "assignment": {
            return v => {
                const assignment = decorators[getId(v)]?.assignment
                if (!assignment) return ""
                const user = users[assignment.userId]
                return user ? user.displayName || user.email : ""
            }
        }
        case "star":
            return v => (decorators[getId(v)]?.star?.value ? 1 : 0)
        case "priorityRank":
            return v => {
                const ranks = decorators[getId(v)]?.priorityRank
                if (!ranks?.[searchAreaId]) return 0
                return priorityRankSortingLevels[ranks?.[searchAreaId].value] || 0
            }
        case "pipelineStage": {
            return v => {
                const staticPipelines = getPipelineStagesMap()
                const pipelineDecorator = decorators[getId(v)]?.pipelineStage

                if (isEmpty(pipelineDecorator)) return staticPipelines[NO_DECORATOR].order
                const ref = pipelineDecorator[searchAreaId]?.valueRef

                if (keys(staticPipelines).includes(ref)) return staticPipelines[ref].order
                return pipelines[ref]?.order ?? staticPipelines[NO_DECORATOR].order
            }
        }
        default:
            return v => v[sortingKey] as any
    }
}

export const sortCollection = async <C extends CName, Col extends ViewModelsMap[C]>(
    params: PreparationPack,
    cname: C,
    vs: Col[]
) => {
    const { config, filters, sorting } = params
    const { radarId } = config
    const searchAreas = (await getCachedData(radarId).searchAreas) || {}
    const filteredSearchAreaId = values(searchAreas).find(sa => sa.name === filters.searchArea)?.areaId
    const decorators = (await getCachedData(radarId).decorators) || {}
    const users = (await getCachedData(radarId).users) || {}
    const psvs = (await getCachedData(radarId).pipelines) || {}

    const csp = sorting[cname] as SortingParams<C>[] | undefined
    const sortingParams = getSortingParams({
        cname,
        config,
        sorting: csp,
        searchAreaId: filteredSearchAreaId
    })

    const lastValuesStr = keys(specialValues)
    const lastValuesNr = values(specialValues)
    const sortingPreds = sortingParams.map(sp => ({
        comparator: compareSingleCollectionField(lastValuesStr, lastValuesNr, sp.sortingAsc),
        prepareValue: prepareValueToSort(cname, sp.sortingKey, filteredSearchAreaId, decorators, users, psvs)
    }))
    return vs.sort((l, r) =>
        sortingPreds.reduce((acc, preds) => {
            if (acc !== 0) return acc
            const [left, right] = [l, r].map(preds.prepareValue)
            return preds.comparator(left, right)
        }, 0)
    )
}

export const compareSingleCollectionField = (lastStrValues: string[], lastNrValues: number[], asc: boolean) => (
    left: any,
    right: any
): number => {
    let result: number = 0

    if (left === undefined || left === null) return 1
    if (right === undefined || right === null) return -1

    if (isString(left) && isString(right)) {
        if (!left || lastStrValues.includes(left)) return 1
        if (!right || lastStrValues.includes(right)) return -1
        result = primitiveCompareAsc(left.trim().toLocaleLowerCase(), right.trim().toLocaleLowerCase())
    } else {
        if (lastNrValues.includes(left)) {
            return 1
        }
        if (lastNrValues.includes(right)) {
            return -1
        }
        result = primitiveCompareAsc(left, right)
    }
    return asc ? result : -result
}
