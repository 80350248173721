import styled from "styled-components"
import { Flex } from "../../styles/common"

export const _RadarBreadcrumbsContainer = styled(Flex)`
    font-size: 20px;
    line-height: 19px;
    color: #859ec5;

    & > *:last-child {
        color: ${({ theme }) => theme.colors.primary};
    }

    & > *:not(:last-child) {
        &:after {
            content: ">";
            font-size: 15px;
            display: inline-block;
            padding: 0 4px;
            vertical-align: middle;
        }
    }
`
