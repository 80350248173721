import * as React from "react"
import { DropdownProps, Dropdown, RadioDropdown } from "../../components/Dropdown"
import { _noop } from "../../../functions/src/utils"
import { toOption, toColorOption } from "../../../functions/src/utils/types"
import { _HorizontalSpace, _FlexRow } from "../../styles/common"

export const DemoDropdownView = () => {
    // const selectedOption = toOption("medium", _noop)
    // const props: DropdownProps<any> = {
    //     onSelect: _noop,
    //     options: [toOption("low", _noop), selectedOption, toOption("high", _noop)],
    //     title: "title",
    //     selected: [selectedOption]
    // }

    const selectedChipOption = toColorOption("medium", _noop, "lightgreen")
    const chipProps: DropdownProps<any> = {
        onSelect: _noop,
        // // options: [toColorOption("low", _noop, "yellow"), selectedChipOption, toColorOption("high", _noop, "red")],
        options: [toOption("low", _noop), selectedChipOption, toOption("high", _noop)],
        title: "title",
        selected: [selectedChipOption]
    }
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <h1>Dropdowns</h1>
            {/* <RadioDropdown {...props} /> */}
            <_HorizontalSpace base="24px" />
            <_FlexRow>
                <Dropdown {...chipProps} optionsYLocation="top" full />
                <RadioDropdown {...chipProps} full />
            </_FlexRow>
        </div>
    )
}
