import styled from "styled-components"
import { Link } from "react-router-dom"

export const _Wrapper = styled.div`
    position: relative;
    z-index: 6;
`
export const _ResultsContainer = styled.div`
    position: absolute;
    top: 40px;
    right: 0;

    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(44, 64, 90, 0.1);
    border-radius: 4px;

    width: 600px;
    max-width: 85vw;
    max-height: 75vh;
    overflow: auto;
`
export const _SearchResultWrapper = styled.div`
    display: flex;
    padding: 8px 0;
    align-items: center;
    padding: 4px 10px;

    & img {
        flex: 0 0 auto;
    }

    &:hover {
        background: #fafbfc;
    }
`

export const _ResultLink = styled(Link)`
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 17px;
`

export const _MoreLink = styled.p`
    text-decoration: none;
    color: #0093ee;
    font-size: 14px;
    &:hover {
        cursor: pointer;
    }
`

export const _ResultGroupTitle = styled.span`
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    text-transform: uppercase;
`
