import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router"
import { flatten, values, pickObject } from "../../../functions/src/utils/map"
import { isEqual } from "../../utils"
import { AuthPayload, AuthResult, AuthConfig, AuthConfigBase, AuthRule, Middleware } from "./authMiddleware.types"
import { Action } from "../../utils/redux.types"

export const PermissionsChanged = "@@Auth/PermissionsChanged"

export type PermissionsAction<TParams, TReason, TName> = {
    type: TName
    payload: AuthPayload<TParams, TReason>
}
export type PermissionsActions<TParams, TReason> = PermissionsAction<TParams, TReason, typeof PermissionsChanged>

export const PermissionsAction = <TName extends typeof PermissionsChanged>(type: TName) => <TParams, TReason>(
    params: TParams,
    permissions: AuthResult<TReason>
): PermissionsAction<TParams, TReason, TName> => ({
    type,
    payload: { permissions, params }
})

export const PermissionsChangedAction = PermissionsAction(PermissionsChanged)

const isLocationChange = (action: Action): action is LocationChangeAction => action.type === LOCATION_CHANGE
const getLocation = <TParams, TData>(
    config: AuthConfig<TParams, TData>,
    state: TData,
    action: Action
): Pick<Location, "pathname" | "search"> =>
    pickObject(isLocationChange(action) ? action.payload.location : config.getCurrentLocation(state), [
        "pathname",
        "search"
    ])
// there is a bug when in location change action query is not included (when empty)
// that produces unnecessary auth permissions recalculation

export const getAuthMiddleware = <TParams, TData>(
    config: AuthConfig<TParams, TData>
): Middleware<SMap<unknown>, TData> => store => next => async action => {
    const prevAuthPayload = config.getPreviousAuthPayload(store.getState())
    next(action)

    if ([...config.skipActionTypes, PermissionsChanged].includes(action.type)) return

    const state = store.getState()
    const location = getLocation(config, state, action)
    const params = config.getLocationParams(location, state)
    const permissions = validate(config)(location, state, params)
    if (permissions.status === "Waiting") return next(PermissionsChangedAction(params, permissions))

    if (isEqual(prevAuthPayload.permissions, permissions) && isEqual(prevAuthPayload.params, params)) return
    if (permissions.status === "NotAllowed") {
        next(PermissionsChangedAction(params, permissions))
        config.mapReasonToActions(params, permissions.reason, state).forEach(next)
        return
    }

    return next(PermissionsChangedAction(params, permissions))
}

export const validate = <TData, TReason, TParams>({
    rulesMap,
    defaultPolicy
}: AuthConfigBase<TParams, TData, TReason>): AuthRule<TData, TParams, TReason> => (location, data, params) => {
    if (!rulesMap) return defaultPolicy
    const rmap = values(rulesMap)
        .filter(r => r.pattern === "*" || r.patternRegExp.test(location.pathname))
        .map(r => r.rules)
    const rs = flatten<AuthRule<TData, TParams, TReason>>(rmap)
    if (!rs || !rs.length) return defaultPolicy
    const result = rs.reduce(
        (acc, r) => (!acc || acc.status === "Allowed" ? r(location, data, params) : acc),
        (null as any) as AuthResult<TReason>
    )
    return result
}
