import { mapObject, filterObject } from "../../functions/src/utils/map"
import { isEmpty, isSpecialNumber } from "../../functions/src/utils/validators"
import { labelize } from "../../functions/src/utils"
import { specialValues } from "../../functions/src/models/converters"
import * as formless from "@react-formless/core"
import { Icon } from "../../functions/src/models/icons"

export const clean = <T>(v: T): T => {
    const type = typeof v
    switch (type) {
        case "string":
            return "" as any
        case "number":
            return 0 as any
        case "object":
            if (Array.isArray(v)) {
                if (v.length === 0) return v
                return [clean(v[0])] as any
            }
            return mapObject(v, (_, ov) => clean(ov))
    }
    return v
}

export const markAsRequired = (v: string) => `${v}*`

const getInputSchema = <T>(
    value: any,
    name: string,
    validators: Validator<T, string>[] = []
): InputSchema<T> | null => {
    const type = typeof value
    switch (type) {
        case "number":
            if (isSpecialNumber(specialValues, value)) return { type: "specialNumber", validators, name }
            return { type: "number", validators, name }
        case "string":
            return { type: "text", validators, name }

        case "object": {
            if (!Array.isArray(value) || value.length === 0) return null
            if (typeof value[0] === "object")
                return {
                    type: "collection",
                    name,
                    sectionTitle: name,

                    fields: getFormSchema<typeof value[0]>(value[0]),
                    mutate: { createValue: clean(value[0]), createLabel: `Add ${name}` }
                }
            return {
                sectionTitle: name,
                type: "list",
                name,
                field: getInputSchema(value[0], "") as SimpleInputSchema<any>,
                mutate: { createValue: clean(value[0]), createLabel: `Add ${name}` }
            }
        }

        default:
            return null
    }
}

export const getFormSchema = <T>(o: T, display?: Displayable<T>, validators?: ValidationMap<T>): FormSchema<T> => {
    if (isEmpty(o)) return {} as any
    const schema = mapObject(o, (k, v) =>
        getInputSchema(
            v,
            display ? display[k] : labelize(`${k}`),
            validators ? (validators[k] as Validator<any, string>[]) : undefined
        )
    )
    return filterObject(schema, (_, v) => !!v) as any
}

export const getFormlessInputSchema = <T>(
    value: any,
    name: string,
    validators: Validator<T, string>[] = []
): formless.InputSchema<T> | null => {
    const type = typeof value
    switch (type) {
        case "number":
            if (isSpecialNumber(specialValues, value)) return { type: "text", validators, name }
            return { type: "number", validators, name }
        case "string":
            return { type: "text", validators, name }

        case "object": {
            if (!Array.isArray(value) || value.length === 0) return null
            if (typeof value[0] === "object")
                return {
                    type: "collection",
                    name,
                    sectionTitle: name,

                    fields: getFormlessFormSchema<typeof value[0]>(value[0]),
                    mutate: { createValue: clean(value[0]), addNextLabel: `+Add ${name}` }
                }
            return {
                sectionTitle: name,
                type: "list",
                name,
                field: getFormlessInputSchema(value[0], "") as formless.SimpleInputSchema<any>,
                mutate: { createValue: clean(value[0]), addNextLabel: `+Add ${name}` }
            }
        }

        default:
            return null
    }
}

export const getFormlessFormSchema = <T>(
    o: T,
    display?: Displayable<T>,
    validators?: ValidationMap<T>,
    iconsMap?: OMap<keyof T, Icon>
): formless.FormSchema<T> => {
    if (isEmpty(o)) return {} as any
    const schema = mapObject(o, (k, v) => ({
        ...getFormlessInputSchema(
            v,
            display ? display[k] : labelize(`${k}`),
            validators ? (validators[k] as Validator<any, string>[]) : undefined
        ),
        icon: iconsMap?.[k]
    }))
    return filterObject(schema, (_, v) => !!v) as any
}
