import * as React from "react"
import { ModalTitle } from "../../components/Modal.styles"
import { _HorizontalSpace, _Center, _Spacer } from "../../styles/common"
import { Tabs, Tab } from "../../components/tabs/Tabs"
import { _h1, _BodyFont } from "../../styles/typography"
import { isRoot, isAdmin } from "../../models/LoginStatus"
import { Loader } from "../../components/common/Loader"
import { LoaderPayload } from "../../store/data/dataSelectors"
import { AuthenticationState } from "../../models/auth"
import { ModalRouteProps } from "../../containers/ModalRenderer"
import {
    _CardContainer,
    _CardHeader,
    CardCloseButton,
    _CardContent,
    CardModalTitle,
    CardStarButton,
    EditCardButton,
    ImportPrioritizationButton,
    _ExportCardButton
} from "../../components/newCards/Card.styles"
import { ButtonGroup, DeleteItem } from "../../styles/buttons"
import { useExport } from "../../utils/hooks/useExport"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { NotificationMsg, Popup } from "../../store/store"
import { Msg } from "../../models/notifications"
import { IconSvg } from "../../components/IconSvg"
import { collections } from "../../../functions/src/models/schemas"
import { asyncWithDefault } from "../../../functions/src/utils/types"
import { _Badge } from "../../components/common/Badge"
import { AllTabTypes, buildTab } from "./tabFactory"
import { UploadPrioritizationMeta } from "../../components/popups/UploadPrioritization"
import { asyncConnect } from "../../store/async/asyncConnect"
import { startupPrioritization } from "./StartupPrioritization"

export type CardStateBaseProps = {
    cname: CName
    demo: boolean
    commentsLength?: number
    contactedLeadsLength?: number
    attachmentsLength?: number
    config: LocationParams
    radarConfig: ByRadarConfig
    authentication: AuthenticationState
    objectId: string
    displayName: string
    radarId: string
    isStarred: boolean
    assignedUser: string
    actionsResults: SMap<CloudActionResult>
    searchAreas: string[]
    relationshipsEnabled: boolean
}
export type CardStateProps = State<"Ok", CardStateBaseProps> | State<"NotFound">

// These are implemented in Cards.tsx, in mapDispatch
export type CardActionProps = {
    onStarClick: F2<ObjectId, boolean>
    onEditItem: F2<CName, ObjectId>
    openPopup: F2<Type<Popup>, Value<Popup>>
    closePopup: F1<Type<Popup>>
    goToListing: F1<CName>
    queueNotification: F1<NotificationMsg>
    onDeleteItem: F2<ActionId, RemoveCollectionItemsPayload<CName>>
}

export type CardProps = CardStateProps & CardActionProps & ModalRouteProps

export const ItemNotFoundCard: React.FC<ModalRouteProps> = p => (
    <_CardContainer>
        <_CardHeader data-cy="card-view-header">
            <ModalTitle bold>{p.title}</ModalTitle>
            <_HorizontalSpace base="16px" />
            <CardCloseButton onClick={p.onClose}>
                <IconSvg name="close" width={15} height={15} />
            </CardCloseButton>
        </_CardHeader>
        <_CardContent>
            <_Center>
                <_h1>Item not found</_h1>
            </_Center>
        </_CardContent>
    </_CardContainer>
)

const radarHasLeads = (config: LocationParams, radarConfig: ByRadarConfig, cname: CName) =>
    config.withContactedLead && radarConfig.contactedLeads?.includes(cname)

export const Card: React.FC<CardProps> = p => {
    if (p.type === "NotFound") return <ItemNotFoundCard {...p} />

    const tabs: Tab<AllTabTypes>[] = [buildTab("details", p)]

    if (p.cname === "sectors") {
        tabs.push(buildTab("relativePositioning", p))

        if (p.config.collections.includes("startups")) tabs.push(buildTab("startups", p))

        if (p.config.collections.includes("companies"))
            // TODO Add lengths to companies & startups
            tabs.push(buildTab("companies", p))

        tabs.push(buildTab("prioritization", p))
    }
    // XXX: Don't hastily commit this part
    // Tweak these in development to see the tabs regardless of current user priviledges.
    const retail = !p.demo
    const privileged = isRoot(p.authentication)
    const commentsVisible = retail && !privileged
    const attachmentsVisible = retail && !privileged
    const leadsVisible = retail && !privileged && radarHasLeads(p.config, p.radarConfig, p.cname)

    if (retail && p.relationshipsEnabled) tabs.push(buildTab("relations", p))
    // PENDING
    // if (retail && p.cname == "startups")
    //     tabs.push(buildTab("partnershipProfile", p))
    if (commentsVisible) tabs.push(buildTab("comments", p))
    if (attachmentsVisible) tabs.push(buildTab("attachments", p))
    if (leadsVisible) tabs.push(buildTab("contactedLeads", p))

    const [, onDelete] = useCloudAction(p.onDeleteItem, p.actionsResults, ({ result }) => {
        p.queueNotification(Msg("delete", { name: p.displayName }, result))
        p.goToListing(p.cname)
    })
    const onDeletePrompt = () => {
        const namesById = { [p.objectId]: p.displayName }
        p.openPopup("deleteConfirmation", {
            objectName: p.displayName,
            objectType: collections[p.cname].singleName.toLocaleLowerCase(),
            onDelete: () => onDelete({ namesById, searchAreas: p.searchAreas, radarId: p.radarId, cname: p.cname })
        })
    }

    const { doExport } = useExport(p.radarId)
    const canUseTools = !p.demo && isAdmin(p.authentication)
    const [displayEditTools, setDisplayEditTools] = React.useState(true)
    const [displayPrioritizationTools, setDisplayPriTools] = React.useState(false)
    const onTabChanged = (tabId: string) => {
        if (tabId === "prioritization") {
            setDisplayPriTools(true)
            setDisplayEditTools(false)
        } else {
            setDisplayPriTools(false)
            setDisplayEditTools(true)
        }
    }
    const onClickImport = () => {
        p.openPopup("prioritizationUpload", {
            sectorId: p.objectId,
            onDone: () => p.closePopup("prioritizationUpload")
        } as UploadPrioritizationMeta)
    }

    return (
        <_CardContainer data-cy="card-container">
            <_CardHeader>
                <CardModalTitle bold>{`${p.title} / ${p.displayName}`}</CardModalTitle>
                <_HorizontalSpace base="36px" />
                {!p.demo && !isRoot(p.authentication) && p.config.withAssignments ? (
                    <>
                        <_BodyFont s15 color="theme10" data-cy="prm-in-card-container-heading">
                            Primary relationship manager:&nbsp;
                        </_BodyFont>
                        <_BodyFont s15 bold color="theme10" uppercase>
                            {p.assignedUser}
                        </_BodyFont>
                    </>
                ) : null}
                <_Spacer />
                {p.config.withStars && !p.demo && (
                    <CardStarButton
                        data-cy={`star-button-${p.isStarred}`}
                        onClick={e => {
                            p.onStarClick(p.objectId, !p.isStarred)
                            e.currentTarget.blur()
                        }}
                        starred={p.isStarred}>
                        <IconSvg name="star" width={27} height={27} />
                    </CardStarButton>
                )}
                <_HorizontalSpace base="16px" />
                <CardCloseButton onClick={p.onClose}>
                    <IconSvg name="close" width={15} height={15} />
                </CardCloseButton>
            </_CardHeader>
            <_CardContent>
                <Tabs data={tabs} scrollable legacy={false} onChange={onTabChanged}>
                    {canUseTools && displayEditTools ? (
                        <ExportEditTools
                            {...p}
                            onEditItem={() => p.onEditItem(p.cname, p.objectId)}
                            onExport={() => doExport("csv", { type: "ids", ids: { [p.cname]: [p.objectId] } })}
                            onDeletePrompt={onDeletePrompt}
                        />
                    ) : canUseTools && displayPrioritizationTools ? (
                        <PrioritizationTools onImport={onClickImport} />
                    ) : null}
                </Tabs>
            </_CardContent>
            {p.cname === "startups" && <PrioritizationTable objectId={p.objectId} />}
        </_CardContainer>
    )
}

type PTProps = {
    objectId: string
}

const PrioritizationTable = asyncConnect<PTProps>()({
    data: ["viewModels"]
})(p => {
    const collection = p.viewModels["startups"]
    const item = asyncWithDefault<any>(collection, {})[p.objectId]

    return item !== undefined ? startupPrioritization(p.viewModels, item) : null
})

type ExportEditToolsProps = {
    onEditItem: F0
    onDeletePrompt: F0
    onExport: F0
}

const ExportEditTools = ({ onEditItem, onDeletePrompt, onExport }: ExportEditToolsProps) => {
    return (
        <>
            <_Spacer />
            <_HorizontalSpace base="20px" />
            <_ExportCardButton onClick={onExport}>
                <IconSvg name="export" width={16} height={16} />
            </_ExportCardButton>
            <_HorizontalSpace base="25px" />
            <ButtonGroup>
                <DeleteItem onClick={onDeletePrompt} />
                <_HorizontalSpace base="8px" />
                <EditCardButton onClick={onEditItem}>
                    <IconSvg name="edit" width={16} height={16} />
                    <_HorizontalSpace base="8px" />
                    Edit
                </EditCardButton>
            </ButtonGroup>
        </>
    )
}

type PrioritizationToolsProps = {
    onImport: F0
}

const PrioritizationTools = ({ onImport }: PrioritizationToolsProps) => {
    return (
        <>
            <_Spacer />
            <ButtonGroup>
                <ImportPrioritizationButton onClick={onImport}>
                    <_HorizontalSpace base="8px" />
                    <IconSvg name="export" width={16} height={16} rotate={180} />
                    <_HorizontalSpace base="8px" />
                    Import CSV
                    <_HorizontalSpace base="8px" />
                </ImportPrioritizationButton>
            </ButtonGroup>
        </>
    )
}

export const CardLoader = <T extends LoaderPayload>(p: Loadable<unknown, T>) =>
    p.loading ? (
        <_CardContainer>
            <_CardContent>
                <Loader
                    fill
                    show={p.loading}
                    loadingText={p.loading && p.stateDescription}
                    loadingSubtext={p.loading && p.stepDescription}
                />
            </_CardContent>
        </_CardContainer>
    ) : null
