import {
    validString,
    validNumber,
    validate,
    validArrayStringDef,
    validStringDef,
    validNumberDef
} from "../utils/validators"
import { mapToDisplay } from "./common"
import {
    DEFAULT_PLACEHOLDER,
    sharedRequiredRadarFieldsValidationMap,
    sharedOptionalRadarFieldsValidationMap,
    sharedVMMetaValidationMap
} from "./shared"
import { RFields } from "./RFields"
import { PatentHolderVM } from "./ViewModels"

export const patentHolderRequiredFieldsValidationMap: ValidationMap<Required<PatentHolderFieldsRequired>> = {
    ...sharedRequiredRadarFieldsValidationMap,
    num_citations: validNumber,
    num_patents: validNumber,
    patent_holder: validString
}
export const patentHolderOptionalFieldsValidationMap: ValidationMap<Required<PatentHolderFieldsOptional>> = {
    ...sharedOptionalRadarFieldsValidationMap,
    patent_links: validArrayStringDef,
    industry: validStringDef,
    num_patent_families: validNumberDef,
    num_patent_family_citations: validNumberDef,
    patent_holder_url: validStringDef
}

export const patentHolderFieldsValidationMap: ValidationMap<Required<PatentHolderFields>> = {
    ...patentHolderRequiredFieldsValidationMap,
    ...patentHolderOptionalFieldsValidationMap
}

export const patentHolderValidationMap: ValidationMap<Required<PatentHolder>> = {
    ...patentHolderFieldsValidationMap,
    createdTs: validNumber,
    patentHolderId: validString
}

export const patentHolderRelationsValidationMap: ValidationMap<Required<PatentHolderRelations>> = {
    patents: validArrayStringDef
}

export const validatePatentHolderRelations = validate<PatentHolderRelations>(patentHolderRelationsValidationMap)
export const validatePatentHolderFields = validate<PatentHolderFields>(patentHolderFieldsValidationMap)
export const validatePatentHolder = validate<PatentHolder>(patentHolderValidationMap)
export const validatePatentHolderVM = validate<PatentHolderVM>({
    ...patentHolderValidationMap,
    ...sharedVMMetaValidationMap
})

export const displayPatentHolder = (p: PatentHolderVM, ph: string = DEFAULT_PLACEHOLDER): Displayable<PatentHolderVM> =>
    mapToDisplay(p, ph)

export const patentHoldersFields: CFields<"patent_holders"> = RFields<
    PatentHolderFieldsRequired,
    PatentHolderFieldsOptional
>(patentHolderRequiredFieldsValidationMap, patentHolderOptionalFieldsValidationMap)

export const patentHolderRelations: RFields<"patent_holders"> = RFields<unknown, TechExpertRelations>(
    {},
    patentHolderRelationsValidationMap
)

export const PatentHolderFieldsDisplay: Displayable<PatentHolderVM> = {
    patent_holder: "Patent Holder",
    description: "Description",
    num_citations: "No. of citations",
    num_patents: "No. of patents",
    patent_links: "Patent Links",
    source: "Source",
    blurOnListing: "",
    createdTs: "Created At",
    commentsCount: "No. of comments",
    contactedLeadsCount: "No. of contacted leads",
    patentHolderId: "",
    industry: "Industry",
    num_patent_families: "No. of patent families",
    num_patent_family_citations: "No. of patent family citations",
    patent_holder_url: "Patent holder URL",
    filterBounds: ""
}
