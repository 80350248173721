import {
    validate,
    validNumber,
    validString,
    validNumberDef,
    validArrayStringDef,
    validStringDef
} from "../utils/validators"
import { RFields } from "./RFields"
import { mapToDisplay, parseString } from "./common"
import { formatDecimal, formatInteger, formatMillions } from "./converters"
import {
    DEFAULT_PLACEHOLDER,
    sharedRequiredRadarFieldsValidationMap,
    sharedOptionalRadarFieldsValidationMap,
    sharedVMMetaValidationMap
} from "./shared"
import { ResearchHubVM, DisplayModelsMap } from "./ViewModels"

const researchHubRequiredFieldsValidationMap: ValidationMap<Required<ResearchHubFieldsRequired>> = {
    research_hub_name: validString,
    research_hub_url: validStringDef, // TODO legacy
    ...sharedRequiredRadarFieldsValidationMap
}

const researchHubOptionalFieldsValidationMap: ValidationMap<Required<ResearchHubFieldsOptional>> = {
    ...sharedOptionalRadarFieldsValidationMap,
    active_funding: validNumberDef,
    citations_per_publication: validNumberDef,
    grant_funding: validNumberDef,
    location: validStringDef,
    num_citations: validNumberDef,
    num_patents: validNumberDef,
    num_publications: validNumberDef,
    university: validStringDef,
    num_clinical_trials: validNumberDef,
    num_experts: validNumberDef,
    num_patent_families: validNumberDef
}

export const researchHubFieldsValidation: ValidationMap<Required<ResearchHubFields>> = {
    ...researchHubRequiredFieldsValidationMap,
    ...researchHubOptionalFieldsValidationMap
}

export const researchHubFields: CFields<"research_hubs"> = RFields<
    ResearchHubFieldsRequired,
    ResearchHubFieldsOptional
>(researchHubRequiredFieldsValidationMap, researchHubOptionalFieldsValidationMap)

export const researchHubValidationMap: ValidationMap<Required<ResearchHub>> = {
    ...researchHubFieldsValidation,
    researchHubId: validString,
    createdTs: validNumber
}

export const researchHubRelationsValidationMap: ValidationMap<Required<ResearchHubRelations>> = {
    startups: validArrayStringDef,
    companies: validArrayStringDef,
    influencers: validArrayStringDef,
    sectors: validArrayStringDef,
    tech_experts: validArrayStringDef,
    patents: validArrayStringDef,
    research_papers: validArrayStringDef,
    tech_transfers: validArrayStringDef,
    investors: validArrayStringDef
}

export const validateResearchHubFields = validate<ResearchHubFields>(researchHubFieldsValidation)
export const validateResearchHubRelations = validate<ResearchHubRelations>(researchHubRelationsValidationMap)
export const validateResearchHub = validate<ResearchHub>(researchHubValidationMap)
export const validateResearchHubVM = validate<ResearchHubVM>({
    ...researchHubValidationMap,
    ...sharedVMMetaValidationMap
})

export const researchHubRelations: RFields<"research_hubs"> = RFields<unknown, ResearchHubRelations>(
    {},
    researchHubRelationsValidationMap
)

export const transformResearchHub = (c: ResearchHubVM): DisplayModelsMap["research_hubs"] => ({
    ...c,
    num_publications: formatInteger(c.num_publications),
    num_citations: formatInteger(c.num_citations),
    num_patents: formatInteger(c.num_patents),
    active_funding: formatMillions(c.active_funding, false),
    grant_funding: formatMillions(c.grant_funding, false),
    citations_per_publication: formatDecimal(c.citations_per_publication),
    description: parseString(c.description)
})

export const displayResearchHub = (r: ResearchHubVM, ph: string = DEFAULT_PLACEHOLDER): Displayable<ResearchHubVM> =>
    mapToDisplay(r, ph)

export const ResearchHubDisplayFields: Displayable<ResearchHubVM> = {
    research_hub_name: "Name",
    active_funding: "Active funding ($m)",
    citations_per_publication: "Citations per publication",
    description: "Description",
    grant_funding: "Grant funding ($m)",
    location: "Location",
    num_citations: "Number of citations",
    num_patents: "Number of patents",
    num_publications: "Number of publications",
    university: "University",
    source: "Source",
    research_hub_url: "Research Hub URL",
    blurOnListing: "",
    createdTs: "Created At",
    commentsCount: "No. of comments",
    contactedLeadsCount: "Number of contacted leads",
    num_clinical_trials: "Number of clinical trials",
    num_experts: "Number of experts",
    num_patent_families: "Number of patent families",
    researchHubId: "ID",
    filterBounds: ""
}
