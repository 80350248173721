/* eslint-disable max-lines */
import * as React from "react"
import { connect, ConnectedComponent } from "react-redux"
import { _VerticalSpace, _FlexRow, _Right, _HorizontalSpace, _FlexColumn } from "../../styles/common"
import { Button } from "../../styles/buttons"
import { Table } from "../../components/table/TableView"
import { SubpageLayout } from "../../components/Page"
import { mkRow, HeaderTitle } from "../../components/table/TableViewController"
import { Modal } from "../../components/Modal"
import { values, extend } from "../../../functions/src/utils/map"
import { getNowTS } from "../../utils"
import {
    toOption,
    Loaded,
    exhaustiveStringTuple,
    toStringOption,
    isProcessing
} from "../../../functions/src/utils/types"
import { RadarUserEdit } from "./RadarUserEdit"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { Msg } from "../../models/notifications"
import { actions as cloudActions } from "../../store/cloud/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { mkTableTitleSchema, mkTableComputedSchema } from "../../components/table/TableViewSchemas"
import { getDataByDeps } from "../../store/data/dataSelectors"
import { MapDispatch } from "../../utils/redux.types"
import { NotificationMsg } from "../../store/store"
import { RowSchema } from "../../components/table/TableView.types"
import { isEmpty } from "../../../functions/src/utils/validators"
import { FilterProps, Filter, FilterActions, CheckboxFilterProps } from "../../components/filters/Filter"
import { Join, LoadableView } from "../../utils/reactUtils"
import { assertNever, toggleArray, _noop } from "../../../functions/src/utils"
import { RadarViewFetchMap } from "../../dependencies"
import { _DataContainer } from "./AdminDashboard.styles"
import { ViewLoader } from "../../containers/ViewRenderer"
import { Loader } from "../../components/common/Loader"

export type StateProps = {
    users: SMap<RadarUser>
    radarId: string
    radarName: string
    portalOrganizationId?: string
    restriction: RadarRestriction
    actionsResults: SMap<CloudActionResult>
}
export type ActionProps = {
    onUpdate: F2<string, RadarUser>
    onCreate: F2<string, RadarUserRequest>
    onRevokeInvitation: F3<string, string, string>
    onResendInvitation: F3<string, string, string>
    onRequestAccept: F3<string, string, string>
    onRequestDecline: F3<string, string, string>
    queueNotification: F1<NotificationMsg>
    onPortalInvite: F3<string, string, string>
}

export type UserStatuses = "Active" | "Deactivated" | "Declined" | "Invited" | "Requested"

type Props = StateProps & ActionProps

// FILTERS

type UserFilters = { type: string[] }
const emptyFilters: UserFilters = { type: [] }

const byUserType = ({ type }: UserFilters) => (user: RadarUser) => {
    const userTypeMatches: boolean = type.includes(user.type)
    let userActiveStateMatches: boolean = false
    //if type is Activated or Deactivated, check isDeactivated
    if (type.includes("Active")) {
        userActiveStateMatches = !user.isDeactivated
    }
    if (type.includes("Deactivated")) {
        userActiveStateMatches = userActiveStateMatches || user.isDeactivated
    }
    return userTypeMatches || userActiveStateMatches
}
const filterUsers = (users: RadarUser[], filters: UserFilters) => {
    const filterConditions: F1<RadarUser, boolean>[] = []
    if (!isEmpty(filters.type)) filterConditions.push(byUserType(filters))

    return filterConditions.reduce((usrs, f) => usrs.filter(f), users)
}

type UserFilterType = ValueState<keyof UserFilters, FilterProps>
type UserFiltersProps = { onFilterChange: F1<UserFiltersAction>; filterOptions: UserFilterType[]; filters: UserFilters }
type UserFilterChange<T extends keyof UserFilters> = { type: T; value: UserFilters[T] }
type UserFiltersAction = UserFilterChange<"type">

const onClearNoop = (_key: any) => () => {
    /* Explicitly do nothing */
}

const UserFiltersBar: React.FC<UserFiltersProps> = p => {
    const onChange = (filterType: Type<UserFilterType>): FilterActions["onChange"] => onChangePayload => {
        if (onChangePayload.type === "checkbox")
            p.onFilterChange({ type: filterType, value: toggleArray(p.filters.type, onChangePayload.v) })
    }
    return (
        <_FlexRow alignCenter>
            <Join items={p.filterOptions} renderJoining={() => <_HorizontalSpace base="16px" />}>
                {({ type, value }) => <Filter key={type} {...value} onChange={onChange(type)} onClear={onClearNoop} />}
            </Join>
        </_FlexRow>
    )
}

const filterOptions = (filters: UserFilters): UserFilterType[] => {
    const userTypes = exhaustiveStringTuple<UserStatuses>()("Active", "Deactivated", "Declined", "Invited", "Requested")
    return [
        {
            type: "type",
            value: CheckboxFilterProps({
                options: userTypes.map(toStringOption),
                buttonTextSelected: "Selected user type",
                buttonText: "All Users",
                title: "Filter by user type",
                selected: filters.type.length > 0 ? filters.type.map(toStringOption) : userTypes.map(toStringOption)
            })
        }
    ]
}

const reduceFilters: React.Reducer<UserFilters, UserFiltersAction> = (state, action) => {
    const ext = extend(state)
    switch (action.type) {
        case "type":
            return ext({ type: action.value })
    }
    assertNever(action.type)
}

export const userSchema: RowSchema<RadarUser> = [
    mkTableTitleSchema("Name", "displayName", { min: 300 }),
    //mkTableTitleSchema("Status", "type", { min: 100 }),
    mkTableComputedSchema(
        "Status",
        "type",
        "title",
        x => (x.type === "Approved" ? (x.isDeactivated ? "Deactivated" : "Active") : x.type),
        { min: 100 }
    ),
    mkTableTitleSchema("Mail", "email", { min: 400 }),
    mkTableComputedSchema("Type", "isAdmin", "title", x => (x.isAdmin ? "Admin" : "User"), { min: 70 })
]
const subscriberSchema: RowSchema<RadarUser> = [mkTableTitleSchema("Mail", "email")]
const getTableSchema = (r: RadarRestriction) => (r === "demo" ? subscriberSchema : userSchema)

const userHeaderRow = userSchema.map(v => HeaderTitle(v.key, v.label, v.size, v.key))
const subscriberHeaderRow = subscriberSchema.map(v => HeaderTitle(v.key, v.label, v.size, v.key))
const getHeaderRow = (r: RadarRestriction) => (r === "demo" ? subscriberHeaderRow : userHeaderRow)

const getUserOrder = (u: RadarUser) => {
    const o: TMap<ClaimType, number> = {
        Declined: 3,
        Approved: 2,
        Invited: 1,
        Requested: 0
    }
    return o[u.type]
}

const renderUserEdit = (user: RadarUser, onSubmit: F1<RadarUser>, state: AsyncAction<CloudActionResult>) => (
    <RadarUserEdit
        type="update"
        actionName="Save"
        user={user}
        state={state}
        sendAction={payload =>
            onSubmit({
                radarId: user.radarId,
                userId: user.userId,
                type: user.type,
                timestamp: user.timestamp,
                ...payload
            })
        }
    />
)

const renderUserInvite = (radarId: string, onSubmit: F1<RadarUserRequest>, state: AsyncAction<CloudActionResult>) =>
    isProcessing(state) ? (
        <Loader loadingText="Sending request" />
    ) : (
        <RadarUserEdit
            type="create"
            actionName="Invite"
            user={{ displayName: "", email: "", isAdmin: false, isDeactivated: false }}
            state={state}
            sendAction={payload => onSubmit({ ...payload, radarId, timestamp: getNowTS() })}
        />
    )

export const Users: React.FC<Props> = ({ queueNotification, ...p }) => {
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null)
    const [isAddUserOpen, setIsAddUserOpen] = React.useState(false)
    const [editedUserId, setEditedUserId] = React.useState<React.ReactText | null>(null)
    const [filters, setFilters] = React.useReducer(reduceFilters, emptyFilters)
    const [filteredUsers, setFilteredUsers] = React.useState<RadarUser[]>(values(p.users))
    const sortedUsers = React.useMemo(
        () =>
            filteredUsers
                .sort((u1, u2) => getUserOrder(u1) - getUserOrder(u2))
                .map(u => mkRow(u.userId || u.email, getTableSchema(p.restriction), u)),
        [filteredUsers, p.restriction]
    )

    React.useEffect(() => {
        setFilteredUsers(filterUsers(values(p.users), filters))
    }, [filters, p.users])

    const [createState, onCreate, resetCreateCA] = useCloudAction(p.onCreate, p.actionsResults, ({ result }) => {
        queueNotification(Msg("create", { name: "user" }, result))
        setIsAddUserOpen(false)
        resetCreateCA()
    })

    const [updateState, onUpdate, resetUpdateCA] = useCloudAction(p.onUpdate, p.actionsResults, ({ result }) => {
        setEditedUserId(null)
        queueNotification(Msg("update", { name: "user" }, result))
        resetUpdateCA()
    })

    const [, onRevoke] = useCloudAction(p.onRevokeInvitation, p.actionsResults, ({ result }) =>
        queueNotification(Msg("invitationRevoke", { name: "user" }, result))
    )

    const [, onAccept] = useCloudAction(p.onRequestAccept, p.actionsResults, ({ result }) =>
        queueNotification(Msg("userAccept", { name: "user" }, result))
    )

    const [, onResend] = useCloudAction(p.onResendInvitation, p.actionsResults, ({ result }) =>
        queueNotification(Msg("invitationResend", { name: "user" }, result))
    )

    const [, onDecline] = useCloudAction(p.onRequestDecline, p.actionsResults, ({ result }) =>
        queueNotification(Msg("userDecline", { name: "user" }, result))
    )

    const [, onPortalInvite] = useCloudAction(p.onPortalInvite, p.actionsResults, ({ result }) => {
        queueNotification(Msg("portalInvite", {}, result))
    })

    const getInteractiveOptions = (userId: string) => {
        const user = p.users[userId]
        if (!user) return []

        switch (user.type) {
            case "Requested":
                return [
                    toOption("Accept request", (uid: string) => onAccept(p.radarId, uid)),
                    toOption("Decline request", (uid: string) => onDecline(p.radarId, uid))
                ]
            case "Invited":
                return [
                    toOption("Resend invitation", (email: string) => onResend(p.radarId, email)),
                    toOption("Revoke invitation", (email: string) => onRevoke(p.radarId, email))
                ]
            case "Declined":
                return []
            case "Approved":
                const options = [toOption("Edit", setEditedUserId)]
                if (!isEmpty(p.portalOrganizationId))
                    options.push(toOption("Invite to Portal", (uid: string) => onPortalInvite(uid, p.radarId)))
                return options
        }
        assertNever(user.type)
    }

    const tableProps = { anchor, virtualized: true, headerRow: getHeaderRow(p.restriction), onRowClick: _noop }

    return (
        <SubpageLayout>
            <Modal isOpen={isAddUserOpen} onClose={() => setIsAddUserOpen(false)} title="Invite user">
                {() => renderUserInvite(p.radarId, onCreate, createState)}
            </Modal>
            <Modal isOpen={Boolean(editedUserId)} onClose={() => setEditedUserId(null)} title="Update user">
                {() => renderUserEdit(p.users[editedUserId!], onUpdate, updateState)}
            </Modal>

            <_FlexColumn grow={1}>
                {p.restriction !== "demo" ? (
                    <_Right>
                        <_HorizontalSpace base="16px" />
                        <UserFiltersBar
                            onFilterChange={setFilters}
                            filters={filters}
                            filterOptions={filterOptions(filters)}
                        />
                        <_HorizontalSpace base="16px" />
                        <Button onClick={() => setIsAddUserOpen(true)}>Invite User</Button>
                        <_HorizontalSpace base="16px" />
                    </_Right>
                ) : null}
                <_VerticalSpace base="16px" />
                <_DataContainer ref={setAnchor}>
                    <Table {...tableProps} interactiveOptions={getInteractiveOptions} rows={sortedUsers} />
                </_DataContainer>
                <_VerticalSpace base="42px" />
            </_FlexColumn>
        </SubpageLayout>
    )
}

const mapState = getDataByDeps<StateProps>()(RadarViewFetchMap({}, {}), (deps, { cloud: { actionsResults } }) => {
    const { radar, users } = deps

    return Loaded({
        users,
        radarId: radar.radarId,
        radarName: radar.name,
        portalOrganizationId: radar.portalOrganizationId,
        actionsResults,
        restriction: "client"
    })
})

// Identical to the one in RadarSubscribers
const mapDispatch: MapDispatch<ActionProps> = d => ({
    onRequestAccept: (actionId, radarId, userId) => d(cloudActions.acceptRequest(actionId, radarId, userId)),
    onRequestDecline: (actionId, radarId, userId) => d(cloudActions.declineRequest(actionId, radarId, userId)),

    onCreate: (actionId, userPayload) => d(cloudActions.addUser(actionId, userPayload)),
    onUpdate: (actionId, userPayload) => d(cloudActions.updateUser(actionId, userPayload)),

    onResendInvitation: (actionId, radarId, email) => d(cloudActions.resendInivitation(actionId, radarId, email)),
    onRevokeInvitation: (actionId, radarId, email) => d(cloudActions.revokeInvitation(actionId, radarId, email)),

    onPortalInvite: (actionId, userId, radarId) => d(cloudActions.portalInvite(actionId, userId, radarId)),

    queueNotification: msg => d(uiActions.queueNotification(msg))
})

const LoadableUsers = LoadableView(ViewLoader, Users)

export const UsersView: ConnectedComponent<typeof LoadableUsers, unknown> = connect(
    mapState,
    mapDispatch
)(LoadableUsers)
