export const isContactedLead = (c: ObjectComment) => c.meta?.type === "contactedLead"
export const isNotContactedLead = (c: ObjectComment) => c.meta?.type !== "contactedLead"

export const filterContactedLeads = (cs: ObjectComment[]) => cs.filter(isContactedLead)
export const findContactedLead = (cs: ObjectComment[]) => cs.find(isContactedLead)
export const omitContactedLeads = (cs: ObjectComment[]) => cs.filter(isNotContactedLead)

export const contactedLeadFields = (): TMap<keyof ContactedLeadDecorator, string> => {
    return {
        contactName: "Contact name",
        description: "Description",
        projects: "P&G Project(s)",
        technology: "R&D Technology"
    }
}
