import { validate, validString, validArrayString, validBoolean, Err, validArrayDef } from "../../utils/validators"
import { pickIntersect, omitObject } from "../../utils/map"
import { commentValidationMap } from "../../models/User"
import { RadarCloudActions, RadarActionPayload } from "../actionCreators"
import { CloudActionsValidationMap } from "../validators"
import { validCName } from "../../utils/domainValidators"

const tagPayloadValidationMap = {
    name: validString,
    tagId: validString
}

const segmentTagPayloadValidationMap = {
    name: validString,
    areaId: validString,
    segmentId: validString
}

export const validateTagCreateMutation = validate<Pick<Tag, "name">>(omitObject(tagPayloadValidationMap, ["tagId"]))

export const validateSegmentTagCreateMutation = validate<SegmentTagCreatePayload>(
    omitObject(segmentTagPayloadValidationMap, ["segmentId"])
)
export const validateTagUpdateMutation = validate<Tag>(tagPayloadValidationMap)
export const validateSegmentTagUpdateMutation = validate<SegmentTag>(segmentTagPayloadValidationMap)

export const validateMutateTag = (p: TagMutationPayload) => {
    if (!p.type) return Err("No payload type")
    switch (p.type) {
        case "create":
            return validate<TagMutationPayload>({
                radarId: validString,
                type: validString,
                value: [validateTagCreateMutation]
            })(p)
        case "update":
            return validate<TagMutationPayload>({
                radarId: validString,
                type: validString,
                value: [validateTagUpdateMutation]
            })(p)
        case "delete":
            return validate<TagMutationPayload>({ radarId: validString, type: validString, value: validString })(p)
        default:
            return Err("Not recognized mutation type")
    }
}

export const validateMutateSegmentTag = (p: SegmentTagMutationPayload) => {
    if (!p.type) return Err("No payload type")
    switch (p.type) {
        case "create":
            return validate<SegmentTagMutationPayload>({
                radarId: validString,
                type: validString,
                value: [validateSegmentTagCreateMutation]
            })(p)
        case "update":
            return validate<SegmentTagMutationPayload>({
                radarId: validString,
                type: validString,
                value: [validateSegmentTagUpdateMutation]
            })(p)
        case "delete":
            return validate<SegmentTagMutationPayload>({ radarId: validString, type: validString, value: validString })(
                p
            )
        default:
            return Err("Not recognized mutation type")
    }
}

const validateAssignSegmentTag = validate<SegmentTagAssignmentPayload>({
    segmentId: validString,
    assign: validBoolean,
    objectId: validString,
    radarId: validString
})

export const validateCreateComment = validate<RadarActionPayload<"createComment">>({
    ...pickIntersect<CommentCreatePayload>()(commentValidationMap, ["objectId", "text", "meta"]),
    radarId: validString
})
export const validateEditComment = validate<RadarActionPayload<"editComment">>({
    ...pickIntersect<CommentEditPayload>()(commentValidationMap, ["objectId", "text", "commentId", "meta"]),
    radarId: validString
})
export const validateDeleteComment = validate<RadarActionPayload<"deleteComment">>({
    ...pickIntersect<CommentDeletePayload>()(commentValidationMap, ["objectId", "commentId"]),
    radarId: validString
})

export const validateAddRelation = validate<RadarActionPayload<"addRelation">>({
    relation: validArrayString,
    radarId: validString
})

export const validateRemoveRelation = validate<RadarActionPayload<"removeRelation">>({
    relation: validArrayString,
    radarId: validString
})

const validateAssignSearchArea = validate<SearchAreaAssignmentPayload>({
    areaId: validString,
    assign: validBoolean,
    objectId: validString,
    radarId: validString
})

const validateAssignTag = validate<TagAssignmentPayload>({
    tagId: validString,
    assign: validBoolean,
    objectId: validString,
    radarId: validString
})

const validateMutateByRadarConfig = validate<ByRadarConfigPayload>({
    contactedLeads: validArrayDef(validCName),
    radarId: validString
})

export const radarActionsPayloadValidators: CloudActionsValidationMap<RadarCloudActions> = {
    // "@mutateDatums": [], // TODO <-

    createComment: [validateCreateComment],
    editComment: [validateEditComment],
    deleteComment: [validateDeleteComment],
    addRelation: [validateAddRelation],
    removeRelation: [validateRemoveRelation],

    mutateSearchArea: [], // TODO <-
    assignSearchArea: [validateAssignSearchArea],

    mutateTag: [validateMutateTag],
    assignTag: [validateAssignTag],
    createAndAssignTag: [], // TODO <-

    mutateSegmentTag: [validateMutateSegmentTag],
    assignSegmentTag: [validateAssignSegmentTag],
    createAndAssignSegmentTag: [],
    createBatchSegmentTags: [],

    mutateDecorator: [], // TODO <-
    mutatePipelineStageValue: [], // TODO <-
    mutateByRadarConfig: [validateMutateByRadarConfig],
    uploadPrioritization: []
}
