import styled from "styled-components"

export const _SearchInputControlInput = styled.input`
    padding: 0px 13px;
    font-size: 14px;
    border: 1px solid #edeff2;
    width: 100%;
    background-color: #fff;
    color: #2c405a;
    line-height: 2;
    border-radius: 4px;
    outline: none;
    min-height: 36px;
`
