import { LocationChangeAction } from "connected-react-router"
import { PreloadedCollectionsVM, ImportData } from "../../../functions/src/models/importing.types"
import { createAction } from "../../../functions/src/utils/actions"
import { UnrecognizedFilePayload } from "../store"

export const actions = {
    selectFiles: (files: File[], availableCollections: CName[]) =>
        createAction("selectFiles", { files, availableCollections }),
    removeSelectedFile: (cname: CName, filename: string) => createAction("removeSelectedFile", { cname, filename }),
    startPreload: () => createAction("startPreload"),

    revalidateCollections: () => createAction("revalidateCollections"),
    updateItem: <C extends CName>(collection: C, index: number, value: CollectionItem<C>) =>
        createAction("updateItem", { collection, index, value }),
    addItem: <C extends CName>(collection: C, value: CollectionItem<C>) =>
        createAction("addItem", { collection, value }),
    removeItems: <C extends CName>(collection: C, indices: number[]) =>
        createAction("removeItems", { collection, indices }),
    removeTagFromItems: (tagName: string) => createAction("removeTagFromItems", tagName),
    removeSegmentTagFromItems: (segmentTagName: string) => createAction("removeSegmentTagFromItems", segmentTagName),

    startImport: () => createAction("startImport"),
    startSelectFiles: () => createAction("startSelectFiles"),
    _attachFile: (result: Result<CName, UnrecognizedFilePayload>, file: File) =>
        createAction("_attachFile", { result, file }),
    _updatePreloadedCollections: (pcs: PreloadedCollectionsVM) => createAction("_updatePreloadedCollections", pcs),
    _chunkImportFinished: (payload: ImportData) => createAction("_chunkImportFinished", payload),
    _chunkImportFailed: (err: string) => createAction("_chunkImportFailed", err)
}

export type Actions = ReturnType<typeof actions[keyof typeof actions]> | LocationChangeAction
