import * as React from "react"
import { Popup } from "../Popup"
import { PopupInner } from "../Popup.styles"
import { _h2 } from "../../styles/typography"
import { getMapDispatch } from "../../utils/redux"
import { actions } from "../../store/cloud/actions"
import { connect } from "react-redux"
import { _FlexRow, _HorizontalSpace, _VerticalSpace, _FlexColumn } from "../../styles/common"
import { SecondaryButton, ErrorButton } from "../../styles/buttons"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { getCurrentRadarId } from "../../models/LocationType"
import { Loader } from "../common/Loader"
import { MapState } from "../../utils/redux.types"

type Props = {
    onClose: F0
    meta: PipelineRemovalMeta
}

type StateProps = {
    radarId: string
    results: SMap<CloudActionResult>
}

export const PipelineRemoval: React.FC<Props & StateProps & ActionProps> = p => {
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [, onMutateDatums] = useCloudAction(p.mutatePipelineStage, p.results, p.onClose)
    return (
        <Popup onClose={p.onClose} logo>
            <PopupInner>
                {isSubmitting ? (
                    <Loader loadingText="Submitting" />
                ) : (
                    <_FlexColumn>
                        <_h2>
                            This pipeline stage is attached to {p.meta.count} object(s). If you remove it, stages of
                            those objects will be updated to 'No stage'.
                        </_h2>
                        <_VerticalSpace base="16px" />
                        <_FlexRow>
                            <SecondaryButton wide onClick={p.onClose}>
                                Cancel
                            </SecondaryButton>
                            <_HorizontalSpace base="24px" />
                            <ErrorButton
                                wide
                                onClick={() => {
                                    setIsSubmitting(true)
                                    onMutateDatums({
                                        radarId: p.radarId,
                                        value: p.meta.id,
                                        type: "delete"
                                    })
                                }}>
                                Proceed
                            </ErrorButton>
                        </_FlexRow>
                    </_FlexColumn>
                )}
            </PopupInner>
        </Popup>
    )
}

const mapState: MapState<StateProps> = s => ({
    radarId: getCurrentRadarId(s.auth)!,
    results: s.cloud.actionsResults
})

const mapDispatch = getMapDispatch(actions, ["mutatePipelineStage"])
type ActionProps = ReturnType<typeof mapDispatch>

export const PipelineRemovalView = connect(mapState, mapDispatch)(PipelineRemoval)
