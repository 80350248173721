import { connect } from "react-redux"
import { Loaded } from "../../../functions/src/utils/types"
import { actions as cloudActions } from "../../store/cloud/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { Users, ActionProps, StateProps } from "./RadarUsers"
import { LoadableView } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import { getDataByDeps } from "../../store/data/dataSelectors"
import { MapDispatch } from "../../utils/redux.types"
import { RadarViewFetchMap } from "../../dependencies"

const mapState = getDataByDeps<StateProps>()(RadarViewFetchMap({}, {}), (deps, { cloud: { actionsResults } }) => {
    const { radar, subscribers } = deps
    return Loaded({
        users: subscribers,
        radarId: radar.radarId,
        radarName: radar.name,
        actionsResults,
        restriction: "demo"
        // TODO: Get rid of the restriction
    })
})

// Identical to the one in RadarUsers
const mapDispatch: MapDispatch<ActionProps> = d => ({
    onRequestAccept: (actionId, radarId, userId) => d(cloudActions.acceptRequest(actionId, radarId, userId)),
    onRequestDecline: (actionId, radarId, userId) => d(cloudActions.declineRequest(actionId, radarId, userId)),

    onCreate: (actionId, userPayload) => d(cloudActions.addUser(actionId, userPayload)),
    onUpdate: (actionId, userPayload) => d(cloudActions.updateUser(actionId, userPayload)),

    onResendInvitation: (actionId, radarId, email) => d(cloudActions.resendInivitation(actionId, radarId, email)),
    onRevokeInvitation: (actionId, radarId, email) => d(cloudActions.revokeInvitation(actionId, radarId, email)),

    onPortalInvite: (actionId, userId, radarId) => d(cloudActions.portalInvite(actionId, userId, radarId)),

    queueNotification: msg => d(uiActions.queueNotification(msg))
})

export const SubscribersView = connect(mapState, mapDispatch)(LoadableView(ViewLoader, Users))
