import { assertNever } from "../../../functions/src/utils"
import { FilterActions } from "./Filter"

export const mapFilterValue = (p: Parameters<FilterActions["onChange"]>[0]): any => {
    switch (p.type) {
        case "checkbox":
        case "radio":
        case "input":
        case "search":
        case "collection":
        case "dateRange":
            return p.v
        case "range":
            return { from: p.v?.[0], to: p.v?.[1] }
    }
    assertNever(p)
}
