import { ViewModels, DependencySchema } from "../../../functions/src/models/ViewModels"
import { createAction } from "../../../functions/src/utils/actions"
import { AppLocationState } from "../../models/auth"
import { FlushStateAction } from "../auth/actions"
import { PermissionsActions } from "../middlewares/authMiddleware"
import { AuthReason } from "../middlewares/authMiddleware.types"

export const actions = {
    _fetch: <T extends keyof ViewModels>(
        radarId: string,
        radarName: string | null,
        hubName: string | null,
        schema: DependencySchema<T>,
        cursor: Cursor
    ) => createAction("_fetch", { radarId, schema, cursor, radarName, hubName }),
    _fetchMerge: <T extends keyof ViewModels>(
        radarId: string,
        radarName: string | null,
        hubName: string | null,
        schema: DependencySchema<T>,
        cursor: Cursor
    ) => createAction("_fetchMerge", { radarId, schema, cursor, radarName, hubName }),

    _setFetching: (radarId: string, models: (keyof ViewModels)[], empty?: boolean) =>
        createAction("_setFetching", { radarId, models, empty }),

    _setData: (radarId: string, data: Partial<ViewModels>) => createAction("_setData", { radarId, data }),
    _mergeData: (radarId: string, data: Partial<ViewModels>) => createAction("_mergeData", { radarId, data }),
    _removeData: (radarId: string, key: keyof ViewModels, ids: string[]) =>
        createAction("_removeData", { radarId, key, ids }),
    _setOptimisticDecorator: (payload: DecoratorMutationPayload) => createAction("_upsertDecorator", payload),
    _upsertRelationRemoval: (radarId: string, relation: [string, string]) =>
        createAction("_upsertRelationRemoval", { radarId, relation })
}
export type Actions =
    | ReturnType<typeof actions[keyof typeof actions]>
    | PermissionsActions<AppLocationState, AuthReason>
    | FlushStateAction
