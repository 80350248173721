import { SearchPayload, SearchResponse } from "./search"
import {
    PipelineStageVsSearchAreaReportPayload,
    PipelineStageVsSearchAreaReportResponse,
    PipelineStageVsCollectionReportPayload,
    PipelineStageVsCollectionReportResponse,
    MovedAlongThePipelineReportPayload,
    MovedAlongThePipelineReportResponse,
    ArchiveRationaleReportPayload,
    ArchiveRationaleReportResponse,
    LeadProgressionVsCollectionReportPayload,
    LeadProgressionVsCollectionReportResponse,
    LeadProgressionVsMonthReportPayload,
    LeadProgressionVsMonthReportResponse
} from "./reports"
import { DataRequestPayload, DataRequestResponse } from "./data"
import { DashboardSummaryRequestPayload, DasboardSummaryRequestResponse } from "./summary"
import { ExportResponse, ExportRequest } from "./export"
import { ImportPrioritiesPayload, ImportValidatePrioritiesResponse } from "./import/validatePriorities"
import { ImportValidateItemsResponse, ImportValidateItemsPayload } from "./import/validateItems"
import { MergeTagsRequestPayload, MergeTagsRequestResponse } from "./admin/mergeTags"

export type HttpEndpointActionsType = typeof actions
export type HttpEndpointActions = ReturnType<ValueOf<HttpEndpointActionsType>>
export type HttpEndpointAction<K extends keyof HttpEndpointActionsType> = ReturnType<HttpEndpointActionsType[K]>
export type HttpEndpointActionPayload<K extends keyof HttpEndpointActionsType> = HttpEndpointAction<K>["payload"]
export type HttpEndpointActionResponse<K extends keyof HttpEndpointActionsType> = HttpEndpointAction<K>["__res"]
export type HttpEndpointResponse<T extends keyof HttpEndpointActionsType> = {
    type: T
    payload: HttpEndpointActionResponse<T>
}

export type BaseHttpEndpointAction<T extends string, P, R> = {
    type: T
    payload: P
    __res: R // we do not use the value, we need this **phantom type** further down
}

export const createAction = <R>() => <T extends string, P>(type: T, payload: P): BaseHttpEndpointAction<T, P, R> =>
    ({ type, payload } as BaseHttpEndpointAction<T, P, R>)

export const actions = {
    search: (p: SearchPayload) => createAction<SearchResponse>()("search", p),
    data: (p: DataRequestPayload) => createAction<DataRequestResponse>()("data", p),
    dashboardSummary: (p: DashboardSummaryRequestPayload) =>
        createAction<DasboardSummaryRequestResponse>()("dashboardSummary", p),

    export: (p: ExportRequest) => createAction<ExportResponse>()("export", p),
    exportContactedLeads: (p: ExportRequest) => createAction<ExportResponse>()("exportContactedLeads", p),

    importValidatePriorities: (p: ImportPrioritiesPayload<CName>) =>
        createAction<ImportValidatePrioritiesResponse>()("importValidatePriorities", p),
    importValidateItems: (p: ImportValidateItemsPayload) =>
        createAction<ImportValidateItemsResponse>()("importValidateItems", p),

    pipelineStageVsSearchAreaReport: (p: PipelineStageVsSearchAreaReportPayload) =>
        createAction<PipelineStageVsSearchAreaReportResponse>()("pipelineStageVsSearchAreaReport", p),

    pipelineStageVsCollectionReport: (p: PipelineStageVsCollectionReportPayload) =>
        createAction<PipelineStageVsCollectionReportResponse>()("pipelineStageVsCollectionReport", p),

    leadProgressionVsCollectionReport: (p: LeadProgressionVsCollectionReportPayload) =>
        createAction<LeadProgressionVsCollectionReportResponse>()("leadProgressionVsCollectionReport", p),

    leadProgressionVsMonthReport: (p: LeadProgressionVsMonthReportPayload) =>
        createAction<LeadProgressionVsMonthReportResponse>()("leadProgressionVsMonthReport", p),

    movedAlongThePipelineReport: (p: MovedAlongThePipelineReportPayload) =>
        createAction<MovedAlongThePipelineReportResponse>()("movedAlongThePipelineReport", p),

    archiveRationaleReport: (p: ArchiveRationaleReportPayload) =>
        createAction<ArchiveRationaleReportResponse>()("archiveRationaleReport", p),

    mergeTags: (p: MergeTagsRequestPayload) => createAction<MergeTagsRequestResponse>()("mergeTags", p),

    devTest: (p: any) => createAction<any>()("devTest", p),
    clearCache: (p: any) => createAction<any>()("clearCache", p)
}
