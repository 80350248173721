import React from "react"
import { _FormViewWrapper } from "../../styles/forms"
import { Join } from "../../utils/reactUtils"
import { _VerticalSpace } from "../../styles/common"
import { getPipelineDisplay } from "../../../functions/src/models/decoratorValues"
import { getCollectionSchema, getCollectionFromId } from "../../../functions/src/models/schemas"
import { _ReportsCommentLink, _ReportsCommentWrapper } from "./reports.styles"
import { CommentMeta, CommentText } from "../../components/comments/CommentParts"
import { toRelativeTime } from "../../models/dateTime"
import { CommentsReports } from "../../../functions/src/services/httpEndpoint/reports"
import { values } from "../../../functions/src/utils/map"
import { UNKNOWN } from "../../../functions/src/models/comments"
import { detailsPaths, getCollectionDetailsPathName } from "../../paths"
import { materialize } from "../../utils/router"

type CommentsViewProps<T extends CommentsReports> = {
    report: T
    pipelines: TMap<PipelineStageId, PipelineStageValue>
    searchAreas: TMap<AreaId, SearchArea>
    config: LocationParams
}

const CommentsView = <T extends CommentsReports>(p: CommentsViewProps<T>) => (
    <_FormViewWrapper>
        <Join renderJoining={() => <_VerticalSpace base="16px" />} items={p.report.items}>
            {i => {
                if (!i.meta || i.meta.type !== "pipeline") return null
                const metaValue = i.meta.value
                const cname = getCollectionFromId(metaValue.objectId)
                const text = `${cname ? getCollectionSchema(cname).singleName : ""} ${
                    i.objectName
                } was changed from ${getPipelineDisplay(metaValue.oldValue, p.pipelines)} to ${getPipelineDisplay(
                    metaValue.newValue,
                    p.pipelines
                )} for collection: ${
                    values(p.searchAreas).find(v => v.areaId === metaValue.searchAreaId)?.name || UNKNOWN
                }`

                return (
                    <_ReportsCommentWrapper>
                        <_ReportsCommentLink
                            href={
                                cname
                                    ? materialize(detailsPaths[getCollectionDetailsPathName(cname)].path, {
                                          radarSlug: p.config.radarSlug,
                                          [getCollectionSchema(cname).idField]: i.objectId
                                      })
                                    : "javascript:void(0);"
                            }
                            target="_blank"
                            rel="noreferrer noopener">
                            {text}
                        </_ReportsCommentLink>
                        <_VerticalSpace base="4px" />
                        <CommentText text={i.text} tilted />
                        <_VerticalSpace base="8px" />
                        <CommentMeta
                            time={toRelativeTime(i.updatedTs) || ""}
                            authorName={i.userName}
                            authorEmail={i.userEmail || "no email"}
                        />
                        <_VerticalSpace base="12px" />
                    </_ReportsCommentWrapper>
                )
            }}
        </Join>
    </_FormViewWrapper>
)

export default CommentsView
