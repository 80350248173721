import * as React from "react"

export const useScrollPositionClassNameHandler = (c: HTMLElement | null) => {
    React.useEffect(() => {
        if (c) {
            const scrollHandler = () => {
                const ccl = c.classList
                const left = c.scrollLeft === 0
                const right = c.scrollLeft + c.clientWidth >= c.scrollWidth

                if (c.scrollWidth === c.clientWidth) {
                    ccl.remove("scroll-right")
                    ccl.remove("scroll-middle")
                    ccl.remove("scroll-left")
                } else if (left) {
                    if (!ccl.contains("scroll-left")) {
                        ccl.remove("scroll-right")
                        ccl.remove("scroll-middle")
                        ccl.add("scroll-left")
                    }
                } else if (right) {
                    if (!ccl.contains("scroll-right")) {
                        ccl.remove("scroll-left")
                        ccl.remove("scroll-middle")
                        ccl.add("scroll-right")
                    }
                } else if (!ccl.contains("scroll-middle")) {
                    ccl.remove("scroll-left")
                    ccl.remove("scroll-right")
                    ccl.add("scroll-middle")
                }
            }
            scrollHandler()
            c.addEventListener("scroll", scrollHandler)
            return () => c.removeEventListener("scroll", scrollHandler)
        }
    }, [c])
}
