/* eslint-disable max-lines */
import * as React from "react"
import { useFormHook } from "../../utils/hooks/useFormHook"
import { FormView } from "../../components/form/FormView"
import { actions as uiActions } from "../../store/ui/actions"
import { Button } from "../../styles/buttons"
import { values, remap, identity, keys, filterObject } from "../../../functions/src/utils/map"
import { SubpageLayout } from "../../components/Page"
import { actions } from "../../store/cloud/actions"
import { connect, ConnectedComponent } from "react-redux"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { _VerticalSpace, _Center, _HorizontalSpace, _FlexRow, _FlexColumn, _Spacer } from "../../styles/common"
import { Msg } from "../../models/notifications"
import { mkRow, HeaderTitle } from "../../components/table/TableViewController"
import { Table } from "../../components/table/TableView"
import { Modal } from "../../components/Modal"
import { toOption, Loaded, MutationState, isProcessing, isNotStarted } from "../../../functions/src/utils/types"
import { mkTableNumberSchema, mkTableComputedSchema, mkTableTextSchema } from "../../components/table/TableViewSchemas"
import { getMapDispatch2 } from "../../utils/redux"
import { assignmentsByTagId, byCompareName, displayTag, ExtTag, TagWithUsage } from "../../../functions/src/models/tags"
import { Loader } from "../../components/common/Loader"
import { ViewLoader } from "../../containers/ViewRenderer"
import { LoadableView } from "../../utils/reactUtils"
import { getDataByDeps } from "../../store/data/dataSelectors"
import { actions as dataActions } from "../../store/data/actions"
import { RowSchema } from "../../components/table/TableView.types"
import { RadarViewFetchMap, DecoratingDataFetchMap } from "../../dependencies"
import { _DataContainer } from "./AdminDashboard.styles"
import { getFirebase } from "../../services/firebase"
import { actions as httpActions } from "../../../functions/src/services/httpEndpoint/actions"
import { validArrayStringDef, validString } from "../../../functions/src/utils/validators"
import { Dispatch, Action } from "redux"
import { SearchInput } from "../../components/form/Input"
import { getDeletePopupText } from "./utils"
import { getUserRadars, getCurrentRadar, isDemoRadarLocation } from "../../models/LocationType"

export const schema: FormSchema<Pick<Tag, "name">> = {
    name: { name: "Name", type: "text", validators: validString }
}

type TagEditorProps = {
    tag: Tag | null
    state: AsyncAction<CloudActionResult>
    onSubmit: F1<Pick<Tag, "name">>
}

export const TagEditor: React.FC<TagEditorProps> = p => {
    const { formViewProps, onSubmitClick, resetState } = useFormHook({
        schema,
        onSubmit: v => {
            p.onSubmit(v)
            resetState()
        },
        initialValue: { name: p.tag ? p.tag.name : "" }
    })

    return (
        <_Center>
            {isNotStarted(p.state) && (
                <>
                    <FormView {...formViewProps} />
                    <_Center>
                        <Button onClick={onSubmitClick}>{p.tag ? "Update tag" : "Create tag"}</Button>
                    </_Center>
                </>
            )}
            {isProcessing(p.state) && <Loader />}
        </_Center>
    )
}

type MergeTagsFormSchema = {
    tagsToMerge: TagId[]
    name: string
}

type MergeTagsFormProps = {
    onSubmit: (v: MergeTagsFormSchema) => Promise<void>
    schema: FormSchema<MergeTagsFormSchema>
}
export const MergeTagsForm = (p: MergeTagsFormProps) => {
    const [loading, setLoading] = React.useState(false)
    const { formViewProps, onSubmitClick, resetState } = useFormHook({
        schema: p.schema,
        onSubmit: async v => {
            setLoading(true)
            await p.onSubmit(v)
            setLoading(false)
            resetState()
        },
        initialValue: {
            name: "",
            tagsToMerge: []
        }
    })

    return (
        <_Center>
            {loading ? (
                <Loader />
            ) : (
                <>
                    <FormView {...formViewProps} />
                    <_Center>
                        <Button data-cy="merge-tags-button" onClick={onSubmitClick}>
                            Merge tags
                        </Button>
                    </_Center>
                </>
            )}
        </_Center>
    )
}

type StateProps = {
    tags: SMap<ExtTag>
    radar: RadarDetails
    actionsResults: SMap<CloudActionResult>
    currentRadar?: LocationParams
}

const tagsSchema: RowSchema<ExtTag> = [
    mkTableComputedSchema("Name", "name", "title", displayTag),
    mkTableNumberSchema<ExtTag>("Tagged objects count", "useCnt")
]

const loadinRowSchema: RowSchema<{ text: string }> = [mkTableTextSchema("", "text")]
const LoadingRow = (id: React.ReactText) => mkRow(id, loadinRowSchema, { text: "Removing" }, ["disabled", "error"])

export const TagsEditor: React.FC<StateProps & ActionProps & MergeProps> = p => {
    const radarId = p.radar.radarId
    const onRadar = <T extends SMap<unknown>>(t: T) => ({ radarId, ...t })
    const [isNewTagOpen, setNewTagOpen] = React.useState(false)
    const [isMergeTagsOpen, setMergeTagsOpen] = React.useState(false)
    const [editingTagId, setEditingTagId] = React.useState<React.ReactText | null>(null)
    const [removingIds, setRemovingIds] = React.useState<React.ReactText[]>([])

    const [search, setSearch] = React.useState("")
    const onClear = () => setSearch("")
    const [filteredTags, setFilteredTags] = React.useState(p.tags)

    React.useEffect(() => {
        setFilteredTags(
            filterObject(p.tags, (_, tag) => tag.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        )
    }, [search, p.tags])

    const headerRow = tagsSchema.map(v => HeaderTitle(v.key, v.label, v.size, v.key))
    const tagRows = values(filteredTags)
        .sort(byCompareName)
        .map(t => mkRow(t.tagId, tagsSchema, t))
    const onClose = () => {
        setNewTagOpen(false)
        setEditingTagId(null)
    }
    const [mutatingState, onMutate, resetState] = useCloudAction(p.mutateTag, p.actionsResults, r => {
        onClose()
        const payload = (r.action as ReturnType<typeof actions.mutateTag>).payload
        if (payload.type === "delete") setRemovingIds(ids => ids.filter(id => id === payload.value))
        p.queueNotification(Msg(payload.type, { name: "tag" }, r.result))
        resetState()
    })
    const onRemove = (tagId: string) => {
        p.openPopup("deleteConfirmation", {
            objectType: "Tag",
            objectName: p.tags[tagId].name,
            customText: getDeletePopupText("Tag", p.tags[tagId].name, p.tags[tagId].useCnt),
            onDelete: () => {
                setRemovingIds(ids => ids.concat(tagId))
                onMutate(onRadar(MutationState("delete", tagId)))
            }
        })
    }
    const onEditorSubmit = (tagId: string | null) => (payload: Pick<Tag, "name">) =>
        onMutate(onRadar(tagId ? MutationState("update", { ...payload, tagId }) : MutationState("create", payload)))

    const mergeTagsSchema: FormSchema<MergeTagsFormSchema> = {
        tagsToMerge: {
            type: "selectableChips",
            name: "Select tags to merge",
            validators: validArrayStringDef,
            values: keys(p.tags).map(tid => [`${p.tags[tid].name}`, tid])
        },
        ...schema
    }

    return (
        <SubpageLayout>
            <Modal
                isOpen={isNewTagOpen || !!editingTagId}
                onClose={onClose}
                title={isNewTagOpen ? "Add tag" : "Update tag"}>
                <TagEditor
                    tag={editingTagId ? p.tags[editingTagId] : null}
                    onSubmit={onEditorSubmit(editingTagId as string)}
                    state={mutatingState}
                />
            </Modal>
            <Modal isOpen={isMergeTagsOpen} onClose={() => setMergeTagsOpen(false)} title="Merge tags">
                <MergeTagsForm
                    onSubmit={async v => {
                        const r = await getFirebase().functions.callFunctions(
                            httpActions.mergeTags({
                                radarId,
                                tagsToMerge: v.tagsToMerge,
                                newTag: {
                                    name: v.name
                                }
                            })
                        )
                        p.fetchAssignments()
                        p.queueNotification(
                            Msg("custom", { success: "Successfully merged tags", error: "Error while merging tags" }, r)
                        )
                        setMergeTagsOpen(false)
                    }}
                    schema={mergeTagsSchema}
                />
            </Modal>
            <_FlexColumn grow={1}>
                <_FlexRow>
                    <_HorizontalSpace base="16px" />
                    <SearchInput value={search} onChange={setSearch} onClear={onClear} />
                    <_Spacer />
                    <Button data-cy="merge-tags-button" onClick={() => setMergeTagsOpen(true)}>
                        Merge Tags
                    </Button>
                    <_HorizontalSpace base="16px" />
                    <Button data-cy="add-tag-button" onClick={() => setNewTagOpen(true)}>
                        Add Tag
                    </Button>
                    <_HorizontalSpace base="16px" />
                </_FlexRow>
                <_VerticalSpace base="16px" />
                <_DataContainer>
                    <Table
                        rowHeight={50}
                        interactiveOptions={[toOption("Edit", setEditingTagId), toOption("Remove", onRemove)]}
                        virtualized
                        rows={tagRows.map(row => (removingIds.includes(row.id) ? LoadingRow(row.id) : row))}
                        headerRow={headerRow}
                    />
                </_DataContainer>
                <_VerticalSpace base="24px" />
            </_FlexColumn>
        </SubpageLayout>
    )
}

const mapState = getDataByDeps<StateProps>()(
    RadarViewFetchMap({}, { tagsAssignments: "all" }),
    (deps, { auth, cloud: { actionsResults } }) => {
        const { radar, tags, tagsAssignments } = deps
        const tagsAssignmentsByTagId = assignmentsByTagId(tagsAssignments)

        const extTags = remap(tags, identity, (t, tid) => TagWithUsage(t, tagsAssignmentsByTagId[tid]))

        const isDemo = isDemoRadarLocation(auth.params)
        const radars = getUserRadars(auth)
        const current = getCurrentRadar(auth)

        const currentRadar = current
            ? isDemo
                ? (current as LocationParams)
                : radars.find(r => r.radarId === current.radarId)
            : undefined

        return Loaded({ tags: extTags, radar, actionsResults, currentRadar })
    }
)

type ActionProps = ReturnType<typeof mapDispatch>
type MergeProps = { fetchAssignments: () => void }
const mapDispatch = (dispatch: Dispatch<Action>, ownProps: Record<string, unknown>) => ({
    ...getMapDispatch2(actions, ["mutateTag"], uiActions, ["queueNotification", "openPopup"])(dispatch, ownProps),
    dispatch
})
const mergeProps = (
    stateProps: Loadable<StateProps>,
    dispatchProps: ActionProps & { dispatch: Dispatch<Action> },
    ownProps: Record<string, unknown>
) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    fetchAssignments: () =>
        dispatchProps.dispatch(
            dataActions._fetchMerge(
                (stateProps as Loaded<StateProps>).radar.radarId,
                (stateProps as Loaded<StateProps>).currentRadar?.radarName || null,
                (stateProps as Loaded<StateProps>).currentRadar?.hubName || null,
                DecoratingDataFetchMap({ tagsAssignments: "all" }),
                { type: "none" }
            )
        )
})
const LoadableTagsEditor = LoadableView(ViewLoader, TagsEditor)

export const TagsEditorView: ConnectedComponent<typeof LoadableTagsEditor, unknown> = connect(
    mapState,
    mapDispatch,
    mergeProps
)(LoadableTagsEditor)
