import {
    validate,
    validString,
    validStringDef,
    validMapDef,
    validBooleanDef,
    validNumberDef,
    validArrayDef,
    validBooleanDefTrue,
    validArrayString,
    validatePartial
} from "../utils/validators"
import { pickIntersect } from "../utils/map"
import { validateStartupFields, startupValidationMap, startupRelationsValidationMap } from "./Startup"
import { validateSectorFields, sectorValidationMap, sectorRelationsValidationMap } from "./Sector"
import { validatePatentFields, patentValidationMap, patentRelationsValidationMap } from "./Patent"
import { validateInfluencerFields, influencerValidationMap, influencerRelationsValidationMap } from "./Influencer"
import { validateTechExpertFields, techExpertValidationMap, techExpertRelationsValidationMap } from "./TechExpert"
import {
    validateTechTransferFields,
    techTransferValidationMap,
    techTransferRelationsValidationMap
} from "./TechTransfer"
import { validateResearchHubFields, researchHubValidationMap, researchHubRelationsValidationMap } from "./ResearchHub"
import { validateInvestorFields, investorValidationMap, investorRelationsValidationMap } from "./Investor"
import {
    validateResearchPaperFields,
    researchPaperValidationMap,
    researchPaperRelationsValidationMap
} from "./ResearchPaper"
import {
    validatePatentHolderFields,
    patentHolderValidationMap,
    patentHolderRelationsValidationMap
} from "./PatentHolder"
import { validateItemClassifiersInput, validatePartialItemClassifiersInput } from "./common"
import { validateGrantFields, grantValidationMap, grantRelationsValidationMap } from "./Grant"
import {
    validateClinicalTrialFields,
    clinicalTrialValidationMap,
    clinicalTrialRelationsValidationMap
} from "./ClinicalTrial"
import { companyValidationMap, companyRelationsValidationMap, validateCompanyFields } from "./Company"
import { ImportableItem, ImportableCollections } from "./importing.types"

export const getFederatedUrl = (deployment_mode = "dev") =>
    deployment_mode === "prod"
        ? "https://fedauth.pg.com/idp/startSSO.ping?PartnerSpId=PGVDiscoveryDashboard"
        : "https://fedauthtst.pg.com/idp/startSSO.ping?PartnerSpId=PGVDiscoveryDashboard"

export const PG_RADAR_SLUG = "ventures"
export const PG_HUB_SLUG = "pg"

export const isPG = (hubSlug: HubSlug) => hubSlug === PG_HUB_SLUG

const radarConfigValidationMap: ValidationMap<Required<LocationParams>> = {
    collections: validArrayString,
    restriction: validString,
    isDisabled: validBooleanDef,

    hubId: validString,
    hubName: validString,
    hubSlug: validStringDef,

    radarId: validString,
    radarName: validString,
    radarSlug: validString,

    withDashboard: validBooleanDef,
    withJobClusters: validBooleanDef,
    withPriorityRanks: validBooleanDefTrue,
    withStars: validBooleanDefTrue,
    withAssignments: validBooleanDefTrue,
    withPipelinesSummary: validBooleanDef,
    withContactedLead: validBooleanDef,
    withSegmentTags: validBooleanDef
}

export const validateRadarConfig = validate<LocationParams>(radarConfigValidationMap)

const radarValidationMap: ValidationMap<Required<Radar>> = {
    name: validString,
    radarId: validString,
    hubId: null,
    relationshipsEnabled: validBooleanDef,

    xAxisLabels: null,
    yAxisLabels: null,
    columns: validNumberDef,
    rows: validNumberDef,
    portalOrganizationId: validStringDef,

    startups: null,
    sectors: null,
    patents: null,
    influencers: null,
    companies: null,
    tech_experts: null,
    tech_transfers: null,
    research_hubs: null,
    investors: null,
    research_papers: null,
    patent_holders: null,
    grants: null,
    clinical_trials: null,
    demo: validBooleanDef
}

export const validateRadarCollections = validate<RadarCollections>({
    startups: validMapDef([validateStartupFields]),
    sectors: validMapDef([validateSectorFields]),
    patents: validMapDef([validatePatentFields]),
    influencers: validMapDef([validateInfluencerFields]),
    tech_experts: validMapDef([validateTechExpertFields]),
    tech_transfers: validMapDef([validateTechTransferFields]),
    research_hubs: validMapDef([validateResearchHubFields]),
    investors: validMapDef([validateInvestorFields]),
    research_papers: validMapDef([validateResearchPaperFields]),
    patent_holders: validMapDef([validatePatentHolderFields]),
    grants: validMapDef([validateGrantFields]),
    clinical_trials: validMapDef([validateClinicalTrialFields]),
    companies: validMapDef([validateCompanyFields])
})

export const validateItemMap = <C extends CName>(
    fieldsValidators: ValidationMap<Required<RCollection<C>>>,
    relationsValidators: ValidationMap<Required<RCollectionRelations<C>>>,
    partial: boolean
) => {
    const validationMap: ValidationMap<Required<ImportableItem<C>>> = {
        fields: [
            partial ? validatePartial<RCollection<C>>(fieldsValidators) : validate<RCollection<C>>(fieldsValidators)
        ],
        relations: [
            partial
                ? validatePartial<RCollectionRelations<C>>(relationsValidators)
                : validate<RCollectionRelations<C>>(relationsValidators)
        ],
        classifiers: [partial ? validatePartialItemClassifiersInput : validateItemClassifiersInput]
    }
    return partial ? validatePartial<ImportableItem<C>>(validationMap) : validate<ImportableItem<C>>(validationMap)
}

export const validateImportableCollections = (partial: boolean) =>
    validate<ImportableCollections>({
        startups: validArrayDef([validateItemMap(startupValidationMap, startupRelationsValidationMap, partial)]),
        sectors: validArrayDef([validateItemMap(sectorValidationMap, sectorRelationsValidationMap, partial)]),
        patents: validArrayDef([validateItemMap(patentValidationMap, patentRelationsValidationMap, partial)]),
        influencers: validArrayDef([
            validateItemMap(influencerValidationMap, influencerRelationsValidationMap, partial)
        ]),
        tech_experts: validArrayDef([
            validateItemMap(techExpertValidationMap, techExpertRelationsValidationMap, partial)
        ]),
        tech_transfers: validArrayDef([
            validateItemMap(techTransferValidationMap, techTransferRelationsValidationMap, partial)
        ]),
        research_hubs: validArrayDef([
            validateItemMap(researchHubValidationMap, researchHubRelationsValidationMap, partial)
        ]),
        investors: validArrayDef([validateItemMap(investorValidationMap, investorRelationsValidationMap, partial)]),
        research_papers: validArrayDef([
            validateItemMap(researchPaperValidationMap, researchPaperRelationsValidationMap, partial)
        ]),
        patent_holders: validArrayDef([
            validateItemMap(patentHolderValidationMap, patentHolderRelationsValidationMap, partial)
        ]),
        grants: validArrayDef([validateItemMap(grantValidationMap, grantRelationsValidationMap, partial)]),
        clinical_trials: validArrayDef([
            validateItemMap(clinicalTrialValidationMap, clinicalTrialRelationsValidationMap, partial)
        ]),
        companies: validArrayDef([validateItemMap(companyValidationMap, companyRelationsValidationMap, partial)])
    })

export const validateRadar = validate<Radar>(radarValidationMap)

export const validateRadarImportPayload = validate<RadarDetailsPayload>({
    ...pickIntersect<RadarDetailsPayload>()(radarValidationMap, [
        "name",
        "xAxisLabels",
        "yAxisLabels",
        "columns",
        "rows",
        "portalOrganizationId",
        "relationshipsEnabled"
    ]),
    ...pickIntersect<RadarDetailsPayload>()(radarConfigValidationMap, [
        "restriction",
        "radarSlug",
        "isDisabled",
        "withDashboard",
        "withJobClusters",
        "withPriorityRanks",
        "withStars",
        "withAssignments",
        "withPipelinesSummary",
        "withContactedLead",
        "withSegmentTags",
        "collections"
    ])
})
