import { toOrderOption, toColorOption, toOption } from "../utils/types"
import { toMap, sortByOrder, toArray, keys, values } from "../utils/map"
import { isEmpty } from "../utils/validators"
import { PipelineStageValueVM } from "./ViewModels"
import { getDecryptedPipeline } from "./decorators"

export const NO_DECORATOR = "no_decorator"
export const ARCHIVED_PSV = "archived_psv"

export const DecoratorsDisplayNames: Record<Decorators, string> = {
    assignment: "Assignment",
    attachment: "Attachment",
    pipelineStage: "Pipeline Stage",
    priorityRank: "Priority Rank",
    star: "Star",
    lead: "Contacted Lead"
}

// ================ PIPELINE STAGE VALUES ================

export const PIPELINE_STAGE_NA = "No stage"
export const PIPELINE_STAGE_ARCHIVED = "Archived"

export const pipelineStages: OrderOption<string>[] = [
    toOrderOption(PIPELINE_STAGE_ARCHIVED, ARCHIVED_PSV, 99),
    toOrderOption(PIPELINE_STAGE_NA, NO_DECORATOR, 100)
]

export const getPipelineStagesMap = (): SMap<Required<OmitStrict<PipelineStageValue, "collection">>> =>
    toMap(
        pipelineStages,
        tk => tk.value,
        tv => ({ name: tv.label, order: tv.order, pipelineId: tv.value })
    )

export const getPipelineDisplay = (
    pipelineId: PipelineStageId | undefined,
    dynamicPS: TMap<PipelineStageId, PipelineStageValue>
) =>
    !pipelineId || pipelineId === NO_DECORATOR
        ? PIPELINE_STAGE_NA
        : pipelineId === ARCHIVED_PSV
        ? PIPELINE_STAGE_ARCHIVED
        : dynamicPS[pipelineId]?.name || ""

export const getPipelineStageValues = (psv: SMap<PipelineStageValueVM>, cname?: CName): ROptions => {
    const vs = sortByOrder(
        toArray(psv).filter(v => !cname || cname === v.collection),
        pv => pv.order
    ).map(v => toOrderOption(`${v.name}`, v.pipelineId))
    const removedDuplicates = vs.reduce(
        (acc, o) => (acc.find(v => v.label === o.label) ? acc : acc.concat(o)),
        [] as OrderOption<string>[]
    )
    return [...removedDuplicates, ...pipelineStages]
}

export const getPipelineCurrentValue = (
    pipelines: SMap<PipelineStageValue>,
    decorators: DecoratorsMap,
    searchAreaId: string
): ROption => {
    const dec = decorators?.pipelineStage?.[searchAreaId]
    const staticPipelines = getPipelineStagesMap()
    if (!dec) return toOption(PIPELINE_STAGE_NA, NO_DECORATOR)
    if (keys(staticPipelines).includes(dec.valueRef)) return toOption(staticPipelines[dec.valueRef].name, dec.valueRef)
    if (!pipelines[dec.valueRef]) return toOption(PIPELINE_STAGE_NA, NO_DECORATOR)
    return toOption(pipelines[dec.valueRef].name, pipelines[dec.valueRef].pipelineId)
}

export const getAndDecryptPipelineValue = (
    pipelines: SMap<PipelineStageValue>,
    secureKey: EncryptionKey | null,
    pipelineId?: string
): string | null => {
    const ds: SMap<PipelineStageValue> = pipelines || {}
    const pipelineValue = values(ds).find(d => d.pipelineId === pipelineId)
    const staticPipelines = getPipelineStagesMap()
    const fallback = staticPipelines[pipelineId || NO_DECORATOR]?.name || PIPELINE_STAGE_NA
    return pipelineValue ? getDecryptedPipeline(pipelineValue, secureKey).name : fallback || null
}

// ================ PRIORITY RANK VALUES ================

export const priorityRanks = {
    high: "High",
    medium: "Medium",
    low: "Low",
    notAvailable: "N/A"
} as const

export const priorityRankOptions: ColorOption<PriorityRank | "no_decorator">[] = [
    toColorOption(priorityRanks.high, priorityRanks.high, "#b4f0d3"),
    toColorOption(priorityRanks.medium, priorityRanks.medium, "#fbe2d0"),
    toColorOption(priorityRanks.low, priorityRanks.low, "#ff89a1"),
    toColorOption(priorityRanks.notAvailable, NO_DECORATOR, "#e4eafd")
]

export const getPriorityRankMap = (): TMap<PriorityRank, string> =>
    toMap(
        priorityRankOptions,
        tk => tk.value,
        tv => tv.label
    )

export const getPriorityCurrentValue = (decorators: DecoratorsMap, searchAreaId: string): ROption => {
    const dec = decorators?.priorityRank?.[searchAreaId]
    return (
        priorityRankOptions.find(o => o.value === dec?.value) ||
        toColorOption(priorityRanks.notAvailable, NO_DECORATOR, "#e4eafd")
    )
}

export const getPriorityRankValues = (): ROptions => priorityRankOptions

export const priorityRankSortingLevels: SMap<number> = {
    [priorityRanks.high]: 3,
    [priorityRanks.medium]: 2,
    [priorityRanks.low]: 1,
    [priorityRanks.notAvailable]: 0
}

// ================ ASSIGNMENT VALUES ================
export const ASSIGNMENT_NA = "Not Assigned"

const mapUserToDisplay = ({ displayName, email }: RadarUser): string => `${displayName || email}`.trim()

export const mapUserToOption = (user: RadarUser, userId: string): ROption<string> =>
    toOption(mapUserToDisplay(user).trim(), userId, user.email)

export const getAssignedUserName = (users: SMap<RadarUser>, userId?: string) => {
    if (!userId) return ASSIGNMENT_NA
    const user = users[userId!]
    if (!user) return ASSIGNMENT_NA
    return mapUserToDisplay(user)
}

export const getUserOptionByUserId = (users: SMap<RadarUser>) => (userId?: string): ROption => {
    if (isEmpty(userId)) return toOption(ASSIGNMENT_NA, NO_DECORATOR)
    const user = users[userId]
    if (!user) return toOption(ASSIGNMENT_NA, NO_DECORATOR)
    return mapUserToOption(user, userId)
}

export const getUserNameFromId = (users: TMap<UserId, RadarUser>, id: string): string => {
    const u = users[id]
    return u ? u.displayName || u.email : "Unknown user"
}

export const getPGAssignmentDisplay = (u?: RadarUser): string => {
    if (!u) return "Unknown user"
    const [first, second, third] = u.email.split("@")[0].split(".")

    return `${second || ""}${first[0]}${third || ""}`.toUpperCase()
}

export const getAssignmentDecoratorOptions = (users: TMap<UserId, RadarUser>): ROptions => {
    return [getUserOptionByUserId(users)(""), ...keys(users).map(uid => mapUserToOption(users[uid], uid))]
}

// ================ CONTACTED LEAD VALUES ================
