import * as React from "react"
import {
    Attachment,
    UploadingFile,
    getRowFromUploadingSchema,
    uploadingRowSchema,
    attachmentBaseRowSchema
} from "../models/attachments"
import { toOption } from "../../functions/src/utils/types"
import { isAdmin } from "../models/LoginStatus"
import { FileStatus } from "../utils/hooks/useStorage"
import { isEmpty } from "../../functions/src/utils/validators"
import { keys, omitObject } from "../../functions/src/utils/map"
import { mkRow, HeaderTitle } from "./table/TableViewController"
import { Table } from "./table/TableView"
import { AuthenticatedState } from "../models/auth"
import { Row } from "./table/TableView.types"
import { _noop } from "../../functions/src/utils"

const getInteractiveOptions = (p: {
    onDownload: F1<string>
    attachments: Attachment[]
    deleteOptions: DeleteOptions
    uploadStatuses?: SMap<FileStatus>
}) => (id: string) => {
    if (p.uploadStatuses && p.uploadStatuses[id] && p.uploadStatuses[id].type !== "Done")
        return [toOption("This file is not ready", _noop)]
    const opts: ROptions<F1<string, any>> = [toOption("Download", p.onDownload)]
    const attachment = p.attachments.find(a => a.id === id)!

    switch (p.deleteOptions.type) {
        case "admin&author":
            if (isAdmin(p.deleteOptions.value.auth) || attachment.authorId === p.deleteOptions.value.userId)
                opts.push(toOption("Delete", p.deleteOptions.value.onDelete))
            break
        case "all":
            opts.push(toOption("Delete", p.deleteOptions.value.onDelete))
            break
        case "none":
            break
    }
    return opts
}

// TMap<UploadingFileUID, StorageStatus>
const getUploadingRows = (uploadStatuses: SMap<FileStatus>): Row[] => {
    if (isEmpty(uploadStatuses)) return []
    const mappedUploadingFiles: UploadingFile[] = keys(uploadStatuses).flatMap(k =>
        getRowFromUploadingSchema(k, uploadStatuses[k])
    )
    return mappedUploadingFiles.map(f => mkRow(f.key, uploadingRowSchema, f))
}

type DeleteOptions =
    | ValueState<"admin&author", { auth: AuthenticatedState; userId: string; onDelete: F1<string> }>
    | ValueState<"all", { onDelete: F1<string> }>
    | State<"none">

type AttachmentsTableProps = {
    deleteOptions: DeleteOptions
    onDownload: F1<string, Promise<any>>
    attachments: SMap<AttachmentFile>
    uploadStatuses?: SMap<FileStatus>
    mapToDisplay: F2<string, AttachmentFile, Attachment>
}

export const AttachmentsTable: React.FC<AttachmentsTableProps> = ({ attachments, uploadStatuses, ...p }) => {
    const displayableAttachments = keys(attachments)
        .sort((k1, k2) => attachments[k1].createdAt - attachments[k2].createdAt)
        .map(k => p.mapToDisplay(k, attachments[k]))
    const rowsFromDecorators = displayableAttachments.map(a => mkRow(a.id, attachmentBaseRowSchema, a))
    const [downloadingRows, setDownloadingRows] = React.useState<SMap<Row>>({})
    const onDownload = async (uuid: string) => {
        const file = attachments[uuid]
        setDownloadingRows(drs => ({
            ...drs,
            [uuid]: mkRow(uuid, attachmentBaseRowSchema, {
                filename: file.filename,
                addedDate: "Preparing to download...",
                authorName: ""
            })
        }))
        await p.onDownload(uuid)
        setDownloadingRows(drs => omitObject(drs, [uuid]))
    }
    const withDownloadInfo = rowsFromDecorators.map(r => (downloadingRows[r.id] ? downloadingRows[r.id] : r))

    const rows = [...withDownloadInfo, ...getUploadingRows(uploadStatuses || {})]
    return (
        <Table
            headerRow={attachmentBaseRowSchema.map(v => HeaderTitle(v.key, v.label, v.size, v.key))}
            rows={rows}
            interactiveOptions={getInteractiveOptions({
                onDownload,
                attachments: displayableAttachments,
                deleteOptions: p.deleteOptions,
                uploadStatuses
            })}
            virtualized={false}
        />
    )
}
