import styled, { css, DefaultTheme } from "styled-components"
import { Flex } from "../../../styles/common"
import { _IconSvgContainer } from "../../IconSvg.styles"

const getItemPadding = (p: { theme: DefaultTheme }) => 0.7 * p.theme.sizes.defaultPadding

export const _FLItemIconContainer = styled(Flex)`
    margin-top: -2px;
    padding-right: 13px;
    flex-shrink: 0;

    ${_IconSvgContainer} {
        max-width: 23px;
        max-height: 23px;
    }
`

export const _FLItemInfoIconContainer = styled.span`
    position: relative;
    margin: ${getItemPadding}px 0;
    cursor: pointer;

    ${_IconSvgContainer} {
        position: absolute;
        top: 2px;
        left: 5px;
    }
`

export const _FLItemTitleContainer = styled(Flex)<{ withInfo?: boolean }>`
    grid-area: name;
    cursor: ${p => p.withInfo && "pointer"};
    padding: ${getItemPadding}px 0;
    align-items: flex-start;
    color: #617084;
    transition: color 0.15s ease-in-out;

    ${p =>
        p.withInfo &&
        css`
            &:hover {
                color: #68e2a7;
            }
        `}
`

export const _FLItemNameContainer = styled.span`
    font-weight: 500;
`

export const _FLItemValueContainer = styled(Flex)`
    grid-area: value;
    word-break: break-word;
    padding: ${getItemPadding}px 0;
`

export const _FLItemGridContainer = styled.div<{
    vertical?: boolean
}>`
    display: grid;
    grid-template-columns: 1.2fr 1fr;

    & > *:not(:last-child) {
        padding-right: 13px;
    }

    ${p =>
        p.vertical
            ? css`
                  display: flex;
                  flex-direction: column;
              `
            : css`
                  grid-template-areas: "name value";
              `}

    ${_FLItemValueContainer} {
        ${p =>
            !p.vertical
                ? css`
                      justify-content: flex-end;
                      text-align: right;
                  `
                : css`
                      padding: 0px 19px 0 34px;
                  `}
    }
`

export const _FLItemContainer = styled.div`
    position: relative;
    padding: 0 8px;
    margin-left: -8px;
    margin-right: -8px;
    font-size: 16px;
    border-bottom: 0.5px solid #e1e4e8;

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
`
