import { createAction } from "../../../functions/src/utils/actions"
import { AuthenticationState, AppLocationState } from "../../models/auth"
import { NavigationParams } from "../../utils/router.types"
import { PermissionsActions } from "../middlewares/authMiddleware"
import { AuthReason } from "../middlewares/authMiddleware.types"

export const NavigateActionName = "navigate"
export const FlushStateAction = "flushState"

export type FlushStateAction = { type: typeof FlushStateAction }

// These are action creators: they don't do much except repacking their arguments into a named Action object.
// Which has at least a type (for zero-arg actions), a payload, and an optional meta section
// The actual handling of these is in the reducer at src/store/auth/index
export const actions = {
    fetchConfigs: () => createAction("fetchConfigs"),
    _setConfigs: (configs: Async<SMap<LocationParams>>) => createAction("_setConfigs", configs),

    fetchHubs: () => createAction("fetchHubs"),
    _setHubs: (hubs: Async<SMap<ExtHub>>) => createAction("_setHubs", hubs),

    tryFederatedLogin: (token: string) => createAction("tryFederatedLogin", token),
    tryLogout: () => createAction("tryLogout"),

    resetAuthStatuses: () => createAction("resetAuthStatuses"),

    signInViaEmail: (actionId: string, email: string) => createAction("signInViaEmail", { actionId, email }),
    checkDeliverableEmail: (actionId: string, email: string) =>
        createAction("checkDeliverableEmail", { actionId, email }),

    tryRequestAccess: (actionId: string) => createAction("tryRequestAccess", actionId),

    [NavigateActionName]: (params: NavigationParams) => createAction(NavigateActionName, params),
    saveDemoEmail: (email: string) => createAction("saveDemoEmail", { email }),

    _setDemoEmailGiven: (email: string) => createAction("_setDemoEmailGiven", { email }),
    _setLoginStatus: (s: AuthenticationState) => createAction("_setLoginStatus", s),
    _setAccessRequestResult: (r: UserAccessRequestResult) => createAction("_setAccessRequestResult", r),
    _flushState: () => createAction(FlushStateAction)
}

export type Actions =
    | ReturnType<typeof actions[keyof typeof actions]>
    | PermissionsActions<AppLocationState, AuthReason>
