import { Store, compose, createStore, applyMiddleware } from "redux"
import { install as installReduxLoop } from "redux-loop"
import { connectRouter, routerMiddleware } from "connected-react-router"
import { createBrowserHistory, LocationState } from "history"

import * as data from "./data"
import { actions as dataActions } from "./data/actions"
import * as auth from "./auth"
import { actions as authActions, NavigateActionName } from "./auth/actions"
import * as importing from "./importing"
import * as ui from "./ui"
import { actions as uiActions } from "./ui/actions"
import * as cloud from "./cloud"
import { actions as cloudActions } from "./cloud/actions"
import * as async from "./async"
import { actions as asyncActions } from "./async/actions"
import { getAuthMiddleware } from "./middlewares/authMiddleware"
import * as authRules from "./authRules"
import { ContextReducer, combineContextReducers } from "./combineContextReducers"
import { isLoggedIn } from "../models/LoginStatus"
import { subscribeOnPublicActionResults } from "./data/subscriptions"
import { getCurrentRadarId } from "../models/LocationType"
import { isFetched } from "../../functions/src/utils/types"
import { RootState, RootContext } from "./store"
import { keys } from "../../functions/src/utils/map"

export type TStore = Store<RootState>
export type ExtContextReducer<TState, A extends TypedAction = TypedAction> = ContextReducer<LocationState, TState, A>

let _history: ReturnType<typeof createBrowserHistory> = null as any

export const getHistory = () => {
    if (!_history) _history = createBrowserHistory()
    return _history
}

export const pushHistory = (path: string) => getHistory().push(path)
export const replaceHistory = (path: string) => getHistory().replace(path)
export const goBackHistory = () => getHistory().goBack()
export const redirect = (path: string) => window.location.assign(path)

let _store: TStore | null = null
type State = OmitStrict<RootState, "router">
export const initialRootState: State = {
    ui: ui.initialState,
    auth: auth.initialState,
    data: data.initialState,
    importing: importing.initialState,
    async: async.initialState,
    cloud: cloud.initialState
}
const { __REDUX_DEVTOOLS_EXTENSION__: installDevTools = () => (f: any) => f } = window as any

const getSecureKey = (state: RootState, radarId: string | null): EncryptionKey | null => {
    const asyncSecureKey = state.data[radarId || ""]?.secureKeys
    if (!asyncSecureKey) return null
    return isFetched(asyncSecureKey) ? asyncSecureKey.value.key : null
}

export const getContext = (state: RootState): RootContext => {
    const userId = isLoggedIn(state.auth.authentication) ? state.auth.authentication.user.userId! : null
    const authorizationType = isLoggedIn(state.auth.authentication) ? state.auth.authentication.authorization : null
    const radarId = getCurrentRadarId(state.auth)
    return {
        ...state.auth.params,
        userId,
        secureKey: getSecureKey(state, radarId),
        permissions: state.auth.permissions,
        authorizationType,
        ui: state.ui
    }
}

const initStore = (): TStore =>
    createStore(
        combineContextReducers<RootState, RootContext>(
            {
                ui: ui.reducer,
                auth: auth.reducer,
                data: data.reducer,
                importing: importing.reducer,
                cloud: cloud.reducer,
                async: async.reducer,
                router: connectRouter(getHistory())
            },
            getContext
        ),
        initialRootState as RootState,
        compose(
            installReduxLoop({ DONT_LOG_ERRORS_ON_HANDLED_FAILURES: process.env.NODE_ENV === "production" }),
            applyMiddleware(
                routerMiddleware(getHistory()),
                getAuthMiddleware(
                    authRules.getConfig(
                        [
                            NavigateActionName,
                            ...keys(dataActions),
                            ...keys(uiActions),
                            ...keys(cloudActions),
                            ...keys(asyncActions)
                        ],
                        authActions[NavigateActionName]
                    )
                )
            ),
            installDevTools()
        )
    )

export const getStore = () => {
    if (!_store) {
        _store = initStore()
        _store.dispatch(authActions.fetchConfigs())
        subscribeOnPublicActionResults()
    }
    return _store
}
