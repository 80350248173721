import * as React from "react"
import { assertNever } from "../../../functions/src/utils"
import { _HorizontalSpace, _Center, Flex, _Spacer } from "../../styles/common"
import { _Badge } from "../../components/common/Badge"
import { TabText, TabRenderable, Tab } from "../../components/tabs/Tabs"
import { DetailsCardView } from "../cards/CardDetails"
import { SectorPositioningCardView } from "./CardSectorPositioning"
import { CommentsCardView } from "./CardComments"
import { CompaniesCardView } from "./CardCompanies"
import { ContactedLeadsCardView } from "./CardLeads"
import { StartupsCardView } from "./CardStartups"
import { RelationsCardView } from "./CardRelations"
import { AttachmentsCardView } from "./CardAttachments"
import { CardPrioritization } from "./CardPrioritization"

export type AllTabTypes =
    | "details"
    | "comments"
    | "relations"
    | "attachments"
    | "contactedLeads"
    | "startups"
    | "companies"
    | "relativePositioning"
    | "prioritization"
    | "partnershipProfile"

const TitleWithCount = (title: string, count = 0) => (
    <Flex align="center">
        <>{title}</>
        <_HorizontalSpace base="6px" />
        <_Badge color="theme16" size={20}>
            {count}
        </_Badge>
    </Flex>
)

export const buildTab = (id: AllTabTypes, props: any): Tab<AllTabTypes> => {
    switch (id) {
        case "details":
            return {
                id,
                title: TabText("Snapshot"),
                content: TabRenderable(() => <DetailsCardView {...props} />)
            }
        case "relativePositioning":
            return {
                id,
                title: TabText("Relative positioning"),
                content: TabRenderable(() => <SectorPositioningCardView {...props} />)
            }
        case "comments":
            return {
                id,
                title: TabRenderable(() => TitleWithCount("Comments", props.commentsLength)),
                content: TabRenderable(() => <CommentsCardView {...props} />)
            }
        case "relations":
            return {
                id,
                title: TabText("Relationships"),
                content: TabRenderable(() => <RelationsCardView {...props} />)
            }
        case "attachments":
            return {
                id,
                title: TabRenderable(() => TitleWithCount("Attachments", props.attachmentsLength)),
                content: TabRenderable(() => <AttachmentsCardView {...props} />)
            }
        case "contactedLeads":
            return {
                id,
                title: TabRenderable(() => TitleWithCount("Contacted Leads", props.contactedLeadsLength)),
                content: TabRenderable(() => <ContactedLeadsCardView {...props} />)
            }
        case "startups":
            return {
                id,
                title: TabText("Startups"),
                content: TabRenderable(() => <StartupsCardView {...props} />)
            }
        case "companies":
            return {
                id,
                title: TabText("Product & Technologies"),
                content: TabRenderable(() => <CompaniesCardView {...props} />)
            }
        case "prioritization":
            return {
                id,
                title: TabText("Prioritization"),
                content: TabRenderable(() => <CardPrioritization {...props} />)
            }
        case "partnershipProfile":
            return {
                id,
                title: TabText("Partnership Profile"),
                content: TabRenderable(() => <h1>PLACEHOLDER for Partnership card</h1>)
            }
    }
    assertNever(id)
}
