import styled from "styled-components"

export const _StatusBar = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 16px;
    height: 60px;

    background: ${({ theme }) => theme.colors.theme1};
`
