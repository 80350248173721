import * as React from "react"
import { Button } from "./phantomButtons"
import { isNothing } from "../../functions/src/utils/types"
import styled, { css, DefaultTheme } from "styled-components"
import { IconSvg } from "../components/IconSvg"
import { assertNever } from "../../functions/src/utils"
import { _HorizontalSpace } from "./common"
import { _IconSvgContainer } from "../components/IconSvg.styles"

const getSize = ({ size }: Button) => {
    if (isNothing(size)) return ""
    switch (size.value) {
        case "unset":
            return ""
        case "stretch":
            return css`
                width: 100%;
            `
    }
    assertNever(size.value)
}
const getFontSize = ({ fontSize }: Button) => {
    if (isNothing(fontSize))
        return css`
            font-size: 14px;
        `
    return css`
        font-size: ${fontSize.value}px;
    `
}

const getBackground = ({ btnColor, theme }: Button & { theme: DefaultTheme }) => {
    if (isNothing(btnColor)) return ""
    return css`
        background-color: ${theme.colors[btnColor.value]};
    `
}
const getHoverColor = ({ hoverColor, theme }: Button & { theme: DefaultTheme }) => {
    if (isNothing(hoverColor)) return ""
    return css`
        &:hover {
            border: 1px solid ${theme.colors[hoverColor.value]};
        }
    `
}

const getIconSize = ({ iconSize }: Button) => {
    if (isNothing(iconSize)) return ""
    return css`
        ${_IconSvgContainer} {
            width: ${iconSize.value.width}px;
            height: ${iconSize.value.height}px;
        }
    `
}
const getColor = ({ fontColor, theme }: Button & { theme: DefaultTheme }) => {
    if (isNothing(fontColor)) return ""
    return css`
        color: ${theme.colors[fontColor.value]};
    `
}

const getTextDecoration = ({ textDecoration }: Button) => {
    if (isNothing(textDecoration)) return ""
    switch (textDecoration.value) {
        case "bold":
            return css`
                font-style: bold;
            `
        case "italic":
            return css`
                font-style: italic;
            `
        case "underline":
            return css`
                text-decoration: underline;
            `
    }
}

const getBorder = ({ border }: Button) => {
    if (isNothing(border)) return

    switch (border.value) {
        case "primary":
            return css`
                border: solid 1px rgba(0, 0, 0, 0.05);
            `
        case "borderless":
            return css`
                border: none;
            `
    }
}

const StyledButton = styled.button<Button>`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    letter-spacing: 0.3px;
    min-width: 72px;
    padding: 10px 12px;
    color: #fff;
    border-radius: 4px;
    outline: none;
    cursor: pointer;

    ${getBorder}
    ${getSize}
    ${getFontSize}
    ${getBackground}
    ${getIconSize}
    ${getColor}
    ${getHoverColor}
    ${getTextDecoration}
`
const ButtonText = styled.div``

export const PhantomButton = (config: Button) => {
    const renderContent = ({ content, text }: Button) => {
        if (isNothing(content)) return text
        switch (content.value.type) {
            case "Icon":
                return <IconSvg name={content.value.icon} />
            case "Text&Icon":
                return (
                    <>
                        <IconSvg name={content.value.icon} />
                        <_HorizontalSpace base="8px" />
                        <ButtonText>{content.value.text}</ButtonText>
                    </>
                )
            case "Text":
                return <ButtonText>{content.value.text}</ButtonText>
            case "ReactNode":
                return content.value.value
        }
    }
    return <StyledButton {...config}>{renderContent(config)}</StyledButton>
}
