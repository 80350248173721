import * as React from "react"

import {
    getCells,
    drawMap,
    getExtCells,
    updateMap,
    zoomInMap,
    zoomOutMap,
    containerSize,
    attachOnClick
} from "../views/SectorsMapController"
import { SectorVM } from "../../functions/src/models/ViewModels"

type Props = {
    sectors: SectorVM[]
    searchAreasBySectorId: TMap<ObjectId, SearchArea[]>
    tagNamesByObjectId: SMap<string[]>
    columns?: number
    rows?: number

    zoom: number
    isDragging: boolean
    indicatorsVisible: boolean

    selectedTags: string[]
    searchAreasFilter: SMap<boolean>
    onClick: F1<string>
}

export const findBoundaries = (sectors: SectorVM[]): { columns: number; rows: number } => {
    return sectors.reduce(
        (accu, fields) => ({
            ...accu,
            rows: Math.min(30, fields.row > accu.rows ? fields.row : accu.rows),
            columns: Math.min(30, fields.column > accu.columns ? fields.column : accu.columns)
        }),
        {
            columns: 0,
            rows: 0
        }
    )
}

export const HexMap: React.FC<Props> = ({
    sectors,
    selectedTags,
    searchAreasFilter,
    zoom,
    onClick,
    isDragging,
    searchAreasBySectorId,
    tagNamesByObjectId,
    ...p
}) => {
    // TODO check if it's working as intended & eventually fix this
    const map = React.createRef<SVGSVGElement>() as any

    const boundaries: CRSize = !p.columns || !p.rows ? findBoundaries(sectors) : { columns: p.columns, rows: p.rows }
    const { width, height } = containerSize(boundaries)
    React.useEffect(
        () =>
            drawMap(
                map.current,
                getCells(boundaries, searchAreasBySectorId, tagNamesByObjectId, sectors),
                p.indicatorsVisible
            ),
        [map.current] // eslint-disable-line react-hooks/exhaustive-deps
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => attachOnClick(onClick), [onClick, map.current, sectors, isDragging])

    React.useEffect(
        () =>
            updateMap(
                getExtCells(getCells(boundaries, searchAreasBySectorId, tagNamesByObjectId, sectors), {
                    selectedTags,
                    searchAreasFilter
                })
            ),
        [map.current, sectors, searchAreasFilter, selectedTags] // eslint-disable-line react-hooks/exhaustive-deps
    )

    React.useEffect(() => {
        const cell = getExtCells(getCells(boundaries, searchAreasBySectorId, tagNamesByObjectId, sectors), {
            selectedTags,
            searchAreasFilter
        })
        if (zoom === 1) zoomOutMap(zoom)
        else zoomInMap(zoom, cell)
    }, [zoom]) // eslint-disable-line react-hooks/exhaustive-deps

    return <svg width={width * zoom} height={height * zoom} ref={map} className="hex-map" />
}
