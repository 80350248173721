import styled from "styled-components"
import { ThemeColors } from "../../styles/styled"
import { styleFromProp } from "../../styles/common"

export const Bubble = styled.div<{ color: keyof ThemeColors; width?: number }>`
    position: relative;
    background: ${p => p.theme.colors[p.color]};
    border-radius: 4px;
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 9px;
    padding: 2px 5px;
    ${p => styleFromProp(p.width, `min-width: ${p.width}px;`)}
    margin-bottom: 2px;

    &:after {
        content: "";
        position: absolute;
        bottom: -4px;
        left: 3px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 7px 0 0;
        border-color: ${p => p.theme.colors[p.color]} transparent transparent transparent;
    }
`
