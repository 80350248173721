import * as React from "react"
import { _AbsPosElement, _VerticalSpace } from "../../styles/common"
import { Table } from "../../components/table/TableView"
import { mkRow, HeaderTitle } from "../../components/table/TableViewController"
import { schemas } from "../lists/ListSchemas"
import { getDefinitionForKey } from "../lists/definitions"
import { StartupVM, CompanyVM, StartupDisplayDelta } from "../../../functions/src/models/ViewModels"
import {
    _CardSectionContainer,
    _CardSectionTitle,
    _CardSectionTableContainer
} from "../../components/newCards/sections/CardSection.styles"

export const byStartupFunding = (a: StartupVM, b: StartupVM) => {
    if (!a.funding) return 1
    if (!b.funding) return -1
    return b.funding - a.funding
}

export const StartupDetails: React.FC<{
    startups: Extended<StartupVM, StartupDisplayDelta>[]
    onItemClick: F1<ObjectId>
    isDemo: boolean
}> = p => {
    const rowSchema = schemas.startups.schema
    const rows = p.startups.map(item =>
        mkRow(
            item.startupId,
            rowSchema,
            item as StartupVM,
            p.isDemo && item.blurOnListing ? ["blurred", "disabled"] : []
        )
    )
    return (
        <_CardSectionContainer big>
            <_CardSectionTitle>Sector Startups</_CardSectionTitle>
            <_VerticalSpace base="15px" />
            <_CardSectionTableContainer>
                <_AbsPosElement>
                    <Table
                        headerRow={rowSchema.map(v =>
                            HeaderTitle(
                                v.key,
                                v.label,
                                v.size,
                                v.key as any,
                                getDefinitionForKey("startups", v.key as any)
                            )
                        )}
                        showTooltip
                        rows={rows}
                        onRowClick={(startupId: ObjectId) => p.onItemClick(startupId)}
                        virtualized
                    />
                </_AbsPosElement>
            </_CardSectionTableContainer>
        </_CardSectionContainer>
    )
}

export const CompanyDetails: React.FC<{ companies: CompanyVM[]; onItemClick: F1<ObjectId>; isDemo: boolean }> = p => {
    const rowSchema = schemas.companies.schema
    const rows = p.companies.map(item =>
        mkRow(item.companyId, rowSchema, item, p.isDemo && item.blurOnListing ? ["blurred", "disabled"] : [])
    )
    return (
        <_CardSectionContainer big>
            <_CardSectionTitle>Sector Products &amp; Technologies</_CardSectionTitle>
            <_VerticalSpace base="15px" />
            <_CardSectionTableContainer>
                <_AbsPosElement>
                    <Table
                        headerRow={rowSchema.map(v =>
                            HeaderTitle(
                                v.key,
                                v.label,
                                v.size,
                                v.key as any,
                                getDefinitionForKey("companies", v.key as any)
                            )
                        )}
                        showTooltip
                        rows={rows}
                        virtualized
                        onRowClick={(objectId: ObjectId) => p.onItemClick(objectId)}
                    />
                </_AbsPosElement>
            </_CardSectionTableContainer>
        </_CardSectionContainer>
    )
}
