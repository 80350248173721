import * as React from "react"
import { RadarCommentsProps, RadarCommentsActions, RadarItemComments } from "../../components/comments/Comment"
import { actions } from "../../store/cloud/actions"
import { connect } from "react-redux"
import { _CardTitle } from "../../components/newCards/Card.styles"
import { _TagFont } from "../../styles/typography"
import { _VerticalSpace, _FlexRow, _HorizontalSpace } from "../../styles/common"
import { Loaded, toOption, LoadingWithPayload } from "../../../functions/src/utils/types"
import { LoadableView, Join } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import {
    Filter,
    CheckboxFilterProps,
    FilterProps,
    FilterActions,
    RadioFilterProps
} from "../../components/filters/Filter"
import { toggleArray, assertNever } from "../../../functions/src/utils"
import { keys, extend } from "../../../functions/src/utils/map"
import { isEmpty } from "../../../functions/src/utils/validators"
import { getSearchAreasForObject } from "../../../functions/src/models/searchAreas"
import { getDefaultSearchAreaFilter } from "../../models/collections"
import { isLoggedIn } from "../../models/LoginStatus"
import { getPipelineStageValues, NO_DECORATOR } from "../../../functions/src/models/decoratorValues"
import { getDataByPath } from "../../store/data/dataSelectors"
import { getCollectionDetailsPathName } from "../../paths"
import { ViewModelsBase, CommentsVM } from "../../../functions/src/models/ViewModels"
import { RootState } from "../../store/store"
import { MapDispatch } from "../../utils/redux.types"
import { omitContactedLeads } from "../../../functions/src/models/contactedLeads"
import { _CardCommentsHeader } from "../../components/comments/comment.styles"
import { _CardSectionContainer, _CardSectionTitle } from "../../components/newCards/sections/CardSection.styles"
import { getCollectionFromId } from "../../../functions/src/models/schemas"

type CommentMetaType = OmitStringUnion<Type<CommentMeta>, "contactedLead">
const typeFilter: Record<CommentMetaType | "noMetaComment", string> = {
    noMetaComment: "Comment",
    assignment: "Assignment",
    pipeline: "Pipeline stage"
}
type TypeFilter = keyof typeof typeFilter
type CommentFilters = { commentType: TypeFilter[]; searchAreaId: string | null; pipelineType: string[] }
const emptyFilters: CommentFilters = { commentType: [], searchAreaId: null, pipelineType: [] }

const byNoMeta = (c: ObjectComment) => c.meta === null
const byCommentType = ({ commentType: type }: CommentFilters) => (comment: ObjectComment) =>
    comment.meta
        ? type.includes(comment.meta.type as CommentMetaType)
        : type.includes("noMetaComment")
        ? byNoMeta(comment)
        : false

const bySearchArea = ({ searchAreaId: searchArea }: CommentFilters) => (comment: ObjectComment) =>
    comment.meta?.type === "pipeline" ? searchArea === comment.meta.value.searchAreaId : searchArea === null
const byPipelineType = ({ pipelineType: type }: CommentFilters) => (comment: ObjectComment) =>
    comment.meta?.type === "pipeline" ? type.includes(comment.meta?.value.newValue || NO_DECORATOR) : false

const filterComments = (comments: ObjectComment[], filters: CommentFilters) => {
    const filterConditions: F1<ObjectComment, boolean>[] = []
    if (!isEmpty(filters.commentType)) filterConditions.push(byCommentType(filters))
    if (!isEmpty(filters.searchAreaId)) filterConditions.push(bySearchArea(filters))
    if (!isEmpty(filters.pipelineType)) filterConditions.push(byPipelineType(filters))

    return filterConditions.reduce((cs, f) => cs.filter(f), comments)
}

type CommentFilterType = ValueState<keyof CommentFilters, FilterProps>
type CommentFiltersProps = { onFilterChange: F1<CommentFiltersAction>; filterOptions: CommentFilterType[] }
type CommentFilterChange<T extends keyof CommentFilters> = { type: T; value: ArrayItem<CommentFilters[T]> }
type CommentFiltersAction =
    | CommentFilterChange<"commentType">
    | CommentFilterChange<"searchAreaId">
    | CommentFilterChange<"pipelineType">

const onClearNoop = (_key: any) => () => {
    /* Explicitly do nothing */
}

const CommentFiltersBar: React.FC<CommentFiltersProps> = p => {
    const onChange = (filterType: Type<CommentFilterType>): FilterActions["onChange"] => onChangePayload => {
        if (onChangePayload.type === "dateRange") return

        p.onFilterChange({ type: filterType, value: onChangePayload.v } as CommentFiltersAction)
    }
    return (
        <_FlexRow alignCenter>
            <_TagFont color="theme10" fontSize={12}>
                Filter
            </_TagFont>
            <_HorizontalSpace base="25px" />

            <Join items={p.filterOptions} renderJoining={() => <_HorizontalSpace base="25px" />}>
                {({ type, value }) => <Filter key={type} {...value} onChange={onChange(type)} onClear={onClearNoop} />}
            </Join>
        </_FlexRow>
    )
}

const filterOptions = (
    searchAreas: TMap<AreaId, SearchArea>,
    pipelines: StateProps["pipelines"],
    cname: CName | undefined,
    { commentType, searchAreaId, pipelineType }: CommentFilters
): CommentFilterType[] => {
    const psvs = getPipelineStageValues(pipelines, cname)
    return [
        {
            type: "commentType",
            value: CheckboxFilterProps({
                options: keys(typeFilter).map(id => toOption(typeFilter[id], id)),
                buttonTextSelected: "Selected comment type",
                buttonText: "All Comments",
                title: "Filter by comment type",
                selected: commentType.map(id => toOption(typeFilter[id], id))
            })
        },
        {
            type: "searchAreaId",
            value: RadioFilterProps({
                allOptionsText: getDefaultSearchAreaFilter(),
                options: keys(searchAreas).map(id => toOption(searchAreas[id].name, id)),
                selected: searchAreaId
                    ? [toOption(searchAreas[searchAreaId]?.name || "unrecognized", searchAreaId)]
                    : []
            })
        },
        {
            type: "pipelineType",
            value: CheckboxFilterProps({
                options: psvs.map(v => toOption(v.label, v.value)),
                buttonText: "All pipelines",
                buttonTextSelected: "Selected pipeline types",
                title: "Filter by pipeline stage",
                selected: psvs.filter(o => pipelineType.includes(o.value)),
                isHidden: !commentType.includes("pipeline")
            })
        }
    ]
}

const reduceFilters: React.Reducer<CommentFilters, CommentFiltersAction> = (state, action) => {
    const ext = extend(state)
    switch (action.type) {
        case "pipelineType":
            return ext({ pipelineType: toggleArray(state.pipelineType, action.value) })
        case "commentType":
            return ext({ commentType: toggleArray(state.commentType, action.value) })
        case "searchAreaId":
            return ext({ searchAreaId: action.value })
    }
    assertNever(action)
}

type StateProps = Pick<RadarCommentsProps, "actionsResults" | "auth" | "comments"> &
    Pick<ViewModelsBase, "pipelines"> & {
        availableSearchAreas: TMap<AreaId, SearchArea>
    }
type ActionProps = RadarCommentsActions

export type CardCommentProps = Pick<RadarCommentsProps, "objectId" | "radarId"> & { cname: CName }
type Props = StateProps & ActionProps & CardCommentProps

const CardComments: React.FC<Props> = p => {
    const [filters, setFilters] = React.useReducer(reduceFilters, emptyFilters)
    const [filteredComments, setFilteredComments] = React.useState<CommentsVM>([...p.comments])

    React.useEffect(() => {
        setFilteredComments(filterComments(p.comments, filters))
    }, [filters, p.comments])

    return (
        <_CardSectionContainer big>
            <_CardCommentsHeader>
                <_CardTitle>
                    <_CardSectionTitle>Comments</_CardSectionTitle>
                </_CardTitle>
                <CommentFiltersBar
                    filterOptions={filterOptions(
                        p.availableSearchAreas,
                        p.pipelines,
                        getCollectionFromId(p.objectId) || undefined,
                        filters
                    )}
                    onFilterChange={setFilters}
                />
            </_CardCommentsHeader>
            <_VerticalSpace base="16px" />
            <RadarItemComments {...p} comments={filteredComments} />
        </_CardSectionContainer>
    )
}
const mapState = (st: RootState, op: CardCommentProps) =>
    getDataByPath<StateProps, CardCommentProps>()(
        getCollectionDetailsPathName(op.cname),
        (
            { comments, searchAreas, searchAreasAssignments, pipelines },
            { cloud: { actionsResults }, auth },
            { objectId }
        ) => {
            if (!isLoggedIn(auth.authentication)) return LoadingWithPayload({ stateDescription: "Authenticating" })
            return Loaded({
                actionsResults,
                comments: omitContactedLeads(comments[objectId] || []),
                auth: auth.authentication,
                availableSearchAreas: getSearchAreasForObject(searchAreas, searchAreasAssignments[objectId]),
                pipelines
            })
        }
    )(st, op)

const mapDispatch: MapDispatch<ActionProps> = d => ({
    onCreate: payload => d(actions.createComment(payload)),
    onEdit: payload => d(actions.editComment(payload)),
    onDelete: payload => d(actions.deleteComment(payload))
})

export const CommentsCardView = connect(mapState, mapDispatch)(LoadableView(ViewLoader, CardComments))
