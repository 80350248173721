import * as React from "react"
import { _h1 } from "../../styles/typography"
import { _VerticalSpace, _HorizontalSpace, _Spacer, Flex } from "../../styles/common"
import { Popup } from "../Popup"
import { PopupInner } from "../Popup.styles"
import { _Input } from "../form/Input.styles"
import { ErrorButton, SecondaryButton } from "../../styles/buttons"
import styled from "styled-components"

// TODO: {customText: string } | {objectName: string; objectType: string}
export type DeleteConfirmationPopupMeta = {
    objectType: string
    objectName: string
    extraConfirmation?: boolean
    customText?: React.ReactNode
    onDelete: F0
}
type OwnProps = { onClose: F0; meta: DeleteConfirmationPopupMeta }

const _PopupText = styled.span`
    font-size: 16px;
    line-height: 30px;
`

export const DeleteConfirmationPopup: React.FC<OwnProps> = p => {
    const [value, setValue] = React.useState("")
    const onDeleteClick = () => {
        p.meta.onDelete()
        p.onClose()
    }
    return (
        <Popup logo onClose={p.onClose}>
            <PopupInner column>
                <_h1>Delete {p.meta.objectType}?</_h1>
                <_VerticalSpace base="16px" />
                {p.meta.extraConfirmation ? (
                    <>
                        <_PopupText>
                            {p.meta.customText || (
                                <>
                                    Confirm you want to delete {p.meta.objectType} by typing its name:
                                    <_HorizontalSpace base="4px" />
                                    <b>{p.meta.objectName}</b>
                                </>
                            )}
                        </_PopupText>
                        <_VerticalSpace base="16px" />
                        <_Input
                            placeholder={p.meta.objectName}
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        />
                    </>
                ) : (
                    <_PopupText>
                        {p.meta.customText || (
                            <>
                                Are you sure you want to delete
                                <_HorizontalSpace base="4px" />
                                <b style={{ color: "red" }}>{p.meta.objectName}</b>
                                <_HorizontalSpace base="4px" />
                                <span>{p.meta.objectType}</span>?
                            </>
                        )}
                    </_PopupText>
                )}
                <_VerticalSpace base="30px" />
                <Flex>
                    <ErrorButton
                        disabled={p.meta.extraConfirmation && value !== p.meta.objectName}
                        data-cy="confirm-object-delete"
                        onClick={onDeleteClick}>
                        Yes, delete
                    </ErrorButton>
                    <_Spacer />
                    <SecondaryButton onClick={p.onClose}>Cancel</SecondaryButton>
                </Flex>
            </PopupInner>
        </Popup>
    )
}
