import React from "react"
import { _BodyFont, _TagFont } from "../../../styles/typography"
import { _VerticalSpace, _AbsPosElement, Flex, _HorizontalSpace } from "../../../styles/common"
import { _Arrow } from "../../../styles/common"
import { kebabCase } from "lodash"
import { loadPrioritizationFrom } from "../../../models/prioritization"
import { isErr } from "../../../../functions/src/utils/validators"
import { createGridLayout, ColumnHierarchy, estimateWidth } from "./helpers"
import { _RowScrollingFade } from "../../../components/table/TableView.styles"

// TODO: move types to models/prioritization
type RankPoint = "*HIGH" | "*MEDIUM" | "*LOW"
type DataRow = (RankPoint | string)[]
const circleSvg = (
    <svg className="circle" width="9" height="9" viewBox="0 0 9 9">
        <circle cx="4.5" cy="4.5" r="4" />
    </svg>
)

const dotLabels = {
    "*HIGH": "High",
    "*MEDIUM": "Medium",
    "*LOW": "Low"
}

const dataColumn = (
    col: RankPoint | string,
    extraClass: string,
    key: string,
    props: Record<string, any>
): React.ReactNode => {
    switch (col) {
        case "*HIGH":
        case "*MEDIUM":
        case "*LOW":
            const label = dotLabels[col]
            const rank = col.slice(1).toLowerCase()
            return (
                <_BodyFont className={`col dataCol rank-${rank} ${extraClass}`} key={key} {...props}>
                    {circleSvg}
                    {label}
                </_BodyFont>
            )
        default:
            return (
                <_BodyFont className={`col dataCol ${extraClass}`} key={key} {...props}>
                    {col}
                </_BodyFont>
            )
    }
}

const renderHeaders = (hierarchy: ColumnHierarchy): React.ReactNode => {
    const keys = Object.keys(hierarchy)

    const tds = keys.map((key, index) => {
        const nextColumn = keys[index + 1] || "end"
        const style = {
            gridColumn: `${kebabCase(key)} / ${kebabCase(nextColumn)}`
        }
        return (
            <div className="col headCol" style={style} key={`head - ${kebabCase(key)}`}>
                <_BodyFont s14 align="center">
                    {key}
                </_BodyFont>
            </div>
        )
    })

    return <>{tds}</>
}

const renderCategories = (
    hierarchy: ColumnHierarchy,
    sortedBy: string | undefined,
    toggleSort: F1<string>,
    alternate: boolean
): React.ReactNode => {
    const [sortKey, sortDir] = (sortedBy || "").split(":")
    const arrowDir = sortDir == "asc" ? "up" : "down"
    const onClickSort = (name: string) => () => toggleSort(kebabCase(name))
    const colClass = `col groupCol ${!alternate && "tintedCol"}`

    const tds = Object.values(hierarchy).flatMap(names => {
        return names.map(name => (
            <div className={colClass} key={`cat-${kebabCase(name)}`}>
                <Flex align="center">
                    <_BodyFont bold align="center" onClick={onClickSort(name)}>
                        {name}
                    </_BodyFont>
                    <_HorizontalSpace base="8px" />
                    {!alternate &&
                        (sortKey === kebabCase(name) ? (
                            <_Arrow color="primary" direction={arrowDir} onClick={onClickSort(name)} />
                        ) : (
                            <_Arrow direction="right" color="theme14" onClick={onClickSort(name)} />
                        ))}
                </Flex>
            </div>
        ))
    })

    return (
        <>
            {!alternate && (
                <div className="col groupCol round-tl sticky-left tintedCol" key="cat-startupname">
                    <_BodyFont bold>Startup Name</_BodyFont>
                </div>
            )}
            {tds}
        </>
    )
}
const rowComparator = (keyIndex: number, order: string) => {
    const HML = ["*LOW", "*MEDIUM", "*HIGH"]
    const mul = order == "desc" ? -1 : 1

    return (left: DataRow, right: DataRow) => {
        const a = left[keyIndex]
        const b = right[keyIndex]

        if (HML.indexOf(a) != -1 && HML.indexOf(b) != -1) return mul * (HML.indexOf(a) - HML.indexOf(b))
        else return mul * (a < b ? -1 : a == b ? 0 : 1)
    }
}

const renderDataRows = (
    dataRows: DataRow[],
    sortBy: string,
    sortMap: Record<string, number>,
    onClickName: F1<string>,
    alternate: boolean
): React.ReactNode => {
    const [sortKey, sortOrder] = sortBy.split(":")
    if (sortKey != "") dataRows.sort(rowComparator(sortMap[sortKey], sortOrder))
    const columnClass = alternate ? "center" : "center tall"

    const rows = dataRows.map((row, rownum) => {
        const [name, url, ...rest] = row
        return (
            <>
                {!alternate &&
                    dataColumn(name, "left sticky-left", `data-${rownum}-name`, { onClick: () => onClickName(url) })}
                {rest.map((col, colnum) => dataColumn(col, columnClass, `data-${rownum}-col${colnum}`, {}))}
            </>
        )
    })
    return <>{rows}</>
}

type Props = {
    className?: string
    csv: Array<string>
    navigateToStartup: F1<string>
    alternate?: boolean
}

export const GridTable = (p: Props) => {
    const result = loadPrioritizationFrom(p.csv)
    if (isErr(result)) return <></>

    const alternate = !!p.alternate

    const headers = result.value.hierarchy
    const prioritization = result.value.data
    const columns = createGridLayout(headers, alternate)
    // On wider displays the table will stretch itself to fill available space.
    // On narrower ones, a minimum width will force scrolling
    const minWidth = estimateWidth(headers, alternate)

    const [sortBy, setSortOrder] = React.useState("")
    const toggleSort = (key: string) => {
        const [currentKey, currentOrder] = sortBy.split(":")
        if (key != currentKey) setSortOrder(`${key}:asc`)
        else if (currentOrder == "asc") setSortOrder(`${key}:desc`)
        else setSortOrder(`${key}:asc`)
    }

    const sortKeys = Object.values(headers).flatMap(names => names.map(kebabCase))
    // Offset by 2 to account for the names and crunchbase columns.
    const sortMap = Object.fromEntries(sortKeys.map((name, index) => [name, index + 2]))

    const onClickName = (url: string) => p.navigateToStartup(url)

    return (
        <div className={p.className} style={{ gridTemplateColumns: columns, minWidth: minWidth }}>
            {renderHeaders(headers)}
            {renderCategories(headers, sortBy, toggleSort, alternate)}
            {renderDataRows(prioritization, sortBy, sortMap, onClickName, alternate)}
        </div>
    )
}
