/* eslint-disable max-lines */
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./TableView.types.ts"/>
import * as React from "react"

import { Tooltip } from "../tooltip/Tooltip"
import { VirtualizedTable } from "../common/Virtualized"
import { _BodyFont } from "../../styles/typography"
import { useTooltipController, UseTooltipControllerData } from "../tooltip/TooltipController"
import { Flex } from "../../styles/common"
import { focusWithoutScrolling, getOnScreenPosition } from "../../utils/html5"
import { toggleArray } from "../../../functions/src/utils"
import { isHeaderCell, isValueCell, CellContext, ValueCellMeta, CellMeta } from "./TableViewCell"
import { isHeaderRow, isValueRow, RowContext, HeaderRowView, ValueRowView, RowMeta } from "./TableViewRow"
import { TooltipDefinition } from "../tooltip/SectionController"
import { toOption } from "../../../functions/src/utils/types"
import { Loader } from "../common/Loader"
import {
    _NoRowsRow,
    _TableBody,
    _TableHead,
    _TableView,
    _TablePinnedRow,
    _TableStickyRowsContainer
} from "./TableView.styles"
import { CellSize, Row, RowEffect, SpecialCellIds, TableProps } from "./TableView.types"
import { isEmpty } from "../../../functions/src/utils/validators"
import { PinnedColumns, usePinnedColumns } from "./utils/pinnedColumns"
import { decoratorCellSizes } from "../../models/collections"
import { flatten } from "../../../functions/src/utils/map"
import { useScrollPositionClassNameHandler } from "./utils/useScrollPositionClassNameHandler"

export const DEFAULT_ROW_HEIGHT = 71
export const TABLE_HEADER_HEIGHT = 56

export type RowInteractionProps = {
    isSelectable?: boolean
    showTooltip?: boolean
    selectedRows?: React.ReactText[]
    onRowSelect?: F1<React.ReactText[]>
    onRowClick?: F2<React.ReactText, React.SyntheticEvent>
    onCellContentClick?: F2<ValueCellMeta<string>, React.MouseEvent>
    interactiveOptions?: F1<React.ReactText, ROption<F1<React.ReactText>>[]> | ROption<F1<React.ReactText>>[]
}

type VirtualizationProps = {
    rowHeight: number
    virtualized?: boolean
    itemsCount?: number
}

const renderRow = <ID extends string>(
    rowCtx: RowContext,
    cellCtx: CellContext<ID>,
    r: Row<ID>,
    index: number,
    style?: React.CSSProperties
) => (
    <ValueRowView
        key={r.id || `Table-Row${index}`}
        {...r}
        index={index}
        style={style}
        data-cy="table-row"
        ctx={rowCtx}
        cellCtx={cellCtx}
    />
)

const renderEmpty = () => null as any

export type ExtTableProps<ID extends string> = TableProps<ID> &
    RowInteractionProps &
    Optionalize<VirtualizationProps, "rowHeight"> & {
        onSort?: F1<string>
        limit?: number
        onReachBottom?: F0
        smallLoader?: boolean
        headerRowEffects?: RowEffect[]
        pinnedRow?: number
    } & {
        autofocus?: boolean
    }

type UseRowContextProps<ID extends string> = {
    rows: Row<ID>[]
    selectedRows: any
    setSelectedRows: F1<any>
    containerRef: HTMLElement | null
    shouldShowTooltip?: boolean
} & Pick<
    ExtTableProps<ID>,
    "virtualized" | "onRowClick" | "interactiveOptions" | "tooltipContents" | "isSelectable" | "rowHeight"
> &
    Pick<UseTooltipControllerData, "showTooltip" | "hideTooltip">
const useRowContext = <ID extends string>({
    rows,
    selectedRows,
    setSelectedRows,
    virtualized,
    onRowClick,
    tooltipContents,
    containerRef,
    shouldShowTooltip,
    interactiveOptions,
    showTooltip,
    hideTooltip,
    rowHeight = DEFAULT_ROW_HEIGHT,
    isSelectable = false
}: UseRowContextProps<ID>): RowContext => {
    const onSelect = React.useCallback(
        (meta: RowMeta) => {
            const newSelectedRows = isHeaderRow(meta)
                ? selectedRows.length === rows.length
                    ? []
                    : rows.map(r => r.id)
                : toggleArray(selectedRows, meta.id)
            setSelectedRows(newSelectedRows)
        },
        [selectedRows, setSelectedRows, rows]
    )

    const isSelected = React.useCallback(
        (meta: RowMeta) =>
            isValueRow(meta)
                ? selectedRows.includes(meta.id)
                : selectedRows.length !== 0 && selectedRows.length === rows.length,
        [selectedRows, rows]
    )
    const isLast = React.useCallback(
        (meta: RowMeta) =>
            virtualized ? isValueRow(meta) && meta.index > rows.length - Math.min(rows.length - 1, 4) : false,
        [virtualized, rows]
    )

    const onClick = React.useCallback(
        (meta: RowMeta, event: React.MouseEvent) => isValueRow(meta) && onRowClick?.(meta.id, event),
        [onRowClick]
    )
    // TODO Make it the same way as HeaderCell - ref & position
    const onFocus = React.useCallback(
        (meta: RowMeta, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            isValueRow(meta) &&
                shouldShowTooltip &&
                tooltipContents &&
                showTooltip(tooltipContents[meta.id], {
                    // 42 is the width of the first, checkbox-bearing column
                    left: getOnScreenPosition(containerRef).left + 42,
                    top: getOnScreenPosition(e.currentTarget).bottom - 8
                })
        },
        [shouldShowTooltip, tooltipContents, showTooltip, containerRef]
    )
    const getRowOptions = React.useCallback(
        (meta: RowMeta) => {
            if (!isValueRow(meta) || !interactiveOptions) return null
            const options = typeof interactiveOptions === "function" ? interactiveOptions(meta.id) : interactiveOptions
            return options.map(o => toOption(o.label, () => o.value(meta.id)))
        },
        [interactiveOptions]
    )

    return React.useMemo(
        () => ({
            onSelect,
            isSelected,
            isLast,
            onClick,
            onFocus,
            onFocusLost: hideTooltip,
            getRowOptions,
            rowHeight,
            isSelectable
        }),
        [onSelect, isSelected, isLast, onClick, onFocus, hideTooltip, getRowOptions, rowHeight, isSelectable]
    )
}

type UseCellContextProps<ID extends string> = {
    rows: Row<ID>[]
    pinnedColumns: PinnedColumns
} & Pick<ExtTableProps<ID>, "virtualized" | "onSort" | "onCellContentClick" | "sortingColumn" | "sortingAsc"> &
    Pick<UseTooltipControllerData, "showTooltip" | "hideTooltip">
const useCellContext = <ID extends string>({
    rows,
    virtualized,
    onCellContentClick,
    onSort,
    showTooltip,
    hideTooltip,
    sortingAsc,
    sortingColumn,
    pinnedColumns
}: UseCellContextProps<ID>): CellContext<ID> => {
    const onClick = React.useCallback(
        (meta: CellMeta<ID>, event: React.MouseEvent) =>
            isHeaderCell(meta) ? onSort?.(meta.key) : onCellContentClick?.(meta, event),
        [onSort, onCellContentClick]
    )

    const isSortable = React.useCallback((meta: CellMeta<ID>) => isHeaderCell(meta) && onSort, [onSort])

    const isLast = React.useCallback(
        (meta: CellMeta<ID>) =>
            virtualized ? isValueCell(meta) && meta.rowIndex > rows.length - Math.min(rows.length - 1, 4) : false,
        [rows, virtualized]
    )

    const onFocus = React.useCallback(
        (meta: CellMeta<ID>) => {
            if (isHeaderCell(meta) && meta.definition) {
                const pos = meta.getPosition()
                showTooltip([TooltipDefinition(meta.definition)], {
                    left: pos.left - 180,
                    top: pos.bottom + 10
                })
            }
        },
        [showTooltip]
    )

    return React.useMemo(
        () => ({
            onClick,
            isLast,
            onFocus,
            onFocusLost: hideTooltip,
            sortDirection: sortingAsc ? "asc" : "desc",
            sortBy: sortingColumn,
            isSortable,
            pinnedColumns
        }),
        [onClick, isLast, onFocus, hideTooltip, sortingColumn, sortingAsc, pinnedColumns, isSortable]
    )
}

export const Table = <ID extends string>({
    rowHeight = DEFAULT_ROW_HEIGHT,
    virtualized = true,
    onReachBottom,
    onRowSelect,
    autofocus,
    ...p
}: ExtTableProps<ID>) => {
    const [containerRef, setContainerRef] = React.useState<HTMLElement | null>(null)
    const [virtualizedContainerRef, setVirtualizedContainerRef] = React.useState<HTMLElement | null>(null)
    const [selectedRows, setLocalSelectedRows] = React.useState<React.ReactText[]>(p.selectedRows || [])
    React.useEffect(() => {
        if (p.selectedRows) setLocalSelectedRows(p.selectedRows)
    }, [p.selectedRows])
    const setSelectedRows = React.useCallback(
        (r: React.ReactText[]) => {
            onRowSelect?.(r)
            setLocalSelectedRows(r)
        },
        [onRowSelect]
    )

    const rows = React.useMemo(() => (p.limit ? p.rows.slice(0, p.limit) : p.rows), [p.rows, p.limit])
    const columns = React.useMemo(() => {
        const cs = p.headerRow.map(c => ({ id: c.id, size: c.size })) || []
        if (p.isSelectable) cs.unshift({ id: "select" as ID, size: decoratorCellSizes.select })
        if (p.interactiveOptions) cs.push({ id: "menu" as ID, size: decoratorCellSizes.menu })
        return cs
    }, [p.headerRow, p.isSelectable, p.interactiveOptions])
    const minRowWidth = React.useMemo(() => columns.reduce((acc, c) => acc + c.size.min, 0), [columns])
    const shouldVirtualize = virtualized && rows.length > 5
    const isPinnedRow = !isEmpty(p.pinnedRow) && !!rows[p.pinnedRow]
    const scrollingContainerRef = shouldVirtualize ? virtualizedContainerRef : containerRef

    const { pinnedColumns } = usePinnedColumns<ID | SpecialCellIds, { id: ID | SpecialCellIds; size: CellSize }>(
        columns,
        {
            left: flatten<ID | SpecialCellIds>([p.isSelectable ? ["select"] : [], p.pinnedColumns?.left || []]),
            right: flatten<ID | SpecialCellIds>([
                p.interactiveOptions ? ["menu"] : [],
                columns.find(c => c.id === "star") ? ["star" as ID] : [],
                columns.find(c => c.id === "comments") ? ["comments" as ID] : [],
                p.pinnedColumns?.right || []
            ])
        }
    )

    const { isTooltipVisible, tooltipPosition, tooltipItems, showTooltip, hideTooltip } = useTooltipController(
        scrollingContainerRef
    )

    React.useEffect(() => {
        hideTooltip()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows])

    useScrollPositionClassNameHandler(scrollingContainerRef)

    const rowContext = useRowContext({
        ...p,
        rowHeight,
        shouldShowTooltip: p.showTooltip,
        rows,
        selectedRows,
        setSelectedRows,
        showTooltip,
        hideTooltip,
        containerRef
    })
    const pinnedRowContext = React.useMemo(() => ({ ...rowContext, rowHeight: TABLE_HEADER_HEIGHT }), [rowContext])

    const cellContext = useCellContext({
        ...p,
        rows,
        showTooltip,
        hideTooltip,
        pinnedColumns
    })

    const virtualizedRenderRow = React.useCallback(
        (rp: { index: number; style: React.CSSProperties }) =>
            renderRow(rowContext, cellContext, rows[rp.index], rp.index, rp.style),
        [rowContext, cellContext, rows]
    )

    const headerElement = React.useMemo(
        () => (
            <_TableStickyRowsContainer>
                {/* Need this for hiding overflow */}
                <div style={{ background: "white", flexGrow: 1 }}>
                    {isPinnedRow ? (
                        <_TablePinnedRow sticky={true}>
                            {renderRow(pinnedRowContext, cellContext, rows[p.pinnedRow!], p.pinnedRow!)}
                        </_TablePinnedRow>
                    ) : null}
                    <_TableHead>
                        <HeaderRowView
                            data-cy="table-header-row-view"
                            effects={p.headerRowEffects}
                            cells={p.headerRow}
                            hasOptions={Boolean(p.interactiveOptions)}
                            ctx={rowContext}
                            cellCtx={cellContext}
                        />
                    </_TableHead>
                </div>
            </_TableStickyRowsContainer>
        ),
        [
            rowContext,
            cellContext,
            p.headerRowEffects,
            p.headerRow,
            p.interactiveOptions,
            p.pinnedRow,
            pinnedRowContext,
            rows,
            isPinnedRow
        ]
    )
    const renderRows = React.useCallback(() => {
        // Rows have to be greater than 5, because there are Dropdown overflow hidden errors
        if (shouldVirtualize) {
            return (
                <Flex grow={1}>
                    <VirtualizedTable
                        rows={rows}
                        containerRef={setVirtualizedContainerRef}
                        renderEmpty={renderEmpty}
                        headerElement={headerElement}
                        pinnedRows={isPinnedRow ? 1 : 0}
                        rowHeight={rowHeight}
                        onRowsRendered={onReachBottom}
                        rowRender={virtualizedRenderRow}
                        itemsCount={p.itemsCount || rows.length}
                    />
                </Flex>
            )
        } else if (!virtualized && rows.length > 30) {
            // eslint-disable-next-line no-console
            console.warn("You are not virtualizing a large dataset, consider adding `virtualized` prop to your table")
        }

        if (rows.length) return rows.map((r, i) => renderRow(rowContext, cellContext, r, i))

        return !p.loading ? (
            <_NoRowsRow rowHeight={rowHeight} minWidth={minRowWidth}>
                <_BodyFont s14>No items available</_BodyFont>
            </_NoRowsRow>
        ) : null
    }, [
        virtualized,
        shouldVirtualize,
        rows,
        p.loading,
        rowHeight,
        rowContext,
        cellContext,
        virtualizedRenderRow,
        onReachBottom,
        headerElement,
        p.itemsCount,
        isPinnedRow,
        minRowWidth
    ])

    React.useEffect(() => {
        if (scrollingContainerRef && autofocus) {
            scrollingContainerRef.style.outline = "none"
            scrollingContainerRef.tabIndex = 0
            focusWithoutScrolling(scrollingContainerRef)
        }
    }, [scrollingContainerRef, autofocus, rows, selectedRows])

    return (
        <_TableView>
            <_TableBody ref={setContainerRef} virtualized={shouldVirtualize} pinnedRows={isPinnedRow}>
                {shouldVirtualize ? null : headerElement}
                {/* It is necessary to always render rows - infinite loader */}
                {renderRows()}
                {p.loading ? (
                    <_NoRowsRow rowHeight={rowHeight} minWidth={minRowWidth}>
                        {p.smallLoader ? <Loader size="small" /> : <Loader loadingText="Calculating data" />}
                    </_NoRowsRow>
                ) : null}
            </_TableBody>
            <Tooltip visible={isTooltipVisible} items={tooltipItems} position={tooltipPosition} />
        </_TableView>
    )
}
