import _isEqual from "lodash/fp/isEqual"
import _debounce from "lodash/debounce"
import { isEmpty } from "../../functions/src/utils/validators"

export const range = (from: number, to: number) => Array.from(new Array(to), (_, i) => i + from)
export const repeat = (to: number) => range(0, to).map((_, i) => i)

export const positiveOrZero = (num: number) => (num > 0 ? num : 0)
export const calculatePercentage = (float: number): string => `${Math.floor(float) * 100}%`

export const formatBlanks = (v: string) => v.replace(/\s+/g, " ")
export const concatWithSeparator = (base: string, str: string, separator = ",") =>
    base + (!isEmpty(base) ? `${separator} ` : "") + str

export const isEqual = <T>(a: T, b: T) => _isEqual(a, b)

export const getNowTS = () => new Date().getTime()

export const debounce = _debounce

const matchBrowserVersion = (regex: RegExp) => (str: string): number | null => {
    const res = str.match(regex)
    return res ? parseInt(res[2], 10) : null
}
const unsupportedBrowsers = {
    explorer: { codename: "MSIE", verRegex: matchBrowserVersion(/(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/) },
    edge: { codename: "Edge", verRegex: matchBrowserVersion(/(Edge\/)(\d+).\d+/) }
}
type UnsupportedBrowsers = typeof unsupportedBrowsers

export const matchUnsupportedBrowser = (ua: string, version: number, browser: keyof UnsupportedBrowsers): boolean => {
    const b = unsupportedBrowsers[browser]
    const match = ua.includes(b.codename)
    if (!match) return false
    const v = b.verRegex(ua)
    return v ? v <= version : false
}

export const isIE = () => matchUnsupportedBrowser(window.navigator.userAgent, 11, "explorer")
export const isEdge = () => matchUnsupportedBrowser(window.navigator.userAgent, 80, "edge")
export const isSafari = () =>
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") == -1 &&
    navigator.userAgent.indexOf("FxiOS") == -1
export const printPage = () => {
    if (isSafari()) {
        try {
            // Workaround for SAFARI
            document.execCommand("print", false)
        } catch (e) {
            window.print()
        }
    } else {
        window.print()
    }
}
