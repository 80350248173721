import styled, { css } from "styled-components"
import { styleIfProp, _FlexRow } from "../../styles/common"
import { _ErrorLabel } from "./Input.styles"
import { RichTextDescriptionProps } from "./RichEditor"

const lineHeight = 1.5
const fontSize = 14

const basicDraftStyles = css`
    .DraftEditor-root {
        font-size: ${fontSize}px;
        font-style: normal;
        font-stretch: normal;
        font-weight: normal;
        line-height: ${lineHeight};
        color: ${({ theme }) => theme.colors.primary};

        ul {
            margin: 0;
        }
    }
`

export const _RichTextAreaWrapper = styled.div<{ maximized?: boolean; validationError?: boolean }>`
    display: flex;
    flex-direction: column;

    ${_ErrorLabel} {
        align-self: flex-end;
    }
    border: solid 1px ${styleIfProp("validationError", "#ff151f", "#edeff2")};
    border-radius: 20px;
    &:focus-within {
        border: 1px solid ${styleIfProp("validationError", "#ff151f", "#617084")};
        & .DraftEditor-root {
            border-bottom: 1px solid ${styleIfProp("validationError", "#ff151f", "#617084")};
        }
    }

    ${basicDraftStyles}

    .public-DraftEditor-content {
        padding: 10px 15px 4px;
        min-height: 150px;
    }
    .DraftEditor-root {
        border-bottom: 1px solid #edeff2;
    }

    ${p =>
        p.maximized
            ? css`
                  position: fixed;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  background: #fff;
                  z-index: 12;

                  .DraftEditor-root,
                  .DraftEditor-editorContainer,
                  .public-DraftEditor-content {
                      display: flex;
                      flex-direction: column;
                      flex-grow: 1;
                  }
              `
            : css`
                  .DraftEditor-editorContainer {
                      max-height: 220px;
                      overflow-y: auto;
                  }
              `}
`

export const _RichTextAreaToolbar = styled(_FlexRow)`
    position: relative;
`

export const _RichTextAreaError = styled.div`
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    padding: 4px;
    color: ${p => p.theme.colors.danger};
    background: white;
    font-size: 11px;
`

export const EditorButton = styled.button<{ enabled?: boolean }>`
    border: none;
    background: none;
    padding: 8px;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    &:focus,
    &:active {
        outline: none;
    }

    ${p => p.enabled && "filter: contrast(1) hue-rotate(5deg) saturate(88) brightness(0.8)"}
`

export const _RichTextDescriptionContainer = styled.div.withConfig<Partial<RichTextDescriptionProps>>({
    shouldForwardProp: (p, d) => !["color"].includes(p) && d(p)
})`
    ${basicDraftStyles}
    .DraftEditor-root {
        color: ${p => p.color && p.theme.colors[p.color]};
    }
    ${p => !!p.maxLines && `max-height: ${p.maxLines * fontSize * lineHeight}px; overflow: hidden;`}
`

export const _MaximizedSaveBtn = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
`
