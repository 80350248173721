import { PriorityImportPayload } from "../../../functions/src/models/importing.types"
import { createAction } from "../../../functions/src/utils/actions"

export type RadarMutationPayload = { hubId: string; radar: RadarDetailsPayload }
export type PrioritizationPayload = { radarId: string; sectorId: string; rows: string[] }

export const actions = {
    createRadar: (actionId: string, p: RadarMutationPayload) => createAction("createRadar", { actionId, ...p }),
    updateRadar: (actionId: string, p: OnRadar<RadarMutationPayload>) =>
        createAction("updateRadar", { actionId, ...p }),
    updateRadarConfig: (actionId: string, p: ByRadarConfigPayload) =>
        createAction("mutateByRadarConfig", { actionId, ...p }),

    _mutateRadarCollectionItem: <T extends CName>(
        actionId: string,
        p: { cname: T; item: CollectionItem<T>; radarId: string; type: MutationType }
    ) => createAction("_mutateRadarCollectionItem", { actionId, ...p }),

    removeRadarCollectionItems: (actionId: string, p: RemoveCollectionItemsPayload<CName>) =>
        createAction("removeRadarCollectionItems", { actionId, ...p }),

    createHub: (actionId: string, hub: HubPayload) => createAction("createHub", { actionId, hub }),
    updateHub: (actionId: string, hubId: string, hub: HubPayload) =>
        createAction("updateHub", { actionId, hub, hubId }),

    updateUser: (actionId: string, userPayload: RadarUser) => createAction("updateUser", { actionId, userPayload }),
    setNewsfeedVisit: (actionId: string, radarId: string, timestamp: Timestamp) =>
        createAction("setNewsfeedVisit", { actionId, radarId, timestamp }),

    updateProfile: (actionId: string, user: Pick<User, "displayName" | "userId">) =>
        createAction("updateProfile", { actionId, user }),
    addUser: (actionId: string, userPayload: RadarUserRequest) => createAction("addUser", { actionId, ...userPayload }),
    revokeInvitation: (actionId: string, radarId: string, userId: string) =>
        createAction("revokeInvitation", { actionId, radarId, userId }),
    resendInivitation: (actionId: string, radarId: string, userId: string) =>
        createAction("resendInivitation", { actionId, radarId, userId }),
    acceptRequest: (actionId: string, radarId: string, userId: string) =>
        createAction("acceptRequest", { actionId, radarId, userId }),
    declineRequest: (actionId: string, radarId: string, userId: string) =>
        createAction("declineRequest", { actionId, radarId, userId }),

    portalInvite: (actionId: string, userId: string, radarId: string) =>
        createAction("portalInvite", { actionId, userId, radarId }),

    importPrioritiesChunk: (actionId: string, p: PriorityImportPayload) =>
        createAction("importPrioritiesChunk", { actionId, ...p }),

    addRelation: (actionId: string, p: RelationTuple) => createAction("addRelation", { actionId, ...p }),
    removeRelation: (actionId: string, p: RelationTuple) => createAction("removeRelation", { actionId, ...p }),

    mutateDecorator: (actionId: string, p: DecoratorMeta) => createAction("mutateDecorator", { actionId, ...p }),
    mutateDecoratorDebounced: (p: DecoratorMeta) => createAction("mutateDecoratorDebounced", p),
    mutatePipelineStage: (actionId: string, p: PipelineValueMutationPayload) =>
        createAction("mutatePipelineStage", { actionId, ...p }),

    mutateSearchArea: (actionId: string, p: SearchAreaMutationPayload) =>
        createAction("mutateSearchArea", { actionId, ...p }),
    assignSearchArea: (p: OnAction<SearchAreaAssignmentPayload>) => createAction("assignSearchArea", p),

    assignTag: (actionId: string, p: TagAssignmentPayload) => createAction("assignTag", { actionId, ...p }),
    mutateTag: (actionId: string, p: TagMutationPayload) => createAction("mutateTag", { actionId, ...p }),
    createAndAssignTag: (
        actionId: string,
        p: TagMutationPayload & OmitStrict<TagAssignmentPayload, "tagId" | "assign">
    ) => createAction("createAndAssignTag", { actionId, ...p }),

    createBatchSegmentTags: (actionId: string, p: SegmentTagBatchMutationPayload) =>
        createAction("createBatchSegmentTags", { actionId, ...p }),
    mutateSegmentTag: (actionId: string, p: SegmentTagMutationPayload) =>
        createAction("mutateSegmentTag", { actionId, ...p }),
    assignSegmentTag: (actionId: string, p: SegmentTagAssignmentPayload) =>
        createAction("assignSegmentTag", { actionId, ...p }),
    createAndAssignSegmentTag: (
        actionId: string,
        p: SegmentTagMutationPayload & OmitStrict<SegmentTagAssignmentPayload, "segmentId" | "assign">
    ) => createAction("createAndAssignSegmentTag", { actionId, ...p }),

    createComment: (p: OnAction<CommentCreatePayload>) => createAction("createComment", p),
    editComment: (p: OnAction<CommentEditPayload>) => createAction("editComment", p),
    deleteComment: (p: OnAction<CommentDeletePayload>) => createAction("deleteComment", p),

    _setActionResults: (result: SMap<CloudActionResult>) => createAction("_setActionResults", result),

    uploadPrioritization: (actionId: string, payload: PrioritizationPayload) =>
        createAction("uploadPrioritization", { actionId, ...payload })
}

export type Actions = ReturnType<typeof actions[keyof typeof actions]>
