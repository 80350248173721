// TODO Fix this
/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react"
import CreatableSelect from "react-select/creatable"

import { getTagSelectStyles } from "../reactSelectUtils"
import { connect } from "react-redux"
import { actions } from "../../store/cloud/actions"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { toArray, keys, identity } from "../../../functions/src/utils/map"
import { Loading, Loaded } from "../../../functions/src/utils/types"
import { getCurrentRadarId } from "../../models/LocationType"
import { getMapDispatch } from "../../utils/redux"
import { _TagsContainer } from "./Tags.styles"
import { tagToOption, getTagMutationPayloadFromName, byCompareName } from "../../../functions/src/models/tags"
import { Loader } from "../common/Loader"
import { getDataByKeys } from "../../store/data/dataSelectors"
import { CloudState } from "../../store/store"
import { MapState } from "../../utils/redux.types"
import { RadarLocationState } from "../../models/auth"
import { ReactSelectAction } from "../form/FormlessInputs"

export type TagsEditorOwnProps = { objectId: string }
export type TagsEditorStateProps = Loadable<
    { allTags: Tag[]; assignedTags: Tag[]; flags: ConfigFeatureFlags } & Pick<CloudState, "actionsResults"> &
        Pick<RadarDetails, "radarId">
>

const _TagsEditor: React.FC<TagsEditorOwnProps & ActionProps & TagsEditorStateProps> = p => {
    if (p.loading) return <Loader />

    const [isLoading, setIsLoading] = React.useState(false)

    const [, onAssign] = useCloudAction(p.assignTag, p.actionsResults, () => setIsLoading(false))
    const [, onCreateAssign] = useCloudAction(p.createAndAssignTag, p.actionsResults, () => setIsLoading(false))

    const handleChange = (_: any, actionMeta: ReactSelectAction) => {
        setIsLoading(true)
        const partialPayload: OmitStrict<TagAssignmentPayload, "tagId" | "assign"> = {
            objectId: p.objectId,
            radarId: p.radarId
        }
        switch (actionMeta.action) {
            case "remove-value":
            case "pop-value":
                if (!actionMeta?.removedValue?.value) return
                return onAssign({ ...partialPayload, tagId: actionMeta.removedValue.value, assign: false })
            case "select-option":
                if (!actionMeta?.option?.value) return
                return onAssign({ ...partialPayload, tagId: actionMeta.option.value, assign: true })
        }
    }

    const onCreate = (rawName: string) => {
        setIsLoading(true)
        const payload: TagMutationPayload & OmitStrict<TagAssignmentPayload, "tagId" | "assign"> = {
            ...getTagMutationPayloadFromName(rawName),
            radarId: p.radarId,
            objectId: p.objectId
        }
        onCreateAssign(payload)
    }
    return (
        <_TagsContainer>
            <div style={{ width: "100%" }}>
                <CreatableSelect
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    onChange={handleChange}
                    onCreateOption={onCreate}
                    isMulti
                    createOptionPosition="first"
                    formatCreateLabel={v => `Create new tag "${v}"...`}
                    isClearable={false}
                    closeMenuOnSelect={true}
                    styles={getTagSelectStyles(false)}
                    value={p.assignedTags.map(tagToOption(false))}
                    options={p.allTags.map(tagToOption(false))}
                />
            </div>
        </_TagsContainer>
    )
}

export const tagEditorMapState: MapState<TagsEditorStateProps, TagsEditorOwnProps> = (
    { cloud: { actionsResults }, auth, data },
    op
) => {
    const radarId = getCurrentRadarId(auth)!
    const deps = getDataByKeys(["tags", "tagsAssignments"], data[radarId])

    if (deps.loading) return Loading()
    const { tags, tagsAssignments } = deps
    const alreadyAssignedTags = tagsAssignments[op.objectId]

    const allTags = toArray(tags, identity).sort(byCompareName)

    const assignedTags = keys(alreadyAssignedTags)
        .filter(t => alreadyAssignedTags[t] && tags[t])
        .map(t => tags[t])

    return Loaded({
        actionsResults,
        radarId,
        allTags,
        assignedTags,
        flags: (auth.params as RadarLocationState).locationParams
    })
}

type ActionProps = ReturnType<typeof mapDispatch>
const mapDispatch = getMapDispatch(actions, ["assignTag", "createAndAssignTag"])

export const CardTagsEditor = connect(tagEditorMapState, mapDispatch)(_TagsEditor)
