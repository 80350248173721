import * as React from "react"
import {
    _ListWrapper,
    _ListItemTitle,
    _ListName,
    _ListFilesWrapper,
    _ListTitle,
    _ListItemDetails,
    _InlineIconList,
    _InlineIconListTitle,
    _InlineIconListItem
} from "./IconList.styles"
import { _VerticalSpace } from "../../styles/common"
import { call } from "../../../functions/src/utils"
import styled from "styled-components"
import { IconSvg, IconSvgProps } from "../IconSvg"

type InlineIconListProps = {
    title: string
    items: InlineIconListItem[]
}

export type InlineIconListItem = {
    title: string
    icon: IconSvgProps
}

export const InlineIconList: React.FC<InlineIconListProps> = p => (
    <_InlineIconList>
        <_InlineIconListTitle>{p.title}</_InlineIconListTitle>
        {p.items.map((i, k) => (
            <_InlineIconListItem key={k}>
                <IconSvg {...i.icon} />
                <span>{i.title}</span>
            </_InlineIconListItem>
        ))}
    </_InlineIconList>
)

export type IconListItemProps = {
    header: string
    subHeader?: string
    description?: string
    type?: "positive" | "negative"
    icon: IconSvgProps
    onRemove?: F0
    onSelect?: F0
    onShowReason?: F0
}
export type IconListProps = {
    title: string
    items: IconListItemProps[]
}
export const IconList: React.FC<IconListProps> = p => (
    <_ListFilesWrapper>
        <_ListTitle>{p.title.toLocaleUpperCase()}</_ListTitle>
        {p.items.map((item, i) => (
            <IconListItem {...item} key={i} />
        ))}
    </_ListFilesWrapper>
)

const ModalLink = styled.div<Pick<IconListItemProps, "type">>`
    color: ${p => (p.type === "negative" ? "red" : "green")};
    text-decoration: underline dashed;
`
export const IconListItem: React.FC<IconListItemProps> = p => {
    return (
        <_ListWrapper clickable={Boolean(p.onSelect)} onClick={() => call(p.onSelect)}>
            <IconSvg {...p.icon} />
            <_ListItemDetails>
                <_ListItemTitle>{p.header}</_ListItemTitle>
                {p.subHeader ? <_ListName>{p.subHeader}</_ListName> : null}
                <_VerticalSpace base="16px" />
                {p.description ? (
                    <ModalLink type={p.type} onClick={p.onShowReason}>
                        {p.description}
                    </ModalLink>
                ) : null}
            </_ListItemDetails>
            {p.onRemove ? <IconSvg name="trash-icon" width={20} height={20} onClick={() => call(p.onRemove)} /> : null}
        </_ListWrapper>
    )
}
