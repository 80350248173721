import { AuthenticationState, AuthorizationType, AuthenticatedState, NotLoggedInState } from "./auth"

export const NotLoggedIn = (emailGiven = false): AuthenticationState => ({ type: "NotLoggedIn", emailGiven })
export const ProcessingLogin = (): AuthenticationState => ({ type: "ProcessingLogin" })
export const LoginError = (error: string): AuthenticationState => ({ type: "LoginError", error })
export const LoggedIn = (user: ExtUser, authorization: AuthorizationType): AuthenticatedState => ({
    type: "LoggedIn",
    authorization,
    user
})

export const isLoggedIn = (v: AuthenticationState): v is AuthenticatedState => v.type === "LoggedIn"
export const isNotLoggedIn = (v: AuthenticationState): v is NotLoggedInState => v.type === "NotLoggedIn"
export const isLoginError = (v: AuthenticationState): v is ErrorState<"LoginError"> => v.type === "LoginError"

export const isAllowed = (v: AuthorizationType | null) =>
    (["RadarAccess", "AdminAccess", "RootAccess"] as AuthorizationType[]).includes(v as any)

export const isAdmin = (v: AuthenticationState) =>
    isLoggedIn(v) && (v.authorization === "AdminAccess" || v.authorization === "RootAccess")

export const isRoot = (v: AuthenticationState) => isLoggedIn(v) && v.authorization === "RootAccess"

export const isRadarAccess = (v: AuthenticationState) => isLoggedIn(v) && v.authorization === "RadarAccess"

export const isAccessNotRequested = (v: AuthenticationState) =>
    isLoggedIn(v) && v.authorization === "AccessNotRequested"

export const getLastNewsfeedVisitTimestamp = (v: AuthenticationState): number | null =>
    v.type === "LoggedIn" ? v.user.newsfeedVisitTimestamp || null : null
