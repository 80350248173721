/* eslint-disable max-lines */
import * as React from "react"
import { _TagFont, _BodyFont } from "../../styles/typography"
import { assertNever } from "../../../functions/src/utils"
import { getOnScreenPosition } from "../../utils/html5"
import { _HorizontalSpace, Flex, _FlexColumn, _VerticalSpace, _Arrow } from "../../styles/common"
import { Bubble } from "../common/SpeechBubble"
import { StarButton } from "../../styles/buttons"
import { RadioDropdown } from "../Dropdown"
import { _TableCell, _TableCellContent } from "./TableView.styles"
import { ImageWithFallback } from "../common"
import { _ChipBase } from "../common/Chips"
import { _CommentAvatar } from "../comments/comment.styles"
import { ColumnDefinition, DropdownCell, StarCell, HeaderCell, RowEffect, ContentCell } from "./TableView.types"
import { IconSvg } from "../IconSvg"
import { PinnedColumns } from "./utils/pinnedColumns"
import { _Badge } from "../common/Badge"
import { theme } from "../../styles/themes"

type HeaderCellMeta<ID extends string> = State<
    "header",
    { cellIndex: number; key: ID; id: ID; definition?: ColumnDefinition; getPosition: () => Bounds }
>
const HeaderCellMeta = <ID extends string>(
    id: ID,
    cellIndex: number,
    key: ID,
    getPosition: () => Bounds = () => ({ left: 0, right: 0, top: 0, bottom: 0 }),
    definition?: ColumnDefinition
): HeaderCellMeta<ID> => ({
    type: "header",
    key,
    id,
    cellIndex,
    getPosition,
    definition
})

type CellAction<ID extends string> =
    | ValueState<Type<DropdownCell<ID>>, AreaDecoratorMeta>
    | ValueState<Type<StarCell<ID>>, boolean>
export type ValueCellMeta<ID extends string, T = any> = State<
    "value",
    { rowId: React.ReactText; id: ID; cellIndex: number; rowIndex: number; actions?: CellAction<ID>; value: T }
>
function ValueCellMeta<T, ID extends string>(
    id: ID,
    rowId: React.ReactText,
    cellIndex: number,
    rowIndex: number,
    value: T
): ValueCellMeta<ID, T> {
    return {
        type: "value",
        id,
        cellIndex,
        rowId,
        rowIndex,
        value
    }
}
export type CellMeta<ID extends string> = HeaderCellMeta<ID> | ValueCellMeta<ID>
type CellCallback<ID extends string, E extends React.SyntheticEvent, T = void> = (meta: CellMeta<ID>, event?: E) => T
type SimpleCallback<ID extends string, T = void> = (meta: CellMeta<ID>) => T

export const isHeaderCell = <ID extends string>(v: CellMeta<ID>): v is HeaderCellMeta<ID> => v && v.type === "header"
export const isValueCell = <ID extends string>(v: CellMeta<ID>): v is ValueCellMeta<ID> => v && v.type === "value"

export type CellContext<ID extends string> = {
    onClick: CellCallback<ID, React.MouseEvent>
    onFocus: CellCallback<ID, React.FocusEvent>
    onFocusLost: CellCallback<ID, React.FocusEvent>
    isLast: SimpleCallback<ID, boolean>
    sortDirection: SortDirection
    sortBy?: number
    isSortable: SimpleCallback<ID>
    pinnedColumns: PinnedColumns
}

type HeaderCellProps<ID extends string> = HeaderCell<ID> & {
    index: number
    isLast?: boolean
    effects?: RowEffect[]
    clickable?: boolean
    rowHeight: number
    ctx: CellContext<ID>
}
export const HeaderCellView = <ID extends string>({ clickable = true, ctx, ...p }: HeaderCellProps<ID>) => {
    const [ref, setRef] = React.useState<HTMLElement | null>(null)
    const meta = HeaderCellMeta<ID>(p.id, p.index, p.sortingKey as ID, () => getOnScreenPosition(ref), p.definition)
    const lastCellSeparator = Boolean(p.isLast && p.effects?.includes("last-cell-separator"))
    const sortedBy = ctx.sortBy === p.index

    return (
        <_TableCell
            lastCellSeparator={lastCellSeparator}
            key={p.id}
            onClick={() => ctx.onClick(meta)}
            background={theme.colors.theme1}
            pinnedStyles={ctx.pinnedColumns.all[p.id]}
            clickable={clickable}
            className={ctx.pinnedColumns.all[p.id]?.classNames.join(" ")}>
            <_TableCellContent rowHeight={p.rowHeight} nopadding={p.id === "star"}>
                {/* TODO HeaderCellView should allow to have value outside TagFont... */}
                {p.id === "star" || p.rawContent ? (
                    p.value
                ) : (
                    <_TagFont
                        ref={setRef}
                        onMouseEnter={() => ctx.onFocus(meta)}
                        onMouseLeave={() => ctx.onFocusLost(meta)}>
                        {p.value}
                        {p.definition && <IconSvg inline name="question-mark" width={12} height={12} />}
                    </_TagFont>
                )}
                {!["star", "comments"].includes(p.id) && ctx.isSortable(meta) ? (
                    <>
                        <_HorizontalSpace base="8px" />
                        <_Arrow
                            direction={sortedBy ? (ctx.sortDirection === "asc" ? "up" : "down") : "right"}
                            color={sortedBy ? "theme10" : "theme14"}
                        />
                    </>
                ) : null}
            </_TableCellContent>
        </_TableCell>
    )
}

export type ValueCellProps<ID extends string> = ContentCell<ID> & {
    rowId: React.ReactText
    id: ID
    index: number
    isLast?: boolean
    rowIndex: number
    rowHeight: number
    effects?: RowEffect[]
    ctx: CellContext<ID>
    background?: string
}

export const ValueCellView = React.memo(<ID extends string>(p: ValueCellProps<ID>) => {
    const meta = React.useMemo(() => ValueCellMeta(p.id, p.rowId, p.index, p.rowIndex, p.value), [
        p.id,
        p.rowId,
        p.index,
        p.rowIndex,
        p.value
    ])
    // TODO Refactor that to improve performance
    const renderContent = () => {
        switch (p.type) {
            case "title":
                return (
                    <_TableCellContent rowHeight={p.rowHeight}>
                        <_BodyFont s17>{p.value}</_BodyFont>
                    </_TableCellContent>
                )

            case "text":
                return (
                    <_TableCellContent rowHeight={p.rowHeight}>
                        {p.value !== "" ? <_BodyFont s14>{p.value}</_BodyFont> : ""}
                    </_TableCellContent>
                )

            case "icon-title":
                return (
                    <_TableCellContent rowHeight={p.rowHeight}>
                        <ImageWithFallback src={p.url} width={32} height={32} />
                        <_HorizontalSpace base="16px" />
                        <_BodyFont s17>{p.value}</_BodyFont>
                    </_TableCellContent>
                )

            case "pill":
                return (
                    <_TableCellContent rowHeight={p.rowHeight}>
                        <_ChipBase background="theme9">
                            <Flex>
                                <IconSvg name="file" width={15} height={15} />
                                <_HorizontalSpace base="8px" />
                                {p.value}
                            </Flex>
                        </_ChipBase>
                    </_TableCellContent>
                )
            case "file":
                return (
                    <_TableCellContent rowHeight={p.rowHeight}>
                        <Flex>
                            <IconSvg name="file" width={15} height={15} />
                            <_HorizontalSpace base="8px" />
                            {p.value}
                        </Flex>
                    </_TableCellContent>
                )
            case "comment":
                return (
                    <_TableCellContent rowHeight={p.rowHeight} nopadding justify="flex-end">
                        {p.value ? (
                            <Bubble width={20} color="primary">
                                {p.value}
                            </Bubble>
                        ) : null}
                    </_TableCellContent>
                )

            case "comment-bubble-decker": {
                return (
                    <_TableCellContent rowHeight={p.rowHeight} nopadding justify="flex-end">
                        {p.value.upper ? (
                            <_Badge size={20} fontSize={9} color="theme17">
                                {p.value.upper}
                            </_Badge>
                        ) : null}
                        {p.value.lower ? (
                            <>
                                <_HorizontalSpace base="5px" />
                                <Bubble width={20} color="primary">
                                    {p.value.lower}
                                </Bubble>
                            </>
                        ) : null}
                    </_TableCellContent>
                )
            }

            case "star":
                return (
                    <_TableCellContent rowHeight={p.rowHeight} nopadding justify="flex-end">
                        <StarButton
                            starred={p.value}
                            onClick={e => {
                                e.stopPropagation()
                                p.ctx.onClick({ ...meta, actions: { type: "star", value: !p.value } })
                            }}>
                            <IconSvg data-cy={`star-button-${p.value}`} name="star" width={15} height={14} />
                        </StarButton>
                    </_TableCellContent>
                )

            case "dropdown": {
                return (
                    <_TableCellContent rowHeight={p.rowHeight}>
                        <div onMouseEnter={() => p.ctx.onFocusLost(meta)}>
                            <RadioDropdown
                                options={p.value.options}
                                closeOnSelect
                                optionsYLocation={p.ctx.isLast(meta) ? "top" : "bottom"}
                                selected={p.value.selected ? [p.value.selected] : []}
                                onSelect={({ value }) => {
                                    p.ctx.onClick({
                                        ...meta,
                                        actions: {
                                            type: "dropdown",
                                            value: { ...p.meta!, newValue: value, oldValue: p.value.selected!.value }
                                        } as State<
                                            "dropdown",
                                            {
                                                value: AreaDecoratorMeta
                                            }
                                        >
                                    })
                                }}
                            />
                        </div>
                    </_TableCellContent>
                )
            }

            case "box":
                return (
                    <_TableCellContent rowHeight={p.rowHeight} justify="center">
                        <_CommentAvatar data-cy="table-comment-avatar" editable={false}>
                            {p.value}
                        </_CommentAvatar>
                    </_TableCellContent>
                )
        }
        assertNever(p)
    }

    const lastCellSeparator = Boolean(p.isLast && p.effects?.includes("last-cell-separator"))
    return (
        <_TableCell
            background={p.background}
            lastCellSeparator={lastCellSeparator}
            pinnedStyles={p.ctx.pinnedColumns.all[p.id]}
            className={p.ctx.pinnedColumns.all[p.id]?.classNames.join(" ")}
            key={p.id}
            onClick={() => p.ctx.onClick(meta)}
            clickable={p.clickable}>
            {renderContent()}
        </_TableCell>
    )
})
