import { ViewModelsMap } from "../ViewModels"

const mkListSchema = <C extends CName, T extends keyof ViewModelsMap[C] & string>(_c: C, cols: T[]): T[] => cols

export const schemas = {
    patents: mkListSchema("patents", ["patent_title", "holder", "publication_number", "filing_location"]),
    sectors: mkListSchema("sectors", [
        "sector_name",
        "num_startups",
        "funding",
        "investor_quality",
        "sector_funding_momentum",
        "funding_momentum",
        "avg_company_age",
        "priority_score"
    ]),
    startups: mkListSchema("startups", [
        "startup_name",
        "sectors",
        "headquarters",
        "funding",
        "stage",
        "investor_quality",
        "funding_momentum"
    ]),
    tech_transfers: mkListSchema("tech_transfers", ["tech_of_interest", "research_hub", "location"]),
    investors: mkListSchema("investors", [
        "investor_name",
        "total_unique_investments",
        "number_of_investment_rounds",
        "amount_invested",
        "amount_per_investment",
        "investor_quality"
    ]),
    research_hubs: mkListSchema("research_hubs", [
        "research_hub_name",
        "university",
        "num_publications",
        "num_experts",
        "num_patent_families",
        "num_clinical_trials"
    ]),
    research_papers: mkListSchema("research_papers", [
        "paper_title",
        "research_group",
        "university",
        "publication_year"
    ]),
    tech_experts: mkListSchema("tech_experts", [
        "tech_expert_name",
        "association",
        "num_publications",
        "num_patent_families"
    ]),
    influencers: mkListSchema("influencers", [
        "influencer_name",
        "type",
        "num_publications",
        "num_instagram",
        "num_twitter",
        "num_patents",
        "num_news_sources"
    ]),
    patent_holders: mkListSchema("patent_holders", [
        "patent_holder",
        "industry",
        "num_patents",
        "num_citations",
        "num_patent_families",
        "num_patent_family_citations"
    ]),
    grants: mkListSchema("grants", ["grant_title", "research_group", "university", "location", "funding"]),
    clinical_trials: mkListSchema("clinical_trials", [
        "trial_title",
        "sponsor",
        "location",
        "study_phase",
        "start_date",
        "end_date"
    ]),
    companies: mkListSchema("companies", [
        "product_technology_name",
        "company_name",
        "company_type",
        "active_ingredient"
    ])
}

export type ListSchemas = typeof schemas
