import * as React from "react"
import styled from "styled-components"
import { Popup } from "../Popup"
import { PopupInner, PopupContent } from "../Popup.styles"

export const FAQContainer = styled.div`
    overflow: auto;
    p {
        &:first-child {
            margin-top: 0;
        }
        margin: 34px 0;
    }
`

type Props = {
    onClose: F0
}

export const FAQ: React.FC<Props> = p => (
    <Popup logo onClose={p.onClose}>
        <PopupInner>
            <PopupContent long>
                <FAQContainer>
                    <p>
                        <strong>What is this?</strong>
                        <br />
                        This is a Radar. Radars use proprietary machine learning tools to create custom, dynamic maps of
                        the areas you should focus on – and keep you up to date. Radars use as inputs your business
                        lines, hunting grounds, and focus areas. The Radar output is a customized map of opportunities.
                    </p>
                    <p>
                        <strong>How does this work?</strong>
                        <br />
                        We use our machine learning algorithms in an iterative process with our analysts to identify
                        opportunity spaces (sectors) of relevance. This sector is placed on the Radar and positioned
                        with respect to your business. Behind each sector is contextualization (description, startups,
                        etc). To see the sector information, simply click on it.
                    </p>
                    <p>
                        <strong>What problem do Radars solve?</strong>
                        <br />
                        Comprehensively identifying and tracking new and existing opportunity spaces is incredibly
                        difficult without significant investments in data science and/or personnel.
                    </p>
                    <p>
                        <strong>What data science?</strong>
                        <br />
                        We use the three main tools with Radars: our NLP search engine, our clustering algorithm, and
                        our keyword-agnostic similarity search engine.
                    </p>
                    <p>
                        <strong>How is the Radar maintained?</strong>
                        <br />
                        The Radar is designed to be a dynamic tool used to monitor coverage of focus areas.
                        <br />
                        The data behind the Radar updates automatically. As an example, if a company raises money, it
                        will be reflected in the sector card. At the same time, the Radar can keep you updated on other
                        things such as:
                    </p>
                    <ul>
                        <li>New Companies</li>
                        <li>Financings</li>
                        <li>M&amp;A/Exits</li>
                        <li>Management changes</li>
                        <li>Other Relevant News &amp; Insights</li>
                    </ul>
                    <p>
                        If you are interested in monitoring, please reach out to Stu{" "}
                        <a href="mailto:stu@radicleinsights.com">stu@radicleinsights.com</a>.
                    </p>
                    <p>
                        <strong>Radar 2.0</strong>
                        <br />
                        We have a long list of feature introductions we will be rolling out over the coming months,
                        including: report view/downloading, tag creation, liking/unliking sectors, request for Debrief,
                        etc. If there are things you'd like to see, please let us know.
                    </p>
                </FAQContainer>
            </PopupContent>
        </PopupInner>
    </Popup>
)
