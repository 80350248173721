import React from "react"
import styled from "styled-components"
import { assertNever } from "../../../functions/src/utils"
import { Flex, _HorizontalSpace } from "../../styles/common"
import { IconSvg } from "../IconSvg"
import { Hexagon } from "./Loader"
import { _LoaderSvg } from "./Loader.styles"

type FetchIndicatorProps<T> = { data: Async<T>; onRetry?: F0 }

const _FetchIndicatorContainer = styled.div`
    display: inline-block;
    position: relative;

    &,
    ${_LoaderSvg} {
        width: 16px;
        height: 16px;
    }

    ${_LoaderSvg} {
        margin-left: 0;
    }
`

const getFetchIndicator = <T extends unknown>(p: FetchIndicatorProps<T>) => {
    switch (p.data.type) {
        case "NotFetched":
            return (
                <span title="Fetching not started">
                    <IconSvg name="cross" width={16} height={16} />
                </span>
            )
        case "Fetching":
            return <Hexagon small={true} />
        case "FetchError":
            return (
                <Flex align="center">
                    <span title="Error while fetching data">
                        <IconSvg name="wrong" width={16} height={16} />
                    </span>
                    {p.onRetry && (
                        <>
                            <_HorizontalSpace base="4px" />
                            <span onClick={p.onRetry} style={{ cursor: "pointer" }}>
                                Retry?
                            </span>
                        </>
                    )}
                </Flex>
            )
        case "Fetched":
            return (
                <span title="Data fetched">
                    <IconSvg name="tick" width={16} height={16} />
                </span>
            )
    }
    assertNever(p.data)
}

export const FetchIndicator = <T extends unknown>(p: FetchIndicatorProps<T>) => (
    <_FetchIndicatorContainer>{getFetchIndicator(p)}</_FetchIndicatorContainer>
)
