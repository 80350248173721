/* eslint-disable max-lines */
import { Err, isEmpty, Ok, isErr, isArray, isOk } from "../../utils/validators"
import { values, keys, remap, identity, unique, mapObject } from "../../utils/map"
import { collections, getNameField, getIdField, getCollectionFromId } from "../schemas"
import { getCopy } from "../../services/copy"
import { toOption, toStringOption } from "../../utils/types"
import {
    getIdFromRadarByName,
    getNameFromVM,
    getNameFromPC,
    getPreloadedItemValues,
    getIdFromPCByName
} from "./relationUtils"
import { mapAreaIdsByName } from "../searchAreas"
import { mapTagsIdsByName, tagToOption, segmentTagToOptionRelations, parseSegmentTag, displaySegmentTag } from "../tags"
import { DATA_PRESENTER, DATA_SEPARATOR } from "../shared"
import { cutNonLatinString } from "../../utils"
import { RadarCollectionsVMs, ViewModelsBase, RelationsByCName } from "../ViewModels"
import {
    PreloadedCollections,
    PreloadedCollection,
    PreloadedCollectionsVM,
    PreloadedCollectionVM
} from "../importing.types"
import {
    findDuplicates,
    getRequiredUniqueFieldsMessage,
    getUniqueFields,
    validateUniqueFields
} from "../uniqueConstraint"
import { UniqueConstraint } from "../uniqueConstraint.types"
import { ImportValidateItemsPayload } from "../../services/httpEndpoint/import/validateItems"
import { collectionsFieldsValidators, collectionsRelationsValidators } from "../collections"
import { isCollectionItemInvalid } from "../itemValidationUtils"
export const RECOGNITION_ERROR_MESSAGE = "Couldn't recognize"
export const SEGMENT_TAG_WRONG_SA_MESSAGE =
    "Segment tag search area is not assigned to an object. Please assign search area to an object or remove segment tag"

export const validateClassifiers = <T>(
    ids: string[] = [],
    namesById: SMap<T>,
    message: string
): Result<string[], string> => {
    const results = ids.map(id => {
        if (!namesById[id]) return Err("Not found", id)
        return Ok(id)
    })
    const badResults = results.filter<Err<string>>(isErr)
    const okResults = results.filter(isOk)

    if (!isEmpty(badResults))
        return Err(`${message}: ${badResults.map(r => r.obj).join(DATA_SEPARATOR)}`, [
            ...okResults.map(r => r.value),
            ...badResults.map(r => r.obj)
        ])

    return Ok([...okResults.map(r => r.value)])
}

export const validateMultiCollectionRelationships = <T extends CName>(
    cname: T,
    relatedObjectNames: string[],
    rcols: RadarCollections,
    pcs: PreloadedCollections
): Result<string[], string> => {
    const relationsResults = relatedObjectNames.map(name => {
        const idFromVM = getIdFromRadarByName(cname, rcols, name)
        if (idFromVM) return Ok(idFromVM)
        const idFromPC = getIdFromPCByName(cname, pcs, name)
        if (idFromPC) return Ok(idFromPC)
        return Err("Not found", name)
    })
    const badResults = relationsResults.filter<Err<string>>(isErr)
    const okResults = relationsResults.filter(isOk)
    if (!isEmpty(badResults))
        return Err(`${RECOGNITION_ERROR_MESSAGE} ${cname}: ${badResults.map(r => r.obj).join(DATA_SEPARATOR)}`)
    return Ok([...okResults.map(r => r.value)])
}

export const validateFields = <C extends CName>(cname: C, f: RCollection<C>): Result<RCollection<C>> => {
    const r = collectionsFieldsValidators[cname](f)
    if (isErr(r)) return r
    return Ok({ ...f, ...r.value })
}

export const validateDuplicationsInFields = (rcols: RadarCollections, pcs: PreloadedCollections) => <C extends CName>(
    cname: C,
    value: RCollection<C>,
    mode: ImportValidateItemsPayload["mode"],
    isEditing = false
): Result<
    {
        overwriteId: ObjectId | undefined
        fields: RCollection<C>
    },
    ExtErrors<RCollection<C>>
> => {
    const err: ExtErrors<RCollection<C>> = {}
    let overwriteId: ObjectId | undefined
    const collection = rcols[cname] as RadarCollectionsVMs[C]
    const idField = collections[cname].idField as keyof RCollection<C>
    const uc = collections[cname].uniqueConstraint as UniqueConstraint<keyof RCollection<C> & string, any, any>
    const pcItems = (getPCfromPCS(pcs)(cname)?.value || []).map(
        pcv => (getPreloadedItemValues("fields", pcv) as unknown) as RCollection<C>
    )

    const uniqueFieldsRes = getUniqueFields(uc)
    const uniqueMessageRes = getRequiredUniqueFieldsMessage(uc)
    if (isErr(uniqueFieldsRes)) return Err(uniqueFieldsRes.value, value)
    if (isErr(uniqueMessageRes)) return Err(uniqueMessageRes.value, value)
    const uniqueFields = uniqueFieldsRes.value
    const uniqueFieldsDisplay = uniqueMessageRes.value

    const validationResult = validateUniqueFields(uc, value)
    if (isErr(validationResult)) uniqueFields.forEach(f => ((err as any)[f] = validationResult.value))

    const dbDuplicatesRes = findDuplicates(uc, value, values(collection))
    const importDuplicatesRes = findDuplicates(uc, value, pcItems)
    if (isErr(dbDuplicatesRes) || isErr(importDuplicatesRes)) return Err("Cannot find duplicates", value)
    const dbDuplicates = dbDuplicatesRes.value
    const importDuplicates = importDuplicatesRes.value

    if (importDuplicates.length > 1) {
        importDuplicates.filter(d => d[idField] !== value[idField])
        const error = Err(
            `Duplicated value. You imported two or more items with the same unique field(s). Unique constraint is: ${uniqueFieldsDisplay}`,
            value
        )
        uniqueFields.forEach(f => ((err as any)[f] = error.value))
    } else if (dbDuplicates.length) {
        const matchingCollectionIdDuplicate = dbDuplicates.find(d => d[idField] === value[idField])

        if (mode === "import") {
            value[idField] = dbDuplicates[0][idField]
            overwriteId = value[idField] as any
            // TODO Verify...
        } else if (!(matchingCollectionIdDuplicate && dbDuplicates.length === 1 && isEditing)) {
            const error = Err(
                `Duplicated value. Value in radar exists with the same unique field(s). Unique constraint is: ${uniqueFieldsDisplay}`,
                value
            )
            uniqueFields.forEach(f => ((err as any)[f] = error.value))
        }
    }

    return isEmpty(err)
        ? Ok({
              overwriteId,
              fields: value
          })
        : Err(err, value)
}

export const validateCollectionRelations = (rcols: RadarCollections, pcs: PreloadedCollections, cnames: CName[]) => <
    C extends CName
>(
    cname: C,
    value: RCollectionRelations<C>
): Result<RCollectionRelations<C>> => {
    const err: ExtErrors<RCollection<C>> = {}
    cnames.forEach(<TC extends C>(col: TC) => {
        const relations: CName[] = (value as any)[col] || []
        if (isEmpty(relations)) return
        const multiCollectionsRelationsValidationResult = validateMultiCollectionRelationships(
            col,
            relations,
            rcols,
            pcs
        )
        if (isErr(multiCollectionsRelationsValidationResult))
            (err as any)[col] = multiCollectionsRelationsValidationResult.value
        else
            (value as any)[col] = multiCollectionsRelationsValidationResult.value.filter(oid => {
                const c = getCollectionFromId(oid)
                return c && cnames.includes(c)
            })
    })

    return isEmpty(err) ? collectionsRelationsValidators[cname](value) : Err(err, value)
}

export const extendRelations = (
    relations: TMap<ObjectId, OMap<CName, ObjectId[]>>,
    objectId: ObjectId,
    cname: CName,
    newRelations: string[]
) => {
    if (!relations[objectId]) relations[objectId] = {}
    if (!relations[objectId][cname]) relations[objectId][cname] = newRelations
    else relations[objectId][cname] = Array.from(new Set([...(relations[objectId][cname] || []), ...newRelations]))

    return relations
}

export const unifyRelations = (
    pcs: PreloadedCollectionsVM,
    collectionsMap: RadarCollections,
    relationsValidator: <C extends CName>(cname: C, value: RCollectionRelations<C>) => Result<RCollectionRelations<C>>,
    mode: ImportValidateItemsPayload["mode"]
): PreloadedCollectionsVM => {
    const currentRelations: TMap<ObjectId, OMap<CName, ObjectId[]>> = {}
    // Get all relations
    keys(pcs).forEach(<C extends CName>(cname: C) => {
        const pcObjects = pcs[cname]?.value as CollectionItemVM<C>[]
        const idField = getIdField(cname)

        pcObjects.forEach(o => {
            const fields = isOk(o.fields) ? o.fields.value : o.fields.obj
            const rs = isOk(o.relations) ? o.relations.value : o.relations.obj
            keys(rs).forEach(c => {
                const collectionRelations = (rs[c] || []) as string[]
                extendRelations(currentRelations, fields[idField], c as CName, collectionRelations)

                collectionRelations.forEach(r => extendRelations(currentRelations, r, cname, [fields[idField]]))
            })
        })
    })

    // Unify relations
    keys(pcs).forEach(<C extends CName>(cname: C) => {
        const pc = pcs[cname] as PreloadedCollectionVM<C>
        const pcObjects = pc.value as CollectionItemVM<C>[]
        const idField = getIdField(cname)
        let isValid = true

        pcObjects.forEach(o => {
            const fields = isOk(o.fields) ? o.fields.value : o.fields.obj
            o.relations = relationsValidator(cname, currentRelations[fields[idField]] || o.relations.value)
            o.relationsVM = mapRelationsToOptions(
                isOk(o.relations) ? o.relations.value : o.relations.obj,
                pcs,
                collectionsMap
            )
            if (isCollectionItemInvalid(o, mode)) isValid = false
        })
        pc.isValid = isValid
    })

    return pcs
}

export const validateClassifiersRelations = (
    classifiers: Pick<ViewModelsBase, "searchAreas" | "tags" | "segmentTags">,
    config: LocationParams
) => (value: ItemClassifiersInput, valuesType: "names" | "ids") => {
    const err: ExtErrors<ItemClassifiersInput> = {}

    if (!classifiers.searchAreas)
        return Err(
            {
                search_areas: `No radar fetched, couldnt verify ${getCopy("searchAreas")}`,
                tags: `No radar fetched, couldnt verify tags`,
                segment_tags: `No radar fetched, couldnt verify segment tags`
            },
            value
        )

    const areaNamesById = remap(classifiers.searchAreas, identity, v => v.name)
    const searchAreaIdsByName = mapAreaIdsByName(classifiers.searchAreas)

    // Primary search areas
    if (!isArray(value.primary_search_areas) && !isEmpty(value.primary_search_areas))
        err.primary_search_areas = `Wrong ${getCopy("primarySearchAreas")} type`
    else if (isEmpty(value.primary_search_areas))
        err.primary_search_areas = `There has to be at least one ${getCopy("primarySearchArea")}`
    else {
        const prepSA =
            valuesType === "ids"
                ? value.primary_search_areas
                : value.primary_search_areas.map(saname => searchAreaIdsByName[saname] || saname)
        const primaryAreasResult = validateClassifiers(
            prepSA,
            areaNamesById,
            `${RECOGNITION_ERROR_MESSAGE} ${getCopy("primarySearchAreas")}`
        )
        if (isErr(primaryAreasResult)) err.primary_search_areas = primaryAreasResult.value
        if (valuesType === "names")
            value.primary_search_areas = isErr(primaryAreasResult) ? primaryAreasResult.obj : primaryAreasResult.value
    }

    // Secondary search areas
    if (!isArray(value.secondary_search_areas) && !isEmpty(value.secondary_search_areas))
        err.secondary_search_areas = `Wrong ${getCopy("secondarySearchAreas")} type`
    else {
        const prepSA =
            valuesType === "ids"
                ? value.secondary_search_areas
                : (value.secondary_search_areas || []).map(saname => searchAreaIdsByName[saname] || saname)
        const areasResult = validateClassifiers(
            prepSA,
            areaNamesById,
            `${RECOGNITION_ERROR_MESSAGE} ${getCopy("secondarySearchAreas")}`
        )
        if (isErr(areasResult)) err.secondary_search_areas = areasResult.value
        if (valuesType === "names")
            value.secondary_search_areas = isErr(areasResult) ? areasResult.obj : areasResult.value
    }

    // Validate search area only in one field
    if (!err.secondary_search_areas && !err.primary_search_areas) {
        const duplicatedSA = value.primary_search_areas.filter(sa => value.secondary_search_areas?.includes(sa))
        if (duplicatedSA.length)
            err.secondary_search_areas = `${getCopy("searchArea")} cannot be both in ${getCopy(
                "primarySearchAreas"
            )} and ${getCopy("secondarySearchAreas")}${DATA_PRESENTER} ${duplicatedSA
                .map(said => classifiers.searchAreas[said]?.name || said)
                .join(DATA_SEPARATOR)}`
    }

    const tagNamesById = remap(classifiers.tags, identity, v => v.name)
    const tagsIdsByName = mapTagsIdsByName(classifiers.tags)
    const prepT =
        valuesType === "ids" ? value.tags : unique(value.tags || []).map(tname => tagsIdsByName[tname] || tname)
    const tagsValidationResult = validateClassifiers(prepT, tagNamesById, `${RECOGNITION_ERROR_MESSAGE} tags`)
    if (isErr(tagsValidationResult)) err.tags = tagsValidationResult.value
    if (valuesType === "names")
        value.tags = isErr(tagsValidationResult) ? tagsValidationResult.obj : tagsValidationResult.value

    if (config.withSegmentTags) {
        const segmentTagNamesById = mapObject(classifiers.segmentTags, (_, v) =>
            displaySegmentTag(classifiers.searchAreas)(v)
        )
        const prepST =
            valuesType === "ids"
                ? value.segment_tags
                : unique(value.segment_tags || []).map(stname => {
                      const { name: vstname, searchArea } = parseSegmentTag(stname)
                      return (
                          values(classifiers.segmentTags).find(
                              st => st.name === vstname && st.areaId === searchAreaIdsByName[searchArea]
                          )?.segmentId || stname
                      )
                  })
        const segmentTagsValidationResult = validateClassifiers(
            prepST,
            segmentTagNamesById,
            `${RECOGNITION_ERROR_MESSAGE} segment tags`
        )
        if (isErr(segmentTagsValidationResult)) err.segment_tags = segmentTagsValidationResult.value
        else {
            const segmentTagsSearchAreasValidationResult = validateSegmentTagsSearchAreas(
                segmentTagsValidationResult.value,
                [...(value.primary_search_areas || []), ...(value.secondary_search_areas || [])],
                classifiers.segmentTags,
                classifiers.searchAreas
            )
            if (isErr(segmentTagsSearchAreasValidationResult))
                err.segment_tags = segmentTagsSearchAreasValidationResult.value
        }
        if (valuesType === "names")
            value.segment_tags = isErr(segmentTagsValidationResult)
                ? segmentTagsValidationResult.obj
                : segmentTagsValidationResult.value
    }

    if (!isEmpty(err)) return Err(err, value)
    else return Ok(value)
}

export const validateSegmentTagsSearchAreas = (
    segmentTagIds: string[],
    searchAreasIds: string[],
    segmentTags: TMap<SegmentTag["segmentId"], SegmentTag>,
    searchAreas: TMap<SearchArea["areaId"], SearchArea>
): Result<string[], string> => {
    const wrongSASegmentTags = segmentTagIds.filter(stid => !searchAreasIds.includes(segmentTags[stid]?.areaId))

    if (wrongSASegmentTags.length)
        return Err(
            `${SEGMENT_TAG_WRONG_SA_MESSAGE}: ${wrongSASegmentTags
                .map(stid => {
                    const a = searchAreas[segmentTags[stid].areaId]
                    return `${a.name}: ${segmentTags[stid].name}`
                })
                .join(DATA_SEPARATOR)}`
        )
    return Ok([])
}

export const mapRelationsToOptions = <C extends CName>(
    relations: RCollectionRelations<C>,
    pcs: PreloadedCollections,
    rcols: RadarCollections
): Casted<RCollectionRelations<C>, ROptions> =>
    remap(relations, identity, (rels, cname: CName) =>
        isEmpty(rels)
            ? []
            : (rels
                  .map(r =>
                      toOption(
                          getNameFromVM(cname, rcols, r) || getNameFromPC(cname, r, getPCfromPCS(pcs)(cname)) || r,
                          r
                      )
                  )
                  .filter(v => v.label) as ROptions)
    ) as Casted<RCollectionRelations<C>, ROptions>

export const mapClassifiersToOptions = (
    { primary_search_areas = [], secondary_search_areas = [], tags = [], segment_tags = [] }: ItemClassifiersInput,
    vms: Pick<ViewModelsBase, "searchAreas" | "tags" | "segmentTags">,
    config: LocationParams
): Casted<ItemClassifiersInput, ROptions<AreaId>> => {
    const r: Casted<ItemClassifiersInput, ROptions<AreaId>> = {
        primary_search_areas: primary_search_areas.map(areaId => {
            const area = vms.searchAreas[areaId]
            if (!area) return toStringOption(areaId)
            return toOption(area.name, areaId)
        }),
        secondary_search_areas: secondary_search_areas.map(areaId => {
            const area = vms.searchAreas[areaId]
            if (!area) return toStringOption(areaId)
            return toOption(area.name, areaId)
        }),
        tags: tags.map(tid => {
            const tag = vms.tags[tid]
            if (!tag) return toOption(tid, tid)
            return tagToOption(false)(tag)
        })
    }
    if (config.withSegmentTags) {
        r.segment_tags = segment_tags.map(stid => {
            const segmentTag = vms.segmentTags[stid]
            if (!segmentTag) return toOption(stid, stid)
            return segmentTagToOptionRelations({ searchAreas: vms.searchAreas, useNameAsId: false })(segmentTag)
        })
    }

    return r
}

export const getPCfromPCS = (pcs: PreloadedCollections) => <C extends CName>(c: C): PreloadedCollection<C> =>
    pcs[c] as any
export const getPCVMfromPCSVM = (pcs: PreloadedCollectionsVM) => <C extends CName>(c: C): PreloadedCollectionVM<C> =>
    pcs[c] as any

export const mapItemsToCollectionOption = (radarVMs: RadarCollectionsVMs, objectRelations: RelationsByCName = {}) => (
    cname: CName
) => {
    const colItems = radarVMs[cname] || {}
    return keys(colItems).reduce((options, k) => {
        if ((objectRelations[cname] || []).includes(k)) return options

        const name = cutNonLatinString(colItems[k][getNameField(cname)] as string, 50)
        const id = colItems[k][getIdField(cname)] as string

        return options.concat(toOption(name, id))
    }, [] as ROptions)
}
