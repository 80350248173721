/* eslint-disable max-lines */
import { Cmd } from "redux-loop"
import * as Sentry from "@sentry/browser"

import { getFirebase } from "../../services/firebase"
import { validateClaim, validateUser } from "../../../functions/src/models/User"
import { pushHistory, replaceHistory, getStore } from ".."
import { materialize, matchesPath } from "../../utils/router"
import { actions as uiActions } from "../ui/actions"
import { assertNever, usleep } from "../../../functions/src/utils"
import { isValidLocation, isInvalidLocation } from "../../models/LocationType"
import { actions } from "./actions"
import { LoggedIn, NotLoggedIn, LoginError, isLoggedIn } from "../../models/LoginStatus"
import { isEqual } from "../../utils"
import { Fetched } from "../../../functions/src/utils/types"
import { paths, Path } from "../../paths"
import { userCloudActionsCmd, publicCloudActionsCmd, firestoreCmd } from "../../utils/reduxLoop"
import { publicCloudActions, userCloudActions } from "../../../functions/src/actions/actionCreators"
import { validateRadarConfig } from "../../../functions/src/models/Radar"
import { validateHub } from "../../../functions/src/models/common"
import { values, toMap, identity } from "../../../functions/src/utils/map"
import { fetchExtHub } from "../../models/ExtHub"
import { Err, isErr, errors, isOk } from "../../../functions/src/utils/validators"
import { getPerf } from "../../services/performance"
import { subscribeOnUserActionResults } from "../data/subscriptions"
import {
    AuthorizationType,
    AuthenticationState,
    ValidLocationState,
    InvalidLocationState,
    AppLocationState
} from "../../models/auth"
import { FilterType } from "../../../functions/src/models/filtering"
import { NavigationParams } from "../../utils/router.types"
import { cmdList } from "../../utils/redux"

const getClaim = async (radarId: string, userId: string): Promise<Result<Claim>> => {
    const { firestore } = getFirebase()
    const userClaims = firestore.ref("radarsClaims", radarId, "claims", userId)
    try {
        return firestore.fetchDoc(userClaims, validateClaim)
    } catch (e) {
        Sentry.captureException(
            new Error(`Error fetching user claim from db ${userId} for radar ${radarId}, ${JSON.stringify(e)}`)
        )
        return Err("Error trying to fetch data about user claims")
    }
}

const ExtUser = (user: User, tokenClaim: TokenClaim): ExtUser => ({
    userId: user.userId!,
    timestamp: 0,
    email: user.email,
    displayName: user.displayName || "",
    radarIds: tokenClaim.activeRadarIds || [],
    adminRadarIds: tokenClaim.adminRadarIds || []
})

const AttachRadarClaimDetails = (user: ExtUser, claim: Claim): ExtUser => ({
    ...user,
    timestamp: claim.timestamp,
    newsfeedVisitTimestamp: claim.newsfeedVisitTimestamp
})

export const fetchConfigs = () =>
    firestoreCmd(async firestore => (await firestore.fetchCol(firestore.ref("configs"), validateRadarConfig)).valid, {
        onSuccess: auths => actions._setConfigs(Fetched(auths)),
        onError: actOnFirestoreError("configs")
    })

export const fetchHubs = (configs: SMap<LocationParams>) =>
    firestoreCmd(
        async firestore => {
            const hubs = (await firestore.fetchCol(firestore.ref("hubs"), validateHub)).valid
            const extHubs = await Promise.all(values(hubs).map(fetchExtHub(firestore, configs)))
            return toMap(extHubs, h => h.hubId, identity)
        },
        { onSuccess: hs => actions._setHubs(Fetched(hs)), onError: actOnFirestoreError("hubs") }
    )

export const getAccessStatusForClaim = (c: Claim): AuthorizationType => {
    switch (c.type) {
        case "Requested":
            return "AccessRequested"
        case "Declined":
            return "AccessDeclined"
        case "Invited":
            return "AccessPending"
        case "Approved":
            if (c.isDeactivated) return "AccessDeactivated"
            if (c.isAdmin) return "AdminAccess"
            return "RadarAccess"
    }
    assertNever(c.type)
}

export const tryFederatedLoginCmd = (token: string) =>
    Cmd.run(
        () => {
            getPerf().startSingleInstanceTrace("FEDERATED_LOGIN")
            return getFirebase().auth.instance.signInWithCustomToken(token)
        },
        {
            failActionCreator: ({ message }) => actions._setLoginStatus(LoginError(message))
        }
    )

export const tryLogoutCmd = (lp?: Partial<LocationParams>) =>
    cmdList(
        [
            Cmd.run(() => getFirebase().auth.instance.signOut(), { successActionCreator: () => actions._flushState() }),
            lp?.radarSlug
                ? Cmd.action(actions.navigate({ path: paths["user/login"].path, slugs: { radarSlug: lp?.radarSlug } }))
                : undefined
        ].filter(Boolean)
    )

export const tryRequestAccessCmd = (actionId: string, radarId: string, { email, userId }: User, hostname: string) =>
    userCloudActionsCmd({ userId: userId!, actionId }, userCloudActions.requestAccess({ radarId, email, hostname }))

export const signInViaEmail = (actionId: string, p: { email: string }) =>
    publicCloudActionsCmd(actionId, publicCloudActions.signInViaEmail({ ...p, hostname: window.location.origin }))

// This action cannot be done on just the frontend, and needs the backend to cooperate.
// publicCloudActionsCmd records an entry in the publicActions collection
// This one is processed by the reducer in functions/src/actions/public/public.ts
export const checkDeliverableEmail = (actionId: string, p: { email: string }) =>
    publicCloudActionsCmd(actionId, publicCloudActions.checkDeliverableEmail({ email: p.email }))

export const DEMO_EMAIL_GIVEN_LS_KEY = "demo_email_given_radicle"

export const setEmailGivenToLocalStorageCmd = (email: string) =>
    Cmd.run(() => localStorage.setItem(DEMO_EMAIL_GIVEN_LS_KEY, email))

export const getUserTokenClaims = async () => {
    const { auth } = getFirebase()
    if (!auth.instance.currentUser) return null
    const token = await auth.instance.currentUser.getIdTokenResult(true)
    return token.claims as TokenClaim
}

export const refreshToken = (location: AppLocationState, currentLoginStatus: AuthenticationState) =>
    Cmd.run(
        async () => {
            await getFirebase().auth.refreshToken()
            return calculateLoginStatus(location, currentLoginStatus)(getFirebase().auth.getCurrentUser())
        },
        { failActionCreator: actOnFirestoreError("refresh token") }
    )

export const fetchUserFromDatabase = async (uid: string) => {
    const { firestore } = getFirebase()
    try {
        return firestore.fetchDoc(firestore.ref("users", uid), validateUser)
    } catch (e) {
        Sentry.captureException(new Error(`Error fetching user from db ${uid}, ${JSON.stringify(e)}`))
        return Err("Error trying to fetch data about user")
    }
}

export const onAuthChanged = async (
    params: AppLocationState,
    fbUser: firebase.User | null
): Promise<AuthenticationState | null> => {
    if (!fbUser) return NotLoggedIn(Boolean(localStorage.getItem(DEMO_EMAIL_GIVEN_LS_KEY)))
    if (!isValidLocation(params)) return null

    getPerf().stopSingleInstanceTrace("LOGIN")
    getPerf().stopSingleInstanceTrace("FEDERATED_LOGIN")
    let userBase = await fetchUserFromDatabase(fbUser.uid)

    // User needs to be created for the first time
    let trial = 0
    while (isErr(userBase) && userBase.value === "Not found" && trial < 15) {
        await usleep(2000)
        userBase = await fetchUserFromDatabase(fbUser.uid)
        trial++
    }

    if (isErr(userBase)) return LoginError(`${JSON.stringify(userBase.value)}`)
    const tokenClaims = await getUserTokenClaims()
    if (!tokenClaims) return null
    const user = ExtUser(userBase.value, tokenClaims)
    if (tokenClaims.userType === "root") return LoggedIn(user, "RootAccess")

    const { radarId } = params.locationParams
    if (!radarId) return LoggedIn(user, "RadarAccess")

    const claim = await getClaim(radarId, user.userId || user.email)

    if (isErr(claim))
        return claim.value === errors.notFound
            ? LoggedIn(user, "AccessNotRequested")
            : LoginError(`${JSON.stringify(claim.value)}`)

    return LoggedIn(AttachRadarClaimDetails(user, claim.value), getAccessStatusForClaim(claim.value))
}

export const onUserStatusChange = (currentLoginStatus: AuthenticationState, newLoginStatus: AuthenticationState) =>
    Cmd.run(() => {
        if (!isLoggedIn(currentLoginStatus) && isLoggedIn(newLoginStatus))
            subscribeOnUserActionResults(newLoginStatus.user.userId!)
    })

export const calculateLoginStatus = (location: AppLocationState, currentLoginStatus: AuthenticationState) => async (
    fbUser: firebase.User | null
) => {
    const loginStatus = await onAuthChanged(location, fbUser)
    if (!loginStatus || isEqual(loginStatus, currentLoginStatus)) return
    getStore().dispatch(actions._setLoginStatus(loginStatus))
}

export const subscribeOnClaimChange = (location: AppLocationState, currentLoginStatus: AuthenticationState) => {
    const { firestore } = getFirebase()
    if (!isValidLocation(location) || !isLoggedIn(currentLoginStatus)) return
    const { radarId } = location.locationParams
    const { userId, email } = currentLoginStatus.user
    if (!radarId) return

    firestore.subscribeOnDoc(
        firestore.ref("radarsClaims", radarId, "claims", userId || email),
        validateClaim,
        r => {
            if (isOk(r)) calculateLoginStatus(location, currentLoginStatus)(getFirebase().auth.getCurrentUser())
        },
        actOnFirestoreError("subscribe on claims")
    )
}

export const subscribeOnAuthChange = (location: AppLocationState, currentLoginStatus: AuthenticationState) =>
    Cmd.run(() => getFirebase().auth.onAuthStateChanged(calculateLoginStatus(location, currentLoginStatus)))

export const flushSubscriptions = () =>
    firestoreCmd(async fs => fs.flushSubscriptions([fs.ref("publicActionsResults").path]), {
        onSuccess: () => actions.navigate({ replace: true })
    })

export const saveEmail = ({ radarId, radarName }: Pick<LocationParams, "radarId" | "radarName">, email: string) =>
    firestoreCmd(
        async firestore =>
            firestore.addDoc(firestore.ref("contactRecords", radarId, "records"), {
                email,
                radarName,
                radarId,
                timestamp: new Date().getTime()
            }),
        {
            onSuccess: () => actions._setDemoEmailGiven(email),
            onError: actOnFirestoreError("saveEmail()")
        }
    )

const matchPath = (pathType: Path, uri: string) => matchesPath(paths[pathType].path, uri)
const deleteFilter = (searchParams: URLSearchParams, filter: FilterType) => searchParams.delete(filter)
export const filterSearchParams = (sourcePath: Path, targetPath: Path, searchParams: URLSearchParams) => {
    if (matchPath("radar/newsfeed", sourcePath) || matchPath("radar/dashboard", targetPath))
        deleteFilter(searchParams, "collections")
    return searchParams.toString()
}

export const materializedNavigateCmd = (
    l: ValidLocationState | InvalidLocationState,
    { path, slugs: params, delay, replace, searchParams, preserveSearchParams, newTab }: NavigationParams
) =>
    Cmd.run(async () => {
        if (delay) await usleep(delay)
        const auth = isInvalidLocation(l) ? {} : (l.locationParams as LocationParams)
        let targetPath = path ? materialize(path, { ...auth, ...params }) : l.pathname
        if (preserveSearchParams)
            targetPath += `?${filterSearchParams(
                l.pathname as Path,
                targetPath as Path,
                new URLSearchParams(l.search)
            )}`
        else if (searchParams) targetPath += `?${searchParams.toString()}`
        if (newTab) return window.open(targetPath, "_blank", "noopener,noreferrer")
        return replace ? replaceHistory(targetPath) : pushHistory(targetPath)
    })

export const actOnFirestoreError = (from: string) => (err: any) => {
    // eslint-disable-next-line no-console
    console.error("Error occured after trying to fetch:", from, err)
    Sentry.captureException(new Error(`Error occured after trying to fetch: ${from}; Error: ${err}`))
    if (err?.code === "permission-denied") return uiActions.openPopup("permissions")
    return uiActions.openPopup("error")
}
