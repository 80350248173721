/* eslint-disable max-lines */
import * as React from "react"
import { connect } from "react-redux"

import { actions } from "../store/ui/actions"
import { initialFilters } from "../store/ui"

import { FilterProps, FilterActions, getPropsForFilter } from "../components/filters/Filter"
import { toMap, identity, omitObject } from "../../functions/src/utils/map"
import { isEmpty } from "../../functions/src/utils/validators"
import { getMapDispatch } from "../utils/redux"
import { getCurrentRadarId } from "../models/LocationType"
import { MapState } from "../utils/redux.types"
import { FiltersColumn } from "../components/filters/FiltersColumn"
import { FiltersBar } from "../components/filters/FiltersBar"
import { RootState } from "../store/store"
import { FilterType } from "../../functions/src/models/filtering"
import { keys } from "../../functions/src/utils/map"
import { assertNever } from "../../functions/src/utils"
import { mapFilterValue } from "../components/filters/utils"

type OwnProps = React.PropsWithChildren<{
    filters: FilterType[]
    withClear?: boolean
    resetOnPickTypes?: FilterType[]
    cname?: CName
    wrap?: boolean
}>

type StateProps = {
    [P in FilterType]: Loadable<FilterProps>
}

export type CommonFilterProps = StateProps & ActionProps & OwnProps
const initialFiltersKeys = keys(initialFilters)

const Filters = (p: CommonFilterProps & { column?: boolean }) => {
    // Remove unknown filters
    const filters = React.useMemo(() => p.filters.filter(f => initialFiltersKeys.includes(f)), [p.filters])
    const areFiltersSet = !!filters.find(type => {
        const props = p[type]
        if (!props || props.loading) return false
        switch (props.type) {
            case "checkbox":
            case "radio":
                return !isEmpty(props.value.selected)
            case "dateRange":
                return !isEmpty(props.value.value![0]) || !isEmpty(props.value.value![1])
            case "input":
            case "search":
                return !isEmpty(props.value.value)
            case "collection":
                return props.value.isSet
            case "range":
                return props.value.value[0] !== props.value.min || props.value.value[1] !== props.value.max
        }

        assertNever(props)
    })

    const onChange = (key: FilterType): FilterActions["onChange"] => payload => {
        if ((p.resetOnPickTypes || []).includes(key)) {
            // TODO Refactor [keys] -> key
            p.updateFilters(omitObject(initialFilters, [key]))
        }

        switch (payload.type) {
            case "checkbox":
                return p.toggleFilter(key as any, mapFilterValue(payload))
            case "radio":
            case "input":
            case "search":
            case "range":
            case "collection":
                return p.updateFilters({ [key]: mapFilterValue(payload) })
            case "dateRange":
                return p.updateFilters(mapFilterValue(payload))
        }

        assertNever(payload)
    }

    const onClear = (key: FilterType): FilterActions["onClear"] => payload => {
        // This does the same thing as onChange. However, payload is guaranteed to have the correct
        // value set as null
        return p.updateFilters({ [key]: mapFilterValue(payload) })
    }

    React.useEffect(() => {
        // Clear not applicable filters on mount
        p.updateFilters(omitObject(initialFilters, filters))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const FiltersComponent = p.column ? FiltersColumn : FiltersBar

    return (
        <FiltersComponent
            {...p}
            filters={filters}
            onChange={onChange}
            onClear={onClear}
            areFiltersSet={areFiltersSet}
        />
    )
}

const mapState: MapState<StateProps, OwnProps> = (state, { cname }) => {
    const radarId = getCurrentRadarId(state.auth)!
    return toMap(keys(state.ui.filters), identity, getPropsForFilter(state, radarId, cname))
}

type ActionProps = ReturnType<typeof mapDispatch>
const mapDispatch = getMapDispatch(actions, ["toggleFilter", "updateFilters", "resetFilters"])

export const FiltersContainer = connect<StateProps, ActionProps, OwnProps, RootState>(mapState, mapDispatch)(Filters)
