/* eslint-disable max-lines */
import * as React from "react"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import {
    InputPropsBase,
    getInputProps,
    toFormState,
    toInputState,
    DropdownInputProps,
    getDropdownInputProps,
    InputProps
} from "../../utils/forms"
import {
    _ErrorLabel,
    _InputWrapper,
    _Label,
    _Input,
    _TextAreaWrapper,
    _TextArea,
    _RadioView,
    _RadioWrapper,
    _PointInput,
    _SearchInputWrapper,
    _SelectContainer,
    _TinyInputWrapper
} from "./Input.styles"
import { SectionTitle } from "./SectionTitle"
import { _HorizontalSpace, _Right, _VerticalSpace } from "../../styles/common"
import { _CollectionWrapper } from "../../styles/forms"
import { _FormInput, FormView } from "./FormView"
import { IconButton } from "../common/Buttons"
import { replace } from "../../../functions/src/utils/map"
import { toOption } from "../../../functions/src/utils/types"
import { Dropdown, RadioDropdown } from "../Dropdown"
import { mkString } from "../../../functions/src/utils"
import { isEmpty, isErr } from "../../../functions/src/utils/validators"
import { IconSvg } from "../IconSvg"
import { ReactSelectAction } from "./FormlessInputs"
import { getSelectStyles } from "../reactSelectUtils"

export const getValidationError = (s: InputState<any>) =>
    s.validationResult && s.visited && isErr(s.validationResult) ? s.validationResult.value : null

export const ErrorLabel: React.FC<InputState<any>> = s => <_ErrorLabel>{getValidationError(s)} </_ErrorLabel>

export const BasicInput: React.FC<InputProps<any>> = p => (
    <>
        <SectionTitle {...p.schema} />
        <_InputWrapper>
            <_Label>{p.schema.name}</_Label>
            <_Input
                {...getInputProps(p)}
                type={p.schema.type === "number" ? "text" : p.schema.type}
                validationError={!!getValidationError(p.state)}
            />
            <ErrorLabel {...p.state} />
        </_InputWrapper>
    </>
)

export const TextAreaInput: React.FC<InputProps<any>> = p => (
    <_TextAreaWrapper>
        <_Label>{p.schema.name}</_Label>
        <_TextArea {...getInputProps<any, HTMLTextAreaElement>(p)} validationError={!!getValidationError(p.state)} />
        <ErrorLabel {...p.state} />
    </_TextAreaWrapper>
)

type RadioInputProps<T> = InputPropsBase<InputState<T>, InputOptionSchema<T>, F1<any>>
export const RadioInput: React.FC<RadioInputProps<any>> = p => (
    <_RadioView>
        <SectionTitle {...p.schema} />
        <_VerticalSpace base="4px" />
        <_Label>{p.schema.name}</_Label>
        {p.schema.values.map(([name, value]) => {
            const inputProps = getInputProps(p)
            return (
                <_RadioWrapper key={value} {...inputProps}>
                    <label>
                        <_PointInput
                            {...inputProps}
                            value={value}
                            type="radio"
                            checked={`${p.state.value}` === `${value}`}
                        />
                        <_HorizontalSpace base="14px" />
                        {name}
                    </label>
                </_RadioWrapper>
            )
        })}
    </_RadioView>
)

export const DropdownInput = <T extends any>(p: DropdownInputProps<T>): React.ReactElement => (
    <>
        <SectionTitle {...p.schema} />
        <_InputWrapper>
            <_Label>{p.schema.name}</_Label>
            {p.schema.type === "colorDropdown" ? (
                <RadioDropdown alignToLeft full closeOnSelect {...getDropdownInputProps(p)} />
            ) : (
                <Dropdown alignToLeft full closeOnSelect {...getDropdownInputProps(p)} />
            )}
            <ErrorLabel {...p.state} />
        </_InputWrapper>
    </>
)

type CollectionInputProps<T> = InputPropsBase<FormState<T>[], CollectionInputSchema<T>, F1<any>>
export const CollectionInput: React.FC<CollectionInputProps<any>> = p => {
    const CreateMutation = p.schema.mutate ? (
        <IconButton
            icon={{
                name: "green-plus",
                width: 16,
                height: 16
            }}
            fullWidth
            onClick={() =>
                p.setDelta([
                    ...p.state,
                    toFormState(p.schema.fields, p.schema.mutate ? p.schema.mutate.createValue : null)
                ])
            }>
            {p.schema.mutate.createLabel}
        </IconButton>
    ) : null

    return !p.state.length ? (
        <_CollectionWrapper key={p.schema.name}>
            <SectionTitle {...p.schema} />
            {CreateMutation}
        </_CollectionWrapper>
    ) : (
        <>
            {p.state.map((c, index) => (
                <_CollectionWrapper key={(p.schema.name || "") + index}>
                    <SectionTitle data-cy="collection-section-title" {...p.schema} />
                    <FormView
                        schema={p.schema.fields}
                        state={c}
                        setState={d => p.setDelta(replace(p.state, index, d))}
                    />
                    {p.schema.mutate && p.state.length > 0 ? (
                        <_Right>
                            <IconButton
                                fullWidth
                                icon={{
                                    name: "red-minus",
                                    width: 16,
                                    height: 16
                                }}
                                onClick={() => p.setDelta(p.state.filter((_, i) => i !== index))}>
                                {p.schema.mutate.removeLabel || "Remove"}
                            </IconButton>
                        </_Right>
                    ) : null}
                    {CreateMutation}
                </_CollectionWrapper>
            ))}
        </>
    )
}

type ListInputProps<T> = InputPropsBase<InputState<T>[], ListInputSchema<T>, F1<any>>
export const ListInput = <T extends any>(p: ListInputProps<T>): React.ReactElement => (
    <_CollectionWrapper key={p.schema.name || p.schema.sectionTitle}>
        <SectionTitle {...p.schema} />
        {p.state.map((s, index) => (
            <React.Fragment key={`${p.schema.type}-${index}`}>
                <_FormInput
                    schema={p.schema.field}
                    state={s}
                    setDelta={(value: InputState<T>) => p.setDelta(replace(p.state, index, value))}
                />
                {p.schema.mutate && p.state.length > 0 ? (
                    <_Right>
                        <IconButton
                            fullWidth
                            icon={{
                                name: "red-minus",
                                width: 16,
                                height: 16
                            }}
                            onClick={() => p.setDelta(p.state.filter((_, i) => i !== index))}>
                            {p.schema.mutate.removeLabel || "Remove"}
                        </IconButton>
                    </_Right>
                ) : null}
            </React.Fragment>
        ))}
        {p.schema.mutate ? (
            <IconButton
                fullWidth
                icon={{
                    name: "green-plus",
                    width: 16,
                    height: 16
                }}
                onClick={() =>
                    p.schema.mutate
                        ? p.setDelta([...p.state, toInputState(p.schema.field, p.schema.mutate.createValue)])
                        : null
                }>
                {p.schema.mutate.createLabel}
            </IconButton>
        ) : null}
    </_CollectionWrapper>
)

type CreatableChipsInputProps = InputPropsBase<InputState<string[]>, ChipsInputSchema, F1<any>>
export const CreatableChipsInput = (p: CreatableChipsInputProps): React.ReactElement => (
    <_CollectionWrapper key={p.schema.name || p.schema.sectionTitle}>
        <SectionTitle {...p.schema} />
        <CreatableSelect
            defaultValue={(p.state.value || []).map(v => toOption(v, v))}
            isMulti
            isClearable
            onFocus={() => p.setDelta({ ...p.state, active: true })}
            onBlur={() => p.setDelta({ ...p.state, active: false, visited: true })}
            onChange={(opts: ROption[] = []) =>
                p.setDelta(
                    toInputState(
                        p.schema.field,
                        opts.map(o => o.label)
                    )
                )
            }
            styles={getSelectStyles(!!getValidationError(p.state))}
        />
    </_CollectionWrapper>
)

type ChipsInputProps<T> = InputPropsBase<InputState<T[]>, InputOptionSchema<T>, F1<InputState<T[]>>>
export const ChipsInput = <T extends any>(p: ChipsInputProps<T>): React.ReactElement => {
    const options = p.schema.values.map(v => toOption(v[0], v[1])) as ROption<T>[]
    const currentValue = (p.state.value || [])
        .map(v => p.schema.values.find(pv => pv[1] === v) || [mkString(v), mkString(v)])
        .map(v => toOption(v[0], v[1]))
    const isError = !!getValidationError(p.state)

    return (
        <>
            <SectionTitle {...p.schema} />
            <_InputWrapper withMaxWidth>
                <_Label>{p.schema.name}</_Label>
                <_SelectContainer data-cy={`select-container-${p.schema.name}`}>
                    <Select
                        value={currentValue}
                        isMulti
                        isClearable={false}
                        isSearchable
                        options={options}
                        onFocus={() => p.setDelta({ ...p.state, active: true })}
                        onBlur={() => setTimeout(() => p.setDelta({ ...p.state, active: false, visited: true }), 0)}
                        // TODO: fixit using less hacky way ^^
                        onChange={(_: any, o: ReactSelectAction) => {
                            const newState =
                                o.action === "select-option"
                                    ? { value: (p.state.value || []).concat(o.option.value as any) }
                                    : { value: (p.state.value || []).filter(v => (v as any) !== o.removedValue.value) }
                            p.setDelta({
                                ...p.state,
                                validationResult: undefined,
                                ...newState,
                                active: false,
                                visited: true
                            })
                        }}
                        styles={getSelectStyles(isError)}
                    />
                </_SelectContainer>
                <ErrorLabel {...p.state} />
            </_InputWrapper>
        </>
    )
}

export type SearchInputProps = { value: string; onChange: F1<string>; onFocus?: F0; onClear: F0 }
export const SearchInput = (p: SearchInputProps) => (
    <_SearchInputWrapper>
        <div id="search">
            <IconSvg name="search-icon" width={18} height={18} />
        </div>
        <input value={p.value} onFocus={p.onFocus} onChange={e => p.onChange(e.target.value)} />
        {!isEmpty(p.value) ? (
            <div id="clearInput">
                <IconSvg name="close-icon" width={10} height={10} onClick={p.onClear} />
            </div>
        ) : null}
    </_SearchInputWrapper>
)

export type TinyInputProps = {
    value: string
    onEnter: F1<string>
    onBlur: F1<string>
    rightAlign?: boolean
    placeholder?: string
    disabled?: boolean
}
export const TinyInput = (p: TinyInputProps) => {
    const [text, setText] = React.useState(p.value)

    const onKeyUp = (ev: React.KeyboardEvent) => {
        if (ev.key === "Enter") {
            p.onEnter(text)
            ev.preventDefault()
        }
    }

    // This is a controlled component. We need to hook onChange and update our state text value
    const onChange = (ev: React.ChangeEvent) => {
        setText((ev.target as HTMLInputElement).value)
    }

    // Display new value if changed from outside
    React.useEffect(() => {
        setText(p.value)
    }, [p.value])

    return (
        <_TinyInputWrapper rightAlign={p.rightAlign}>
            <input
                value={text}
                placeholder={p.placeholder}
                onKeyUp={onKeyUp}
                onChange={onChange}
                onBlur={e => p.onBlur(e.target.value)}
                disabled={p.disabled}
            />
        </_TinyInputWrapper>
    )
}
