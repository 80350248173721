import * as React from "react"
import { getIntercomService } from "../../../services/intercom"
import { InfoButton } from "../../../styles/buttons"
import { Flex } from "../../../styles/common"
import { _BodyFont } from "../../../styles/typography"
import { _CardSectionContainer, _CardSectionTitle } from "./CardSection.styles"

export const RadicleInsights = () => {
    const intercomService = React.useMemo(() => getIntercomService(), [])

    return (
        <_CardSectionContainer>
            <_CardSectionTitle>Radicle insights</_CardSectionTitle>
            <_BodyFont s16>
                Through our{" "}
                <a href="https://radicleinsights.com/products/debrief" target="_blank" rel="noreferrer noopener">
                    Debrief
                </a>{" "}
                deep-dive product, Radicle can accelerate your understanding of this market and its opportunities{" "}
                <b>in only 30 days.</b>
            </_BodyFont>
            <Flex justify="flex-start">
                <InfoButton onClick={() => intercomService.showNewMessage()}>
                    I'm interested in learning more
                </InfoButton>
            </Flex>
        </_CardSectionContainer>
    )
}
