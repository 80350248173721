import * as XLSX from "xlsx"
import { keys } from "../../../utils/map"

export const toCSV = <T extends SMap<string | number>>(schema: Required<T>, objs: T[]) => {
    const fields = keys(schema)
    const sheet = XLSX.utils.json_to_sheet(
        [schema, ...objs.map(o => fields.reduce((acc, f) => ({ ...acc, [f]: o[f] }), {}))],
        { skipHeader: true }
    )

    return XLSX.utils.sheet_to_csv(sheet)
}

export const toXLSX = <T extends SMap<string | number>>(
    schema: Required<T>,
    objs: T[],
    title: string = "Untitled spreadsheet 1"
) => {
    const fields = keys(schema)
    const wb = XLSX.utils.book_new()
    const sheet = XLSX.utils.json_to_sheet(
        [schema, ...objs.map(o => fields.reduce((acc, f) => ({ ...acc, [f]: o[f] }), {}))],
        { skipHeader: true }
    )
    XLSX.utils.book_append_sheet(wb, sheet, title)
    return XLSX.write(wb, { bookType: "xlsx", type: "array" })
}
