import styled from "styled-components"

export const _ProgressTitle = styled.div`
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 11px;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
`
export const _ProgressLabel = styled.div`
    margin-bottom: 8px;
    font-size: 23px;
    line-height: 1.3;
`

export const _Bar = styled.div<{ progress: number }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${p => `${Math.min(p.progress, 100)}%`};
    background: #02b875;
`
export const _ProgressWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    background: rgb(197 225 213);

    width: 289px;
    height: 6px;
    border-radius: 3px;

    ${_ProgressLabel} {
        padding-left: 3px;
        margin-bottom: 8px;
        font-size: 23px;
        line-height: 1.3;
    }
`
