import * as React from "react"
import { useDropzone } from "react-dropzone"
import { _IconButton, _PureIconButton, Button, ButtonProps } from "../../styles/buttons"
import { _DNDButtonWrapper, _DNDButtonDescription } from "./Buttons.styles"
import { _h1 } from "../../styles/typography"
import { _InfoLink } from "../importing/InfoPanel.styled"
import { IconSvg, IconSvgProps } from "../IconSvg"

type IconButtonProps = ButtonProps & {
    disabled?: boolean
    fullWidth?: boolean
    right?: boolean
    icon: IconSvgProps
    onClick: F0
}
export const IconButton: React.FC<IconButtonProps> = ({ children, icon, ...p }) => (
    <_IconButton {...p}>
        <IconSvg {...icon} />
        <span>{children}</span>
    </_IconButton>
)

export const PureIconButton: React.FC<{ disabled?: boolean; icon: IconSvgProps; onClick: F0; title: string }> = ({
    icon,
    ...p
}) => (
    <_PureIconButton {...p}>
        <IconSvg {...icon} />
    </_PureIconButton>
)

export type DNDButtonProps = { onDrop: F1<File[]>; onInfoClick: F0 }
export const DNDButton: React.FC<DNDButtonProps> = ({ onDrop, onInfoClick }) => {
    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        accept: ".csv",
        onDrop,
        multiple: true,
        noClick: true
    })
    return (
        <_DNDButtonWrapper data-cy="dragSpace" isDragActive={isDragActive} {...getRootProps({ tabIndex: -1 })}>
            <_h1>Drag files here</_h1>
            <_h1>or</_h1>
            <input {...getInputProps()} />
            <Button data-cy="chooseButton" {...getRootProps({ className: "dropzone" })} onClick={open}>
                {!isDragActive ? `Choose files from device` : `Drop files here`}
            </Button>
            <_DNDButtonDescription>
                Please check below this box to see if all the files were accepted by our verification. If not, correct
                files or see our documentation on how to create correct files.
            </_DNDButtonDescription>
            <_InfoLink onClick={onInfoClick}>How to create files to import?</_InfoLink>
        </_DNDButtonWrapper>
    )
}
