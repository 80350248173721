import * as React from "react"
import { IconSvg, IconSvgProps } from "../IconSvg"
import { _InfoPanel, _InfoPanelIcon, _InfoPanelDetails, _InfoPanelTitle, _InfoLink } from "./InfoPanel.styled"

export type InfoPanelProps = {
    title: string
    description: string
    icon?: IconSvgProps
    onLinkClick?: F0
    linkDescription?: string
}
export const InfoPanel: React.FC<InfoPanelProps> = p => (
    <_InfoPanel>
        <_InfoPanelIcon>
            <IconSvg {...(p.icon || { name: "question-mark-white", width: 20, height: 20 })} />
        </_InfoPanelIcon>
        <_InfoPanelDetails>
            <_InfoPanelTitle>{p.title}</_InfoPanelTitle>
            <p>{p.description}</p>
            {p.onLinkClick && p.linkDescription ? (
                <_InfoLink onClick={p.onLinkClick}>{p.linkDescription}</_InfoLink>
            ) : null}
        </_InfoPanelDetails>
    </_InfoPanel>
)
