import { ViewModelsMap } from "../models/ViewModels"
import { EditorState, ContentState, convertFromRaw } from "draft-js"

export type CoreDraftBlockType =
    | "unstyled"
    | "paragraph"
    | "header-one"
    | "header-two"
    | "header-three"
    | "header-four"
    | "header-five"
    | "header-six"
    | "unordered-list-item"
    | "ordered-list-item"
    | "blockquote"
    | "code-block"
    | "atomic"

const mapBlockToPlainValue = (b: Draft.ContentBlock): string => {
    const type: CoreDraftBlockType = b.getType() as CoreDraftBlockType

    switch (type) {
        case "unordered-list-item":
            return ` - ${b.getText()}`
    }
    return b.getText()
}

// There is an issue with multiple draftjs instances so we couldn't be DRY
export const getLegacyTextStateBackend = (v: EditorState | string = "") => {
    if (v instanceof EditorState) return v
    try {
        const parsed = JSON.parse(v)
        return EditorState.createWithContent(convertFromRaw(parsed))
    } catch {
        return EditorState.createWithContent(ContentState.createFromText(v))
    }
}

export const mapRichTextValue = (v: string) =>
    getLegacyTextStateBackend(v)
        .getCurrentContent()
        .getBlockMap()
        .map(block => (block ? mapBlockToPlainValue(block) : ""))
        .join("\n")

export const mapRichTextFields = <C extends CName, T extends Displayable<ViewModelsMap[C]>>(
    o: T,
    fields: (keyof T)[]
): T => {
    const richTextFieldsMapped = fields.reduce<{ [K in keyof T]: T[K] }>((acc, f) => {
        acc[f] = mapRichTextValue(o[f]) as T[keyof T]
        return acc
    }, {} as any)

    return {
        ...o,
        ...richTextFieldsMapped
    }
}
