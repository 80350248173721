import * as React from "react"

export const useOutsideClick = (className: string, ref: Element | null, onClick: F0) => {
    React.useEffect(() => {
        const body = document.querySelector("body")
        if (!body) return
        const handler = ({ target }: Event) => {
            if ((target as Element).closest(`.${className}`) !== ref) {
                onClick()
            }
        }
        body.addEventListener("click", handler)
        return () => body.removeEventListener("click", handler)
    }, [className, ref, onClick])
}
