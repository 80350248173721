import {
    validate,
    validString,
    validEmail,
    validArrayString,
    validBoolean,
    validStringDef,
    validateMemberOf,
    validNumber,
    validBooleanDef,
    validateArray,
    validateEncryptionKey,
    validDef,
    Ok,
    validNumberDef,
    validateHexColor,
    validateString
} from "../utils/validators"
import { keys, pickIntersect } from "../utils/map"

export const isRoot = (user: TokenClaim): boolean => user.userType === "root"

export const userValidators: ValidationMap<Required<User>> = {
    email: validEmail,
    userId: validStringDef,
    displayName: validStringDef,
    radarIds: validArrayString
}
const allValues = <T extends string>(all: TMap<T, 0>): T[] => keys(all)

export const radarClaims = allValues<ClaimType>({ Invited: 0, Approved: 0, Requested: 0, Declined: 0 })

const validClaimType = [validateMemberOf(radarClaims)]

export const userClaimValidationMap: ValidationMap<Required<Claim>> = {
    isAdmin: validBoolean,
    isDeactivated: validBoolean,
    timestamp: validNumber,
    email: validEmail,
    type: validClaimType,
    userId: validStringDef,
    newsfeedVisitTimestamp: validNumberDef
}

export const tagValidationMap: ValidationMap<Required<Tag>> = {
    tagId: validString,
    name: validString
}

export const segmentTagValidationMap: ValidationMap<Required<SegmentTag>> = {
    segmentId: validString,
    name: validString,
    areaId: validString
}

export const commentValidationMap: ValidationMap<Required<ObjectComment>> = {
    meta: validDef(null, Ok),
    commentId: validString,
    createdTs: validNumber,
    objectId: validString,
    removed: validBooleanDef,
    text: validString,
    updatedTs: validNumber,
    userId: validString,
    userName: validStringDef,
    userEmail: validStringDef
}

export const commentStructValidationMap: ValidationMap<Required<CommentsStruct>> = {
    comments: [validateArray([validate<ObjectComment>(commentValidationMap)])]
}

export const radarUserRequestValidators: ValidationMap<Required<RadarUserRequest>> = {
    ...pickIntersect<RadarUserRequest>()(userClaimValidationMap, [
        "email",
        "isAdmin",
        "isDeactivated",
        "timestamp",
        "userId"
    ]),
    displayName: userValidators.displayName,
    radarId: validString
}

export const validateRadarUser = validate<RadarUser>({
    ...radarUserRequestValidators,
    type: validClaimType
})

export const validateRadarUserRequest = validate<RadarUserRequest>(radarUserRequestValidators)

export const validateUser = validate<User>(userValidators)

export const validateContactRecord = validate<ContactRecord>({
    email: validString,
    radarId: validString,
    radarName: [validateString], // Some records were saved as "" (validString is not passing it)
    timestamp: validNumber
})

export const validateClaim = validate<Claim>(userClaimValidationMap)
export const validateTag = validate<Tag>(tagValidationMap)
export const validateSegmentTag = validate<SegmentTag>(segmentTagValidationMap)

export const validateComment = validate<ObjectComment>(commentValidationMap)

export const validateComments = validate<{ comments: ObjectComment[] }>({
    comments: [validateArray([validateComment])]
})

export const validateDecorators = validate<DecoratorsMap>({
    pipelineStage: [],
    priorityRank: [],
    assignment: [],
    star: [],
    attachment: [],
    lead: []
})

export const validateSearchArea = validate<SearchArea>({
    attachments: null,
    areaId: validString,
    color: [validateHexColor],
    name: validString,
    summary: validStringDef,
    typeId: validStringDef // Change after migration or optionalize typeId
})

export const validateSecureKey = validate<RadarKeyCode>({
    key: [validateEncryptionKey]
})

export const validateAccessRequestStatus: Validator<UserAccessRequestResult> = validate<UserAccessRequestResult>({
    type: validString,
    payload: null
})

export const validateUserTokenClaims = validate<TokenClaim>({
    adminRadarIds: validArrayString,
    activeRadarIds: validArrayString,
    userType: [validateMemberOf<UserType>(["root", "user"])]
})
