/* eslint-disable max-lines */
import styled from "styled-components"
import { Flex, _FlexColumn, _VerticalSpace, _HorizontalSpace, _Spacer } from "../../styles/common"
import React from "react"
import { _BodyFont } from "../../styles/typography"
import {
    _SwitchLabelDescription,
    _SwitchView,
    _InputWrapper,
    _Label,
    _Input,
    _TextAreaWrapper,
    _TextArea,
    _ListInputContainer,
    _RadioView,
    _RadioWrapper,
    _PointInput,
    _InputHintText
} from "./Input.styles"
import {
    InputPropsBase,
    getInputProps,
    InputProps,
    InputState,
    InputRenderMap,
    InputOptionSchema,
    FormState,
    CollectionInputSchema,
    toFormState,
    FormView,
    StyledFormSchema,
    FormSchema,
    StyledInputsRenderMap,
    ListInputSchema,
    StyledFormView,
    MultiselectInputSchema,
    FormItemView,
    FormHookProps,
    FormHookResult
} from "@react-formless/core"
import { toOption, Option } from "@react-formless/utils"

import {
    getDropdownInputProps,
    isFormActive,
    toInputState,
    toResult,
    validateForm
} from "@react-formless/core/lib/forms"
import { SectionTitle } from "./SectionTitle"
import { ErrorLabel, getValidationError } from "./Input"
import { CheckboxDropdown, Dropdown } from "../Dropdown"
import { mkString, assertNever } from "../../../functions/src/utils"
import Select from "react-select"
import { PureIconButton } from "../common/Buttons"
import { replace, mapObject } from "../../../functions/src/utils/map"
import {
    _FormSectionContainer,
    _FormSectionTitleContainer,
    _FlexSectionContentContainer
} from "../../views/admin/RadarEdit.styles"
import { isSpecialNumber, Ok, runValidatorsRaw, isEmpty } from "../../../functions/src/utils/validators"
import { RichTextAreaInput } from "./RichEditor"
import { LinkButton } from "../../styles/buttons"
import { IconSvg } from "../IconSvg"
import { Icon } from "../../../functions/src/models/icons"
import { specialValuesRev, specialValues } from "../../../functions/src/models/converters"
import { getSelectStyles } from "../reactSelectUtils"

export type CustomFields<T> =
    | State<"switch", { name: keyof T; toValue?: F1<boolean, any>; fromValue: F1<string, any>; inline?: boolean }>
    | State<"section", { title: string; content: StyledFormSchema<T> }>
    | State<"selectableChips", { name: keyof T }>
    | State<"richTextarea", { name: keyof T }>

export type ReactSelectAction =
    | { action: "remove-value" | "pop-value"; removedValue: ROption }
    | { action: "select-option"; option: ROption }

const SwitchInputLabel = styled.label`
    position: relative;
    display: inline-block;
    width: 24px;
    height: 17px;
    flex-shrink: 0;
`
const _SwitchButton = styled.span`
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #edeff2;
    height: 6px;
    margin: auto;
    border-radius: 10px;

    &:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: -7.5px;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: #d3d5d8;
        transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out;
        border-radius: 50%;
    }
`

const SwitchInputInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${_SwitchButton}:before {
        transform: translateX(24px);
        background-color: #2196f3;
    }
`
const SwitchInputWrapper = styled(Flex)`
    align-items: center;
    margin: 8px 0;
`

type SwitchLabelProps = {
    text: string
    description?: string
}

const SwitchLabel = ({ text, description }: SwitchLabelProps) => (
    <_FlexColumn data-cy="switch-text-container">
        <_BodyFont s14 data-cy="switch-title">
            {text}
        </_BodyFont>
        <_VerticalSpace base="3px" />
        <_SwitchLabelDescription>{description && <_BodyFont s11>{description}</_BodyFont>}</_SwitchLabelDescription>
    </_FlexColumn>
)

export const SwitchInput: React.FC<
    InputPropsBase<any, any, any> & {
        toValue?: F1<boolean, any>
        fromValue?: F1<any, boolean>
        inline?: boolean
    }
> = p => {
    const ip = getInputProps(p)
    const checkboxValue = p.fromValue ? p.fromValue(ip.value) : Boolean(ip.value)
    const value = p.toValue ? p.toValue(checkboxValue) : Boolean(checkboxValue)
    const label = p.schema.values.find((v: any) => v[1] === value)?.[0]
    const icon: Icon | undefined = (p.schema as any).icon // TODO Make it better typed
    return (
        <_SwitchView data-cy="switch-view">
            <SwitchInputWrapper data-cy="switch-wrapper-with-description">
                {p.inline && (
                    <>
                        <_HorizontalSpace base="4px" />
                        {icon && (
                            <>
                                <IconSvg name={icon} width={23} height={23} />
                                <_HorizontalSpace base="10px" />
                            </>
                        )}
                        <SwitchLabel text={p.schema.name} />
                        <_Spacer />
                    </>
                )}
                <SwitchInputLabel data-cy="switch-clickable">
                    <SwitchInputInput
                        type="checkbox"
                        checked={checkboxValue}
                        onChange={v => {
                            v.persist()
                            ip.onChange &&
                                ip.onChange({
                                    target: { value: p.toValue ? p.toValue(v.target.checked) : v.target.checked }
                                } as any)
                        }}
                    />
                    <_SwitchButton />
                </SwitchInputLabel>
                {!p.inline && (
                    <>
                        <_HorizontalSpace base="16px" />
                        <SwitchLabel text={p.schema.name} description={label} />
                    </>
                )}
            </SwitchInputWrapper>
            {p.inline && <_VerticalSpace base="20px" />}
        </_SwitchView>
    )
}

export const BasicInput: React.FC<InputProps> = p => {
    const icon: Icon | undefined = (p.schema as any).icon // TODO Make it better typed
    const isError = !!getValidationError(p.state)
    const inputProps = getInputProps(p)
    return (
        <>
            <SectionTitle {...p.schema} />
            <_InputWrapper>
                <_Label error={isError}>
                    {icon && (
                        <>
                            <IconSvg name={icon} width={23} height={23} />
                            <_HorizontalSpace base="10px" />
                        </>
                    )}
                    {p.schema.name}
                </_Label>
                <_Input
                    {...inputProps}
                    value={
                        isSpecialNumber(specialValues, inputProps.value)
                            ? specialValuesRev[inputProps.value]
                            : inputProps.value
                    }
                    type={p.schema.type === "number" ? "text" : p.schema.type}
                    validationError={isError}
                />
                {!isEmpty(p.schema.hint) ? (
                    <_InputHintText>
                        <_BodyFont s14>{p.schema.hint}</_BodyFont>
                    </_InputHintText>
                ) : (
                    ""
                )}
                <ErrorLabel {...p.state} />
            </_InputWrapper>
        </>
    )
}

export const TextAreaInput: React.FC<InputProps> = p => {
    const icon: Icon | undefined = (p.schema as any).icon // TODO Make it better typed
    const isError = !!getValidationError(p.state)
    return (
        <_TextAreaWrapper>
            <_Label error={isError}>
                {icon && (
                    <>
                        <IconSvg name={icon} width={23} height={23} />
                        <_HorizontalSpace base="10px" />
                    </>
                )}
                {p.schema.name}
            </_Label>
            <_TextArea {...getInputProps(p)} validationError={isError} />
            <ErrorLabel {...p.state} />
        </_TextAreaWrapper>
    )
}

type RadioInputProps<T> = InputPropsBase<InputOptionSchema<T>, InputState<T>, F1<any>>
export const RadioInput: React.FC<RadioInputProps<any>> = p => (
    <_RadioView>
        <SectionTitle {...p.schema} />
        <_VerticalSpace base="4px" />
        <_Label>{p.schema.name}</_Label>
        {p.schema.values.map(([name, value]) => {
            const inputProps = getInputProps(p)
            return (
                <_RadioWrapper key={value} {...inputProps}>
                    <label>
                        <_PointInput
                            {...inputProps}
                            value={value}
                            type="radio"
                            checked={`${p.state.value}` === `${value}`}
                        />
                        <_HorizontalSpace base="14px" />
                        {name}
                    </label>
                </_RadioWrapper>
            )
        })}
    </_RadioView>
)

export const DropdownInput = <T extends unknown>(p: InputPropsBase<InputOptionSchema<T>, InputState<T>, F1<any>>) => {
    const icon: Icon | undefined = (p.schema as any).icon // TODO Make it better typed
    const isError = !!getValidationError(p.state)
    return (
        <_InputWrapper>
            <_Label error={isError}>
                {icon && (
                    <>
                        <IconSvg name={icon} width={23} height={23} />
                        <_HorizontalSpace base="10px" />
                    </>
                )}
                {p.schema.name}
            </_Label>
            <Dropdown alignToLeft full closeOnSelect {...getDropdownInputProps(p)} />
            <ErrorLabel {...p.state} />
        </_InputWrapper>
    )
}

export const getMultiselectInputProps = <T extends unknown[]>({
    state,
    schema,
    setDelta
}: InputPropsBase<MultiselectInputSchema<T>, InputState<T>, F1<any>>) => {
    const currentValue = schema.values.filter(v => state.value?.includes(v[1]))
    const runValidation = (v: T) => (schema.validators ? runValidatorsRaw<T, string>(schema.validators, v) : Ok(v))
    const options = schema.values.map(v => toOption(v[0], v[1]))
    const onSelect = (o: Option<T>) => {
        const newValue = (state.value?.includes(o.value)
            ? state.value.filter(v => v !== o.value)
            : [...(state.value || []), o.value]) as T
        setDelta({
            ...state,
            validationResult: runValidation(newValue),
            value: newValue,
            visited: true
        })
    }
    return {
        selected: currentValue.map(v => toOption(v[0], v[1])),
        options,
        onSelect
    }
}

export const MultiselectInput = <T extends any[]>(
    p: InputPropsBase<MultiselectInputSchema<T>, InputState<T>, F1<any>>
) => {
    const icon: Icon | undefined = (p.schema as any).icon // TODO Make it better typed
    const isError = !!getValidationError(p.state)
    return (
        <_InputWrapper>
            <_Label error={isError}>
                {icon && (
                    <>
                        <IconSvg name={icon} width={23} height={23} />
                        <_HorizontalSpace base="10px" />
                    </>
                )}
                {p.schema.name}
            </_Label>
            <CheckboxDropdown alignToLeft full buttonText="Object types" {...getMultiselectInputProps(p)} />
            <ErrorLabel {...p.state} />
        </_InputWrapper>
    )
}

type ChipsInputProps<T> = InputPropsBase<InputOptionSchema<T>, InputState<T[]>, F1<InputState<T[]>>>
export const ChipsInput = <T extends any>(p: ChipsInputProps<T>): React.ReactElement => {
    const options = p.schema.values.map(v => toOption(v[0], v[1])) as ROption<T>[]
    const currentValue = (p.state.value || [])
        .map(v => p.schema.values.find(pv => pv[1] === v) || [mkString(v), mkString(v)])
        .map(v => toOption(v[0], v[1]))
    const icon: Icon | undefined = (p.schema as any).icon // TODO Make it better typed
    const isError = !!getValidationError(p.state)

    return (
        <>
            <_InputWrapper withMaxWidth>
                <_Label error={isError}>
                    {icon && (
                        <>
                            <IconSvg name={icon} width={23} height={23} />
                            <_HorizontalSpace base="10px" />
                        </>
                    )}
                    {p.schema.name}
                </_Label>
                <Select
                    value={currentValue}
                    isMulti
                    isClearable={false}
                    isSearchable
                    options={options}
                    onFocus={() => p.setDelta({ ...p.state, active: true })}
                    onBlur={() => setTimeout(() => p.setDelta({ ...p.state, active: false, visited: true }), 0)}
                    // TODO: fixit using less hacky way ^^
                    onChange={(_: any, o: ReactSelectAction) => {
                        const newState =
                            o.action === "select-option"
                                ? { value: (p.state.value || []).concat(o.option.value as any) }
                                : { value: (p.state.value || []).filter(v => (v as any) !== o.removedValue.value) }
                        p.setDelta({
                            ...p.state,
                            validationResult: undefined,
                            ...newState,
                            active: false,
                            visited: true
                        })
                    }}
                    styles={getSelectStyles(isError)}
                />
                <ErrorLabel {...p.state} />
            </_InputWrapper>
        </>
    )
}

const _CollectionItem = styled(Flex)`
    align-items: center;
`

const _CollectionActions = styled(Flex)`
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;

    &,
    & > * + * {
        margin-left: 8px;
    }
`

type CollectionInputProps<T> = InputPropsBase<CollectionInputSchema<T>, FormState<T>[], F1<any>>
export const CollectionInput: React.FC<CollectionInputProps<any>> = p => {
    const CreateMutationButton = () =>
        p.schema.mutate ? (
            <PureIconButton
                icon={{
                    name: "blue-plus",
                    width: 16,
                    height: 16
                }}
                onClick={() =>
                    p.setDelta([
                        ...p.state,
                        toFormState(p.schema.fields, p.schema.mutate ? p.schema.mutate.createValue : null)
                    ])
                }
                title={p.schema.mutate.addNextLabel}
            />
        ) : null

    return !p.state.length ? (
        <Flex key={p.schema.name}>
            <CreateMutationButton />
        </Flex>
    ) : (
        <>
            {p.state.map((c, index) => (
                <_CollectionItem key={(p.schema.name || "") + index}>
                    <FormView
                        schema={mapObject(p.schema.fields, (_, v: any) =>
                            v.name === "Label name" ? { ...v, name: `Label ${index + 1}` } : v
                        )}
                        state={c}
                        setState={d => p.setDelta(replace(p.state, index, d))}
                        inputsRenderMap={customRenderMap}
                    />
                    <_CollectionActions>
                        {p.schema.mutate && p.state.length > 0 ? (
                            <PureIconButton
                                icon={{
                                    name: "simple-trash",
                                    width: 16,
                                    height: 16
                                }}
                                onClick={() => p.setDelta(p.state.filter((_, i) => i !== index))}
                                title={p.schema.mutate.removeLabel || "Remove"}
                            />
                        ) : null}
                        {index === p.state.length - 1 ? <CreateMutationButton /> : null}
                    </_CollectionActions>
                </_CollectionItem>
            ))}
        </>
    )
}

type ListInputProps<T> = InputPropsBase<ListInputSchema<T>, InputState<T>[], F1<any>>
export const ListInput = <T extends any>(p: ListInputProps<T>): React.ReactElement => {
    const icon: Icon | undefined = (p.schema as any).icon // TODO Make it better typed
    const isError = p.state.some(f => !!getValidationError(f))

    React.useEffect(() => {
        if (p.state.length < 1)
            p.setDelta([...p.state, toInputState(p.schema.field, p.schema.mutate?.createValue as any)])
    }, [p])

    return (
        <_ListInputContainer>
            <_Label error={isError}>
                {icon && (
                    <>
                        <IconSvg name={icon} width={23} height={23} />
                        <_HorizontalSpace base="10px" />
                    </>
                )}
                {p.schema.name}
            </_Label>
            {p.state.map((s, index) => (
                <Flex key={`${p.schema.type}-${index}`}>
                    <FormItemView
                        schema={p.schema.field}
                        state={s}
                        setDelta={value => p.setDelta(replace(p.state, index, value))}
                        renderOptions={p.renderOptions}
                    />
                    <_HorizontalSpace base="10px" />
                    {p.schema.mutate && p.state.length > 1 ? (
                        <div style={{ marginTop: "14px" }}>
                            <IconSvg
                                name="simple-trash"
                                width={16}
                                height={16}
                                onClick={() => p.setDelta(p.state.filter((_, i) => i !== index))}
                                alt={p.schema.mutate.removeLabel || "Remove"}
                            />
                        </div>
                    ) : null}
                </Flex>
            ))}
            {p.schema.mutate ? (
                <LinkButton
                    color="edit"
                    onClick={() =>
                        p.schema.mutate
                            ? p.setDelta([...p.state, toInputState(p.schema.field, p.schema.mutate.createValue as any)])
                            : null
                    }>
                    {p.schema.mutate.addNextLabel.toLocaleUpperCase()}
                </LinkButton>
            ) : null}
            <_VerticalSpace base="20px" />
        </_ListInputContainer>
    )
}

export const Row = styled.div`
    display: flex;
    align-items: center;

    & > * {
        flex: 1 1 0;
    }
`

export const customRenderMap: Partial<InputRenderMap<any>> = {
    text: BasicInput,
    number: BasicInput,
    textarea: TextAreaInput,
    select: DropdownInput,
    collection: CollectionInput,
    list: ListInput,
    radio: RadioInput,
    customOption: SwitchInput,
    multiselect: MultiselectInput
}

export const getStyledInputsRenderMap = <T extends any>(
    schema: FormSchema<T>
): Partial<StyledInputsRenderMap<T, CustomFields<T>>> => {
    const styledInputsRenderMap: Partial<StyledInputsRenderMap<T, CustomFields<T>>> = {
        Custom: ({ value, getProps, ...p }) => {
            switch (value.type) {
                case "section":
                    return (
                        <_FormSectionContainer data-cy={`form-section-container-${value.title}`}>
                            <_FormSectionTitleContainer data-cy={`form-section-title-container-${value.title}`}>
                                <_BodyFont s17 data-cy="form-section-title-text">
                                    {value.title}
                                </_BodyFont>
                            </_FormSectionTitleContainer>
                            <_FlexSectionContentContainer
                                grow={1}
                                data-cy={`form-section-content-container-${value.title}`}>
                                <StyledFormView
                                    {...p}
                                    styledSchema={value.content}
                                    styledInputsRenderMap={styledInputsRenderMap}
                                />
                            </_FlexSectionContentContainer>
                        </_FormSectionContainer>
                    )
                case "switch":
                    return (
                        <SwitchInput
                            {...p}
                            {...getProps(value.name, schema[value.name] as any)}
                            toValue={value.toValue}
                            fromValue={value.fromValue}
                            inline={value.inline}
                            schema={schema[value.name]}
                        />
                    )
                case "selectableChips":
                    return (
                        <ChipsInput {...(getProps(value.name, schema[value.name] as InputOptionSchema<any>) as any)} />
                    )
                case "richTextarea":
                    return (
                        <RichTextAreaInput
                            {...(getProps(value.name, schema[value.name]) as any)}
                            schema={schema[value.name]}
                        />
                    )
            }
            assertNever(value)
        },
        Row: p => <Row>{p.value}</Row>
    }
    return styledInputsRenderMap
}

export const customUseFormHook = <T extends any>({
    schema,
    ...p
}: FormHookProps<T> & { onSubmitError: F1<Result<T, T>> }): FormHookResult<T> => {
    const [state, setState] = React.useState(toFormState<T>(schema, (p.initialValue || {}) as any))
    const [touched, setTouched] = React.useState(false)
    const [submitted, setSubmitted] = React.useState(false)
    const result = toResult(schema, state)
    const handleSubmit = (e?: React.FormEvent) => {
        e?.preventDefault()
        setSubmitted(true)
        if (!touched) setTouched(true)
        if (result.type === "Err") {
            const validatedState = validateForm(schema, state)
            const validationResult = toResult(schema, validatedState)
            if (validationResult.type === "Err") {
                setState(validatedState)
                if (p.onSubmitError) p.onSubmitError(validationResult)
            } else if (p.onSubmit) p.onSubmit(validationResult.value)
        } else if (p.onSubmit) p.onSubmit(result.value)
    }

    const resetState = () => {
        setState(toFormState(schema, p.initialValue as any))
        setTouched(false)
        setSubmitted(false)
    }

    return {
        handleSubmit,
        result,
        formViewProps: {
            state,
            setState: s => {
                if (!touched) setTouched(true)
                setState(s)
            },
            schema
        },
        resetState,
        submitted,
        touched,
        active: isFormActive(schema, state)
    }
}
