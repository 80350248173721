import * as React from "react"
import { connect } from "react-redux"
import { toOption, Loaded, LoadingWithPayload } from "../../functions/src/utils/types"
import { HeaderTitle, mkRow } from "../components/table/TableViewController"
import { SubHeader, SubpageLayout } from "../components/Page"
import { _HorizontalSpace, _Left, _VerticalSpace } from "../styles/common"
import { IconButton } from "../components/common/Buttons"
import { _BodyFont } from "../styles/typography"
import { isEmpty } from "../../functions/src/utils/validators"
import { Table } from "../components/table/TableView"
import { values } from "../../functions/src/utils/map"
import { RadioDropdown } from "../components/Dropdown"
import { getDefaultSearchAreaFilter } from "../models/collections"
import { getCurrentRadarId } from "../models/LocationType"
import { mkTableTitleSchema, mkTableComputedSchema } from "../components/table/TableViewSchemas"
import { LoadableView } from "../utils/reactUtils"
import { ViewLoader } from "./ViewRenderer"
import { getDataByKeys, LoaderPayload } from "../store/data/dataSelectors"
import { useExport } from "../utils/hooks/useExport"
import { Loader } from "../components/common/Loader"
import { RadarLocationState } from "../models/auth"
import { MapState } from "../utils/redux.types"
import { Row, Cell, RowSchema } from "../components/table/TableView.types"
import { _noop } from "../../functions/src/utils"
import { getSearchAreaIdFromName } from "../../functions/src/models/searchAreas"
import { getAvailableCollections } from "../../functions/src/models/collections"

type StateProps = {
    rows: Row[]
    headerRow: Cell[]
    searchAreas: SearchArea[]
    radarId: string
}

const exportRowSchema: RowSchema<{ collectionName: CName }> = [
    mkTableTitleSchema("Data type", "collectionName"),
    mkTableComputedSchema("File type", "collectionName", "text", () => "CSV")
]

const _ExportList: React.FC<StateProps> = p => {
    const [selectedCols, setSelectedCols] = React.useState<CName[]>([])
    const [selectedSA, setSelectedSA] = React.useState<string>(getDefaultSearchAreaFilter())
    const options = [getDefaultSearchAreaFilter(), ...p.searchAreas.map(sA => sA.name)]
    const { exporting, doExport } = useExport(p.radarId)
    if (exporting) return <Loader />
    return (
        <>
            <SubHeader title="Exporting">
                <RadioDropdown
                    full
                    optionsXLocation="left"
                    selected={[toOption(selectedSA, null)]}
                    onSelect={({ label }) => setSelectedSA(label)}
                    options={options.map(s => toOption(s, null))}
                />
            </SubHeader>
            <_Left>
                <_HorizontalSpace base="16px" />
                <IconButton
                    icon={{
                        name: "export-icon",
                        width: 17,
                        height: 20
                    }}
                    onClick={() =>
                        doExport("zip", {
                            type: "all",
                            cnames: p.rows.map(r => r.id as CName),
                            filters: { searchArea: getSearchAreaIdFromName(p.searchAreas)(selectedSA) }
                        })
                    }>
                    <_BodyFont s15 color="theme6">
                        Export all
                    </_BodyFont>
                </IconButton>
                <IconButton
                    disabled={isEmpty(selectedCols)}
                    icon={{
                        name: "export-icon",
                        width: 17,
                        height: 20
                    }}
                    onClick={() =>
                        doExport("zip", {
                            type: "all",
                            cnames: selectedCols,
                            filters: { searchArea: getSearchAreaIdFromName(p.searchAreas)(selectedSA) }
                        })
                    }>
                    <_BodyFont s15 color="theme6">
                        Export selected
                    </_BodyFont>
                </IconButton>
            </_Left>
            <_VerticalSpace base="16px" />

            <SubpageLayout>
                <Table
                    rows={p.rows}
                    headerRow={p.headerRow}
                    onRowClick={_noop}
                    isSelectable
                    onRowSelect={cols => setSelectedCols(cols as any)}
                />
            </SubpageLayout>
        </>
    )
}

const mapState: MapState<Loadable<StateProps, LoaderPayload>> = ({ data, auth }) => {
    const radarId = getCurrentRadarId(auth)!
    const config = (auth.params as RadarLocationState).locationParams
    const availableCollections = getAvailableCollections(config)
    const deps = getDataByKeys(["searchAreas"], data[radarId])
    if (deps.loading) return LoadingWithPayload({ state: "Data" })
    return Loaded({
        radarId,
        searchAreas: values(deps.searchAreas),
        rows: availableCollections
            .map(cname => ({ collectionName: cname }))
            .map(cname => mkRow(cname.collectionName, exportRowSchema, cname)),
        headerRow: exportRowSchema.map(v => HeaderTitle(v.key, v.label, v.size, v.key))
    })
}

export const ExportList = connect(mapState)(LoadableView(ViewLoader, _ExportList))
