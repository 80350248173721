import styled from "styled-components"
import { _CenterMiddle } from "../importing/SelectFiles.styles"
import { styleIfProp } from "../../styles/common"

export const ModalOverlay = styled(_CenterMiddle)<{ background?: string }>`
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(5, 5, 5, 0.75);
    ${({ background }) => styleIfProp("background", `background: ${background}`)}
`

export const ModalContent = styled.div``
