import * as React from "react"
import { _FlexRow, _VerticalSpace } from "../../styles/common"
import { _TagFont, _BodyFont } from "../../styles/typography"
import { getContactedLeadsCount } from "../../../functions/src/models/comments"
import { CommentsVM } from "../../../functions/src/models/ViewModels"

export const TooltipCommentsPreview: React.FC<{ comments: CommentsVM }> = ({ comments }) => (
    <>
        <_FlexRow alignCenter>
            <_TagFont>Contacted Lead number:&nbsp;</_TagFont>
            <_BodyFont s14>{getContactedLeadsCount(comments)}</_BodyFont>
        </_FlexRow>
        <_VerticalSpace base="16px" />
        <_FlexRow alignCenter>
            <_TagFont>No. comments:&nbsp;</_TagFont>
            <_BodyFont s14>{comments?.length || 0}</_BodyFont>
        </_FlexRow>
    </>
)
