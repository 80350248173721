import { ViewModels, NewsDTO, ViewModelsBase } from "../../../models/ViewModels"
import { validatePartial, validateArray, Ok, validateAsync, validateSMap } from "../../../utils/validators"
import { toMap, identity, keys } from "../../../utils/map"
import { collectionsVMValidators } from "../../../models/collections"
import { cnames } from "../../../models/schemas"

const validateNews: Validator<NewsDTO> = Ok

export const viewModelsBaseValidationMap: ValidationMap<Required<ViewModelsBase>> = {
    news: [validateArray([validateNews])],
    pipelines: null,
    radar: null,
    users: null,
    relations: null,
    comments: null,
    decorators: null,
    tags: null,
    tagsAssignments: null,
    segmentTags: null,
    segmentTagsAssignments: null,
    secureKeys: null,
    searchAreas: null,
    searchAreasAssignments: null,
    subscribers: null,
    config: null,
    ...toMap(cnames, identity, cname => [validateSMap([collectionsVMValidators[cname]])])
}

const viewModelsValidationMap: ValidationMap<ViewModels> = toMap(keys(viewModelsBaseValidationMap), identity, k =>
    viewModelsBaseValidationMap[k] ? [validateAsync(viewModelsBaseValidationMap[k] as Validators<any>)] : null
)

export const validatePartialViewModels: Validator<Partial<ViewModels>> = validatePartial<ViewModels>(
    viewModelsValidationMap
)
