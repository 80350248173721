import * as React from "react"
import { _Bar, _ProgressWrapper, _ProgressLabel, _ProgressTitle } from "./Progress.styles"
import { _VerticalSpace } from "../../styles/common"

export type ProgressProps = { current: number; total: number; title?: string }
export const Progress: React.FC<ProgressProps> = ({ total, current, title }) => {
    const progress = Math.floor((current / total) * 100)
    return (
        <div>
            {title ? (
                <>
                    <_ProgressTitle>{title}</_ProgressTitle>
                    <_VerticalSpace base="20px" />
                </>
            ) : null}

            <_ProgressLabel>
                {current} out of {total}
            </_ProgressLabel>
            <_ProgressWrapper>
                <_Bar progress={progress} />
            </_ProgressWrapper>
        </div>
    )
}
