import { validString, validNumber, validate, validNumberDef, validStringDef } from "../utils/validators"
import { mapToDisplay } from "./common"
import {
    DEFAULT_PLACEHOLDER,
    sharedRequiredRadarFieldsValidationMap,
    sharedOptionalRadarFieldsValidationMap,
    sharedVMMetaValidationMap
} from "./shared"
import { RFields } from "./RFields"
import { formatMillions } from "./converters"
import { GrantVM, DisplayModelsMap } from "./ViewModels"

export const grantRequiredFieldsValidationMap: ValidationMap<Required<GrantFieldsRequired>> = {
    ...sharedRequiredRadarFieldsValidationMap,
    grant_title: validString
}
export const grantOptionalFieldsValidationMap: ValidationMap<Required<GrantFieldsOptional>> = {
    ...sharedOptionalRadarFieldsValidationMap,
    funding: validNumberDef,
    grant_url: validStringDef,
    group_url: validStringDef,
    location: validStringDef,
    research_group: validStringDef,
    university: validStringDef,
    years_funded: validNumberDef
}

export const grantFieldsValidationMap: ValidationMap<Required<GrantFields>> = {
    ...grantRequiredFieldsValidationMap,
    ...grantOptionalFieldsValidationMap
}

export const grantValidationMap: ValidationMap<Required<Grant>> = {
    ...grantFieldsValidationMap,
    grantId: validString,
    createdTs: validNumber
}

export const grantRelationsValidationMap: ValidationMap<Required<GrantRelations>> = {}

export const validateGrantFields = validate<GrantFields>(grantFieldsValidationMap)
export const validateGrantRelations = validate<GrantRelations>(grantRelationsValidationMap)
export const validateGrant = validate<Grant>(grantValidationMap)
export const validateGrantVM = validate<GrantVM>({
    ...grantValidationMap,
    ...sharedVMMetaValidationMap
})

export const displayGrant = (p: GrantVM, ph: string = DEFAULT_PLACEHOLDER): Displayable<GrantVM> => mapToDisplay(p, ph)

export const transformGrant = (r: GrantVM): DisplayModelsMap["grants"] => ({
    ...r,
    funding: formatMillions(r.funding, false)
})

export const grantsFields: CFields<"grants"> = RFields<GrantFieldsRequired, GrantFieldsOptional>(
    grantRequiredFieldsValidationMap,
    grantOptionalFieldsValidationMap
)

export const grantRelations: RFields<"grants"> = RFields<unknown, GrantRelations>({}, grantRelationsValidationMap)

export const GrantFieldsDisplay: Displayable<GrantVM> = {
    grant_title: "Grant Title",
    description: "Description",
    source: "Source",
    blurOnListing: "",
    createdTs: "Created At",
    grantId: "Grant Id",
    research_group: "Research group",
    funding: "Funding ($m)",
    grant_url: "Grant URL",
    group_url: "Group URL",
    location: "Location",
    university: "University",
    commentsCount: "No. of comments",
    contactedLeadsCount: "No. of contacted leads",
    years_funded: "Years Funded (last 10 years)",
    filterBounds: ""
}
