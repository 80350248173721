import { isFetched, Loading, Loaded, LoadingWithPayload, isFetchingOrFetched } from "../../../functions/src/utils/types"
import { toMap, mapObject, keys } from "../../../functions/src/utils/map"
import { displayFetchingKeys } from "./dataUtils"
import { getCurrentRadarId } from "../../models/LocationType"
import { isEmpty } from "../../../functions/src/utils/validators"
import { DependencyMap, getDependenciesForPath } from "../../dependencies"
import { initialViewModels } from "."
import { ViewModels, ViewModelsBase, DependencySchema } from "../../../functions/src/models/ViewModels"
import { RootState } from "../store"
import { MapState } from "../../utils/redux.types"
import { subscriptionDataTypes } from "../../../functions/src/models/dataSelectors"

export const getDataByKeys = <T extends keyof ViewModels>(
    deps: T[],
    vms: ViewModels = initialViewModels
): Loadable<{ [P in T]: ViewModelsBase[P] }> => {
    const isNotLoaded = deps.some(k => !isFetched(vms[k]))
    if (isNotLoaded) return Loading()
    const models = toMap(
        deps,
        (k: keyof ViewModels) => k,
        (k: keyof ViewModels) => (vms[k] as AsyncFetched<ViewModelsBase[typeof k]>).value
    ) as { [P in T]: ViewModelsBase[P] }
    return Loaded(models)
}

export const MAX_CONCURRENT_FETCHES = 3
export type LoaderPayload = { stateDescription?: string; stepDescription?: string }

export const getDataByPath = <Props, OwnProps = SMap<unknown>>() => <Path extends keyof DependencyMap>(
    path: Path,
    cb: (
        deps: Cast<UnionToIntersection<DependencyMap[Path]>, ViewModelsBase>,
        state: RootState,
        ownProps: OwnProps,
        modelsFetchState: ViewModels
    ) => Loadable<Props, LoaderPayload>
): MapState<Loadable<Props, LoaderPayload>, OwnProps> => (s, op) => {
    const dependencies = getDependenciesForPath(path)
    if (isEmpty(dependencies)) return cb({} as any, s, op, {} as any)

    const radarId = getCurrentRadarId(s.auth)
    if (!radarId) return LoadingWithPayload({ stateDescription: "Fetching radar", stepDescription: "1/2" })

    const data = s.data[radarId] || {}

    // isFetchingOrFetched is necessary for virtualized lists e.g. startups
    const isNotLoaded =
        subscriptionDataTypes.some(k => !isFetched<ViewModelsBase[typeof k]>(data[k])) ||
        keys(dependencies as DependencySchema).some(k => !isFetchingOrFetched<ViewModelsBase[typeof k]>(data[k]))

    if (isNotLoaded)
        return LoadingWithPayload({
            stateDescription: `Fetching ${displayFetchingKeys("tags")} & ${displayFetchingKeys("searchAreas")}`,
            stepDescription: "1/2"
        })

    const result = mapObject(
        dependencies as DependencySchema,
        (k: keyof ViewModels) => (data[k] as AsyncFetched<ViewModelsBase[typeof k]>).value
    ) as Cast<UnionToIntersection<DependencyMap[Path]>, ViewModelsBase>

    return cb(result, s, op, data)
}

export const getDataByDeps = <Props, OwnProps = SMap<unknown>>() => <D extends Partial<DependencySchema>>(
    dependencies: D,
    cb: (
        deps: Cast<UnionToIntersection<D>, ViewModelsBase>,
        state: RootState,
        ownProps: OwnProps,
        modelsFetchState: ViewModels
    ) => Loadable<Props, LoaderPayload>
): MapState<Loadable<Props, LoaderPayload>, OwnProps> => (s, op) => {
    if (isEmpty(dependencies)) return cb({} as any, s, op, {} as any)

    const radarId = getCurrentRadarId(s.auth)
    if (!radarId) return LoadingWithPayload({ stateDescription: "Fetching radar", stepDescription: "1/2" })

    const data = s.data[radarId] || {}

    const isNotLoaded =
        subscriptionDataTypes.some(k => !isFetched<ViewModelsBase[typeof k]>(data[k])) ||
        keys(dependencies as DependencySchema).some(k => !isFetchingOrFetched<ViewModelsBase[typeof k]>(data[k]))

    if (isNotLoaded)
        return LoadingWithPayload({
            stateDescription: `Fetching ${displayFetchingKeys("tags")} & ${displayFetchingKeys("searchAreas")}`,
            stepDescription: "1/2"
        })

    const result = mapObject(
        dependencies as DependencySchema,
        (k: keyof ViewModels) => (data[k] as AsyncFetched<ViewModelsBase[typeof k]>).value
    ) as Cast<UnionToIntersection<D>, ViewModelsBase>

    return cb(result, s, op, data)
}
