import * as React from "react"
import { connect } from "react-redux"
import { _TagFont, _BodyFont } from "../../styles/typography"
import { isEmpty } from "../../../functions/src/utils/validators"
import { _TagsContainer, _TagBase } from "./Tags.styles"
import { TagsEditorStateProps, TagsEditorOwnProps, tagEditorMapState } from "./TagsEditor"
import { Loader } from "../common/Loader"
import { SegmentTag } from "../segmentTags/SegmentTagsPreview"
import { RootState } from "../../store/store"
import { getCurrentRadarId } from "../../models/LocationType"
import { isFetched } from "../../../functions/src/utils/types"
import { Join } from "../../utils/reactUtils"

export const Tag: React.FC<{ value: Tag }> = p => <_TagBase key={p.value.tagId}>{p.value.name}</_TagBase>

export const _CardTagsPreview: React.FC<TagsEditorStateProps & TagsEditorOwnProps> = p => {
    if (p.loading) return <Loader />

    return (
        <_TagsContainer>
            {isEmpty(p.assignedTags) ? (
                <_BodyFont s14>This item does not have tags at this moment</_BodyFont>
            ) : (
                p.assignedTags.map(tag => <Tag key={tag.tagId} value={tag} />)
            )}
        </_TagsContainer>
    )
}

export const TooltipTagsPreview = (p: { tags: Tag[] }) => (
    <>
        <_TagFont>Tags</_TagFont>
        <_TagsContainer>
            {isEmpty(p.tags) ? (
                <_BodyFont s14>This item does not have Tags at this moment</_BodyFont>
            ) : (
                p.tags.map(tag => <Tag value={tag} key={tag.tagId} />)
            )}
        </_TagsContainer>
    </>
)

export const TooltipSegmentTagsComponent = (p: {
    segmentTags: SegmentTag[]
    searchAreas: TMap<AreaId, SearchArea>
}) => (
    <>
        <_TagFont>Segment Tags</_TagFont>
        <_TagsContainer>
            {isEmpty(p.segmentTags) ? (
                <_BodyFont s14>This item does not have Segment Tags at this moment</_BodyFont>
            ) : (
                <Join items={p.segmentTags}>
                    {st => <SegmentTag tag={st} key={st.segmentId} searchAreas={p.searchAreas} />}
                </Join>
            )}
        </_TagsContainer>
    </>
)

export const TooltipSegmentTagsPreview = connect((s: RootState) => {
    const radarId = getCurrentRadarId(s.auth)!
    const searchAreas = s.data[radarId].searchAreas
    return isFetched(searchAreas) ? { searchAreas: searchAreas.value } : {}
})(TooltipSegmentTagsComponent)

export const CardTagsPreview = connect(tagEditorMapState)(_CardTagsPreview)
