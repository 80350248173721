import styled from "styled-components"
import { BaseButton } from "../../../styles/buttons"
import { _FlexContainer } from "../../../styles/common"
import { _SearchAreaBadge } from "../../common"
import { _DropdownContainer, _DropdownWrapper } from "../../Dropdown.styles"

export const _SearchAreasCardSectionContainer = styled(_FlexContainer)`
    ${_SearchAreaBadge} {
        margin: 0;
    }

    ${_DropdownContainer},
    ${_DropdownWrapper},
    ${BaseButton} {
        width: 100%;
    }

    ${BaseButton} {
        min-width: unset;
    }
`
