import * as React from "react"
import { connect } from "react-redux"
import { _LayoutContainer } from "../components/Page"
import { Header } from "../components/Header"
import { _Title, _h1, _h3 } from "../styles/typography"
import { Button, LinkButton } from "../styles/buttons"
import { goBackHistory } from "../store"
import { _VerticalSpace, absoluteCenter } from "../styles/common"
import styled from "styled-components"
import { assertNever } from "../../functions/src/utils"
import { RequestAccessView } from "../views/user/RequestAccess"
import { actions } from "../store/auth/actions"
import { Loader } from "../components/common/Loader"
import { getPerf } from "../services/performance"
import { AuthReason, AuthResult } from "../store/middlewares/authMiddleware.types"
import { MapState, MapDispatch } from "../utils/redux.types"

const Center = styled.div`
    ${absoluteCenter}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const NotAllowedView: React.FC<{ reason: AuthReason; onLogout: F0 }> = p => {
    switch (p.reason) {
        case "NotAuthorized": {
            return (
                <_LayoutContainer>
                    <Header />
                    <Center>
                        <_Title>403</_Title>
                        <_h1>You are not authorized to view this page</_h1>
                        <_h3>Please contact your radar admin for more info.</_h3>
                        <_VerticalSpace base="30px" />
                        <Button onClick={goBackHistory}>Go back</Button>
                        <_VerticalSpace base="30px" />
                        <LinkButton onClick={p.onLogout}>Log out</LinkButton>
                    </Center>
                </_LayoutContainer>
            )
        }
        case "NotFound": {
            return (
                <_LayoutContainer>
                    <Header />
                    <Center>
                        <_Title>404</_Title>
                        <_h1>The page was not found</_h1>
                        <_VerticalSpace base="30px" />
                        <Button onClick={goBackHistory}>Go back</Button>
                    </Center>
                </_LayoutContainer>
            )
        }
        case "Deactivated": {
            return (
                <_LayoutContainer>
                    <Header />
                    <Center>
                        <_h1>
                            Your account has been deactivated, please contact{" "}
                            <a href="mailto:stu@radicleinsights.com">stu@radicleinsights.com</a> for futher info
                        </_h1>
                        <_VerticalSpace base="30px" />
                        <Button onClick={goBackHistory}>Go back</Button>
                        <_VerticalSpace base="30px" />
                        <LinkButton onClick={p.onLogout}>Log out</LinkButton>
                    </Center>
                </_LayoutContainer>
            )
        }
        case "RadarDisabled": {
            return (
                <_LayoutContainer>
                    <Header />
                    <Center>
                        <_h1>
                            This radar has been deactivated, please contact{" "}
                            <a href="mailto:stu@radicleinsights.com">stu@radicleinsights.com</a> for futher info
                        </_h1>
                        <_VerticalSpace base="30px" />
                        <Button onClick={goBackHistory}>Go back</Button>
                        <_VerticalSpace base="30px" />
                        <LinkButton onClick={p.onLogout}>Log out</LinkButton>
                    </Center>
                </_LayoutContainer>
            )
        }
        case "NoAccessGranted":
            return <RequestAccessView />
        case "ConfigsError": {
            return (
                <_LayoutContainer>
                    <Header />
                    <Center>
                        <_Title>500</_Title>
                        <_h1>Config fetching error. Please try again after a while.</_h1>
                    </Center>
                </_LayoutContainer>
            )
        }
        case "Authenticated":
        case "NotAuthenticated":
            return null // TODO Change this for better handling...
        default:
            return assertNever(p.reason)
    }
}

type ActionProps = { logout: F0 }
const Auth: React.FC<AuthResult & ActionProps> = p => {
    React.useEffect(() => {
        if (p.status === "Waiting") getPerf().startSingleInstanceTrace("AUTHENTICATION")
        else getPerf().stopSingleInstanceTrace("AUTHENTICATION")
    }, [p.status])
    return (
        <>
            {p.status === "Waiting" ? (
                <Loader fill loadingText="Authenticating" />
            ) : p.status === "NotAllowed" ? (
                <NotAllowedView reason={p.reason} onLogout={p.logout} />
            ) : (
                p.children
            )}
        </>
    )
}

const mapState: MapState<AuthResult> = s => s.auth.permissions

const mapDispatch: MapDispatch<ActionProps> = d => ({
    logout: () => d(actions.tryLogout())
})

export const AuthRenderer = connect(mapState, mapDispatch)(Auth)
