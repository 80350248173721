import { getAvailableCollections } from "../../../functions/src/models/collections"
import { isDemo } from "../../../functions/src/models/common"
import { isPG } from "../../../functions/src/models/Radar"
import { ViewModels, ViewModelsBase } from "../../../functions/src/models/ViewModels"
import { filterObject } from "../../../functions/src/utils/map"
import { Fetched, isFetched } from "../../../functions/src/utils/types"
import { getCurrentRadar } from "../../models/LocationType"
import { isAdmin, isLoggedIn, isRoot } from "../../models/LoginStatus"
import { ListParams } from "../../views/lists/Lists"
import { getUseListData } from "../../views/lists/useList"
import { RootState } from "../store"
import { mapCNameToTopObjectsState } from "./utils"

export const dataSelectors = {
    // TODO rid parameter?
    currentRadarConfig: (s: RootState) => {
        const current = getCurrentRadar(s.auth!)!
        const radarConfig = s.data[current.radarId!].config
        const rcv = isFetched(radarConfig) ? radarConfig.value : {}
        return { ...current, ...rcv } as LocationParams & ByRadarConfig
    },
    flags: (s: RootState) => {
        const c = dataSelectors.currentRadarConfig(s)
        const demo = dataSelectors.isDemo(s)
        const root = dataSelectors.isRoot(s)

        return {
            withAssignments: !demo && !root && c.withAssignments,
            withSegmentTags: !demo && c.withSegmentTags,
            withPipelines: !root,
            withStars: !demo && c.withStars,
            withPriorityRanks: c.withPriorityRanks,
            withContactedLead: !demo && !root && c.withContactedLead,
            withDashboard: !demo && c.withDashboard,
            withPipelinesSummary: !demo && !root && c.withPipelinesSummary,
            withTags: !demo,
            isPG: isPG(c.hubSlug)
        }
    },
    isRoot: (s: RootState) => isRoot(s.auth.authentication),
    isAdmin: (s: RootState) => isAdmin(s.auth.authentication),
    isDemo: (s: RootState) => isDemo(dataSelectors.currentRadarConfig(s)),
    authentication: (s: RootState) => s.auth.authentication,
    user: (s: RootState) => (isLoggedIn(s.auth.authentication) ? s.auth.authentication.user : null),
    availableCollections: (s: RootState) =>
        getAvailableCollections(dataSelectors.currentRadarConfig(s), dataSelectors.isRoot(s)),
    viewModels: (s: RootState): ViewModels => {
        const rid = dataSelectors.currentRadarConfig(s).radarId
        const rd = s.data[rid] || {}
        return (rid ? rd : {}) as ViewModels
    },
    approvedUsers: (s: RootState) => {
        const users = dataSelectors.viewModels(s).users
        return isFetched(users)
            ? Fetched(
                  filterObject(users.value, (_, v) => v.type === "Approved"),
                  users
              )
            : users
    },
    ui: (s: RootState) => s.ui,
    filters: (s: RootState) => s.ui.filters || {},
    sorting: (s: RootState) => s.ui.sorting || {},
    actionResults: (s: RootState) => s.cloud.actionsResults,
    dashboardSummary: (s: RootState) => s.async.dashboardSummary,
    topObjects: <C extends CName>(s: RootState, op: { cname: C }): Async<ViewModelsBase[C]> =>
        s.async[mapCNameToTopObjectsState(op.cname)] as any,
    sectorsPositioning: (s: RootState): Async<ViewModelsBase["sectors"]> => s.async.sectorsPositioning,
    topObjectsListData: <C extends CName>(s: RootState, op: { cname: C }): ListParams<C> => {
        const config = dataSelectors.currentRadarConfig(s)
        const vms = dataSelectors.viewModels(s)
        const ui = dataSelectors.ui(s)

        return getUseListData(
            op.cname,
            config,
            config,
            {
                ...vms,
                [op.cname]: dataSelectors.topObjects(s, op)
            },
            ui,
            isRoot(s.auth.authentication)
        )
    }
}

export type DataSelectors = typeof dataSelectors
export type DataSelectorName = keyof DataSelectors
export type DataSelectorState<T extends DataSelectorName> = ReturnType<DataSelectors[T]>
