/* eslint-disable max-lines */
import * as React from "react"
import { connect } from "react-redux"
import { getDataByDeps } from "../../store/data/dataSelectors"
import {
    Loaded,
    toOption,
    isNotStarted,
    isProcessing,
    MutationState,
    exhaustiveStringTuple
} from "../../../functions/src/utils/types"
import { SubpageLayout } from "../../components/Page"
import { _FlexColumn, _HorizontalSpace, _VerticalSpace, _Center, _FlexRow, _Spacer } from "../../styles/common"
import { Button } from "../../styles/buttons"
import { _DataContainer } from "./AdminDashboard.styles"
import { Table } from "../../components/table/TableView"
import { useFormHook } from "../../utils/hooks/useFormHook"
import {
    byCompareName,
    assignmentsByTagId,
    ExtTag,
    segmentTagSectionDisplayMap,
    validateSegmentTagDuplicate,
    validateSegmentTagName
} from "../../../functions/src/models/tags"
import { getCopy } from "../../../functions/src/services/copy"
import { values, remap, identity, filterObject, extend } from "../../../functions/src/utils/map"
import { FormView } from "../../components/form/FormView"
import { Loader } from "../../components/common/Loader"
import { Modal } from "../../components/Modal"
import { LoadableView, Join } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { actions as cloudActions } from "../../store/cloud/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { getMapDispatch2 } from "../../utils/redux"
import { Msg } from "../../models/notifications"
import { mkRow, HeaderTitle } from "../../components/table/TableViewController"
import { RowSchema } from "../../components/table/TableView.types"
import { mkTableComputedSchema, mkTableNumberSchema, mkTableTitleSchema } from "../../components/table/TableViewSchemas"
import { RadarViewFetchMap } from "../../dependencies"
import { SearchInput } from "../../components/form/Input"
import {
    isErr,
    validNotEmptyArrayString,
    validString,
    isEmpty,
    foldResult
} from "../../../functions/src/utils/validators"
import { getAreaValues } from "../../../functions/src/models/searchAreas"
import { ModalState, mkOpen, mkClosed, isOpen } from "../../models/modal"
import { _SegmentTagCreatorFormContainer, _SegmentTagEditorFormContainer } from "./SegmentTags.styles"
import { FilterProps, FilterActions, Filter, RadioFilterProps } from "../../components/filters/Filter"
import { assertNever } from "../../../functions/src/utils"
import { _ErrorLabel } from "../../components/form/Input.styles"
import { getDeletePopupText } from "./utils"

// FILTERS

type SegmentTagFilters = { kind: SearchAreaKind | null }
const emptyFilters: SegmentTagFilters = { kind: null }

type SegmentTagFilterType = ValueState<keyof SegmentTagFilters, FilterProps>
type SegmentTagFiltersProps = {
    onFilterChange: F1<SegmentTagFiltersAction>
    filterOptions: SegmentTagFilterType[]
    filters: SegmentTagFilters
}
type SegmentTagFilterChange<T extends keyof SegmentTagFilters> = ValueState<T, SegmentTagFilters[T]>
type SegmentTagFiltersAction = SegmentTagFilterChange<"kind">

const bySegmentTagKind = ({ kind }: SegmentTagFilters) => (tag: ExtSegmentTag) => kind === tag.kind
const filterSegmentTags = (segmentTags: TMap<string, ExtSegmentTag>, filters: SegmentTagFilters) => {
    const filterConditions: F1<ExtSegmentTag, boolean>[] = []
    if (!isEmpty(filters.kind)) filterConditions.push(bySegmentTagKind(filters))

    return filterObject(segmentTags, (_, v) => filterConditions.every(f => f(v), segmentTags))
}

const onClearNoop = (_key: any) => () => {
    /* Explicitly do nothing */
}
const SegmentTagFiltersBar = (p: SegmentTagFiltersProps) => {
    const onChange = (filterType: Type<SegmentTagFilterType>): FilterActions["onChange"] => onChangePayload => {
        if (onChangePayload.type === "radio")
            p.onFilterChange({ type: filterType, value: onChangePayload.v as SearchAreaKind | null })
    }
    return (
        <_FlexRow alignCenter>
            <Join items={p.filterOptions} renderJoining={() => <_HorizontalSpace base="16px" />}>
                {({ type, value }) => <Filter key={type} {...value} onChange={onChange(type)} onClear={onClearNoop} />}
            </Join>
        </_FlexRow>
    )
}

const filterOptions = (filters: SegmentTagFilters): SegmentTagFilterType[] => {
    const segmentTagTypes = exhaustiveStringTuple<SearchAreaKind>()("techPlatform", "business", "no_decorator")
    const kindToOption = (k: SearchAreaKind) => toOption(segmentTagSectionDisplayMap[k], k)
    return [
        {
            type: "kind",
            value: RadioFilterProps({
                options: segmentTagTypes.map(kindToOption),
                allOptionsText: "All Segment Tags",
                title: "Filter by segment tag type",
                selected: filters.kind ? [kindToOption(filters.kind)] : undefined
            })
        }
    ]
}

const reduceFilters: React.Reducer<SegmentTagFilters, SegmentTagFiltersAction> = (state, action) => {
    const ext = extend(state)
    switch (action.type) {
        case "kind":
            return ext({ kind: action.value })
    }
    assertNever(action.type)
}

const getEditSchema = (searchAreas: SMap<SearchArea>): FormSchema<SegmentTagCreatePayload> => ({
    name: { name: "Name", type: "text", validators: [validateSegmentTagName] },
    areaId: {
        name: `${getCopy("searchArea")}`,
        type: "dropdown",
        validators: validString,
        values: getAreaValues(searchAreas)
    }
})

const getCreateSchema = (searchAreas: SMap<SearchArea>): FormSchema<Value<SegmentTagBatchMutationPayload>> => ({
    name: { name: "Name", type: "text", validators: [validateSegmentTagName] },
    areaIds: {
        name: `${getCopy("searchAreas")}`,
        type: "selectableChips",
        validators: validNotEmptyArrayString,
        values: getAreaValues(searchAreas)
    }
})

type ExtSegmentTag = ExtTag<SegmentTag> & { kind: SearchAreaKind }
type SegmentTagEditorProps = {
    onSubmit: F1<any>
    tag: ExtSegmentTag | null
    searchAreas: SMap<SearchArea>
    segmentTags: SMap<SegmentTag>
    state: AsyncAction<CloudActionResult>
}
export const SegmentTagEditor: React.FC<SegmentTagEditorProps> = p => {
    const [error, setError] = React.useState<string | null>(null)
    const { formViewProps, onSubmitClick, resetState, result } = useFormHook({
        schema: getEditSchema(p.searchAreas),
        onSubmit: v => {
            const vresult = validateSegmentTagDuplicate(p)({ ...p.tag, ...v })
            if (isErr(vresult)) {
                setError(vresult.value)
            } else {
                setError(null)
                p.onSubmit(v)
                resetState()
            }
        },
        initialValue: { name: p.tag ? p.tag.name : "", areaId: p.tag?.areaId || "" }
    })

    return (
        <_Center>
            {isNotStarted(p.state) && (
                <_SegmentTagEditorFormContainer>
                    <FormView {...formViewProps} />
                    {error ? (
                        <>
                            <_ErrorLabel>{error}</_ErrorLabel>
                            <_VerticalSpace base="8px" />
                        </>
                    ) : null}
                    <_Center>
                        <Button onClick={onSubmitClick} disabled={isErr(result)}>
                            Update tag
                        </Button>
                    </_Center>
                </_SegmentTagEditorFormContainer>
            )}
            {isProcessing(p.state) && <Loader />}
        </_Center>
    )
}

export const SegmentTagCreator: React.FC<SegmentTagEditorProps> = p => {
    const [error, setError] = React.useState<string | null>(null)
    const { formViewProps, onSubmitClick, resetState, result } = useFormHook({
        schema: getCreateSchema(p.searchAreas),
        onSubmit: v => {
            const vresult = foldResult(
                v.areaIds.map(areaId => validateSegmentTagDuplicate(p)({ name: v.name, areaId }))
            )
            if (isErr(vresult)) {
                setError(vresult.value)
            } else {
                setError(null)
                p.onSubmit(v)
                resetState()
            }
        },
        initialValue: { name: "", areaIds: [] }
    })

    return (
        <_Center>
            {isNotStarted(p.state) && (
                <_SegmentTagCreatorFormContainer>
                    <FormView {...formViewProps} />
                    {error ? (
                        <>
                            <_ErrorLabel>{error}</_ErrorLabel>
                            <_VerticalSpace base="12px" />
                        </>
                    ) : null}
                    <_Center>
                        <Button onClick={onSubmitClick} disabled={isErr(result)}>
                            Create tag
                        </Button>
                    </_Center>
                </_SegmentTagCreatorFormContainer>
            )}
            {isProcessing(p.state) && <Loader />}
        </_Center>
    )
}

type StateProps = {
    segmentTags: SMap<ExtSegmentTag>
    segmentTagsAssignments: SMap<Assignments<TagId>>
    searchAreas: SMap<SearchArea>
    radar: RadarDetails
    actionsResults: SMap<CloudActionResult>
}
type StateActions = ReturnType<typeof mapDispatch>
type SegmentTagsProps = StateProps & StateActions

type TagModalState = ModalState<React.ReactText | null>

const SegmentTags: React.FC<SegmentTagsProps> = p => {
    const onRadar = <T extends SMap<unknown>>(t: T) => ({ radarId: p.radar.radarId, ...t })

    const [, setRemovingIds] = React.useState<string[]>([])
    const [tagEdit, setTagEdit] = React.useState<TagModalState>({ type: "closed" })
    const [search, setSearch] = React.useState("")
    const onClear = () => setSearch("")
    const [filteredTags, setFilteredTags] = React.useState(p.segmentTags)
    const [filters, setFilters] = React.useReducer(reduceFilters, emptyFilters)

    React.useEffect(() => {
        setFilteredTags(
            filterSegmentTags(
                filterObject(p.segmentTags, (_, tag) =>
                    tag.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                ),
                filters
            )
        )
    }, [search, filters, p.segmentTags])

    const [mutatingState, onMutate, resetState] = useCloudAction(p.mutateSegmentTag, p.actionsResults, r => {
        onClose()
        const payload = (r.action as ReturnType<typeof cloudActions.mutateSegmentTag>).payload
        if (payload.type === "delete") setRemovingIds(ids => ids.filter(id => id === payload.value))
        p.queueNotification(Msg(payload.type, { name: "segment tag" }, r.result))
        resetState()
    })

    const [batchCreatingState, onBatchCreate, resetCreatingState] = useCloudAction(
        p.createBatchSegmentTags,
        p.actionsResults,
        r => {
            onClose()
            const payload = (r.action as ReturnType<typeof cloudActions.createBatchSegmentTags>).payload
            p.queueNotification(Msg(payload.type, { name: "segment tag" }, r.result))
            resetCreatingState()
        }
    )

    const onCreate = () => setTagEdit(mkOpen(null))
    const onEdit = (id: React.ReactText) => setTagEdit(mkOpen(id as string))
    const onClose = () => setTagEdit(mkClosed())
    const onRemove = (tagId: string) => {
        p.openPopup("deleteConfirmation", {
            objectType: "Segment Tag",
            objectName: p.segmentTags[tagId].name,
            customText: getDeletePopupText("Segment Tag", p.segmentTags[tagId].name, p.segmentTags[tagId].useCnt),
            onDelete: () => {
                setRemovingIds(ids => ids.concat(tagId))
                onMutate(onRadar(MutationState("delete", tagId)))
            }
        })
    }

    const onEditorSubmit = (segmentId: string | null) => (payload: SegmentTagCreatePayload) =>
        onMutate(
            onRadar(segmentId ? MutationState("update", { ...payload, segmentId }) : MutationState("create", payload))
        )
    const onCreatorSubmit = (payload: Pick<Value<SegmentTagBatchMutationPayload>, "name" | "areaIds">) =>
        onBatchCreate(onRadar(MutationState("create", payload)))

    const schema: RowSchema<ExtTag<ExtSegmentTag>> = [
        mkTableTitleSchema("Name", "name"),
        mkTableComputedSchema("Type", "kind", "text", stag => segmentTagSectionDisplayMap[stag.kind]),
        mkTableComputedSchema(getCopy("searchArea"), "areaId", "text", t => p.searchAreas[t.areaId]?.name || ""),
        mkTableNumberSchema<ExtTag<SegmentTag>>("Tagged objects count", "useCnt")
    ]

    const headerRow = schema.map(v => HeaderTitle(v.key, v.label, v.size, v.key))
    const segmentTagRows = values(filteredTags)
        .sort(byCompareName)
        .map(t => mkRow(t.segmentId, schema, t))

    return (
        <SubpageLayout>
            <Modal
                isOpen={isOpen(tagEdit)}
                onClose={onClose}
                title={isOpen(tagEdit) && tagEdit.value ? "Update segment tag" : "Add segment tag"}>
                {isOpen(tagEdit) && tagEdit.value ? (
                    <SegmentTagEditor
                        tag={tagEdit.value ? p.segmentTags[tagEdit.value] : null}
                        segmentTags={p.segmentTags}
                        onSubmit={onEditorSubmit(tagEdit.value as string)}
                        state={mutatingState}
                        searchAreas={p.searchAreas}
                    />
                ) : (
                    <SegmentTagCreator
                        tag={null}
                        segmentTags={p.segmentTags}
                        onSubmit={onCreatorSubmit}
                        state={batchCreatingState}
                        searchAreas={p.searchAreas}
                    />
                )}
            </Modal>
            <_FlexColumn grow={1}>
                <_FlexRow>
                    <_HorizontalSpace base="16px" />
                    <SegmentTagFiltersBar
                        onFilterChange={setFilters}
                        filters={filters}
                        filterOptions={filterOptions(filters)}
                    />
                    <_HorizontalSpace base="16px" />
                    <SearchInput value={search} onChange={setSearch} onClear={onClear} />
                    <_Spacer />
                    <_HorizontalSpace base="16px" />
                    <Button onClick={onCreate}>Add Segment Tag</Button>
                    <_HorizontalSpace base="16px" />
                </_FlexRow>
                <_VerticalSpace base="16px" />
                <_DataContainer>
                    <Table
                        rowHeight={50}
                        interactiveOptions={[toOption("Edit", onEdit), toOption("Remove", onRemove)]}
                        virtualized
                        rows={segmentTagRows}
                        headerRow={headerRow}
                    />
                </_DataContainer>
                <_VerticalSpace base="24px" />
            </_FlexColumn>
        </SubpageLayout>
    )
}

const mapDispatch = getMapDispatch2(cloudActions, ["createBatchSegmentTags", "mutateSegmentTag"], uiActions, [
    "queueNotification",
    "openPopup"
])
const mapState = getDataByDeps<StateProps>()(
    RadarViewFetchMap({ segmentTags: "all", searchAreas: "all", radar: "single" }, { segmentTagsAssignments: "all" }),
    ({ searchAreas, segmentTags, radar, segmentTagsAssignments }, { cloud: { actionsResults } }) => {
        const segmentsByTagId = assignmentsByTagId(segmentTagsAssignments)
        const extTags: SMap<ExtSegmentTag> = remap(segmentTags, identity, (stag, tid) => ({
            ...stag,
            useCnt: values(segmentsByTagId[tid]).filter(Boolean).length || 0,
            kind: (searchAreas[stag.areaId]?.typeId as SearchAreaKind) || "no_decorator"
        }))
        return Loaded({
            segmentTags: extTags,
            searchAreas,
            radar,
            actionsResults,
            segmentTagsAssignments
        })
    }
)

export const SegmentTagsView = connect(mapState, mapDispatch)(LoadableView(ViewLoader, SegmentTags))
