import * as React from "react"

import { asyncWithDefault, isFetching } from "../../../functions/src/utils/types"

import { asyncConnect } from "../../store/async/asyncConnect"
import { Loader } from "../../components/common/Loader"
import { isEmpty } from "../../../functions/src/utils/validators"
import { CompanyDetails } from "./SectorCardStartups"
import { collectionsVMConverters } from "../../../functions/src/models/collections"
import { detailsPaths } from "../../paths"

export type CardCompaniesProps<C extends CName> = {
    objectId: string
    cname: C
}

export const CompaniesCardView = asyncConnect<CardCompaniesProps<CName>>()({
    data: ["viewModels", "isDemo"],
    actions: ["navigate"]
})(p => {
    const relations = React.useMemo(() => asyncWithDefault(p.viewModels.relations, {}), [p.viewModels.relations])
    const companiesToDisplay = React.useMemo(
        () =>
            (relations[p.objectId]?.companies || [])
                .map(cid => asyncWithDefault(p.viewModels.companies, {})[cid])
                .filter(c => !isEmpty(c))
                .map(c => collectionsVMConverters.companies(c)),
        [relations, p.objectId, p.viewModels.companies]
    )
    return isFetching(p.viewModels.companies) ? (
        <Loader />
    ) : (
        <CompanyDetails
            companies={companiesToDisplay}
            onItemClick={companyId =>
                p.navigate({ path: detailsPaths["details/companies"].path, slugs: { companyId } })
            }
            isDemo={p.isDemo}
        />
    )
})
