import { ColRef, DocRef } from "../../../functions/src/services/firebase/firestore"
import { Firestore } from "../../../functions/src/services/firebase"

import { validateRadar } from "../../../functions/src/models/Radar"
import {
    validateSecureKey,
    validateTag,
    validateSearchArea,
    validateClaim,
    validateContactRecord,
    validateSegmentTag
} from "../../../functions/src/models/User"
import { identity } from "../../../functions/src/utils/map"
import { validatePipelineStage } from "../../../functions/src/models/common"
import { prepareSubscribers, preparePipelines } from "./dataUtils"
import { isDemoRadarLocation } from "../../models/LocationType"
import { SubscriptionDataTypes } from "../../../functions/src/models/ViewModels"
import { prepareUsers } from "../../../functions/src/services/httpEndpoint/data/users"
import { AppLocationState } from "../../models/auth"
import { RootContext } from "../store"

export type RadarFetchAttribute = "demo" | "root"
export type DataRestriction = "no-demo" | "no-root" | "force-no-fetch" | "root-only"
export type SubscriptionSchema<T = any, R = T> = (
    | State<"collection", { ref: ColRef<SMap<R>>; transform: F1<SMap<R>, T | Promise<T>> }>
    | State<"document", { ref: DocRef<R>; transform: F1<R, T | Promise<T>> }>
) & {
    restrictions: DataRestriction[]
    validator: Validator<R>
}

export type DataRequest<T = any> = F1<DataRequestCallbacks<T>>
export type DataRequestCallbacks<T = any> = {
    onSuccess: F1<Async<T>>
    onFailure: F1<any>
}

const DocSchema = <T>(
    ref: DocRef<T>,
    validator: Validator<T>,
    restrictions: DataRestriction[] = []
): SubscriptionSchema<T> => ({
    type: "document",
    ref,
    validator,
    transform: identity,
    restrictions
})

const ColSchema = <T, R = T>(
    ref: ColRef<SMap<R>>,
    validator: Validator<R>,
    transform: F1<SMap<R>, T | Promise<T>>,
    restrictions: DataRestriction[] = []
): SubscriptionSchema<T, R> => ({
    type: "collection",
    ref,
    validator,
    transform,
    restrictions
})

export const getRadarFetchAttributes = (params: AppLocationState, ctx: RootContext) => {
    const radarFetchAttrs: RadarFetchAttribute[] = []
    if (ctx.authorizationType === "RootAccess") radarFetchAttrs.push("root")
    if (isDemoRadarLocation(params)) radarFetchAttrs.push("demo")
    return radarFetchAttrs
}

export const getRequestSchema = (fs: Firestore, key: SubscriptionDataTypes, radarId: string): SubscriptionSchema => {
    const { ref } = fs
    const requestMap = {
        radar: DocSchema(ref("radars", radarId), validateRadar),
        secureKeys: DocSchema(ref("secureKeys", radarId), validateSecureKey, ["no-root", "no-demo"]),
        users: ColSchema(ref("radarsClaims", radarId, "claims"), validateClaim, prepareUsers(fs, radarId), ["no-demo"]),
        subscribers: ColSchema(ref("contactRecords", radarId, "records"), validateContactRecord, prepareSubscribers, [
            "root-only"
        ]),
        tags: ColSchema(ref("byRadarId", radarId, "tags"), validateTag, identity),
        segmentTags: ColSchema(ref("byRadarId", radarId, "segmentTags"), validateSegmentTag, identity, ["no-demo"]),
        pipelines: ColSchema(ref("byRadarId", radarId, "pipelines"), validatePipelineStage, preparePipelines(radarId), [
            "no-demo",
            "no-root"
        ]),
        searchAreas: ColSchema(ref("byRadarId", radarId, "searchAreas"), validateSearchArea, identity)
    }
    return requestMap[key]
}
