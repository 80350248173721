import { isEmpty } from "../../utils/validators"

export type Copies = typeof defaultCopies

export const defaultCopies = {
    area: "Area",
    searchArea: "Search Area",
    searchAreas: "Search Areas",
    primarySearchArea: "Primary Focus Area",
    primarySearchAreas: "Primary Focus Areas",
    secondarySearchArea: "Secondary Focus Area",
    secondarySearchAreas: "Secondary Focus Areas",
    notPublicSearchArea: "This item is not attached to any Search Area at this moment.",
    filterBySearchArea: "Filter by Search Area",
    allSearchAreas: "All Search Areas",
    clusterName: "Area name",
    network: "Network",
    leads: "Leads"
}

export const businessCopies: Copies = {
    ...defaultCopies,
    area: "Collection",
    searchArea: "Collection",
    searchAreas: "Collections",
    primarySearchArea: "Primary Focus Collection",
    primarySearchAreas: "Primary Focus Collections",
    secondarySearchArea: "Secondary Collection",
    secondarySearchAreas: "Secondary Collections",
    notPublicSearchArea: "This item is not attached to any collections at this moment.",
    filterBySearchArea: "Filter by Business or Technology Platform",
    allSearchAreas: "All Collections",
    network: "Innovation Nodes and Networks",
    leads: "Commercial and Technical Leads"
}

export const getCopiesSource = (featureFlags: ConfigFeatureFlags) =>
    !isEmpty(featureFlags) && featureFlags.withJobClusters ? businessCopies : defaultCopies

let _copies = getCopiesSource({})

export const setCopySource = (featureFlags: ConfigFeatureFlags) => {
    _copies = getCopiesSource(featureFlags)
}

export const getCopy = <T extends keyof Copies>(name: T): Copies[T] => _copies[name]

export const copiesTuple: Tuples<boolean> = [
    [`Use ${businessCopies.searchAreas}`, true],
    [`Use Search Areas`, false]
]
