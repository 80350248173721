/* eslint-disable max-lines */
import * as React from "react"
import { connect, ConnectedComponent } from "react-redux"
import { useDropzone } from "react-dropzone"
import { FormView } from "../../components/form/FormView"
import {
    validString,
    validStringDef,
    validateHexColor,
    isErr,
    validateString
} from "../../../functions/src/utils/validators"
import { SubpageLayout, SubHeader } from "../../components/Page"
import { Button } from "../../styles/buttons"
import { _VerticalSpace, _Right, _Spacer, _FlexRow, _Center, _HorizontalSpace, _FlexColumn } from "../../styles/common"
import { _FormViewWrapper } from "../../styles/forms"
import { getCopy } from "../../../functions/src/services/copy"
import { values, toMap, identity, sortByOrder } from "../../../functions/src/utils/map"
import { actions as cloudActions } from "../../store/cloud/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { Msg } from "../../models/notifications"
import { Loaded, MutationState, Loading, toOption } from "../../../functions/src/utils/types"
import { useFormHook } from "../../utils/hooks/useFormHook"
import { getMapDispatch2 } from "../../utils/redux"
import { asyncForEach, capitalize } from "../../../functions/src/utils"
import { Modal } from "../../components/Modal"
import { _DNDFieldWrapper, _DNDField } from "../../components/common/Buttons.styles"
import { _BodyFont } from "../../styles/typography"
import { useStorageUpload } from "../../utils/hooks/useStorage"
import { downloadFile, mapRawAttachment } from "../../models/attachments"
import { getFirebase } from "../../services/firebase"
import { isLoggedIn, isRoot } from "../../models/LoginStatus"
import { MB_LIMIT_10 } from "../cards/CardAttachments"
import { Table } from "../../components/table/TableView"
import { mkTableTitleSchema, mkTableComputedSchema } from "../../components/table/TableViewSchemas"
import { HeaderTitle, mkRow } from "../../components/table/TableViewController"
import { AttachmentsTable } from "../../components/AttachmentTable"
import {
    searchAreaColors,
    areaTypes,
    getAreaType,
    byNameAsc,
    getAreaOrder
} from "../../../functions/src/models/searchAreas"
import { LoadableView } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import { Loader } from "../../components/common/Loader"
import { getDataByDeps } from "../../store/data/dataSelectors"
import { RowSchema } from "../../components/table/TableView.types"
import { NO_DECORATOR } from "../../../functions/src/models/decoratorValues"
import { RadarViewFetchMap } from "../../dependencies"
import { _DataContainer } from "./AdminDashboard.styles"

const searchAreaSchema = (colors: string[]): FormSchema<OmitStrict<SearchArea, "areaId">> => ({
    name: { name: getCopy("clusterName"), type: "text", validators: validString },
    color: {
        name: `${getCopy("clusterName")} color`,
        type: "colorDropdown",
        values: colors.map(v => [v, v]),
        validators: [validateHexColor]
    },
    typeId: {
        name: `${capitalize(getCopy("searchArea"))} type`,
        type: "dropdown",
        values: sortByOrder(values(areaTypes), sat => sat.order).map(v => [v.name, v.id]),
        validators: [validateString]
    },
    summary: { name: "Executive summary", type: "textarea", validators: validStringDef }
})

type SearchAreaFormProps = Pick<ActionProps, "openPopup"> & {
    area?: SearchArea
    radarId: string
    userId: string
    isRoot: boolean
    users: SMap<RadarUser>
    encryptionKey: EncryptionKey
    availableColors: string[]
    onSave: F1<OmitStrict<SearchArea, "areaId">>
}
const SearchAreaForm: React.FC<SearchAreaFormProps> = p => {
    const [filesToDelete, setFilesToDelete] = React.useState<string[]>([])
    const [loading, setLoading] = React.useState(false)
    const [attachments, setAttachments] = React.useState<OnPath<AttachmentFile>[]>(
        p.area ? p.area.attachments || [] : []
    )
    const { formViewProps, onSubmitClick, result } = useFormHook({
        schema: searchAreaSchema(p.availableColors),
        initialValue: { ...p.area, typeId: p.area?.typeId || NO_DECORATOR } || {
            color: p.availableColors[0],
            typeId: NO_DECORATOR
        },
        onSubmit: async (res: OmitStrict<SearchArea, "areaId">) => {
            setLoading(true)
            const { storage } = getFirebase()
            await asyncForEach(filesToDelete, async ftd => {
                const ref = storage.ref("attachments", p.radarId, "executiveSummaries", ftd)
                await storage.deleteByRef(ref)
            })
            p.onSave({ ...res, attachments })
        }
    })
    const [uploadStatuses, onUpload] = useStorageUpload(
        p.radarId,
        p.encryptionKey,
        "executiveSummaries",
        (path, filename) => {
            setAttachments(as => [...as, { authorId: p.userId, path, filename, createdAt: new Date().getTime() }])
        }
    )

    const onDownload = (path: string) => {
        const filename = attachments.find(a => a.path === path)?.filename || "unknown"
        return downloadFile(filename, p.encryptionKey, s => s.ref("attachments", p.radarId, "executiveSummaries", path))
    }
    const onDelete = (path: string) => {
        setAttachments(as => as.filter(a => a.path !== path))
        setFilesToDelete(ftd => [...ftd, path])
    }

    const { getRootProps, getInputProps, isDragActive, draggedFiles } = useDropzone({
        maxSize: MB_LIMIT_10,
        onDropAccepted: files => onUpload(files),
        onDropRejected: files => {
            if (files.length > 1) return p.openPopup("attachmentInfo", { type: "multiple" })
            if (files[0].file.size >= MB_LIMIT_10) return p.openPopup("attachmentInfo", { type: "bigFile" })
        },
        multiple: false
    })

    return (
        <>
            <Loader fill show={loading} />
            <_DNDFieldWrapper {...getRootProps({ onClick: e => e.stopPropagation(), tabIndex: -1 })}>
                {isDragActive && (
                    <_DNDField>
                        {draggedFiles.length > 1 ? (
                            <_BodyFont s17 bold color="danger">
                                You cannot upload more than one file at a time
                            </_BodyFont>
                        ) : (
                            <_BodyFont s17 bold>
                                Drop files here
                            </_BodyFont>
                        )}
                    </_DNDField>
                )}
                <input {...getInputProps()} />

                <_FormViewWrapper>
                    <SubHeader title={`${getCopy("searchArea")} ${p.area ? "edit" : "create"}`} />
                    <FormView {...formViewProps} />
                    <_VerticalSpace base="16px" />
                    {!p.isRoot && [
                        <_FlexRow alignCenter key="att-title">
                            <_BodyFont s17>Attachments</_BodyFont>
                            <_Spacer />
                            <_BodyFont>Drag files here or</_BodyFont>
                            <_HorizontalSpace base="8px" />
                            <Button {...getRootProps({ className: "dropzone" })}>Choose files from device</Button>
                        </_FlexRow>,
                        <_VerticalSpace base="16px" key="att-title-spacer" />,
                        <AttachmentsTable
                            onDownload={onDownload}
                            deleteOptions={{ type: "all", value: { onDelete } }}
                            uploadStatuses={uploadStatuses}
                            mapToDisplay={mapRawAttachment({ users: p.users })}
                            attachments={toMap(attachments, a => a.path, identity)}
                            key="att-table"
                        />,
                        <_VerticalSpace key="att-table-spacer" base="42px" />
                    ]}
                    <_Center>
                        <Button
                            disabled={isErr(result) || values(uploadStatuses).some(v => v.type !== "Done")}
                            onClick={onSubmitClick}>
                            Save
                        </Button>
                    </_Center>
                    <_VerticalSpace base="16px" />
                </_FormViewWrapper>
            </_DNDFieldWrapper>
        </>
    )
}

const searchAreaRowSchema: RowSchema<SearchArea> = [
    mkTableTitleSchema("Name", "name"),
    mkTableComputedSchema("Type", "typeId", "text", sa => getAreaType(sa).name)
]
const searchAreaHeaderRow = searchAreaRowSchema.map(v => HeaderTitle(v.key, v.label, v.size, v.key))

type SearchAreasTableProps = {
    onDelete: F1<string>
    onEdit: F1<string>
    areas: SMap<SearchArea>
}
const SearchAreasTable: React.FC<SearchAreasTableProps> = ({ onDelete, onEdit, areas }) => {
    const searchAreaRows = values(areas)
        .sort(byNameAsc)
        .sort((s1, s2) => getAreaOrder(s1) - getAreaOrder(s2))
        .map(a => mkRow(a.areaId, searchAreaRowSchema, a))
    return (
        <Table
            headerRow={searchAreaHeaderRow}
            rows={searchAreaRows}
            virtualized={true}
            onRowClick={onEdit}
            interactiveOptions={[toOption("Edit", onEdit), toOption("Remove", onDelete)]}
        />
    )
}

type StateProps = {
    areas: SMap<SearchArea>
    radar: RadarDetails
    userId: string
    isRoot: boolean
    users: SMap<RadarUser>
    encryptionKey: EncryptionKey
    actionsResults: SMap<CloudActionResult>
}
export const RadarSearchAreas: React.FC<StateProps & ActionProps> = p => {
    const [editedAreaId, setEditedAreaId] = React.useState<string | null>(null)
    const [newAreaOpen, setNewAreaOpen] = React.useState(false)

    const [, onSave] = useCloudAction(p.mutateSearchArea, p.actionsResults, ({ result: res }) => {
        p.queueNotification(Msg("update", { name: getCopy("searchArea") }, res))
    })
    const [, onDelete] = useCloudAction(p.mutateSearchArea, p.actionsResults, ({ result: res }) => {
        p.queueNotification(Msg("delete", { name: getCopy("searchArea") }, res))
    })

    const colors = searchAreaColors.filter(c => !values(p.areas).find(a => a.color === c))

    return (
        <SubpageLayout>
            <Modal
                full
                isOpen={Boolean(editedAreaId) || newAreaOpen}
                title={`${getCopy("searchArea")} ${editedAreaId ? "edit" : "create"}`}
                onClose={() => (editedAreaId ? setEditedAreaId(null) : setNewAreaOpen(false))}>
                <SearchAreaForm
                    area={editedAreaId ? p.areas[editedAreaId] : undefined}
                    encryptionKey={p.encryptionKey}
                    radarId={p.radar.radarId}
                    userId={p.userId}
                    isRoot={p.isRoot}
                    users={p.users}
                    availableColors={editedAreaId ? colors.concat(p.areas[editedAreaId].color) : colors}
                    onSave={sa => {
                        onSave({
                            radarId: p.radar.radarId,
                            ...(editedAreaId
                                ? MutationState("update", { areaId: editedAreaId, ...sa })
                                : MutationState("create", sa))
                        })
                        editedAreaId ? setEditedAreaId(null) : setNewAreaOpen(false)
                    }}
                    openPopup={p.openPopup}
                />
            </Modal>
            <_FlexColumn grow={1}>
                <_Right>
                    <_HorizontalSpace base="16px" />
                    <Button onClick={() => setNewAreaOpen(true)}>Add {getCopy("searchArea")}</Button>
                    <_HorizontalSpace base="16px" />
                </_Right>
                <_VerticalSpace base="16px" />
                <_DataContainer>
                    <SearchAreasTable
                        onDelete={said => onDelete({ radarId: p.radar.radarId, type: "delete", value: said })}
                        onEdit={setEditedAreaId}
                        areas={p.areas}
                    />
                </_DataContainer>
                <_VerticalSpace base="42px" />
            </_FlexColumn>
        </SubpageLayout>
    )
}

const mapState = getDataByDeps<StateProps>()(
    RadarViewFetchMap({}, {}),
    (deps, { cloud: { actionsResults }, auth: { authentication } }) => {
        if (!isLoggedIn(authentication)) return Loading()

        const { searchAreas: areas, radar, users } = deps
        const userId = authentication.user.userId!

        return Loaded({
            areas,
            radar,
            users,
            userId,
            isRoot: isRoot(authentication),
            encryptionKey: deps.secureKeys.key,
            actionsResults
        })
    }
)

type ActionProps = ReturnType<typeof mapDispatch>
const mapDispatch = getMapDispatch2(cloudActions, ["mutateSearchArea"], uiActions, ["queueNotification", "openPopup"])
const LoadableSearchAreas = LoadableView(ViewLoader, RadarSearchAreas)

export const RadarSearchAreasView: ConnectedComponent<typeof LoadableSearchAreas, unknown> = connect(
    mapState,
    mapDispatch
)(LoadableSearchAreas)
