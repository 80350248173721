import { DateTime, DurationObject } from "luxon"
import moment from "moment"
import { RangePickerValue } from "antd/lib/date-picker/interface"

const getLocale = () => "en"

export const toDateString = (ts: number, dayTreshold = 2) => {
    const d = DateTime.fromMillis(ts)
    const { days } = d.diffNow("days").toObject()
    const date =
        days !== undefined && Math.abs(days) > dayTreshold
            ? d.toFormat("MM/dd/yy")
            : d.toRelativeCalendar({ locale: getLocale(), unit: "days" })
    return `${date} at ${d.toLocaleString(DateTime.TIME_SIMPLE)}`
}

export const toDate = (ts: number) => DateTime.fromMillis(+ts).toFormat("DD")

export const toRelativeTime = (ts: number, hourTreshold = 1) => {
    const d = DateTime.fromMillis(ts)
    const { hours } = d.diffNow("hours").toObject()
    return hours !== undefined && Math.abs(hours) > hourTreshold
        ? d.toFormat("t, DD")
        : d.toRelativeCalendar({ locale: getLocale(), unit: "minutes" })
}

export const timestampToDay = (ts: number) => DateTime.fromMillis(ts).toISODate()

export const roundToDay = (ts: number) => DateTime.fromMillis(ts).startOf("day").toMillis()

export const getMillisFromPast = (dur: DurationObject) => DateTime.local().minus(dur).toMillis()

const timestampToMoment = (v: string | null): moment.Moment | null => (v ? moment(+v) : null)

export const fromDateRange = ([from, to]: RangePickerValue) => ({
    from: from
        ? DateTime.fromMillis(+from.format("x"))
              .startOf("day")
              .toMillis()
              .toString()
        : null,
    to: to
        ? DateTime.fromMillis(+to.format("x"))
              .endOf("day")
              .toMillis()
              .toString()
        : null
})

export const toDateRange = ({ from, to }: { from: string | null; to: string | null }) => [
    timestampToMoment(from),
    timestampToMoment(to)
]

export const getTimezone = () => DateTime.local().zoneName
