/* eslint-disable max-lines */
import * as React from "react"
import { IconButton } from "../common/Buttons"
import { TabRenderable, Tabs, Tab } from "../tabs/Tabs"
import { TableHeader } from "../table/TableView.styles"
import {
    getCollectionRowSchema,
    isOkPI,
    isOverwritePI,
    isErrPI,
    getPreloadStatistics,
    sortPreloadedCollection
} from "./PreloadedCollectionsListController"
import { HeaderTitle, mkRow } from "../table/TableViewController"
import { InlineIconListItem } from "./IconList"
import { EditCollectionItemModal } from "../edit/EditCollectionItem"
import { _VerticalSpace, _FlexRow, _HorizontalSpace, _AbsPosElement, _AbsPosContainer } from "../../styles/common"
import { findIndex } from "../../../functions/src/utils"
import { Table } from "../table/TableView"
import { _InlineIconListItem } from "./IconList.styles"
import { getCollectionSchema, getIdField } from "../../../functions/src/models/schemas"
import { Prominent } from "../../styles/typography"
import { ErrorDigestModal, prepareErrorMap } from "./ErrorHintModals"
import { keys, mapObject } from "../../../functions/src/utils/map"
import { getPreloadedItemValues } from "../../../functions/src/models/relations/relationUtils"
import {
    DisplayConverter,
    DisplayModelsMap,
    ViewModelsBase,
    ViewModelsMap
} from "../../../functions/src/models/ViewModels"
import { PreloadedCollectionsVM, PreloadedCollectionVM } from "../../../functions/src/models/importing.types"
import { Cell, Row } from "../table/TableView.types"
import { _PCTabWrapper } from "./PreloadedCollectionList.styles"
import { Loader } from "../common/Loader"
import { collectionsVMConverters, getAvailableCollections } from "../../../functions/src/models/collections"
import { IconSvg } from "../IconSvg"
import { ActionDispatchFn } from "../../store/actions"
import { isEmpty, isErr } from "../../../functions/src/utils/validators"

type PreloadedTab = "invalid" | "overwrite" | "valid"

type SinglePCListProps<C extends CName> = {
    cname: C
    vms: Pick<
        ViewModelsBase,
        "radar" | "tags" | "searchAreas" | "segmentTags" | "segmentTagsAssignments" | "tagsAssignments"
    >
    pc: PreloadedCollectionVM<C>
    pcs: PreloadedCollectionsVM
    onDeleteSelected: F1<number[]>
    onAddTag: F1<string>
    onRemoveTag: F1<string>
    onAddSegmentTag: F2<string, OnIndex<CollectionItemVM<CName>>, Result<string>>
    onRemoveSegmentTag: F1<string>
    onItemSaved: F2<number, CollectionItem<C>>
    onAddItem: F0
    queueNotification: ActionDispatchFn<"queueNotification">
    config: LocationParams
    isRoot: boolean
}

type PCTabBaseProps = {
    anchor?: HTMLElement | null
    headerRow: Cell[]
    onSort: F1<string>
    sortingAsc: boolean
    sortingColumn: number
    onRowClick: F1<number>
}
type PCTabDynamicProps = {
    onRowSelect: F1<number[]>
    selectedRows: number[]
    rows: Row[]
    loading?: boolean
}
const PCTab = (baseProps: PCTabBaseProps) => (title: string, dynamicProps: PCTabDynamicProps) =>
    TabRenderable(() =>
        dynamicProps.loading ? (
            <Loader />
        ) : (
            <_PCTabWrapper>
                <TableHeader title={title} />
                <_AbsPosContainer>
                    <_AbsPosElement>
                        <Table virtualized isSelectable {...baseProps} {...dynamicProps} />
                    </_AbsPosElement>
                </_AbsPosContainer>
            </_PCTabWrapper>
        )
    )

const IconListItem: React.FC<InlineIconListItem> = p => (
    <_InlineIconListItem>
        <IconSvg {...p.icon} />
        <span>{p.title}</span>
    </_InlineIconListItem>
)
export type OnIndex<T> = T & { originalIndex: number }

const prepareToDisplay = <C extends CName>(
    cname: C,
    i: CollectionItemVM<C>
): DisplayModelsMap[C] & ItemClassifiersInput & RCollections[C]["relations"]["all"] => {
    const converter = collectionsVMConverters[cname] as DisplayConverter<C>

    return {
        ...converter(getPreloadedItemValues("fields", i) as ViewModelsMap[C]),
        ...mapObject(i.relationsVM, (_, os) => os.map(o => o.label)),
        ...mapObject(i.classifiersVM, (_, os) => os!.map(o => o.label))
    }
}

// TODO Test this fn
const createPCsWithRequiredRelations = <C extends CName>(
    itemRelations: RCollectionRelations<C>,
    pcs: PreloadedCollectionsVM
): PreloadedCollectionsVM =>
    keys(itemRelations).reduce((acc, _cname) => {
        const cname = _cname as CName
        if (isEmpty(itemRelations[_cname])) return acc
        const pcToSearch = pcs[cname]
        if (isEmpty(pcToSearch)) return acc
        return {
            ...acc,
            [cname]: {
                ...pcToSearch,
                value: (itemRelations[_cname] as any)
                    .map((oid: string) => {
                        return (pcToSearch.value as any).find((o: any) => {
                            const fields = isErr(o.fields) ? o.fields.obj : o.fields.value
                            return fields[getIdField(cname)] === oid
                        })
                    })
                    .filter(Boolean)
            }
        }
    }, {})

export const PreloadedCollectionList = <C extends CName>(
    p: SinglePCListProps<C>
): React.ReactElement<SinglePCListProps<C>> => {
    const [showErrorDigest, setShowErrorDigest] = React.useState(false)
    const [selectedValidIds, setValidIds] = React.useState<number[]>([])
    const [selectedOverwriteIds, setOverwriteIds] = React.useState<number[]>([])
    const [selectedInvalidIds, setInvalidIds] = React.useState<number[]>([])
    const [editedIndex, setEditedIndex] = React.useState<number | null>(null)
    const [sortingKey, setSortingKey] = React.useState<keyof ViewModelsMap[C]>(
        getCollectionSchema(p.cname).defaultSortField as keyof ViewModelsMap[C]
    )
    const [sortingAsc, setSortingAsc] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<PreloadedTab[]>([])

    React.useEffect(() => {
        setLoading([])
    }, [p.pc.value])
    const availableCollections = React.useMemo(() => getAvailableCollections(p.config, p.isRoot), [p.config, p.isRoot])
    const schema = React.useMemo(() => getCollectionRowSchema(p.cname, p.config, availableCollections), [
        p.cname,
        p.config,
        availableCollections
    ])
    const headerRow = React.useMemo(() => schema.map(s => HeaderTitle(s.key, s.label, s.size, s.key as string)), [
        schema
    ])
    const items = p.pc.value
        .map((v, originalIndex): OnIndex<typeof v> => ({ ...v, originalIndex }))
        .sort(sortPreloadedCollection(sortingKey as any, sortingAsc))
    const validItemsIndices = items.map((_, i) => i).filter(i => isOkPI(items[i]))
    const overwriteItemsIndices = items.map((_, i) => i).filter(i => isOverwritePI(items[i]))
    const invalidItemsIndices = items.map((_, i) => i).filter(i => isErrPI(items[i]))
    const onSort = React.useCallback(
        (key: string) => {
            setSortingAsc(sortingKey === key ? !sortingAsc : false)
            setSortingKey(key as any)
        },
        [setSortingAsc, setSortingKey, sortingKey, sortingAsc]
    )
    const stats = getPreloadStatistics(p.pc)
    // TODO Add memoization
    const tabContentBase = PCTab({
        headerRow,
        onRowClick: setEditedIndex,
        sortingAsc,
        sortingColumn: findIndex(headerRow, v => v.sortingKey === sortingKey, 0),
        onSort
    })
    const tabsData: Tab<PreloadedTab>[] = []
    if (invalidItemsIndices.length)
        tabsData.push({
            id: "invalid",
            title: TabRenderable(() => <IconListItem {...stats.invalid} />),
            content: tabContentBase("Invalid entries", {
                selectedRows: selectedInvalidIds,
                rows: invalidItemsIndices.map(i => mkRow(i, schema, prepareToDisplay(p.cname, items[i]), ["error"])),
                onRowSelect: setInvalidIds,
                loading: loading.includes("invalid")
            })
        })
    if (overwriteItemsIndices.length)
        tabsData.push({
            id: "overwrite",
            title: TabRenderable(() => <IconListItem {...stats.overwrite} />),
            content: tabContentBase("Entries to overwrite", {
                selectedRows: selectedOverwriteIds,
                rows: overwriteItemsIndices.map(i =>
                    mkRow(i, schema, prepareToDisplay(p.cname, items[i]), ["warning"])
                ),
                onRowSelect: setOverwriteIds,
                loading: loading.includes("overwrite")
            })
        })

    tabsData.push({
        id: "valid",
        title: TabRenderable(() => <IconListItem {...stats.valid} />),
        content: tabContentBase("Valid entries", {
            selectedRows: selectedValidIds,
            rows: validItemsIndices.map(i => mkRow(i, schema, prepareToDisplay(p.cname, items[i]))),
            onRowSelect: setValidIds,
            loading: loading.includes("valid")
        })
    })

    return (
        <>
            <EditCollectionItemModal
                vms={p.vms}
                mode="import"
                queueNotification={p.queueNotification}
                isOpen={editedIndex !== null}
                onClose={() => setEditedIndex(null)}
                config={p.config}
                isRoot={p.isRoot}
                cname={p.cname}
                getAdditionalObjectsRequiredForValidation={(item, validationType) => {
                    if (validationType === "relations") {
                        const itemRelations = isErr(item.relations)
                            ? (item.relations.obj as RCollectionRelations<C>)
                            : item.relations.value
                        return createPCsWithRequiredRelations(itemRelations, p.pcs)
                    }
                    return {}
                }}
                item={editedIndex !== null ? items[editedIndex] : undefined}
                onSave={(item: CollectionItem<C>) => p.onItemSaved(items[editedIndex!].originalIndex, item)}
            />
            <ErrorDigestModal
                isOpen={showErrorDigest}
                onClose={() => setShowErrorDigest(false)}
                onIgnoreTag={p.onRemoveTag}
                onAddTag={p.onAddTag}
                onIgnoreSegmentTag={p.onRemoveSegmentTag}
                onAddSegmentTag={p.onAddSegmentTag}
                cname={p.cname}
                errors={prepareErrorMap(items)}
            />

            <_VerticalSpace base="8px" />
            <_FlexRow alignCenter noshrink>
                <div>
                    <TableHeader title={getCollectionSchema(p.cname).displayName} />
                </div>
                <_FlexRow noshrink align="flex-start" grow={1}>
                    <_HorizontalSpace base="16px" />
                    <IconButton
                        disabled={
                            !selectedValidIds.length && !selectedInvalidIds.length && !selectedOverwriteIds.length
                        }
                        icon={{ name: "trash-icon", width: 20, height: 20 }}
                        onClick={() => {
                            setLoading(
                                [
                                    selectedInvalidIds.length && "invalid",
                                    selectedOverwriteIds && "overwrite",
                                    selectedValidIds && "valid"
                                ].filter(Boolean) as PreloadedTab[]
                            )
                            p.onDeleteSelected(
                                [...selectedInvalidIds, ...selectedOverwriteIds, ...selectedValidIds].map(
                                    i => items[i].originalIndex
                                )
                            )
                            setInvalidIds([])
                            setValidIds([])
                            setOverwriteIds([])
                        }}>
                        Delete selected
                    </IconButton>
                    <IconButton icon={{ name: "green-plus", width: 16, height: 16 }} onClick={p.onAddItem}>
                        Add new
                    </IconButton>
                </_FlexRow>
                {!p.pc.isValid && (
                    <_FlexRow data-cy="whats-wrong-button">
                        <Prominent underlined color="danger" onClick={() => setShowErrorDigest(true)}>
                            What's wrong with this object type?
                        </Prominent>
                        <_HorizontalSpace base="24px" />
                    </_FlexRow>
                )}
            </_FlexRow>
            <Tabs data={tabsData} legacy />
        </>
    )
}
