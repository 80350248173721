import styled, { css } from "styled-components"
import { styleIfProp, styleFromProp } from "./common"
import { ThemeColors } from "./styled"

export type Align = "left" | "right" | "center"
type TagFontProps = { color?: keyof ThemeColors; bold?: boolean; fontSize?: number }

export const _TagFont = styled.span<TagFontProps>`
    position: relative;
    letter-spacing: 2px;
    font-size: ${p => (p.fontSize ? `${p.fontSize}px` : "11px")};
    font-weight: ${p => (p.bold ? 900 : 600)};
    text-transform: uppercase;
    ${styleIfProp("onClick", "cursor: pointer;")}
    ${p => styleFromProp(p.color, `color: ${p.theme.colors[p.color!]};`)}
`

export const Caption = styled.span<{ bold: boolean }>`
    font-size: 12px;
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    line-height: 1.33;
    letter-spacing: 0.3px;
`
type Typography = {
    bold?: boolean
    preserveWhitespace?: boolean
    align?: Align
    color?: keyof ThemeColors
    uppercase?: boolean
}

export const _Title = styled.h1<Typography>`
    font-size: 34px;
    line-height: 1.29;
    letter-spacing: -0.5px;
    margin: 0;
    text-align: ${({ align }) => (align ? align : "left")};
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
`

export const _h1 = styled.h1<Typography>`
    font-size: 23px;
    line-height: 1.3;
    margin: 0;
    text-align: ${({ align }) => (align ? align : "left")};
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
`

export const _h2 = styled.h2<Typography>`
    font-size: 16px;
    margin: 0;
    line-height: 1.38;
    text-align: ${({ align }) => (align ? align : "left")};
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
`

export const _h3 = styled.h3<Typography>`
    font-size: 15px;
    line-height: normal;
    margin: 0;
    text-align: ${({ align }) => (align ? align : "left")};
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
`

const BodyBase = styled.p`
    line-height: 1.41;
    margin: 0;
`

export type FontSizes = {
    s11?: boolean
    s14?: boolean
    s15?: boolean
    s17?: boolean
    s16?: boolean
    s20?: boolean
}

const DEFAULT_SIZE = 14
const fontSizeToNumber = (sizes: FontSizes) =>
    sizes.s11 ? 11 : sizes.s14 ? 14 : sizes.s15 ? 15 : sizes.s17 ? 17 : sizes.s16 ? 16 : sizes.s20 ? 20 : DEFAULT_SIZE

export const _BodyFont = styled(BodyBase)<Typography & FontSizes>`
    font-size: ${fontSizeToNumber}px;
    ${styleIfProp("preserveWhitespace", "white-space: pre-wrap;")}
    text-align: ${({ align }) => (align ? align : "left")};
    font-weight: ${({ bold }) => (bold ? 700 : 400)};
    ${p => styleFromProp(p.color, `color: ${p.theme.colors[p.color!]};`)}
    ${styleIfProp("onClick", "cursor: pointer;")}
    ${styleIfProp(
        "uppercase",
        css`
            text-transform: uppercase;
        `
    )}
`

export const Prominent = styled.span<{ color?: keyof ThemeColors; underlined?: boolean }>`
    color: ${({ theme, color }) => (color ? theme.colors[color] : theme.colors.info)};
    text-decoration: none;
    cursor: pointer;
    ${p =>
        styleFromProp(
            p.underlined,
            `border-bottom: 1px dashed ${p.color ? p.theme.colors[p.color] : p.theme.colors.info};`
        )}
    &:hover {
        ${p =>
            styleFromProp(
                p.underlined,
                `border-bottom: 1px solid ${p.color ? p.theme.colors[p.color] : p.theme.colors.info};`
            )}
    }
`

export const _Text = styled.div`
    font-size: 16px;
    line-height: 1.5;
`
