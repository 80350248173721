import styled from "styled-components"
import { BaseButton } from "../../../styles/buttons"
import { _FlexContainer } from "../../../styles/common"
import { _DropdownWrapper, _DropdownContainer, _DropdownOptions } from "../../Dropdown.styles"

export const _AssignedUserCardSectionContainer = styled(_FlexContainer)`
    ${_DropdownContainer},
    ${_DropdownWrapper},
    ${BaseButton},
    ${_DropdownOptions} {
        width: 100%;
    }
`
