import { StarFilterType } from "../../functions/src/models/filtering"
import { getCopy } from "../../functions/src/services/copy"
import { extend } from "../../functions/src/utils/map"

import {
    mkRow,
    Star,
    CommentBubble,
    mkDropdownCell,
    Box,
    CommentBubbleDecker
} from "../components/table/TableViewController"
import {
    getPipelineCurrentValue,
    getPipelineStageValues,
    getPriorityCurrentValue,
    getPriorityRankValues,
    getPGAssignmentDisplay
} from "../../functions/src/models/decoratorValues"
import { mkString, assertNever } from "../../functions/src/utils"
import { SortingKey } from "../../functions/src/models/sorting"
import { ViewModelsMap, ViewModelsBase } from "../../functions/src/models/ViewModels"
import { RowSchema, Row, Cell, SpecialCellIds, CellSize } from "../components/table/TableView.types"

export const TAG_FILTER_ALL = "All Tags"
export const getDefaultSearchAreaFilter = () => getCopy("allSearchAreas")
export const getStarsFilterAll: F0<StarFilterType> = () => "All (starred and not)"

export type SortingEssentials<T extends CName> = {
    sortingKey: SortingKey<T>
    sortingAsc: boolean
}

export const getBaseRows = <TName extends CName, T extends ViewModelsMap[TName]>(
    schema: RowSchema<T>,
    vs: T[],
    idKey: keyof T
): Row[] => vs.map(v => mkRow(v[idKey] as any, schema, v, v.blurOnListing ? ["blurred", "disabled"] : []))

export type RowModifier = F1<Row[], Row[]>

export const decoratorCellSizes: Record<ListDecorators | "comments" | SpecialCellIds, CellSize> = {
    pipelineStage: {
        min: 200,
        max: 200
    },
    priorityRank: {
        min: 200,
        max: 200
    },
    assignment: {
        min: 120,
        max: 120
    },
    star: {
        min: 30,
        max: 30
    },
    comments: {
        min: 50,
        max: 50
    },
    select: {
        min: 40,
        max: 40
    },
    menu: {
        min: 40,
        max: 40
    }
}

export const addCommentCells = <C extends CName>(item: ViewModelsMap[C]) => (row: Row): Row => {
    const bubble =
        item.contactedLeadsCount > 0
            ? CommentBubbleDecker("comments", item.contactedLeadsCount, item.commentsCount, decoratorCellSizes.comments)
            : CommentBubble("comments", item.commentsCount, decoratorCellSizes.comments)
    row.cells.splice(-1, 0, bubble)
    return extend(row)({ cells: row.cells })
}

export const addDecoratorCells = (
    decoratorsToAttach: ListDecorators[],
    data: Pick<ViewModelsBase, "decorators" | "pipelines" | "users">,
    cname: CName,
    searchAreaId: string
) => (row: Row): Row => {
    const objectDecorators = data.decorators[row.id as string]
    const newCells = decoratorsToAttach.map(k => {
        switch (k) {
            case "assignment": {
                if (!objectDecorators?.assignment) return Box("assignment", "-", decoratorCellSizes.assignment)
                const userDisplay = getPGAssignmentDisplay(data.users[objectDecorators.assignment.userId])
                return Box("assignment", userDisplay, decoratorCellSizes.assignment)
            }
            case "pipelineStage": {
                const selected = getPipelineCurrentValue(data.pipelines, objectDecorators, searchAreaId)
                const options = getPipelineStageValues(data.pipelines, cname)
                return mkDropdownCell(
                    "pipelineStage",
                    selected,
                    options,
                    {
                        type: "pipelineStage",
                        searchAreaId,
                        objectId: mkString(row.id)
                    },
                    decoratorCellSizes.pipelineStage
                )
            }
            case "priorityRank": {
                const selected = getPriorityCurrentValue(objectDecorators, searchAreaId)
                return mkDropdownCell(
                    "priorityRank",
                    selected,
                    getPriorityRankValues(),
                    {
                        type: "priorityRank",
                        searchAreaId,
                        objectId: mkString(row.id)
                    },
                    decoratorCellSizes.priorityRank
                )
            }
            case "star": {
                return Star("star", objectDecorators?.star?.value || false, decoratorCellSizes.star)
            }
        }
        assertNever(k)
    })
    // return extend(row)({ cells: [...row.cells, ...newCells] })
    return extend(row)({ cells: insertCells(cname, row.cells, newCells) })
}

type ReorderMap = { [key: string]: number }

/* Modifies row in place, adding new cells.
   If a cell requires specific positioning, insert it at that spot.
   Otherwise, append at the end.
   The positioning rules can be different for each table type, therefore we also require a cname
   Returns the modified row. */
export const insertCells = (cname: CName | string, row: Cell[], newCells: Cell[]): Cell[] => {
    switch (cname) {
        case "startups":
        case "companies":
        case "patents":
        case "tech_transfers":
        case "clinical_trials":
        case "grants":
        case "research_papers":
            return insertCellsWithMap(row, newCells, { pipelineStage: 1, priorityRank: 2 })
        case "sectors":
        case "investors":
        case "research_hubs":
        case "tech_experts":
        case "influencers":
        case "patent_holders":
            return insertCellsWithMap(row, newCells, { priorityRank: 1 })
    }

    // If we don't have rules for this type of table, just append at the end
    return [...row, ...newCells]
}

const insertCellsWithMap = (row: Cell[], newCells: Cell[], mapping: ReorderMap): Cell[] => {
    for (const cell of newCells) {
        const start = mapping[cell.id]

        if (start === undefined) row.push(cell)
        else row.splice(start, 0, cell)
    }
    return row
}
