import styled from "styled-components"
import { _Tab, _TabSelector, _TabContentWrapper } from "../../components/tabs/Tabs.styles"
import {
    _TableCell,
    _TableRow,
    _TableHead,
    _TableBody,
    _RowScrollingFade
} from "../../components/table/TableView.styles"
import { _FilterBarContainer } from "../../components/Header"
import { _AbsPosContainer, _VerticalSpace } from "../../styles/common"
import { _TagFont, _BodyFont } from "../../styles/typography"

export const _PrintTitle = styled.div`
    display: none;
    @media print {
        display: flex;
        font-size: 22px;
        padding-bottom: 10px;
    }
`

export const _ReportsCommentWrapper = styled.div`
    border-bottom: 1px solid #8dabc4;
`

export const _ReportsCommentLink = styled.a`
    text-decoration: none;
    color: inherit;
    display: inline-block;
    padding: 4px 0;

    &:hover,
    &:active {
        color: inherit;
        text-decoration: none;
        font-weight: bold;
    }
`

export const _ReportsPlaceholder = styled.div`
    margin-top: 30vh;
    font-size: 18px;
    text-align: center;
`

export const _ReportsViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    ${_Tab} {
        max-width: 180px;
    }

    ${_TableHead} {
        position: sticky;
        z-index: 2;
        top: 0;
        border: none;

        ${_TableCell}:first-child {
            background: #ecf5fd;
        }
    }

    ${_TableCell}:first-child {
        position: sticky;
        left: 0;
        z-index: 1;
        background: #fff;
    }

    ${_TableCell}:empty {
        position: relative;
        background-image: linear-gradient(
            45deg,
            #d1dde7 7.14%,
            transparent 7.14%,
            transparent 50%,
            #d1dde7 50%,
            #d1dde7 57.14%,
            transparent 57.14%,
            transparent 100%
        );
        background-size: 9.9px 9.9px;

        &:after {
            content: "";
            position: absolute;
            width: 20px;
            right: 0;
            top: 0;
            bottom: 0;
            background: #fff;
        }
    }

    @media print {
        @page {
            size: landscape;
        }
        ${_FilterBarContainer} {
            display: none;
        }
        ${_TabSelector}, ${_TabSelector} ${_VerticalSpace} {
            display: none;
        }

        ${_TabContentWrapper} {
            overflow: visible;

            ${_TagFont} {
                font-size: 8px;
                word-break: break-word;
            }

            ${_BodyFont} {
                font-size: 10px;
                word-break: break-word;
            }

            ${_TableRow} {
                height: auto;
                grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));

                ${_RowScrollingFade} {
                    display: none;
                }
            }
        }

        ${_AbsPosContainer} {
            overflow: visible;
        }

        ${_TableBody} {
            overflow: visible;
        }
    }
`
