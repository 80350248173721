import React from "react"
import { debounce } from "../../../utils"
import { IconSvg } from "../../../components/IconSvg"
import { _Input } from "../../form/Input.styles"
import { _SearchInputControlInput } from "./SearchInputControl.styles"
import styled from "styled-components"

type SearchInputControlProps = {
    onChange: F1<{ type: "input"; v: string }>
    value: string
    search?: boolean
}

const _SearchInputWrapper = styled.div`
    position: relative;
    input {
        text-indent: 28px;
        border-radius: 17px;
    }
    #search {
        position: absolute;
        top: 10px;
        left: 15px;
    }
`

export const SearchInputControl = (p: SearchInputControlProps) => {
    const [value, setValue] = React.useState(p.value)

    const debouncedOnChange = React.useMemo(
        () =>
            debounce((v: string) => {
                p.onChange({ type: "input", v })
            }, 3000),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [p.onChange]
    )

    React.useEffect(() => {
        if (p.value !== value) setValue(p.value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.value])

    const input = (
        <_SearchInputControlInput
            data-cy={`search-input-${value}`}
            value={value}
            placeholder="All"
            onChange={e => {
                const v = e.target.value
                setValue(v)
                debouncedOnChange(v)
            }}
            onBlur={e => {
                const v = e.target.value
                p.onChange({ type: "input", v })
                debouncedOnChange.cancel()
            }}
        />
    )

    if (!p.search) return input
    else
        return (
            <_SearchInputWrapper>
                <div id="search">
                    <IconSvg name="search-icon" width={18} height={18} />
                </div>
                {input}
            </_SearchInputWrapper>
        )
}
