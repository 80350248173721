import styled from "styled-components"
import { StepProps } from "./Stepper"

const SPACE_BETWEEN = "158px"

export const _StepTitle = styled.div<Pick<StepProps, "selected">>`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    line-height: 24px;
    padding-top: 10px;
    width: 130px;
    ${({ selected }) =>
        selected
            ? ` &:after {
        content: "";
        height: 1px;
        width: 60px;
        background: #2c405a;
    }`
            : ""};
`
export const _StepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 40px;
    span {
        margin-top: 18px;
        height: 24px;
        font-size: 14px;
        line-height: 1.71;
    }
`

export const _Stepper = styled.div`
    display: flex;
    & > * + * {
        margin-left: ${SPACE_BETWEEN};
        position: relative;

        &::before {
            content: "";
            position: absolute;
            right: calc(${SPACE_BETWEEN} / 2);
            top: calc(20px / 2);
            width: 80px;
            height: 1px;
            border-radius: 1.5px;
            background: lightgrey;
        }
    }
`
