import { SegmentTagSingleCreatePayload } from "../components/segmentTags/SegmentTagCreateForm"
import { validateSegmentTagName } from "../../functions/src/models/tags"
import { getCopy } from "../../functions/src/services/copy"
import { validString } from "../../functions/src/utils/validators"
import { getAreaValues } from "../../functions/src/models/searchAreas"

export const getSingleAreaSegmentTagSchema = (
    searchAreas: SMap<SearchArea>
): FormSchema<SegmentTagSingleCreatePayload> => ({
    name: { name: "Name", type: "text", validators: [validateSegmentTagName] },
    areaId: {
        name: `${getCopy("searchArea")}`,
        type: "dropdown",
        validators: validString,
        values: getAreaValues(searchAreas)
    }
})
