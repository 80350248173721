import styled from "styled-components"
import { ModalTitle } from "../Modal.styles"
import { _IconButton, BaseButton } from "../../styles/buttons"
import { Flex, _FlexContainer } from "../../styles/common"
import { _ColumnLayoutContainer } from "../layout/ColumnLayout"
import { _SearchInputWrapper } from "../form/Input.styles"
import { _IconSvgContainer } from "../IconSvg.styles"

export const _CardContainer = styled(Flex).attrs(() => ({ direction: "column" }))`
    position: relative;

    min-height: 124px;
    min-width: 624px;
    height: 100vh;
    width: 100vw;

    background: rgb(246, 247, 251);
    padding: 30px 40px;
`

export const _CardContent = styled(_FlexContainer).attrs(() => ({ grow: 1 }))`
    background: rgb(246, 247, 251);
    border: none;
    width: 100%;
    max-height: unset;
`

export const _CardHeader = styled(Flex).attrs(() => ({ align: "center" }))`
    background: rgb(246, 247, 251);
    height: 69px;
    margin-bottom: ${p => 0.8 * p.theme.sizes.defaultPadding}px 0;
`

export const _CardGrid = styled.div<{ columns?: number }>`
    display: grid;
    padding: 0;
    grid-template-columns: ${p =>
        Array(p.columns || 2)
            .fill("1fr")
            .join(" ")};
    grid-gap: 30px 50px;
`

export const _CardGridItem = styled.div``

export const _CardTitle = styled(Flex)`
    align-items: center;
`

export const CardCloseButton = styled(Flex).attrs(() => ({ align: "center", justify: "center", noshrink: true }))`
    font-size: 16px;
    cursor: pointer;
    height: 57px;
    width: 57px;
    border-radius: 28.5px;
    background: #ffffff;
    box-shadow: 0 0 5px 0 #edeff2;

    :hover {
        box-shadow: 0 2px 4px 0 rgba(127, 127, 127, 0.66);
    }
`

export const CardStarButton = styled(BaseButton)<{ starred: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    height: 37px;
    width: 37px;
    padding: 0;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    border-radius: 4px;
    outline: none;

    ${_IconSvgContainer} {
        --fill: ${p => (!p.starred ? p.theme.colors.theme18 : p.theme.colors.theme15)};
    }

    &:focus,
    &:hover {
        ${_IconSvgContainer} {
            --fill: ${p => (p.starred ? p.theme.colors.theme20 : p.theme.colors.theme19)};
        }
    }
`

export const CardActionButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 42px;

    line-height: 19px;
    letter-spacing: 0.3px;
    font-size: 15px;
    min-width: 72px;
    padding: 8px 10px;
    border: solid 1px rgba(0, 0, 0, 0.05);
    color: #fff;
    border-radius: 4px;
    cursor: pointer;

    &:focus {
        outline: none;
    }
`

export const EditCardButton = styled(CardActionButton)`
    background: ${p => p.theme.colors.edit};
    &:focus,
    &:hover {
        border: solid 1px #43bb81;
    }
`
export const DeleteCardButton = styled(CardActionButton)`
    background: ${p => p.theme.colors.danger};
    &:focus,
    &:hover {
        border: solid 1px #b20b0b;
    }
`

export const ImportPrioritizationButton = styled(CardActionButton)`
    background: ${p => p.theme.colors.info};
    &:focus,
    &:hover {
        border: solid 1px ${p => p.theme.colors.infoHover};
    }
`

export const _ExportCardButton = styled(_IconButton)`
    &:focus,
    &:hover {
        ${_IconSvgContainer} {
            --fill: #4e6f99;
        }
    }
`

export const CardModalTitle = styled(ModalTitle)`
    font-size: 16px;
    text-transform: uppercase;
`

export const _SearchAreasContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 18px;
`

export const _CardSource = styled.div`
    padding-top: ${p => 0.8 * p.theme.sizes.defaultPadding}px;
    padding-left: ${p => p.theme.sizes.defaultPadding}px;
    color: #617084;
`

export const _CardDetailsContainer = styled(_FlexContainer).attrs(() => ({ grow: 1 }))`
    ${_ColumnLayoutContainer} {
        flex-grow: 1;
    }
`

export const _RelationsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: ${p => p.theme.sizes.defaultPadding}px;

    ${_SearchInputWrapper} {
        width: 100%;
        max-width: unset;
    }
`
