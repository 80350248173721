import amplitude from "amplitude-js"

export const init = (key: string | undefined) => {
    if (!key) return
    amplitude.getInstance().init(key)
}

export const track = (category: string, action: string, info?: Record<string, unknown>) => {
    amplitude.getInstance().logEvent(`${category}.${action}`, info)
    return true
}

export const identify = (radarName: string, hubName: string) => {
    const ident = new amplitude.Identify()
    ident.set("radarName", radarName)
    ident.set("hubName", hubName)
    amplitude.getInstance().identify(ident)
}
