import { collections, getNameField, getCollectionFromId } from "./schemas"
import { isEmpty } from "../utils/validators"
import { keys } from "../utils/map"
import { exhaustiveStringTuple } from "../utils/types"
import { SubscriptionDataTypes, DecoratingDataTypes } from "./ViewModels"

export const getItemCollectionFromId = (data: RadarCollections) => (itemId: string): CName | null => {
    if (isEmpty(data)) return null
    return (
        getCollectionFromId(itemId) ||
        keys(collections).reduce((key, col) => (key ? key : data[col] ? (data[col]![itemId] ? col : null) : null), null)
    )
}

export const getNameFromId = <T extends CName>(data: RadarCollections, cname: T | null, fallback: string) => (
    itemId: string
): string => {
    if (isEmpty(data) || isEmpty(cname)) return fallback
    if (isEmpty(data[cname])) return fallback
    const nameField = getNameField(cname)
    const item = data[cname][itemId] as RCollection<T>
    return item ? (item[nameField] as any) : fallback
}

export const getSearchAreasFromId = (data: Pick<ByRadarSchema, "searchAreasAssignments" | "searchAreas">) => (
    itemId: string
): string[] => {
    if (isEmpty(data)) return []
    const areasAssignments = data.searchAreasAssignments[itemId]
    return keys(areasAssignments)
        .filter(areaId => areasAssignments[areaId] && Boolean(data.searchAreas[areaId]))
        .map(areaId => data.searchAreas[areaId].name)
}

// TODO: TEST
export const getTagsFromAssignments = (vms: Pick<ByRadarSchema, "tags" | "tagsAssignments">, cname: CName | null) => (
    itemId: string
): Tag[] => {
    if (isEmpty(vms) || isEmpty(cname)) return []
    const assignments = vms.tagsAssignments[itemId]
    if (!assignments) return []
    const tags: Tag[] = []
    keys(assignments).forEach(tid => {
        if (assignments[tid] && vms.tags[tid]) tags.push(vms.tags[tid])
    })
    return tags
}
export const getSegmentTagsFromAssignments = (
    vms: Pick<ByRadarSchema, "segmentTags" | "segmentTagsAssignments">,
    cname: CName | null
) => (objectId: string) => {
    if (isEmpty(vms) || isEmpty(cname)) return []
    const assignments = vms.segmentTagsAssignments[objectId]
    if (!assignments) return []
    const tags: SegmentTag[] = []
    keys(assignments).forEach(tid => {
        if (assignments[tid] && vms.segmentTags[tid]) tags.push(vms.segmentTags[tid])
    })
    return tags
}

export const subscriptionDataTypes = exhaustiveStringTuple<SubscriptionDataTypes>()(
    "radar",
    "secureKeys",
    "tags",
    "segmentTags",
    "searchAreas",
    "users",
    "pipelines",
    "subscribers"
)

export const decoratingDataTypes = exhaustiveStringTuple<DecoratingDataTypes>()(
    "comments",
    "decorators",
    "relations",
    "searchAreasAssignments",
    "tagsAssignments",
    "segmentTagsAssignments"
)
