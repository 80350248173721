import { AdminAction, ImportActionShortened, RadarAction } from "../../../actions/actionCreators"
import { exhaustiveStringTuple } from "../../../utils/types"
import { assertNever } from "../../../utils"
import { Firestore } from "../../firebase"
import { validateActionResult } from "../../../actions/validators"
import { QueryWhere, ColRef, DocRef } from "../../firebase/firestore"
import { values, remap, identity, groupBy } from "../../../utils/map"
import { RelationsByCName } from "../../../models/ViewModels"
import { validateRelation } from "../../../models/common"
import { toRelationMap, toRelationArray } from "../../../models/relations/relationUtils"
import { isOk } from "../../../utils/validators"
import { Logger } from "../../logging"
import { getCollectionFromId } from "../../../models/schemas"

export const { log, error } = Logger("HTTP CACHE::")

export const getActionResultsByTimestamp = async (
    firestore: Firestore,
    radarId: string,
    ts: Timestamp
): Promise<CloudActionResult[]> => {
    const { valid: adminResults } = await firestore.fetchCol(
        firestore.ref("adminActionsResults", radarId, "results"),
        validateActionResult,
        { where: QueryWhere<CloudActionResult>("createdTs", ">=", ts) }
    )
    const { valid: radarResults } = await firestore.fetchCol(
        firestore.ref("radarActionsResults", radarId, "results"),
        validateActionResult,
        { where: QueryWhere<CloudActionResult>("createdTs", ">=", ts) }
    )
    return values({ ...adminResults, ...radarResults })
}

// TODO Fix this method -> Results in firebase bug: FAILED_PRECONDITION: The requested snapshot version is too old. in cache.initRadarCollections()
export const asyncFetchCol = async <T>(
    firestore: Firestore,
    ref: ColRef<SMap<T>>,
    validator: Validator<T>
): Promise<SMap<T>> => {
    const { valid } = await firestore.fetchCol(ref, validator)
    return valid
}

export const asyncFetchDoc = <T>(firestore: Firestore, ref: DocRef<T>, validator: Validator<T>): Promise<T> =>
    new Promise(res => {
        firestore.fetchDoc(ref, validator).then(r => res(isOk(r) ? r.value : ({} as T)))
    })

type CollectionInvalidatingAction =
    | AdminAction<"mutateRadarCollectionItem" | "removeCollectionItems">
    | ImportActionShortened
    | RadarAction<"uploadPrioritization">
export const filterActionsForCollections = (
    allResults: CloudActionResult[]
): CloudActionResult<CollectionInvalidatingAction>[] =>
    allResults.filter((r): r is CloudActionResult<CollectionInvalidatingAction> =>
        exhaustiveStringTuple<CollectionInvalidatingAction["type"]>()(
            "@importChunk",
            "@mutateRadarCollectionItem",
            "@removeColItems",
            "uploadPrioritization"
        ).includes(r.action.type as any)
    )

type UserInvalidatingAction = AdminAction<
    "acceptAccessRequest" | "denyAccessRequest" | "inviteUser" | "resendUserInvite" | "revokeUserInvite"
>

export const filterActionsForUsers = (allResults: CloudActionResult[]): CloudActionResult<UserInvalidatingAction>[] =>
    allResults.filter((r): r is CloudActionResult<UserInvalidatingAction> =>
        exhaustiveStringTuple<UserInvalidatingAction["type"]>()(
            "@acceptAccessRequest",
            "@denyAccessRequest",
            "@inviteUser",
            "@resendUserInvite",
            "@revokeUserInvite"
        ).includes(r.action.type as any)
    )

export const shouldUpdateRadarCollections = (
    results: CloudActionResult<CollectionInvalidatingAction>[],
    cname: CName
): boolean =>
    results.some(r => {
        switch (r.action.type) {
            case "@importChunk":
                return (r.action.payload.data[cname]?.length || 0) > 0
            case "@mutateRadarCollectionItem":
            case "@removeColItems":
                return r.action.payload.cname === cname
            case "uploadPrioritization":
                return true
        }
        assertNever(r.action)
    })

export const shouldUpdateRadarUsers = (results: CloudActionResult<UserInvalidatingAction>[]) =>
    results.some(r => {
        switch (r.action.type) {
            case "@acceptAccessRequest":
            case "@denyAccessRequest":
            case "@inviteUser":
            case "@resendUserInvite":
            case "@revokeUserInvite":
                return true
        }
        assertNever(r.action)
    })

type SubscriptionInvalidatingAction = RadarAction<
    | "createAndAssignTag"
    | "mutateTag"
    | "mutateSearchArea"
    | "mutatePipelineStageValue"
    | "createAndAssignSegmentTag"
    | "mutateSegmentTag"
    | "createBatchSegmentTags"
>

export const filterActionsForSubscriptions = (
    allResults: CloudActionResult[]
): CloudActionResult<SubscriptionInvalidatingAction>[] =>
    allResults.filter((r): r is CloudActionResult<SubscriptionInvalidatingAction> =>
        exhaustiveStringTuple<SubscriptionInvalidatingAction["type"]>()(
            "createAndAssignTag",
            "createAndAssignSegmentTag",
            "mutateSegmentTag",
            "mutateTag",
            "mutateSearchArea",
            "mutatePipelineStageValue",
            "createBatchSegmentTags"
        ).includes(r.action.type as any)
    )

export type DecoratingDataInvalidatingAction =
    | RadarAction<
          | "assignSearchArea"
          | "assignTag"
          | "assignSegmentTag"
          | "addRelation"
          | "removeRelation"
          | "mutateDecorator"
          | "createComment"
          | "deleteComment"
          | "editComment"
          | "createAndAssignTag"
          | "createAndAssignSegmentTag"
          | "uploadPrioritization"
      >
    | AdminAction<"mutateRadarCollectionItem" | "importChunk" | "importPriorityChunk" | "removeCollectionItems">
export const filterActionsForDecorations = (
    allResults: CloudActionResult[]
): CloudActionResult<DecoratingDataInvalidatingAction>[] =>
    allResults.filter((r): r is CloudActionResult<DecoratingDataInvalidatingAction> =>
        exhaustiveStringTuple<DecoratingDataInvalidatingAction["type"]>()(
            "assignSearchArea",
            "assignTag",
            "assignSegmentTag",
            "createAndAssignTag",
            "createAndAssignSegmentTag",
            "addRelation",
            "removeRelation",
            "mutateDecorator",
            "createComment",
            "deleteComment",
            "editComment",
            "uploadPrioritization",
            "@importChunk",
            "@mutateRadarCollectionItem",
            "@importPriorityChunk",
            "@removeColItems"
        ).includes(r.action.type as any)
    )

export const prepareRelationsRaw = async (radarId: string, firestore: Firestore): Promise<SMap<RelationsByCName>> => {
    const rawRelations = await firestore.fetchCol(firestore.ref("byRadarId", radarId, "relations"), validateRelation)
    const relationsById = toRelationMap(toRelationArray(values(rawRelations.valid)))
    const groupedRs: SMap<RelationsByCName> = remap(
        relationsById,
        identity,
        itemRelations => groupBy(itemRelations, k => getCollectionFromId(k), identity) as RelationsByCName
    )
    return groupedRs
}

export const isCommentInvalidatingAction = (result: CloudActionResult<DecoratingDataInvalidatingAction>) =>
    result.action.type === "createComment" ||
    result.action.type === "editComment" ||
    result.action.type === "deleteComment" ||
    result.action.type === "@removeColItems"

export const isRelationInvalidatingAction = (result: CloudActionResult<DecoratingDataInvalidatingAction>) =>
    result.action.type === "addRelation" ||
    result.action.type === "removeRelation" ||
    result.action.type === "@importChunk" ||
    result.action.type === "@removeColItems"

export const isAssignmentsInvalidatingAction = (result: CloudActionResult<DecoratingDataInvalidatingAction>) =>
    result.action.type === "@mutateRadarCollectionItem" ||
    result.action.type === "@importChunk" ||
    result.action.type === "@removeColItems"

export const isTagAssignmentsInvalidatingAction = (result: CloudActionResult<DecoratingDataInvalidatingAction>) => {
    const actions: typeof result.action.type[] = ["createAndAssignTag", "assignTag"]
    return actions.includes(result.action.type)
}

export const isSegmentTagsAssignmentsInvalidatingAction = (
    result: CloudActionResult<DecoratingDataInvalidatingAction>
) => {
    const actions: typeof result.action.type[] = ["createAndAssignSegmentTag", "assignSegmentTag"]
    return actions.includes(result.action.type)
}
