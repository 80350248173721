import { ViewContent, ViewRenderable, ViewText } from "./views"
import { v4 } from "uuid"
import { capitalize } from "../../functions/src/utils"
import { NotificationMsg } from "../store/store"
import { Err, isErr } from "../../functions/src/utils/validators"

type Notification<T, T2> = { Ok: ViewContent<T>; Err: ViewContent<T2> }

const Notification = <
    T extends ViewContent<SMap<unknown>>,
    T2 extends ViewContent<NotificationErrorPayload | CustomNotificationPayload>
>(
    ok: T,
    err: T2
) => ({
    Ok: ok,
    Err: err
})

export type NotificationPayload = { name: string }
export type NotificationErrorPayload = NotificationPayload & { err?: string }
type CustomNotificationPayload = {
    success?: string
    error?: string
}

const NotificationInfo = <T extends NotificationPayload = NotificationPayload>(v: F1<T, string>) => ViewRenderable<T>(v)
const NotificationError = <T extends NotificationErrorPayload = NotificationErrorPayload>(v: F1<T, string>) =>
    ViewRenderable<T>(v)

export const messages = {
    update: Notification(
        NotificationInfo(({ name }) => `${capitalize(name)} was updated successfully`),
        NotificationError(o => `Could not update ${o.name}. ${o.err || ""}`)
    ),
    create: Notification(
        NotificationInfo(({ name }) => `${capitalize(name)} was created successfully`),
        NotificationError(o => `Could not create ${o.name}. ${o.err || ""}`)
    ),
    delete: Notification(
        NotificationInfo(({ name }) => `${capitalize(name)} was removed successfully`),
        NotificationError(o => `Could not remove ${o.name}. ${o.err || ""}`)
    ),

    invitationSend: Notification(ViewText("Invitation sent"), ViewText("Failed to send invitation")),
    invitationRevoke: Notification(ViewText("Invitation revoked"), ViewText("Failed to revoke invitation")),
    invitationResend: Notification(ViewText("Successfully resent invitation"), ViewText("Failed to resend invitation")),

    userAccept: Notification(ViewText("Successfully accepted user"), ViewText("Failed to accept user")),
    userDecline: Notification(ViewText("Successfully declined user"), ViewText("Failed to decline user")),
    failedAction: Notification(
        ViewText(""),
        NotificationError(o => `Failed to perform action on ${o.name}.`)
    ),
    portalInvite: Notification(
        NotificationInfo(() => "Invited user to Portal"),
        NotificationError(({ err }) => `Could not invite user to Portal: ${err}`)
    ),
    custom: Notification<ViewContent<CustomNotificationPayload>, ViewContent<CustomNotificationPayload>>(
        ViewRenderable(o => o.success || ""),
        ViewRenderable(o => o.error || "")
    )
}

type Messages = typeof messages

export const Msg = <T extends keyof Messages>(
    k: T,
    p: NotificationPayload | CustomNotificationPayload,
    { type, value }: Result<any> = Err("")
): NotificationMsg => {
    const getText = () => {
        const msg = messages[k][type]
        if (msg.type !== "Renderable") return msg.value
        if (type === "Err" || isErr(type)) return msg.render({ ...(p as any), err: `${value}` })
        return msg.render(p as any)
    }
    return { text: getText(), type, id: v4() }
}
