import * as React from "react"
import { actions } from "../store/ui/actions"
import { actions as authActions } from "../store/auth/actions"
import { connect } from "react-redux"
import { FAQ } from "../components/popups/FAQ"
import { Contact } from "../components/popups/Contact"
import { GetEmail } from "../components/popups/Email"
import { EditProfilePopup } from "../components/popups/EditProfile"
import { ErrorPopup } from "../components/popups/Permissions"
import { MandatoryCommentPopup } from "../components/popups/MandatoryComment"
import { ModalContainer } from "../components/common/ModalContainer"
import { isEmpty } from "../../functions/src/utils/validators"
import { ExportPopup } from "../components/popups/ExportPopup"
import { PipelineRemovalView } from "../components/popups/PipelineRemoval"
import { AttachmentInfoPopup } from "../components/popups/LargeFile"
import { Popup, NotificationMsg } from "../store/store"
import { MapState, MapDispatch } from "../utils/redux.types"
import { EditHubView } from "../views/admin/HubEdit"
import { AddHubView } from "../views/admin/HubAdd"
import { SelectExportTypePopup, ExportPopupMeta } from "../components/popups/SelectExportTypePopup"
import { ByIdsListData } from "../views/lists/ByIdsList"
import { ByIdsListPopup } from "../components/popups/ByIdsListPopup"
import { DeleteConfirmationPopup, DeleteConfirmationPopupMeta } from "../components/popups/DeleteConfirmationPopup"
import { LinkPopup, LinkPopupMeta } from "../components/popups/editor/LinkPopup"
import { UploadPrioritization, UploadPrioritizationMeta } from "../components/popups/UploadPrioritization"

export type StateProps = { popups: Popup[]; popupsTypes: Type<Popup>[] }
export type ActionProps = {
    onClose: F1<Type<Popup>>
    onEmailReceived: F1<string>
}
export type NotificationProps = NotificationMsg & ActionProps
type Props = StateProps & ActionProps

const renderPopup = ({ popupsTypes: openPopups, onClose, ...p }: Props) => {
    if (openPopups.includes("linkPopup")) {
        const meta = p.popups.find(s => s.type === "linkPopup") as ValueState<"linkPopup", LinkPopupMeta>
        return <LinkPopup onClose={() => onClose("linkPopup")} meta={meta?.value} />
    }
    if (openPopups.includes("permissions"))
        return <ErrorPopup isPermissionError onClose={() => onClose("permissions")} />
    if (openPopups.includes("error")) return <ErrorPopup onClose={() => onClose("error")} />
    if (openPopups.includes("showEmail")) return <GetEmail onFormSubmit={p.onEmailReceived} />
    if (openPopups.includes("showContact")) return <Contact onClose={() => onClose("showContact")} />
    if (openPopups.includes("profile")) return <EditProfilePopup onClose={() => onClose("profile")} />

    if (openPopups.includes("pipelineRemoval")) {
        const meta: any = p.popups.find(s => s.type === "pipelineRemoval")
        return <PipelineRemovalView onClose={() => onClose("pipelineRemoval")} meta={meta?.value} />
    }
    if (openPopups.includes("export")) return <ExportPopup onClose={() => onClose("export")} />
    if (openPopups.includes("showFAQ")) return <FAQ onClose={() => onClose("showFAQ")} />
    if (openPopups.includes("attachmentInfo")) {
        const meta: any = p.popups.find(s => s.type === "attachmentInfo")
        return <AttachmentInfoPopup onClose={() => onClose("attachmentInfo")} meta={meta?.value} />
    }
    if (openPopups.includes("mandatoryComment")) {
        const meta = p.popups.find(s => s.type === "mandatoryComment") as ValueState<"mandatoryComment", CommentMeta>
        return <MandatoryCommentPopup onClose={() => onClose("mandatoryComment")} meta={meta?.value} />
    }
    if (openPopups.includes("hubEdit")) {
        const meta = p.popups.find(s => s.type === "hubEdit") as ValueState<"hubEdit", HubPayload>
        return <EditHubView onClose={() => onClose("hubEdit")} meta={meta?.value} />
    }
    if (openPopups.includes("hubAdd")) {
        return <AddHubView onClose={() => onClose("hubAdd")} meta={{ hubSlug: "", name: "" }} />
    }
    if (openPopups.includes("selectExportType")) {
        const meta = p.popups.find(s => s.type === "selectExportType") as ValueState<
            "selectExportType",
            ExportPopupMeta
        >
        return <SelectExportTypePopup onClose={() => onClose("selectExportType")} meta={meta?.value} />
    }
    if (openPopups.includes("byIdsList")) {
        const meta = p.popups.find(s => s.type === "byIdsList") as ValueState<"byIdsList", ByIdsListData>
        return <ByIdsListPopup onClose={() => onClose("byIdsList")} meta={meta?.value} />
    }
    if (openPopups.includes("deleteConfirmation")) {
        const meta = p.popups.find(s => s.type === "deleteConfirmation") as ValueState<
            "deleteConfirmation",
            DeleteConfirmationPopupMeta
        >
        return <DeleteConfirmationPopup onClose={() => onClose("deleteConfirmation")} meta={meta?.value} />
    }

    if (openPopups.includes("prioritizationUpload")) {
        const meta = p.popups.find(s => s.type === "prioritizationUpload") as ValueState<
            "prioritizationUpload",
            UploadPrioritizationMeta
        >

        return <UploadPrioritization onClose={() => onClose("prioritizationUpload")} meta={meta?.value} />
    }
}

const PopupRendererPure: React.FC<Props> = p => {
    return <ModalContainer isOpened={!isEmpty(p.popupsTypes)}>{renderPopup(p)}</ModalContainer>
}

const mapState: MapState<StateProps> = ({ ui: { popups } }) => ({ popups, popupsTypes: popups.map(p => p.type) })
const mapDispatch: MapDispatch<ActionProps> = d => ({
    onClose: paramKey => d(actions.closePopup(paramKey)),
    onEmailReceived: email => {
        d(actions.closePopup("showEmail"))
        d(authActions.saveDemoEmail(email))
    }
})

export const PopupRenderer = connect(mapState, mapDispatch)(PopupRendererPure)
