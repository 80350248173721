/* eslint-disable max-lines */
import * as React from "react"

import { initialFilters } from "../../store/ui"

import { _FilterBarContainer } from "../Header"
import { Filter, FilterActions, FilterProps } from "./Filter"
import { LinkButton } from "../../styles/buttons"
import { Loader } from "../common/Loader"
import { CommonFilterProps } from "../../containers/Filters"
import { FilterType } from "../../../functions/src/models/filtering"

// Same as FiltersColumnProps, but it's not necessary to share type
type FiltersBarProps = CommonFilterProps & {
    onChange: (key: FilterType) => FilterActions["onChange"]
    onClear: (key: FilterType) => FilterActions["onClear"]
    areFiltersSet: boolean
}
export const FiltersBar = React.memo((p: FiltersBarProps) => (
    <_FilterBarContainer wrap={p.wrap}>
        {p.filters.map(filterType =>
            p[filterType].loading ? (
                <Loader size="small" key={filterType} />
            ) : (
                <Filter
                    key={filterType}
                    onChange={p.onChange(filterType)}
                    onClear={p.onClear(filterType)}
                    {...(p[filterType] as Loaded<FilterProps>)}
                />
            )
        )}
        {p.withClear && p.areFiltersSet && (
            <LinkButton color="theme6" onClick={() => p.updateFilters(initialFilters)}>
                Clear filters
            </LinkButton>
        )}
        {p.children}
    </_FilterBarContainer>
))
