export function createAction<A extends string>(type: A): TypedAction<A>
export function createAction<P, A extends string, Meta extends SMap<unknown> = SMap<unknown>>(
    type: A,
    payload: P,
    meta?: Meta
): TypePayloadAction<A, P, Meta>
export function createAction(type: any, payload?: any, meta?: any) {
    return payload !== undefined ? (meta !== undefined ? { type, payload, meta } : { type, payload }) : { type }
}

export const createCloudAction = <P, A extends string>(type: A, payload: P) => (actionId: string) =>
    createAction<P, A, CloudActionMeta>(type, payload, { actionId })
