/* eslint-disable max-lines */
import * as React from "react"
import styled, { css } from "styled-components"
import { darken } from "polished"
import { styleFromProp, styleIfProp, getItemWidth } from "./common"
import { ThemeColors } from "./styled"
import { Link as RouterLink } from "react-router-dom"
import {
    mkButton,
    withBackgroundColor,
    withContent,
    withIconSize,
    withColor,
    withHover,
    withBorder,
    withTextDecoration,
    withSize
} from "./phantomButtons"
import { PhantomButton } from "./PhantomButtonsView"
import { pipe } from "fp-ts/lib/pipeable"
import { _IconSvgContainer } from "../components/IconSvg.styles"

const FILTER_DISABLED_COLOR = "#d4d7da"

export type ButtonProps = { wide?: boolean; height?: number; width?: number; disabled?: boolean }
const BaseButtonComponent = (
    { onClick, ...p }: React.ButtonHTMLAttributes<HTMLButtonElement>,
    ref: React.RefObject<HTMLButtonElement> | null
) => (
    <button data-cy="base-button" onClick={p.disabled ? undefined : onClick} {...p} ref={ref}>
        {p.children}
    </button>
)

export const BaseButton = styled(React.forwardRef(BaseButtonComponent))`
    border-radius: 4px;
    height: 36px;
    outline: none;
    ${p => p.onClick && "cursor: pointer;"};
    line-height: 1;
    border: 0;
    font-family: inherit;
`

type LinkButtonProps = {
    color?: keyof ThemeColors
    size?: number
    bold?: boolean
    boldOnHover?: boolean
    inline?: boolean
}
export const LinkButton = styled(BaseButton).withConfig<LinkButtonProps>({
    shouldForwardProp: (p, d) => !["color", "size", "bold", "boldOnHover"].includes(p) && d(p)
})`
    display: ${p => (p.inline ? "inline-flex" : "flex")};
    align-items: center;
    font-size: ${p => p.size || 14}px;
    ${styleIfProp("bold", "font-weight: bold;")}
    ${p =>
        p.boldOnHover &&
        p.onClick &&
        css`
            &:hover {
                font-weight: bold;
            }
        `}
    line-height: 1;
    padding: 4px 8px;
    background: none;
    color: ${({ theme, color }) => theme.colors[color || "primary"]};
    height: auto;
    outline: none;
    text-decoration: none;
    & > * + * {
        margin-left: 4px;
    }
`

type LinkProps = { color?: keyof ThemeColors; size?: number; bold?: boolean; inline?: boolean }
export const Link = styled(RouterLink).withConfig<LinkProps>({
    shouldForwardProp: (p, d) => !["color", "size", "bold"].includes(p) && d(p)
})`
    display: ${p => (p.inline ? "inline-flex" : "flex")};
    align-items: center;
    font-size: ${p => p.size || 14}px;
    ${styleIfProp("bold", "font-weight: bold;")}
    line-height: 1;
    padding: 4px 8px;
    background: none;
    outline: none;
    color: ${({ theme, color }) => theme.colors[color || "primary"]};
    text-decoration: none;

    & > * + * {
        margin-left: 8px;
    }
`

export const Filter = styled(BaseButton).withConfig<{ off: boolean }>({
    shouldForwardProp: (p, d) => !["off"].includes(p) && d(p)
})`
    font-size: 14px;
    color: #fff;
    text-align: left;
    margin: 5px 0;
    padding: 10px;
    margin-right: 16px;
    height: auto;
    background-color: ${({ color, off }) => (off ? FILTER_DISABLED_COLOR : color)};
    transition: background-color 0.2s linear;
    &:hover {
        background-color: ${({ color, off }) => darken(0.05, off ? FILTER_DISABLED_COLOR : color!)};
    }
    flex-shrink: 0;
`

export const StarButton = styled(BaseButton).withConfig<{ starred: boolean }>({
    shouldForwardProp: (p, d) => !["starred"].includes(p) && d(p)
})`
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    border-radius: 4px;
    outline: none;
    background: none;

    ${_IconSvgContainer} {
        --fill: ${p => (!p.starred ? p.theme.colors.theme18 : p.theme.colors.theme15)};
    }

    &:focus,
    &:hover {
        ${_IconSvgContainer} {
            --fill: ${p => (p.starred ? p.theme.colors.theme20 : p.theme.colors.theme19)};
        }
    }
`
export const Button = styled(BaseButton).withConfig<ButtonProps>({
    shouldForwardProp: (p, d) => !["wide", "height", "width"].includes(p) && d(p)
})`
    display: inline-block;
    background: #0093ee;
    color: #fff;
    box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0);
    font-size: 15px;
    min-width: 200px;
    width: ${({ width, wide }) => getItemWidth(wide, width)};
    height: ${p => styleFromProp(p.height, `${p.height}px`, "42px")};
    transition: background 0.1s linear;

    &:hover {
        background: #0285d7;
    }
    &:active {
        background: #0079c4;
    }
    &:disabled {
        border: solid 1px rgba(0, 0, 0, 0.05);
        background-color: #edeff2;
        color: rgba(44, 64, 90, 0.33);
    }
`

export const SmallButton = styled(Button)<ButtonProps>`
    display: inline-flex;
    align-items: center;
    min-width: unset;
    width: ${({ width, wide }) => getItemWidth(wide, width)};
    padding: 0 16px;
`

export const PGButton = styled(BaseButton).withConfig<ButtonProps>({
    shouldForwardProp: (p, d) => !["wide", "height", "width"].includes(p) && d(p)
})`
    background: #003eaf;
    box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0);
    color: #fff;
    font-size: 15px;
    min-width: 200px;
    height: ${p => styleFromProp(p.height, `${p.height}px`, "42px")};
    display: inline-block;
    transition: background 0.1s linear;
    width: ${({ width, wide }) => getItemWidth(wide, width)};
`

export const ErrorButton = styled(Button)`
    background: red;
    color: #fff;
    border: solid 1px #edeff2;
    &:hover {
        box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0);
        border: solid 1px rgba(0, 0, 0, 0.05);
        background: red;
    }
    &:active {
        box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0);
        border: solid 1px rgba(0, 0, 0, 0.05);
        background-color: #fff;
        color: ${({ theme }) => theme.colors.primary};
    }
    &:disabled {
        color: #fff;
        box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0);
        border: solid 1px rgba(0, 0, 0, 0.05);
        background-color: #ffbdbd;
    }
`

export const SecondaryButton = styled(Button)`
    background: #fff;
    color: ${({ theme }) => theme.colors.primary};
    border: solid 1px #edeff2;
    &:hover {
        box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0);
        border: solid 1px rgba(0, 0, 0, 0.05);
        background-color: #f3f4f6;
    }
    &:active {
        box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0);
        border: solid 1px rgba(0, 0, 0, 0.05);
        background-color: #ebecf0;
    }
    &:disabled {
        color: #bfc5ce;
        box-shadow: inset -1px 1px 0 0 rgba(255, 255, 255, 0);
        border: solid 1px rgba(0, 0, 0, 0.05);
        background-color: #ffffff;
    }
`

export const _IconButton = styled(BaseButton).withConfig<
    { fullWidth?: boolean; right?: boolean } & OmitStrict<ButtonProps, "disabled">
>({
    shouldForwardProp: (p, d) => !["fullWidth"].includes(p) && d(p)
})`
    ${styleIfProp("fullWidth", "flex: 1 0 auto;")}
    ${styleIfProp("disabled", "filter: grayscale(100%) opacity(30%);")}
    ${styleIfProp("disabled", "cursor: default;")}
    ${styleIfProp("right", "margin-left: auto;")}
    height: ${p => styleFromProp(p.height, `${p.height}px`, "42px")};
    width: ${({ width, wide }) => getItemWidth(wide, width)};
    background: none;
    display: flex;
    align-items: center;
    line-height: 1.33;
    letter-spacing: 0.3px;
    font-size: 12px;
    & > * + * {
        margin-left: 4px;
    }
`

export const _PureIconButton = styled(BaseButton)`
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #f0f0f4;
    border-radius: 4px;
    height: 42px;
    width: 42px;
    padding: 0;
`

type ButtonGroupProps = { vertical?: boolean; horizontal?: boolean; spacing?: number }
export const ButtonGroup = styled.div<ButtonGroupProps>`
    display: flex;
    ${styleIfProp("vertical", "flex-direction: column")}
    ${styleIfProp("horizontal", "flex-direction: row")}
    & > * + * {
        ${p => styleIfProp("horizontal", `margin-left: ${p.spacing || 8}px`)}
        ${p => styleIfProp("vertical", `margin-top: ${p.spacing || 8}px`)}
    }
`

export const DeleteItem = ({ onClick }: { onClick: F0 }) =>
    pipe(
        mkButton(onClick),
        withBackgroundColor("danger"),
        withContent({ type: "Text&Icon", icon: "trash", text: "Delete" }),
        withIconSize({ height: 16, width: 16 }),
        withColor("theme4"),
        withHover("dangerHover"),
        withBorder("primary"),
        // TODO: you could possibly derive all types of buttons and instantiate them with certain props, but this is
        // merely an example of how it could be done
        PhantomButton
    )

export const ClearButton = ({ onClick }: { onClick: F0 }) =>
    pipe(
        mkButton(onClick),
        withContent({ type: "Text", text: "Clear" }),
        withBackgroundColor("transparent"),
        withColor("primary"),
        withBorder("borderless"),
        withTextDecoration("underline"),
        PhantomButton
    )

export const InfoButton = ({ onClick, children }: { onClick: F0; children: React.ReactNode }) =>
    pipe(
        mkButton(onClick),
        withContent({ type: "ReactNode", value: children }),
        withBackgroundColor("info"),
        withSize("unset"),
        withColor("theme3"),
        withHover("infoHover"),
        withBorder("primary"),
        PhantomButton
    )
