import * as React from "react"

import { ListProps } from "./Lists"
import { _FlexRow, _AbsPosContainer, _AbsPosElement, _FlexColumn, Flex, Grid } from "../../styles/common"
import { Table } from "../../components/table/TableView"
import { IconButton } from "../../components/common/Buttons"
import { _BodyFont, Prominent } from "../../styles/typography"
import { isEmpty } from "../../../functions/src/utils/validators"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { toOption } from "../../../functions/src/utils/types"
import { Msg } from "../../models/notifications"
import { isRoot, isAdmin } from "../../models/LoginStatus"
import { ADDED_OBJECT_NEW_ID } from "../cards/EditForms"
import { useList } from "./useList"
import { useExport } from "../../utils/hooks/useExport"
import { Loader } from "../../components/common/Loader"
import { _ActionBarContainer, _ItemsCount, _ListContainer, _ListTableContainer } from "./List.styles"
import { actions as uiActions } from "../../store/ui/actions"
import { SortingKey } from "../../../functions/src/models/sorting"
import { getCollectionSchema } from "../../../functions/src/models/schemas"
import { FiltersContainer } from "../../containers/Filters"
import { Filters, FilterType } from "../../../functions/src/models/filtering"

export const List = <C extends CName>({ dispatch, ...p }: ListProps<C>) => {
    const [selectedRows, setSelectedRows] = React.useState<React.ReactText[]>([])
    const [, onDeleteItems, reset] = useCloudAction(p.onDeleteItems(dispatch), p.actionResults, async ({ result }) => {
        p.queueNotification(Msg("delete", { name: "item(s)" }, result))
        setSelectedRows([])
        reset()
    })
    const confirmDeleteItems = (ids: ObjectId[]) => {
        const { singleName, collectionName } = getCollectionSchema(p.cname)
        p.openPopup("deleteConfirmation", {
            objectType: ids.length > 1 ? collectionName : singleName,
            objectName: "",
            customText: (
                <>
                    Are you sure you want to delete <Prominent color="danger">{ids.length}</Prominent> element(s)?
                </>
            ),
            onDelete: () => onDeleteItems(ids)
        })
    }
    const { exporting, doExport } = useExport(p.deps.radar.radarId)
    const tableProps = useList(p)

    const isDemo = p.config.restriction === "demo"
    const withActionBar = isAdmin(p.loginStatus) || !isDemo
    const withContactedLeads =
        (p.config.withContactedLead && p.radarConfig.contactedLeads?.includes(p.cname) && !p.isRoot) ?? null

    const filters: FilterType[] = ["searchArea"]
    if (!isDemo && p.config.withSegmentTags) filters.push("segmentTags")
    filters.push("tags")
    if (!isDemo && p.config.withStars) filters.push("stars")
    if (!isRoot(p.loginStatus)) filters.push("pipelines")
    if (p.config.withPriorityRanks) filters.push("priorities")
    if (!isDemo && !isRoot && p.config.withAssignments) filters.push("assignments")
    filters.push("date")
    filters.push(p.cname as C & keyof Filters)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onReachBottom = React.useCallback(() => p.onLoadMore(dispatch), [p.onLoadMore, dispatch])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onRowClick = React.useCallback((id: string, event: React.MouseEvent) => p.onRowClick(id, dispatch, event), [
        p.onRowClick,
        dispatch
    ])
    const onRowSelect = React.useCallback(rIds => setSelectedRows(rIds), [])

    /* This flex is necessary because of chrome grid bug... */
    return (
        <Flex grow={1}>
            <Grid columns="250px 1fr" grow={1}>
                <FiltersContainer
                    filters={filters}
                    withClear
                    cname={p.cname}
                    resetOnPickTypes={["searchArea"]}
                    column
                />
                <_ListContainer>
                    {withActionBar && (
                        <_ActionBarContainer>
                            <_FlexRow>
                                {isAdmin(p.loginStatus) ? (
                                    <>
                                        {exporting && (
                                            <div>
                                                <Loader size="small" />
                                            </div>
                                        )}
                                        <IconButton
                                            icon={{ name: "export-icon", width: 17, height: 20 }}
                                            onClick={() =>
                                                dispatch(
                                                    uiActions.openPopup("selectExportType", {
                                                        type: "all",
                                                        cnames: [p.cname],
                                                        filters: p.ui.filters,
                                                        withContactedLeads,
                                                        onExport: doExport
                                                    })
                                                )
                                            }>
                                            <_BodyFont data-cy="export-all" s15 color="theme6">
                                                Export all
                                            </_BodyFont>
                                        </IconButton>
                                        <IconButton
                                            disabled={isEmpty(selectedRows)}
                                            icon={{ name: "export-icon", width: 17, height: 20 }}
                                            onClick={() =>
                                                dispatch(
                                                    uiActions.openPopup("selectExportType", {
                                                        type: "ids",
                                                        ids: { [p.cname]: selectedRows as string[] },
                                                        withContactedLeads,
                                                        onExport: doExport
                                                    })
                                                )
                                            }>
                                            <_BodyFont s15 color="theme6">
                                                Export selected
                                            </_BodyFont>
                                        </IconButton>
                                        <IconButton
                                            data-cy="add-item-manually"
                                            icon={{ name: "green-plus", width: 16, height: 16 }}
                                            onClick={() => p.onItemEdit(p.cname, ADDED_OBJECT_NEW_ID)}>
                                            <_BodyFont s15 color="theme6">
                                                Add item manually
                                            </_BodyFont>
                                        </IconButton>
                                        <IconButton
                                            right
                                            disabled={isEmpty(selectedRows)}
                                            icon={{ name: "red-minus", width: 16, height: 16 }}
                                            onClick={() => confirmDeleteItems(selectedRows as string[])}>
                                            <_BodyFont data-cy="delete-selected" s15 color="theme6">
                                                Delete Selected
                                            </_BodyFont>
                                        </IconButton>
                                    </>
                                ) : null}
                                {!tableProps.loading && <_ItemsCount>Items: {p.itemsCount}</_ItemsCount>}
                            </_FlexRow>
                        </_ActionBarContainer>
                    )}
                    <_ListTableContainer>
                        <_AbsPosContainer>
                            <_AbsPosElement>
                                <_FlexColumn grow={1} style={{ maxWidth: "100%" }}>
                                    <Table
                                        {...p}
                                        {...tableProps}
                                        autofocus
                                        isSelectable={isAdmin(p.loginStatus) || !isDemo}
                                        onReachBottom={onReachBottom}
                                        showTooltip={!isDemo}
                                        onSort={key =>
                                            p.onSort(
                                                key as SortingKey<C>,
                                                tableProps.sortingKey === key
                                                    ? !tableProps.sortingAsc
                                                    : // TODO Make default sorting direction setting for column possible
                                                    key === "pipelineStage"
                                                    ? true
                                                    : false
                                            )
                                        }
                                        onRowClick={onRowClick}
                                        onRowSelect={onRowSelect}
                                        interactiveOptions={
                                            !isDemo && isAdmin(p.loginStatus)
                                                ? [toOption("Edit", (id: string) => p.onItemEdit(p.cname, id))]
                                                : undefined
                                        }
                                    />
                                </_FlexColumn>
                            </_AbsPosElement>
                        </_AbsPosContainer>
                    </_ListTableContainer>
                </_ListContainer>
            </Grid>
        </Flex>
    )
}
