import { getReducer, impossibleStateBreaker, cmdList } from "../../utils/redux"
import * as cmds from "./cloudCmds"
import { getEncryptedText } from "../../../functions/src/utils/crypto"
import { toImportableItem } from "../importing/importingUtils"
import { encryptPipeline, encryptLead } from "../../../functions/src/models/decorators"
import { actions as dataActions } from "../data/actions"
import { CloudState, RootContext } from "../store"
import { isRadarLocation } from "../../models/LocationType"
import { assertNever } from "../../../functions/src/utils"
import { Actions } from "./actions"

export const initialState: CloudState = {
    actionsResults: {}
}

export const reducer = getReducer<CloudState, Actions, RootContext>((state, action, context) => {
    const senderId = context.userId!
    const hostname = window.location.origin
    switch (action.type) {
        case "createRadar":
            return cmds.createRadar({ ...action.payload, userId: context.userId! })
        case "updateRadar":
            return cmds.updateRadar({ ...action.payload, userId: context.userId! })

        case "createHub":
            return cmds.createHub(action.payload.actionId, senderId, action.payload.hub)
        case "updateHub":
            return cmds.updateHub(action.payload.actionId, senderId, action.payload.hub, action.payload.hubId)

        case "addUser":
            return cmds.inviteUser({ ...action.payload, hostname, senderId })
        case "updateUser":
            return cmds.updateUser(action.payload.actionId, senderId, action.payload.userPayload)

        case "revokeInvitation":
            return cmds.revokeInvitation({
                ...action.payload,
                hostname,
                senderId
            })

        case "portalInvite":
            return cmds.inviteToPortal({ ...action.payload, senderId })

        case "resendInivitation":
            return cmds.resendInvitation({ ...action.payload, hostname, senderId })

        case "acceptRequest":
            return cmds.acceptRequest({ ...action.payload, hostname, senderId })

        case "declineRequest":
            return cmds.declineRequest({ ...action.payload, hostname, senderId })

        case "mutateSearchArea":
            return cmds.mutateSearchArea({ ...action.payload, senderId })

        case "assignSearchArea":
            return cmds.assignSearchArea({ ...action.payload, senderId })

        case "updateProfile":
            return cmds.updateProfile(action.payload.actionId, action.payload.user)

        case "assignTag":
            return cmds.assignTag({ ...action.payload, senderId })
        case "mutateTag":
            return cmds.mutateTag({ ...action.payload, senderId })
        case "createAndAssignTag":
            return cmds.createAndAssignTag({ ...action.payload, senderId })

        case "assignSegmentTag":
            return cmds.assignSegmentTag({ ...action.payload, senderId })
        case "mutateSegmentTag":
            return cmds.mutateSegmentTag({ ...action.payload, senderId })
        case "createBatchSegmentTags":
            return cmds.createBatchSegmentTags({ ...action.payload, senderId })
        case "createAndAssignSegmentTag":
            return cmds.createAndAssignSegmentTag({ ...action.payload, senderId })

        case "importPrioritiesChunk":
            return cmds.importPriorityPayload({ ...action.payload, senderId })

        case "createComment": {
            const key = context.secureKey
            if (!key) return impossibleStateBreaker("createComment action", "Encryption key should be available.")

            if (action.payload.meta?.type === "contactedLead") {
                const { newValue, oldValue } = action.payload.meta.value
                const newEncrypted = newValue ? encryptLead(newValue, key) : undefined
                const oldEncrypted = oldValue ? encryptLead(oldValue, key) : undefined
                const encrypted = getEncryptedText("Contacted lead comment", key)
                return cmds.createComment({
                    ...action.payload,
                    meta: {
                        ...action.payload.meta,
                        value: { ...action.payload.meta.value, newValue: newEncrypted, oldValue: oldEncrypted }
                    },
                    text: encrypted,
                    senderId
                })
            } else {
                const encrypted = getEncryptedText(action.payload.text, key)
                return cmds.createComment({ ...action.payload, text: encrypted, senderId })
            }
        }

        case "editComment": {
            const key = context.secureKey
            if (!key) return impossibleStateBreaker("editComment action", "Encryption key should be available.")
            if (action.payload.meta?.type === "contactedLead") {
                const { newValue, oldValue } = action.payload.meta.value
                const newEncrypted = newValue ? encryptLead(newValue, key) : undefined
                const oldEncrypted = oldValue ? encryptLead(oldValue, key) : undefined
                const encrypted = getEncryptedText("Contacted lead comment", key)
                return cmds.editComment({
                    ...action.payload,
                    meta: {
                        ...action.payload.meta,
                        value: { ...action.payload.meta.value, newValue: newEncrypted, oldValue: oldEncrypted }
                    },
                    text: encrypted,
                    senderId
                })
            } else {
                const encrypted = getEncryptedText(action.payload.text, key)
                return cmds.editComment({ ...action.payload, text: encrypted, senderId })
            }
        }

        case "deleteComment":
            return cmds.deleteComment({ ...action.payload, senderId })

        case "removeRadarCollectionItems":
            return cmds.removeRadarCollectionItems({ ...action.payload, senderId })

        case "mutatePipelineStage": {
            const key = context.secureKey
            if (!key) return impossibleStateBreaker("mutatePipelineStage action", "Encryption key should be available.")
            return cmds.mutatePipelineValue({
                actionId: action.payload.actionId,
                ...encryptPipeline(action.payload, key),
                senderId
            })
        }
        case "mutateDecorator": {
            if (!isRadarLocation(context)) return {}
            const payload = { radarId: context.locationParams.radarId, ...action.payload }
            return cmdList([
                dataActions._setOptimisticDecorator(payload),
                cmds.mutateDecorator({ ...payload, senderId })
            ])
        }
        case "mutateDecoratorDebounced": {
            if (!isRadarLocation(context)) return {}
            const payload = { radarId: context.locationParams.radarId, ...action.payload }
            return cmdList([
                dataActions._setOptimisticDecorator(payload),
                cmds.mutateDecoratorDebounced({ ...payload, senderId })
            ])
        }
        case "addRelation":
            if (!isRadarLocation(context)) return {}
            return cmds.addRelation({ radarId: context.locationParams.radarId, senderId, ...action.payload })
        case "removeRelation": {
            if (!isRadarLocation(context)) return {}
            const radarId = context.locationParams.radarId
            return cmdList([
                dataActions._upsertRelationRemoval(radarId, action.payload.relation),
                cmds.removeRelation({ radarId, senderId, ...action.payload })
            ])
        }

        case "setNewsfeedVisit":
            return cmds.updateNewsfeedVisit(action.payload.actionId, senderId, action.payload)

        case "_setActionResults":
            return { actionsResults: { ...state.actionsResults, ...action.payload } }

        case "_mutateRadarCollectionItem":
            return cmds.mutateRadarCollectionItem({
                ...action.payload,
                item: toImportableItem(action.payload.cname, action.payload.item)!,
                senderId
            })

        case "mutateByRadarConfig":
            return cmds.mutateByRadarConfig({ ...action.payload, senderId })

        case "uploadPrioritization":
            return cmds.uploadPrioritization({ ...action.payload, senderId })
    }
    assertNever(action)
})
