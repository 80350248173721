import * as React from "react"
import { values } from "../../../functions/src/utils/map"

export const getHubFromSlug = (hubSlug: string, hubs: SMap<ExtHub>) => values(hubs).find(h => h.hubSlug === hubSlug)

export const getDeletePopupText = (type: string, name: string, count: number) => (
    <>
        {type} <b style={{ color: "red" }}>{name}</b>{" "}
        {count > 0 ? (
            <>
                is assigned to <b>{count}</b> item{count === 1 ? "" : "s"}.
            </>
        ) : (
            "is not assigned to any item."
        )}
        <br />
        Are you sure you want to delete it?
    </>
)
