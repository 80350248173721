import styled from "styled-components"
import { _AbsPosContainer, _FlexContainer } from "../../../styles/common"

export const _CardSectionTableContainer = styled(_AbsPosContainer)`
    max-width: unset;
    min-height: 300px;
`

export const _CardSectionContainer = styled(_FlexContainer)<{ big?: boolean }>`
    padding: ${p => (p.big ? 1.5 * p.theme.sizes.defaultPadding : p.theme.sizes.defaultPadding)}px;
    border-radius: ${p => (p.big ? "27px" : "20px")};
    box-shadow: 0 0 4px 0 #fafbfc;
    background: #fff;
    flex-grow: ${p => p.big && 1};
    flex-shrink: 0;

    & > * + * {
        margin-top: ${p => 0.7 * p.theme.sizes.defaultPadding}px;
    }

    & > ${_CardSectionTableContainer} {
        margin: 0 -${p => (p.big ? 1.5 * p.theme.sizes.defaultPadding : p.theme.sizes.defaultPadding)}px;
    }

    ${_CardSectionTableContainer} {
        border-radius: ${p => (p.big ? "27px" : "20px")};
    }
`

export const _CardSectionTitle = styled.h2`
    font-size: 18px;
    font-weight: 900;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin: 0;
`
