type CancellableResult<T> = State<"cancelled"> | ValueState<"resolved", T> | ValueState<"cbError", Error>
export type Cancellable<T> = { cancel: F0; start: () => Promise<CancellableResult<T>> }

// TODO: add type constructors for UseCancellableResult
// TODO: Refactor to use Async<T>
// TODO: Write tests for proper error passing

export const isResolved = <T>(v: CancellableResult<T>): v is ValueState<"resolved", T> => v.type === "resolved"
export const isCancelled = <T>(v: CancellableResult<T>): v is State<"cancelled"> => v.type === "cancelled"

export const cancellable = <T>(cb: F0<Promise<T>>): Cancellable<T> => {
    let rejectRef: ((arg?: CancellableResult<T>) => void) | null = null
    const cancel = () => rejectRef && rejectRef({ type: "cancelled" })
    const start = () =>
        new Promise<CancellableResult<T>>((res, rej) => {
            rejectRef = rej
            cb()
                .then(v => res({ type: "resolved", value: v }))
                .catch(err => rej({ type: "cbError", value: err }))
        })

    return { cancel, start }
}
