import { keys } from "../../functions/src/utils/map"
import { NamedPath } from "./router.types"

export const matchPathParams = (pattern: string, uri: string, exact = true): SMap<string> | null => {
    const mapping = [""]
    const match = new RegExp(
        "^" +
            pattern
                .replace(/[.[\]()^$\\]/g, m => `\\${m}`)
                .replace(/:(\w+)/g, (_x, n) => {
                    mapping.push(n)
                    return "([^/]+)"
                }) +
            "/?" +
            (exact ? "$" : "")
    ).exec(uri)
    if (!match) return null
    const res: TMap<string, string> = {}
    for (let i = 1; i < mapping.length; ++i) res[mapping[i]] = match[i]
    return res
}

export const matchesPath = (pattern: string, uri: string, exact = true): boolean =>
    Boolean(matchPathParams(pattern, uri, exact))

export const materialize = (pattern: string, params: OMap<string, string>): string => {
    let url = pattern
    keys(params).forEach(key => {
        url = url.replace(key.startsWith(":") ? key : `:${key}`, params[key] || "")
    })
    return url
}

type RouterPathName = { location: { pathname: string } }
export const getCurrentPath = (
    paths: NamedPath[],
    router: RouterPathName,
    fallback: NamedPath,
    exact?: boolean
): NamedPath => {
    if (!router) return fallback
    return paths.find(p => matchesPath(p.path, router.location.pathname, exact)) || fallback
}

export const getMatchingPath = <T>(pathToMatch: string, paths: T[], getPath: F1<T, string>): T | null =>
    paths.find(v => matchesPath(getPath(v), pathToMatch, true)) || null
