import * as React from "react"
import { connect } from "react-redux"
import { actions } from "../../store/auth/actions"
import { userPaths } from "../../paths"
import { MapDispatch } from "../../utils/redux.types"

type Actions = { tryLogout: F0; navigateToLogin: F0 }

const Logout: React.FC<Actions> = p => {
    React.useEffect(() => {
        p.tryLogout()
        p.navigateToLogin()
    })
    return null
}

const mapDispatch: MapDispatch<Actions> = d => ({
    tryLogout: () => d(actions.tryLogout()),
    navigateToLogin: () => d(actions.navigate(userPaths["user/login"]))
})

export const LogoutView = connect(null, mapDispatch)(Logout)
