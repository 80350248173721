import * as React from "react"
import { connect, ConnectedComponent } from "react-redux"
import { StepDescription, Stepper } from "../../components/common/Stepper"
import { values } from "../../../functions/src/utils/map"
import { Button, SecondaryButton, ButtonGroup } from "../../styles/buttons"
import { _HorizontalSpace, _Right, _VerticalSpace, Flex, Margin } from "../../styles/common"
import { isEmpty } from "../../../functions/src/utils/validators"
import { assertNever, _noop } from "../../../functions/src/utils"
import { getMapDispatch2 } from "../../utils/redux"
import { _Header, _CenterMiddle, _DoneWrapper } from "../../components/importing/SelectFiles.styles"
import { _LayoutContainer } from "../../components/Page"
import { actions as authActions } from "../../store/auth/actions"
import { countImportData } from "../../store/importing"
import { actions } from "../../store/importing/actions"
import { SelectFiles } from "../../components/importing/SelectFiles"
import { PreloadedCollectionsListView } from "../../components/importing/PreloadedCollectionsList"
import { Progress } from "../../components/common/Progress"
import { Prompt } from "react-router-dom"
import { radarPaths } from "../../paths"
import { isFetched, Loaded, Loading } from "../../../functions/src/utils/types"
import { LoadableView } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import { getDataByDeps } from "../../store/data/dataSelectors"
import { ImportingState } from "../../store/store"
import { RadarViewFetchMap, getDecoratingFetchMap } from "../../dependencies"
import { getCurrentRadarId } from "../../models/LocationType"
import { isRoot } from "../../models/LoginStatus"
import { getAvailableCollections } from "../../../functions/src/models/collections"
import { IconSvg } from "../../components/IconSvg"

const stepDescription: TMap<ImportingStateType, StepDescription> = {
    SelectFiles: { title: "Select files", stepNumber: 1 },
    PreloadFiles: { title: "View / edit object type", stepNumber: 2 },
    UploadingChunks: { title: "Upload", stepNumber: 3 },
    Done: { title: "Files merged", stepNumber: 4 },
    Error: { title: "Error occured", stepNumber: 5 }
}

type Steps = { available: number[]; onResetClick: F0; onNextClick: F0; reset: boolean }
const Steps = (p: Partial<Steps>): Steps => ({
    available: [],
    onResetClick: _noop,
    onNextClick: _noop,
    reset: false,
    ...p
})

const getAvailableSteps = (p: Props): Steps => {
    switch (p.importing.type) {
        case "SelectFiles":
            return Steps({
                available: isEmpty(p.importing.value.recognized) ? [] : [2],
                onNextClick: p.startPreload
            })
        case "PreloadFiles":
            if (values(p.importing.value).some(c => Boolean(c) && c!.isValid === false))
                return Steps({ available: [1], reset: true, onResetClick: p.startSelectFiles })

            return Steps({
                available: [1, 3],
                onNextClick: p.startImport,
                onResetClick: p.startSelectFiles,
                reset: true
            })
        case "UploadingChunks":
            return Steps({})
        case "Error":
        case "Done":
            return Steps({
                available: [1],
                onNextClick: p.startSelectFiles,
                onResetClick: p.startSelectFiles,
                reset: true
            })
        default:
            assertNever(p.importing)
            return Steps({})
    }
}
const RadarImportWrapper: React.FC<Props> = p => {
    const { available, onNextClick, onResetClick, reset } = getAvailableSteps(p)
    const currentStep = stepDescription[p.importing.type]
    const shouldPrompt = p.importing.type === "UploadingChunks" || p.importing.type === "PreloadFiles"
    return (
        <>
            <_Header>
                <Stepper
                    steps={values(stepDescription).slice(0, 4)}
                    currentStepNumber={currentStep.stepNumber}
                    availableSteps={available}
                    onStepClicked={_noop}
                />
                <_Right>
                    <Margin top="25px" bottom="25px">
                        <Flex wrap>
                            {reset ? <SecondaryButton onClick={onResetClick}>Restart</SecondaryButton> : null}
                            <_HorizontalSpace base="8px" />
                            <Button onClick={onNextClick} disabled={!available.includes(currentStep.stepNumber + 1)}>
                                Next step
                            </Button>
                        </Flex>
                    </Margin>
                </_Right>
            </_Header>

            <_LayoutContainer relative>
                <Prompt
                    when={shouldPrompt}
                    message={() =>
                        "Going back will cause losing all your import progress. Press cancel to abort going back"
                    }
                />
                <Flex grow={1}>
                    <RadarImportContent {...p} />
                </Flex>
            </_LayoutContainer>
        </>
    )
}

const RadarImportContent: React.FC<Props> = p => {
    switch (p.importing.type) {
        case "SelectFiles": {
            return (
                <SelectFiles
                    selectFiles={f => p.selectFiles(f, getAvailableCollections(p.config, p.isRoot))}
                    importing={p.importing}
                    removeSelectedFile={p.removeSelectedFile}
                    config={p.config}
                    isRoot={p.isRoot}
                />
            )
        }
        case "PreloadFiles":
            return <PreloadedCollectionsListView pcs={p.importing.value} />
        case "UploadingChunks":
            return (
                <_CenterMiddle>
                    <Progress
                        title="Uploading"
                        total={p.importing.value.totalSize}
                        current={p.importing.value.totalSize - countImportData(p.importing.value)}
                    />
                </_CenterMiddle>
            )
        case "Done":
            return (
                <_DoneWrapper>
                    <IconSvg name="selected" width={150} height={150} />
                    <div data-cy="uploadOKFirstLabel">Your files have been uploaded succesfully!</div>
                    <div data-cy="uploadOKSecondLabel"> You can import new files or go to dashboard.</div>
                    <ButtonGroup horizontal spacing={10}>
                        <SecondaryButton onClick={p.startSelectFiles}>Import new files</SecondaryButton>
                        <_HorizontalSpace base="8px" />
                        <Button
                            onClick={() => {
                                p.navigate({ path: radarPaths["radar/radar"].path })
                                p.startSelectFiles()
                            }}>
                            Go to Radar
                        </Button>
                    </ButtonGroup>
                </_DoneWrapper>
            )
        case "Error":
            return (
                <_DoneWrapper>
                    <IconSvg name="cross" width={90} height={90} />
                    <_VerticalSpace base="8px" />
                    <div data-cy="uploadFailedFirstLabel">Error has occured during updating the files</div>
                    <div data-cy="uploadFailedSecondLabel">Error message: {p.importing.msg}</div>
                    <ButtonGroup horizontal spacing={10}>
                        <SecondaryButton onClick={p.startSelectFiles}>Reimport files</SecondaryButton>
                        <_HorizontalSpace base="8px" />
                        <Button
                            onClick={() => {
                                p.navigate({ path: radarPaths["radar/radar"].path })
                                p.startSelectFiles()
                            }}>
                            Go to Radar
                        </Button>
                    </ButtonGroup>
                </_DoneWrapper>
            )
    }
}

type StateProps = {
    importing: ImportingState
    config: LocationParams
    isRoot: boolean
}

type Props = StateProps & ReturnType<typeof mapDispatch>

type ImportingStateType = Type<ImportingState>

const mapDispatch = getMapDispatch2(
    actions,
    ["selectFiles", "startPreload", "startImport", "startSelectFiles", "removeSelectedFile"],
    authActions,
    ["navigate"]
)

const mapState = getDataByDeps<StateProps>()(
    RadarViewFetchMap({}, getDecoratingFetchMap("all")),
    (_, { importing, auth }) => {
        const configs = auth.configs
        if (!isFetched(configs)) return Loading()
        const radarId = getCurrentRadarId(auth)!
        const config = radarId ? configs.value[radarId] : ({} as LocationParams)
        return Loaded({ importing, config, isRoot: isRoot(auth.authentication) })
    }
)
const LoadableRadarImportView = LoadableView(ViewLoader, RadarImportWrapper)

export const RadarImportView: ConnectedComponent<typeof LoadableRadarImportView, unknown> = connect(
    mapState,
    mapDispatch
)(LoadableRadarImportView)
