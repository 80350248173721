import styled, { css } from "styled-components"
import { Caption } from "../styles/typography"
import { styleIfProp } from "../styles/common"

export const ModalContainer = styled.div<{ full?: boolean }>`
    position: relative;
    min-height: 124px;
    border-radius: 6px;
    margin: 8px;
    min-width: 624px;
    width: 624px;
    max-width: 95vw;
    width: ${({ full }) => (full ? "95vw" : "124px")};
    background: #fff;
    border-radius: 8px;
`

export const ModalHeader = styled.div`
    background: #fafbfc;
    height: 69px;
    display: flex;
    align-items: center;
    padding: 0 16px 0 30px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.theme2};
    border-radius: 8px 8px 0 0;
`

export const ModalTitle = styled(Caption)``

export const ModalCloseButton = styled.div`
    font-size: 16px;
    cursor: pointer;
    margin-right: 7px;
`

export const ModalContent = styled.div<{ full?: boolean; nonScrollable?: boolean }>`
    padding: 30px;
    max-height: 90vh;
    ${styleIfProp("full", "height: 90vh;")}
    ${styleIfProp(
        "nonScrollable",
        css`
            padding: 10px 30px 0;
            display: flex;
            flex-direction: column;
        `,
        css`
            padding: 30px;
            overflow: auto;
        `
    )}
`
