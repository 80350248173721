import { log, error } from "firebase-functions/lib/logger"
import { _noop } from "../utils"
import { values, keys } from "../utils/map"
import { isString } from "../utils/validators"

/* eslint-disable no-console */
export const Logger = (prefix: string) => {
    const instance =
        typeof window !== "undefined" // Disable logging in the browser console
            ? { log: _noop, error: _noop }
            : process.env.NODE_ENV === "test" // Set logging to default console for test env
            ? {
                  log: (...args: any) =>
                      console.log(...args.map((a: any) => (typeof a != "string" ? JSON.stringify(a) : a))),
                  error: (...args: any) =>
                      console.error(...args.map((a: any) => (typeof a != "string" ? JSON.stringify(a) : a)))
              }
            : { log, error } // Use firebase logging functions for better log readability
    return {
        log: (...vs: any[]) => instance.log(prefix, ...vs),
        error: (...vs: any[]) => instance.error(prefix, ...vs),
        logErrorFields: (map: SMap<Err<any>>, name = "invalid") => {
            const errors = values(map)
            if (!errors.length) return null
            const errorMap = errors.reduce((acc: SMap<{ err: string; count: number }>, err: Err<any>) => {
                const e = err.value
                if (isString(e)) {
                    if (acc[e]) acc[e].count++
                    else acc[e] = { err: e, count: 1 }
                    return acc
                }
                keys(e).forEach((k: string) => (acc[k] ? acc[k].count++ : (acc[k] = { err: e[k], count: 1 })))
                return acc
            }, {})
            instance.log(prefix, name, errors.length, errorMap)
        }
    }
}
