import { isErr, isEmpty, Ok, isOk } from "../../../functions/src/utils/validators"
import { generateCollectionItemId } from "../../../functions/src/models/collections"
import { keys, values, asyncMapObject, mapObject } from "../../../functions/src/utils/map"
import { collections } from "../../../functions/src/models/schemas"
import { parseFields } from "../../services/import"
import { getPreloadedItemValues } from "../../../functions/src/models/relations/relationUtils"
import {
    RadarChunk,
    ImportableCollections,
    ImportableItem,
    PreloadedCollections,
    ImportableCollection,
    ImportData
} from "../../../functions/src/models/importing.types"
import { SelectFilesPayload } from "../store"
import { isCollectionItemInvalid, isOverwrite } from "../../../functions/src/models/itemValidationUtils"

const SIZE_LIMIT = 500_000
const getSize = (o: any) => JSON.stringify(o).length

export const calculateMaxFittingLength = (array: any[], maxSize: number) =>
    array.reduce(
        (acc, element, index) => {
            if (acc.isFull || acc.size + getSize(element) > maxSize) return { ...acc, isFull: true }
            return { length: index + 1, size: acc.size + getSize(element), isFull: false }
        },
        { length: 0, size: 0, isFull: false }
    ).length

type Chopped = OmitStrict<RadarChunk, "radarId" | "type"> & { rest: ImportableCollections }
export const splitCollections = (payload: ImportableCollections, chunkNo: number, size = SIZE_LIMIT): Chopped => {
    const data: ImportableCollections = {}

    keys(collections).forEach(k => {
        const p = payload[k]
        if (isEmpty(p)) return
        const length = calculateMaxFittingLength(p, size - getSize(data))
        data[k] = p.splice(0, length) as any
    })

    return { data, chunkNo, rest: payload }
}
export const isICEmpty = (ic: ImportableCollections): boolean => (isEmpty(ic) ? true : values(ic).every(isEmpty))

export const resetErrorsInCollectionItem = <C extends CName>(c: CollectionItem<C>[]): CollectionItem<C>[] =>
    c.map(item => ({
        ...item,
        fields: Ok(isErr(item.fields) ? item.fields.obj : item.fields.value),
        relations: Ok(isErr(item.relations) ? item.relations.obj : item.relations.value),
        classifiers: Ok(isErr(item.classifiers) ? item.classifiers.obj : item.classifiers.value)
    }))

export const toImportableItem = <C extends CName>(cname: C, item: CollectionItem<C>): ImportableItem<C> | null =>
    isCollectionItemInvalid(item, "import")
        ? null
        : {
              fields: getPreloadedItemValues("fields", item),
              relations: getPreloadedItemValues("relations", item),
              classifiers: getPreloadedItemValues("classifiers", item)
          }

export const parseCollections = async (payload: SelectFilesPayload): Promise<PreloadedCollections> => {
    const parsedCols: PreloadedCollections = await asyncMapObject(payload.recognized!, async (cname, file) => {
        if (!file) return
        const { fields, items } = await parseFields(cname, file!)
        const pc = {
            filename: file!.name,
            isValid: !items.some(r => isErr(r.fields) || isErr(r.relations)),
            fieldsFromFile: fields,
            value: items
        }
        return pc as any
    })
    return attachIdsToPreloadedItems(parsedCols)
}

export const attachIdToCollectionItem = <C extends CName>(cname: C, item: RCollection<C>): RCollection<C> => {
    const idField = collections[cname].idField as keyof RCollection<C>
    if (item[idField]) return item
    const id = generateCollectionItemId(cname)
    item[idField] = id as any
    item.createdTs = new Date().getTime()
    return item
}

// TODO TEST
export const attachIdToPreloadedItem = <C extends CName>(
    cname: C,
    fields: Result<RCollection<C>>
): Result<RCollection<C>, ExtErrors<RCollection<C>>> => {
    const attached = attachIdToCollectionItem(cname, isErr(fields) ? fields.obj : fields.value)
    if (isOk(fields)) return Ok(attached)
    return { ...fields, obj: attached }
}

export const removeTagFromItem = (tagName: string) => <C extends CName>(item: CollectionItem<C>) => {
    const { classifiers } = item
    if (isOk(classifiers)) return item // we cannot remove tag that is correct
    const tags = (classifiers.obj as ItemClassifiersInput).tags
    if (!tags?.includes(tagName)) return item
    return {
        ...item,
        classifiers: { ...classifiers, obj: { ...classifiers.obj, tags: tags.filter(t => t !== tagName) } }
    }
}

export const removeSegmentTagFromItem = (segmentTagName: string) => <C extends CName>(item: CollectionItem<C>) => {
    const { classifiers } = item
    if (isOk(classifiers)) return item // we cannot remove tag that is correct
    const segment_tags = (classifiers.obj as ItemClassifiersInput).segment_tags
    if (!segment_tags?.includes(segmentTagName)) return item
    return {
        ...item,
        classifiers: {
            ...classifiers,
            obj: { ...classifiers.obj, segment_tags: segment_tags.filter(t => t !== segmentTagName) }
        }
    }
}

export const attachIdsToPreloadedItems = (pcs: PreloadedCollections): PreloadedCollections =>
    mapObject(pcs, <C extends CName>(cname: C, pc: PreloadedCollections[C] | undefined): PreloadedCollections[C] => {
        if (!pc) return
        const results = pc.value as CollectionItem<C>[]
        let isValid = pc.isValid
        const value = results.map(({ fields, ...rest }) => {
            const newFields = attachIdToPreloadedItem(cname, fields)
            if (isErr(newFields)) isValid = false
            return { ...rest, fields: newFields }
        })
        return { ...pc, isValid, value }
    })

const toImportableCollection = <C extends CName>(
    cname: C,
    v: PreloadedCollections[C],
    pred: F1<CollectionItem<C>, boolean>
): ImportableCollection<C> => (v!.value as CollectionItem<C>[]).filter(pred).map(i => toImportableItem(cname, i)!)

export const toImportableCollections = (v: PreloadedCollections): ImportData => ({
    create: mapObject(v, (cname, col) => toImportableCollection(cname, col, c => !isOverwrite(c))),
    update: mapObject(v, (cname, col) => toImportableCollection(cname, col, i => isOverwrite(i)))
})
