import * as React from "react"
import { connect, ConnectedComponent } from "react-redux"
import { isFetched } from "../../functions/src/utils/types"
import { MapState, MapDispatch } from "../utils/redux.types"
import { MenuConfig, Menu, MenuItem } from "../components/Menu"
import { isRoot, isLoggedIn } from "../models/LoginStatus"
import { values, keys } from "../../functions/src/utils/map"
import { Icon } from "../../functions/src/models/icons"
import { AuthenticatedState, AppLocationState, AdminLocationState } from "../models/auth"
import { Loader } from "../components/common/Loader"
import { Hr } from "../components/common"
import { actions as uiActions } from "../store/ui/actions"
import { actions as authActions } from "../store/auth/actions"
import { AuthState } from "../store/store"
import { adminPaths } from "../paths"
import {
    _AdminNavigationContainer,
    _AdminNavigationHeading,
    _AdminNavigationTitle,
    _AdminNavigationExpandButton,
    _AdminAddRadar,
    _AdminNavigationContent,
    _NewRadar
} from "./AdminNavigation.styles"
import { capitalize } from "../../functions/src/utils"
import { _VerticalSpace, _HorizontalSpace } from "../styles/common"
import { LinkButton } from "../styles/buttons"
import { matchesPath } from "../utils/router"
import { isValidLocation } from "../models/LocationType"
import { IconSvg } from "../components/IconSvg"

type ActionProps = {
    onEdit: F1<string>
    onRadarAdd: F1<string>
    onRadarClick: F1<RadarId>
    onHubAdd: F0
}
type MergeProps = {
    navigation: MenuConfig
    title: string
    root: boolean
    loading: boolean
    radarSlug?: string
    new: boolean
}

type StateProps = Pick<AuthState, "hubs" | "configs" | "authentication" | "params">

const groupRadarsByHubs = (radars: LocationParams[], hubs?: SMap<ExtHub>): TMap<string, LocationParams[]> => {
    const result = radars.reduce<TMap<string, LocationParams[]>>((acc, r) => {
        if (!acc[r.hubSlug]) acc[r.hubSlug] = []
        acc[r.hubSlug].push(r)
        return acc
    }, {})

    if (hubs)
        values(hubs).forEach(h => {
            if (!result[h.hubSlug]) result[h.hubSlug] = []
        })

    return result
}

const getHubName = (hubSlug: string, radars: LocationParams[], hubs?: SMap<ExtHub>): string =>
    radars[0]?.hubName || (hubs && values(hubs).find(h => h.hubSlug === hubSlug)?.name) || hubSlug

type PrepareNavigationParams = Pick<ActionProps, "onEdit" | "onRadarAdd" | "onRadarClick"> & {
    configs: AsyncFetched<SMap<LocationParams>>
    hubs: Async<SMap<ExtHub>>
    authentication: AuthenticatedState
    params: AppLocationState
}

const prepareNavigation = ({
    authentication,
    configs,
    hubs,
    params,
    onEdit,
    onRadarAdd,
    onRadarClick
}: PrepareNavigationParams): MenuConfig => {
    const lp = params as AdminLocationState
    const currentRadar = lp.locationParams
    const searchHubSlug = new URLSearchParams(lp.search).get("hub")
    const isNewRadar = matchesPath(adminPaths["admin/newRadar"].path, lp.pathname)
    const initialUnfolded = currentRadar.hubSlug ? [currentRadar.hubSlug] : searchHubSlug ? [searchHubSlug] : []
    const arids = ((authentication as unknown) as AuthenticatedState).user.adminRadarIds
    const hs = hubs as AsyncFetched<SMap<ExtHub>> // Works for superadmin only
    const radars = isRoot(authentication)
        ? groupRadarsByHubs(values(configs.value), hs.value)
        : groupRadarsByHubs(values(configs.value).filter(r => arids.includes(r.radarId)))
    const items = keys(radars)
        .map<MenuItem>(hubSlug => {
            // "client" or "demo":
            // in each radars[hubSlug] restriction field
            // radars[hubSlug] is an array
            return {
                label: capitalize(getHubName(hubSlug, radars[hubSlug], hs.value)),
                key: hubSlug,
                items: radars[hubSlug]
                    .map<MenuItem>(r => {
                        let iconName: Icon | undefined
                        if (r.restriction) {
                            switch (r.restriction) {
                                case "client":
                                    iconName = "client"
                                    break
                                case "demo":
                                    iconName = "demo"
                                    break
                                default:
                                    iconName = undefined
                            }
                        }
                        return {
                            iconName,
                            label: capitalize(r.radarName),
                            key: r.radarSlug,
                            onClick: i => onRadarClick(i.key),
                            isSelected: i => i.key === currentRadar.radarSlug,
                            isDisabled: r.isDisabled
                        }
                    })
                    .sort((r1, r2) => (r1.label < r2.label ? -1 : 1))
            }
        })
        .sort((r1, r2) => (r1.label < r2.label ? -1 : 1))
    if (isRoot(authentication)) {
        return {
            meta: {
                actions: [
                    {
                        render: () => <IconSvg name="hub-edit" width={16} height={16} />,
                        onClick: i => onEdit(i.key)
                    }
                ],
                initialUnfolded,
                renderAfter: i => (
                    <>
                        {isNewRadar && i.key === searchHubSlug && <_NewRadar>New Radar</_NewRadar>}
                        <_AdminAddRadar onClick={() => onRadarAdd(i.key)} data-cy="add-radar-button">
                            + Add radar
                        </_AdminAddRadar>
                    </>
                )
            },
            items
        }
    } else {
        return {
            meta: { initialUnfolded },
            items
        }
    }
}

export const Navigation: React.FC<MergeProps & ActionProps> = ({ loading, navigation, title, ...p }) => {
    const [showHubs, setShowHubs] = React.useState(false)
    const [allExpanded, setAllExpanded] = React.useState<boolean | null>(null)

    const filteredNavigation =
        !p.root || (!p.new && !p.radarSlug) || showHubs
            ? navigation
            : {
                  ...navigation,
                  items: navigation.items.filter(i => navigation.meta?.initialUnfolded?.includes(i.key))
              }

    const onExpandToggle = React.useCallback(() => {
        setAllExpanded(!allExpanded)
    }, [allExpanded])

    return (
        <_AdminNavigationContainer>
            <_AdminNavigationContent>
                <_AdminNavigationHeading>
                    <_AdminNavigationTitle>{title as string}</_AdminNavigationTitle>
                    {p.root && (
                        <>
                            <_HorizontalSpace base="12px" />
                            {(p.new || p.radarSlug) && (
                                <IconSvg
                                    {...(showHubs
                                        ? {
                                              name: "eye-on",
                                              width: 16,
                                              height: 16
                                          }
                                        : {
                                              name: "eye-off",
                                              width: 16,
                                              height: 16
                                          })}
                                    onClick={() => setShowHubs(!showHubs)}
                                />
                            )}
                        </>
                    )}
                    {showHubs && (
                        <_AdminNavigationExpandButton role="button" onClick={onExpandToggle}>
                            {`${allExpanded ? "Collapse" : "Expand"} all`}
                        </_AdminNavigationExpandButton>
                    )}
                </_AdminNavigationHeading>
                {loading ? <Loader /> : <Menu config={filteredNavigation} expandAllToggleState={allExpanded} />}
                {p.root && (
                    <>
                        <_VerticalSpace base="16px" />
                        <Hr gutter={8} />
                        <_VerticalSpace base="16px" />
                        <LinkButton data-cy="add-hub" color="info" bold onClick={() => p.onHubAdd()}>
                            + Add Hub
                        </LinkButton>
                    </>
                )}
                <_VerticalSpace base="32px" />
            </_AdminNavigationContent>
        </_AdminNavigationContainer>
    )
}

const mapState: MapState<StateProps> = ({ auth: { hubs, configs, authentication, params } }) => {
    return { hubs, configs, authentication, params }
}

const mapDispatch: MapDispatch<ActionProps> = d => ({
    onEdit: (hubSlug: string) => d(uiActions.openPopup("hubEdit", { hubSlug })),
    onRadarClick: radarSlug =>
        d(authActions.navigate({ path: adminPaths["admin/radarDashboard"].path, slugs: { radarSlug } })),
    onRadarAdd: hubSlug =>
        d(
            authActions.navigate({
                path: adminPaths["admin/newRadar"].path,
                searchParams: `hub=${hubSlug}`
            })
        ),
    onHubAdd: () => d(uiActions.openPopup("hubAdd"))
})

type MapMergeProps = F3<StateProps, ActionProps, unknown, MergeProps>

const mergeProps: MapMergeProps = ({ hubs, configs, authentication, params }, ap) => {
    if (
        !isFetched(configs) ||
        !isLoggedIn(authentication) ||
        (isRoot(authentication) && !isFetched(hubs)) ||
        !isValidLocation(params)
    )
        return {
            loading: true,
            navigation: {
                items: []
            },
            root: false,
            new: false,
            title: ""
        }

    return {
        loading: false,
        navigation: prepareNavigation({ authentication, configs, hubs, params, ...ap }),
        title: isRoot(authentication) ? "Hubs" : "Radars",
        root: isRoot(authentication),
        radarSlug: params.locationParams.radarSlug,
        new: matchesPath(adminPaths["admin/newRadar"].path, params.pathname),
        ...ap
    }
}

export const AdminNavigation: ConnectedComponent<typeof Navigation, unknown> = connect(
    mapState,
    mapDispatch,
    mergeProps
)(Navigation)
