import { kebabCase } from "lodash"

export type ColumnHierarchy = { [index: string]: any[] }

export const createGridLayout = (hierarchy: ColumnHierarchy, alternate?: boolean) => {
    const elements = []
    if (!alternate) elements.push("[startup-name] 2fr")

    for (const key in hierarchy) {
        const len = hierarchy[key].length
        elements.push(`[${kebabCase(key)}] repeat(${len}, 1fr)`)
    }
    elements.push("[end]")
    return elements.join(" ")
}

/**
   The prioritization table needs a minimum width. Otherwise the grid will try to fit
   all columns into view, which may result in an unreadable table.
   This is an *estimation* of how much space we need:
   160px for the startup name
   120px for each column later on.
*/
export const estimateWidth = (hierarchy: ColumnHierarchy, alternate?: boolean): number => {
    const colsWidth =
        Object.values(hierarchy)
            .flatMap(names => names.length)
            .reduce((a, v) => a + v, 0) * 120
    const nameWidth = alternate ? 0 : 160

    return colsWidth + nameWidth
}
