import { getFirebase } from "../services/firebase"
import { Cmd } from "redux-loop"
import {
    UserCloudActions,
    RadarCloudActions,
    AdminCloudActions,
    PublicCloudActions
} from "../../functions/src/actions/actionCreators"
import { AuthService } from "../services/auth"
import { Firestore } from "../../functions/src/services/firebase"
import { getPerf } from "../services/performance"
import { FunctionsService } from "../services/functions"

type FCb<TService, T> = F1<TService, Promise<T>>
export type AuthCmdCb<T> = FCb<AuthService, T>
export type FirestoreCmdCb<T> = FCb<Firestore, T>

const fCmd = <TService>(getService: F0<TService>) => <T>(
    cb: FCb<TService, T>,
    actions: { onSuccess?: F1<T, TypedAction>; onError?: F1<any, TypedAction> } = {}
) =>
    Cmd.run(() => cb(getService()), {
        successActionCreator: actions.onSuccess,
        failActionCreator: actions.onError
    })

export const firestoreCmd = fCmd<Firestore>(() => getFirebase().firestore)
export const httpCmd = fCmd<FunctionsService>(() => getFirebase().functions)

export const authCmd = <T, TService extends AuthService = AuthService>(
    cb: FCb<TService, T>,
    successActionCreator?: F1<T, TypedAction>,
    failActionCreator?: F1<any, TypedAction>
) => Cmd.run(() => cb(getFirebase().auth as any), { successActionCreator, failActionCreator })

export const userCloudActionsCmd = (p: { userId: string; actionId: string }, getAction: F1<string, UserCloudActions>) =>
    firestoreCmd(async firestore => {
        const action = getAction(p.actionId)
        getPerf().startTraceById("CLOUD_ACTION", p.actionId, action.type)
        return firestore.setDoc(firestore.ref("userActions", p.userId, "actions", p.actionId), action)
    })

export const radarCloudActionsCmd = (
    p: { senderId: string; actionId: string },
    getAction: F1<string, RadarCloudActions>
) =>
    firestoreCmd(async firestore => {
        const action = getAction(p.actionId)
        getPerf().startTraceById("CLOUD_ACTION", p.actionId, action.type)
        return firestore.setDoc(firestore.ref("radarActions", p.senderId, "actions", p.actionId), getAction(p.actionId))
    })

export const adminCloudActionsCmd = (
    p: { senderId: string; actionId: string },
    getAction: F1<string, AdminCloudActions>
) =>
    firestoreCmd(async firestore => {
        const action = getAction(p.actionId)
        getPerf().startTraceById("CLOUD_ACTION", p.actionId, action.type)
        return firestore.setDoc(firestore.ref("adminActions", p.senderId, "actions", p.actionId), getAction(p.actionId))
    })

export const publicCloudActionsCmd = (actionId: string, getAction: F1<string, PublicCloudActions>) =>
    firestoreCmd(async firestore => {
        const action = getAction(actionId)
        getPerf().startTraceById("CLOUD_ACTION", actionId, action.type)
        return firestore.setDoc(firestore.ref("publicActions", actionId), getAction(actionId))
    })
