import * as React from "react"
import { toFormState, toResult, validateForm } from "../forms"
import { FormViewProps } from "../../components/form/FormView"
import { isErr } from "../../../functions/src/utils/validators"

export type FormHookProps<T> = {
    initialValue: T
    schema: FormSchema<T>
    onSubmit: F1<T>
}
export type FormHookResult<T> = {
    formViewProps: FormViewProps<T>
    onSubmitClick: F0
    onSubmitClickReset: F0
    result: Result<T, T>
    resetState: (s?: T) => void
    touched: boolean
    isSubmitted: boolean
}

export const useFormHook = <T>({ schema, ...p }: FormHookProps<T>): FormHookResult<T> => {
    const [state, setState] = React.useState(toFormState(schema, p.initialValue))
    const [touched, setTouched] = React.useState(false)
    const [isSubmitted, setIsSubmitted] = React.useState(true)

    const onSubmitClick = () => {
        const res = toResult(schema, state)
        if (isErr(res)) {
            setState(validateForm(schema, state, true))
        } else {
            setIsSubmitted(true)
            p.onSubmit(res.value)
        }
        setTouched(true)
    }

    const resetState = (emptyState?: T) => {
        setTouched(false)
        setIsSubmitted(false)
        setState(toFormState(schema, !emptyState ? p.initialValue : emptyState))
    }

    return {
        onSubmitClick,
        onSubmitClickReset: () => {
            onSubmitClick()
            resetState()
        },
        result: toResult(schema, state),
        formViewProps: {
            state,
            setState: s => {
                setTouched(true)
                setState(s)
            },
            schema
        },
        touched,
        isSubmitted,
        resetState
    }
}
