import {
    validate,
    validNumber,
    validString,
    validNumberDef,
    validArrayStringDef,
    validStringDef
} from "../utils/validators"
import { mapToDisplay, parseString } from "./common"
import { formatDecimal, formatInteger, formatMillions } from "./converters"
import { RFields } from "./RFields"
import {
    DEFAULT_PLACEHOLDER,
    sharedRequiredRadarFieldsValidationMap,
    sharedOptionalRadarFieldsValidationMap,
    sharedVMMetaValidationMap
} from "./shared"
import { TechExpertVM, DisplayModelsMap } from "./ViewModels"

const techExpertRequestFieldsValidationMap: ValidationMap<Required<TechExpertFieldsRequired>> = {
    tech_expert_name: validString,
    ...sharedRequiredRadarFieldsValidationMap
}

const techExpertOptionalFieldsValidationMap: ValidationMap<Required<TechExpertFieldsOptional>> = {
    ...sharedOptionalRadarFieldsValidationMap,
    active_funding: validNumberDef,
    association: validStringDef,
    citations_per_publication: validNumberDef,
    funding_type: validStringDef,
    grant_funding: validNumberDef,
    num_citations: validNumberDef,
    num_patents: validNumberDef,
    num_publications: validNumberDef,
    sector_option: validStringDef,
    tech_expert_url: validStringDef,
    num_clinical_trials: validNumberDef,
    num_patent_families: validNumberDef
}

export const techExpertRelationsValidationMap: ValidationMap<Required<TechExpertRelations>> = {
    startups: validArrayStringDef,
    companies: validArrayStringDef,
    influencers: validArrayStringDef,
    investors: validArrayStringDef,
    patents: validArrayStringDef,
    research_hubs: validArrayStringDef,
    research_papers: validArrayStringDef,
    sectors: validArrayStringDef,
    tech_transfers: validArrayStringDef
}

export const techExpertFieldsValidation: ValidationMap<Required<TechExpertFields>> = {
    ...techExpertRequestFieldsValidationMap,
    ...techExpertOptionalFieldsValidationMap
}
export const techExpertValidationMap: ValidationMap<Required<TechExpert>> = {
    ...techExpertFieldsValidation,
    techExpertId: validString,
    createdTs: validNumber
}

export const techExpertFields: CFields<"tech_experts"> = RFields<TechExpertFieldsRequired, TechExpertFieldsOptional>(
    techExpertRequestFieldsValidationMap,
    techExpertOptionalFieldsValidationMap
)
export const techExpertRelations: RFields<"tech_experts"> = RFields<unknown, TechExpertRelations>(
    {},
    techExpertRelationsValidationMap
)

export const validateTechExpertRelations = validate<TechExpertRelations>(techExpertRelationsValidationMap)
export const validateTechExpertFields = validate<TechExpertFields>(techExpertFieldsValidation)
export const validateTechExpert = validate<TechExpert>(techExpertValidationMap)
export const validateTechExpertVM = validate<TechExpertVM>({
    ...techExpertValidationMap,
    ...sharedVMMetaValidationMap
})
export const transformTechExpert = (c: TechExpertVM): DisplayModelsMap["tech_experts"] => ({
    ...c,
    active_funding: formatMillions(c.active_funding, false),
    grant_funding: formatMillions(c.grant_funding, false),
    num_publications: formatInteger(c.num_publications),
    num_citations: formatInteger(c.num_citations),
    num_patents: formatInteger(c.num_patents),
    citations_per_publication: formatDecimal(c.citations_per_publication),
    description: parseString(c.description)
})

export const displayTechExpert = (r: TechExpertVM, ph: string = DEFAULT_PLACEHOLDER): Displayable<TechExpertVM> =>
    mapToDisplay(r, ph)

export const TechExpertFieldsDisplay: Displayable<TechExpertVM> = {
    tech_expert_name: "Name",
    association: "Association",
    active_funding: "Active funding ($m)",
    citations_per_publication: "Citations per publication",
    description: "Description",
    funding_type: "Funding type",
    grant_funding: "Grant funding ($m)",
    num_citations: "Number of citations",
    num_patents: "Number of patents",
    num_publications: "Number of publications",
    num_clinical_trials: "Number of Clinical Trials",
    num_patent_families: "Number of Patent Families",
    sector_option: "Sector option",
    source: "Source",
    tech_expert_url: "Tech Expert URL",
    blurOnListing: "",
    createdTs: "Created At",
    commentsCount: "No. of comments",
    contactedLeadsCount: "No. of contacted leads",
    techExpertId: "ID",
    filterBounds: ""
}
