import * as React from "react"
import { FormHookProps, useFormHook } from "../../utils/hooks/useFormHook"
import { FormView } from "../form/FormView"
import { _HorizontalSpace, _VerticalSpace } from "../../styles/common"
import { SecondaryButton, Button } from "../../styles/buttons"
import {
    _CommentActions,
    _CommentButton,
    _CommentDetails,
    _CommentAuthor,
    _CommentInfo,
    _CommentText,
    _CommentNewWrapper,
    CommentsFormWrapper,
    _CommentFormActionButtons
} from "./comment.styles"
import { Join } from "../../utils/reactUtils"
import { toRelativeTime } from "../../models/dateTime"
import { RenderHTML } from "../common"
import { Loader } from "../common/Loader"
import { linkify } from "../../utils/linkify"

export type RCommentText = Pick<ObjectComment, "text">

export type CommentFormProps = FormHookProps<RCommentText> & {
    onCancel?: F0
    isSubmitting?: boolean
    mode: "edit" | "create"
}
export const CommentForm: React.FC<CommentFormProps> = p => {
    const { formViewProps, onSubmitClickReset } = useFormHook(p)
    if (p.isSubmitting) return <Loader />
    return (
        <CommentsFormWrapper>
            <FormView {...formViewProps} />
            <_CommentFormActionButtons>
                {p.onCancel ? (
                    <>
                        <SecondaryButton onClick={p.onCancel}>Cancel</SecondaryButton>
                        <_HorizontalSpace base="16px" />
                    </>
                ) : null}
                <Button onClick={onSubmitClickReset}>{p.mode === "edit" ? "Save comment" : "Post comment"}</Button>
            </_CommentFormActionButtons>
        </CommentsFormWrapper>
    )
}

type CommentActionsProps = { onEdit: F0; onDelete: F0; editable: boolean; deletable?: boolean }
export const CommentActions: React.FC<CommentActionsProps> = p => {
    if (!p.editable) return null
    const deletable = p.deletable === undefined ? p.editable : p.deletable
    return (
        <_CommentActions>
            <_CommentButton onClick={p.onEdit} severity="Info">
                Edit
            </_CommentButton>
            {deletable && (
                <_CommentButton onClick={p.onDelete} severity="Warning">
                    Delete
                </_CommentButton>
            )}
        </_CommentActions>
    )
}

export const CommentText: React.FC<{ text: string; tilted?: boolean }> = p => (
    <_CommentText {...p}>
        {p.tilted && '"'}
        <Join items={p.text.split("\n")} renderJoining={() => <br />}>
            {t => <>{linkify(t)}</>}
        </Join>
        {p.tilted && '"'}
    </_CommentText>
)

export const CommentMeta: React.FC<{
    isRead?: boolean
    label?: string
    time: string
    authorName: string
    authorEmail: string
}> = ({ isRead = true, ...p }) => (
    <_CommentDetails>
        {!isRead ? <_CommentNewWrapper>New!</_CommentNewWrapper> : ""}
        {p.label || ""} {p.time} by{" "}
        <_CommentAuthor>
            {p.authorName}
            {p.authorEmail ? `, ${p.authorEmail}` : ""}
        </_CommentAuthor>
    </_CommentDetails>
)

export const CommentPreview: React.FC<{ comment: DisplayComment; isSubmitting: boolean }> = p => {
    if (p.isSubmitting) return <Loader />

    const label = p.comment.updatedTs !== p.comment.createdTs ? "(Edited)" : ""

    return (
        <>
            {p.comment.desc && (
                <>
                    <RenderHTML html={p.comment.desc} />
                    <_VerticalSpace base="4px" />
                </>
            )}
            <CommentText text={p.comment.text} tilted={Boolean(p.comment.desc)} />
            <_VerticalSpace base="8px" />
            <_CommentInfo>
                <CommentMeta
                    label={label}
                    time={toRelativeTime(p.comment.updatedTs) || ""}
                    authorName={p.comment.userName}
                    authorEmail={p.comment.userEmail || "no email"}
                />
                <_HorizontalSpace base="10px" />
                {p.children}
            </_CommentInfo>
        </>
    )
}
