import { cnames } from "../models/schemas"
import { Ok, Err } from "./validators"

const errors = {
    notCName: "Invalid cname"
}

export const isCName = (v: any): v is CName => v && cnames.includes(v)
export const validateCName = (v: any, msg?: string): Result<CName, string> =>
    isCName(v) ? Ok(v) : Err(msg || errors.notCName)

export const validCName = [validateCName]
