import { functions } from "firebase/app"
import { str2ab, ab2str } from "../../functions/src/utils"
import {
    isErr,
    Err,
    validate,
    validStringDef,
    validString,
    validateArray,
    runValidatorsRaw,
    validNumber
} from "../../functions/src/utils/validators"
import { validCName } from "../../functions/src/utils/domainValidators"
import {
    HttpEndpointActions,
    HttpEndpointActionsType,
    HttpEndpointAction,
    HttpEndpointActionResponse,
    HttpEndpointResponse
} from "../../functions/src/services/httpEndpoint/actions"
import { ResponseActionValidationMap, httpCallsErrors } from "../../functions/src/services/httpEndpoint/validators"
import { CollectionSearchResult } from "../../functions/src/models/search"
import { validatePartialViewModels } from "../../functions/src/services/httpEndpoint/data/validators"
import { ExportResponse, ExportFile } from "../../functions/src/services/httpEndpoint/export"
import { ungzip, gzip } from "pako"

export type FunctionsService = {
    callFunctions: <T extends keyof HttpEndpointActionsType, C extends HttpEndpointAction<T>>(
        p: C,
        onError?: F1<string>
    ) => Promise<Result<HttpEndpointActionResponse<C["type"]>, string>>
}

export const init = (instance: functions.Functions): FunctionsService => {
    const httpsCallable = async <T extends keyof HttpEndpointActionsType>(
        data: HttpEndpointActions,
        onError?: F1<string>
    ): Promise<Result<HttpEndpointActionResponse<T>, string>> => {
        // Need to compress data - 10MB limit on Firebase functions...
        const stringifiedRequest = JSON.stringify(data)
        const zip = stringifiedRequest.length >= 9_800_000
        const r = zip ? ab2str(gzip(stringifiedRequest).buffer) : stringifiedRequest

        // For development debug
        // const response = await (
        //     await fetch("http://localhost:8000", {
        //         method: "POST",
        //         body: JSON.stringify({
        //             data: {
        //                 data: r,
        //                 zipped: zip
        //             }
        //         }),
        //         headers: {
        //             "Content-Type": "application/json"
        //         }
        //     })
        // ).json()
        const response = (
            await instance.httpsCallable("onHttpRequestCF", { timeout: 540000 })({
                data: r,
                zipped: zip
            })
        ).data

        if (isErr(response)) {
            if (onError) onError(response.value as string)
            return Err("Response failed from the server")
        }
        const unpackedResponse = JSON.parse(
            response.zipped ? ungzip(new Uint8Array(str2ab(response.data)), { to: "string" }) : response.data
        ) as Result<HttpEndpointResponse<T>>
        const validatedResp = validateResponse(responseValidationMap)(unpackedResponse.value)
        if (isErr(validatedResp)) return Err("Response validation failed")
        return validatedResp
    }

    return {
        callFunctions: httpsCallable
    }
}

const validateSearchResult = validate<CollectionSearchResult["results"][0]>({
    icon: validStringDef,
    name: validString,
    id: validString
})

const validateSearchResults = validate<CollectionSearchResult>({
    cname: validCName,
    results: [validateArray([validateSearchResult])]
})
const validateSearchResponse = validate<HttpEndpointActionResponse<"search">>({
    searchedObjects: [validateArray([validateSearchResults])]
})

const valiateDashboardSummaryResponse = validate<HttpEndpointActionResponse<"dashboardSummary">>({
    sectorsCount: validNumber,
    starredObjectsCount: validNumber,
    startupsCount: validNumber
})

const validateExportFile = validate<ExportFile>({ file: validString, cname: validCName })
const validateExportResponse = validate<ExportResponse>({ data: [validateArray<ExportFile>([validateExportFile])] })

// TODO Add reports validation
export const responseValidationMap: ResponseActionValidationMap<keyof HttpEndpointActionsType> = {
    search: [validateSearchResponse],
    dashboardSummary: [valiateDashboardSummaryResponse],
    importValidatePriorities: [],
    importValidateItems: [],
    pipelineStageVsSearchAreaReport: [],
    pipelineStageVsCollectionReport: [],
    movedAlongThePipelineReport: [],
    archiveRationaleReport: [],
    leadProgressionVsCollectionReport: [],
    leadProgressionVsMonthReport: [],
    data: [validatePartialViewModels],
    export: [validateExportResponse],
    exportContactedLeads: [validateExportResponse],
    devTest: [],
    clearCache: [],
    mergeTags: []
}

export const validateResponse = <T extends keyof HttpEndpointActionsType>(map: ResponseActionValidationMap<T>) => (
    v: any
): Result<HttpEndpointActionResponse<T>> => {
    if (!v.type || `${v.type}`.startsWith("_")) return Err(httpCallsErrors.wrongActionType, v)
    if (isErr(v)) return Err(httpCallsErrors.failedHttpRequest, v)
    const response = validateActionResponse(map, v)
    if (isErr(response)) {
        // eslint-disable-next-line no-console
        console.error(response)
        return Err(httpCallsErrors.invalidResponse, response)
    }
    return response
}
export const validateActionResponse = <T extends keyof HttpEndpointActionsType>(
    map: ResponseActionValidationMap<T>,
    v: any
): Result<HttpEndpointActionResponse<T>> => {
    if (!v || !v.type) return Err(httpCallsErrors.notAnAction)
    const value = v as HttpEndpointAction<T>
    const validators = map[value.type]
    if (!validators) return Err(httpCallsErrors.wrongActionType)
    return runValidatorsRaw(validators, v.payload)
}
