import * as React from "react"
import { connect, useSelector, useDispatch, ConnectedComponent } from "react-redux"
import { dashboardDetailsPaths, getDashboardDetailsPathName } from "../../paths"
import { actions as authActions } from "../../store/auth/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { initialState as uiInitialState } from "../../store/ui"
import { actions as cfActions } from "../../store/cloud/actions"
import { actions as dataActions } from "../../store/data/actions"
import { getCollectionSchema } from "../../../functions/src/models/schemas"
import { Loaded, isFetching, LoadingWithPayload } from "../../../functions/src/utils/types"
import { isRoot } from "../../models/LoginStatus"
import { getCurrentRadarId, getUserRadars, isDemoRadarLocation, getCurrentRadar } from "../../models/LocationType"
import { LoadableView } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import { getDataByDeps } from "../../store/data/dataSelectors"
import { RadarViewFetchMap, getDecoratingFetchMap } from "../../dependencies"
import { RootState } from "../../store/store"
import { RadarLocationState } from "../../models/auth"
import { MapDispatch } from "../../utils/redux.types"
import { ListParams } from "./Lists"
import { NavigationParams } from "../../utils/router.types"
import { ValueCellMeta } from "../../components/table/TableViewCell"
import { BasicList } from "./BasicList"

type StateProps = OmitStrict<ListParams<CName>, "loadingMore"> & {
    getItemPath: F1<string, NavigationParams>
}
type ActionProps = {
    navigate: F1<NavigationParams>
    onCellContentClick: F1<ValueCellMeta<string>>
}

type OwnProps<C extends CName> = {
    cname: C
}

const getListProps = <C extends CName>(s: RootState, { cname }: OwnProps<C>) =>
    getDataByDeps<StateProps>()(
        RadarViewFetchMap({ [cname]: "page", config: "all" } as any, getDecoratingFetchMap("all")),
        (deps, { auth }, _, models) => {
            if (isFetching(models[cname]))
                return LoadingWithPayload({
                    stateDescription: `Fetching imported objects`
                })
            const config = (auth.params as RadarLocationState).locationParams

            const getItemPath = (itemId: string) => ({
                path: dashboardDetailsPaths[getDashboardDetailsPathName(cname)].path,
                slugs: { [getCollectionSchema(cname).idField]: itemId },
                preserveSearchParams: true
            })
            return Loaded({
                cname,
                config,
                radarConfig: deps.config,
                getItemPath,
                deps,
                ui: uiInitialState,
                isRoot: isRoot(auth.authentication)
            })
        }
    )(s, {})

const getListActions: MapDispatch<ActionProps> = d => ({
    navigate: params => d(authActions.navigate(params)),
    onCellContentClick: meta => {
        if (!meta.actions) return
        switch (meta.actions.type) {
            case "star":
                return d(
                    cfActions.mutateDecoratorDebounced({
                        type: "star",
                        objectId: meta.rowId as string,
                        newValue: meta.actions.value
                    })
                )
            case "dropdown":
                if (meta.actions.value.type === "pipelineStage")
                    return d(uiActions.openPopup("mandatoryComment", { type: "pipeline", value: meta.actions.value }))
                return d(cfActions.mutateDecoratorDebounced(meta.actions.value))
        }
    }
})

const LoadableByIdsList = LoadableView(ViewLoader, BasicList)
const ByIdsList: ConnectedComponent<typeof LoadableByIdsList, OwnProps<CName>> = connect(
    getListProps,
    getListActions
)(LoadableByIdsList)

export type ByIdsListData = { cname: CName; ids: ObjectId[] }
type ByIdsListViewProps = { data: ByIdsListData }

export const ByIdsListView = ({ data }: ByIdsListViewProps) => {
    const [startedFetching, setStartedFetching] = React.useState(false)
    const { auth } = useSelector<RootState, Pick<RootState, "auth">>(s => ({
        auth: s.auth
    }))
    const d = useDispatch()
    const radarId = getCurrentRadarId(auth)!

    const radars = getUserRadars(auth)
    const current = getCurrentRadar(auth)
    const isDemo = isDemoRadarLocation(auth.params)

    const currentRadar = current
        ? isDemo
            ? (current as LocationParams)
            : radars.find(r => r.radarId === current.radarId)
        : undefined

    const radarName: string | null = currentRadar?.radarName || null
    const hubName: string | null = currentRadar?.radarName || null

    React.useEffect(() => {
        d(
            dataActions._fetch(radarId, radarName, hubName, { [data.cname]: "ids" } as { [x in CName]: "ids" }, {
                type: "ids",
                value: { [data.cname]: data.ids }
            })
        )
        setStartedFetching(true)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return startedFetching ? <ByIdsList cname={data.cname} /> : null
}
