/* eslint-disable max-lines */
import * as React from "react"
import { connect, DispatchProp } from "react-redux"
import { _TagFont, _BodyFont } from "../styles/typography"
import { _VerticalSpace, Flex, _Spacer, _HorizontalSpace } from "../styles/common"
import { Join, LoadableView, MultilineDescription } from "../utils/reactUtils"
import { SubpageLayout, SubHeader } from "../components/Page"
import { values, toMap, identity, intersect } from "../../functions/src/utils/map"
import { focusWithoutScrolling, scrollToY } from "../utils/html5"
import {
    StyledLeftPanelItem,
    _LeftPanel,
    _LeftPanelContent,
    StyledSeparator,
    StyledDasboardLayout,
    _DashboardWrapper
} from "./DashboardView.styles"
import { SmallButton } from "../styles/buttons"
import { Modal } from "../components/Modal"
import { actions as uiActions } from "../store/ui/actions"
import { actions as cfActions } from "../store/cloud/actions"
import { actions as authActions } from "../store/auth/actions"
import { ValueCellMeta } from "../components/table/TableViewCell"
import { Loaded } from "../../functions/src/utils/types"
import { isRoot } from "../models/LoginStatus"
import { getCopy } from "../../functions/src/services/copy"
import { downloadFile, mapRawAttachment } from "../models/attachments"
import { AttachmentsTable } from "../components/AttachmentTable"
import { ViewLoader } from "../containers/ViewRenderer"
import { DashboardTableView, getDashboardTableId } from "./DashboardTable"
import { isEmpty } from "../../functions/src/utils/validators"
import { getCollectionSchema } from "../../functions/src/models/schemas"
import { getDataByPath } from "../store/data/dataSelectors"
import { ViewModelsBase } from "../../functions/src/models/ViewModels"
import { UIState } from "../store/store"
import { RadarLocationState } from "../models/auth"
import { getUserRadars, isDemoRadarLocation, getCurrentRadar } from "../models/LocationType"
import { SortingParams, SortingKey } from "../../functions/src/models/sorting"
import { NavigationParams } from "../utils/router.types"
import { MapDispatch } from "../utils/redux.types"
import { IntersectionLoader } from "../containers/IntersectionLoader"
import { DashboardSummary } from "../components/dashboard/DashboardSummary"
import { getAvailableCollections } from "../../functions/src/models/collections"
import { IconSvg } from "../components/IconSvg"
import { FiltersContainer } from "../containers/Filters"
import { FilterType } from "../../functions/src/models/filtering"
import { track, identify } from "../tracking"

type StateProps = Pick<ViewModelsBase, "users"> & {
    isDemo: boolean
    current?: LocationParams
    radarId: string
    encryptionKey: EncryptionKey
    flags: ConfigFeatureFlags
    isRoot: boolean
    searchAreas: SearchArea[]
    ui: UIState
    availableCollections: CName[]
    sectionItems: Section[]
}

type ActionProps = {
    navigate: F1<NavigationParams>
    onSort: F3<CName, string, boolean>
    decorateItem: F1<ValueCellMeta<string>>
}

type Props = StateProps & ActionProps
type Section = { title: string; items: (keyof RadarCollections)[] }

const renderLeftPanelItem = (anchor: HTMLElement | null, cname?: CName) => {
    if (!cname) return null
    return (
        <StyledLeftPanelItem
            onClick={() => scrollToY(document.getElementById(getDashboardTableId(cname))?.offsetTop || 0, anchor)}>
            {getCollectionTitle(cname)}
        </StyledLeftPanelItem>
    )
}

type SummaryModalProps = Pick<StateProps, "isRoot" | "ui" | "searchAreas"> &
    Pick<ViewModelsBase, "users"> & {
        isOpen: boolean
        radarId: string
        encryptionKey: EncryptionKey
        onClose: F0
    }
const SummaryModal: React.FC<SummaryModalProps> = ({ searchAreas, ui, ...p }) => {
    const summary = searchAreas.find(sA => sA.name === ui.filters.searchArea)
    if (!summary) return null

    const onDownload = (path: string) => {
        const filename = (summary.attachments || []).find(a => a.path === path)?.filename || "unknown"
        return downloadFile(filename, p.encryptionKey, s => s.ref("attachments", p.radarId, "executiveSummaries", path))
    }

    return (
        <Modal title={`EXECUTIVE SUMMARY - ${ui.filters.searchArea}`} {...p} full>
            <MultilineDescription value={summary.summary} />
            <_VerticalSpace base="16px" />
            {!p.isRoot && [
                <_BodyFont key="att-title" s17 bold>
                    Attached files
                </_BodyFont>,
                <_VerticalSpace key="att-title-spacer" base="16px" />,

                <AttachmentsTable
                    key="att-table"
                    deleteOptions={{ type: "none" }}
                    onDownload={onDownload}
                    mapToDisplay={mapRawAttachment({ users: p.users })}
                    attachments={toMap(summary.attachments || [], a => a.path, identity)}
                />,
                <_VerticalSpace key="att-table-spacer" base="64px" />
            ]}
        </Modal>
    )
}

const isSectionRenderable = (s: Section, collections: CName[]) => !isEmpty(intersect(s.items, collections))
const getCollectionTitle = (cname: CName) => `Top ${getCollectionSchema(cname).displayName}`

const Dashboard: React.FC<Props & DispatchProp> = p => {
    const [showSummary, setShowSummary] = React.useState(false)
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null)
    const searchAreaFilterSelected = !!p.ui.filters.searchArea
    const filters: FilterType[] = ["searchArea"]
    if (p.flags.withSegmentTags) filters.push("segmentTags")
    filters.push("tags")
    if (p.flags.withStars) filters.push("stars")
    if (!p.isRoot) filters.push("pipelines")
    if (p.flags.withPriorityRanks) filters.push("priorities")
    filters.push("date")

    React.useEffect(() => {
        if (anchor) focusWithoutScrolling(anchor)
    }, [anchor])

    React.useEffect(() => {
        if (p.current) {
            identify(p.current.radarName, p.current.hubName)
            track("dashboard", "view", { demo: p.isDemo, root: p.isRoot, filters: filters })
        }
    })

    return (
        <>
            <FiltersContainer withClear resetOnPickTypes={["searchArea"]} filters={filters}>
                <_Spacer />
                {searchAreaFilterSelected && (
                    <Flex>
                        <SmallButton height={34} onClick={() => setShowSummary(true)}>
                            <IconSvg name="summary" width={24} height={24} />
                            <_HorizontalSpace base="12px" />
                            Summary
                        </SmallButton>
                    </Flex>
                )}
            </FiltersContainer>
            <SummaryModal isOpen={showSummary} onClose={() => setShowSummary(false)} {...p} />
            <StyledDasboardLayout>
                <_LeftPanel>
                    <_LeftPanelContent lowered={p.isDemo || p.isRoot}>
                        <_TagFont>go to section</_TagFont>
                        <_VerticalSpace base="21px" />
                        <Join items={p.sectionItems} renderJoining={() => <_VerticalSpace base="21px" />}>
                            {section =>
                                isSectionRenderable(section, p.availableCollections) ? (
                                    <>
                                        <_BodyFont s14 bold>
                                            {section.title}
                                        </_BodyFont>
                                        <StyledSeparator />
                                        <Join
                                            items={section.items.filter(i => p.availableCollections.includes(i))}
                                            renderJoining={() => <_VerticalSpace base="12px" />}>
                                            {cname => renderLeftPanelItem(anchor, cname)}
                                        </Join>
                                    </>
                                ) : null
                            }
                        </Join>
                    </_LeftPanelContent>
                </_LeftPanel>
                <SubpageLayout wide={true}>
                    <_DashboardWrapper ref={setAnchor}>
                        <DashboardSummary />
                        <Join items={p.sectionItems} renderJoining={() => <_VerticalSpace base="56px" />}>
                            {section =>
                                isSectionRenderable(section, p.availableCollections) ? (
                                    <>
                                        <SubHeader title={section.title} />
                                        <Join
                                            items={section.items.filter(i => p.availableCollections.includes(i))}
                                            renderJoining={() => <_VerticalSpace base="56px" />}>
                                            {cname =>
                                                p.availableCollections.includes(cname) ? (
                                                    <div id={getDashboardTableId(cname)}>
                                                        <IntersectionLoader height={800}>
                                                            <DashboardTableView
                                                                title={getCollectionTitle(cname)}
                                                                cname={cname}
                                                            />
                                                        </IntersectionLoader>
                                                    </div>
                                                ) : null
                                            }
                                        </Join>
                                    </>
                                ) : null
                            }
                        </Join>
                        <_VerticalSpace base="30px" />
                    </_DashboardWrapper>
                </SubpageLayout>
            </StyledDasboardLayout>
        </>
    )
}

const mapState = getDataByPath<StateProps>()("radar/dashboard", (deps, state) => {
    const sectionItems: Section[] = [
        {
            title: getCopy("leads"),
            items: [
                "startups",
                "sectors",
                "companies",
                "patents",
                "tech_transfers",
                "clinical_trials",
                "research_papers",
                "grants"
            ]
        },
        {
            title: getCopy("network"),
            items: ["investors", "research_hubs", "tech_experts", "influencers", "patent_holders"]
        }
    ]

    const radars = getUserRadars(state.auth)
    const current = getCurrentRadar(state.auth)
    const isDemo = isDemoRadarLocation(state.auth.params)

    const availableCollections = getAvailableCollections((state.auth.params as RadarLocationState).locationParams)

    return Loaded({
        isDemo: deps.radar.demo,
        radarId: deps.radar.radarId,
        current: current
            ? isDemo
                ? (current as LocationParams)
                : radars.find(r => r.radarId === current.radarId)
            : undefined,
        isRoot: isRoot(state.auth.authentication),
        flags: (state.auth.params as RadarLocationState).locationParams,
        availableCollections,
        searchAreas: values(deps.searchAreas),
        encryptionKey: deps.secureKeys.key,
        ui: state.ui,
        sectionItems,
        users: deps.users
    })
})

const mapDispatch: MapDispatch<ActionProps> = d => ({
    navigate: params => d(authActions.navigate(params)),
    onSort: (cname, key, asc) => {
        const params: SortingParams<typeof cname>[] = [
            {
                sortingAsc: asc,
                sortingKey: key as SortingKey<typeof cname>
            }
        ]
        d(uiActions.updateSortingKey(cname, params))
    },
    decorateItem: meta => {
        if (!meta.actions) return
        switch (meta.actions.type) {
            case "star":
                return d(
                    cfActions.mutateDecoratorDebounced({
                        type: "star",
                        objectId: meta.rowId as string,
                        newValue: meta.actions.value
                    })
                )
            case "dropdown":
                if (meta.actions!.value.type === "pipelineStage")
                    return d(uiActions.openPopup("mandatoryComment", { type: "pipeline", value: meta.actions.value }))
                return d(cfActions.mutateDecoratorDebounced(meta.actions.value))
        }
    }
})

export const DashboardView = connect(mapState, mapDispatch)(LoadableView(ViewLoader, Dashboard))
