import React from "react"
import { SidebarContainer, SidebarHeader, ToggleSidebar, ToggleSidebarButton, ScrollableArea } from "./Sidebar.styles"
import * as Typography from "../styles/typography"
import { _Copy } from "./common/Copy"
import { IconSvg } from "./IconSvg"
import { _AbsPosContainer, _AbsPosElement } from "../styles/common"

export const Sidebar: React.FC = ({ children }) => {
    const [isOpen, changeVisibility] = React.useState<boolean>(true)

    const triggerVisibility = () => {
        changeVisibility(state => !state)
    }

    return (
        <SidebarContainer open={isOpen}>
            {isOpen && (
                <>
                    <SidebarHeader>
                        <Typography._TagFont>
                            filter by
                            <br />
                            <_Copy name="searchAreas" />
                        </Typography._TagFont>
                    </SidebarHeader>
                    <_AbsPosContainer>
                        <_AbsPosElement>
                            <ScrollableArea>{children}</ScrollableArea>
                        </_AbsPosElement>
                    </_AbsPosContainer>
                </>
            )}
            <ToggleSidebar>
                <ToggleSidebarButton twisted={!isOpen} onClick={triggerVisibility}>
                    <IconSvg name="arrow-circle-left" width={26} height={26} />
                </ToggleSidebarButton>
            </ToggleSidebar>
        </SidebarContainer>
    )
}
