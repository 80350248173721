import * as React from "react"
import { _VerticalSpace, _HorizontalSpace } from "../../styles/common"
import { _FormViewWrapper } from "../../styles/forms"
import { Stepper, StepperProps } from "../../components/common/Stepper"
import { StatusBarProps, StatusBar } from "../../components/common/StatusBar"
import { Progress } from "../../components/common/Progress"
import { InfoPanel, InfoPanelProps } from "../../components/importing/InfoPanel"
import { Modal } from "../../components/Modal"
import { IconListProps, IconList, InlineIconListItem, InlineIconList } from "../../components/importing/IconList"
import { DNDButton } from "../../components/common/Buttons"
import { _OutlinedPill } from "../../components/common"
import { Chip } from "../../components/common/Chips"
import { Loader } from "../../components/common/Loader"
import { _noop } from "../../../functions/src/utils"

export const DemoView = () => {
    const [currentStep, setCurrentStep] = React.useState(0)
    const [infoModalOpen, setInfoModalOpen] = React.useState(false)
    const [showSpinner, setShowSpinner] = React.useState(false)

    const steps: StepperProps["steps"] = [
        { stepNumber: 0, title: "FooFoo" },
        { stepNumber: 1, title: "BarBar" },
        { stepNumber: 2, title: "Baz" },
        { stepNumber: 3, title: "FooBar" }
    ]

    const statusBarProps: StatusBarProps = {
        onPreviousClick: () => setCurrentStep(currentStep - 1),
        onNextClick: () => setCurrentStep(currentStep + 1),
        stepDescription: `Step ${currentStep + 1}`
    }

    const infoPanelProps: InfoPanelProps = {
        title: "How to create files to import?",
        description: `Be sure to upload the correct files for each collection.
        If you have any doubts how to create a correct file go to our documentation below.`,
        icon: { name: "question-mark-white", width: 20, height: 20 },
        onLinkClick: () => setInfoModalOpen(true),
        linkDescription: "How to create files to import?"
    }

    const recognizedListProps: IconListProps = {
        title: "Recognized Files",
        items: [
            { header: "Sectors", subHeader: "sectors.csv", icon: { name: "tick", width: 30, height: 30 } },
            { header: "Tech Transfers", subHeader: "techTransfers.csv", icon: { name: "tick", width: 30, height: 30 } },
            { header: "Research Hubs", subHeader: "researchHubs.csv", icon: { name: "tick", width: 30, height: 30 } },
            { header: "Patents", subHeader: "patents.csv", icon: { name: "tick", width: 30, height: 30 } }
        ]
    }
    const unrecognizedListProps: IconListProps = {
        title: "Unrecognized Files",
        items: [
            { header: "", subHeader: "sectors.csv", icon: { name: "cross", width: 30, height: 30 } },
            { header: "", subHeader: "researchHubs.csv", icon: { name: "cross", width: 30, height: 30 } },
            { header: "", subHeader: "patents.csv", icon: { name: "cross", width: 30, height: 30 } }
        ]
    }
    const inlineIconListItems: InlineIconListItem[] = [
        { title: "25 Correct", icon: { name: "selected", width: 15, height: 15 } },
        { title: "15 Duplicated", icon: { name: "duplicated", width: 15, height: 15 } },
        { title: "13 Wrong", icon: { name: "wrong", width: 15, height: 15 } }
    ]

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ width: 300, height: 200, border: "2px solid black", zIndex: 300 }}>
                <button
                    style={{ width: "200px", zIndex: 200, marginTop: "20px" }}
                    onClick={() => setShowSpinner(show => !show)}>
                    {showSpinner ? "Hide" : "Show"} spinner
                </button>
            </div>

            <Loader loadingText="Data and Other things" loadingSubtext="1/5" show={showSpinner} fill />
            <Loader />
            <Loader loadingText="Processing request" />
            <Loader size="small" />

            <_VerticalSpace base="32px" />
            <_FormViewWrapper>
                <h1>Drag'n'Drop button</h1>
                <DNDButton onDrop={_noop} onInfoClick={() => setInfoModalOpen(true)} />
                <h1>Stepper</h1>
                <Stepper steps={steps} currentStepNumber={currentStep} availableSteps={[1, 3]} onStepClicked={_noop} />
                <StatusBar {...statusBarProps} />

                <h1>Progress Bar</h1>
                <Progress current={25} total={100} title="Uploading" />

                <h1>Chips</h1>
                <Chip onClick={_noop} onClose={_noop}>
                    text
                </Chip>
                <_OutlinedPill color="danger">text</_OutlinedPill>

                <h1>InfoPanel</h1>
                <InfoPanel {...infoPanelProps} />

                <h1>File List</h1>
                <div style={{ display: "flex" }}>
                    <IconList {...recognizedListProps} />
                    <_HorizontalSpace base="8px" />
                    <IconList {...unrecognizedListProps} />
                </div>

                <h1>Statistics</h1>
                <InlineIconList title="upload statistics" items={inlineIconListItems} />
                <_VerticalSpace base="100px" />
                <_VerticalSpace base="100px" />
            </_FormViewWrapper>
            <Modal title="Creating import files" isOpen={infoModalOpen} onClose={() => setInfoModalOpen(false)} />
        </div>
    )
}
