import React from "react"
import { VariableSizeList, ListChildComponentProps } from "react-window"
import AutoSizer from "react-virtualized-auto-sizer"
import InfiniteLoader from "react-window-infinite-loader"
import { TABLE_HEADER_HEIGHT } from "../table/TableView"

type VirtualizedTableProps<T extends { cells: any[] }> = {
    rows: T[]
    renderEmpty: F0<React.ReactElement>
    onRowsRendered?: F1<{ startIndex: number; stopIndex: number }>
    rowRender: F1<ListChildComponentProps, React.ReactElement<any, any> | null>
    rowHeight: number
    headerElement?: React.ReactNode
    pinnedRows?: number
    itemsCount: number
    containerRef?: React.Ref<HTMLElement>
}

export const VirtualizedTable = <T extends { cells: any[] }>({ rowRender, ...p }: VirtualizedTableProps<T>) => {
    const utilityRowsCount = (p.headerElement ? 1 : 0) + (p.pinnedRows || 0)

    const innerElementType = React.useMemo(
        () =>
            p.headerElement
                ? React.forwardRef<HTMLElement, any>(({ children, ...rest }, ref) => {
                      return (
                          <div
                              {...rest}
                              ref={r => {
                                  if (typeof ref === "function") ref(r)
                                  else if (ref) ref.current = r
                              }}
                              style={{ ...rest.style, outline: "none" }}>
                              {p.headerElement}
                              {children}
                          </div>
                      )
                  })
                : undefined,
        [p.headerElement]
    )
    const renderRow = React.useCallback(
        (lccp: ListChildComponentProps) =>
            lccp.index < utilityRowsCount ? null : rowRender({ ...lccp, index: lccp.index - utilityRowsCount }),
        [rowRender, utilityRowsCount]
    )

    return p.rows.length ? (
        <AutoSizer>
            {({ width, height }) => (
                <InfiniteLoader
                    isItemLoaded={i => i < p.rows.length + utilityRowsCount}
                    itemCount={p.itemsCount}
                    loadMoreItems={async (startIndex, stopIndex) => p.onRowsRendered?.({ startIndex, stopIndex })}>
                    {({ onItemsRendered, ref }) => {
                        return (
                            <VariableSizeList
                                ref={ref}
                                outerRef={p.containerRef}
                                height={height}
                                width={width}
                                innerElementType={innerElementType}
                                itemSize={i => (i < utilityRowsCount ? TABLE_HEADER_HEIGHT : p.rowHeight)}
                                overscanCount={10}
                                itemCount={p.rows.length + utilityRowsCount}
                                estimatedItemSize={p.rowHeight}
                                onItemsRendered={onItemsRendered}
                                className="virtualized-table">
                                {renderRow}
                            </VariableSizeList>
                        )
                    }}
                </InfiniteLoader>
            )}
        </AutoSizer>
    ) : (
        <>
            {p.headerElement}
            {p.renderEmpty()}
        </>
    )
}
