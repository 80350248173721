import * as React from "react"
import * as ReactDOM from "react-dom"
import ScrollLock from "react-scrolllock"

import { selectCreateDiv } from "../../utils/html5"
import * as Styles from "./ModalContainer.styles"
import { usePrevious } from "../../utils/hooks/usePrevious"

type ModalWrapperProps = {
    isOpened: boolean
    noKeysSupport?: boolean
    onClose?: F0
}

const ModalWrapper: React.FC<ModalWrapperProps> = ({ noKeysSupport = false, isOpened, onClose, children }) => {
    const previousIsOpened = usePrevious(isOpened)

    const handleKey = React.useCallback(
        (e: KeyboardEvent) => {
            if (e.which === 27 && isOpened && onClose) {
                onClose()
                window.removeEventListener("keyup", handleKey, false)
            }
        },
        [onClose, isOpened]
    )

    React.useEffect(() => {
        if (!noKeysSupport && isOpened && !previousIsOpened) window.addEventListener("keyup", handleKey, false)
        if (!noKeysSupport && !isOpened && previousIsOpened) window.removeEventListener("keyup", handleKey, false)
    }, [isOpened, previousIsOpened, handleKey, noKeysSupport])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => () => window.removeEventListener("keyup", handleKey, false), [])

    if (!isOpened) return null
    return ReactDOM.createPortal(children, selectCreateDiv("presentation"))
}

type ModalProps = {
    isOpened: boolean
    onOverlayClick?: F0
    onClose?: F0
    overlayBg?: string
    buttons?: F0<JSX.Element>
    withMargin?: number
}

export const ModalContainer: React.SFC<ModalProps> = ({ isOpened, onClose, onOverlayClick, children, overlayBg }) => (
    <ModalWrapper isOpened={isOpened} onClose={onClose}>
        <ScrollLock isActive={isOpened}>
            <Styles.ModalOverlay onClick={onOverlayClick} background={overlayBg}>
                <Styles.ModalContent
                    data-cy="modal-content"
                    onClick={e => {
                        e.stopPropagation()
                        e.nativeEvent.stopImmediatePropagation()
                    }}>
                    {children}
                </Styles.ModalContent>
            </Styles.ModalOverlay>
        </ScrollLock>
    </ModalWrapper>
)
