import styled from "styled-components"
import {
    _DropdownContainer,
    _DropdownOptions,
    _DropdownTrigger,
    _DropdownWrapper
} from "../../components/Dropdown.styles"
import { _SelectContainer } from "../../components/form/Input.styles"
import { _FlexColumn } from "../../styles/common"

export const _SegmentTagEditorFormContainer = styled(_FlexColumn)`
    width: 100%;

    ${_DropdownTrigger} {
        min-width: 280px;
        max-width: 480px;
        width: 100%;
    }

    ${_DropdownWrapper} {
        position: absolute;
        justify-content: stretch;
        width: calc(100% - 60px);
    }

    ${_DropdownOptions} {
        width: 100%;
        max-width: 480px;
        max-height: 300px;
    }

    ${_DropdownContainer} {
        height: 36px;
    }
`

export const _SegmentTagCreatorFormContainer = styled(_FlexColumn)`
    width: 100%;

    ${_SelectContainer} {
        height: 36px;

        & > * {
            position: absolute;
            width: 100%;
            max-width: 480px;
            min-width: 280px;
        }
    }
`
