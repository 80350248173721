/* eslint-disable max-lines */
import {
    validateStartup,
    displayStartup,
    transformStartup,
    validateStartupFields,
    StartupFieldsDisplay,
    startupFieldsValidation,
    validateStartupRelations,
    validateStartupVM
} from "./Startup"
import {
    validateInfluencer,
    displayInfluencer,
    validateInfluencerFields,
    InfluencerFieldsDisplay,
    transformInfluencer,
    influencerFieldsValidation,
    validateInfluencerRelations,
    validateInfluencerVM
} from "./Influencer"
import {
    validateInvestor,
    displayInvestor,
    transformInvestor,
    validateInvestorFields,
    InvestorFieldsDisplay,
    investorFieldsValidation,
    validateInvestorRelations,
    validateInvestorVM
} from "./Investor"
import {
    validatePatent,
    displayPatent,
    validatePatentFields,
    PatentFieldsDisplay,
    patentFieldsValidation,
    validatePatentRelations,
    validatePatentVM
} from "./Patent"
import {
    validateSector,
    displaySector,
    transformSector,
    validateSectorFields,
    SectorFieldsDisplay,
    sectorFieldsValidation,
    validateSectorRelations,
    validateSectorVM
} from "./Sector"
import {
    validateResearchHub,
    displayResearchHub,
    validateResearchHubFields,
    ResearchHubDisplayFields,
    transformResearchHub,
    researchHubFieldsValidation,
    validateResearchHubRelations,
    validateResearchHubVM
} from "./ResearchHub"
import {
    validateResearchPaper,
    displayResearchPaper,
    validateResearchPaperFields,
    ResearchPaperFieldsDisplay,
    researchPaperFieldsValidation,
    validateResearchPaperRelations,
    validateResearchPaperVM
} from "./ResearchPaper"
import {
    validateTechExpert,
    displayTechExpert,
    validateTechExpertFields,
    TechExpertFieldsDisplay,
    transformTechExpert,
    techExpertFieldsValidation,
    validateTechExpertRelations,
    validateTechExpertVM
} from "./TechExpert"
import {
    validateTechTransfer,
    displayTechTransfer,
    validateTechTransferFields,
    TechTransferFieldsDisplay,
    techTransferFieldsValidation,
    validateTechTransferRelations,
    validateTechTransferVM
} from "./TechTransfer"
import {
    StartupFixture,
    SectorFixture,
    PatentFixture,
    TechTransferFixture,
    InvestorFixture,
    TechExpertFixture,
    ResearchHubFixture,
    InfluencerFixture,
    ResearchPaperFixture,
    StartupRelationsFixture,
    SectorRelationsFixture,
    PatentRelationsFixture,
    TechTransferRelationsFixture,
    InvestorRelationsFixture,
    TechExpertRelationsFixture,
    ResearchHubRelationsFixture,
    InfluencerRelationsFixture,
    ResearchPaperRelationsFixture,
    PatentHolderFixture,
    PatentHolderRelationsFixture,
    GrantFixture,
    GrantRelationsFixture,
    ClinicalTrialFixture,
    ClinicalTrialRelationsFixture,
    CompanyRelationsFixture,
    CompanyFixture
} from "./collectionFixtures"
import { parseObjectFields } from "./common"
import {
    patentHolderFieldsValidationMap,
    validatePatentHolder,
    validatePatentHolderFields,
    displayPatentHolder,
    PatentHolderFieldsDisplay,
    validatePatentHolderRelations,
    validatePatentHolderVM
} from "./PatentHolder"
import {
    grantFieldsValidationMap,
    validateGrant,
    validateGrantFields,
    validateGrantRelations,
    GrantFieldsDisplay,
    transformGrant,
    displayGrant,
    validateGrantVM
} from "./Grant"
import {
    ClinicalTrialFieldsDisplay,
    clinicalTrialFieldsValidationMap,
    validateClinicalTrial,
    validateClinicalTrialFields,
    displayClinicalTrial,
    validateClinicalTrialRelations,
    validateClinicalTrialVM
} from "./ClinicalTrial"
import {
    CompanyFieldsDisplay,
    displayCompany,
    validateCompanyRelations,
    validateCompanyFields,
    validateCompany,
    companyFieldsValidation,
    validateCompanyVM
} from "./Company"
import { identity } from "../utils/map"
import { ViewModelsMap, DisplayModelsMap } from "./ViewModels"
import { getCopy } from "../services/copy"
import { v4 } from "uuid"
import { cnames } from "./schemas"

export const collectionsValidationMaps: { [C in CName]: ValidationMap<RCollectionFields<C>> } = {
    startups: startupFieldsValidation,
    sectors: sectorFieldsValidation,
    patents: patentFieldsValidation,
    tech_transfers: techTransferFieldsValidation,
    investors: investorFieldsValidation,
    tech_experts: techExpertFieldsValidation,
    research_hubs: researchHubFieldsValidation,
    influencers: influencerFieldsValidation,
    research_papers: researchPaperFieldsValidation,
    patent_holders: patentHolderFieldsValidationMap,
    grants: grantFieldsValidationMap,
    clinical_trials: clinicalTrialFieldsValidationMap,
    companies: companyFieldsValidation
}
export const collectionsValidators: { [C in CName]: Validator<RCollection<C>> } = {
    startups: validateStartup,
    sectors: validateSector,
    patents: validatePatent,
    tech_transfers: validateTechTransfer,
    investors: validateInvestor,
    tech_experts: validateTechExpert,
    research_hubs: validateResearchHub,
    influencers: validateInfluencer,
    research_papers: validateResearchPaper,
    patent_holders: validatePatentHolder,
    grants: validateGrant,
    clinical_trials: validateClinicalTrial,
    companies: validateCompany
}

export const collectionsVMValidators: { [C in CName]: Validator<ViewModelsMap[C]> } = {
    startups: validateStartupVM,
    sectors: validateSectorVM,
    patents: validatePatentVM,
    tech_transfers: validateTechTransferVM,
    investors: validateInvestorVM,
    tech_experts: validateTechExpertVM,
    research_hubs: validateResearchHubVM,
    influencers: validateInfluencerVM,
    research_papers: validateResearchPaperVM,
    patent_holders: validatePatentHolderVM,
    grants: validateGrantVM,
    clinical_trials: validateClinicalTrialVM,
    companies: validateCompanyVM
}

export const collectionsFieldsValidators: { [C in CName]: Validator<RCollectionFields<C>> } = {
    startups: validateStartupFields,
    sectors: validateSectorFields,
    patents: validatePatentFields,
    tech_transfers: validateTechTransferFields,
    investors: validateInvestorFields,
    tech_experts: validateTechExpertFields,
    research_hubs: validateResearchHubFields,
    influencers: validateInfluencerFields,
    research_papers: validateResearchPaperFields,
    patent_holders: validatePatentHolderFields,
    grants: validateGrantFields,
    clinical_trials: validateClinicalTrialFields,
    companies: validateCompanyFields
}

export const collectionsRelationsValidators: { [C in CName]: Validator<RCollectionRelations<C>> } = {
    startups: validateStartupRelations,
    sectors: validateSectorRelations,
    patents: validatePatentRelations,
    tech_transfers: validateTechTransferRelations,
    investors: validateInvestorRelations,
    tech_experts: validateTechExpertRelations,
    research_hubs: validateResearchHubRelations,
    influencers: validateInfluencerRelations,
    research_papers: validateResearchPaperRelations,
    patent_holders: validatePatentHolderRelations,
    grants: validateGrantRelations,
    clinical_trials: validateClinicalTrialRelations,
    companies: validateCompanyRelations
}
export const collectionFixtures: {
    [C in CName]: (f?: Partial<RCollection<C>>) => RCollection<C>
} = {
    startups: StartupFixture,
    sectors: SectorFixture,
    patents: PatentFixture,
    tech_transfers: TechTransferFixture,
    investors: InvestorFixture,
    tech_experts: TechExpertFixture,
    research_hubs: ResearchHubFixture,
    influencers: InfluencerFixture,
    research_papers: ResearchPaperFixture,
    patent_holders: PatentHolderFixture,
    grants: GrantFixture,
    clinical_trials: ClinicalTrialFixture,
    companies: CompanyFixture
}

export const collectionsExportConverters: { [C in CName]: F1<ViewModelsMap[C], Displayable<ViewModelsMap[C]>> } = {
    startups: displayStartup,
    sectors: displaySector,
    patents: displayPatent,
    tech_transfers: displayTechTransfer,
    investors: displayInvestor,
    tech_experts: displayTechExpert,
    research_hubs: displayResearchHub,
    influencers: displayInfluencer,
    research_papers: displayResearchPaper,
    patent_holders: displayPatentHolder,
    grants: displayGrant,
    clinical_trials: displayClinicalTrial,
    companies: displayCompany
}
export const getExportConverters = <C extends CName>(c: C): F1<ViewModelsMap[C], Displayable<ViewModelsMap[C]>> =>
    collectionsExportConverters[c] as any

export const collectionsVMConverters: { [C in CName]: F1<ViewModelsMap[C], DisplayModelsMap[C]> } = {
    sectors: transformSector,
    startups: transformStartup,
    investors: transformInvestor,
    influencers: transformInfluencer,
    research_hubs: transformResearchHub,
    tech_experts: transformTechExpert,
    tech_transfers: identity,
    patents: identity,
    research_papers: identity,
    patent_holders: identity,
    grants: transformGrant,
    clinical_trials: identity,
    companies: identity
}

export const collectionsParsers: { [C in CName]: F1<SMap<any>, Result<RCollection<C>>> } = {
    startups: parseObjectFields("startups", StartupFixture()),
    sectors: parseObjectFields("sectors", SectorFixture()),
    patents: parseObjectFields("patents", PatentFixture()),
    tech_transfers: parseObjectFields("tech_transfers", TechTransferFixture()),
    investors: parseObjectFields("investors", InvestorFixture()),
    tech_experts: parseObjectFields("tech_experts", TechExpertFixture()),
    research_hubs: parseObjectFields("research_hubs", ResearchHubFixture()),
    influencers: parseObjectFields("influencers", InfluencerFixture()),
    research_papers: parseObjectFields("research_papers", ResearchPaperFixture()),
    patent_holders: parseObjectFields("patent_holders", PatentHolderFixture()),
    grants: parseObjectFields("grants", GrantFixture()),
    clinical_trials: parseObjectFields("clinical_trials", ClinicalTrialFixture()),
    companies: parseObjectFields("companies", CompanyFixture())
}

export const relationParsers: { [C in CName]: F1<SMap<any>, Result<RCollectionRelations<C>>> } = {
    startups: parseObjectFields("startups", StartupRelationsFixture()),
    sectors: parseObjectFields("sectors", SectorRelationsFixture()),
    patents: parseObjectFields("patents", PatentRelationsFixture()),
    tech_transfers: parseObjectFields("tech_transfers", TechTransferRelationsFixture()),
    investors: parseObjectFields("investors", InvestorRelationsFixture()),
    tech_experts: parseObjectFields("tech_experts", TechExpertRelationsFixture()),
    research_hubs: parseObjectFields("research_hubs", ResearchHubRelationsFixture()),
    influencers: parseObjectFields("influencers", InfluencerRelationsFixture()),
    research_papers: parseObjectFields("research_papers", ResearchPaperRelationsFixture()),
    patent_holders: parseObjectFields("patent_holders", PatentHolderRelationsFixture()),
    grants: parseObjectFields("grants", GrantRelationsFixture()),
    clinical_trials: parseObjectFields("clinical_trials", ClinicalTrialRelationsFixture()),
    companies: parseObjectFields("companies", CompanyRelationsFixture())
}

const relationDisplayNames: Displayable<RCollections> = {
    startups: "Startups",
    influencers: "Influencers",
    investors: "Investors",
    patent_holders: "Patent holders",
    patents: "Patents",
    research_hubs: "Research hub",
    research_papers: "Research papers",
    sectors: "Sectors",
    tech_experts: "Tech experts",
    tech_transfers: "Tech transfers",
    grants: "Grants",
    clinical_trials: "Clinical trials",
    companies: "Products & Technologies"
}

export const collectionPathParts: Casted<RCollections, string> = {
    sectors: "sectors",
    startups: "startups",
    patents: "patents",
    investors: "investors",
    tech_transfers: "tech-transfers",
    tech_experts: "tech-experts",
    research_hubs: "research-hubs",
    influencers: "influencers",
    research_papers: "research-papers",
    patent_holders: "patent-holders",
    grants: "grants",
    companies: "companies",
    clinical_trials: "clinical-trials"
}

export const getCollectionPathByCname = (cname: CName) => collectionPathParts[cname]

export const itemClassifiersDisplayNames = (): Displayable<ItemClassifiersInput> => ({
    primary_search_areas: getCopy("primarySearchAreas"),
    secondary_search_areas: getCopy("secondarySearchAreas"),
    tags: "Tags",
    segment_tags: "Segment tags"
})

export const collectionsListFieldsDisplayNames: { [C in CName]: Displayable<ViewModelsMap[C]> } = {
    startups: StartupFieldsDisplay,
    influencers: InfluencerFieldsDisplay,
    investors: InvestorFieldsDisplay,
    patents: PatentFieldsDisplay,
    research_hubs: ResearchHubDisplayFields,
    research_papers: ResearchPaperFieldsDisplay,
    sectors: SectorFieldsDisplay,
    tech_experts: TechExpertFieldsDisplay,
    tech_transfers: TechTransferFieldsDisplay,
    patent_holders: PatentHolderFieldsDisplay,
    grants: GrantFieldsDisplay,
    clinical_trials: ClinicalTrialFieldsDisplay,
    companies: CompanyFieldsDisplay
}

const collectionsEditFieldsDisplayNames: { [C in CName]: Displayable<ViewModelsMap[C]> } = {
    ...collectionsListFieldsDisplayNames,
    startups: {
        ...StartupFieldsDisplay,
        funding: "Funding"
    },
    grants: {
        ...GrantFieldsDisplay,
        funding: "Funding"
    },
    investors: {
        ...InvestorFieldsDisplay,
        amount_invested: "Amount invested",
        amount_per_investment: "Amount per investment"
    },
    research_hubs: {
        ...ResearchHubDisplayFields,
        active_funding: "Active funding",
        grant_funding: "Grant funding"
    },
    sectors: {
        ...SectorFieldsDisplay,
        funding: "Funding",
        recent_funding: "Total funding (Past 2 years)"
    },
    tech_experts: {
        ...TechExpertFieldsDisplay,
        active_funding: "Active funding",
        grant_funding: "Grant funding"
    }
}

export type DisplayNames<C extends CName> = (keyof Displayable<
    ViewModelsMap[C] & RCollectionRelations<C> & ItemClassifiersInput
>)[]
export const getDisplayNames = <C extends CName>(
    c: C
): F1<
    { mode: "list" | "edit" },
    Displayable<ViewModelsMap[C] & RCollectionRelations<C> & ItemClassifiersInput>
> => p => ({
    ...(p.mode === "list" ? collectionsListFieldsDisplayNames[c] : (collectionsEditFieldsDisplayNames[c] as any)),
    ...itemClassifiersDisplayNames(),
    ...relationDisplayNames
})

export const generateCollectionItemId = (cname: CName) => `${cname}-${v4()}`

export const getAvailableCollections = (config: Partial<LocationParams>, root: boolean = false) =>
    root ? cnames : cnames.filter(cname => config.collections?.includes(cname)) || []
