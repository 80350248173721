import styled, { keyframes, css } from "styled-components"
import { LoaderProps } from "./Loader"
const stroke = keyframes`
    0% {
      stroke-dashoffset: 300;
    }
    50% {
      stroke-dashoffset: 150;
    }
    100% {
      stroke-dashoffset: 0;
    }`

const rotate = keyframes` {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(60deg);
    }
    30% {
      transform: rotate(60deg);
    }
    40% {
      transform: rotate(120deg);
    }
    50% {
      transform: rotate(120deg);
    }
    60% {
      transform: rotate(180deg);
    }
    70% {
      transform: rotate(180deg);
    }
    80% {
      transform: rotate(240deg);
    }
    90% {
      transform: rotate(240deg);
    }
    100% {
      transform: rotate(300deg);
    }
  }`

export const _LoaderContainerOverlay = styled.div<{ show?: boolean; hidden?: boolean }>`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: ${p => (p.hidden ? "hidden" : "visible")};
    opacity: ${props => (props.show ? 1 : 0)};
    transition: opacity 0.3s ${props => (props.show ? 0 : "0.5s")};
    z-index: 10;
    background: #fff;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
`
export const _LoaderContainer = styled.div<{ show?: boolean; hidden?: boolean }>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const _LoaderContent = styled.div<{ show?: boolean; small?: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => (!props.show ? 0 : props.small ? 36 : 64)}px;
    height: ${props => (!props.show ? 0 : props.small ? 36 : 64)}px;
    opacity: ${props => (props.show ? 1 : 0)};
    transition: opacity 0.3s;

    & > * {
        flex-shrink: 0;
    }
`
export const _LoadingInfo = styled.div<Pick<LoaderProps, "show">>`
    height: ${props => (props.show ? 64 : 0)}px;
    margin-left: ${props => (props.show ? 10 : 0)}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    opacity: ${props => (props.show ? 1 : 0)};
    font-family: "Lato", sans-serif;
    transition: opacity 0.3s linear ${props => (props.show ? "0.15s" : "0s")};
`
export const _LoaderLabel = styled.p<{ head?: boolean }>`
    margin: 0;
    font-size: ${props => (props.head ? "16px" : "12px")};
    font-weight: ${props => (props.head ? 600 : 400)};
    color: ${props => (props.head ? "#4F5D75" : "#8DABC4")};
    margin-bottom: 2px;
`

export const _LoaderSvg = styled.svg<{ center?: boolean; small?: boolean }>`
    width: ${props => (props.small ? "36px" : "64px")};
    height: ${props => (props.small ? "36px" : "64px")};
    & > path.track {
        transform-origin: 50% 50%;
        ${props =>
            props.small
                ? ""
                : css`
                      animation: ${rotate} 4s ease-in-out infinite;
                  `}
    }

    & > path.fill {
        stroke-dasharray: 150;
        stroke-dashoffset: 300;
        transform-origin: 50% 50%;
        animation: ${stroke} 2s ease-in-out infinite, ${rotate} 4s ease-in-out infinite;
    }
`
