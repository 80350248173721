import { Err, isErr, isOk } from "../../../functions/src/utils/validators"
import { collectionsFieldsValidators } from "../../../functions/src/models/collections"
import { validateItemClassifiersInput } from "../../../functions/src/models/common"
import { PreloadedCollections, PreloadedCollectionsVM } from "../../../functions/src/models/importing.types"
import { getFirebase } from "../../services/firebase"
import { actions } from "../../../functions/src/services/httpEndpoint/actions"
import { actions as uiActions } from "../ui/actions"
import { ImportValidateItemsPayload } from "../../../functions/src/services/httpEndpoint/import/validateItems"
import { getStore } from ".."
import { Msg } from "../../models/notifications"
import { PAYLOAD_TOO_LONG } from "../../../functions/src/services/httpEndpoint/const"

export const validateFields = <C extends CName>(cname: C, value: Result<RCollection<C>>): Result<RCollection<C>> => {
    if (isErr(value)) return value
    const res = collectionsFieldsValidators[cname](value.value) as Result<RCollection<C>>
    // Preserve all fields like id, createdTs
    if (isOk(res)) {
        res.value = { ...value.value, ...res.value }
    } else {
        res.obj = { ...value.value, ...res.obj }
    }
    return res
}

export const validateClassifiersTypes = (value: CollectionItemClassifiers): CollectionItemClassifiers => {
    if (isErr(value)) return value
    return validateItemClassifiersInput(value.value) as CollectionItemClassifiers
}

// TODO TEST
export const validateItem = async (
    pcs: PreloadedCollections,
    radarId: string,
    mode: ImportValidateItemsPayload["mode"],
    isEditing: boolean = false
): Promise<PreloadedCollectionsVM> => {
    const result = await getFirebase().functions.callFunctions(
        actions.importValidateItems({ pcs, radarId, mode, isEditing }),
        e => {
            if (e === PAYLOAD_TOO_LONG)
                getStore().dispatch(
                    uiActions.queueNotification(
                        Msg(
                            "custom",
                            {
                                success: "",
                                error:
                                    "We cannot handle that amount of data. Please consider uploading smaller data files or upload them separately."
                            },
                            Err("")
                        )
                    )
                )
        }
    )
    if (isErr(result)) {
        // eslint-disable-next-line no-console
        console.error("failed to validate collection", result)
        return {}
    }
    return result.value.pcs
}
