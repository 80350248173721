import * as React from "react"
import { connect } from "react-redux"

import { Dropdown, IconDropdown } from "../../components/Dropdown"
import { radarPaths, collectionPaths, staticRadarPaths, adminPaths, getListPathName, Paths } from "../../paths"
import { getCurrentPath } from "../../utils/router"
import { values, filterObject } from "../../../functions/src/utils/map"
import { Header, _Headline } from "../../components/Header"
import { Banner } from "../../components/Banner"
import { _h2 } from "../../styles/typography"
import { actions as authActions } from "../../store/auth/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { _LayoutContainer } from "../../components/Page"
import { _HorizontalSpace, Flex } from "../../styles/common"
import { toOption, isFetched, Loading, Loaded } from "../../../functions/src/utils/types"
import { SearchBarView } from "../../components/SearchBar"
import { NewsfeedButton } from "../../components/newsfeed/NewsfeedButton"
import { getLastNewsfeedVisitTimestamp, isAdmin, isRoot, isLoggedIn } from "../../models/LoginStatus"
import { filterResults } from "../../models/results"
import { CloudActions } from "../../../functions/src/actions/actionCreators"
import { getCurrentRadarId } from "../../models/LocationType"
import { NamedPath } from "../../utils/router.types"
import { AuthResult } from "../../store/middlewares/authMiddleware.types"
import { AuthenticationState, RadarLocationState } from "../../models/auth"
import { Popup } from "../../store/store"
import { MapState, MapDispatch } from "../../utils/redux.types"
import { getNewsLength } from "../../components/newsfeed/Newsfeed"
import RadarNavigation from "../../components/radar/RadarNavigation"
import { getAvailableCollections } from "../../../functions/src/models/collections"

type PathOption = ROption<string>

type StateProps = {
    selectedPaths: PathOption[]
    availablePaths: PathOption[]
    currentPath: NamedPath
    radar?: RadarDetails
    permissions: AuthResult
    loginStatus: AuthenticationState
    unreadNewsCounter: Loadable<{ value: number }>
    radarSlug: string
    config: LocationParams
}

type ActionProps = {
    navigateAdmin: F1<string>
    navigate: F1<string>
    logout: F0
    openPopup: F1<Type<Popup>>
    goToNewsfeed: F0
    showReportCard: F0
}

type Props = StateProps & ActionProps

const DemoBanner: React.FC<{ onContactClick: F0 }> = p => (
    <Banner>
        <_h2 bold align="center">
            <a href="#" onClick={p.onContactClick} style={{ color: "white" }}>
                Contact us
            </a>{" "}
            if you wish to view full content of this radar.
        </_h2>
    </Banner>
)

const AdminBanner: React.FC = () => (
    <Banner>
        <_h2 bold align="center">
            Admin view
        </_h2>
    </Banner>
)

const RadarLayout: React.FC<Props> = p => {
    const isDashboard = p.currentPath === radarPaths["radar/dashboard"]
    const isDemo = p.radar ? p.radar.demo : false
    const showExport = p.radar && !isDemo && isAdmin(p.loginStatus) && !isRoot(p.loginStatus)

    const options = [toOption("FAQ", () => p.openPopup("showFAQ"))]

    if (isLoggedIn(p.loginStatus)) {
        if (showExport) options.push(toOption("Export", () => p.openPopup("export")))
        if (!isDemo && !isRoot(p.loginStatus) && p.config.withPipelinesSummary)
            options.push(toOption("Reports View", () => p.showReportCard()))
        options.push(toOption("Edit Profile", () => p.openPopup("profile")))
        if (isAdmin(p.loginStatus)) options.push(toOption("Admin panel", () => p.navigateAdmin(p.radarSlug)))
        options.push(toOption("Logout", p.logout))
    }
    return (
        <_LayoutContainer>
            {isRoot(p.loginStatus) ? (
                <AdminBanner />
            ) : (
                isDemo && <DemoBanner onContactClick={() => p.openPopup("showContact")} />
            )}
            <Header hideLogo={isDashboard} onClickLogo={() => p.navigate("/app/:radarSlug")}>
                <Dropdown
                    full
                    title="views"
                    selected={p.selectedPaths}
                    options={p.availablePaths}
                    onSelect={o => p.navigate(o.value)}
                />
                <_HorizontalSpace base="15px" />
                <_Headline>
                    <RadarNavigation />
                </_Headline>
                <Flex>
                    {isDemo ? null : <NewsfeedButton unreadCount={p.unreadNewsCounter} onClick={p.goToNewsfeed} />}
                    <_HorizontalSpace base="10px" />
                    <SearchBarView />
                    <_HorizontalSpace base="16px" />
                    <IconDropdown
                        icon={{
                            name: "menu-icon",
                            width: 18,
                            height: 18
                        }}
                        full
                        optionsXLocation="left"
                        options={options}
                        onSelect={a => a.value()}
                    />
                </Flex>
            </Header>
            {p.children}
        </_LayoutContainer>
    )
}

export const getAvailablePaths = (
    radarId: string,
    configs: Async<SMap<LocationParams>>,
    isDemo: boolean
): SMap<NamedPath> => {
    if (!radarId || !isFetched(configs)) return radarPaths
    const radarAvailableCollectionPaths = getAvailableCollections(configs.value[radarId]).map(getListPathName)
    const availableSubpaths = filterObject(collectionPaths, (k: keyof typeof collectionPaths) =>
        radarAvailableCollectionPaths.includes(k)
    )
    const pathMap: SRecord<keyof Paths, boolean> = {
        "radar/dashboard": configs.value[radarId].withDashboard && !isDemo,
        "radar/newsfeed": !isDemo,
        "radar/reports": false
    }
    const staticPaths = filterObject(staticRadarPaths, (k: keyof typeof staticRadarPaths) => pathMap[k] !== false)
    return { ...staticPaths, ...availableSubpaths }
}

const mapState: MapState<StateProps> = ({ data, auth, router, cloud }) => {
    const radarId = getCurrentRadarId(auth) || ""
    const radarSlug = isFetched(auth.configs) && radarId ? auth.configs.value[radarId].radarSlug : ""
    const radar = data[radarId]?.radar
    const rawRadar = isFetched(radar) ? radar.value : undefined
    // TODO Add memoization
    const availablePaths = values(getAvailablePaths(radarId, auth.configs, rawRadar?.demo !== false)).map(v =>
        toOption(v.name, v.path)
    )
    const fallbackPath = getCurrentPath(values(radarPaths), router, radarPaths["radar/radar"])
    const currentPath = getCurrentPath(values(collectionPaths), router, fallbackPath, false)
    const selectedPaths = [toOption(currentPath.name, currentPath.path)]
    const config = (auth.params as RadarLocationState).locationParams

    const unreadNewsCounter = !isLoggedIn(auth.authentication)
        ? Loading()
        : Loaded({
              value: filterResults(
                  cloud.actionsResults as SMap<CloudActionResult<CloudActions>>,
                  auth.authentication.user
              )
                  .filter(r => r.createdTs >= (getLastNewsfeedVisitTimestamp(auth.authentication) || Infinity))
                  .flatMap(getNewsLength(radarId))
                  .reduce((acc, len) => acc + len, 0)
          })

    return {
        permissions: auth.permissions,
        radar: rawRadar,
        availablePaths,
        selectedPaths,
        currentPath,
        loginStatus: auth.authentication,
        unreadNewsCounter,
        radarSlug,
        config
    }
}
const mapDispatch: MapDispatch<ActionProps> = d => ({
    navigateAdmin: rslug =>
        d(authActions.navigate({ path: adminPaths["admin/radarDashboard"].path, slugs: { radarSlug: rslug } })),
    navigate: path => d(authActions.navigate({ path, preserveSearchParams: true })),
    openPopup: popupKey => d(uiActions.openPopup(popupKey)),
    logout: () => d(authActions.tryLogout()),
    goToNewsfeed: () => d(authActions.navigate({ path: radarPaths["radar/newsfeed"].path })),
    showReportCard: () => d(authActions.navigate({ path: radarPaths["radar/reports"].path }))
})

export const RadarLayoutWrapper = connect(mapState, mapDispatch)(RadarLayout)
