import React from "react"
import { getCollectionSchema } from "../../../functions/src/models/schemas"
import { isEmpty } from "../../../functions/src/utils/validators"
import { asyncConnect } from "../../store/async/asyncConnect"
import { _Center } from "../../styles/common"
import { _BodyFont } from "../../styles/typography"
import { Join } from "../../utils/reactUtils"
import { CollectionRelations } from "../../views/cards/CardRelations"
import { _CardTitle, _RelationsGrid } from "../newCards/Card.styles"
import { _CardSectionContainer, _CardSectionTitle } from "../newCards/sections/CardSection.styles"
import { EditItemProps } from "./EditCollectionItem"

type CollectionRelationsFormProps<C extends CName> = {
    onAdd: F2<CName, string>
    onRemove: F2<CName, string>
    getSelected: F1<CName, ROptions>
    getIsLoading?: F1<CName, boolean>
    onNavigate?: F2<CName, string>
    cname: C
    objectId?: ObjectId
    radarId: RadarId
    errors?: SMap<string>
} & Pick<EditItemProps<C>, "mode">

export const CollectionRelationsEditor = asyncConnect<CollectionRelationsFormProps<CName>>()({
    data: ["currentRadarConfig", "availableCollections", "isRoot"]
})(p => {
    const irc = getCollectionSchema(p.cname).relations.all as CName[]
    const { availableCollections, currentRadarConfig } = p
    const itemRelatedCollections =
        p.isRoot && p.mode === "import"
            ? irc
            : irc.filter(v => availableCollections.includes(v) && currentRadarConfig.collections.includes(v))

    return (
        <_CardSectionContainer big>
            <_CardTitle>
                <_CardSectionTitle>Relationships</_CardSectionTitle>
            </_CardTitle>
            <_BodyFont s16>Add relationships to other objects and search for those that interest you</_BodyFont>
            {!isEmpty(itemRelatedCollections) ? (
                <_RelationsGrid>
                    <Join items={itemRelatedCollections}>
                        {(cname, i) => {
                            const { displayName } = getCollectionSchema(cname)
                            return (
                                <CollectionRelations
                                    title={displayName}
                                    errors={p.errors}
                                    cname={cname}
                                    objectId={p.objectId}
                                    radarId={p.radarId}
                                    selected={p.getSelected(cname)}
                                    key={i}
                                    isLoading={p.getIsLoading ? p.getIsLoading(cname) : false}
                                    onNavigate={slug => p.onNavigate && p.onNavigate(cname, slug)}
                                    onAdd={({ value }) => p.onAdd(cname, value)}
                                    onRemove={id => p.onRemove(cname, id)}
                                />
                            )
                        }}
                    </Join>
                </_RelationsGrid>
            ) : (
                <_Center>
                    <_BodyFont>No relations available for this object type.</_BodyFont>
                </_Center>
            )}
        </_CardSectionContainer>
    )
})
