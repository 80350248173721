import * as React from "react"
import { adminPaths } from "../../../paths"
import { asyncConnect } from "../../../store/async/asyncConnect"
import { LinkButton } from "../../../styles/buttons"
import { Flex, _FlexContainer, _VerticalSpace } from "../../../styles/common"
import { CardTagsEditor } from "../../tags/TagsEditor"
import { _CardSectionContainer, _CardSectionTitle } from "./CardSection.styles"

export type OwnProps = {
    objectId: ObjectId
}

export const TagsCardSection = asyncConnect<OwnProps>()({
    data: ["currentRadarConfig", "isDemo", "isAdmin"],
    actions: ["openPopup", "navigate"]
})(p => {
    return (
        <_FlexContainer>
            <_CardSectionContainer>
                <_CardSectionTitle>Tags</_CardSectionTitle>
                <div>
                    {/* TODO Refactor this editor */}
                    <CardTagsEditor objectId={p.objectId} />
                    <_VerticalSpace base="4px" />
                    {p.isAdmin && (
                        <Flex justify="flex-end">
                            <LinkButton
                                color="theme6"
                                onClick={() =>
                                    p.navigate({
                                        path: adminPaths["admin/radarDashboard"].path,
                                        slugs: { radarSlug: p.currentRadarConfig.radarSlug },
                                        searchParams: "tab=tags"
                                    })
                                }>
                                Manage Tags
                            </LinkButton>
                        </Flex>
                    )}
                </div>
            </_CardSectionContainer>
        </_FlexContainer>
    )
})
