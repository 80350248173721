import * as React from "react"
import Slider from "antd/lib/slider"
import "antd/lib/slider/style/index.css"
import "antd/lib/tooltip/style/index.css"
import { usePrevious } from "../../../utils/hooks/usePrevious"
import { isEqual } from "../../../utils"
import { _TagFont } from "../../../styles/typography"
import { formatMillions, formatDecimal, formatNumber, parseNumber } from "../../../../functions/src/models/converters"
import { identity } from "lodash/fp"
import { Flex } from "../../../styles/common"
import { TinyInput } from "../../form/Input"
import { assertNever } from "../../../../functions/src/utils"

type HasRangeFlavor = {
    flavor?: string
}

export type RangeControlProps = {
    value: [number, number]
    max?: number
    min?: number
    onChange: F1<[number, number]>
} & HasRangeFlavor

const Formatters: SMap<F1<any, string>> = {
    plain(v: any) {
        if (v === undefined) return ""
        return formatNumber(v, false)
    },
    funds(v: any) {
        if (v === undefined) return ""
        return formatMillions(v, false)
    },
    percentile(v: any) {
        if (v === undefined) return ""
        return formatDecimal(v, "0") + "%"
    }
}

const Parsers: SMap<F1<string, number>> = {
    plain(v: string) {
        // Use a ready function that handles all commas, dots and $m formats
        const parsed = parseNumber(v, "")
        switch (parsed.type) {
            case "Ok":
                return parsed.value
            case "Err":
                return NaN
        }
        assertNever(parsed)
    },
    funds(v: string) {
        const parsed = parseNumber(v, "")
        switch (parsed.type) {
            case "Ok":
                return parsed.value * 1_000_000
            case "Err":
                return NaN
        }
        assertNever(parsed)
    }
}

export const RangeControl = (p: RangeControlProps) => {
    // Used as slider min/max values
    const [leftBound, setLeftBound] = React.useState(p.min)
    const [rightBound, setRightBound] = React.useState(p.max)
    // If first render happended without data loaded, lb and rb may be undefined.
    // In that case, ensure they are refreshed on next load
    React.useEffect(() => {
        if (leftBound === undefined) setLeftBound(p.min)
        if (rightBound === undefined) setRightBound(p.max)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.min, p.max])

    const [value, setValue] = React.useState(p.value)

    const previousValue = usePrevious(p.value)

    const formatterKey = p.flavor ?? "plain"
    const formatter = Formatters[formatterKey] ?? identity
    const parser = Parsers[formatterKey] ?? Parsers.plain

    // Either value can be a special marker to signify "unknown". At which time they get clipped to min/max
    const _left = p.value[0] === Number.MIN_SAFE_INTEGER ? p.min : p.value[0]
    const _right = p.value[1] === Number.MAX_SAFE_INTEGER ? p.max : p.value[1]
    // Used on the left and right inputs
    const [leftValue, setLeftValue] = React.useState(formatter(_left))
    const [rightValue, setRightValue] = React.useState(formatter(_right))

    const applyChanges = (vals: [number, number]) => p.onChange(vals)

    React.useEffect(() => {
        setLeftValue(formatter(_left))
        setRightValue(formatter(_right))
        if (isEqual(previousValue, p.value)) return
        setValue(p.value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.value, previousValue, formatter])

    const onConfirmMin = (v: any) => {
        // Explicitly disallow a blank string. The parser will convert it to -1572861 because that's -3 * Undisclosed.
        // Which is not useful in sliders.
        if (v === "") return
        const parsed = parser(v as string)
        // Don't continue on malformed numbers
        if (isNaN(parsed)) return

        const newBounds: [number, number] = [parsed, value[1]]
        // Update slider
        setValue(newBounds)
        setLeftValue(formatter(parsed))
        // Update bounds if we extended them
        if (leftBound === undefined || parsed < leftBound) setLeftBound(parsed)
        // Update params and filter current table
        applyChanges(newBounds)
    }

    // Same as onConfirmMin, just for the max field
    const onConfirmMax = (v: any) => {
        if (v === "") return
        const parsed = parser(v as string)
        if (isNaN(parsed)) return

        const newBounds: [number, number] = [value[0], parsed]
        setValue(newBounds)
        setRightValue(formatter(parsed))
        if (rightBound === undefined || parsed > rightBound) setRightBound(parsed)
        applyChanges(newBounds)
    }

    const onChangeSlider = (v: any) => {
        const newBounds = v as [number, number]
        setValue(newBounds)
    }

    const commitSlider = (v: any) => {
        const newBounds = v as [number, number]
        // When the slider stops moving (handle released), update inputs
        setLeftValue(formatter(v[0]))
        setRightValue(formatter(v[1]))
        p.onChange(newBounds)
    }

    return (
        <>
            <Flex justify="space-between" align="baseline">
                <TinyInput value={leftValue} onEnter={onConfirmMin} onBlur={onConfirmMin} placeholder="-" />
                <TinyInput value={rightValue} onEnter={onConfirmMax} onBlur={onConfirmMax} placeholder="-" rightAlign />
            </Flex>
            <Slider
                range
                tipFormatter={v => formatter(v).toUpperCase()}
                value={value}
                min={leftBound}
                max={rightBound}
                onChange={onChangeSlider}
                onAfterChange={commitSlider}
            />
        </>
    )
}
