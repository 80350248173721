import * as React from "react"
import { connect } from "react-redux"
import { getCurrentPath } from "../utils/router"
import { radarPaths, detailsPaths, dashboardDetailsPaths, editPaths } from "../paths"
import { ModalProps } from "../components/Modal"
import { values } from "../../functions/src/utils/map"
import { actions } from "../store/auth/actions"
import { ModalContainer } from "../components/common/ModalContainer"
import { goBackHistory } from "../store"
import { NamedPath } from "../utils/router.types"
import { MapState, MapDispatch } from "../utils/redux.types"

type ActionProps = { navigate: F1<string> }
type StateProps = OmitStrict<ModalProps, "onClose"> & { parentPath: NamedPath; visitedPaths: string[] }
type Props = ActionProps & StateProps

export type ModalRouteProps = {
    onClose: F0
    title: string
}

export const ModalRendererPure: React.FC<Props> = ({ navigate, parentPath, visitedPaths, ...p }) => {
    const onClose = () => (visitedPaths.length > 2 ? goBackHistory() : navigate(parentPath.path))

    const childrenWithProps = React.Children.map(p.children, child =>
        React.cloneElement<ModalRouteProps>(child as React.ReactElement, {
            onClose,
            title: p.title
        })
    )
    return (
        <ModalContainer isOpened={p.isOpen} onOverlayClick={onClose} onClose={onClose}>
            {p.isOpen && childrenWithProps}
        </ModalContainer>
    )
}

const mapState: MapState<StateProps> = s => {
    const allowedPaths = [...values(detailsPaths), ...values(dashboardDetailsPaths), ...values(editPaths)]
    const currentPath = getCurrentPath([...allowedPaths, ...values(radarPaths)], s.router, radarPaths["radar/radar"])
    const { "radar/radar": radar, ...subpaths } = radarPaths

    const parentPath = getCurrentPath(values(subpaths), s.router, radar, false)
    return {
        title: currentPath.name,
        isOpen: Boolean(allowedPaths.find(d => d.path === currentPath.path)),
        parentPath,
        visitedPaths: s.ui.visitedPaths
    }
}

const mapDispatch: MapDispatch<ActionProps> = d => ({
    navigate: path => d(actions.navigate({ path, preserveSearchParams: true }))
})

// TODO: get rid of the modal renderer at all, performance reasons, treat object card as separate view
export const ModalRenderer = connect(mapState, mapDispatch)(ModalRendererPure)
