import styled from "styled-components"

type DNDButtonProps = { isDragActive: boolean }
export const _DNDButtonWrapper = styled.div<DNDButtonProps>`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 365px;

    padding: 16px;
    border-radius: 8px;
    border: ${({ isDragActive, theme }) => (isDragActive ? `solid 2px ${theme.colors.info};` : `dashed 2px #e2e4e6;`)};
    opacity: ${({ isDragActive }) => (isDragActive ? "0.5" : "1")};
    background: #fcfdff;

    &:active,
    :focus {
        outline: none;
    }

    & > * + * {
        margin-top: 20px;
    }
`
export const _DNDField = styled.div`
    position: absolute;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-height: 50vh;

    border-radius: 8px;
    border: dashed 2px ${p => p.theme.colors.info};
    opacity: 0.7;
    background: #fcfdff;

    outline: none;
`

export const _DNDFieldWrapper = styled.div`
    position: relative;
    display: flex;

    width: 100%;
    min-height: 50vh;

    outline: none;
    flex-direction: column;
`

export const _DNDButtonDescription = styled.div`
    font-size: 13px;
    line-height: 1.46;
    text-align: center;
    color: #3f536e;
`
