import * as React from "react"
import { ValueCellMeta } from "../components/table/TableViewCell"
import { useList } from "./lists/useList"
import { Flex, Margin, _AbsPosContainer, _AbsPosElement, _HorizontalSpace, _VerticalSpace } from "../styles/common"
import { _BodyFont } from "../styles/typography"
import { StyledTableButton } from "./DashboardView.styles"
import { Table } from "../components/table/TableView"
import { getCollectionSchema } from "../../functions/src/models/schemas"
import { collectionPaths, getListPathName, dashboardDetailsPaths, getDashboardDetailsPathName } from "../paths"
import { asyncConnect } from "../store/async/asyncConnect"
import { mapCNameToTopObjectsState } from "../store/async/utils"
import { isEqual } from "lodash"
import { usePrevious } from "../utils/hooks/usePrevious"
import { FetchIndicator } from "../components/common/FetchIndicator"

type OwnProps = {
    title: string
    cname: CName
}

export const getDashboardTableId = (cname: CName) => `dashboard-${cname}`

export const getRedirectPaths = (cname: CName, radarSlug: RadarId) => {
    const schema = getCollectionSchema(cname)
    const collectionIdField = schema.idField
    const ListViewPath = collectionPaths[getListPathName(cname)]
    const DetailViewPath = dashboardDetailsPaths[getDashboardDetailsPathName(cname)]

    const collectionPath = { path: ListViewPath.path }
    const getItemPath = (itemId: string) => ({
        path: DetailViewPath.path,
        slugs: { [collectionIdField]: itemId, radarSlug },
        preserveSearchParams: true
    })

    return {
        collectionPath,
        getItemPath
    }
}

export const DashboardTableView = asyncConnect<OwnProps>()({
    data: ["topObjects", "topObjectsListData", "filters", "currentRadarConfig", "sorting"],
    actions: ["navigate", "updateSortingKey", "openPopup", "mutateDecoratorDebounced", "_mergeData"],
    fetchHandlers: {
        fetchTopObjects: {
            resolver: "topObjects",
            getStateKey: op => mapCNameToTopObjectsState(op.cname)
        }
    }
})(p => {
    const sortingParams = p.topObjectsListData.ui.sorting[p.cname]
    const tableProps = useList(p.topObjectsListData)
    const { getItemPath, collectionPath } = React.useMemo(
        () => getRedirectPaths(p.cname, p.currentRadarConfig.radarSlug),
        [p.cname, p.currentRadarConfig.radarSlug]
    )

    const decorateItem = React.useCallback(
        (meta: ValueCellMeta<string>) => {
            if (!meta.actions) return
            switch (meta.actions.type) {
                case "star":
                    return p.mutateDecoratorDebounced({
                        type: "star",
                        objectId: meta.rowId as string,
                        newValue: meta.actions.value
                    })
                case "dropdown":
                    if (meta.actions!.value.type === "pipelineStage")
                        return p.openPopup("mandatoryComment", { type: "pipeline", value: meta.actions.value })
                    return p.mutateDecoratorDebounced(meta.actions.value)
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [p.mutateDecoratorDebounced, p.openPopup]
    )

    const handleOnSort = React.useCallback(
        sortingKey =>
            p.updateSortingKey(p.cname, [
                {
                    sortingKey,
                    sortingAsc: sortingParams?.[0]?.sortingKey === sortingKey ? !sortingParams?.[0]?.sortingAsc : false
                }
            ]),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [p.cname, p.updateSortingKey, sortingParams]
    )

    const handleOnRowClick = React.useCallback(
        item => {
            p._mergeData(p.currentRadarConfig.radarId, {
                [p.cname]: p.topObjects
            })
            p.navigate(getItemPath(item as string))
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [p.navigate, getItemPath, p.topObjects, p.cname, p.currentRadarConfig.radarId, p._mergeData]
    )

    const handleFetchTopObjects = React.useCallback(
        () => p.fetchTopObjects(p),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [p.filters, p.sorting, p.cname]
    )

    const prevFilters = usePrevious(p.filters)
    const prevSorting = usePrevious(p.sorting)

    React.useEffect(() => {
        const shouldUpdateAfterSort = !isEqual(p.sorting[p.cname], prevSorting?.[p.cname])
        if (!isEqual(p.filters, prevFilters) || shouldUpdateAfterSort) handleFetchTopObjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.filters, p.sorting])

    if (!p) return null
    return (
        <Margin left="16px" right="16px">
            <Flex align="center">
                <_HorizontalSpace base="20px" />
                <_BodyFont s17 bold>
                    {p.title}
                </_BodyFont>
                <_HorizontalSpace base="8px" />
                <FetchIndicator data={p.topObjects} onRetry={handleFetchTopObjects} />
            </Flex>
            <_VerticalSpace base="20px" />
            <Table
                {...tableProps}
                showTooltip={false}
                limit={10}
                smallLoader
                onCellContentClick={decorateItem}
                onSort={handleOnSort}
                virtualized={false}
                onRowClick={handleOnRowClick}
            />
            <StyledTableButton onClick={() => p.navigate({ ...collectionPath, preserveSearchParams: true })}>
                Browse all
            </StyledTableButton>
        </Margin>
    )
})
