/* eslint-disable max-lines */
import { DefaultTheme } from "styled-components"

export const theme: DefaultTheme = {
    colors: {
        theme1: "#ecf5fd",
        theme2: "#edeff2",
        theme3: "#fff",
        theme4: "#f3f3f3",
        theme6: "#0093ee",
        theme7: "#ab7df6",
        theme8: "#26c1c9",
        theme9: "#e4f5ff",
        theme10: "#58697e",
        theme11: "#647081",
        theme12: "#4340de",
        theme13: "#7d9df8",
        theme14: "#d4d7da",
        theme15: "#fbd051",
        theme16: "#12a4c1",
        theme17: "#a8c6df",
        theme18: "#e0e2e4",
        theme19: "#ebedee",
        theme20: "#F1C06E",
        primary: "#2c405a",
        secondary: "#8dabc4",
        success: "#4ece3d",
        info: "#0093ee",
        infoHover: "#0074bd",
        danger: "#ff151f",
        dangerHover: "#b20b0b",
        edit: "#68e2a7",
        transparent: "transparent"
    },
    sizes: {
        sidebarWidth: 175,
        arrowColumnsHeight: 60,
        arrrowRowsWidth: 78,
        defaultPadding: 25
    }
}
