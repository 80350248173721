import * as React from "react"
import { Popup } from "../Popup"
import { PopupInner } from "../Popup.styles"
import { _h1 } from "../../styles/typography"

type Props = {
    onClose: F0
}

export const Contact: React.FC<Props> = p => (
    <Popup onClose={p.onClose} logo>
        <PopupInner>
            <_h1>
                This is a sample radar. If you wish to view the full content of this radar, please contact{" "}
                <a href="mailto:stu@radicleinsights.com">stu@radicleinsights.com</a>
            </_h1>
        </PopupInner>
    </Popup>
)
