import * as React from "react"
import styled from "styled-components"
import { _FlexContainer } from "../../styles/common"

type GridValue = string

const _TableRow = styled.div<{ layout: GridValue; rowGap?: string; columnGap?: string }>`
    display: grid;
    grid-template-columns: ${p => p.layout};
    grid-gap: ${p => p.columnGap};

    &:not(:last-child) {
        margin-bottom: ${p => p.rowGap};
    }
`

const _TableLayout = styled(_FlexContainer)``

type TableLayoutProps<C extends React.ReactNode, R extends C[]> = {
    rows: R[]
    header: React.ReactNode[]
    layout: GridValue
    rowGap?: string
    columnGap?: string
}

export const TableLayout = <C extends React.ReactNode, R extends C[]>(p: TableLayoutProps<C, R>) => {
    return (
        <_TableLayout>
            <_TableRow layout={p.layout} rowGap={p.rowGap} columnGap={p.columnGap}>
                {p.header}
            </_TableRow>
            {p.rows.map((r, i) => (
                <_TableRow key={i} layout={p.layout} rowGap={p.rowGap} columnGap={p.columnGap}>
                    {r}
                </_TableRow>
            ))}
        </_TableLayout>
    )
}
