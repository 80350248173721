import { getDecryptedText, getEncryptedText } from "../utils/crypto"
import { remap, identity, mapObject } from "../utils/map"

export const decryptPipelines = <T extends PipelineStageValue>(
    pipelines: SMap<T>,
    key: EncryptionKey | null
): SMap<T> => remap(pipelines, identity, p => getDecryptedPipeline(p, key))

export const getDecryptedPipeline = <T extends PipelineStageValue>(ps: T, key: EncryptionKey | null): T => ({
    ...ps,
    name: key ? getDecryptedText(ps.name, key) : ps.name
})

export const encryptPipeline = (
    payload: PipelineValueMutationPayload,
    key: EncryptionKey
): PipelineValueMutationPayload => {
    if (payload.type === "delete") return payload
    const newPayload = { ...payload }
    if (newPayload.type === "update")
        newPayload.value = newPayload.value.map(p => ({ ...p, name: getEncryptedText(p.name, key) }))
    else newPayload.value.name = getEncryptedText(newPayload.value.name, key)
    return newPayload
}

export const encryptLead = (payload: ContactedLeadDecorator, key: EncryptionKey): ContactedLeadDecorator =>
    mapObject(payload, (_, v) => getEncryptedText(v, key))

export const decryptLead = (payload: ContactedLeadDecorator, key: EncryptionKey): ContactedLeadDecorator =>
    mapObject(payload, (_, v) => getDecryptedText(v, key))

export const isAreaDecoratorMeta = (m: DecoratorMeta): m is AreaDecoratorMeta =>
    m.type === "pipelineStage" || m.type === "priorityRank"

export const PipelineDecoratorFromMeta = (m: AreaDecoratorMeta): PipelineStageDecorator | null => {
    if (m.type !== "pipelineStage" || !m.newValue) return null
    return { searchAreaId: m.searchAreaId, valueRef: m.newValue }
}

export const PriorityDecoratorFromMeta = (m: AreaDecoratorMeta): PriorityRankDecorator | null => {
    if (m.type !== "priorityRank" || !m.newValue) return null
    return { searchAreaId: m.searchAreaId, value: m.newValue }
}

export const AssignmentDecoratorFromMeta = (m: NonAreaDecoratorMeta): AssignmentDecorator | null => {
    if (m.type !== "assignment" || !m.newValue) return null
    return { userId: m.newValue }
}
export const AttachmentDecoratorFromMeta = (m: AttachmentDecoratorMeta): AttachmentDecorator | null => {
    if (m.type !== "attachment" || !m.newValue) return null
    return { ...m.newValue, path: m.path }
}
export const StarDecoratorFromMeta = (m: StarDecoratorMeta): StarDecorator | null => {
    if (m.type !== "star" || !m.newValue) return null
    return { value: true }
}
export const ContactedLeadDecoratorFormMeta = (m: ContactedLeadDecoratorMeta): ContactedLeadDecorator | null => {
    if (m.type !== "lead" || !m.newValue) return null
    return m.newValue
}
