import { Ok, Err } from "./validators"

export const TemporaryEmailBlacklist = [
    "10minutemail.com",
    "20minutemail.com",
    "biyac.com",
    "emailfake.com",
    "genebag.com",
    "guerrillamail.com",
    "jetable.org",
    "just4fun.me",
    "mailinator.com",
    "mailpoof.com",
    "mintemail.com",
    "mytemp.email",
    "mytrashmail.com",
    "nicoric.com",
    "upived.online",
    "vintomaper.com",
    "yopmail.com",
    "zetmail.com"
]

export const rejectTemporaryMailboxes = (v: any, msg?: string): Result<string, string> => {
    const [, domain] = (v as string).split("@")
    if (TemporaryEmailBlacklist.indexOf(domain.toLowerCase()) != -1)
        return Err(msg || "Temporary mailbox addresses are not accepted.", v)

    return Ok(v)
}
