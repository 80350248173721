import { keys } from "../utils/map"

export const RFields = <T1, T2>(
    requiredMap: Casted<T1, any>,
    optionalMap: Casted<T2, any>
): FieldKeys<T1, T2, T1 & T2> => {
    const required = keys(requiredMap)
    const optional = keys(optionalMap)
    return { required, optional, all: [...required, ...optional] }
}

export const mergeFields = (f1: FieldKeys<any, any>, f2: FieldKeys<any, any>): FieldKeys<any, any> => {
    const required = [...f1.required, ...f2.required]
    const optional = [...f1.optional, ...f2.optional]
    const all = [...f1.all, ...f2.all]
    return { required, optional, all }
}
