import * as React from "react"
import { connect } from "react-redux"
import { actions } from "../../store/auth/actions"

import { _BodyFont, Prominent } from "../../styles/typography"
import { Flex, _HorizontalSpace, _VerticalSpace } from "../../styles/common"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { AuthenticationState } from "../../models/auth"
import { MapState, MapDispatch } from "../../utils/redux.types"
import { HexLayout } from "../layouts/HexLayout"
import { _DarkBlueButton, _LoginFormTitle } from "./Login"
import { Fetching, isFetching, NotFetched } from "../../../functions/src/utils/types"
import { getCurrentRadar } from "../../models/LocationType"
import { Loader } from "../../components/common/Loader"

type StateProps = {
    authentication: AuthenticationState
    actionsResults: SMap<CloudActionResult>
    radarName: string
}
type ActionProps = {
    tryRequestAccess: F1<string>
    tryLogout: F0
    resetAuthState: F0
}

type Props = ActionProps & StateProps
const renderHeader = (p: Props) => {
    if (p.authentication.type !== "LoggedIn") return null
    switch (p.authentication.authorization) {
        case "AccessRequested":
        case "AccessNotRequested":
            return (
                <>
                    <Flex as="h1" wrap justify="center">
                        <_LoginFormTitle>Request</_LoginFormTitle>
                        <_HorizontalSpace base="11px" />
                        <_LoginFormTitle bold>access</_LoginFormTitle>
                    </Flex>
                    <_VerticalSpace base="14px" />
                    <_BodyFont s14 color="theme11" align="center">
                        To view {p.radarName} radar as {p.authentication.user.email}, you have to request the access or{" "}
                        <Prominent onClick={p.tryLogout}>LOGOUT</Prominent>
                    </_BodyFont>
                </>
            )
        case "AccessDeclined":
            return (
                <>
                    <Flex as="h1" wrap justify="center">
                        <_LoginFormTitle>Access</_LoginFormTitle>
                        <_HorizontalSpace base="11px" />
                        <_LoginFormTitle bold>declined</_LoginFormTitle>
                    </Flex>
                    <_VerticalSpace base="14px" />
                    <_BodyFont s14 color="theme11" align="center">
                        Your access as {p.authentication.user.email} to {p.radarName} radar has been declined.
                    </_BodyFont>
                </>
            )
        default:
            return null
    }
}

const renderContent = (authentication: AuthenticationState, requestAccess: F0, logout: F0) => {
    const [sendResult, setSendResult] = React.useState<Async<string>>(NotFetched())

    if (authentication.type !== "LoggedIn") return null
    switch (authentication.authorization) {
        case "AccessNotRequested":
            return (
                <>
                    {isFetching(sendResult) ? (
                        <Loader loadingText="Requesting access" />
                    ) : (
                        <_DarkBlueButton
                            data-cy="RequestAccess"
                            onClick={() => {
                                setSendResult(Fetching(""))
                                requestAccess()
                            }}>
                            Request
                        </_DarkBlueButton>
                    )}
                </>
            )
        case "AccessRequested":
            return (
                <_BodyFont data-cy="request-access-thank-you-confirmation" s14 align="center">
                    Thank you. You will be notified when your access to this radar is granted. It could take a few days
                </_BodyFont>
            )
        case "AccessDeclined":
            return <_DarkBlueButton onClick={logout}>Logout</_DarkBlueButton>
        default:
            return null
    }
}

export const RequestAccessPopup: React.FC<Props> = p => {
    const sendAction = useCloudAction(p.tryRequestAccess, p.actionsResults)[1]

    return (
        <HexLayout
            main={
                <>
                    {renderHeader(p)}
                    <_VerticalSpace base="30px" />
                    {renderContent(p.authentication, sendAction, p.tryLogout)}
                </>
            }
        />
    )
}

const mapState: MapState<StateProps> = ({ auth, cloud: { actionsResults } }) => ({
    authentication: auth.authentication,
    radarName: getCurrentRadar(auth)?.radarName || "this",
    actionsResults
})

const mapDispatch: MapDispatch<ActionProps> = d => ({
    tryRequestAccess: (aid: string) => d(actions.tryRequestAccess(aid)),
    tryLogout: () => d(actions.tryLogout()),
    resetAuthState: () => d(actions.resetAuthStatuses())
})

export const RequestAccessView = connect(mapState, mapDispatch)(RequestAccessPopup)
