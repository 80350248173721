import * as React from "react"
import { toArray, iterateMap } from "../../../functions/src/utils/map"
import { _FormViewWrapper } from "../../styles/forms"
import { assertNever, hasField } from "../../../functions/src/utils"
import {
    BasicInput,
    TextAreaInput,
    CollectionInput,
    ListInput,
    RadioInput,
    CreatableChipsInput,
    DropdownInput,
    ChipsInput
} from "./Input"
import { isArray } from "../../../functions/src/utils/validators"
import { RichTextAreaInput } from "./RichEditor"

type FormInputProps<T> = { schema: InputSchema<T>; state: any; setDelta: F1<any> }
export const _FormInput = <T extends any>({ schema, ...p }: FormInputProps<T>): React.ReactElement => {
    switch (schema.type) {
        case "text":
        case "email":
        case "number":
        case "specialNumber":
        case "password":
            return <BasicInput {...p} schema={schema} />

        case "textarea":
            return <TextAreaInput {...p} schema={schema} />

        case "richTextarea":
            return <RichTextAreaInput {...p} schema={schema} />

        case "collection":
            return <CollectionInput {...p} schema={schema} />

        case "list":
            return <ListInput {...p} schema={schema} />

        case "radio":
        case "switch": // Use switch in @react-formless only
            return <RadioInput {...p} schema={schema} />

        case "chips":
            return <CreatableChipsInput {...p} schema={schema} />

        case "colorDropdown":
        case "dropdown":
            return <DropdownInput {...p} schema={schema} />

        case "selectableChips":
            return <ChipsInput {...p} schema={schema} />
    }
    assertNever(schema)
}

export type FormViewProps<T> = {
    setState: F1<FormState<T>>
    state: FormState<T>
    schema: FormSchema<T>
    columns?: number
}

// TODO test it on every type
const ensureStateIsNotActive = <T extends any>(fs: FormState<T>) => {
    iterateMap(fs as SMap<InputState<any> | FormState<any>>, (_, v) => {
        if (isArray(v)) {
            v.forEach((v2: any) => {
                if (hasField(v2, "active")) v2.active = false
                else ensureStateIsNotActive(v2)
            })
        } else {
            const v3 = v as InputState<any>
            if (hasField(v3, "active")) v3.active = false
        }
    })
}

export function FormView<T extends any>(p: FormViewProps<T>): React.ReactElement {
    const setDelta = (key: keyof T) => (value: any) => {
        ensureStateIsNotActive(p.state)
        p.setState({ ...p.state, [key]: value })
    }
    const inputProps = toArray(p.schema, (schema, key) => ({ schema: schema as any, key, state: p.state[key] })).map(
        s => ({
            ...s,
            setDelta: setDelta(s.key)
        })
    )
    return (
        <_FormViewWrapper gridColumns={p.columns}>
            {inputProps.map(ip => (
                <_FormInput {...ip} /> // eslint-disable-line react/jsx-key
            ))}
        </_FormViewWrapper>
    )
}
