import { usleep } from "../../functions/src/utils"
import { debounce } from "../utils"

type IntercomUser = {
    name: string
    email: string
}

// eslint-disable-next-line no-console
const getIntercom = () => (window as any).Intercom || (() => console.error("Intercom not loaded properly"))

export const getIntercomService = () => {
    let isLoading = false
    let isBooted = false

    const s = {
        boot: debounce(async (user?: IntercomUser | null) => {
            const Intercom = getIntercom()
            while (isLoading) {
                await usleep(200)
            }
            isLoading = true

            if (isBooted) {
                s.shutdown()
                await usleep(100)
            }
            Intercom("boot", {
                app_id: process.env.INTERCOM_APP_ID,
                ...(user || {})
            })
            await usleep(100)
            isLoading = false
            isBooted = true
        }, 500),
        update: (user?: IntercomUser) => {
            const Intercom = getIntercom()
            if (isBooted && !isLoading) {
                Intercom("update", user)
            }
        },
        shutdown: () => {
            const Intercom = getIntercom()
            Intercom("shutdown")
        },
        show: () => {
            const Intercom = getIntercom()
            Intercom("show")
        },
        showNewMessage: () => {
            const Intercom = getIntercom()
            Intercom("showNewMessage")
        },
        hide: () => {
            const Intercom = getIntercom()
            Intercom("hide")
        }
    }
    return s
}
