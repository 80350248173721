import * as React from "react"
import { connect } from "react-redux"

import { Header, _Headline } from "../../components/Header"
import { actions } from "../../store/auth/actions"
import { _LayoutContainer } from "../../components/Page"
import { Flex } from "../../styles/common"
import { IconDropdown } from "../../components/Dropdown"
import { toOption } from "../../../functions/src/utils/types"
import { LinkButton } from "../../styles/buttons"
import { actions as uiActions } from "../../store/ui/actions"
import { isRoot } from "../../models/LoginStatus"
import { AuthenticationState } from "../../models/auth"
import { NavigationParams, NamedPath } from "../../utils/router.types"
import { Popup } from "../../store/store"
import { MapState, MapDispatch } from "../../utils/redux.types"
import { IconSvg } from "../../components/IconSvg"

type StateProps = {
    loginStatus: AuthenticationState
    backParams: (NavigationParams & NamedPath) | undefined
}

type ActionProps = { navigate: F1<NavigationParams>; logout: F0; openPopup: F1<Type<Popup>> }

type Props = StateProps & ActionProps

const AdminLayout: React.FC<Props> = p => {
    const isSuperAdmin = isRoot(p.loginStatus)
    const menuOptions: ROption<F0>[] = []
    menuOptions.push(toOption("Edit Profile", () => p.openPopup("profile")))
    menuOptions.push(toOption("Logout", p.logout))
    return (
        <_LayoutContainer>
            <Header hideBorder>
                <_Headline>
                    <strong>Admin panel</strong>
                    <span>&nbsp;|&nbsp;</span>
                    {isSuperAdmin ? "Radicle SuperAdmin" : "Radar"}
                </_Headline>
                <Flex>
                    <IconDropdown
                        icon={{
                            name: "menu-icon",
                            width: 18,
                            height: 18
                        }}
                        full
                        optionsXLocation="left"
                        options={menuOptions}
                        onSelect={a => a.value()}
                    />
                </Flex>
            </Header>
            {p.backParams && (
                <Header hideLogo>
                    <LinkButton size={17} bold onClick={() => p.navigate(p.backParams!)} color="theme3">
                        <IconSvg name="chevronblue" width={15} height={15} />
                        <span>Back to {p.backParams.name.toLocaleLowerCase()}</span>
                    </LinkButton>
                </Header>
            )}
            {p.children}
        </_LayoutContainer>
    )
}

const mapState: MapState<StateProps> = ({ auth: { authentication: loginStatus } }) => {
    let backParams: (NavigationParams & NamedPath) | undefined
    if (loginStatus.type !== "LoggedIn") return { loginStatus, backParams }
    return { loginStatus, backParams }
}

const mapDispatch: MapDispatch<ActionProps> = d => ({
    navigate: params => d(actions.navigate(params)),
    openPopup: popupKey => d(uiActions.openPopup(popupKey)),
    logout: () => d(actions.tryLogout())
})

export const AdminLayoutWrapper = connect(mapState, mapDispatch)(AdminLayout)
