import { Cmd } from "redux-loop"
import { remap, identity } from "../../../functions/src/utils/map"
import { matchHeaderWithCollection } from "../../services/import"
import { isEmpty } from "../../../functions/src/utils/validators"
import { actions } from "./actions"
import { runCloudFunc } from "../cloud/cloudCmds"
import { adminCloudActions } from "../../../functions/src/actions/actionCreators"
import {
    isICEmpty,
    splitCollections,
    resetErrorsInCollectionItem,
    parseCollections,
    removeTagFromItem,
    removeSegmentTagFromItem
} from "./importingUtils"
import { validateItem } from "./importingValidators"
import { UnrecognizedFilePayload, SelectFilesPayload } from "../store"
import {
    PreloadedCollections,
    ImportData,
    ChunkType,
    PreloadedCollection
} from "../../../functions/src/models/importing.types"

export const recognizeFile = (file: File, availableCollections: CName[]) =>
    Cmd.run(() => matchHeaderWithCollection(file, availableCollections), {
        successActionCreator: (res: Result<CName, UnrecognizedFilePayload>) => actions._attachFile(res, file)
    })

export const startPreload = (radarId: string, payload: SelectFilesPayload) =>
    Cmd.run(
        async () => {
            if (isEmpty(payload.recognized)) return
            const parsedCols = await parseCollections(payload)
            return validateItem(parsedCols, radarId, "import")
        },
        { successActionCreator: actions._updatePreloadedCollections }
    )

export const revalidateCollections = (radarId: string, pcs: PreloadedCollections) =>
    Cmd.run(
        async () => {
            const resetValidatedCols = remap(pcs, identity, c => ({
                ...c,
                isValid: true,
                value: resetErrorsInCollectionItem(c!.value)
            }))
            return validateItem(resetValidatedCols, radarId, "import")
        },
        { successActionCreator: actions._updatePreloadedCollections }
    )

export const removeTagFromCollections = (tagName: string, pcs: PreloadedCollections) =>
    Cmd.run(
        async () => {
            return remap(pcs, identity, c => ({
                ...c,
                value: (c as PreloadedCollection<CName>).value.map(removeTagFromItem(tagName))
            }))
        },
        { successActionCreator: actions._updatePreloadedCollections }
    )

export const removeSegmentTagFromCollections = (segmentTagName: string, pcs: PreloadedCollections) =>
    Cmd.run(
        async () => {
            return remap(pcs, identity, c => ({
                ...c,
                value: (c as PreloadedCollection<CName>).value.map(removeSegmentTagFromItem(segmentTagName))
            }))
        },
        { successActionCreator: actions._updatePreloadedCollections }
    )

export const importNextChunk = (senderId: string, radarId: string, payload: ImportData, chunkNo: number) =>
    Cmd.run(
        async () => {
            const importingType: ChunkType = isICEmpty(payload.update) ? "create" : "update"
            const { rest, ...chunk } = splitCollections(payload[importingType], chunkNo)
            const chunkPayload = { ...chunk, senderId, radarId, type: importingType }
            await runCloudFunc("adminActions", senderId, radarId, adminCloudActions.importChunk(chunkPayload))
            return { ...payload, [importingType]: rest }
        },
        {
            successActionCreator: actions._chunkImportFinished,
            failActionCreator: actions._chunkImportFailed
        }
    )
