import { isString, Err, Ok, isNumber, isEmpty } from "../utils/validators"
import { keys, isKeyOf, remap } from "../utils/map"
import { DEFAULT_PLACEHOLDER } from "./shared"

const mmap: SMap<number> = { k: 3, m: 6, b: 9, t: 12 }
const abbrs = ["", ...keys(mmap)]

const PRIME_MARKER = 524287

export const specialValues = {
    Undisclosed: -1 * PRIME_MARKER,
    "-": -2 * PRIME_MARKER,
    "–": -2 * PRIME_MARKER,
    empty: -3 * PRIME_MARKER
}
export const specialValuesRev = remap(
    specialValues,
    (_, v) => v.toString(),
    (_, k) => (k === "empty" ? "" : k)
)
const numberRegExp = new RegExp(`[$,${keys(mmap).join(",")},%,-,–]`, "g")

export const convertNumber = (v = ""): number => {
    if (v === "" || v === null) return specialValues.empty
    if (!isString(v)) return v
    if (isKeyOf(v, specialValues)) return specialValues[v]
    const key = keys(mmap).find(k => new RegExp(`^.*[${k}${k.toUpperCase()}]`).test(v))
    const value = parseFloat(v.replace(numberRegExp, "").trim()) || 0
    return key ? value * Math.pow(10, mmap[key]) : value
}

export const parseNumber = (v: any, msg: string): Result<number> =>
    isString(v) ? Ok(convertNumber(v)) : Err("Invalid string", msg)

export const upToDecimal = (v: number, d: number) =>
    Math.round((v + Number.EPSILON) * Math.pow(10, d)) / Math.pow(10, d)

export const toFixed = (num: number, decimals: number) => upToDecimal(num, decimals).toFixed(decimals)
export const formatWithCommas = (num: string) => {
    const [n, d] = num.split(".")
    return `${(n.match(/\d{1,3}(?=(\d{3})*$)/g) || [n]).join(",")}${d ? `.${d}` : ""}`
}

export const formatNumber = (v: any, withSuffix = true, decimals = 2, fixed = false) => {
    if (isEmpty(v)) return ""
    if (!isNumber(v)) return `${v}`
    if (isKeyOf(v.toString(), specialValuesRev)) return specialValuesRev[v.toString()]
    const sign = v < 0 ? "-" : ""
    const av = Math.abs(v)
    const index = av > 0 ? Math.floor(Math.log(av) / Math.log(1000)) : 0
    const abbr = !withSuffix || index < 0 || index > abbrs.length - 1 ? "" : abbrs[index]

    const formatter = fixed ? toFixed : (n: number, d: number) => upToDecimal(n, d).toString()
    const result =
        withSuffix && av > 1000
            ? formatter(av / Math.pow(1000, index), decimals)
            : formatWithCommas(formatter(av, decimals))
    return `${sign}${result}${abbr}`
}

export function formatMillions(v: any, withSuffix = true, decimals = 2): string {
    if (!isNumber(v)) return `${v}`
    if (isKeyOf(v.toString(), specialValuesRev)) return specialValuesRev[v.toString()]
    const sign = v < 0 ? "-" : ""
    const av = Math.abs(v)
    const result = toFixed(av / Math.pow(10, 6), decimals)
    return `${sign}${formatWithCommas(result)}${withSuffix ? "m" : ""}`
}

export const formatDollars = (v: any, placeholder: string = DEFAULT_PLACEHOLDER) => {
    if (!v && v !== 0) return placeholder || ""
    if (!isNumber(v)) return `${v}`
    const result = formatNumber(v, true, 2)
    if (keys(specialValues).includes(result as any)) return result
    return `$${result}`
}

export const formatMillionDollars = (v: any, withSuffix = true, decimals = 2) =>
    `$${formatMillions(v, withSuffix, decimals)}`

export const formatDecimal = (v: any, placeholder: string = DEFAULT_PLACEHOLDER, decimals = 2, fixed = false) => {
    const result = formatNumber(v, false, decimals, fixed)
    return placeholder && (!result || result === "0") ? placeholder : result
}

export const formatInteger = (v: any, placeholder: string = DEFAULT_PLACEHOLDER) => {
    const result = formatNumber(v, false, 0)
    return placeholder && (!result || result === "0") ? placeholder : result
}
