/* eslint-disable max-lines */
import { patentFields, patentRelations } from "./Patent"
import { sectorFields, sectorRelations } from "./Sector"
import { influencerFields, influencerRelations } from "./Influencer"
import { startupFields, startupRelations } from "./Startup"
import { techTransferFields, techTransferRelations } from "./TechTransfer"
import { investorFields, investorRelations } from "./Investor"
import { researchHubFields, researchHubRelations } from "./ResearchHub"
import { researchPaperFields, researchPaperRelations } from "./ResearchPaper"
import { techExpertFields, techExpertRelations } from "./TechExpert"
import { patentHoldersFields, patentHolderRelations } from "./PatentHolder"
import {
    PatentFixtures,
    SectorFixtures,
    InfluencerFixtures,
    StartupFixtures,
    TechTransferFixtures,
    InvestorFixtures,
    ResearchHubFixtures,
    ResearchPaperFixtures,
    TechExpertFixtures,
    PatentHolderFixtures,
    GrantFixtures,
    ClinicalTrialFixtures,
    CompanyFixtures,
    SectorVMFixture,
    InfluencerVMFixture,
    StartupVMFixture,
    TechTransferVMFixture,
    InvestorVMFixture,
    ResearchHubVMFixture,
    ResearchPaperVMFixture,
    PatentVMFixture,
    TechExpertVMFixture,
    PatentHolderVMFixture,
    ClinicalTrialVMFixture,
    CompanyVMFixture,
    GrantVMFixture
} from "./collectionFixtures"
import { keys } from "../utils/map"
import { grantRelations, grantsFields } from "./Grant"
import { clinicalTrialFields, clinicalTrialRelations } from "./ClinicalTrial"
import { exhaustiveStringTuple } from "../utils/types"
import { companyFields, companyRelations } from "./Company"
import { ViewModelsMap } from "./ViewModels"
import { mkUniqueOR, mkUniqueValue, UniqueConstraint } from "./uniqueConstraint.types"
import { Icon } from "./icons"
import { FileFields } from "./importing.types"

export const classifiersFields: (keyof ItemClassifiersInput)[] = exhaustiveStringTuple<keyof ItemClassifiersInput>()(
    "primary_search_areas",
    "secondary_search_areas",
    "tags",
    "segment_tags"
)

export const getNameField = <T extends CName>(cname: T) => collections[cname].nameField as keyof RCollection<T>

export const getIdField = <T extends CName>(cname: T) => collections[cname].idField as keyof RCollection<T>

export type CollectionSchema<C extends CName, UC extends UniqueConstraint<keyof RCollection<C> & string, any, any>> = {
    idField: keyof RCollection<C>
    uniqueConstraint: UC
    defaultSortField: keyof RCollection<C>
    sortDirection: SortDirection
    nameField: keyof RCollection<C>
    searchFields: (keyof RCollection<C>)[]
    fields: CFields<C>
    icons: Partial<{ [K in keyof ViewModelsMap[C]]: Icon }>
    relations: RFields<C>
    singleName: string
    collectionName: C
    fixture: RFixtures<C>
    viewFixture: ViewModelsMap[C]
    displayName: string
}

// TODO When we get to the point when nothing else is todo, then think about why those types are not working
export const getCollectionSchema = <
    C extends CName,
    UF extends UniqueConstraint<keyof RCollection<C> & string, any, any>
>(
    cname: C
): CollectionSchema<C, UF> => collections[cname] as any

export const getCollectionFromId = (id: string): CName | null =>
    keys(collections).includes(id.split("-")[0] as any) ? (id.split("-")[0] as any) : null

export const collections: {
    [C in CName]: CollectionSchema<C, UniqueConstraint<keyof RCollection<C> & string, any, any>>
} = {
    startups: {
        displayName: "Startups",
        searchFields: ["startup_name", "description"],
        idField: "startupId",
        uniqueConstraint: mkUniqueOR(mkUniqueValue("crunchbase_url"), mkUniqueValue("homepage_url")),
        nameField: "startup_name",
        singleName: "Startup",
        collectionName: "startups",
        defaultSortField: "funding",
        sortDirection: "desc",
        icons: {
            funding: "cardSections:funding",
            headquarters: "cardSections:headquaters",
            stage: "cardSections:stage",
            lead_investors: "cardSections:customer",
            investor_quality: "cardSections:complete",
            homepage_url: "href",
            logo_url: "href",
            startup_completed: "cardSections:complete",
            crunchbase_url: "href",
            all_investors: "cardSections:customer",
            founders_and_executives: "cardSections:customer",
            funding_momentum: "cardSections:funding-momentum"
        },
        fixture: StartupFixtures,
        viewFixture: StartupVMFixture(),
        fields: startupFields,
        relations: startupRelations
    },
    sectors: {
        displayName: "Sectors",
        searchFields: ["sector_name", "description"],
        idField: "sectorId",
        uniqueConstraint: mkUniqueValue("sector_name"),
        nameField: "sector_name",
        collectionName: "sectors",
        singleName: "Sector",
        defaultSortField: "sector_name",
        sortDirection: "asc",
        icons: {
            funding: "cardSections:funding",
            recent_funding: "cardSections:funding",
            primary_customer: "cardSections:customer",
            product: "cardSections:products",
            geography: "cardSections:earth",
            business_model: "cardSections:business",
            jobs_to_be_done: "cardSections:business",
            investor_quality: "cardSections:complete",
            sector_concentration: "cardSections:smart-money",
            funding_momentum: "cardSections:funding-momentum",
            sector_funding_momentum: "cardSections:funding-momentum",
            avg_company_age: "cardSections:avg-age",
            priority_score: "cardSections:score",
            column: "cardSections:crosshair",
            row: "cardSections:crosshair",
            sector_completed: "cardSections:complete",
            market_size_url: "href",
            source: "href",
            incumbents: "cardSections:person"
        },
        fixture: SectorFixtures,
        viewFixture: SectorVMFixture(),
        fields: sectorFields,
        relations: sectorRelations
    },
    companies: {
        displayName: "Products & Technologies",
        collectionName: "companies",
        searchFields: ["product_technology_name", "description", "company_name"],
        uniqueConstraint: mkUniqueValue("product_technology_url"),
        nameField: "product_technology_name",
        idField: "companyId",
        singleName: "Product & Technology",
        defaultSortField: "product_technology_name",
        sortDirection: "asc",
        icons: {
            company_type: "cardSections:industry",
            company_name: "cardSections:business",
            company_url: "href",
            product_technology_name: "cardSections:name-card",
            product_technology_url: "href",
            active_ingredient: "cardSections:ingredient",
            other_ingredients: "cardSections:business",
            efficacy_ranking: "cardSections:ranking",
            efficacy_source: "href",
            efficacy_source_url: "href",
            moa: "cardSections:funding-momentum",
            crunchbase_url: "href",
            safety_source_url: "href",
            safety_ranking: "cardSections:ranking"
        },
        fixture: CompanyFixtures,
        viewFixture: CompanyVMFixture(),
        fields: companyFields,
        relations: companyRelations
    },
    patents: {
        displayName: "Patents",
        searchFields: ["patent_title", "description"],
        idField: "patentId",
        uniqueConstraint: mkUniqueValue("publication_number"),
        nameField: "patent_title",
        collectionName: "patents",
        singleName: "Patent",
        defaultSortField: "patent_title",
        sortDirection: "asc",
        icons: {
            holder: "cardSections:holder",
            num_citations: "cardSections:bubble",
            filing_location: "cardSections:location",
            publication_number: "cardSections:publication",
            patent_url: "href",
            patent_url_2: "href",
            patent_type: "cardSections:business",
            original_assignee: "cardSections:assignee",
            current_assignee: "cardSections:assignee",
            application_date: "cardSections:calendar",
            publication_date: "cardSections:calendar",
            estimated_expiration_date: "cardSections:calendar"
        },
        fixture: PatentFixtures,
        viewFixture: PatentVMFixture(),
        fields: patentFields,
        relations: patentRelations
    },
    tech_transfers: {
        displayName: "Technology Transfers",
        searchFields: ["tech_of_interest", "description"],
        uniqueConstraint: mkUniqueValue("tech_of_interest"),
        nameField: "tech_of_interest",
        idField: "techTransferId",
        singleName: "Tech Transfer",
        collectionName: "tech_transfers",
        defaultSortField: "tech_of_interest",
        sortDirection: "asc",
        icons: {
            research_hub: "search-icon",
            location: "cardSections:location",
            tech_transfer_url: "href"
        },
        fixture: TechTransferFixtures,
        viewFixture: TechTransferVMFixture(),
        fields: techTransferFields,
        relations: techTransferRelations
    },
    clinical_trials: {
        displayName: "Clinical Trials",
        collectionName: "clinical_trials",
        searchFields: ["trial_title", "description"],
        uniqueConstraint: mkUniqueValue("trial_title"),
        nameField: "trial_title",
        idField: "trialId",
        singleName: "Clinical Trial",
        defaultSortField: "trial_title",
        sortDirection: "asc",
        icons: {
            location: "cardSections:location",
            sponsor: "cardSections:money",
            trial_url: "href",
            start_date: "cardSections:university",
            end_date: "cardSections:university",
            authors: "cardSections:customer",
            affiliations: "cardSections:customer",
            study_phase: "cardSections:university"
        },
        fixture: ClinicalTrialFixtures,
        viewFixture: ClinicalTrialVMFixture(),
        fields: clinicalTrialFields,
        relations: clinicalTrialRelations
    },
    research_papers: {
        displayName: "Research Papers",
        searchFields: ["paper_title", "description"],
        uniqueConstraint: mkUniqueValue("paper_title"),
        nameField: "paper_title",
        idField: "researchPaperId",
        singleName: "Research Paper",
        collectionName: "research_papers",
        defaultSortField: "paper_title",
        sortDirection: "asc",
        icons: {
            research_group: "search-icon",
            university: "cardSections:university",
            location: "cardSections:location",
            publication_year: "cardSections:publication",
            actively_involved: "search-icon",
            paper_url: "href"
        },
        fixture: ResearchPaperFixtures,
        viewFixture: ResearchPaperVMFixture(),
        fields: researchPaperFields,
        relations: researchPaperRelations
    },
    grants: {
        displayName: "Grants",
        collectionName: "grants",
        searchFields: ["grant_title", "description"],
        uniqueConstraint: mkUniqueValue("grant_title"),
        nameField: "grant_title",
        idField: "grantId",
        singleName: "Grant",
        defaultSortField: "grant_title",
        sortDirection: "asc",
        icons: {
            funding: "cardSections:funding",
            grant_url: "href",
            group_url: "href",
            location: "cardSections:location",
            research_group: "search-icon",
            university: "cardSections:university",
            years_funded: "cardSections:funding"
        },
        fixture: GrantFixtures,
        viewFixture: GrantVMFixture(),
        fields: grantsFields,
        relations: grantRelations
    },
    investors: {
        displayName: "Investors",
        searchFields: ["investor_name", "description"],
        uniqueConstraint: mkUniqueValue("investor_name"),
        nameField: "investor_name",
        idField: "investorId",
        singleName: "Investor",
        collectionName: "investors",
        defaultSortField: "total_unique_investments",
        sortDirection: "desc",
        icons: {
            number_of_investment_rounds: "cardSections:families",
            amount_invested: "cardSections:money",
            total_unique_investments: "cardSections:funding",
            amount_per_investment: "cardSections:money",
            investor_quality: "cardSections:complete",
            investor_url: "href",
            investments: "cardSections:funding"
        },
        fixture: InvestorFixtures,
        viewFixture: InvestorVMFixture(),
        fields: investorFields,
        relations: investorRelations
    },
    research_hubs: {
        displayName: "Research Hub",
        searchFields: ["research_hub_name", "description"],
        uniqueConstraint: mkUniqueValue("research_hub_url"),
        nameField: "research_hub_name",
        idField: "researchHubId",
        singleName: "Research Hub",
        collectionName: "research_hubs",
        defaultSortField: "research_hub_name",
        sortDirection: "asc",
        icons: {
            university: "cardSections:university",
            location: "cardSections:location",
            num_publications: "cardSections:publication",
            num_citations: "cardSections:bubble",
            citations_per_publication: "cardSections:bubble",
            num_patents: "cardSections:holder",
            active_funding: "cardSections:funding",
            grant_funding: "cardSections:funding",
            research_hub_url: "href",
            num_patent_families: "cardSections:families",
            num_clinical_trials: "cardSections:families",
            num_experts: "cardSections:families"
        },
        fixture: ResearchHubFixtures,
        viewFixture: ResearchHubVMFixture(),
        fields: researchHubFields,
        relations: researchHubRelations
    },
    tech_experts: {
        displayName: "Technology Experts",
        searchFields: ["tech_expert_name", "description"],
        uniqueConstraint: mkUniqueValue("tech_expert_name"),
        nameField: "tech_expert_name",
        idField: "techExpertId",
        singleName: "Tech Expert",
        collectionName: "tech_experts",
        defaultSortField: "tech_expert_name",
        sortDirection: "asc",
        icons: {
            association: "cardSections:customer",
            num_publications: "cardSections:publication",
            num_citations: "cardSections:bubble",
            citations_per_publication: "cardSections:bubble",
            num_patent_families: "cardSections:families",
            num_patents: "cardSections:holder",
            active_funding: "cardSections:funding",
            grant_funding: "cardSections:funding",
            funding_type: "cardSections:funding",
            tech_expert_url: "href",
            num_clinical_trials: "cardSections:families"
        },
        fixture: TechExpertFixtures,
        viewFixture: TechExpertVMFixture(),
        fields: techExpertFields,
        relations: techExpertRelations
    },
    influencers: {
        displayName: "Influencers",
        searchFields: ["influencer_name", "description"],
        uniqueConstraint: mkUniqueValue("influencer_name"),
        nameField: "influencer_name",
        idField: "influencerId",
        singleName: "Influencer",
        collectionName: "influencers",
        defaultSortField: "influencer_name",
        sortDirection: "asc",
        icons: {
            influencer_name: "cardSections:customer",
            location: "cardSections:location",
            type: "cardSections:business",
            num_news_sources: "cardSections:families",
            google_ranking: "cardSections:ranking",
            influencer_url: "href",
            num_publications: "cardSections:publication",
            num_patents: "cardSections:holder",
            num_instagram: "cardSections:follower",
            num_twitter: "cardSections:follower"
        },
        fixture: InfluencerFixtures,
        viewFixture: InfluencerVMFixture(),
        fields: influencerFields,
        relations: influencerRelations
    },
    patent_holders: {
        displayName: "Patent Holders",
        collectionName: "patent_holders",
        searchFields: ["patent_holder", "description"],
        uniqueConstraint: mkUniqueValue("patent_holder"),
        nameField: "patent_holder",
        idField: "patentHolderId",
        singleName: "Patent Holder",
        defaultSortField: "patent_holder",
        sortDirection: "asc",
        icons: {
            num_citations: "cardSections:bubble",
            num_patents: "cardSections:holder",
            industry: "cardSections:industry",
            num_patent_families: "cardSections:families",
            num_patent_family_citations: "cardSections:families",
            patent_holder_url: "href"
        },
        fixture: PatentHolderFixtures,
        viewFixture: PatentHolderVMFixture(),
        fields: patentHoldersFields,
        relations: patentHolderRelations
    }
}

export const cnames = keys(collections)
export const allImportableFields = <C extends CName>(cname: C): FileFields<C> => [
    ...getCollectionSchema(cname).fields.all,
    ...getCollectionSchema(cname).relations.all,
    ...classifiersFields
]
export const isCName = (v: string): v is CName => cnames.includes(v as any)
