import { createGlobalStyle, css } from "styled-components"

const linkStyles = css`
    display: inline-block;
    color: ${p => p.theme.colors.info};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`

export const GlobalStyle = createGlobalStyle`
  body, html {
    position: relative;
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif;
    color: ${({ theme }) => theme.colors.primary};
  }

  #app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  #toasts {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 1002;
  }


  * {
    box-sizing: border-box;
  }

  .hex-text {
    color: #fff;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hex-text p, .hex-text strong {
    margin: 2.5px 0;
  }

  .hex-group {
    cursor: pointer;
  }

  .react-draggable {
    cursor: move;
  }

  .hex-map {
    transition: height 0.3s linear, width 0.3s linear;
  }

  table {
      border-collapse: collapse;
      text-align: left;
      width: 100%;
  }

  table, th, td {
      border: 1px solid #aaa;
  }

  th {
      padding: 10px 4px;
  }

  td {
      padding: 4px;
  }

  a {
    ${linkStyles}
  }
`
