import * as React from "react"
import { render } from "react-dom"

import { Provider } from "react-redux"
import { Switch, Route } from "react-router-dom"
import { ConnectedRouter } from "connected-react-router"

import { getStore, getHistory } from "./store"
import { GlobalStyle } from "./styles/global"
import { ThemeProvider } from "styled-components"
import { theme } from "./styles/themes"

import { SectorsMap } from "./views/SectorsMap"
import {
    detailsPaths,
    radarPaths,
    userPaths,
    adminPaths,
    getDashboardDetailsPathName,
    getListPathName,
    collectionPaths,
    getCollectionDetailsPathName,
    dashboardDetailsPaths,
    getCollectionEditPathName,
    editPaths,
    demoPaths,
    staticPaths
} from "./paths"
import { keys, toMap } from "../functions/src/utils/map"
import { selectCreateDiv } from "./utils/html5"
import { ModalRenderer } from "./containers/ModalRenderer"
import { ListViews } from "./views/lists/Lists"
import { DashboardView } from "./views/DashboardView"
import { RadarLayoutWrapper } from "./views/layouts/RadarLayout"
import { CardViews } from "./views/cards/Cards"
import { EditViews } from "./views/cards/EditForms"
import { AdminDashboard } from "./views/admin/AdminDashboard"
import { AdminLayoutWrapper } from "./views/layouts/AdminLayout"
import { LoginView } from "./views/user/Login"
import { WrappedRoute, ModalRoute } from "./utils/Routes"
import { NotificationRenderer } from "./containers/NotificationRenderer"
import { LogoutView } from "./views/user/Logout"
import { AuthRenderer } from "./containers/AuthRenderer"
import { PopupRenderer } from "./containers/PopupRenderer"
import { DemoView } from "./views/user/DemoView"
import { RadarImportView } from "./views/admin/RadarImport"
import { DemoForms } from "./views/demo/forms"
import { DemoDropdownView } from "./views/demo/dropdowns"
import { NewsfeedView } from "./views/NewsfeedView"
import { cnames } from "../functions/src/models/schemas"
import { PrioritiesImportView } from "./views/admin/PrioritiesImport"
import { isIE, isEdge } from "./utils"
import { IEPopup } from "./components/popups/IE"
import { FederatedLoginView } from "./views/user/FederatedLogin"
import { ReportsView } from "./views/reports/ReportsView"

import * as Sentry from "@sentry/browser"
import ReactGA from "react-ga"
import { init as initAmplitude } from "./tracking"
import { HowToPage } from "./components/importing/ErrorHintModals"
import { SignInWithEmailLink } from "./views/user/SignInWithEmailLink"
import { IntercomHandler } from "./components/IntercomHandler"

function requireAll(r: any) {
    r.keys().forEach(r)
}
requireAll(require.context("./styles/icons", true, /\.svg$/))

if (process.env.DEPLOYMENT_MODE === "prod" && process.env.GA_TRACKING_ID && process.env.SENTRY_DSN) {
    ReactGA.initialize(process.env.GA_TRACKING_ID || "")
    ReactGA.pageview(window.location.pathname + window.location.search)
    Sentry.init({ dsn: process.env.SENTRY_DSN })
}

// If amplitude should only be enabled on production, move this line into the conditional above
initAmplitude(process.env.AMPLITUDE_API_KEY)

export const radarComponents: Casted<typeof radarPaths, React.FC<any>> = {
    "radar/dashboard": DashboardView,
    "radar/newsfeed": NewsfeedView,
    "radar/reports": ReportsView,
    ...(toMap(cnames, getListPathName, cname => ListViews[cname]) as Casted<typeof collectionPaths, React.FC<any>>),
    "radar/radar": SectorsMap
}
export const adminComponents: Casted<typeof adminPaths, React.FC<any>> = {
    "admin/dashboard": AdminDashboard,
    "admin/importRadar": RadarImportView,
    "admin/importPriorities": PrioritiesImportView,
    "admin/radarDashboard": AdminDashboard,
    "admin/newRadar": AdminDashboard
}

export const demoComponents: Casted<typeof demoPaths, React.FC<any>> = {
    "demo/demo": DemoView,
    "demo/demoForms": DemoForms,
    "demo/demoDrops": DemoDropdownView
}

export const userComponents: Casted<typeof userPaths, React.FC<any>> = {
    "user/logout": LogoutView,
    "user/login": LoginView,
    "user/federated": FederatedLoginView,
    "user/adminLogin": LoginView,
    "user/linkLogin": SignInWithEmailLink
}

export const staticComponents: Casted<typeof staticPaths, React.FC> = {
    "static/howToImportData": HowToPage
}

const AdminRoute = WrappedRoute(AdminLayoutWrapper)
const RadarRoute = WrappedRoute(RadarLayoutWrapper)

const Routes: React.FC = () => (
    <>
        <Switch>
            {keys(userPaths).map(k => (
                <Route key={k} {...userPaths[k]} component={userComponents[k]} exact />
            ))}
            {keys(staticPaths).map(k => (
                <Route key={k} {...staticPaths[k]} component={staticComponents[k]} exact />
            ))}
            {keys(adminComponents).map(k => (
                <AdminRoute key={k} {...adminPaths[k]} component={adminComponents[k]} exact />
            ))}
            {keys(radarComponents).map(k => (
                <RadarRoute key={k} {...radarPaths[k]} component={radarComponents[k]} />
            ))}
            {keys(demoComponents).map(k => (
                <AdminRoute key={k} {...demoPaths[k]} component={demoComponents[k]} exact />
            ))}
        </Switch>

        <ModalRenderer>
            <ModalRoute {...detailsPaths["details/sectorsMap"]} component={CardViews.sectors} exact />
            {keys(CardViews).map(k => (
                <ModalRoute key={k} {...detailsPaths[getCollectionDetailsPathName(k)]} component={CardViews[k]} exact />
            ))}
            {keys(CardViews).map(k => (
                <ModalRoute
                    key={k}
                    {...dashboardDetailsPaths[getDashboardDetailsPathName(k)]}
                    component={CardViews[k]}
                    exact
                />
            ))}
            {keys(EditViews).map(k => (
                <ModalRoute key={k} {...editPaths[getCollectionEditPathName(k)]} component={EditViews[k]} exact />
            ))}
        </ModalRenderer>
    </>
)

const App: React.FC = () => {
    if (isIE() || isEdge()) return <IEPopup />
    return (
        <ThemeProvider theme={theme}>
            <Provider store={getStore()}>
                <ConnectedRouter history={getHistory()}>
                    <NotificationRenderer />
                    <PopupRenderer />
                    <IntercomHandler />
                    <AuthRenderer>
                        <Routes />
                    </AuthRenderer>
                </ConnectedRouter>
                <GlobalStyle />
            </Provider>
        </ThemeProvider>
    )
}

render(<App />, selectCreateDiv("app"))

if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept()
}
