import styled from "styled-components"
import { _FlexContainer } from "../../styles/common"
import { _DropdownTrigger, _DropdownWrapper } from "../Dropdown.styles"
import { _FilterContainer } from "./Filter.styles"
import { BaseButton } from "../../styles/buttons"

export const _FiltersColumnContainer = styled(_FlexContainer)`
    padding: 15px 7px 15px 15px;
    background: ${p => p.theme.colors.theme1};
    flex-grow: 1;
    flex-shrink: 0;

    ${_DropdownTrigger} {
        width: 100%;
    }

    ${_DropdownWrapper} {
        flex-grow: 1;

        ${BaseButton} {
            min-width: unset;
        }
    }

    ${_FilterContainer}:not(:empty) {
        margin-bottom: 17px;
    }

    .ant-calendar-range-picker-input {
        flex: 1 1 0;
    }
`
