import * as React from "react"
import { connect } from "react-redux"
import * as Sentry from "@sentry/browser"

import { actions, NavigateActionName } from "../../store/auth/actions"

import { Popup } from "../../components/Popup"
import { PopupInner } from "../../components/Popup.styles"

import { _BodyFont } from "../../styles/typography"
import { _VerticalSpace, _Center } from "../../styles/common"
import { RouterState } from "connected-react-router"
import { Ok, Err, isOk } from "../../../functions/src/utils/validators"
import { SearchParams } from "../../services/urlService"
import { userPaths } from "../../paths"
import { getMapDispatch } from "../../utils/redux"
import { Button, PGButton } from "../../styles/buttons"
import { MapState } from "../../utils/redux.types"
import { getFederatedUrl } from "../../../functions/src/models/Radar"

type StateProps = Result<{ token: string }>
type Props = StateProps & ActionProps
export const FederatedLogin: React.FC<Props> = p => {
    React.useEffect(() => {
        if (isOk(p)) p.tryFederatedLogin(p.value.token)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <Popup logo>
            <PopupInner column>
                {isOk(p) ? (
                    <_Center>We are validating your credentials, this can take a few seconds</_Center>
                ) : (
                    <_Center>
                        <_BodyFont s17 align="center">
                            Something went wrong during your login. Our development team will be notified about this
                            automatically.
                        </_BodyFont>
                        <_VerticalSpace base="16px" />
                        <_BodyFont>You can click one of the buttons below to try again</_BodyFont>
                        <_VerticalSpace base="16px" />
                        <PGButton onClick={() => (window.location.href = getFederatedUrl(process.env.DEPLOYMENT_MODE))}>
                            Go to PingID login page
                        </PGButton>
                        <_VerticalSpace base="16px" />
                        <Button onClick={() => p[NavigateActionName](userPaths["user/adminLogin"])}>
                            Go to standard login page
                        </Button>
                    </_Center>
                )}
            </PopupInner>
        </Popup>
    )
}
const mapState: MapState<StateProps> = ({ router }) => {
    const { location } = router as RouterState
    const path = location.search
    const params = SearchParams<{ auth_token: string }>(path)
    const token = params.get("auth_token")

    if (!token) {
        Sentry.captureException(new Error(`Invalid auth token on Federated Login: ${path}`))
        return Err("Missing/invalid token")
    }
    return Ok({ token })
}

const mapDispatch = getMapDispatch(actions, ["tryFederatedLogin", "navigate"])
type ActionProps = ReturnType<typeof mapDispatch>

export const FederatedLoginView = connect(mapState, mapDispatch)(FederatedLogin)
