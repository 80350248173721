/* eslint-disable max-lines */
import {
    HttpEndpointActionResponse,
    HttpEndpointActionsType,
    HttpEndpointAction,
    HttpEndpointActions,
    HttpEndpointActionPayload
} from "./actions"
import {
    Err,
    isErr,
    Ok,
    runValidatorsRaw,
    validate,
    validateString,
    validString,
    validArrayStringDef,
    validStringDef,
    validStringDefNull,
    validDef,
    validNumberDefNull
} from "../../utils/validators"
import { Filters, TimestampFilterType, PlainRangeFilterType } from "../../models/filtering"
import { Sorting } from "../../models/sorting"
import { DependencySchema } from "../../models/ViewModels"
import { validateExportRequestPayload } from "./export/validators"
import { validateTagCreateMutation } from "../../actions/radar/radarValidators"

export const httpCallsErrors = {
    invalidPayload: "Invalid http call payload",
    invalidResponse: "Invalid http call response",
    notAnAction: "Value is not a http call",
    wrongActionType: "Http call type not recognized",
    noPermission: "No permission for this acition",
    failedHttpRequest: "Http request failed",
    noPermissionFor: (who: string) => `No permission of ${who} for this acition`,
    noUser: "No user in database",
    noConfig: (rid: string) => `Could not find config for radarId: ${rid}`
}

export const validateRequest = <T extends keyof HttpEndpointActionsType>(map: RequestActionValidationMap<T>) => (
    v: any
): Result<HttpEndpointAction<T>> => {
    if (!v.type || `${v.type}`.startsWith("_")) return Err(httpCallsErrors.wrongActionType, v)
    const payload = validateActionPayload(map, v)
    if (isErr(payload)) return Err(httpCallsErrors.invalidPayload, payload)
    return Ok({ type: v.type, payload: payload.value }) as Result<HttpEndpointAction<T>>
}

export const validateActionPayload = (
    map: RequestActionValidationMap<keyof HttpEndpointActionsType>,
    v: any
): Result<HttpEndpointActions["payload"]> => {
    if (!v || !v.type) return Err(httpCallsErrors.notAnAction)
    const validators = map[(v as HttpEndpointActions).type]
    if (!validators) return Err(httpCallsErrors.wrongActionType)
    return runValidatorsRaw(validators, v.payload)
}

// VALIDATORS

export const validateDateFilter = validDef(
    null,
    validate<TimestampFilterType>({
        from: validStringDefNull,
        to: validStringDefNull
    })
)

export const validateRangeFilter = validDef(
    null,
    validate<PlainRangeFilterType>({
        from: validNumberDefNull,
        to: validNumberDefNull,
        min: validNumberDefNull,
        max: validNumberDefNull,
        flavor: validDef("plain", validateString)
    })
)

export const validateStringSearchFilter = validStringDefNull

export const validateFilters = validate<Filters>({
    tags: validArrayStringDef,
    searchArea: validStringDefNull,
    searchAreas: validArrayStringDef,
    stars: validStringDefNull,
    pipelines: validArrayStringDef,
    priorities: validArrayStringDef,
    date: validateDateFilter,
    collections: validArrayStringDef,
    mutationType: validArrayStringDef,
    assignments: validArrayStringDef,
    segmentTags: validArrayStringDef,
    startups: validDef(
        null,
        validate<Required<Filters>["startups"]>({
            startup_name: validateStringSearchFilter,
            sectors: validateStringSearchFilter,
            headquarters: validateStringSearchFilter,
            funding: validateRangeFilter,
            stage: validateStringSearchFilter,
            investor_quality: validateRangeFilter,
            funding_momentum: validateRangeFilter
        })
    ),
    sectors: validDef(
        null,
        validate<Required<Filters>["sectors"]>({
            sector_name: validateStringSearchFilter,
            num_startups: validateRangeFilter,
            funding: validateRangeFilter,
            investor_quality: validateRangeFilter,
            sector_funding_momentum: validateRangeFilter,
            funding_momentum: validateRangeFilter,
            avg_company_age: validateRangeFilter,
            priority_score: validateRangeFilter
        })
    ),
    patents: validDef(
        null,
        validate<Required<Filters>["patents"]>({
            patent_title: validateStringSearchFilter,
            holder: validateStringSearchFilter,
            publication_number: validateStringSearchFilter,
            filing_location: validateStringSearchFilter
        })
    ),
    tech_transfers: validDef(
        null,
        validate<Required<Filters>["tech_transfers"]>({
            tech_of_interest: validateStringSearchFilter,
            research_hub: validateStringSearchFilter,
            location: validateStringSearchFilter
        })
    ),
    investors: validDef(
        null,
        validate<Required<Filters>["investors"]>({
            investor_name: validateStringSearchFilter,
            total_unique_investments: validateRangeFilter,
            number_of_investment_rounds: validateRangeFilter,
            amount_invested: validateRangeFilter,
            amount_per_investment: validateRangeFilter,
            investor_quality: validateRangeFilter
        })
    ),
    research_hubs: validDef(
        null,
        validate<Required<Filters>["research_hubs"]>({
            research_hub_name: validateStringSearchFilter,
            university: validateStringSearchFilter,
            num_publications: validateRangeFilter,
            num_experts: validateRangeFilter,
            num_patent_families: validateRangeFilter,
            num_clinical_trials: validateRangeFilter
        })
    ),
    research_papers: validDef(
        null,
        validate<Required<Filters>["research_papers"]>({
            paper_title: validateStringSearchFilter,
            research_group: validateStringSearchFilter,
            university: validateStringSearchFilter,
            publication_year: validateStringSearchFilter
        })
    ),
    tech_experts: validDef(
        null,
        validate<Required<Filters>["tech_experts"]>({
            tech_expert_name: validateStringSearchFilter,
            association: validateStringSearchFilter,
            num_publications: validateRangeFilter,
            num_patent_families: validateRangeFilter
        })
    ),
    influencers: validDef(
        null,
        validate<Required<Filters>["influencers"]>({
            influencer_name: validateStringSearchFilter,
            type: validateStringSearchFilter,
            num_publications: validateRangeFilter,
            num_instagram: validateRangeFilter,
            num_twitter: validateRangeFilter,
            num_patents: validateRangeFilter,
            num_news_sources: validateRangeFilter
        })
    ),
    patent_holders: validDef(
        null,
        validate<Required<Filters>["patent_holders"]>({
            patent_holder: validateStringSearchFilter,
            industry: validateStringSearchFilter,
            num_patents: validateRangeFilter,
            num_citations: validateRangeFilter,
            num_patent_families: validateRangeFilter,
            num_patent_family_citations: validateRangeFilter
        })
    ),
    grants: validDef(
        null,
        validate<Required<Filters>["grants"]>({
            grant_title: validateStringSearchFilter,
            research_group: validateStringSearchFilter,
            university: validateStringSearchFilter,
            location: validateStringSearchFilter,
            funding: validateRangeFilter
        })
    ),
    clinical_trials: validDef(
        null,
        validate<Required<Filters>["clinical_trials"]>({
            trial_title: validateStringSearchFilter,
            sponsor: validateStringSearchFilter,
            location: validateStringSearchFilter,
            study_phase: validateStringSearchFilter,
            start_date: validateStringSearchFilter,
            end_date: validateStringSearchFilter
        })
    ),
    companies: validDef(
        null,
        validate<Required<Filters>["companies"]>({
            product_technology_name: validateStringSearchFilter,
            company_name: validateStringSearchFilter,
            company_type: validateStringSearchFilter,
            active_ingredient: validateStringSearchFilter
        })
    )
})

export const validateSearchRequest = validate<HttpEndpointActionPayload<"search">>({
    phrase: [validateString],
    radarId: validString,
    cname: validStringDef
})

// TODO Add proper validators
export const validatePipelineStageVsSearchAreaReportRequest = validate<
    HttpEndpointActionPayload<"pipelineStageVsSearchAreaReport">
>({
    filters: [validateFilters],
    radarId: validString
})

export const validatePipelineStageVsCollectionReportRequest = validate<
    HttpEndpointActionPayload<"pipelineStageVsCollectionReport">
>({
    filters: [validateFilters],
    radarId: validString
})

export const validateMovedAlongThePipelineReportRequest = validate<
    HttpEndpointActionPayload<"movedAlongThePipelineReport">
>({
    filters: [validateFilters],
    radarId: validString
})

export const validateArchiveRationaleReportRequest = validate<HttpEndpointActionPayload<"archiveRationaleReport">>({
    filters: [validateFilters],
    radarId: validString
})

export const validateLeadProgressionVsCollectionReportRequest = validate<
    HttpEndpointActionPayload<"leadProgressionVsCollectionReport">
>({
    filters: [validateFilters],
    radarId: validString
})

export const validateLeadProgressionVsMonthReportRequest = validate<
    HttpEndpointActionPayload<"leadProgressionVsMonthReport">
>({
    filters: [validateFilters],
    radarId: validString,
    timezone: validString
})

// const validatePageDetails = validate<PageDetails>({
//     startingAfter: validNumberDef,
//     endingBefore: validNumberDef,
//     limit: validNumber
// })
const validateDataCursor = Ok as Validator<Cursor>
const validateDataSchema = Ok as Validator<DependencySchema>
const validateDataSorting = Ok as Validator<Sorting>

// TODO ^^ Add complex validation see: validateMutateTag

export const validateDataRequest = validate<HttpEndpointActionPayload<"data">>({
    radarId: [validateString],
    cursor: [validateDataCursor],
    filters: [validateFilters],
    schema: [validateDataSchema],
    sorting: [validateDataSorting]
})

const validateDashboardSummaryRequest = validate<HttpEndpointActionPayload<"dashboardSummary">>({
    radarId: validString,
    filters: [validateFilters]
})

const validateMergeTagsRequest = validate<HttpEndpointActionPayload<"mergeTags">>({
    radarId: validString,
    tagsToMerge: validArrayStringDef,
    newTag: [validateTagCreateMutation]
})

// VALIDATORS MAP
export type RequestActionValidationMap<T extends keyof HttpEndpointActionsType> = Casted<
    HttpEndpointActionsType,
    Validators<HttpEndpointActionPayload<T>>
>

export type ResponseActionValidationMap<T extends keyof HttpEndpointActionsType> = Casted<
    HttpEndpointActionsType,
    Validators<HttpEndpointActionResponse<T>>
>

export const requestValidationMap: RequestActionValidationMap<keyof HttpEndpointActionsType> = {
    search: [validateSearchRequest],
    export: [validateExportRequestPayload],
    dashboardSummary: [validateDashboardSummaryRequest],
    pipelineStageVsSearchAreaReport: [validatePipelineStageVsSearchAreaReportRequest],
    pipelineStageVsCollectionReport: [validatePipelineStageVsCollectionReportRequest],
    movedAlongThePipelineReport: [validateMovedAlongThePipelineReportRequest],
    archiveRationaleReport: [validateArchiveRationaleReportRequest],
    leadProgressionVsCollectionReport: [validateLeadProgressionVsCollectionReportRequest],
    leadProgressionVsMonthReport: [validateLeadProgressionVsMonthReportRequest],
    data: [validateDataRequest],
    importValidateItems: [],
    importValidatePriorities: [],
    exportContactedLeads: [validateExportRequestPayload],
    devTest: [],
    clearCache: [],
    mergeTags: [validateMergeTagsRequest]
}
