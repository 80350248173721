import styled from "styled-components"
import { _FlexContainer } from "../../../styles/common"
import { _RichTextDescriptionContainer } from "../../form/RichEditor.styles"

export const _InfoCardSectionContainer = styled(_FlexContainer)`
    ${_RichTextDescriptionContainer} {
        .DraftEditor-root {
            font-size: 16px;
        }
    }
`
