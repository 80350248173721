import * as React from "react"
import { connect } from "react-redux"
import { _TagFont, _BodyFont } from "../../styles/typography"
import { isEmpty } from "../../../functions/src/utils/validators"
import { _TagsContainer, _TagBase } from "./SegmentTags.styles"
import { SegmentTagsEditorStateProps, SegmentTagsEditorOwnProps, segmentTagEditorMapState } from "./SegmentTagsEditor"
import { Loader } from "../common/Loader"
import { displaySegmentTag } from "../../../functions/src/models/tags"

export const SegmentTag: React.FC<{ tag: SegmentTag; searchAreas: TMap<AreaId, SearchArea> }> = p => (
    <_TagBase key={p.tag.segmentId}>{displaySegmentTag(p.searchAreas)(p.tag)}</_TagBase>
)

export const _CardSegmentTagsPreview: React.FC<SegmentTagsEditorStateProps & SegmentTagsEditorOwnProps> = p => {
    if (p.loading) return <Loader />

    return (
        <>
            <_TagFont>Segment Tags</_TagFont>
            <_TagsContainer>
                {isEmpty(p.assignedTags) ? (
                    <_BodyFont s14>This item does not have segment tags at this moment</_BodyFont>
                ) : (
                    p.assignedTags.map(segmentTag => (
                        <SegmentTag key={segmentTag.segmentId} tag={segmentTag} searchAreas={p.objectSearchAreas} />
                    ))
                )}
            </_TagsContainer>
        </>
    )
}

export const TooltipSegmentTagsPreview: React.FC<{
    tags: SegmentTag[]
    searchAreas: TMap<AreaId, SearchArea>
}> = p => (
    <>
        <_TagFont>Segment Tags</_TagFont>
        <_TagsContainer>
            {isEmpty(p.tags) ? (
                <_BodyFont s14>This item does not have segment tags at this moment</_BodyFont>
            ) : (
                p.tags.map(tag => <SegmentTag tag={tag} key={tag.segmentId} searchAreas={p.searchAreas} />)
            )}
        </_TagsContainer>
    </>
)

export const CardSegmentTagsPreview = connect(segmentTagEditorMapState)(_CardSegmentTagsPreview)
