import styled from "styled-components"
import { FlexInline } from "../styles/common"

export const SidebarContainer = styled(FlexInline)<{ open: boolean }>`
    flex-direction: column;
    padding: 16px 0 16px 16px;
    border-right: 1px solid ${({ theme }) => theme.colors.theme2};
    position: relative;
    width: ${({ theme, open }) => (open ? theme.sizes.sidebarWidth : 15)}px;
    flex-shrink: 0;
`

export const SidebarHeader = styled.header`
    margin-bottom: 25px;
`

export const ToggleSidebar = styled.div`
    position: absolute;
    top: 12px;
    right: -13px;
    z-index: 4;
`

export const ToggleSidebarButton = styled.button<{ twisted: boolean }>`
    border: 0;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transform: rotate(${({ twisted }) => (twisted ? 180 : 0)}deg);
    padding: 0;
`

export const ScrollableArea = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
`
