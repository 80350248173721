import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/performance"
import "firebase/functions"
import { initializeApp, firestore, storage, functions } from "firebase/app"
import { initOfType } from "../../functions/src/services/firebase/firestore"
import * as auth from "./auth"
import * as storageService from "./storage"
import * as functionsService from "./functions"
import { Firestore } from "../../functions/src/services/firebase"

let _firebase: {
    firestore: Firestore
    auth: auth.AuthService
    storage: storageService.StorageService
    functions: functionsService.FunctionsService
}
export const getFirebase = () => {
    if (_firebase) return _firebase
    initializeApp(getConfig())
    _firebase = {
        auth: auth.init(),
        firestore: initOfType(firestore() as any, "web"),
        functions: functionsService.init(functions()),
        storage: storageService.init(storage())
    }
    return _firebase
}

export const getConfig = () => ({
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID
})

export type FirebaseConfig = ReturnType<typeof getConfig>
