import styled from "styled-components"

export const _InfoPanel = styled.div`
    width: 489px;
    display: flex;
    align-content: stretch;
    padding: 30px 13px;
    border-radius: 4px;
    border: solid 1px #f0f1f2;
    background-color: #fafbfc;

    & > * + * {
        margin-left: 8px;
    }
`

export const _InfoPanelDetails = styled.div`
    flex: 1 0 0%;
`

export const _InfoLink = styled.a`
    cursor: pointer;
    font-size: 15px;
    color: #4ece3d;
    text-decoration: none;
    padding-bottom: 2px;
    border-bottom: dashed 1px #4ece3d;

    &:active {
        border-bottom: dashed 1px #4ece3d;
    }
    &:visited {
        border-bottom: dashed 1px #4ece3d;
    }
`

export const _InfoPanelIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 1 auto;
    align-self: baseline;

    background: rgb(232, 226, 248);
    padding: 14px;
    border-radius: 12px;
`

export const _InfoPanelTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    color: ${({ theme }) => theme.colors.primary};
`
