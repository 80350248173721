import * as React from "react"
import { parse } from "papaparse"
import { ViewModels, StartupVM, SectorVM } from "../../../functions/src/models/ViewModels"
import { StyledGridTable } from "./CardPrioritization"
import {
    _CardSectionContainer,
    _CardSectionTableContainer,
    _CardSectionTitle
} from "../../components/newCards/sections/CardSection.styles"
import { asyncWithDefault } from "../../../functions/src/utils/types"

// Based on, but different from the one in prioritization/styles

const PrioritizationWrapper = (p: any) => (
    <_CardSectionContainer style={{ padding: "0 50px", marginTop: "10px" }}>
        <_CardSectionTableContainer style={{ minHeight: "unset" }}>{p.children}</_CardSectionTableContainer>
    </_CardSectionContainer>
)

const TinyPrioritizationTable = (p: any) => {
    return (
        <PrioritizationWrapper>
            <StyledGridTable alternate csv={p.prioritization} navigateToStartup={() => 1} />
        </PrioritizationWrapper>
    )
}

// NOTE: this is a de-facto component
export const startupPrioritization = (viewModels: ViewModels, item: any) => {
    // We only call this for startups
    const startup = item as StartupVM
    const sectors = asyncWithDefault<any>(viewModels.sectors, {}) as Record<string, SectorVM>
    const sector = Object.entries(sectors).find(
        ([, sec]) => sec.prioritization !== null && (sec.prioritization as string[]).length > 0
    )?.[1]

    if (sector === undefined) return null

    const prioritization = sector.prioritization
    const result = parse(prioritization.join("\n"), { header: true })
    const index = result.data.findIndex(row => row["Crunchbase URL"] == startup.crunchbase_url)
    if (index === -1) return null
    const csv = [prioritization[0], prioritization[index + 1]]

    return <TinyPrioritizationTable prioritization={csv} />
}
