import * as React from "react"
import { _HorizontalSpace, _VerticalSpace, _AbsPosContainer, _AbsPosElement, _FlexColumn } from "../../styles/common"
import {
    _CommentWrapper,
    _CommentAvatar,
    _CommentInnerWrapper,
    _RadarItemComments,
    _CreateCommentContainer
} from "./comment.styles"
import { validString } from "../../../functions/src/utils/validators"
import { Join } from "../../utils/reactUtils"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { RCommentText, CommentForm, CommentPreview, CommentActions } from "./CommentParts"
import { CommentsVM } from "../../../functions/src/models/ViewModels"
import { AuthenticatedState } from "../../models/auth"
import { IconSvg } from "../IconSvg"
import { Icon } from "../../../functions/src/models/icons"

export type CommentActions = RadarCommentsActions
export type CommentProps = {
    editable: boolean
    deletable?: boolean
    objectId: string
    radarId: string
    comment: ObjectComment
    actionsResults: SMap<CloudActionResult>
}
const schema: FormSchema<RCommentText> = { text: { type: "textarea", validators: validString } }

export const RadarComment: React.FC<CommentProps & CommentActions> = p => {
    const [editing, setEditing] = React.useState(false)
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [, onDelete] = useCloudAction(
        actionId => {
            setIsSubmitting(true)
            p.onDelete({ actionId, commentId: p.comment.commentId, objectId: p.objectId, radarId: p.radarId })
        },
        p.actionsResults,
        () => setIsSubmitting(false)
    )

    const [, onEdit] = useCloudAction<RCommentText>(
        (actionId, { text }) => {
            setIsSubmitting(true)
            p.onEdit({
                actionId,
                commentId: p.comment.commentId,
                text,
                objectId: p.objectId,
                radarId: p.radarId,
                meta: p.comment.meta
            })
        },
        p.actionsResults,
        () => {
            setIsSubmitting(false)
            setEditing(false)
        }
    )
    // TODO: use binded from CardTagsEditor ^^

    const commentIcon: TMap<Type<CommentMeta>, Icon> = {
        assignment: "comment-assign",
        contactedLead: "comment-lead",
        pipeline: "comment-stage"
    }
    return (
        <_CommentWrapper>
            {p.comment.meta ? (
                <IconSvg name={commentIcon[p.comment.meta.type]} width={36} height={36} />
            ) : (
                <_CommentAvatar editable={p.editable}>{p.comment.userName[0]}</_CommentAvatar>
            )}
            <_HorizontalSpace base="28px" />
            <_CommentInnerWrapper>
                {editing ? (
                    <CommentForm
                        isSubmitting={isSubmitting}
                        schema={schema}
                        onCancel={() => setEditing(false)}
                        onSubmit={onEdit}
                        mode="edit"
                        initialValue={{
                            text: p.comment.text
                        }}
                    />
                ) : (
                    <CommentPreview comment={p.comment} isSubmitting={isSubmitting}>
                        <CommentActions
                            editable={p.editable}
                            deletable={p.deletable}
                            onEdit={() => setEditing(true)}
                            onDelete={onDelete}
                        />
                    </CommentPreview>
                )}
            </_CommentInnerWrapper>
        </_CommentWrapper>
    )
}

export type RadarCommentsProps = {
    comments: CommentsVM
    auth: AuthenticatedState
    objectId: string
    radarId: string
    actionsResults: SMap<CloudActionResult>
}
export type RadarCommentsActions = {
    onCreate: F1<OnAction<CommentCreatePayload>>
    onEdit: F1<OnAction<CommentEditPayload>>
    onDelete: F1<OnAction<CommentDeletePayload>>
}

export const Comments: React.FC<RadarCommentsProps & RadarCommentsActions> = p => (
    <_AbsPosContainer>
        <_AbsPosElement>
            <_FlexColumn grow={1}>
                <Join items={p.comments} renderJoining={() => <_VerticalSpace base="32px" />}>
                    {c => (
                        <RadarComment
                            key={c.commentId}
                            {...p}
                            editable={p.auth.user.userId === c.userId}
                            deletable={c.desc ? false : undefined}
                            comment={c}
                        />
                    )}
                </Join>
            </_FlexColumn>
        </_AbsPosElement>
    </_AbsPosContainer>
)

export const RadarItemComments: React.FC<RadarCommentsProps & RadarCommentsActions> = p => {
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [, onCreate] = useCloudAction<RCommentText>(
        (actionId, { text }) => {
            setIsSubmitting(true)
            p.onCreate({ actionId, text, objectId: p.objectId, meta: null, radarId: p.radarId })
        },
        p.actionsResults,
        () => setIsSubmitting(false)
    )

    return (
        <_RadarItemComments>
            <_CreateCommentContainer>
                <CommentForm
                    schema={schema}
                    onSubmit={onCreate}
                    initialValue={{ text: "" }}
                    isSubmitting={isSubmitting}
                    mode="create"
                />
            </_CreateCommentContainer>
            <Comments {...p} />
        </_RadarItemComments>
    )
}
