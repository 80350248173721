import * as React from "react"
import { exportCollection, exportContactedLeads } from "../../services/export"
import {
    ExportIdsRequest,
    ExportAllRequest,
    ExportFormatExt,
    ExportDataType
} from "../../../functions/src/services/httpEndpoint/export"

export const useExport = (radarId: string) => {
    const [exporting, setExporting] = React.useState(false)
    const exp = async (
        format: ExportFormatExt,
        payload: ExportIdsRequest | ExportAllRequest,
        dataType?: ExportDataType
    ) => {
        setExporting(true)
        if (dataType === "contactedLeads") {
            await exportContactedLeads(format, radarId, payload)
        } else {
            await exportCollection(format, radarId, payload)
        }
        setExporting(false)
    }
    return { exporting, doExport: exp }
}
