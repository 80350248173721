import styled from "styled-components"

export const _AdminNavigationContainer = styled.div`
    background: #fafafc;
    overflow-y: auto;
    position: relative;
    width: 208px;
    min-width: 208px;
`

export const _AdminNavigationContent = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`

export const _AdminNavigationHeading = styled.div`
    display: flex;
    align-items: center;
    padding: 32px 16px;
    margin: 0;
`

export const _AdminNavigationTitle = styled.div`
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
`

export const _AdminNavigationExpandButton = styled.span`
    flex: 1;
    text-align: right;
    font-size: 14px;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
`

export const _AdminAddRadar = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #0093ee;
    cursor: pointer;
    padding: 4px 8px 4px 40px;
`

export const _NewRadar = styled.div`
    padding: 4px 8px 4px 36px;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    background: #f0f0f4;
    border-left: 4px solid #9494a8;
    border-radius: 0 4px 4px 0;
    padding-left: 36px;
`
