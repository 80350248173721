import { keys, values } from "../../../functions/src/utils/map"
import { isEmpty } from "../../../functions/src/utils/validators"
import { SearchParams } from "../../services/urlService"
import { collections } from "../../../functions/src/models/schemas"
import { exhaustiveStringTuple } from "../../../functions/src/utils/types"
import { SortingKey, SortingParams, GenericSortingKey, sortingDirection } from "../../../functions/src/models/sorting"
import { UIState } from "../store"
import {
    Filters,
    getSearchStringCollectionFieldFilterKey,
    TimestampFilterType,
    RangeFilterType
} from "../../../functions/src/models/filtering"
import { assertNever } from "../../../functions/src/utils"
import { isNumber } from "lodash"
import {
    CollectionFieldsFilterTypes,
    collectionFieldsFilterTypes,
    FieldFilterType,
    isRangeFlavor
} from "../../../functions/src/models/filtering/collectionFieldsFilters"
import { typeToFlavor } from "../../components/filters/controls/CollectionFieldsFilter"

export type URLUIParams = {
    tags: string // tags.join(",")
    segmentTags: string // segmentTags.join(",")
    area: string
    areas: string // areas.join(",")
    star: string // true false
    pipelines: string
    priorities: string
    collections: string
    collection: string
    date: string
    mutationType: string
    assignments: string

    // IMPORTANT: There are more keys here connected with collection fields
    // eg. startups__startup_name: string, sectors__sector_name: string, ...
    // It is required to upgrade to Typescript > 4.1 for template literal types to shortly describe that
} & { [P in keyof RadarCollections]: string } // Sorting key:direction,key:direction

export const pathToSortingParams = <T extends keyof RadarCollections>(
    path: string,
    collectionName: T
): SortingParams<T>[] => {
    const urlParams = SearchParams<URLUIParams>(path)
    const spl = (urlParams.get(collectionName) || "").split(",").filter(v => !isEmpty(v))
    if (!spl.length) return []
    return spl
        .map(sp => {
            const [key, dir] = sp.split(":") as [SortingKey<T>, SortDirection]
            if (!isValidSortingKey(collectionName, key)) return {}
            return { sortingAsc: dir === "asc", sortingKey: key }
        })
        .filter(v => !isEmpty(v))
}

export const normalizeToUrl = (arr: string[]): string | null =>
    isEmpty(arr) ? null : arr.map(p => p.replace(/,/g, "*")).join(",")
export const denormalizeFromURL = (s: string | null): string[] =>
    s ? s.split(",").map(t => t.replace(/\*/g, ",")) : []

// Filters
export const getSearchStringFromRangeFilter = (v: RangeFilterType): string | null => {
    const from = v.from === null ? "" : `${v.from}`
    const to = v.to === null ? "" : `${v.to}`

    if (from || to) return `${from};${to}`
    return null
}

export const getRangeFilterFromSearchString = (
    s: string | null,
    fieldType: FieldFilterType,
    currentFilter?: RangeFilterType
): RangeFilterType | null => {
    if (!s) return null
    const [rawFrom, rawTo] = s.split(";")
    const f = rawFrom && +rawFrom
    const t = rawTo && +rawTo
    return {
        from: isNumber(f) && !Number.isNaN(f as number) ? f : null,
        to: isNumber(t) && !Number.isNaN(f as number) ? t : null,
        min: currentFilter?.min ?? null,
        max: currentFilter?.max ?? null,
        flavor: currentFilter?.flavor || typeToFlavor(fieldType)
    }
}

export const getSearchStringFromTimestampFilter = (v: TimestampFilterType | null | undefined): string | null => {
    if (!v) return null
    const from = v.from === null ? "" : `${v.from}`
    const to = v.to === null ? "" : `${v.to}`

    if (from || to) return `${from};${to}`
    return null
}

export const getTimestampFilterFromSearchString = (s: string | null): TimestampFilterType | null => {
    if (!s) return null
    const [rawFrom, rawTo] = s.split(";")
    const from = rawFrom || null
    const to = rawTo || null
    return {
        from,
        to
    }
}

export const setSearchStringsForCollectionFilter = (
    c: CName & keyof Filters,
    urlParams: SearchParams<URLUIParams>,
    f: Filters[CName & keyof Filters]
) => {
    // Typescript cannot get proper types
    const filters = keys(collectionFieldsFilterTypes[c])

    const isRangeFilterType = (
        v: any,
        k: keyof NonNullable<Required<Filters>[CName & keyof Filters]>
    ): v is RangeFilterType => isRangeFlavor(collectionFieldsFilterTypes[c as CName & keyof Filters]?.[k])
    const isTimestampFilterType = (
        v: any,
        k: keyof NonNullable<Required<Filters>[CName & keyof Filters]>
    ): v is TimestampFilterType =>
        (collectionFieldsFilterTypes[c as CName & keyof Filters]?.[k] as FieldFilterType) === "timestamp"

    filters.forEach(k => {
        const v = f && f[k]
        const paramsKey = getSearchStringCollectionFieldFilterKey(c, k)
        if (!v) {
            urlParams.set(paramsKey as keyof URLUIParams, null)
            return
        } else if (typeof v === "string") {
            urlParams.set(paramsKey as keyof URLUIParams, v)
            return
        } else if (isRangeFilterType(v, k)) {
            urlParams.set(paramsKey as keyof URLUIParams, getSearchStringFromRangeFilter(v))
            return
        } else if (isTimestampFilterType(v, k)) {
            urlParams.set(paramsKey as keyof URLUIParams, getSearchStringFromTimestampFilter(v))
            return
        }
        assertNever(v)
    })
}

export const getCollectionFilterFromSearchStrings = <C extends keyof Filters & CName>(
    c: C,
    urlParams: SearchParams<URLUIParams>,
    currentFilters: Filters
): Required<Filters>[C] => {
    const fields = keys(collectionFieldsFilterTypes[c])

    const isRangeFilterType = (k: keyof CollectionFieldsFilterTypes[C]) =>
        isRangeFlavor((collectionFieldsFilterTypes[c][k] as unknown) as string)
    const isTimestampFilterType = (k: keyof CollectionFieldsFilterTypes[C]) =>
        ((collectionFieldsFilterTypes[c][k] as unknown) as string) === "timestamp"

    const filter: NonNullable<Required<Filters>[C]> = fields.reduce((acc, f) => {
        const v = urlParams.get(getSearchStringCollectionFieldFilterKey(c, f) as keyof URLUIParams)

        if (!v) {
            acc[f] = null
        } else if (isRangeFilterType(f)) {
            acc[f] = getRangeFilterFromSearchString(
                v,
                (collectionFieldsFilterTypes[c][f] as unknown) as FieldFilterType,
                (currentFilters as any)[c]?.[f]
            ) as any
        } else if (isTimestampFilterType(f)) {
            acc[f] = getTimestampFilterFromSearchString(v) as any
        } else {
            acc[f] = v as any
        }

        return acc
    }, {} as any)

    return values(filter).every(v => v === null) ? null : filter
}

export const paramsToPath = ({ filters, sorting }: UIState) => {
    const {
        tags: tagFilters,
        searchArea: searchAreaFilter,
        searchAreas: searchAreasFilter,
        stars: starFilter,
        pipelines: pipelineFilters,
        priorities: priorityFilters,
        collections: filteredCollections,
        date,
        mutationType,
        assignments,
        segmentTags
    } = filters
    const urlParams = SearchParams<URLUIParams>(location.search)
    urlParams.set("tags", normalizeToUrl(tagFilters || []))
    urlParams.set("area", searchAreaFilter || null)
    urlParams.set("areas", normalizeToUrl(searchAreasFilter || []))
    urlParams.set("priorities", normalizeToUrl(priorityFilters || []))
    urlParams.set("pipelines", normalizeToUrl(pipelineFilters || []))
    urlParams.set("star", starFilter ? (starFilter === "Starred").toString() : null)
    urlParams.set("collections", normalizeToUrl(filteredCollections || []))
    urlParams.set("date", getSearchStringFromTimestampFilter(date))
    urlParams.set("mutationType", normalizeToUrl(mutationType || []))
    urlParams.set("assignments", normalizeToUrl(assignments || []))
    urlParams.set("segmentTags", normalizeToUrl(segmentTags || []))
    setSearchStringsForCollectionFilter("startups", urlParams, filters.startups)
    setSearchStringsForCollectionFilter("sectors", urlParams, filters.sectors)
    setSearchStringsForCollectionFilter("patents", urlParams, filters.patents)
    setSearchStringsForCollectionFilter("tech_transfers", urlParams, filters.tech_transfers)
    setSearchStringsForCollectionFilter("tech_experts", urlParams, filters.tech_experts)
    setSearchStringsForCollectionFilter("investors", urlParams, filters.investors)
    setSearchStringsForCollectionFilter("research_hubs", urlParams, filters.research_hubs)
    setSearchStringsForCollectionFilter("research_papers", urlParams, filters.research_papers)
    setSearchStringsForCollectionFilter("influencers", urlParams, filters.influencers)
    setSearchStringsForCollectionFilter("patent_holders", urlParams, filters.patent_holders)
    setSearchStringsForCollectionFilter("grants", urlParams, filters.grants)
    setSearchStringsForCollectionFilter("clinical_trials", urlParams, filters.clinical_trials)
    setSearchStringsForCollectionFilter("companies", urlParams, filters.companies)

    // TODO Make it separate function & test
    keys(sorting).forEach(k => {
        const urlSP = (sorting[k] as { sortingKey?: any; sortingAsc?: any }[] | undefined)
            ?.filter(sp => sp.sortingKey)
            .map(sp => {
                const sdir: SortDirection = sp.sortingAsc ? sortingDirection.asc : sortingDirection.desc
                return `${sp.sortingKey}:${sdir}`
            })
            .join(",")
        if (!isEmpty(urlSP)) urlParams.set(k, urlSP)
    })
    return urlParams.toURL()
}

export const isValidSortingKey = <T extends keyof RadarCollections>(
    collectionName: T,
    value: any
): value is SortingKey<T> => {
    const sortingKeys = exhaustiveStringTuple<GenericSortingKey>()(
        "assignment",
        "pipelineStage",
        "priorityRank",
        "star"
    )
    return (
        sortingKeys.includes(value) ||
        (keys(collections[collectionName].viewFixture) as (keyof T)[]).includes(value as any)
    )
}

export const pathToFilterParams = (path: string, currentFilters: Filters): Required<Filters> => {
    const urlParams = SearchParams<URLUIParams>(path)
    const mTags = urlParams.get("tags")
    const mSegmentTags = urlParams.get("segmentTags")
    const mSearchArea = urlParams.get("area")
    const mSearchAreas = urlParams.get("areas")
    const mStarred = urlParams.get("star")
    const mPipelines = urlParams.get("pipelines")
    const mPriorities = urlParams.get("priorities")
    const mCollections = urlParams.get("collections")
    const mDate = urlParams.get("date")
    const mMutationType = urlParams.get("mutationType")
    const mAssignments = urlParams.get("assignments")

    return {
        tags: denormalizeFromURL(mTags),
        searchArea: mSearchArea || null,
        searchAreas: denormalizeFromURL(mSearchAreas),
        stars: mStarred ? (mStarred === "true" ? "Starred" : "Not starred") : null,
        pipelines: denormalizeFromURL(mPipelines),
        priorities: denormalizeFromURL(mPriorities),
        collections: denormalizeFromURL(mCollections) as CName[],
        date: getTimestampFilterFromSearchString(mDate),
        mutationType: denormalizeFromURL(mMutationType),
        assignments: denormalizeFromURL(mAssignments),
        segmentTags: denormalizeFromURL(mSegmentTags),
        startups: getCollectionFilterFromSearchStrings("startups", urlParams, currentFilters),
        sectors: getCollectionFilterFromSearchStrings("sectors", urlParams, currentFilters),
        patents: getCollectionFilterFromSearchStrings("patents", urlParams, currentFilters),
        tech_transfers: getCollectionFilterFromSearchStrings("tech_transfers", urlParams, currentFilters),
        tech_experts: getCollectionFilterFromSearchStrings("tech_experts", urlParams, currentFilters),
        investors: getCollectionFilterFromSearchStrings("investors", urlParams, currentFilters),
        research_hubs: getCollectionFilterFromSearchStrings("research_hubs", urlParams, currentFilters),
        research_papers: getCollectionFilterFromSearchStrings("research_papers", urlParams, currentFilters),
        influencers: getCollectionFilterFromSearchStrings("influencers", urlParams, currentFilters),
        patent_holders: getCollectionFilterFromSearchStrings("patent_holders", urlParams, currentFilters),
        grants: getCollectionFilterFromSearchStrings("grants", urlParams, currentFilters),
        clinical_trials: getCollectionFilterFromSearchStrings("clinical_trials", urlParams, currentFilters),
        companies: getCollectionFilterFromSearchStrings("companies", urlParams, currentFilters)
    }
}
