import { LocationChangeAction } from "connected-react-router"
import { Filters } from "../../../functions/src/models/filtering"
import { SortingParams } from "../../../functions/src/models/sorting"
import { createAction } from "../../../functions/src/utils/actions"
import { NavigationParams } from "../../utils/router.types"
import { Popup, NotificationMsg } from "../store"

export const actions = {
    toggleFilter: (key: KeysWithValue<Filters, any[]>, filter: string) => createAction("toggleFilter", { key, filter }),
    updateFilters: (delta: Partial<Filters>) => createAction("updateFilters", delta),
    resetFilters: (initial: Partial<Filters>) => createAction("resetFilters", initial),
    updateSortingKey: <C extends CName>(collectionName: C, sortingParams: SortingParams<C>[]) =>
        createAction("updateSortingKey", { collectionName, sortingParams }),

    updateParamsAndNavigate: (delta: Partial<Filters>, params: NavigationParams) =>
        createAction("updateParamsAndNavigate", { delta, params }),

    openPopup: (key: Type<Popup>, value?: Value<Popup>) => createAction("openPopup", { key, value }),
    closePopup: (key: Type<Popup>) => createAction("closePopup", key),

    queueNotification: (msg: NotificationMsg) => createAction("queueNotification", msg),
    hideMsg: () => createAction("hideMsg"),

    _setCursor: (cursor: Cursor) => createAction("_setCursor", cursor),
    _updateURL: (params?: NavigationParams) => createAction("_updateURL", { params })
}
export type Actions = ReturnType<typeof actions[keyof typeof actions]> | LocationChangeAction
