import * as React from "react"
import * as ReactDOM from "react-dom"
import {
    _DropdownWrapper,
    _DropdownTrigger,
    _DropdownText,
    _DropdownOptions,
    _DropdownOption,
    _DropdownBadge,
    _DropdownLabel,
    _DropdownSublabel,
    _DropdownSection,
    _DropdownContainer,
    _IconDropdownIconContainer
} from "./Dropdown.styles"
import { _TagFont } from "../styles/typography"
import { _VerticalSpace, _HorizontalSpace, _FlexColumn, Flex } from "../styles/common"
import { cbNoPropagation, getOnScreenPosition, selectCreateDiv } from "../utils/html5"
import { _noop } from "../../functions/src/utils"
import { SearchInput } from "./form/Input"
import { keys } from "../../functions/src/utils/map"
import { IconSvg, IconSvgProps } from "./IconSvg"
import { useRerenderOnResize } from "../utils/hooks/useRerenderOnResize"

const filterOptions = <T extends any>(os: ColorOption<T>[], searchPhrase: string) =>
    os.filter(o => o.label.toLocaleLowerCase().includes(searchPhrase.toLocaleLowerCase()))

export type DropdownProps<T, ST extends string = string> = {
    options: ColorOption<T>[]
    onSelect: F1<ColorOption<T>>
    selected?: ColorOption<T>[]
    closeOnSelect?: boolean
    optionsXLocation?: "left" | "right"
    optionsYLocation?: "bottom" | "top"
    alignToLeft?: boolean
    title?: string
    buttonText?: string
    full?: boolean
    searchable?: boolean
    sections?: Record<ST, string>
    getSection?: (o: ColorOption<T>) => ST
}

type TriggerProps = { value: string; open: boolean; onClick: F0; background?: string }
type OptionProps<T> = { isSelected: boolean; value: ROption<T>; onSelect: F0; background?: string; key: string }

type RenderProps<T> = {
    renderTrigger: F1<TriggerProps, React.ReactElement<TriggerProps>>
    renderOption: F1<OptionProps<T>, React.ReactElement<OptionProps<T>> | null>
}

export const getDropdownPosition = (
    refContainer: HTMLElement | null,
    refOptions: HTMLElement | null,
    _optionsXLocation: "left" | "right",
    _optionsYLocation: "bottom" | "top"
): {
    top: number
    left: number
} => {
    const rPos = getOnScreenPosition(refContainer)
    const verticalScroll = document.scrollingElement?.scrollTop ?? 0
    return {
        left: Math.max(
            0,
            rPos.left + (refOptions?.clientWidth || 0) > window.innerWidth
                ? window.innerWidth - (refOptions?.clientWidth || 0) - 5
                : rPos.left
        ),
        top: verticalScroll + rPos.top + (refContainer?.clientHeight || 0) + 3
    }
}

export const DropdownBase = <T extends any>({
    options,
    selected = [],
    title = "",
    buttonText = "",
    onSelect,
    closeOnSelect = true,
    full = false,
    searchable = false,
    optionsXLocation = "right",
    optionsYLocation = "bottom",
    renderTrigger,
    renderOption,
    sections,
    getSection,
    ...stylingProps
}: DropdownProps<T> & RenderProps<T>): React.ReactElement<DropdownProps<T>> => {
    const [open, setOpen] = React.useState<boolean>(false)
    const [searchValue, setSearchValue] = React.useState("")
    const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null)
    const [refOptions, setRefOptions] = React.useState<HTMLDivElement | null>(null)

    React.useEffect(() => {
        const body = document.querySelector("body")
        if (open && body) {
            const handler = ({ target }: Event) => {
                const t = target as Element
                if (t.closest(".dropdown") !== containerRef && !t.closest("#dropdown")) {
                    setOpen(false)
                    setSearchValue("")
                }
            }
            body.addEventListener("click", handler)

            return () => body.removeEventListener("click", handler)
        }
    }, [open, containerRef])

    // Necessary for proper & FAST (avoid jumping) dropdown position recalculation
    useRerenderOnResize()

    const handleSelect = (selectedOption: ColorOption<T>) => {
        setOpen(!closeOnSelect)
        if (closeOnSelect) setSearchValue("")
        setTimeout(() => onSelect(selectedOption), 250)
    }
    const handleTrigger = () => setOpen(o => !o)

    const triggerText = buttonText || (selected.length ? selected[0].label : "")
    const triggerBackground = selected.length ? selected[0].background || "" : ""
    const filteredOptions = searchable ? filterOptions(options, searchValue) : options

    const getOptions = (os: typeof options) =>
        os.map((opt, key) =>
            renderOption({
                isSelected: selected.some(o => o.label === opt.label),
                value: opt,
                onSelect: () => open && handleSelect(opt),
                background: opt.background || "",
                // TODO: duplicate value objects, remove in future
                key: `${opt.label}__${opt.value}__${key}`
            })
        )

    return (
        <_DropdownContainer>
            <_DropdownWrapper ref={setContainerRef} className="dropdown" {...stylingProps}>
                {renderTrigger({ value: triggerText, open, onClick: handleTrigger, background: triggerBackground })}
                {open &&
                    ReactDOM.createPortal(
                        <_DropdownOptions
                            ref={setRefOptions}
                            position={getDropdownPosition(containerRef, refOptions, optionsXLocation, optionsYLocation)}
                            full={full}
                            open={open}>
                            {title && (
                                <>
                                    <_TagFont>{title}</_TagFont>
                                    <_VerticalSpace base="8px" />
                                </>
                            )}
                            {searchable && (
                                <>
                                    <SearchInput
                                        onChange={setSearchValue}
                                        value={searchValue}
                                        onClear={() => setSearchValue("")}
                                    />
                                    <_VerticalSpace base="8px" />
                                </>
                            )}
                            {sections
                                ? keys(sections)
                                      .map(s => {
                                          const sectionOptions = filteredOptions.filter(
                                              o => getSection && getSection(o) === s
                                          )
                                          return sectionOptions.length ? (
                                              <_DropdownSection key={s}>
                                                  <_TagFont>{sections[s]}</_TagFont>
                                                  <_VerticalSpace base="8px" />
                                                  {getOptions(sectionOptions)}
                                                  <_VerticalSpace base="8px" />
                                              </_DropdownSection>
                                          ) : null
                                      })
                                      .filter(Boolean)
                                : getOptions(filteredOptions)}
                        </_DropdownOptions>,
                        selectCreateDiv("dropdown")
                    )}
            </_DropdownWrapper>
        </_DropdownContainer>
    )
}

export const Dropdown = <T extends any>(p: DropdownProps<T>): React.ReactElement<DropdownProps<T>> => (
    <DropdownBase
        {...p}
        renderTrigger={({ onClick, open, value }) => (
            <_DropdownTrigger full={p.full} onClick={cbNoPropagation(onClick)} open={open}>
                <_DropdownText>{value}</_DropdownText>
                <_HorizontalSpace base="20px" />
            </_DropdownTrigger>
        )}
        renderOption={({ onSelect, value }) => (
            <_DropdownOption onClick={cbNoPropagation(onSelect)} key={value.label}>
                {value.label}
            </_DropdownOption>
        )}
    />
)

export const IconDropdown = <T extends any>(
    p: DropdownProps<T> & { icon: IconSvgProps }
): React.ReactElement<DropdownProps<T>> => (
    <DropdownBase
        {...p}
        renderTrigger={({ onClick }) => (
            <_IconDropdownIconContainer>
                <IconSvg {...p.icon} onClick={cbNoPropagation(onClick)} />
            </_IconDropdownIconContainer>
        )}
        renderOption={({ onSelect, value }) => (
            <_DropdownOption onClick={cbNoPropagation(onSelect)} key={value.label}>
                {value.label}
            </_DropdownOption>
        )}
    />
)

export const CheckboxDropdown = <T extends any>(p: DropdownProps<T>): React.ReactElement<DropdownProps<T>> => (
    <DropdownBase
        {...p}
        closeOnSelect={p.closeOnSelect || false}
        renderTrigger={({ onClick, open, value, background }) => (
            <_DropdownTrigger full={p.full} onClick={cbNoPropagation(onClick)} open={open}>
                <Flex align="center">
                    <_DropdownLabel background={background || ""}>
                        <_DropdownText data-cy={`checkbox-text-${value}`}>{value}</_DropdownText>
                    </_DropdownLabel>
                    {(p.selected || []).length ? <_DropdownBadge>{(p.selected || []).length}</_DropdownBadge> : null}
                    <_HorizontalSpace base="20px" />
                </Flex>
            </_DropdownTrigger>
        )}
        renderOption={({ onSelect, value: { label, sublabel }, isSelected, background, key }) => (
            <_DropdownOption onClick={onSelect} checkbox key={key}>
                <input type="checkbox" checked={isSelected} onClick={cbNoPropagation(_noop)} onChange={onSelect} />
                <_HorizontalSpace base="8px" />
                <_FlexColumn>
                    <_DropdownLabel data-cy="dropdown-label" background={background || ""}>
                        {label}
                    </_DropdownLabel>
                    {sublabel ? <_DropdownSublabel background={background || ""}>{sublabel}</_DropdownSublabel> : null}
                </_FlexColumn>
            </_DropdownOption>
        )}
    />
)

export const RadioDropdown = <T extends any>(p: DropdownProps<T>): React.ReactElement<DropdownProps<T>> => (
    <DropdownBase
        {...p}
        closeOnSelect={p.closeOnSelect === undefined ? true : p.closeOnSelect}
        renderTrigger={({ onClick, open, value, background }) => (
            <_DropdownTrigger full={p.full} onClick={cbNoPropagation(onClick)} open={open}>
                <_DropdownLabel background={background || ""}>
                    <_DropdownText data-cy={`radio-text-${value}`}>{value}</_DropdownText>
                </_DropdownLabel>
                <_HorizontalSpace base="20px" />
            </_DropdownTrigger>
        )}
        renderOption={({ onSelect, value: { label, sublabel, value }, isSelected, background }) => (
            <_DropdownOption onClick={cbNoPropagation(onSelect)} checkbox key={`${label}-${value}`}>
                <input type="radio" checked={isSelected} onChange={_noop} />
                <_HorizontalSpace base="8px" />
                <_FlexColumn>
                    <_DropdownLabel background={background || ""}>{label}</_DropdownLabel>
                    {sublabel ? <_DropdownSublabel background={background || ""}>{sublabel}</_DropdownSublabel> : null}
                </_FlexColumn>
            </_DropdownOption>
        )}
    />
)
