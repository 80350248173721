import * as React from "react"
import { Route, RouteProps } from "react-router-dom"
import { ModalRouteProps } from "../containers/ModalRenderer"
import { _noop } from "../../functions/src/utils"

export type ExtRouteProps = RouteProps & { component: React.FC<any> }

export const WrappedRoute = (Wrapper: React.FC<any>): React.FC<ExtRouteProps> => ({ component: C, ...p }) => (
    <Route
        {...p}
        render={props => (
            <Wrapper>
                <C {...props} />
            </Wrapper>
        )}
    />
)

export const ModalRoute = ({
    component: C,
    onClose = _noop,
    title = "",
    ...p
}: ExtRouteProps & Partial<ModalRouteProps>) => (
    <Route {...p} render={props => <C {...props} onClose={onClose} title={title} />} />
)
