import styled from "styled-components"
import { keys } from "../../functions/src/utils/map"
import { Flex, FlexProps } from "../styles/common"
import { IconSvgProps } from "./IconSvg"

export const _IconSvgContainer = styled(Flex).withConfig<FlexProps & IconSvgProps>({
    shouldForwardProp: (p, d) => !["color"].includes(p) && d(p)
})`
    display: ${p => p.inline && "inline-flex"};
    flex-shrink: 0;
    width: ${p => p.width && `${p.width}px`};
    height: ${p => p.height && `${p.height}px`};
    color: ${p => p.color && p.theme.colors[p.color]};
    cursor: ${p => p.onClick && "pointer"};
    transform: ${p => p.rotate && `rotate(${p.rotate}deg)`};

    svg {
        max-width: 100%;
        max-height: 100%;
        flex-grow: 1;
        flex-shrink: 0;

        ${p =>
            p.pathColors &&
            keys(p.pathColors)
                .map(k => `--${k}: ${p.pathColors![k]};`)
                .join("\n")}
    }
`
