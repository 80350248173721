import { ExportRequest, ExportIdsRequest, ExportAllRequest, CollectionIdsMap, ExportFormatExt } from "."
import { validate, validString, validatePartial, validateArray, Err } from "../../../utils/validators"
import { toMap, identity } from "../../../utils/map"
import { cnames } from "../../../models/schemas"
import { validateFilters } from "../validators"
import { validateCName } from "../../../utils/domainValidators"

const exportIdsValidationMap: ValidationMap<Required<CollectionIdsMap>> = toMap(cnames, identity, () => [
    validateArray(validString)
])

export const validateExportRequestPayload = (data: ExportRequest) => {
    if (!data.type) return Err("No export data request type")
    switch (data.type) {
        case "ids":
            return validate<OnRadar<ExportIdsRequest & { format: ExportFormatExt }>>({
                radarId: validString,
                type: validString,
                ids: [validatePartial(exportIdsValidationMap)],
                format: validString
            })(data)

        case "all":
            return validate<OnRadar<ExportAllRequest & { format: ExportFormatExt }>>({
                radarId: validString,
                type: validString,
                cnames: [validateArray([validateCName])],
                filters: [validateFilters],
                format: validString
            })(data)

        default:
            return Err("Not recognized mutation type")
    }
}
