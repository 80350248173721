import * as React from "react"
import { Icon } from "../../../../functions/src/models/icons"
import { Join } from "../../../utils/reactUtils"
import { IconSvg } from "../../IconSvg"
import { CardTooltip } from "../CardTooltip"
import {
    _FLItemContainer,
    _FLItemGridContainer,
    _FLItemIconContainer,
    _FLItemInfoIconContainer,
    _FLItemNameContainer,
    _FLItemTitleContainer,
    _FLItemValueContainer
} from "./FieldsList.styles"

export type FieldsListItem = {
    name: string
    value: React.ReactNode
    icon?: Icon
    info?: React.ReactNode
    vertical?: boolean
    visible?: boolean
}

const defaultIcon: Icon = "cardSections:business"

export const FieldsList = (p: { items: FieldsListItem[] }) => {
    const [tooltipVisible, setTooltipVisible] = React.useState<string | null>(null)
    const onMouseLeave = (i: FieldsListItem) => {
        setTimeout(() => i.info && setTooltipVisible(null), 50)
    }
    const onMouseMove = (i: FieldsListItem) => {
        setTimeout(() => i.info && tooltipVisible !== i.name && setTooltipVisible(i.name), 50)
    }

    return (
        <Join items={p.items}>
            {i =>
                i.visible && (
                    <_FLItemContainer>
                        <_FLItemGridContainer vertical={i.vertical}>
                            <_FLItemTitleContainer
                                withInfo={!!i.info}
                                onMouseMove={() => onMouseMove(i)}
                                onMouseLeave={() => onMouseLeave(i)}>
                                <_FLItemIconContainer>
                                    <IconSvg name={i.icon || defaultIcon} />
                                </_FLItemIconContainer>
                                <_FLItemNameContainer>
                                    {i.name}
                                    {i.info && (
                                        <_FLItemInfoIconContainer>
                                            <IconSvg name="info" width={13} height={13} />
                                        </_FLItemInfoIconContainer>
                                    )}
                                </_FLItemNameContainer>
                            </_FLItemTitleContainer>
                            <_FLItemValueContainer data-cy={`value-container-${i.value}`}>
                                {i.value}
                            </_FLItemValueContainer>
                        </_FLItemGridContainer>
                        {i.info && (
                            <CardTooltip
                                content={i.info}
                                title="More info"
                                show={tooltipVisible === i.name}
                                position={{ top: "90%", left: "20px" }}
                            />
                        )}
                    </_FLItemContainer>
                )
            }
        </Join>
    )
}
