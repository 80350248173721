import * as React from "react"
import styled from "styled-components"
import {
    DragDropContext,
    Droppable,
    Draggable,
    DraggableProvidedDraggableProps,
    DraggableProvidedDragHandleProps
} from "react-beautiful-dnd"

import { ThemeColors } from "../../styles/styled"
import { styleFromProp } from "../../styles/common"
import { call } from "../../../functions/src/utils"
import { _BodyFont } from "../../styles/typography"
import { cbNoPropagation } from "../../utils/html5"

export const _ChipsContainer = styled.div`
    display: flex;
    flex: 1 1 0%;
    align-items: center;
    flex-wrap: wrap;
`
type ChipBaseProps = {
    color?: keyof ThemeColors
    background?: keyof ThemeColors
    leftPadding?: boolean
}

export const _ChipBase = styled.div<ChipBaseProps>`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 24px 4px ${props => (props.leftPadding ? 28 : 14)}px;
    margin: 5px;
    min-width: 0px;
    cursor: pointer !important;
    border-radius: 20px;
    ${p => styleFromProp(p.background, `background-color: ${p.theme.colors[p.background!]};`, "")};
    color: ${({ theme }) => theme.colors.primary};
`
export const _ChipX = styled.span`
    position: absolute;
    right: 4px;
    width: 12px;
    height: 12px;

    padding: 10px;
    transition: transform 0.3s;
    &:hover {
        transform: scale(1.2);
    }
    &:before,
    &:after {
        position: absolute;
        bottom: 5px;
        content: " ";
        height: 9px;
        width: 1px;
        background-color: #333;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
`
export const _ChipLeft = styled.span<{ length: number }>`
    position: absolute;
    left: ${p => (p.length > 1 ? 0 : 8)}px;
    width: 20px;
    height: 20px;
    padding: 1px 4px;
`

type ChipProps = {
    onClick?: F0
    onClose?: F0
    number?: number
    draggableProps?: DraggableProvidedDraggableProps
    dragHandleProps?: DraggableProvidedDragHandleProps | null
}

export const Chip = React.forwardRef<any, React.PropsWithChildren<ChipProps>>(
    ({ dragHandleProps = {}, draggableProps = {}, ...p }, ref) => (
        <_ChipBase
            ref={ref}
            {...dragHandleProps}
            {...draggableProps}
            leftPadding={!!p.number}
            background="theme9"
            onClick={() => call(p.onClick)}>
            {p.number && <_ChipLeft length={p.number.toString().length}>{p.number}.</_ChipLeft>}
            <_BodyFont s15>{p.children}</_BodyFont>
            {p.onClose && <_ChipX onClick={cbNoPropagation(p.onClose)} />}
        </_ChipBase>
    )
)

const reorder = (list: ROptions<string>, startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

type ChipsProps = {
    values: ROptions<string>
    onChipClick: F1<ROption<string>>
    onChipClose: F1<ROption<string>>
    onChipDrag?: F1<ROptions<string>>
    draggable?: boolean
    numbered?: boolean
}
export const Chips: React.FC<ChipsProps> = p => {
    const [values, setValues] = React.useState(p.values)
    React.useEffect(() => setValues(p.values), [p.values])

    return p.draggable ? (
        <DragDropContext
            onDragEnd={res => {
                if (!res.destination || res.source.index === res.destination.index) return
                const reordered = reorder(values, res.source.index, res.destination.index)
                call(p.onChipDrag, reordered)
                setValues(reordered)
            }}>
            <Droppable droppableId="droppable" direction="horizontal">
                {dp => (
                    <_ChipsContainer ref={dp.innerRef as any} {...dp.droppableProps}>
                        {values.map((o, i) => (
                            <Draggable key={o.value} draggableId={o.value} index={i}>
                                {provided => (
                                    <Chip
                                        ref={provided.innerRef}
                                        number={p.numbered ? i + 1 : undefined}
                                        draggableProps={provided.draggableProps}
                                        dragHandleProps={provided.dragHandleProps}
                                        key={o.value}
                                        onClick={() => p.onChipClick(o)}
                                        onClose={() => p.onChipClose(o)}>
                                        {o.label}
                                    </Chip>
                                )}
                            </Draggable>
                        ))}
                        {dp.placeholder}
                    </_ChipsContainer>
                )}
            </Droppable>
        </DragDropContext>
    ) : (
        <_ChipsContainer>
            {p.values.map(o => (
                <Chip key={o.value} onClick={() => p.onChipClick(o)} onClose={() => p.onChipClose(o)}>
                    {o.label}
                </Chip>
            ))}
        </_ChipsContainer>
    )
}
