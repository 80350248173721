import * as d3 from "d3"
import { positiveOrZero, repeat } from "../utils"
import { theme } from "../styles/themes"
import { Size, CRSize, Bounds } from "../../functions/src/models/common"
import { isEmpty } from "../../functions/src/utils/validators"
import { formatMillions } from "../../functions/src/models/converters"
import { SectorVM } from "../../functions/src/models/ViewModels"
import { Icon } from "../../functions/src/models/icons"

type EmptyCell = CRLocation & { type: "Empty" }
type SectorCell = SectorVM & { type: "NotEmpty"; tags: string[]; searchAreaNames: string[]; color_codes: string[] }
type Cell = SectorCell | EmptyCell
type ExtCell = Cell & { disabled: boolean; transparent: boolean }

const polygonRadius = 45
const hex = [polygonRadius, 6, Math.PI / 2]

export const getPolygonVectors = (radius: number, sides: number, angle: number) =>
    repeat(sides)
        .map(n => angle + (2 * Math.PI * n) / sides)
        .map((a): Vector => [Math.round(radius * Math.cos(a)), Math.round(radius * Math.sin(a))])

export const getPolygonEdges = (vectors: Vector[]) => vectors.map(vector => Math.abs(vector[0] + vector[1]))

const appendPath = (prefix: string, v: Vector) => prefix + (prefix.length ? "L" : "M") + v.join(",")
export const getPolygonPath = (vs: Vector[]) => vs.reduce(appendPath, "") + "Z"

// eslint-disable-next-line prefer-spread
const hexVectors = getPolygonVectors.apply(null, hex)
const hexPath = getPolygonPath(hexVectors)
const longestEdge = Math.max.apply(null, getPolygonEdges(hexVectors))

export const getSize = (ss: SectorVM[]) => CRSize(Math.max(...ss.map(s => s.column)), Math.max(...ss.map(s => s.row)))

const equal = <T extends CRLocation>(l: T, r: T) => l.row === r.row && l.column === r.column

const getRow = (column: number, rows: number): CRLocation[] => repeat(rows).map(i => ({ column, row: i + 1 }))
export const getCellLocations = (size: CRSize): CRLocation[] =>
    repeat(size.columns).reduce(
        (locations, column) => [...locations, ...getRow(column + 1, size.rows)],
        [] as CRLocation[]
    )

const Cell = (l: CRLocation, color_codes: string[], searchAreaNames: string[], tags: string[], s?: SectorVM): Cell =>
    s ? { ...s, color_codes, searchAreaNames, tags, type: "NotEmpty" } : { ...l, type: "Empty" }

export const getCells = (
    size: CRSize,
    searchAreaColorsByObjectId: SMap<SearchArea[]>,
    tagNamesByObjectId: SMap<string[]>,
    sectors: SectorVM[]
) =>
    getCellLocations(size).map(l => {
        const sector = sectors.find(s => equal(l, s))
        const searchAreas = sector ? searchAreaColorsByObjectId[sector.sectorId] || [] : []
        const tags = sector ? tagNamesByObjectId[sector.sectorId] || [] : []
        return Cell(
            l,
            searchAreas.map(sa => sa.color),
            searchAreas.map(sa => sa.name),
            tags,
            sector
        )
    })

type CellsConfig = { selectedTags: string[]; searchAreasFilter: SMap<boolean> }
const ExtCell = (cell: Cell, { searchAreasFilter, selectedTags }: CellsConfig): ExtCell => ({
    ...cell,
    transparent:
        cell.type !== "Empty" && !isEmpty(selectedTags) && !selectedTags.find(t => (cell.tags || []).includes(t)),
    disabled: cell.type !== "Empty" && cell.searchAreaNames.some(space => !searchAreasFilter[space])
})

export const getExtCells = (cells: Cell[], config: CellsConfig) => cells.map(c => ExtCell(c, config))

export const containerSize = (s: CRSize, radius = 45) =>
    Size(s.columns * radius * 1.65 + radius / 2, s.rows * radius * 1.9 + radius)

const axisSpace = 26
const getVisibilityIcon = (data: SectorCell): Icon => (data.sector_completed ? "eye" : "lock")

export const renderVisiblityIndicators = (group: d3.Selection<SVGGElement, Cell, SVGGElement, ExtCell>) =>
    group
        .filter(data => data.type !== "Empty")
        .append("svg:use")
        .attr("xlink:href", (d: SectorCell) => `#${getVisibilityIcon(d)}`)
        .attr("x", axisSpace / -2)
        .attr("y", axisSpace * -2)
        .attr("width", axisSpace)
        .attr("height", axisSpace)

type HexGroup = d3.Selection<SVGElement, ExtCell, null, undefined>
let _hexGroup: HexGroup | null = null
export const drawMap = (svg: SVGSVGElement, gridData: Cell[], indicatorsVisible: boolean) => {
    _hexGroup = d3.select(svg)
    const hexGroups = _hexGroup!
        .append("g")
        .attr("class", "hexes")
        .attr("transform", "translate(8, 8)")
        .selectAll(".hex")
        .data(gridData)
        .enter()

    const group = hexGroups
        .append("g")
        .attr("transform", data => {
            const cx = (data.column - 1) * polygonRadius * 1.65 + polygonRadius
            const cy = (data.row - 1) * polygonRadius * 1.9 + (data.column % 2 ? polygonRadius : polygonRadius * 2)
            return `translate(${cx}, ${cy})`
        })
        .attr("class", data => (data.type !== "Empty" ? "hex-group" : ""))

    group
        .append("path")
        .attr("class", "hex")
        .attr("d", hexPath)
        .attr("fill", data => {
            return data.type === "Empty" ? theme.colors.theme4 : data.color_codes[0]
        })
        .attr("transform", "rotate(90 0 0)")

    if (indicatorsVisible) renderVisiblityIndicators(group)

    const hexText = group
        .append("foreignObject")
        .attr("width", longestEdge)
        .attr("height", longestEdge)
        .attr("transform", `translate(-${longestEdge / 2}, -${longestEdge / 2})`)
        .append("xhtml:div")
        .attr("style", "font-size: 9px")
        .attr("class", "hex-text")

    hexText.append("strong").text(data => (data.type !== "Empty" ? data.sector_name : ""))
}

export const attachOnClick = (onClick: F1<string>) => {
    if (!_hexGroup) return
    _hexGroup
        .selectAll(".hex-group")
        .filter((data: Cell) => data.type !== "Empty")
        .on("click", (data: Sector) => onClick(data.sectorId))
}

export const updateMap = (gridData: ExtCell[]) => {
    if (!_hexGroup) return
    _hexGroup
        .selectAll(".hex")
        .data(gridData)
        .transition()
        .duration(300)
        .attr("fill", data => (data.type === "Empty" || data.disabled ? theme.colors.theme4 : data.color_codes[0]))
        .attr("opacity", data => (data.transparent ? 0.3 : 1))
}

const zoomHexes = (h: HexGroup, zoom: number) =>
    h
        .selectAll(".hexes")
        .transition()
        .duration(300)
        .attr("transform", () => `scale(${zoom}), translate(8, 8)`)
        .attr("transform-origin", () => "left top")

export const zoomInMap = (zoom: number, gridData: ExtCell[]) => {
    if (!_hexGroup) return
    zoomHexes(_hexGroup, zoom)
    _hexGroup
        .selectAll(".hex-text")
        .data(gridData)
        .attr("style", "font-size: 7px")
        .append("p")
        .text(data => (data.type !== "Empty" ? `($${formatMillions(data.funding)})` : ""))
        .append("p")
        .text(data => (data.type !== "Empty" ? data.company_hex_display : ""))
}

export const zoomOutMap = (zoom: number) => {
    if (!_hexGroup) return
    zoomHexes(_hexGroup, zoom)
    _hexGroup.selectAll(".hex-text").attr("style", "font-size: 9px").selectAll("p").remove()
}

export const calculateBounds = (mapSelector = ".hex-map", containerSelector = ".hex-map-container") => {
    const hexMap = document.querySelector(mapSelector)
    const hexMapContainer = document.querySelector(containerSelector)

    if (!hexMap || !hexMapContainer) return Bounds(0, 0, 0, 0)

    const { height: haxMapHeight, width: hexMapWidth } = hexMap.getBoundingClientRect()
    const hexContainer = hexMapContainer.getBoundingClientRect()
    const { height, width } = document.body.getBoundingClientRect()
    const { arrowColumnsHeight, arrrowRowsWidth } = theme.sizes

    const top = positiveOrZero(haxMapHeight + hexContainer.top + arrowColumnsHeight + 20 - height) * -1
    const left = positiveOrZero(hexMapWidth + hexContainer.left + arrrowRowsWidth + 20 - width) * -1

    return Bounds(top, 0, 0, left)
}
