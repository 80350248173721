import * as React from "react"
import { debounce } from ".."

export const useRerenderOnResize = () => {
    const [, setS] = React.useState({})

    React.useEffect(() => {
        const rerender = debounce(() => setS({}), 200)
        window.addEventListener("resize", rerender)
        return () => window.removeEventListener("resize", rerender)
    }, [])
}
