import * as React from "react"
import { _Tab, _TabSelector, _TabContentWrapper } from "./Tabs.styles"
import { _AbsPosContainer, _AbsPosElement, _FlexColumn, _VerticalSpace } from "../../styles/common"
import { useHistory, useLocation } from "react-router-dom"
import { History, Location } from "history"
import { TabsProps, Content, Tab } from "./Tabs"

const TAB_QUERY_PARAM = "tab"

export const getTabFromLocation = <T extends string>(data: Tab<T>[], location: Location<History.PoorMansUnknown>) => {
    const queryTab = new URLSearchParams(location.search).get(TAB_QUERY_PARAM)
    return queryTab && data.find(i => i.id === queryTab)?.id
}

const setTab = <T extends string>(
    id: T,
    location: Location<History.PoorMansUnknown>,
    history: History<History.PoorMansUnknown>
) => {
    const query = new URLSearchParams(location.search)
    query.set(TAB_QUERY_PARAM, id)
    history.push({
        search: query.toString()
    })
}

// TODO Add testing for tab query params
export const URLTabs = <T extends string>({ legacy = true, ...p }: TabsProps<T>) => {
    const history = useHistory()
    const location = useLocation()
    const [active, setActive] = React.useState(getTabFromLocation(p.data, location) || p.data[0].id)
    const activeTab = p.data.find(t => t.id === active)

    React.useEffect(() => {
        const locationTab = getTabFromLocation(p.data, location)
        if (!locationTab) {
            const query = new URLSearchParams(location.search)
            query.set(TAB_QUERY_PARAM, p.data[0].id)
            history.replace({
                search: query.toString()
            })
        } else if (locationTab !== active) {
            setActive(locationTab)
        }
    }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (!activeTab) setActive(p.data[0].id)
    }, [p.data]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <_AbsPosContainer>
            <_AbsPosElement>
                <_FlexColumn grow={1} style={{ maxWidth: "100%" }}>
                    <_TabSelector legacy={legacy}>
                        {p.data.map(d => (
                            <_Tab
                                legacy={legacy}
                                selected={active === d.id}
                                onClick={() => {
                                    setTab(d.id, location, history)
                                    if (p.onChange) p.onChange(d.id)
                                }}
                                key={d.id}>
                                <Content {...d.title} />
                            </_Tab>
                        ))}
                    </_TabSelector>
                    <_VerticalSpace base="16px" />
                    <_TabContentWrapper scrollable={p.scrollable}>
                        {activeTab && <Content {...activeTab.content} />}
                    </_TabContentWrapper>
                </_FlexColumn>
            </_AbsPosElement>
        </_AbsPosContainer>
    )
}
