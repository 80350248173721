import * as React from "react"
import { useFormHook } from "../../utils/hooks/useFormHook"
import { _Center, _VerticalSpace } from "../../styles/common"
import { isNotStarted, isProcessing } from "../../../functions/src/utils/types"
import { FormView } from "../form/FormView"
import { Button } from "../../styles/buttons"
import { Loader } from "../common/Loader"
import { isErr } from "../../../functions/src/utils/validators"
import { getSingleAreaSegmentTagSchema } from "../../models/segmentTags"
import { validateSegmentTagDuplicate } from "../../../functions/src/models/tags"
import { _ErrorLabel } from "../form/Input.styles"
import { _SegmentTagEditorFormContainer } from "../../views/admin/SegmentTags.styles"

export type SegmentTagSingleCreatePayload = SegmentTagCreatePayload
export type SegmentTagCreateFormProps = {
    tagName: string
    onSubmit: F1<SegmentTagSingleCreatePayload>
    searchAreas: SMap<SearchArea>
    segmentTags: SMap<SegmentTag>
    state: AsyncAction<CloudActionResult>
}
export const SegmentTagCreateForm: React.FC<SegmentTagCreateFormProps> = p => {
    const [error, setError] = React.useState<string | null>(null)
    const { formViewProps, onSubmitClick, resetState, result } = useFormHook({
        schema: getSingleAreaSegmentTagSchema(p.searchAreas),
        onSubmit: v => {
            const vresult = validateSegmentTagDuplicate(p)(v)
            if (isErr(vresult)) {
                setError(vresult.value)
            } else {
                setError(null)
                p.onSubmit(v)
                resetState()
            }
        },
        initialValue: { name: p.tagName, areaId: "" }
    })

    return (
        <_Center>
            {isNotStarted(p.state) && (
                <_SegmentTagEditorFormContainer>
                    <FormView {...formViewProps} />
                    {error ? (
                        <>
                            <_ErrorLabel>{error}</_ErrorLabel>
                            <_VerticalSpace base="12px" />
                        </>
                    ) : null}
                    <_Center>
                        <Button onClick={onSubmitClick} disabled={isErr(result)}>
                            Create tag
                        </Button>
                    </_Center>
                </_SegmentTagEditorFormContainer>
            )}
            {isProcessing(p.state) && <Loader />}
        </_Center>
    )
}
