import styled, { css } from "styled-components"

export const FORM_MAX_WIDTH = "830px"
export const INPUT_MAX_WIDTH = "480px"

export const TextInput = styled.input<{ hasError?: boolean }>`
    border: 1px solid ${({ theme, hasError }) => (hasError ? theme.colors.danger : theme.colors.theme2)};
    border-radius: 4px;
    font-size: 14px;
    padding: 11px 15px;
    width: 100%;
    margin-bottom: 8px;
    color: inherit;
    outline: none;
`

export const Label = styled.label`
    font-size: 12px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.3px;
    margin-bottom: 10px;
    display: block;
`

export const _CollectionWrapper = styled.div`
    margin: 0 0 16px 0;
    padding: 19px 15px;
    background: #f6f9fc;
    border-radius: 6px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
`

type FormViewWrapperProps = { gridColumns?: number }
export const _FormViewWrapper = styled.div<FormViewWrapperProps>`
    width: 100%;
    max-width: ${FORM_MAX_WIDTH};
    margin: 0 auto;
    ${({ gridColumns }) =>
        gridColumns
            ? css`
                  display: grid;
                  grid-template-columns: ${Array(gridColumns).fill("1fr").join(" ")};
                  grid-column-gap: 30px;
              `
            : css`
                  display: flex;
                  flex-direction: column;

                  & > * {
                      flex: 1 0 auto;
                  }
              `}
`
