import {
    validString,
    validate,
    validNumber,
    validNumberDef,
    validArrayStringDef,
    validDef,
    validateNumber,
    validStringDef
} from "../utils/validators"
import { RFields } from "./RFields"
import { formatDecimal, formatInteger } from "./converters"
import { mapToDisplay, parseString } from "./common"
import {
    DEFAULT_PLACEHOLDER,
    sharedRequiredRadarFieldsValidationMap,
    sharedOptionalRadarFieldsValidationMap,
    sharedVMMetaValidationMap
} from "./shared"
import { InvestorVM, DisplayModelsMap } from "./ViewModels"

const investorRequiredFieldsValidationMap: ValidationMap<Required<InvestorFieldsRequired>> = {
    investor_name: validString,
    ...sharedRequiredRadarFieldsValidationMap
}

const investorOptionalFieldsValidationMap: ValidationMap<Required<InvestorFieldsOptional>> = {
    ...sharedOptionalRadarFieldsValidationMap,
    amount_invested: validNumberDef,
    amount_per_investment: validNumberDef,
    investor_completed: validDef(1, validateNumber),
    investor_quality: validNumberDef,
    number_of_investment_rounds: validNumberDef,
    total_unique_investments: validNumberDef,
    investor_url: validStringDef,
    investments: validStringDef
}

export const investorRelationsValidationMap: ValidationMap<Required<InvestorRelations>> = {
    startups: validArrayStringDef,
    companies: validArrayStringDef,
    influencers: validArrayStringDef,
    research_hubs: validArrayStringDef,
    sectors: validArrayStringDef,
    tech_experts: validArrayStringDef
}

export const investorRelations: RFields<"investors"> = RFields<unknown, InvestorRelations>(
    {},
    investorRelationsValidationMap
)

export const investorFieldsValidation: ValidationMap<Required<InvestorFields>> = {
    ...investorRequiredFieldsValidationMap,
    ...investorOptionalFieldsValidationMap
}

export const investorFields: CFields<"investors"> = RFields<InvestorFieldsRequired, InvestorFieldsOptional>(
    investorRequiredFieldsValidationMap,
    investorOptionalFieldsValidationMap
)

export const investorValidationMap: ValidationMap<Required<Investor>> = {
    ...investorFieldsValidation,
    investorId: validString,
    createdTs: validNumber
}

export const validateInvestorRelations = validate<InvestorRelations>(investorRelationsValidationMap)
export const validateInvestorFields = validate<InvestorFields>(investorFieldsValidation)
export const validateInvestor = validate<Investor>(investorValidationMap)
export const validateInvestorVM = validate<InvestorVM>({
    ...investorValidationMap,
    ...sharedVMMetaValidationMap
})

export const transformInvestor = (c: InvestorVM): DisplayModelsMap["investors"] => ({
    ...c,
    investor_quality: formatDecimal(c.investor_quality, undefined, undefined, true),
    total_unique_investments: formatInteger(c.total_unique_investments),
    number_of_investment_rounds: formatInteger(c.number_of_investment_rounds),
    amount_invested: formatDecimal(c.amount_invested, undefined, undefined, true),
    amount_per_investment: formatDecimal(c.amount_per_investment, undefined, undefined, true),
    description: parseString(c.description)
})

export const displayInvestor = (i: InvestorVM, ph: string = DEFAULT_PLACEHOLDER): Displayable<InvestorVM> =>
    mapToDisplay(i, ph)

export const InvestorFieldsDisplay: Displayable<InvestorVM> = {
    investor_name: "Investor name",
    amount_invested: "Amount invested ($m)",
    amount_per_investment: "Amount per investment ($m)",
    source: "Source",
    investments: "Investments",
    description: "Description",
    investor_quality: "Investor quality (Percentile)",
    number_of_investment_rounds: "Number of investment rounds",
    total_unique_investments: "Total unique investments",
    investor_completed: "Is investor completed?",
    investor_url: "Investor URL",
    blurOnListing: "",
    createdTs: "Created At",
    commentsCount: "No. of comments",
    contactedLeadsCount: "No. of contacted leads",
    investorId: "ID",
    filterBounds: ""
}
