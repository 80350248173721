import { ViewModels, ViewModelsBase } from "../../../functions/src/models/ViewModels"
import { capitalize } from "../../../functions/src/utils"
import { mapObject } from "../../../functions/src/utils/map"
import { Fetching } from "../../../functions/src/utils/types"
import { CancellableAsync, TopObjectsState } from "../store"

export const mkInitialState = <D extends unknown>(): CancellableAsync<D> => Fetching<D>(undefined as D)

export const mapAsyncVMStoVMS = (vms: ViewModels): ViewModelsBase =>
    mapObject(vms, k => {
        const vm = vms[k]
        return vm.type === "Fetched" || vm.type === "Fetching" ? (vm as any).value : ({} as any)
    })

export const mapCNameToTopObjectsState = (cname: CName): keyof TopObjectsState =>
    `top${cname
        .split("_")
        .map(c => capitalize(c))
        .join("")}` as any
