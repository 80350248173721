import styled from "styled-components"
import { Flex, styleIfProp } from "../../styles/common"

export const _DNDCardFieldWrapper = styled(Flex).attrs(() => ({ align: "center", justify: "center" }))<{
    big: boolean
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1005px;
    max-height: ${styleIfProp("big", "531px", "336px")};
    cursor: pointer;

    border-radius: 41px;
    border: dashed 2px rgb(44, 64, 90);
    background-color: #f6f7fb;
    &:focus {
        outline: none;
    }
    margin: 0 auto;
    width: 100%;
`
export const _DNDCardField = styled(Flex)``
