import * as React from "react"
import { Processing, Done } from "../../../functions/src/utils/types"
import { getFirebase } from "../../services/firebase"
import { v4 } from "uuid"
import { Err } from "../../../functions/src/utils/validators"
import { workerActions } from "../../workers/workers"

export const useStorageUpload = (
    radarId: string,
    key: EncryptionKey,
    attachmentType: keyof Attachments,
    onDone: (path: string, filename: string) => void
): [SMap<FileStatus>, F1<File[]>] => {
    const [statuses, setStatuses] = React.useState<SMap<FileStatus>>({})

    const onUpload = async (files: File[]) => {
        const { storage } = getFirebase()
        files.forEach(async file => {
            const fileUID = v4()
            const updateFileStatus = (status: StorageStatus) =>
                setStatuses(s => ({ ...s, [fileUID]: { ...status, filename: file.name } }))
            updateFileStatus(Encrypting())
            try {
                const encrypted = await workerActions.encryptFile(file, key)
                updateFileStatus(Processing())
                const ref = storage.ref("attachments", radarId, attachmentType, fileUID)
                await storage.upload(ref, encrypted, {})
                onDone(ref.name, file.name)
                updateFileStatus(Done(""))
            } catch (e) {
                updateFileStatus(Err(JSON.stringify(e)))
            }
        })
    }

    return [statuses, onUpload]
}

const Encrypting = (): State<"Encrypting"> => ({ type: "Encrypting" })
export type StorageStatus = AsyncAction<string> | State<"Encrypting"> | Err<string>
export type FileStatus = StorageStatus & { filename: string }
