import * as React from "react"

import { _FlexContainer, Flex } from "../../styles/common"
import { Logo } from "../../components/common"
import styled, { css } from "styled-components"
import { _Input } from "../../components/form/Input.styles"
import { IconSvg } from "../../components/IconSvg"
import { RadicleMiniLogo } from "../../components/Popup.styles"

const fontFamily = css`
    font-family: Poppins, Lato, Helvetica, Arial, sans-serif;
`

export const _LogoContainer = styled.div`
    padding: 25px 40px;
`

export const _HexLayoutSide = styled.div`
    margin-left: 30px;
`

export const _HexLayoutContainer = styled(_FlexContainer)`
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 60px;
    justify-content: center;
`

export const _HexLayoutContentContainer = styled(_FlexContainer).attrs(() => ({ align: "center" }))`
    ${fontFamily}
    position: relative;
    padding: 90px 65px;
    max-width: 500px;
    min-width: 300px;
    width: 100%;
    border-radius: 19px;
    box-shadow: 0 2px 6px 0 #efedfc;
    border: solid 0.5px #efedfc;
    background: #fff;
    color: #1d253c;
    flex-grow: 1;

    ${_Input} {
        ${fontFamily}
    }

    h1 {
        margin: 0;
    }
`

export const _HexLayoutBackground = styled.div`
    position: absolute;
    overflow: hidden;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &:before {
        position: absolute;
        content: "";
        background: url("/public/hex-background.svg");
        width: 527px;
        height: 839px;
        right: -250px;
        top: 30px;
        z-index: -1;
    }

    &:after {
        position: absolute;
        content: "";
        background: url("/public/hex-background.svg");
        width: 527px;
        height: 839px;
        left: -250px;
        bottom: -250px;
        z-index: -1;
    }

    @media screen and (max-width: 1200px) {
        &:before {
            right: -300px;
            top: -150px;
            z-index: -1;
        }

        &:after {
            left: -200px;
            bottom: -450px;
            z-index: -1;
        }
    }
`

export const _HexLayoutPageContainer = styled(_FlexContainer)`
    position: relative;

    @media screen and (max-width: 1100px) {
        ${_HexLayoutSide} {
            display: none;
        }

        ${_HexLayoutContainer} > * {
            justify-content: center;
        }
    }
`

type HexLayoutProps = { main: React.ReactNode; side?: React.ReactNode }
export const HexLayout = (p: HexLayoutProps) => (
    <_HexLayoutPageContainer grow={1} noshrink>
        <_LogoContainer>
            <Logo width={139} height={35} />
        </_LogoContainer>
        <_HexLayoutContainer grow={1}>
            <Flex align="center" justify="center">
                <_HexLayoutContentContainer noshrink>
                    <RadicleMiniLogo>
                        <IconSvg name="mini-logo" width={36} height={36} />
                    </RadicleMiniLogo>
                    {p.main}
                </_HexLayoutContentContainer>
                {p.side && <_HexLayoutSide>{p.side}</_HexLayoutSide>}
            </Flex>
        </_HexLayoutContainer>
        <_HexLayoutBackground />
    </_HexLayoutPageContainer>
)
