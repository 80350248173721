import * as React from "react"
import * as ReactDOM from "react-dom"
import { connect } from "react-redux"
import { LoadableView } from "../../utils/reactUtils"
import { _BodyFont } from "../../styles/typography"
import { values, remap, identity, filterObject } from "../../../functions/src/utils/map"
import { _VerticalSpace, _FlexRow } from "../../styles/common"
import { getCollectionSchema } from "../../../functions/src/models/schemas"
import { actions as cloudActions } from "../../store/cloud/actions"
import { actions as authActions } from "../../store/auth/actions"
import { getMapDispatch2 } from "../../utils/redux"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { _InputWrapper, _ErrorLabel } from "../../components/form/Input.styles"
import { mapRelationsToOptions } from "../../../functions/src/models/relations/relations"
import { Chips } from "../../components/common/Chips"
import { isEmpty, isOk } from "../../../functions/src/utils/validators"
import { detailsPaths, getCollectionDetailsPathName } from "../../paths"
import { Loaded, Loading, isFetchingOrFetched, getOptionsValues, toOption } from "../../../functions/src/utils/types"
import { ViewLoader } from "../../containers/ViewRenderer"
import { getDataByPath } from "../../store/data/dataSelectors"
import { NavigationParams } from "../../utils/router.types"
import { RootState } from "../../store/store"
import { RadarLocationState } from "../../models/auth"
import { RadarCollectionsVMs } from "../../../functions/src/models/ViewModels"
import { useDebounce } from "../../utils/hooks/useDebounce"
import { getFirebase } from "../../services/firebase"
import { actions } from "../../../functions/src/services/httpEndpoint/actions"
import { _DropdownWrapper, _DropdownOptions, _DropdownOption } from "../../components/Dropdown.styles"
import { SearchInput } from "../../components/form/Input"
import { Loader } from "../../components/common/Loader"
import { cbNoPropagation, selectCreateDiv } from "../../utils/html5"
import { usePrevious } from "../../utils/hooks/usePrevious"
import { createRelationTuple } from "../../store/data/dataUtils"
import { getAvailableCollections } from "../../../functions/src/models/collections"
import { CollectionSearchResult } from "../../../functions/src/models/search"
import { CollectionRelationsEditor } from "../../components/edit/CollectionRelationsEditor"
import { getDropdownPosition } from "../../components/Dropdown"

type StateProps = {
    availableCollections: CName[]
    relations: OMap<CName, ROptions>
    actionsResults: SMap<CloudActionResult>
    relationsCnt: number
}

type OwnProps = { radarId: string; objectId: string; cname: CName }
type ActionProps = ReturnType<typeof mapDispatch>
type Props = StateProps & ActionProps & OwnProps

type SearchProps = { onAdd: F1<ROption> } & Pick<
    CollectionRelationsProps,
    "selected" | "radarId" | "objectId" | "cname"
>
export const RelationSearchBar: React.FC<SearchProps> = p => {
    const [valueRaw, setValueRaw] = React.useState("")
    const [results, setResults] = React.useState<Loadable<{ data: CollectionSearchResult["results"] }>>(Loading())
    const [open, setOpen] = React.useState(false)
    const ref = React.useRef(null)
    const className = `relations-${p.cname}`
    const [refOptions, setRefOptions] = React.useState<HTMLDivElement | null>(null)

    const apiCall = useDebounce((sp: string, radarId: string) => {
        setResults(Loading())
        return getFirebase().functions.callFunctions(actions.search({ radarId, cname: p.cname, phrase: sp }))
    }, 500)

    React.useEffect(() => {
        const body = document.querySelector("body")
        if (!body) return
        const handler = ({ target }: Event) => {
            const t = target as Element
            if (t.closest(`.${className}`) !== ref.current && !t.closest("#dropdown") && open) setOpen(false)
        }
        body.addEventListener("click", handler)
        return () => body.removeEventListener("click", handler)
    }, [open, ref, refOptions, className])

    const setValue = async (v: string) => {
        setResults(Loading())
        apiCall.cancel()
        setValueRaw(v)

        if (isEmpty(v)) setOpen(false)
        else {
            setOpen(true)
            const resp = await apiCall.run(v, p.radarId)
            setResults(
                Loaded({
                    data: isOk(resp)
                        ? (resp.value.searchedObjects.find(r => r.cname === p.cname)?.results || []).filter(
                              o => o.id !== p.objectId
                          )
                        : []
                })
            )
        }
    }

    return (
        <_DropdownWrapper alignToLeft ref={ref} className={className}>
            <SearchInput
                value={valueRaw}
                onFocus={() => setOpen(Boolean(valueRaw))}
                onChange={setValue}
                onClear={() => {
                    setOpen(false)
                    setValueRaw("")
                }}
            />
            {open &&
                ReactDOM.createPortal(
                    <_DropdownOptions
                        ref={setRefOptions}
                        full
                        open={open}
                        position={getDropdownPosition(ref.current, refOptions, "right", "bottom")}>
                        {results.loading ? (
                            <_DropdownOption checkbox>
                                <Loader size="small" />
                            </_DropdownOption>
                        ) : results.data.length ? (
                            results.data
                                .filter(r => !getOptionsValues(p.selected).includes(r.id))
                                .map(res => (
                                    <_DropdownOption
                                        onClick={cbNoPropagation(() => {
                                            p.onAdd(toOption(res.name, res.id))
                                            setOpen(false)
                                            setValueRaw("")
                                        })}
                                        key={res.id}>
                                        {res.name}
                                    </_DropdownOption>
                                ))
                        ) : (
                            <_DropdownOption checkbox>No results found</_DropdownOption>
                        )}
                    </_DropdownOptions>,
                    selectCreateDiv("dropdown")
                )}
        </_DropdownWrapper>
    )
}

type CollectionRelationsProps = {
    title: string
    selected: ROptions
    onAdd: F1<ROption>
    onRemove: F1<string>
    onNavigate: F1<string>
    errors?: Partial<Record<CName, string>>
    isLoading: boolean
    objectId?: string
} & Pick<Props, "radarId" | "cname">
export const CollectionRelations: React.FC<CollectionRelationsProps> = p => (
    <div>
        <_BodyFont s16>{p.title}</_BodyFont>
        <_InputWrapper>
            <_VerticalSpace base="8px" />
            <RelationSearchBar
                onAdd={p.onAdd}
                cname={p.cname}
                radarId={p.radarId}
                selected={p.selected}
                objectId={p.objectId}
            />
            <_VerticalSpace base="8px" />
        </_InputWrapper>
        {p.errors?.[p.cname] && <_ErrorLabel>{p.errors?.[p.cname]}</_ErrorLabel>}
        <_FlexRow>
            {p.isLoading && (
                <div>
                    <Loader size="small" />
                </div>
            )}
            <Chips
                values={p.selected}
                onChipClick={({ value }) => p.onNavigate(value)}
                onChipClose={({ value }) => p.onRemove(value)}
            />
        </_FlexRow>
    </div>
)

export const CardRelations: React.FC<Props> = p => {
    const actionsResults = p.actionsResults
    const [loadingCollection, setLoadingCollection] = React.useState<CName | null>(null)
    const [, onRemoveRelation] = useCloudAction(p.removeRelation, actionsResults)
    const [, onAddRelation] = useCloudAction(p.addRelation, actionsResults)
    const previousRelations = usePrevious(p.relations)

    const onAdd = (cname: CName, relationId: string) => {
        setLoadingCollection(cname)
        onAddRelation({ relation: createRelationTuple(p.objectId, relationId) })
    }
    const onRemove = (_: CName, relationId: string) =>
        onRemoveRelation({ relation: createRelationTuple(p.objectId, relationId) })

    React.useEffect(() => {
        if (!loadingCollection) return
        if (p.relations[loadingCollection]?.length !== previousRelations?.[loadingCollection]?.length)
            setLoadingCollection(null)
    }, [p.relations, loadingCollection, previousRelations])

    return (
        <CollectionRelationsEditor
            mode="list"
            cname={p.cname}
            radarId={p.radarId}
            objectId={p.objectId}
            getSelected={cname => p.relations[cname] || []}
            onAdd={onAdd}
            onRemove={onRemove}
            getIsLoading={cname => cname === loadingCollection}
            onNavigate={(cname, slug) => {
                const { idField } = getCollectionSchema(cname)
                const params: NavigationParams = {
                    path: detailsPaths[getCollectionDetailsPathName(cname)].path,
                    slugs: { [idField]: slug },
                    preserveSearchParams: true
                }
                p.navigate(params)
            }}
        />
    )
}

const mapState = (st: RootState, op: OwnProps) =>
    getDataByPath<StateProps, OwnProps>()(
        getCollectionDetailsPathName(op.cname),
        (deps, { auth: { params }, cloud: { actionsResults } }, { objectId }, rawModels) => {
            const availableCollections = getAvailableCollections((params as RadarLocationState).locationParams)
            const objectRelations: TMap<CName, string[]> = filterObject(deps.relations[objectId] as any, k =>
                availableCollections.includes(k)
            )
            const relationsCnt = values(objectRelations).flat().length

            const relations = mapRelationsToOptions(
                objectRelations,
                {},
                remap(
                    rawModels,
                    identity,
                    v => (isFetchingOrFetched<any>(v) ? v.value : null),
                    (_, v) => v !== null
                ) as RadarCollectionsVMs
            )

            return Loaded({ relations, availableCollections, actionsResults, relationsCnt })
        }
    )(st, op)

const mapDispatch = getMapDispatch2(cloudActions, ["addRelation", "removeRelation"], authActions, ["navigate"])

export const RelationsCardView = connect(mapState, mapDispatch)(LoadableView(ViewLoader, CardRelations))
