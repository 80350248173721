import { values, arrify } from "../functions/src/utils/map"
import { collectionPathParts } from "../functions/src/models/collections"
import { ValidLocationType } from "./models/auth"
import { NamedPath } from "./utils/router.types"

type PathPart = keyof typeof pathParts
type PathParts = PathPart[] | PathPart
export type TypedPath = NamedPath<PathPart>
type NamedPathFn = (name: string, pathParts: PathParts, type: ValidLocationType) => TypedPath

export const mkNamedPath: NamedPathFn = (name, ps, type) => {
    const parts = arrify(ps)
    return {
        name,
        path: parts.reduce((acc, k) => `${acc}/${pathParts[k]}`, ""),
        pathParts: parts,
        params: parts.filter(p => p.startsWith(":") && (params as any)[p] !== undefined),
        type
    }
}

const pathParms = {
    ":hubSlug": ":hubSlug",
    ":radarSlug": ":radarSlug"
}

export const radarPathParams = {
    ":sectorId": ":sectorId",
    ":startupId": ":startupId",
    ":patentId": ":patentId",
    ":patentHolderId": ":patentHolderId",
    ":techTransferId": ":techTransferId",
    ":investorId": ":investorId",
    ":researchHubId": ":researchHubId",
    ":researchPaperId": ":researchPaperId",
    ":techExpertId": ":techExpertId",
    ":influencerId": ":influencerId",
    ":grantId": ":grantId",
    ":trialId": ":trialId",
    ":companyId": ":companyId"
}

const params = {
    ...pathParms,
    ...radarPathParams
}

export const pathParts = {
    ...params,
    app: "app",
    user: "user",
    admin: "admin",
    radicle: "radicle",

    login: "login",
    federated: "federated",
    logout: "logout",

    hubs: "hubs",
    radars: "radars",
    radar: "radar",
    reports: "reports",
    users: "users",
    subscribers: "subscribers",
    tags: "tags",
    areas: "areas",

    new: "new",
    edit: "edit",
    dashboard: "dashboard",
    newsfeed: "newsfeed",

    import: "import",
    priorityImport: "pr-import",
    ...collectionPathParts,
    "map-sectors": "map-sectors",
    pipelines: "pipelines",

    demo: "demo",
    demoForms: "demo-forms",
    demops: "demo-decorators",
    demoDrops: "demo-dropdowns",

    static: "static",
    howToImportData: "how-to-import-data",
    signInWithEmailLink: "sign-in-with-email-link"
}

type PrefixFn = (name: string, pathPs?: PathParts) => TypedPath
const withPrefix = (prefix: PathParts, type: ValidLocationType): PrefixFn => (name, ps) =>
    mkNamedPath(name, ["app", ...arrify(prefix), ...arrify(ps || [])], type)

const userPath = withPrefix("user", "UserLocation")

export const userPaths = {
    "user/adminLogin": userPath("Admin Sign In", "login"),
    "user/federated": userPath("Processing login", [":radarSlug", "federated"]),
    "user/login": userPath("Sign In", [":radarSlug", "login"]),
    "user/logout": userPath("Sign Out", [":radarSlug", "logout"]),
    "user/linkLogin": userPath("Email Sign-in", ["signInWithEmailLink"])
}

const demoPath = withPrefix("radicle", "AdminLocation")

export const demoPaths = {
    "demo/demoForms": demoPath("Demo Path", "demoForms"),
    "demo/demo": demoPath("Demo Path", "demo"),
    "demo/demoDrops": demoPath("Demo Table components", "demoDrops")
}

const adminPath = withPrefix("admin", "AdminLocation")

export const adminPaths = {
    // TODO Remove import paths
    "admin/importPriorities": adminPath("Import priorities", [
        "hubs",
        ":hubSlug",
        "radars",
        ":radarSlug",
        "priorityImport"
    ]),
    "admin/importRadar": adminPath("Import Radar", ["hubs", ":hubSlug", "radars", ":radarSlug", "import"]),
    "admin/dashboard": adminPath("Admin Dashboard", ["radars"]),
    "admin/newRadar": adminPath("Admin Radar Dashboard", ["radar", "new"]),
    "admin/radarDashboard": adminPath("Admin Radar Dashboard", ["radars", ":radarSlug"])
}

const radarPath = withPrefix(":radarSlug", "RadarLocation")

export const staticRadarPaths = {
    "radar/radar": radarPath("Radar"),
    "radar/dashboard": radarPath("Dashboard", "dashboard"),
    "radar/newsfeed": radarPath("Newsfeed", "newsfeed"),
    "radar/reports": radarPath("Reports", "reports")
}

export const collectionPaths = {
    "list/startups": radarPath("Startups", "startups"),
    "list/sectors": radarPath("Sectors", "sectors"),
    "list/companies": radarPath("Products & Technologies", "companies"),
    "list/patents": radarPath("Patents", "patents"),
    "list/tech_transfers": radarPath("Tech Transfers", "tech_transfers"),
    "list/clinical_trials": radarPath("Clinical Trials", "clinical_trials"),
    "list/research_papers": radarPath("Research Papers", "research_papers"),
    "list/grants": radarPath("Grants", "grants"),
    "list/investors": radarPath("Investors", "investors"),
    "list/research_hubs": radarPath("Research Hubs", "research_hubs"),
    "list/tech_experts": radarPath("Tech Experts", "tech_experts"),
    "list/influencers": radarPath("Influencers", "influencers"),
    "list/patent_holders": radarPath("Patent Holders", "patent_holders")
}

export const getListPathName = (cname: CName) => `list/${cname}` as keyof typeof collectionPaths

export const radarPaths = { ...staticRadarPaths, ...collectionPaths }

export const detailsPaths = {
    "details/sectorsMap": radarPath("SECTOR OVERVIEW", ["map-sectors", ":sectorId"]),
    "details/sectors": radarPath("SECTOR OVERVIEW", ["sectors", ":sectorId"]),
    "details/startups": radarPath("STARTUP OVERVIEW", ["startups", ":startupId"]),
    "details/investors": radarPath("INVESTOR OVERVIEW", ["investors", ":investorId"]),
    "details/patent_holders": radarPath("PATENT HOLDER OVERVIEW", ["patent_holders", ":patentHolderId"]),
    "details/patents": radarPath("PATENT OVERVIEW", ["patents", ":patentId"]),
    "details/tech_transfers": radarPath("TECHNOLOGY TRANSFER OVERVIEW", ["tech_transfers", ":techTransferId"]),
    "details/tech_experts": radarPath("TECHNOLOGY EXPERT OVERVIEW", ["tech_experts", ":techExpertId"]),
    "details/research_hubs": radarPath("RESEARCH HUB OVERVIEW", ["research_hubs", ":researchHubId"]),
    "details/research_papers": radarPath("RESEARCH PAPER OVERVIEW", ["research_papers", ":researchPaperId"]),
    "details/influencers": radarPath("INFLUENCER OVERVIEW", ["influencers", ":influencerId"]),
    "details/grants": radarPath("GRANT OVERVIEW", ["grants", ":grantId"]),
    "details/clinical_trials": radarPath("CLINICAL TRIAL OVERVIEW", ["clinical_trials", ":trialId"]),
    "details/companies": radarPath("PRODUCT & TECHNOLOGY OVERVIEW", ["companies", ":companyId"])
}
export const getCollectionDetailsPathName = (cname: CName) => `details/${cname}` as keyof typeof detailsPaths

export const editPaths = {
    "edit/sectors": radarPath("EDIT SECTOR", ["sectors", "edit", ":sectorId"]),
    "edit/startups": radarPath("EDIT STARTUP", ["startups", "edit", ":startupId"]),
    "edit/investors": radarPath("EDIT INVESTOR", ["investors", "edit", ":investorId"]),
    "edit/patent_holders": radarPath("EDIT PATENT HOLDER", ["patent_holders", "edit", ":patentHolderId"]),
    "edit/patents": radarPath("EDIT PATENT", ["patents", "edit", ":patentId"]),
    "edit/tech_transfers": radarPath("EDIT TECHNOLOGY TRANSFER", ["tech_transfers", "edit", ":techTransferId"]),
    "edit/tech_experts": radarPath("EDIT TECHNOLOGY EXPERT", ["tech_experts", "edit", ":techExpertId"]),
    "edit/research_hubs": radarPath("EDIT RESEARCH HUB", ["research_hubs", "edit", ":researchHubId"]),
    "edit/research_papers": radarPath("EDIT RESEARCH PAPER", ["research_papers", "edit", ":researchPaperId"]),
    "edit/influencers": radarPath("EDIT INFLUENCER", ["influencers", "edit", ":influencerId"]),
    "edit/grants": radarPath("EDIT GRANT", ["grants", "edit", ":grantId"]),
    "edit/clinical_trials": radarPath("EDIT CLINICAL TRIAL", ["clinical_trials", "edit", ":trialId"]),
    "edit/companies": radarPath("EDIT PRODUCT & TEHCHNOLOGY", ["companies", "edit", ":companyId"])
}
export const getCollectionEditPathName = (cname: CName) => `edit/${cname}` as keyof typeof editPaths

const dashboardPath = withPrefix([":radarSlug", "dashboard"], "RadarLocation")

export const dashboardDetailsPaths = {
    "dashboard/sectors": dashboardPath("SECTOR OVERVIEW", ["sectors", ":sectorId"]),
    "dashboard/startups": dashboardPath("STARTUP OVERVIEW", ["startups", ":startupId"]),
    "dashboard/investors": dashboardPath("INVESTOR OVERVIEW", ["investors", ":investorId"]),
    "dashboard/patents": dashboardPath("PATENT OVERVIEW", ["patents", ":patentId"]),
    "dashboard/patent_holders": dashboardPath("PATENT HOLDER OVERVIEW", ["patent_holders", ":patentHolderId"]),
    "dashboard/tech_transfers": dashboardPath("TECHNOLOGY TRANSFER OVERVIEW", ["tech_transfers", ":techTransferId"]),
    "dashboard/tech_experts": dashboardPath("TECHNOLOGY EXPERT OVERVIEW", ["tech_experts", ":techExpertId"]),
    "dashboard/research_hubs": dashboardPath("RESEARCH HUB OVERVIEW", ["research_hubs", ":researchHubId"]),
    "dashboard/research_papers": dashboardPath("RESEARCH PAPER OVERVIEW", ["research_papers", ":researchPaperId"]),
    "dashboard/influencers": dashboardPath("INFLUENCER OVERVIEW", ["influencers", ":influencerId"]),
    "dashboard/grants": dashboardPath("GRANT OVERVIEW", ["grants", ":grantId"]),
    "dashboard/clinical_trials": dashboardPath("CLINICAL TRIAL OVERVIEW", ["clinical_trials", ":trialId"]),
    "dashboard/companies": dashboardPath("PRODUCT & TECHNOLOGY OVERVIEW", ["companies", ":companyId"])
}

export const getDashboardDetailsPathName = (cname: CName) => `dashboard/${cname}` as keyof typeof dashboardDetailsPaths

export const staticPaths = {
    "static/howToImportData": withPrefix("static", "StaticLocation")("HOW TO IMPORT DATA", ["howToImportData"])
}

export const paths = {
    ...userPaths,
    ...demoPaths,
    ...adminPaths,
    ...editPaths,
    ...radarPaths,
    ...detailsPaths,
    ...dashboardDetailsPaths,
    ...staticPaths
}

export type Paths = typeof paths
export type Path = keyof Paths

export const allPaths: TypedPath[] = [
    ...values(userPaths),
    ...values(adminPaths),
    ...values(editPaths),
    ...values(radarPaths),
    ...values(detailsPaths),
    ...values(dashboardDetailsPaths),
    ...values(staticPaths)
]
