import { actions as uiActions } from "./ui/actions"
import { actions as importingActions } from "./importing/actions"
import { actions as cloudActions } from "./cloud/actions"
import { actions as authActions } from "./auth/actions"
import { actions as asyncActions } from "./async/actions"
import { actions as dataActions } from "./data/actions"

export const allActions = {
    ...uiActions,
    ...importingActions,
    ...dataActions,
    ...cloudActions,
    ...authActions,
    ...asyncActions
}

export type ActionCreators = typeof allActions

export type ActionType = keyof ActionCreators
export type Action<T extends ActionType> = ReturnType<ActionCreators[T]>
export type Actions = Action<ActionType>
export type ActionParams<T extends ActionType> = Parameters<ActionCreators[T]>
export type ActionDispatchFn<T extends ActionType> = (...args: ActionParams<T>) => void
