import * as Papa from "papaparse"
import { keys, toMap, values, intersect, identity, filterObject } from "../../functions/src/utils/map"
import { collections, allImportableFields } from "../../functions/src/models/schemas"
import { Err, Ok, isEmpty, isOk } from "../../functions/src/utils/validators"
import { collectionsParsers, relationParsers } from "../../functions/src/models/collections"
import { mergeFields } from "../../functions/src/models/RFields"
import { concatWithSeparator } from "../utils"
import { UnrecognizedFileMsg, UnrecognizedFileFields, UnrecognizedFilePayload } from "../store/store"
import { ViewModelsMap } from "../../functions/src/models/ViewModels"
import { PriorityPayload, FileFields } from "../../functions/src/models/importing.types"
import { classifiersParser, prepareStringArray } from "../../functions/src/models/common"
import { getUniqueFields } from "../../functions/src/models/uniqueConstraint"

export const fieldsCompErrors = {
    duplicatedReq: "Required fields duplicated.",
    duplicatedOpt: "Optional fields duplicated.",
    unknownField: "Unknown fields: "
}

const Unrecognized = (msg: string): UnrecognizedFileMsg => ({
    type: "Msg",
    msg
})
const MissingFields = (p: { missingFields: string[]; unknownFields: string[] }): UnrecognizedFileFields => ({
    type: "Fields",
    ...p
})

export const scoreCollections = (current: string[]): OMap<CName, number> =>
    keys(collections).reduce((acc, cname) => {
        const cfields = collections[cname].fields
        const requiredMap = toMap(
            cfields.required,
            k => k as string,
            () => null as any
        )
        const optionalMap = toMap(
            cfields.optional,
            k => k as string,
            () => null as any
        )
        current.forEach(f => {
            if (requiredMap[f] === null) {
                requiredMap[f] = 1
            } else if (optionalMap[f] >= 0) {
                optionalMap[f]++
            }
        })
        const sumValues = <T extends TMap<any, number>>(map: T) => values(map).reduce((s, e) => s + e, 0)
        const reqScore = sumValues(requiredMap)
        const optScore = sumValues(optionalMap)
        return { ...acc, [cname]: reqScore + optScore }
    }, {} as any)

export const compareFields = <T1, T2>(
    cfields: FieldKeys<T1, T2, T1 & T2>,
    current: string[],
    uniqueFields: string[]
): Result<string, UnrecognizedFilePayload> => {
    const requiredMap = toMap(
        cfields.required.filter(f => !uniqueFields.includes(f as any)),
        k => k as string,
        () => 0
    )
    const optionalMap = toMap(
        cfields.optional.filter(f => !uniqueFields.includes(f as any)),
        k => k as string,
        () => 0
    )
    const uniqueMap = toMap(
        uniqueFields,
        k => k as string,
        () => 0
    )

    const unknownMap: SMap<number> = {}
    current.forEach(f => {
        if (requiredMap[f] >= 0) requiredMap[f]++
        else if (optionalMap[f] >= 0) optionalMap[f]++
        else if (uniqueMap[f] >= 0) uniqueMap[f]++
        else unknownMap[f] = 1
    })

    const missing = filterObject(uniqueMap, (_, v) => v < 1)

    const unknownFields = keys(unknownMap)
    const missingFields = keys(missing)

    if (keys(missing).length > 0) return Err(MissingFields({ missingFields, unknownFields }))

    let okMsg = ""
    if (values(requiredMap).filter(v => v > 1).length > 0 || values(uniqueMap).filter(v => v > 1).length > 0)
        okMsg = concatWithSeparator(okMsg, fieldsCompErrors.duplicatedReq)
    if (values(optionalMap).filter(v => v > 1).length > 0)
        okMsg = concatWithSeparator(okMsg, fieldsCompErrors.duplicatedOpt)
    if (keys(unknownMap).length > 0)
        okMsg = concatWithSeparator(okMsg, fieldsCompErrors.unknownField + unknownFields.toString()) // skip
    return Ok(okMsg)
}

const highestScoreCollection = (scoring: OMap<CName, number>): CName | null =>
    keys(scoring).reduce((highest, current) => {
        if (!highest) return scoring[current]! > 0 ? current : null
        return scoring[current]! > scoring[highest!] ? current : highest
    }, null)

export const matchCollection = (
    fields: string[],
    availableCollections: CName[]
): Result<CName, UnrecognizedFilePayload> => {
    const r = keys(collections).find(cname => {
        const cfields = collections[cname].fields
        const crelations = collections[cname].relations
        const uniqueFieldsResult = getUniqueFields(collections[cname].uniqueConstraint)
        const uniqueFields = isOk(uniqueFieldsResult) ? uniqueFieldsResult.value : []

        const res = compareFields(mergeFields(cfields, crelations), fields, uniqueFields)
        return isOk(res)
    })

    if (!r) {
        const score = scoreCollections(fields)
        const highestScoredCName = highestScoreCollection(score)
        if (highestScoredCName === null || !availableCollections.includes(highestScoredCName))
            return Err(
                Unrecognized("Unable to reason collection. Please refer to FAQ as guidance how to create import files")
            )
        const matchedFields = mergeFields(
            collections[highestScoredCName].fields,
            collections[highestScoredCName].relations
        )
        const uniqueFieldsResult = getUniqueFields(collections[highestScoredCName].uniqueConstraint)
        const uniqueFields = isOk(uniqueFieldsResult) ? uniqueFieldsResult.value : []
        const comparisonRes = compareFields(matchedFields, fields, uniqueFields) as Err<UnrecognizedFilePayload>
        return comparisonRes
    } else if (!availableCollections.includes(r)) {
        return Err(Unrecognized("Cannot import collection. Please refer to FAQ as guidance how to create import files"))
    } else {
        return Ok(r)
    }
}
export const readHeaderFields = async (file: File | string) =>
    new Promise<string[]>(res =>
        Papa.parse(file, { header: true, preview: 1, error: () => res([]), complete: ({ meta }) => res(meta.fields) })
    )

export const parseFields = async <T extends CName>(cname: T, file: File) =>
    new Promise<{ items: CollectionItem<T>[]; fields: FileFields<T> }>(res => {
        Papa.parse(file, {
            dynamicTyping: true,
            header: true,
            skipEmptyLines: true,
            complete: ({ data, meta, errors }) => {
                const parsed: CollectionItem<T>[] = data.map(d => ({
                    type: "new", // at this point we treat each item as new
                    edited: false,
                    fields: collectionsParsers[cname](d) as Result<RCollection<T>>,
                    relations: relationParsers[cname](d) as Result<RCollectionRelations<T>>,
                    classifiers: classifiersParser(d)
                }))
                const fields: FileFields<T> = intersect(allImportableFields(cname), meta.fields as any)
                // eslint-disable-next-line no-console
                errors.forEach(e => console.log(`Error parsing fields on ${cname}`, e))
                res({ items: parsed, fields })
            }
        })
    })

export const parsePrioritiesPayload = async <C extends CName>(collectionFields: (keyof RCollection<C>)[], file: File) =>
    new Promise<Result<PriorityPayload<C>[]>>(res => {
        Papa.parse(file, {
            dynamicTyping: true,
            header: true,
            complete: ({ data, meta }) => {
                const parsingErrors: string[] = []
                const { fields } = meta
                const priorityRankField = "priority_rank"
                const searchAreaField = "search_areas"
                if (!fields.find(f => f === searchAreaField))
                    parsingErrors.push(`Search areas not present in header. Should contain: ${searchAreaField}`)
                if (!fields.find(f => f === priorityRankField))
                    parsingErrors.push(`Priority rank not present in header. Should contain: ${priorityRankField}`)
                const commonFields = intersect(fields, collectionFields as string[]).filter(f => f !== searchAreaField)
                if (isEmpty(commonFields)) {
                    const cFieldsDisplay = collectionFields.join(", ")
                    parsingErrors.push(
                        `Didn't find any object type specific field in header. Expected fields: ${cFieldsDisplay}`
                    )
                }

                if (!isEmpty(parsingErrors)) return res(Err(parsingErrors.join("; ")))
                const parsedData = data
                    .map((fs): PriorityPayload<C>[] => {
                        const searchAreas = prepareStringArray(fs[searchAreaField] || "")
                        return searchAreas.map(sa => ({
                            searchArea: sa,
                            priority: fs[priorityRankField] || "",
                            matchers: toMap(commonFields, identity, field => fs[field] || "") as Partial<
                                ViewModelsMap[C]
                            >
                        }))
                    })
                    .flat()
                return res(Ok(parsedData))
            }
        })
    })

export const matchHeaderWithCollection = async (file: File, availableCollections: CName[]) =>
    matchCollection(await readHeaderFields(file), availableCollections)
