import * as React from "react"
import { _Tab, _TabSelector, _TabContentWrapper, _TabSubmenuContainer } from "./Tabs.styles"
import { _VerticalSpace, Flex, _AbsPosContainer, _AbsPosElement, Margin, Grid, _FlexColumn } from "../../styles/common"
import { assertNever } from "../../../functions/src/utils"
import { keys } from "../../../functions/src/utils/map"
import { partition } from "lodash"
import { debounce, isEqual } from "../../utils"
import { IconSvg } from "../IconSvg"
import { isEmpty } from "../../../functions/src/utils/validators"
import { useOutsideClick } from "../../utils/hooks/useOutsideClick"
import { _MeatballMenuContainer } from "../table/TableView.styles"

export type TabRenderable = ViewRenderable<any, React.ReactNode>
export type TabText = ViewText<unknown>
export type TabContent = TabText | TabRenderable
export type Legacy = { legacy: boolean }

export const TabRenderable = (render: F1<any, React.ReactNode>): TabRenderable => ({
    type: "Renderable",
    render
})
export const TabText = (value: string): TabText => ({ type: "Text", value })

export type Tab<ID extends string, T1 = TabContent, T2 = TabContent> = { id: ID; title: T1; content: T2 }

export type TabsProps<T extends string> = {
    data: Tab<T>[]
    scrollable?: boolean
    onChange?: F1<T>
    setOverflowRef?: F1<HTMLDivElement | null>
    legacy?: boolean
}

type TabsRefs<T extends string> = TMap<T, HTMLElement | null>

export const getFirstHiddenElementIndex = (
    domElements: HTMLElement[],
    containerWidth: number,
    totalWidth: number,
    elementIndex: number
): number => {
    if (totalWidth > containerWidth) return elementIndex - 1
    if (elementIndex == domElements.length) return elementIndex
    return getFirstHiddenElementIndex(
        domElements,
        containerWidth,
        totalWidth + (domElements[elementIndex] ? domElements[elementIndex].scrollWidth : 0),
        elementIndex + 1
    )
}

export const Tabs = <T extends string>({ legacy = true, ...p }: React.PropsWithChildren<TabsProps<T> & Legacy>) => {
    const [active, setActive] = React.useState(p.data[0].id)
    const [hiddenTabs, setHiddenTabs] = React.useState<T[]>([])
    const [containerRef, setContainerRef] = React.useState<HTMLDivElement | null>(null)
    const [containerWidth, setContainerWidth] = React.useState(0)
    const tabsRefs = React.useRef<TabsRefs<T>>({} as any)
    const activeTab = p.data.find(t => t.id === active)
    const [menuOpen, setMenuOpen] = React.useState(false)

    useOutsideClick("tabs-container", containerRef && containerRef.querySelector(".tabs-container"), () =>
        setMenuOpen(false)
    )

    React.useEffect(() => {
        if (!activeTab) setActive(p.data[0].id)
    }, [p.data]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useLayoutEffect(() => {
        if (!legacy) {
            const handleResize = () => setContainerWidth(containerRef?.offsetWidth || 0)
            handleResize()
            const handleResizeDebounced = debounce(handleResize, 50)
            window.addEventListener("resize", handleResizeDebounced)
            return () => {
                handleResizeDebounced.cancel()
                window.removeEventListener("resize", handleResizeDebounced)
            }
        }
    }, [containerRef, legacy])

    React.useLayoutEffect(() => {
        if (containerWidth && !legacy) setHiddenTabs([])
    }, [containerWidth, legacy])

    React.useLayoutEffect(() => {
        if (!legacy) {
            const refs = keys(tabsRefs.current)
                .filter(k => !hiddenTabs.includes(k) && !isEmpty(tabsRefs.current[k]))
                .map(k => tabsRefs.current[k]) as HTMLElement[]
            if (containerWidth) {
                const tabIndex = getFirstHiddenElementIndex(refs, containerWidth, 90, 0)

                const newHiddenTabs = tabIndex ? p.data.slice(tabIndex).map(d => d.id) : []
                if (!isEqual(newHiddenTabs, hiddenTabs)) setHiddenTabs(newHiddenTabs)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.data, hiddenTabs, legacy])

    const [visibleTabs, inMenuTabs] = partition(p.data, d => !hiddenTabs.includes(d.id))

    return (
        <_AbsPosContainer ref={p.setOverflowRef}>
            <_AbsPosElement>
                <_FlexColumn grow={1}>
                    <Grid columns="minmax(0, 1fr) 260px">
                        <Flex grow={1} ref={setContainerRef}>
                            <_TabSelector className="tabs-container" data-cy="tabs-container" legacy={legacy}>
                                {visibleTabs.map(d => (
                                    <_Tab
                                        legacy={legacy}
                                        selected={active === d.id}
                                        onClick={() => {
                                            setActive(d.id)
                                            setMenuOpen(false)
                                            if (p.onChange) p.onChange(d.id)
                                        }}
                                        key={d.id}
                                        ref={r => (tabsRefs.current[d.id] = r)}>
                                        <Content {...d.title} />
                                    </_Tab>
                                ))}
                                {inMenuTabs.length ? (
                                    <_Tab
                                        legacy={legacy}
                                        onClick={() => {
                                            setMenuOpen(!menuOpen)
                                        }}
                                        selected={false}
                                        key="menuIcon">
                                        <Margin left="10px" right="10px">
                                            <_MeatballMenuContainer size={24}>
                                                <IconSvg name="more" width={24} height={24} />
                                            </_MeatballMenuContainer>
                                        </Margin>
                                    </_Tab>
                                ) : null}
                                <_TabSubmenuContainer open={menuOpen}>
                                    {inMenuTabs.map(d => (
                                        <_Tab
                                            legacy={legacy}
                                            selected={active === d.id}
                                            onClick={() => {
                                                setActive(d.id)
                                                setMenuOpen(false)
                                                if (p.onChange) p.onChange(d.id)
                                            }}
                                            key={d.id}
                                            ref={r => (tabsRefs.current[d.id] = r)}>
                                            <Content {...d.title} />
                                        </_Tab>
                                    ))}
                                </_TabSubmenuContainer>
                            </_TabSelector>
                        </Flex>
                        <Flex noshrink align="center">
                            {p.children}
                        </Flex>
                    </Grid>
                    <_VerticalSpace base="20px" />
                    <_TabContentWrapper scrollable={p.scrollable}>
                        {activeTab && <Content {...activeTab.content} />}
                    </_TabContentWrapper>
                </_FlexColumn>
            </_AbsPosElement>
        </_AbsPosContainer>
    )
}

export const Content: React.FC<TabContent> = p => {
    switch (p.type) {
        case "Text":
        case "Title":
            return <>{p.value}</>
        case "Renderable":
            return <>{p.render(p)}</>
    }
    assertNever(p)
}
