import * as React from "react"
import { connect } from "react-redux"
import { actions as cloudActions } from "../../store/cloud/actions"
import { actions as uiActions } from "../../store/ui/actions"

import { Loaded, Loading } from "../../../functions/src/utils/types"
import { getMapDispatch2 } from "../../utils/redux"
import { isLoggedIn } from "../../models/LoginStatus"
import { LoadableView } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import { getDataByPath } from "../../store/data/dataSelectors"
import { getCollectionDetailsPathName } from "../../paths"
import { CloudState, RootState } from "../../store/store"
import { ViewModelsBase } from "../../../functions/src/models/ViewModels"
import { AuthenticatedState } from "../../models/auth"
import { filterContactedLeads } from "../../../functions/src/models/contactedLeads"
import { _BodyFont } from "../../styles/typography"
import { _LeadDescriptionWrapper, LeadsTableWrapper, LeadContentWrapper } from "./CardLeads.styles"
import { _HorizontalSpace, _VerticalSpace, _AbsPosElement, _AbsPosContainer } from "../../styles/common"
import { ButtonGroup, Button, ClearButton } from "../../styles/buttons"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { Loader } from "../../components/common/Loader"
import { useFormHook } from "../../utils/hooks/useFormHook"
import { getLeadSchema } from "../../models/contactedLeads"
import { FormView } from "../../components/form/FormView"
import { Msg } from "../../models/notifications"
import { CardStateBaseProps } from "./Card"
import { _CardSectionContainer, _CardSectionTitle } from "../../components/newCards/sections/CardSection.styles"
import { RowSchema } from "../../components/table/TableView.types"
import { mkTableTextSchema, mkTableComputedSchema } from "../../components/table/TableViewSchemas"
import { Table } from "../../components/table/TableView"
import { HeaderTitle, mkRow } from "../../components/table/TableViewController"
import { mapRichTextValue } from "../../../functions/src/utils/richText"
import { toDate } from "../../models/dateTime"
import { mapObject, toMap } from "../../../functions/src/utils/map"
import { toFormState } from "../../utils/forms"
import { TooltipDescription } from "../../components/tooltip/SectionController"

type StateProps = Loadable<
    Pick<CloudState, "actionsResults"> &
        Pick<ViewModelsBase, "users"> & {
            leads: DisplayComment[]
            userId: string
            auth: AuthenticatedState
        }
>
type OwnProps = Pick<CardStateBaseProps, "radarId" | "objectId" | "cname">
type ActionProps = ReturnType<typeof mapDispatch>
type Props = Loadable<StateProps> & ActionProps & OwnProps

type LeadTableItem = Pick<DisplayComment, "commentId" | "createdTs"> & ContactedLeadDecorator
const LeadsTable = (p: { leads: LeadTableItem[] }) => {
    const schema: RowSchema<LeadTableItem> = [
        mkTableTextSchema("Contact Name", "contactName"),
        mkTableTextSchema("Description", "description"),
        mkTableTextSchema("P&G Project(s)", "projects"),
        mkTableTextSchema("R&D technology", "technology"),
        mkTableComputedSchema("Date", "createdTs", "text", v => toDate(v.createdTs))
    ]
    const tooltipContents = toMap(
        p.leads,
        l => l.commentId,
        l => [TooltipDescription(l.description)]
    )

    return (
        <Table
            headerRow={schema.map(f => HeaderTitle(f.key, f.label, f.size, f.key))}
            rows={p.leads.map(l => mkRow(l.commentId, schema, l))}
            showTooltip
            tooltipContents={tooltipContents}
        />
    )
}

const emptyContactedLead: ContactedLeadDecorator = mapObject(getLeadSchema(), () => "")

type LeadDescriptionProps = Loadable<{ lead: DisplayComment; onEditLead: F1<ObjectComment> }>
const LeadForm: React.FC<LeadDescriptionProps> = p => {
    if (p.loading) return <Loader loadingText="Submitting" />

    const metaValue = (p.lead?.meta?.value as ContactedLeadDecoratorMeta) || { newValue: emptyContactedLead }
    const initialValue = p.lead?.meta && p.lead?.meta?.value.newValue ? metaValue.newValue! : emptyContactedLead
    const { onSubmitClick, resetState, formViewProps } = useFormHook<ContactedLeadDecorator>({
        schema: getLeadSchema(),
        initialValue,
        onSubmit: newValue =>
            p.onEditLead({
                ...p.lead,
                meta: {
                    type: "contactedLead",
                    value: { ...metaValue, type: "lead", oldValue: metaValue.newValue, newValue }
                }
            })
    })

    React.useEffect(() => {
        if (p.lead) formViewProps.setState(toFormState(getLeadSchema(), initialValue))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.lead])

    return (
        <_LeadDescriptionWrapper>
            <_BodyFont s14 bold uppercase>
                Contact History
            </_BodyFont>
            <_VerticalSpace base="6px" />
            <_BodyFont>Please provide the contact details</_BodyFont>
            <_VerticalSpace base="32px" />
            <FormView {...formViewProps} />
            <ButtonGroup>
                <Button onClick={() => onSubmitClick()}>Save</Button>
                <_HorizontalSpace base="8px" />
                <ClearButton onClick={() => resetState(emptyContactedLead)} />
            </ButtonGroup>
        </_LeadDescriptionWrapper>
    )
}

const CardLeads: React.FC<Props> = p => {
    if (p.loading) return <Loader loadingText="Fetching Contacted lead info" />
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [, onEdit] = useCloudAction<ObjectComment>(
        (actionId, ps) => {
            setIsSubmitting(true)
            p.createComment({
                actionId,
                text: "Create Lead comment",
                objectId: p.objectId,
                radarId: p.radarId,
                meta: ps.meta
            })
        },
        p.actionsResults,
        ({ result }) => {
            p.queueNotification(Msg("update", { name: "Contacted lead details" }, result))
            setIsSubmitting(false)
        }
    )

    const contactedLeads = React.useMemo(
        () =>
            p.leads
                .filter(l => l.meta && l.meta.type === "contactedLead" && l.meta.value.newValue)
                .map(l => ({
                    ...(l.meta?.value.newValue as ContactedLeadDecorator),
                    createdTs: l.createdTs,
                    commentId: l.commentId,
                    description: mapRichTextValue((l.meta?.value.newValue as ContactedLeadDecorator).description)
                })),
        [p.leads]
    )

    return (
        <_CardSectionContainer big>
            <_CardSectionTitle>Contacted Lead</_CardSectionTitle>
            <_BodyFont s15>
                Track your history of previous contacts and all the details
                <br />
                about the past conversations by typing them here.
            </_BodyFont>
            <LeadContentWrapper>
                <LeadForm lead={p.leads[0]} onEditLead={onEdit} loading={isSubmitting} />
                <LeadsTableWrapper>
                    <_BodyFont s15 bold uppercase>
                        Contact History
                    </_BodyFont>
                    <_VerticalSpace base="26px" />
                    <_AbsPosContainer>
                        <_AbsPosElement>
                            <LeadsTable leads={contactedLeads} />
                        </_AbsPosElement>
                    </_AbsPosContainer>
                </LeadsTableWrapper>
            </LeadContentWrapper>
        </_CardSectionContainer>
    )
}

const mapState = (st: RootState, op: OwnProps) =>
    getDataByPath<StateProps, OwnProps>()(
        getCollectionDetailsPathName(op.cname),
        (deps, { cloud: { actionsResults }, auth: { authentication } }, { objectId }) => {
            if (!isLoggedIn(authentication)) return Loading()

            const leads = filterContactedLeads(deps.comments[objectId] || [])
            const users = deps.users
            const userId = authentication.user.userId!

            return Loaded({
                leads,
                actionsResults,
                users,
                userId,
                radarConfig: deps.config,
                auth: authentication
            })
        }
    )(st, op)

const mapDispatch = getMapDispatch2(cloudActions, ["mutateDecorator", "createComment"], uiActions, [
    "queueNotification"
])

export const ContactedLeadsCardView = connect(mapState, mapDispatch)(LoadableView(ViewLoader, CardLeads))
