import * as React from "react"
import { _VerticalSpace } from "../../styles/common"
import { _h1 } from "../../styles/typography"

export const SectionTitle = (p: { sectionTitle?: string }) =>
    p.sectionTitle ? (
        <>
            <_VerticalSpace base="16px" />
            <_h1 style={{ width: "100%" }}>{p.sectionTitle}</_h1>
            <_VerticalSpace base="12px" />
        </>
    ) : null
