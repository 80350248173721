import * as React from "react"
import { Popup } from "../Popup"
import { PopupInner } from "../Popup.styles"
import { _BodyFont } from "../../styles/typography"
import { _VerticalSpace, _Center } from "../../styles/common"
import { Button } from "../../styles/buttons"
import { assertNever } from "../../../functions/src/utils"
import { AttachmentInfoMeta } from "../../store/store"

const popupMessage = ({ type }: AttachmentInfoMeta): string => {
    switch (type) {
        case "bigFile":
            return "The size of your file exeeds 10MB. We have a file limit of 10MB."
        case "multiple":
            return "You cannot upload multiple files at once. Please upload one file at a time."
    }
    assertNever(type)
}

export const AttachmentInfoPopup: React.FC<{ onClose: F0; meta: AttachmentInfoMeta }> = ({ onClose, meta }) => (
    <Popup onClose={onClose} logo>
        <PopupInner>
            <_Center>
                <_BodyFont s17>{popupMessage(meta)}</_BodyFont>
                <_VerticalSpace base="24px" />
                <Button onClick={onClose}>OK</Button>
            </_Center>
        </PopupInner>
    </Popup>
)
