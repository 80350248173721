import * as React from "react"
import { connect } from "react-redux"
import { MapState, MapDispatch } from "../../utils/redux.types"
import { toFormState } from "../../utils/forms"
import { actions } from "../../store/auth/actions"
import { Popup } from "../Popup"
import { PopupInner } from "../Popup.styles"
import { _BodyFont, _h2 } from "../../styles/typography"
import { FormView } from "../form/FormView"
import { validateEmail, isOk, Err } from "../../../functions/src/utils/validators"
import { rejectTemporaryMailboxes } from "../../../functions/src/utils/emailBlacklist"
import { isDone } from "../../../functions/src/utils/types"
import { _FormViewWrapper } from "../../styles/forms"
import { _VerticalSpace } from "../../styles/common"
import { Button } from "../../styles/buttons"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { useFormHook } from "../../utils/hooks/useFormHook"

type EmailState = {
    results: SMap<CloudActionResult>
}
type EmailActions = {
    checkDeliverableEmail: F2<string, string>
}
type Props = { onFormSubmit: F1<string> } & EmailActions & EmailState

const initial = { email: "" }

const emailSchema: FormSchema<{ email: string }> = {
    email: {
        type: "text",
        placeholder: "Your email",
        validators: [validateEmail, rejectTemporaryMailboxes]
    }
}

// This type signature tells TS to accept arbitrary strings as indexes here.
const Reasons: { [index: string]: string } = {
    "do_not_mail: disposable": "Do not use disposable addresses."
}

const GetEmailComponent = (p: Props) => {
    // checkDeliverableEmail is provided by Redux's connect() and its dispatch map.
    // This turns it into a single-argument callable which initiates the process.
    // The action to be dispatched is created in src/store/auth/actions
    const [deliverableState, checkDeliverableEmail] = useCloudAction(p.checkDeliverableEmail, p.results)
    const [email, setEmail] = React.useState("")
    const [submitLabel, setSubmitLabel] = React.useState("Send")
    const [isVerifying, setVerifying] = React.useState(false)

    const onSubmit = (emailEntered: string) => {
        setEmail(emailEntered)
        setVerifying(true)
        setSubmitLabel("Verifying e-mail address...")
        checkDeliverableEmail(emailEntered)
        // Don't call p.onFormSubmit just yet. Wait until results arrive.
        // See useEffect below
    }

    // Standard form boilerplate
    const { formViewProps, onSubmitClick } = useFormHook({
        schema: emailSchema,
        initialValue: initial,
        onSubmit: v => onSubmit(v.email)
    })

    const setError = (message: string) => {
        // eslint-disable-next-line no-console
        console.log(message)

        const reason = Reasons[message] ?? "Use a valid, deliverable, non-disposable e-mail address."

        // Manually set form to invalid. Use our error message as validation error.
        const state = toFormState(emailSchema, { email: email })
        state.email.visited = true
        state.email.validationResult = Err(reason, email)
        formViewProps.setState(state)
    }

    // This triggers when deliverableState changes. Which happens (per mapState)
    // once cloud.actionResults is updated, which is exactly when a public action returns.
    React.useEffect(() => {
        if (!isDone(deliverableState)) return

        const result = deliverableState.value.result
        // The e-mail validated correctly, we can proceed
        setVerifying(false)
        setSubmitLabel("Send")

        if (isOk(result)) {
            p.onFormSubmit(email)
            return
        }

        setError(result.value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliverableState.type])

    return (
        <Popup logo>
            <PopupInner>
                <_FormViewWrapper>
                    <_h2>To access this radar, you have to provide your email address</_h2>
                    <_VerticalSpace base="16px" />
                    <FormView {...formViewProps} />
                    <_BodyFont s14>
                        By submitting, you agree to our <a href="/terms">T&C</a>
                    </_BodyFont>
                    <_VerticalSpace base="16px" />
                    <Button wide disabled={isVerifying} onClick={onSubmitClick}>
                        {submitLabel}
                    </Button>
                    <_VerticalSpace base="16px" />
                </_FormViewWrapper>
            </PopupInner>
        </Popup>
    )
}

// Make the actionsResults collection available in a component's properties.
// Pass this as the first argument to connect().
// The argument is a RootState with many useful things, see src/store/store.ts
const mapState: MapState<EmailState> = ({ cloud }) => ({
    results: cloud.actionsResults
})

// Make named dispatching functions available in a component's properties.
// Pass this as second argument to connect().
const mapDispatch: MapDispatch<EmailActions> = dispatch => ({
    checkDeliverableEmail: (actionId, email) => dispatch(actions.checkDeliverableEmail(actionId, email))
})

export const GetEmail = connect(mapState, mapDispatch)(GetEmailComponent)
