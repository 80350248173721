import styled from "styled-components"
import { _FlexContainer } from "../../styles/common"
import { CardTooltipProps } from "./CardTooltip"

export const _CardTooltipContainer = styled(_FlexContainer)<Partial<CardTooltipProps>>`
    position: absolute;
    top: ${p => p.position?.top};
    bottom: ${p => p.position?.bottom};
    left: ${p => p.position?.left};
    right: ${p => p.position?.right};
    padding: ${p => p.theme.sizes.defaultPadding}px ${p => 0.6 * p.theme.sizes.defaultPadding}px;
    width: 300px;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(44, 64, 90, 0.25);
    border: solid 0.5px #e2e5e9;
    border-radius: 13px;
    z-index: 1;

    & > *:not(:last-child) {
        margin-bottom: ${p => 0.6 * p.theme.sizes.defaultPadding}px;
    }
`
