import { mkTableNumberSchema, mkTableTextSchema, mkTableFileSchema } from "../components/table/TableViewSchemas"
import { toDateString } from "./dateTime"
import { FileStatus } from "../utils/hooks/useStorage"
import { getFirebase } from "../services/firebase"
import { StorageService, FileRef } from "../services/storage"
import { decryptFile } from "../../functions/src/utils/crypto"
import { downloadBlob } from "../services/export"
import { getUserNameFromId } from "../../functions/src/models/decoratorValues"
import { ViewModelsBase } from "../../functions/src/models/ViewModels"
import { RowSchema } from "../components/table/TableView.types"

export type Attachment = { filename: string; addedDate: string; authorName: string; authorId: string; id: string }

export const attachmentBaseRowSchema: RowSchema<Pick<Attachment, "filename" | "addedDate" | "authorName">> = [
    mkTableFileSchema("File", "filename"),
    mkTableTextSchema("Added", "addedDate"),
    mkTableTextSchema("Added by", "authorName")
]

export const mapRawAttachment: F1<Pick<ViewModelsBase, "users">, F2<string, AttachmentFile, Attachment>> = vms => (
    id,
    a
) => ({
    filename: a.filename,
    authorName: getUserNameFromId(vms.users, a.authorId),
    authorId: a.authorId,
    addedDate: toDateString(a.createdAt),
    id
})

export const getRowFromUploadingSchema = (key: string, file: FileStatus): UploadingFile[] => {
    switch (file.type) {
        case "Processing":
            return [{ key, filename: file.filename, progress: "Uploading..." }]
        case "Encrypting":
            return [{ key, filename: file.filename, progress: "Encrypting..." }]
        case "Err":
            return [{ key, filename: file.filename, progress: "Error occured while loading" }]
        case "Done":
        case "NotStarted":
            return []
    }
}

export type UploadingFile = { key: string; filename: string; progress?: string }
export const uploadingRowSchema: RowSchema<UploadingFile> = [
    mkTableFileSchema("File", "filename"),
    mkTableNumberSchema("Progress", "progress")
]

export const downloadFile = async (
    filename: string,
    encryptionKey: EncryptionKey,
    getRef: F1<StorageService, FileRef<any>>
) => {
    const { storage } = getFirebase()

    const ref = getRef(storage)
    const blob = await storage.download(ref)
    const decrypted = await decryptFile(blob, encryptionKey)
    downloadBlob(decrypted, filename)
}
