import {
    validate,
    validString,
    validNumber,
    validStringDef,
    validArrayStringDef,
    validNumberDef,
    validNotEmptyArrayString,
    validNumberDefNull
} from "../utils/validators"

import { mapToDisplay, parseString } from "./common"
import { formatDecimal, formatMillions } from "./converters"
import { RFields } from "./RFields"
import {
    DEFAULT_PLACEHOLDER,
    sharedRequiredRadarFieldsValidationMap,
    sharedOptionalRadarFieldsValidationMap,
    sharedVMMetaValidationMap
} from "./shared"
import { SectorVM, DisplayModelsMap } from "./ViewModels"

const requiredSectorFieldsValidationMap: ValidationMap<Required<SectorFieldsRequired>> = {
    sector_name: validString,
    row: validNumber,
    column: validNumber,
    company_hex_display: validString,
    ...sharedRequiredRadarFieldsValidationMap
}

const optionalSectorFieldsValidationMap: ValidationMap<Required<SectorFieldsOptional>> = {
    ...sharedOptionalRadarFieldsValidationMap,
    investor_quality: validNumberDefNull,
    avg_company_age: validNumberDefNull,
    priority_score: validNumberDefNull,
    sector_funding_momentum: validNumberDefNull,
    funding_momentum: validNumberDefNull,
    incumbents: validArrayStringDef,
    market_size: validStringDef,
    market_size_url: validStringDef,
    capital_traction_url: validStringDef,
    customer_type: validArrayStringDef,
    product: validArrayStringDef,
    business_model: validArrayStringDef,
    primary_customer: validArrayStringDef,
    geography: validArrayStringDef,
    sub_verticals: validArrayStringDef,
    sector_completed: validNumberDef,
    funding: validNumberDef,
    recent_funding: validNumberDef,
    sector_concentration: validNumberDefNull,
    jobs_to_be_done: validStringDef,
    recommended: validNumberDef,
    recommended_description: validStringDef,
    num_rounds: validNumberDefNull,
    prioritization: validArrayStringDef
}

export const sectorFieldsValidation: ValidationMap<Required<SectorFields>> = {
    ...requiredSectorFieldsValidationMap,
    ...optionalSectorFieldsValidationMap
}

export const sectorFields: CFields<"sectors"> = RFields<SectorFieldsRequired, SectorFieldsOptional>(
    requiredSectorFieldsValidationMap,
    optionalSectorFieldsValidationMap
)

const sectorRequiredRelationsValidationMap: ValidationMap<Required<SectorRelationsRequired>> = {
    startups: validNotEmptyArrayString
}

const sectorOptionalRelationsValidationMap: ValidationMap<Required<SectorRelationsOptional>> = {
    influencers: validArrayStringDef,
    companies: validArrayStringDef,
    investors: validArrayStringDef,
    patents: validArrayStringDef,
    research_hubs: validArrayStringDef,
    research_papers: validArrayStringDef,
    tech_experts: validArrayStringDef,
    tech_transfers: validArrayStringDef
}

export const sectorRelations: RFields<"sectors"> = RFields<SectorRelationsRequired, SectorRelationsOptional>(
    sectorRequiredRelationsValidationMap,
    sectorOptionalRelationsValidationMap
)

export const sectorRelationsValidationMap: ValidationMap<Required<SectorRelations>> = {
    ...sectorRequiredRelationsValidationMap,
    ...sectorOptionalRelationsValidationMap
}
export const sectorValidationMap: ValidationMap<Required<Sector>> = {
    ...sectorFieldsValidation,
    sectorId: validString,
    createdTs: validNumber
}

export const validateSectorRelations = validate<SectorRelations>(sectorRelationsValidationMap)
export const validateSectorFields = validate<SectorFields>(sectorFieldsValidation)
export const validateSector = validate<Sector>(sectorValidationMap)
export const validateSectorVM = validate<SectorVM>({
    ...sectorValidationMap,
    ...sharedVMMetaValidationMap,
    num_startups: validNumberDef
})

export const transformSector = (c: SectorVM): DisplayModelsMap["sectors"] => ({
    ...c,
    sector_concentration: c.sector_concentration
        ? formatDecimal(c.sector_concentration, undefined, undefined, true)
        : DEFAULT_PLACEHOLDER,
    investor_quality: formatDecimal(c.investor_quality, undefined, undefined, true),
    sector_funding_momentum: formatDecimal(c.sector_funding_momentum),
    funding_momentum: formatDecimal(c.funding_momentum),
    avg_company_age: formatDecimal(c.avg_company_age),
    priority_score: formatDecimal(c.priority_score),
    funding: c.funding ? formatMillions(c.funding, false) : DEFAULT_PLACEHOLDER,
    recent_funding: c.recent_funding ? formatMillions(c.recent_funding, false) : DEFAULT_PLACEHOLDER,
    description: parseString(c.description)
})

export const displaySector = (r: SectorVM, ph: string = DEFAULT_PLACEHOLDER): Displayable<SectorVM> =>
    mapToDisplay(r, ph)

export const SectorFieldsDisplay: Displayable<SectorVM> = {
    sector_name: "Sector name",
    avg_company_age: "Avg company age",
    business_model: "Business model",
    customer_type: "Customer type",
    description: "Description",
    funding: "Total funding ($m)",
    recent_funding: "Total funding ($m, Past 2 years)",
    geography: "Geography",
    incumbents: "Incumbents",
    investor_quality: "Investor quality",
    market_size: "Market size",
    num_startups: "Number of startups",
    primary_customer: "Primary customer",
    priority_score: "Priority score",
    capital_traction_url: "Capital Traction",
    product: "Product",
    funding_momentum: "Funding momentum (Past 2 years)",
    sector_funding_momentum: "Funding momentum (Total)",
    market_size_url: "Market size url",
    source: "Source",
    column: "Hex position (column)",
    row: "Hex position (row)",
    company_hex_display: "Hex display",
    sector_completed: "Is sector completed?",
    sub_verticals: "Sub verticals",
    blurOnListing: "",
    createdTs: "Created At",
    commentsCount: "No. of comments",
    contactedLeadsCount: "No. of contacted leads",
    sectorId: "ID",
    sector_concentration: "Sector concentration",
    jobs_to_be_done: "Job to be done description",
    recommended: "Recommended Sector",
    recommended_description: "Recommended Description",
    num_rounds: "Number of funding rounds (Past 2 years)",
    filterBounds: "",
    prioritization: ""
}
