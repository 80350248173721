import * as React from "react"
import { connect } from "react-redux"
import { useDropzone } from "react-dropzone"
import { _BodyFont } from "../../styles/typography"
import { _VerticalSpace, _HorizontalSpace, Flex, _AbsPosElement, Grid } from "../../styles/common"
import { actions as cloudActions } from "../../store/cloud/actions"
import { actions as uiActions } from "../../store/ui/actions"

import { Loaded, Loading } from "../../../functions/src/utils/types"
import { getMapDispatch2 } from "../../utils/redux"
import { getFirebase } from "../../services/firebase"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { useStorageUpload } from "../../utils/hooks/useStorage"
import { Msg } from "../../models/notifications"
import { isLoggedIn } from "../../models/LoginStatus"
import { Err, isEmpty } from "../../../functions/src/utils/validators"
import { downloadFile, mapRawAttachment } from "../../models/attachments"
import { AttachmentsTable } from "../../components/AttachmentTable"
import { LoadableView } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import { getDataByPath } from "../../store/data/dataSelectors"
import { getCollectionDetailsPathName } from "../../paths"
import { CloudState, RootState } from "../../store/store"
import { ViewModelsBase } from "../../../functions/src/models/ViewModels"
import { AuthenticatedState } from "../../models/auth"
import { _DNDCardFieldWrapper, _DNDCardField } from "./CardAttachments.styles"
import { values } from "d3"
import { IconSvg } from "../../components/IconSvg"
import {
    _CardSectionContainer,
    _CardSectionTableContainer,
    _CardSectionTitle
} from "../../components/newCards/sections/CardSection.styles"

export const MB_LIMIT_10 = 10485760

type StateProps = Pick<CloudState, "actionsResults"> &
    Pick<ViewModelsBase, "users"> & {
        rawAttachments: SMap<AttachmentDecorator>
        encryptionKey: EncryptionKey
        userId: string
        auth: AuthenticatedState
    }
type OwnProps = { radarId: string; objectId: string; cname: CName }
type ActionProps = ReturnType<typeof mapDispatch>

type Props = StateProps & ActionProps & OwnProps

const CardAttachments: React.FC<Props> = p => {
    const [, onDelete] = useCloudAction(p.mutateDecorator, p.actionsResults, ({ result }) => {
        p.queueNotification(Msg("delete", { name: "attachment" }, result))
    })
    const [, onUploadSuccess] = useCloudAction(p.mutateDecorator, p.actionsResults, ({ result }) => {
        p.queueNotification(Msg("update", { name: "attachment" }, result))
    })

    const onDownload = (path: string) => {
        const file = p.rawAttachments[path]
        if (!file)
            return Promise.resolve(p.queueNotification(Msg("failedAction", { name: "attachment" }, Err("Not found"))))
        return downloadFile(file.filename, p.encryptionKey, s => s.ref("attachments", p.radarId, "objects", file.path))
    }

    const deleteAttachment = (path: string) => {
        onDelete({ type: "attachment", objectId: p.objectId, path })
        const { storage } = getFirebase()
        const ref = storage.ref("attachments", p.radarId, "objects", path)
        storage.deleteByRef(ref)
    }

    const confirmDelete = (path: string) => {
        const decorator = p.rawAttachments[path]
        if (!decorator) return p.queueNotification(Msg("delete", { name: "attachment" }, Err("Attachment not found")))
        p.openPopup("deleteConfirmation", {
            objectType: "Attachment",
            objectName: "",
            onDelete: () => deleteAttachment(path),
            customText: (
                <>
                    Are you sure you want to <b>delete attachment?</b>
                </>
            )
        })
    }

    const [uploadStatuses, onUpload] = useStorageUpload(p.radarId, p.encryptionKey, "objects", (path, filename) =>
        onUploadSuccess({
            type: "attachment",
            objectId: p.objectId,
            path,
            newValue: { filename, authorId: p.userId, createdAt: Date.now() }
        })
    )

    const { getRootProps, getInputProps, draggedFiles } = useDropzone({
        maxSize: MB_LIMIT_10,
        onDropAccepted: files => onUpload(files),
        onDropRejected: files => {
            if (files.length > 1) return p.openPopup("attachmentInfo", { type: "multiple" })
            if (files[0].file.size >= MB_LIMIT_10) return p.openPopup("attachmentInfo", { type: "bigFile" })
        },
        multiple: false
    })

    const emptyAttachmentsTable =
        isEmpty(p.rawAttachments) &&
        !values(uploadStatuses).some(s => s.type === "Encrypting" || s.type === "Processing")

    return (
        <_CardSectionContainer big>
            <_CardSectionTitle>Attachments</_CardSectionTitle>
            <_BodyFont s15>
                Your file can't be bigger than <b>10MB</b>
                <br />
                You can drag and drop files here.
            </_BodyFont>
            <_VerticalSpace base="25px" />
            <Flex grow={1} justify="center">
                <Grid columns="repeat(auto-fit, minmax(0, 1fr))" gap="38px" grow={1} noshrink>
                    <_DNDCardFieldWrapper {...getRootProps({ tabIndex: -1 })} big={emptyAttachmentsTable}>
                        <_DNDCardField direction="column">
                            {draggedFiles.length > 1 ? (
                                <_BodyFont s17 bold color="danger">
                                    You cannot upload more than one file at a time
                                </_BodyFont>
                            ) : (
                                <Flex direction="column" align="center" justify="center">
                                    <Flex align="center">
                                        <IconSvg inline name="paper-clip" width={32} height={32} />
                                        <_HorizontalSpace base="8px" />
                                        <_BodyFont s17>
                                            <b>Attach</b> or <b>drag</b> files here!
                                        </_BodyFont>
                                    </Flex>
                                    <_VerticalSpace base="32px" />
                                    <IconSvg name="download-box" width={87} height={76} />
                                </Flex>
                            )}
                        </_DNDCardField>
                        <input {...getInputProps()} />
                    </_DNDCardFieldWrapper>
                    {!emptyAttachmentsTable ? (
                        <_CardSectionTableContainer>
                            <_AbsPosElement>
                                <AttachmentsTable
                                    uploadStatuses={uploadStatuses}
                                    mapToDisplay={mapRawAttachment({ users: p.users })}
                                    attachments={p.rawAttachments}
                                    deleteOptions={{
                                        type: "admin&author",
                                        value: { auth: p.auth, onDelete: confirmDelete, userId: p.userId }
                                    }}
                                    onDownload={onDownload}
                                />
                            </_AbsPosElement>
                        </_CardSectionTableContainer>
                    ) : null}
                </Grid>
            </Flex>
        </_CardSectionContainer>
    )
}

const mapState = (st: RootState, op: OwnProps) =>
    getDataByPath<StateProps, OwnProps>()(
        getCollectionDetailsPathName(op.cname),
        (deps, { cloud: { actionsResults }, auth: { authentication } }, { objectId }) => {
            if (!isLoggedIn(authentication)) return Loading()

            const rawAttachments = deps.decorators[objectId]?.attachment || {}
            const users = deps.users
            const userId = authentication.user.userId!

            return Loaded({
                rawAttachments,
                actionsResults,
                encryptionKey: deps.secureKeys.key,
                users,
                userId,
                auth: authentication
            })
        }
    )(st, op)

const mapDispatch = getMapDispatch2(cloudActions, ["mutateDecorator"], uiActions, ["queueNotification", "openPopup"])

export const AttachmentsCardView = connect(mapState, mapDispatch)(LoadableView(ViewLoader, CardAttachments))
