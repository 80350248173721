import * as React from "react"
import styled from "styled-components"

const _Container = styled.div`
    @import url("https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i");
    font-family: Lato, sans-serif;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.primary};
`

const _SubContainer = styled.div`
    border: 1px solid #2c405a;
    border-radius: 4px;
    padding: 36px 20px 20px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const RadicleMiniLogo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    position: absolute;
    border-radius: 12px;
    box-shadow: inset 1px 1px 0 0 rgba(255, 255, 255, 0.2);
    border: 1px solid #edeff2;
    background-color: #fff;
    top: -32px;
    left: 0;
    right: 0;
    margin: auto;
    width: 64px;
    height: 64px;
`

export const _IECOMPATIconSvg = styled.svg`
    display: block;
    flex-shrink: 0;
    background-image: url("/public/mini-logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 36px 36px;
    width: 36px;
    height: 36px;
`

const _Message = styled.div`
    margin-top: 8px;
`

export const IEPopup: React.FC = () => (
    <_Container>
        <_SubContainer>
            <RadicleMiniLogo>
                <_IECOMPATIconSvg />
            </RadicleMiniLogo>
            <_Message>Unfortunately, we do not support your browser.</_Message>
            <_Message>We recommend switching to modern browsers.</_Message>
        </_SubContainer>
    </_Container>
)
