import * as React from "react"

import { _VerticalSpace, _AbsPosElement } from "../../styles/common"
import { asyncWithDefault, isFetched } from "../../../functions/src/utils/types"

import { asyncConnect } from "../../store/async/asyncConnect"
import {
    _CardSectionContainer,
    _CardSectionTableContainer,
    _CardSectionTitle
} from "../../components/newCards/sections/CardSection.styles"
import { getUseListData, useList } from "../lists/useList"
import { Table } from "../../components/table/TableView"
import { DependencySchema, ViewModelsMap } from "../../../functions/src/models/ViewModels"
import { RowEffect } from "../../components/table/TableView.types"
import { getRowSchema } from "../../components/table/TableViewController"
import { getCollectionSchema } from "../../../functions/src/models/schemas"
import { getDisplayNames } from "../../../functions/src/models/collections"
import { mapObject } from "../../../functions/src/utils/map"
import { _BodyFont } from "../../styles/typography"
import { SortingParams } from "../../../functions/src/models/sorting"

export type CardSectorPositioningProps<C extends CName> = {
    objectId: string
    cname: C
}

const getSectorPositionSchema = () => {
    const schema = getRowSchema<ViewModelsMap["sectors"] & { index: number }, keyof ViewModelsMap["sectors"] | "index">(
        { ...getCollectionSchema("sectors").viewFixture, index: 0 },
        { ...getDisplayNames("sectors")({ mode: "list" }), index: "No." },
        [
            "index",
            "sector_name",
            "investor_quality",
            "sector_concentration",
            "sector_funding_momentum",
            "avg_company_age",
            "priority_score"
        ],
        { titleKey: "priority_score" }
    )
    schema[0].size = { min: 70, max: 70 }
    return schema
}

export const SectorPositioningCardView = asyncConnect<CardSectorPositioningProps<CName>>()({
    data: ["viewModels", "ui", "currentRadarConfig", "isRoot", "sectorsPositioning"],
    actions: ["navigate"],
    fetchHandlers: {
        fetchSectors: {
            resolver: "objects",
            getStateKey: () => "sectorsPositioning"
        }
    }
})(p => {
    const [scrollingContainerRef, setScrollingContainerRef] = React.useState<HTMLElement | null>(null)
    const [sorting, setSorting] = React.useState<SortingParams<"sectors">[]>([
        {
            sortingAsc: false,
            sortingKey: "priority_score"
        },
        {
            sortingAsc: false,
            sortingKey: "investor_quality"
        }
    ])

    React.useEffect(() => {
        if (isFetched(p.sectorsPositioning) && scrollingContainerRef) {
            const vtContainer = scrollingContainerRef.querySelector(".virtualized-table")
            if (vtContainer) (vtContainer as HTMLElement).scrollTop = 0
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.sectorsPositioning])

    const useListData = React.useMemo(() => {
        return getUseListData(
            "sectors",
            p.currentRadarConfig,
            asyncWithDefault(p.viewModels.config, {} as any),
            {
                ...p.viewModels,
                sectors: isFetched(p.sectorsPositioning)
                    ? {
                          ...p.sectorsPositioning,
                          value:
                              p.sectorsPositioning.value &&
                              mapObject(p.sectorsPositioning.value, (_, v, i) => ({
                                  ...v,
                                  index: i + 1
                              }))
                      }
                    : p.sectorsPositioning
            },
            {
                ...p.ui,
                sorting: {
                    ...p.ui.sorting,
                    sectors: sorting
                }
            },
            p.isRoot,
            false,
            false
        )
    }, [p.currentRadarConfig, p.viewModels, p.sectorsPositioning, p.ui, p.isRoot, sorting])

    const schema = React.useMemo(() => getSectorPositionSchema(), [])
    const rawTableProps = useList(useListData, { schema: schema as any, sorting: { sectors: sorting } })
    const tableData = React.useMemo(() => {
        const currentSectorRowIdx = rawTableProps.rows.findIndex(r => r.id === p.objectId)

        return {
            currentSectorRow: currentSectorRowIdx,
            tableProps: {
                ...rawTableProps,
                pinnedColumns: { left: "index" as any },
                rows: rawTableProps.rows.map((r, i) =>
                    i === currentSectorRowIdx ? { ...r, effects: [...r.effects, "highlight"] as RowEffect[] } : r
                )
            }
        }
    }, [rawTableProps, p.objectId])

    React.useEffect(() => {
        p.fetchSectors({
            cname: "sectors",
            sorting: {
                sectors: sorting
            },
            filters: {},
            schema: { sectors: "all" } as DependencySchema,
            cursor: { type: "none" }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting])

    return (
        <_CardSectionContainer big>
            <_CardSectionTitle>Relative positioning</_CardSectionTitle>
            <_BodyFont s16>
                Positioning gives you a quick perspective on where each sector sits relative to its peers.
            </_BodyFont>
            <_VerticalSpace base="15px" />
            <_CardSectionTableContainer ref={setScrollingContainerRef}>
                <_AbsPosElement>
                    <Table
                        {...tableData.tableProps}
                        showTooltip={false}
                        smallLoader
                        pinnedRow={tableData.currentSectorRow}
                        onSort={sortingKey => {
                            if (sortingKey !== "index")
                                setSorting([
                                    {
                                        sortingKey: sortingKey as SortingParams<"sectors">["sortingKey"],
                                        sortingAsc:
                                            sorting?.[0]?.sortingKey === sortingKey ? !sorting?.[0]?.sortingAsc : false
                                    }
                                ])
                        }}
                    />
                </_AbsPosElement>
            </_CardSectionTableContainer>
        </_CardSectionContainer>
    )
})
