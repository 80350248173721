import * as React from "react"

import { asyncWithDefault, isFetching } from "../../../functions/src/utils/types"

import { asyncConnect } from "../../store/async/asyncConnect"
import { Loader } from "../../components/common/Loader"
import { isEmpty } from "../../../functions/src/utils/validators"
import { byStartupFunding, StartupDetails } from "./SectorCardStartups"
import { collectionsVMConverters } from "../../../functions/src/models/collections"
import { detailsPaths } from "../../paths"

export type CardStartupsProps<C extends CName> = {
    objectId: string
    cname: C
}

// TODO Move isDemo to flags
export const StartupsCardView = asyncConnect<CardStartupsProps<CName>>()({
    data: ["viewModels", "isDemo"],
    actions: ["navigate"]
})(p => {
    const relations = React.useMemo(() => asyncWithDefault(p.viewModels.relations, {}), [p.viewModels.relations])
    const startupsToDisplay = React.useMemo(
        () =>
            (relations[p.objectId]?.startups || [])
                .map(sid => asyncWithDefault(p.viewModels.startups, {})[sid])
                .filter(s => !isEmpty(s))
                .sort(byStartupFunding)
                .map(s => collectionsVMConverters.startups(s)),
        [relations, p.objectId, p.viewModels.startups]
    )
    return isFetching(p.viewModels.startups) ? (
        <Loader />
    ) : (
        <StartupDetails
            startups={startupsToDisplay}
            onItemClick={startupId => p.navigate({ path: detailsPaths["details/startups"].path, slugs: { startupId } })}
            isDemo={p.isDemo}
        />
    )
})
