import styled from "styled-components"
import { styleIfProp } from "../../styles/common"

type ListWrapperProps = { isActive?: boolean; clickable?: boolean }
export const _ListWrapper = styled.div<ListWrapperProps>`
    display: flex;
    justify-content: space-between;
    padding: 24px;
    border-bottom: 1px solid #fafbfc;
    &:hover {
        background-color: #fcfcfc;
    }
    ${styleIfProp("clickable", "cursor: pointer;")}
    ${styleIfProp("isActive", "background-color: #fafafa;")}
`

export const _ListItemTitle = styled.div``
export const _ListTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    background: #fafbfc;
    border-top: solid 1px #edeff2;
    padding: 16px;

    letter-spacing: 2px;
    font-size: 11px;
    font-weight: 600;
`
export const _ListName = styled.div`
    color: #3f536e;
`

export const _ListItemDetails = styled.div`
    flex: 1 1 auto;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &* {
        line-height: 1.46;
    }
`

export const _ListFilesWrapper = styled.div`
    width: 349px;
`

export const _InlineIconListTitle = styled.div`
    text-transform: uppercase;
`
export const _InlineIconList = styled.div`
    display: flex;
    align-items: center;
    padding: 15px;
    padding-left: 24px;
    & > * + * {
        margin-left: 24px;
    }
`

export const _InlineIconListItem = styled.div`
    display: flex;
    align-items: center;
    & > * + * {
        margin-left: 8px;
    }
`
