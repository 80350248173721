import * as React from "react"

import { _TagFont, _BodyFont } from "../../styles/typography"
import { _VerticalSpace, _FlexRow } from "../../styles/common"
import { Join } from "../../utils/reactUtils"
import { isEmpty } from "../../../functions/src/utils/validators"
import { _SearchAreasContainer } from "../newCards/Card.styles"
import { _Copy } from "../common/Copy"
import { _SearchAreaBadge } from "../common"
import { TooltipSegmentTagsPreview, TooltipTagsPreview } from "../tags/TagsPreview"
import { assertNever } from "../../../functions/src/utils"
import { TooltipCommentsPreview } from "../comments/CommentsPreview"
import { Section } from "./Sections.types"
import { RichTextDescription } from "../form/RichEditor"

export const SectionComponent = (p: Section) => {
    switch (p.type) {
        case "Description":
            return (
                <>
                    <_TagFont>Description</_TagFont>
                    <_VerticalSpace base="16px" />
                    <RichTextDescription value={p.value} maxLines={5} />
                </>
            )
        case "Badges":
            return (
                <>
                    <_TagFont>
                        <_Copy name="searchAreas" />
                    </_TagFont>
                    <_SearchAreasContainer>
                        {isEmpty(p.values) && (
                            <_BodyFont s14>
                                <_Copy name="notPublicSearchArea" />
                            </_BodyFont>
                        )}
                        <Join items={p.values}>
                            {item => (
                                <_SearchAreaBadge key={item.color} color={item.color}>
                                    {item.name}
                                </_SearchAreaBadge>
                            )}
                        </Join>
                    </_SearchAreasContainer>
                </>
            )
        case "Tags":
            return <TooltipTagsPreview tags={p.values} />
        case "Segment Tags":
            return <TooltipSegmentTagsPreview segmentTags={p.values} />
        case "Definition":
            return (
                <>
                    <_TagFont>{p.header}</_TagFont>
                    <_BodyFont s14>{p.content}</_BodyFont>
                </>
            )
        case "Assignment":
            return (
                <_FlexRow alignCenter>
                    <_TagFont>Relationship manager:&nbsp;</_TagFont>
                    <_BodyFont s14>{p.value}</_BodyFont>
                </_FlexRow>
            )
        case "Comment":
            return p.values?.length ? <TooltipCommentsPreview comments={p.values} /> : null
    }
    assertNever(p)
}
