import { validString } from "../../functions/src/utils/validators"
import { mapObject } from "../../functions/src/utils/map"
import { contactedLeadFields } from "../../functions/src/models/contactedLeads"
import { mkString } from "../../functions/src/utils"

export const getLeadSchema = (): FormSchema<ContactedLeadDecorator> => {
    const { contactName, description, projects, technology } = contactedLeadFields()
    return {
        contactName: {
            type: "text",
            name: contactName,
            validators: validString
        },
        description: {
            type: "richTextarea",
            name: description,
            validators: validString
        },
        projects: {
            type: "text",
            name: projects,
            validators: validString
        },
        technology: {
            type: "text",
            name: technology,
            validators: validString
        }
    }
}

export const LeadSchemaFixture = mapObject(getLeadSchema(), mkString)
