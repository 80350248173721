import {
    validate,
    validNumber,
    validString,
    validStringDef,
    validNumberDef,
    validArrayStringDef
} from "../utils/validators"
import { RFields } from "./RFields"
import { mapToDisplay, parseString } from "./common"
import { formatInteger } from "./converters"
import {
    DEFAULT_PLACEHOLDER,
    sharedRequiredRadarFieldsValidationMap,
    sharedOptionalRadarFieldsValidationMap,
    sharedVMMetaValidationMap
} from "./shared"
import { InfluencerVM, DisplayModelsMap } from "./ViewModels"

const influencerRequiredFieldsValidationMap: ValidationMap<Required<InfluencerFieldsRequired>> = {
    influencer_name: validString,
    ...sharedRequiredRadarFieldsValidationMap
}

const influencerOptionalFieldsValidationMap: ValidationMap<Required<InfluencerFieldsOptional>> = {
    ...sharedOptionalRadarFieldsValidationMap,
    google_ranking: validNumberDef,
    influencer_org: validStringDef,
    location: validStringDef,
    num_news_sources: validNumberDef,
    type: validStringDef,
    influencer_url: validStringDef,
    num_instagram: validNumberDef,
    num_patents: validNumberDef,
    num_publications: validNumberDef,
    num_twitter: validNumberDef
}

export const influencerRelationsValidationMap: ValidationMap<Required<InfluencerRelations>> = {
    startups: validArrayStringDef,
    companies: validArrayStringDef,
    investors: validArrayStringDef,
    patents: validArrayStringDef,
    research_hubs: validArrayStringDef,
    research_papers: validArrayStringDef,
    sectors: validArrayStringDef,
    tech_transfers: validArrayStringDef
}

export const influencerFieldsValidation: ValidationMap<Required<InfluencerFields>> = {
    ...influencerRequiredFieldsValidationMap,
    ...influencerOptionalFieldsValidationMap
}

export const influencerFields: CFields<"influencers"> = RFields<InfluencerFieldsRequired, InfluencerFieldsOptional>(
    influencerRequiredFieldsValidationMap,
    influencerOptionalFieldsValidationMap
)

export const influencerRelations: RFields<"influencers"> = RFields<unknown, InfluencerRelations>(
    {},
    influencerRelationsValidationMap
)

export const influencerValidationMap: ValidationMap<Required<Influencer>> = {
    ...influencerFieldsValidation,
    influencerId: validString,
    createdTs: validNumber
}

export const validateInfluencerRelations = validate<InfluencerRelations>(influencerRelationsValidationMap)
export const validateInfluencerFields = validate<InfluencerFields>(influencerFieldsValidation)
export const validateInfluencer = validate<Influencer>(influencerValidationMap)
export const validateInfluencerVM = validate<InfluencerVM>({
    ...influencerValidationMap,
    ...sharedVMMetaValidationMap
})

export const transformInfluencer = (c: InfluencerVM): DisplayModelsMap["influencers"] => ({
    ...c,
    num_news_sources: formatInteger(c.num_news_sources),
    google_ranking: formatInteger(c.google_ranking),
    description: parseString(c.description)
})

export const displayInfluencer = (i: InfluencerVM, ph: string = DEFAULT_PLACEHOLDER): Displayable<InfluencerVM> =>
    mapToDisplay(i, ph)

export const InfluencerFieldsDisplay: Displayable<InfluencerVM> = {
    influencer_name: "Name",
    influencer_org: "Organization",
    google_ranking: "Google ranking",
    source: "Source",
    type: "Type",
    description: "Description",
    location: "Location",
    num_news_sources: "Number of news sources",
    num_instagram: "Number of instagram followers",
    num_patents: "Number of patents",
    num_publications: "Number of publications",
    num_twitter: "Number of twitter followers",
    influencer_url: "Influencer URL",
    blurOnListing: "",
    createdTs: "Created at",
    commentsCount: "No. of comments",
    contactedLeadsCount: "No. of contacted leads",
    influencerId: "ID",
    filterBounds: ""
}
