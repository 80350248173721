import * as React from "react"
import { _BodyFont, FontSizes } from "../styles/typography"
import { cancellable } from "./cancellable"
import { _noop } from "../../functions/src/utils"
type JoinProps<T> = {
    items: T[]
    renderJoining?: F0<React.ReactElement>
    children: F2<T, number, React.ReactNode>
}

export const Join = <T extends any>(p: JoinProps<T>) => (
    <>
        {p.items.map((item, index) => {
            const toRender = p.children(item, index)
            const joining = toRender && p.renderJoining !== undefined && index !== 0 ? p.renderJoining() : null
            return (
                <React.Fragment key={index}>
                    {joining}
                    {toRender}
                </React.Fragment>
            )
        })}
    </>
)

type AwaitProps<TR, T extends (...args: any[]) => Promise<TR>> = {
    operation: T
    children: F1<TR, React.ReactElement | null>
    renderWaiting: F0<React.ReactElement>
}

export const Await: <TR, T extends () => Promise<TR>>(
    p: AwaitProps<TR, T>
) => React.ReactElement<AwaitProps<TR, T>> | null = ({ operation, children, renderWaiting }) => {
    const [value, setValue] = React.useState<any>(null)
    const currentCall = React.useRef<any>(null)
    React.useEffect(() => {
        if (currentCall.current) currentCall.current.cancel()
        const c = cancellable(operation)
        const task = c.start()
        task.then((v: ValueState<"resolved", any>) => setValue(v.value)).catch(_noop)
        currentCall.current = c
    }, [operation])
    if (!value) return renderWaiting()
    return children(value)
}

export const LoadableView = <T1, T2>(Loading: React.FC<Loadable<T1, T2>>, Loaded: React.FC<T1>) => (
    props: Loadable<T1, T2>
): React.ReactElement<Loadable<T1, T2>> => (
    <>
        <Loading {...(props as Loadable<T1, T2>)} />
        {!props.loading && <Loaded {...(props as T1)} />}
    </>
)
export const MultilineDescription: React.FC<{ value?: string } & FontSizes> = ({ value = "", ...p }) => {
    const regexp = new RegExp(/<br \/>|<br>|<br\/>|\r?\\n|\r?\n/g)

    return (
        <_BodyFont preserveWhitespace {...p}>
            <Join items={value.split(regexp)} renderJoining={() => <br />}>
                {d => <>{d}</>}
            </Join>
        </_BodyFont>
    )
}
