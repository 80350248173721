import styled from "styled-components"
import { _DropdownText, _DropdownWrapper, _DropdownOptions, _DropdownOption } from "../Dropdown.styles"
import { styleFromProp, styleIfProp } from "../../styles/common"

export const _NavigationDropdownContainer = styled.div`
    ${_DropdownWrapper} {
        display: inline-flex;
        position: static;
    }

    ${_DropdownOptions} {
        width: 100%;
    }

    ${_DropdownOption} {
        color: black;
        padding: 0;
    }
`

export const _NavigationDropdownTrigger = styled.div<{ open: boolean; onClick: any }>`
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.5;
    display: flex;
    align-items: center;
    padding: 0 7px 0 4px;
    ${p => styleIfProp("onClick", "cursor: pointer")(p)};
    user-select: none;

    ${_DropdownText} {
        padding-right: 8px;
    }

    &:after {
        content: "";
        display: ${p => styleIfProp("onClick", "block", "none")(p)};
        border-top: solid 2px #0093ee;
        border-right: solid 2px #0093ee;
        transform: rotate(${p => styleFromProp(p.open, "-45deg", "-225deg")});
        transform-origin: 75% 25%;
        width: 7px;
        height: 7px;
        margin-top: 10px;
        transition: transform 0.1s ease-in-out;
    }
`

export const _NavigationDropdownOption = styled.a<{ isSelected: boolean }>`
    display: block;
    width: 100%;
    padding: 6px 0;
    text-decoration: none;

    &,
    &:hover {
        color: black;
        ${p => styleIfProp("isSelected", "color: #6D7E97")(p)};
    }
`
