import * as React from "react"
import { Popup } from "../Popup"
import { PopupInner } from "../Popup.styles"
import { _BodyFont } from "../../styles/typography"
import { Button, LinkButton } from "../../styles/buttons"
import { connect } from "react-redux"
import { _VerticalSpace, _Center } from "../../styles/common"
import { actions } from "../../store/auth/actions"
import { MapDispatch } from "../../utils/redux.types"

type Props = {
    isPermissionError?: boolean
    onClose: F0
}

type ActionProps = { logout: F0 }

export const ErrorPopupPure: React.FC<Props & ActionProps> = p => (
    <Popup logo>
        <PopupInner>
            <_Center>
                <_BodyFont s17>
                    {p.isPermissionError ? (
                        <>
                            It appears you don't have permission to access data contained in this view. If you think
                            this shouldn't happen, contact{" "}
                            <a href="mailto:stu@radicleinsights.com">stu@radicleinsights.com</a>
                        </>
                    ) : (
                        <>
                            Error appeared. Please try reloading the page. If your problem persists, contact{" "}
                            <a href="mailto:stu@radicleinsights.com">stu@radicleinsights.com</a>
                        </>
                    )}
                </_BodyFont>
                <_VerticalSpace base="16px" />
                <Button onClick={window.location.reload.bind(window.location)}>Reload page</Button>
                <_VerticalSpace base="30px" />
                <LinkButton onClick={p.logout}>Log out</LinkButton>
            </_Center>
        </PopupInner>
    </Popup>
)

const mapDispatch: MapDispatch<ActionProps, Props> = (d, p) => ({
    logout: () => {
        d(actions.tryLogout())
        p.onClose()
    }
})

export const ErrorPopup = connect(null, mapDispatch)(ErrorPopupPure)
