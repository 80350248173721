// TODO Fix this
/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react"
import { Popup } from "../Popup"
import { PopupInner } from "../Popup.styles"
import { _BodyFont, _h2 } from "../../styles/typography"
import { FormView, FormViewProps } from "../form/FormView"
import { validString, isErr } from "../../../functions/src/utils/validators"
import { _FormViewWrapper } from "../../styles/forms"
import { _VerticalSpace, _FlexRow, _HorizontalSpace } from "../../styles/common"
import { Button, SecondaryButton } from "../../styles/buttons"
import { useFormHook } from "../../utils/hooks/useFormHook"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { RCommentText } from "../comments/CommentParts"
import { actions } from "../../store/ui/actions"
import { actions as cfActions } from "../../store/cloud/actions"
import { connect } from "react-redux"
import { getCurrentRadarId } from "../../models/LocationType"
import { Loader } from "../common/Loader"
import { Loading, Loaded } from "../../../functions/src/utils/types"
import { assertNever } from "../../../functions/src/utils"
import { getAssignedUserName } from "../../../functions/src/models/decoratorValues"
import { getDataByKeys } from "../../store/data/dataSelectors"
import { ViewModelsBase } from "../../../functions/src/models/ViewModels"
import { AuthenticatedState } from "../../models/auth"
import { MapState, MapDispatch } from "../../utils/redux.types"
import { getLeadSchema, LeadSchemaFixture } from "../../models/contactedLeads"

type OwnProps = {
    onClose: F0
    meta: CommentMeta
}

type StateProps = Loadable<
    Pick<ViewModelsBase, "users"> & {
        auth: AuthenticatedState
        radarId: string
        actionsResults: SMap<CloudActionResult>
    }
>

type ActionProps = {
    onCommentCreate: F1<OnAction<CommentCreatePayload>>
    onCommentSaved: F1<CommentMeta>
}

export const commentSchema: FormSchema<RCommentText> = {
    text: {
        type: "textarea",
        placeholder: "Comment",
        validators: validString
    }
}

const initialComment: RCommentText = { text: "" }

const getDescriptionByMeta = (meta: CommentMeta): string => {
    switch (meta.type) {
        case "pipeline":
            return "To change the pipeline stage, you need to leave a comment with a reason. This comment will be available in the comments section."
        case "assignment":
            return "To assign to user, you need to leave a comment. Your comment will appear in the comments section."
        case "contactedLead":
            return "Please provide the contact details."
    }
    assertNever(meta)
}
const getFormSchema = ({ meta }: Pick<OwnProps, "meta">) => {
    switch (meta.type) {
        case "assignment":
        case "pipeline":
            return commentSchema
        case "contactedLead":
            return getLeadSchema()
    }
    assertNever(meta)
}
const getInitialValue = ({ meta }: Pick<OwnProps, "meta">) => {
    switch (meta.type) {
        case "assignment":
        case "pipeline":
            return initialComment
        case "contactedLead":
            return meta.value.newValue || LeadSchemaFixture
    }
    assertNever(meta)
}

const getFormView = (
    { meta }: Pick<OwnProps, "meta">,
    formViewProps: FormViewProps<Pick<ObjectComment, "text"> | ContactedLeadDecorator>
) => {
    switch (meta.type) {
        case "assignment":
        case "pipeline": {
            return <FormView {...(formViewProps as FormViewProps<Pick<ObjectComment, "text">>)} />
        }
        case "contactedLead": {
            return <FormView {...(formViewProps as FormViewProps<ContactedLeadDecorator>)} />
        }
    }
    assertNever(meta)
}

const MandatoryCommentView: React.FC<OwnProps & ActionProps & StateProps> = p => {
    if (p.loading) return <Loader />
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [, onCreate] = useCloudAction<RCommentText | ContactedLeadDecorator>(
        (actionId, form) => {
            setIsSubmitting(true)
            if (p.meta.type !== "contactedLead") {
                const { text } = form as RCommentText
                p.onCommentCreate({
                    actionId,
                    text,
                    objectId: p.meta.value.objectId,
                    radarId: p.radarId,
                    meta: p.meta
                })
            } else {
                const newValue = form as ContactedLeadDecorator
                p.onCommentCreate({
                    actionId,
                    text: "",
                    objectId: p.meta.value.objectId,
                    radarId: p.radarId,
                    meta: {
                        type: "contactedLead",
                        value: {
                            type: "lead",
                            objectId: p.meta.value.objectId,
                            oldValue: p.meta.value.newValue || LeadSchemaFixture,
                            newValue
                        }
                    }
                })
            }
        },
        p.actionsResults,
        () => p.onCommentSaved(p.meta)
    )

    const { formViewProps, onSubmitClick, result } = useFormHook({
        schema: getFormSchema(p),
        initialValue: getInitialValue(p),
        onSubmit: onCreate
    })
    return (
        <Popup logo {...p}>
            <PopupInner>
                {isSubmitting ? (
                    <Loader loadingText="Submitting" />
                ) : (
                    <_FormViewWrapper>
                        {p.meta.type === "assignment" ? (
                            <>
                                <_FlexRow alignCenter>
                                    <_BodyFont s14>Assign to: </_BodyFont>
                                    <_HorizontalSpace base="8px" />
                                    <_BodyFont s15 bold>
                                        {getAssignedUserName(p.users, p.meta.value.newValue)}
                                    </_BodyFont>
                                </_FlexRow>
                                <_VerticalSpace base="16px" />
                            </>
                        ) : null}
                        <_h2>{getDescriptionByMeta(p.meta)}</_h2>
                        <_VerticalSpace base="16px" />
                        {getFormView(p, formViewProps)}
                        <_VerticalSpace base="16px" />
                        <_FlexRow>
                            <SecondaryButton wide onClick={p.onClose}>
                                Cancel
                            </SecondaryButton>
                            <_HorizontalSpace base="24px" />
                            <Button wide disabled={isErr(result)} onClick={onSubmitClick}>
                                Send
                            </Button>
                        </_FlexRow>
                        <_VerticalSpace base="16px" />
                    </_FormViewWrapper>
                )}
            </PopupInner>
        </Popup>
    )
}
const mapState: MapState<StateProps, OwnProps> = (s, op) => {
    const radarId = getCurrentRadarId(s.auth)!
    const result = {
        users: {},
        actionsResults: s.cloud.actionsResults,
        auth: s.auth.authentication as AuthenticatedState,
        radarId
    }

    if (op.meta.type === "assignment") {
        const deps = getDataByKeys(["users"], s.data[radarId])
        if (deps.loading) return Loading()
        result.users = deps.users
    }
    return Loaded(result)
}
const mapDispatch: MapDispatch<ActionProps> = d => ({
    onCommentCreate: payload => d(cfActions.createComment(payload)),
    onCommentSaved: meta => {
        switch (meta.type) {
            case "pipeline":
            case "assignment":
                d(cfActions.mutateDecoratorDebounced(meta.value))
                break
            case "contactedLead":
                break
        }
        d(actions.closePopup("mandatoryComment"))
    }
})

export const MandatoryCommentPopup = connect(mapState, mapDispatch)(MandatoryCommentView)
