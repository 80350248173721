/* eslint-disable max-lines */
import * as React from "react"
import { ModalProps } from "../Modal"
import { Button } from "../../styles/buttons"
import { getCollectionSchema } from "../../../functions/src/models/schemas"
import {
    values,
    mapObject,
    filterObject,
    identity,
    remap,
    mapOptionsMapToValuesMap,
    pickObject
} from "../../../functions/src/utils/map"
import { isEmpty, isErr, Err, isArray, Ok } from "../../../functions/src/utils/validators"
import { TabRenderable, Tabs } from "../tabs/Tabs"
import { _BodyFont } from "../../styles/typography"
import { CardCloseButton, CardModalTitle, _CardContainer, _CardContent, _CardHeader } from "../newCards/Card.styles"
import { _VerticalSpace, _Spacer, _AbsPosContainer, _AbsPosElement, _HorizontalSpace } from "../../styles/common"
import { toOption } from "../../../functions/src/utils/types"
import { Loader } from "../common/Loader"
import { tagToOption, byCompareName, displaySegmentTag } from "../../../functions/src/models/tags"
import { getPreloadedItemValues } from "../../../functions/src/models/relations/relationUtils"
import { ViewModelsBase } from "../../../functions/src/models/ViewModels"
import { _TabContentWrapper } from "../tabs/Tabs.styles"
import { isCollectionItemInvalid } from "../../../functions/src/models/itemValidationUtils"
import { CollectionFormSchema, EditSchema, editSchemas } from "./EditSchemas"
import { FormState, StyledFormView, FormViewProps } from "@react-formless/core"
import { attachIdToCollectionItem } from "../../store/importing/importingUtils"
import { validateItem } from "../../store/importing/importingValidators"
import { _CardSectionContainer, _CardSectionTitle } from "../newCards/sections/CardSection.styles"
import { IconSvg } from "../IconSvg"
import { ColumnLayout } from "../layout/ColumnLayout"
import { customRenderMap, customUseFormHook, getStyledInputsRenderMap } from "../form/FormlessInputs"
import { ModalContainer } from "../common/ModalContainer"
import { isEqual } from "../../utils"
import { ActionDispatchFn } from "../../store/actions"
import { Msg } from "../../models/notifications"
import { CollectionRelationsEditor } from "./CollectionRelationsEditor"
import { ImportValidateItemsPayload } from "../../../functions/src/services/httpEndpoint/import/validateItems"
import { PreloadedCollections } from "../../../functions/src/models/importing.types"

const mapValidationToState = <C extends CName>(
    s: FormState<RCollectionFields<C> & ItemClassifiersInput>,
    schema: CollectionFormSchema<C>,
    fields: Result<RCollectionFields<C>>,
    classifiers: Result<ItemClassifiersInput>,
    forceVisited = false
): FormState<RCollectionFields<C> & ItemClassifiersInput> => {
    const fieldsObj = isErr(fields) ? (fields.obj as RCollectionFields<C>) : fields.value
    const fieldsError = isErr(fields) ? fields.value : ({} as any)
    const classifiersObj = isErr(classifiers) ? (classifiers.obj as ItemClassifiersInput) : classifiers.value
    const classifiersError = isErr(classifiers) ? classifiers.value : ({} as any)

    const obj = { ...fieldsObj, ...classifiersObj }
    const error = { ...fieldsError, ...classifiersError }

    return {
        ...s,
        ...filterObject(
            mapObject(obj, (k, newValue) => {
                if (!s[k]) return undefined
                const fieldType = schema[k]?.type
                if ((fieldType === "collection" || fieldType === "list") && isArray(newValue))
                    return newValue.map((nvai, i) => ({
                        ...(((s[k] || []) as any)[i] || {}),
                        validationResult: error[k] && error[k].includes(nvai) ? Err(error[k], nvai) : undefined,
                        value: nvai,
                        visited: forceVisited ? true : (s[k] as any).visited
                    })) as any

                return {
                    ...s[k],
                    validationResult: error[k] ? Err(error[k], newValue) : undefined,
                    value: newValue,
                    visited: forceVisited ? true : (s[k] as any).visited
                }
            }),
            (_, v) => !isEmpty(v)
        )
    }
}

const getItemValuesFromForm = <C extends CName>(
    data: RCollectionFields<C> & ItemClassifiersInput,
    // TODO Make sure there is always an item passed to edit form
    item: CollectionItemVM<C> = {
        type: "new",
        fields: Ok({} as any),
        classifiers: Ok({} as any),
        relations: Ok({} as any),
        classifiersVM: { primary_search_areas: [] },
        relationsVM: {} as any
    }
): CollectionItemVM<C> => {
    const { primary_search_areas, secondary_search_areas, tags, segment_tags, ...fields } = data
    const oldItemFields = (item.fields
        ? isErr(item.fields)
            ? item.fields.obj
            : item.fields.value
        : {}) as RCollection<C>
    const oldItemClassifiers = (item.classifiers
        ? isErr(item.classifiers)
            ? item.classifiers.obj
            : item.classifiers.value
        : {}) as ItemClassifiersInput
    const filteredFields = remap(fields as any, identity, v =>
        isArray(v) ? v.filter(i => !isEmpty(i)) : v
    ) as typeof fields

    return {
        ...item,
        fields: Ok({ ...oldItemFields, ...filteredFields }),
        classifiers: Ok({
            ...oldItemClassifiers,
            primary_search_areas,
            secondary_search_areas,
            tags,
            segment_tags
        })
    }
}

const prepareAndValidateItem = async <C extends CName>(
    item: CollectionItem<C>,
    cname: C,
    mode: ImportValidateItemsPayload["mode"],
    config: LocationParams,
    additionalObjects: PreloadedCollections = {}
): Promise<CollectionItemVM<C>> => {
    const isEditing = item.type === "overwrite"
    const i = { ...item }
    // TODO Is this check necessary?
    if (mode !== "list" || !isEditing)
        i.fields = Ok(attachIdToCollectionItem(cname, getPreloadedItemValues("fields", item)))
    i.edited = true

    const result = await validateItem(
        {
            ...additionalObjects,
            [cname]: { ...(additionalObjects[cname] || {}), value: [...(additionalObjects[cname]?.value || []), i] }
        },
        config.radarId,
        mode,
        isEditing
    )
    return result[cname]?.value?.[0] as any
}

const getAvailableSearchAreas = (searchAreas: TMap<AreaId, SearchArea>, currentSA: string[], otherSA: string[]) =>
    values(searchAreas)
        .filter(sa => currentSA.includes(sa.areaId) || otherSA.every(areaId => areaId !== sa.areaId))
        .map(sa => toOption(sa.name, sa.areaId))

type UseCrossSearchAreasValidationProps<C extends CName> = {
    availablePrimarySearchAreas: ROption<string>[] | undefined
    availableSecondarySearchAreas: ROption<string>[] | undefined
    setAvailablePrimarySearchAreas: (sas: ROption<string>[]) => void
    setAvailableSecondarySearchAreas: (sas: ROption<string>[]) => void
    item: CollectionItemVM<C> | undefined
    vms: EditItemProps<C>["vms"]
    result: Result<RCollectionFields<C> & ItemClassifiersInput>
}

const useCrossSearchAreasValidation = <C extends CName>(p: UseCrossSearchAreasValidationProps<C>) => {
    React.useEffect(() => {
        const rObj = isErr(p.result) ? (p.result.obj as RCollectionFields<C> & ItemClassifiersInput) : p.result.value
        const newAvailablePrimarySearchAreas = getAvailableSearchAreas(
            p.vms.searchAreas,
            rObj.primary_search_areas || [],
            rObj.secondary_search_areas || []
        )
        const newAvailableSecondarySearchAreas = getAvailableSearchAreas(
            p.vms.searchAreas,
            rObj.secondary_search_areas || [],
            rObj.primary_search_areas || []
        )

        if (!isEqual(newAvailablePrimarySearchAreas, p.availablePrimarySearchAreas))
            p.setAvailablePrimarySearchAreas(newAvailablePrimarySearchAreas)
        if (!isEqual(newAvailableSecondarySearchAreas, p.availableSecondarySearchAreas))
            p.setAvailableSecondarySearchAreas(newAvailableSecondarySearchAreas)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.result, p.vms.searchAreas])
}

type UseSegmentTagsValidationProps<C extends CName> = {
    availableSegmentTags: ROption<string>[]
    setAvailableSegmentTags: (sts: ROption<string>[]) => void
    vms: EditItemProps<C>["vms"]
    result: Result<RCollectionFields<C> & ItemClassifiersInput>
    availablePrimarySearchAreas: ROption<string>[] | undefined
    availableSecondarySearchAreas: ROption<string>[] | undefined
    formState: FormState<RCollectionFields<C> & ItemClassifiersInput>
    setFormState: F1<FormState<RCollectionFields<C> & ItemClassifiersInput>>
}

const useSegmentTagsValidation = <C extends CName>(p: UseSegmentTagsValidationProps<C>) => {
    const previousSearchAreas = React.useRef<string[] | undefined>()

    // Validate availableSegmentTags
    React.useEffect(() => {
        const rObj = isErr(p.result) ? (p.result.obj as RCollectionFields<C> & ItemClassifiersInput) : p.result.value
        const newSearchAreas = [...rObj.primary_search_areas, ...(rObj.secondary_search_areas || [])]
        const newAvailableSegmentTags = values(p.vms.segmentTags)
            .filter(
                stag =>
                    newSearchAreas.some(areaId => areaId === stag.areaId) ||
                    (rObj.segment_tags || []).includes(stag.segmentId)
            )
            .map(stag => toOption(displaySegmentTag(p.vms.searchAreas)(stag), stag.segmentId))
        const searchAreasChanged = previousSearchAreas.current && !isEqual(previousSearchAreas.current, newSearchAreas)
        previousSearchAreas.current = newSearchAreas
        if (
            searchAreasChanged ||
            !isEqual(
                newAvailableSegmentTags.map(s => s.value),
                p.availableSegmentTags.map(s => s.value)
            )
        )
            p.setAvailableSegmentTags(newAvailableSegmentTags)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.result, p.vms.segmentTags])

    // Remove validation errors when SA Change
    React.useEffect(() => {
        const result = p.result as Result<
            RCollectionFields<C> & ItemClassifiersInput,
            Errors<RCollectionFields<C> & ItemClassifiersInput>,
            RCollectionFields<C> & ItemClassifiersInput
        >
        const rObj = isErr(result) ? (result.obj as RCollectionFields<C> & ItemClassifiersInput) : result.value
        const currentSearchAreas = [...rObj.primary_search_areas, ...(rObj.secondary_search_areas || [])]
        if (
            isErr(result) &&
            result.value.segment_tags &&
            rObj.segment_tags &&
            rObj.segment_tags.every(st => currentSearchAreas.includes(p.vms.segmentTags[st]?.areaId))
        )
            p.setFormState({
                ...p.formState,
                segment_tags: { ...p.formState.segment_tags, validationResult: undefined }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [p.availablePrimarySearchAreas, p.availableSecondarySearchAreas])
}

type UseMapItemValidationToStateProps<C extends CName> = {
    item: CollectionItemVM<C> | undefined
    formViewProps: FormViewProps<RCollectionFields<C> & ItemClassifiersInput>
    submitted: boolean
    mode: ImportValidateItemsPayload["mode"]
}

const useMapItemValidationToState = <C extends CName>(p: UseMapItemValidationToStateProps<C>) => {
    const fields = p.item?.fields
    const classifiers = p.item?.classifiers

    React.useEffect(() => {
        if (fields && classifiers) {
            const newState = mapValidationToState<C>(
                p.formViewProps.state,
                p.formViewProps.schema,
                fields,
                classifiers,
                p.submitted || p.mode === "import"
            )
            p.formViewProps.setState(newState)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields, classifiers])
}

type Props<C extends CName> = Pick<ModalProps, "isOpen"> & EditItemProps<C>

export type EditItemProps<C extends CName> = Pick<ModalProps, "onClose"> & {
    onSave: F1<CollectionItem<C>>
    queueNotification: ActionDispatchFn<"queueNotification">
    vms: Pick<
        ViewModelsBase,
        "radar" | "tags" | "searchAreas" | "segmentTags" | "tagsAssignments" | "segmentTagsAssignments"
    >
    cname: C
    mode: ImportValidateItemsPayload["mode"]
    config: LocationParams
    item?: CollectionItemVM<C>
    getAdditionalObjectsRequiredForValidation?: F2<CollectionItem<C>, "fields" | "relations", PreloadedCollections>
    isRoot: boolean
}

const submitErrorNotificationMsg = Msg("custom", { success: "", error: "Can’t save changes because of error" }, Err(""))

export const EditCollectionItem = <C extends CName>(p: EditItemProps<C>): React.ReactElement<EditItemProps<C>> => {
    const [item, setItem] = React.useState<CollectionItemVM<C> | undefined>(p.item)
    const [validating, setValidating] = React.useState(false)
    const [isUploading, setUploading] = React.useState(false)
    const collectionSchema = getCollectionSchema(p.cname)
    const displayName = p.item?.fields ? getPreloadedItemValues("fields", p.item)[collectionSchema.nameField] : "New"

    const [availablePrimarySearchAreas, setAvailablePrimarySearchAreas] = React.useState<ROption<string>[]>([])
    const [availableSecondarySearchAreas, setAvailableSecondarySearchAreas] = React.useState<ROption<string>[]>([])
    const [availableSegmentTags, setAvailableSegmentTags] = React.useState<ROption<string>[]>([])

    const availableTags = React.useMemo(
        () =>
            values(p.vms.tags)
                .sort(byCompareName)
                .map(tag => tagToOption(false)(tag)),
        [p.vms.tags]
    )

    const schemas = React.useMemo(
        () =>
            (editSchemas[p.cname]({
                availablePrimarySearchAreas,
                availableSecondarySearchAreas,
                availableTags,
                availableSegmentTags,
                config: p.config
            }) as unknown) as EditSchema<C>,
        [
            p.cname,
            availablePrimarySearchAreas,
            availableSecondarySearchAreas,
            availableTags,
            availableSegmentTags,
            p.config
        ]
    )
    const styledInputsRenderMap = React.useMemo(
        () => getStyledInputsRenderMap<RCollectionFields<C> & ItemClassifiersInput>(schemas.schema),
        [schemas.schema]
    )

    // TODO Report issue with handleSubmit 2x when result was an error and field was not validated...
    const { formViewProps, handleSubmit, submitted, result } = customUseFormHook<
        RCollectionFields<C> & ItemClassifiersInput
    >({
        // TODO Validate classifiers on the fly
        schema: schemas.schema,
        onSubmit: async d => {
            setUploading(true)
            const newItem = getItemValuesFromForm<C>(d, item)
            const validatedItem = await prepareAndValidateItem(
                newItem,
                p.cname,
                p.mode,
                p.config,
                p.getAdditionalObjectsRequiredForValidation
                    ? p.getAdditionalObjectsRequiredForValidation(newItem, "fields")
                    : {}
            )

            // Do not update relations in this tab
            validatedItem.relations = newItem.relations
            validatedItem.relationsVM = newItem.relationsVM

            if (isCollectionItemInvalid(validatedItem, p.mode)) {
                setUploading(false)
                p.queueNotification(submitErrorNotificationMsg)
                return setItem(validatedItem)
            }
            return p.onSave(validatedItem)
        },
        onSubmitError: () => p.queueNotification(submitErrorNotificationMsg)
    })

    const fieldsTab = {
        id: "fieldsTab" as const,
        title: TabRenderable(() => (
            <_BodyFont s11 color={isErr(item?.fields) || isErr(item?.classifiers) ? "danger" : "primary"}>
                Fields
            </_BodyFont>
        )),
        content: TabRenderable(() => (
            <ColumnLayout
                items={schemas.styledSchema}
                renderElement={(section: any) => (
                    <_CardSectionContainer data-cy={`card-section-container-${section.title}`} key={section.title}>
                        <_CardSectionTitle>{section.title}</_CardSectionTitle>
                        <div data-cy={`card-section-content-${section.title}`}>
                            <StyledFormView
                                {...formViewProps}
                                styledSchema={section.styledSchema}
                                styledInputsRenderMap={styledInputsRenderMap}
                                inputsRenderMap={customRenderMap}
                            />
                        </div>
                    </_CardSectionContainer>
                )}
            />
        ))
    }

    const relationsTab = {
        id: "relationsTab" as const,
        title: TabRenderable(() => (
            <_BodyFont s11 color={isErr(item?.relations) ? "danger" : "primary"}>
                Relationships
            </_BodyFont>
        )),
        content: TabRenderable(() => {
            const relationsVM = (item ? item.relationsVM : {}) as Casted<RCollectionRelations<C>, ROptions<string>>
            const objectRelations = (cname: CName): ROptions => relationsVM[cname as keyof typeof relationsVM] || []
            const update = async (relations: Casted<RCollectionRelations<C>, ROptions>) => {
                setValidating(true)
                const relationsIds = mapOptionsMapToValuesMap(relations)
                const itemToValidate: CollectionItem<C> = {
                    ...(item ? item : { type: "new", fields: Ok({} as any), classifiers: Ok({} as any) }),
                    relations: Ok(relationsIds)
                }
                const r = await prepareAndValidateItem(
                    itemToValidate,
                    p.cname,
                    p.mode,
                    p.config,
                    p.getAdditionalObjectsRequiredForValidation
                        ? p.getAdditionalObjectsRequiredForValidation(itemToValidate, "relations")
                        : {}
                )
                const newRelations = pickObject(r, ["relations", "relationsVM"])
                setValidating(false)
                setItem(i => (i ? { ...i, ...newRelations } : ({ ...newRelations } as any)))
            }

            return (
                <CollectionRelationsEditor
                    mode={p.mode}
                    cname={p.cname}
                    onAdd={(cname, r) =>
                        update({ ...relationsVM, [cname]: [...objectRelations(cname), { label: r, value: r }] })
                    }
                    onRemove={(cname, r) => {
                        update({
                            ...relationsVM,
                            [cname]: objectRelations(cname).filter(id => id.value !== r)
                        })
                    }}
                    getSelected={cname => objectRelations(cname)}
                    objectId={
                        item?.fields
                            ? (getPreloadedItemValues("fields", item)[collectionSchema.idField] as any)
                            : undefined
                    }
                    radarId={p.config.radarId}
                    errors={isErr(item?.relations) ? (item?.relations.value as SMap<string>) : {}}
                />
            )
        })
    }

    const data =
        p.mode === "import" && !isEmpty(getCollectionSchema(p.cname).relations.all)
            ? [fieldsTab, relationsTab]
            : [fieldsTab]

    useCrossSearchAreasValidation({
        item,
        vms: p.vms,
        availablePrimarySearchAreas,
        availableSecondarySearchAreas,
        setAvailablePrimarySearchAreas,
        setAvailableSecondarySearchAreas,
        result
    })

    useSegmentTagsValidation<C>({
        vms: p.vms,
        availableSegmentTags,
        setAvailableSegmentTags,
        result,
        formState: formViewProps.state,
        setFormState: formViewProps.setState,
        availablePrimarySearchAreas,
        availableSecondarySearchAreas
    })

    useMapItemValidationToState({
        item,
        formViewProps,
        submitted,
        mode: p.mode
    })

    return (
        <_CardContainer>
            <_CardHeader>
                <CardModalTitle bold>{`Edit ${collectionSchema.singleName} / ${displayName}`}</CardModalTitle>
                <_Spacer />
                <div style={{ minWidth: "200px" }}>
                    {validating ? (
                        <Loader loadingText="Validating..." />
                    ) : isUploading ? (
                        <Loader size="small" />
                    ) : (
                        <Button data-cy="edit-save-item" onClick={handleSubmit}>
                            Save item
                        </Button>
                    )}
                </div>
                <_HorizontalSpace base="20px" />
                <CardCloseButton onClick={p.onClose}>
                    <IconSvg name="close" width={15} height={15} />
                </CardCloseButton>
            </_CardHeader>
            <_CardContent>
                <_AbsPosContainer>
                    <_AbsPosElement>
                        {p.mode === "list" ? (
                            <_TabContentWrapper data-cy="scrollable-card-container" scrollable>
                                <_VerticalSpace base="20px" />
                                {fieldsTab.content.render()}
                            </_TabContentWrapper>
                        ) : (
                            <Tabs data={data} scrollable legacy={false} />
                        )}
                    </_AbsPosElement>
                </_AbsPosContainer>
            </_CardContent>
        </_CardContainer>
    )
}

export const EditCollectionItemModal = <C extends CName>(p: Props<C>) => {
    return (
        <ModalContainer isOpened={p.isOpen} onOverlayClick={p.onClose} onClose={p.onClose}>
            {p.isOpen && (
                <EditCollectionItem
                    {...p}
                    onSave={i => {
                        p.onSave(i)
                        p.onClose && p.onClose()
                    }}
                    onClose={p.onClose}
                />
            )}
        </ModalContainer>
    )
}
