import * as React from "react"
import styled from "styled-components"
import { IconSvg } from "../IconSvg"

const _NewsfeedBtn = styled.div`
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 120px;
    padding: 4px 10px;

    color: #fff;
    cursor: pointer;
`
export const _CountBadge = styled.div`
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 12px;
    height: 9px;
    font-size: 11px;
    line-height: 0.82;
    letter-spacing: -0.7px;
    color: #fff;
    width: 20px;
    height: 14px;
    border-radius: 16px;
    border: solid 1px rgba(0, 0, 0, 0.1);
    background-color: #4ece3d;
`
type NewsfeedBtnActions = { onClick: F0 }
type NewsfeedBtnProps = NewsfeedBtnActions & { unreadCount: Loadable<{ value: number }> }
export const NewsfeedButton: React.FC<NewsfeedBtnProps> = p => {
    const badge = p.unreadCount.loading ? "." : p.unreadCount.value >= 10 ? "9+" : p.unreadCount.value
    return (
        <_NewsfeedBtn onClick={p.onClick}>
            <IconSvg name="newsfeed-button" width={30} height={25} />
            Newsfeed
            {p.unreadCount.loading || p.unreadCount.value !== 0 ? <_CountBadge>{badge}</_CountBadge> : null}
        </_NewsfeedBtn>
    )
}
