import { Cmd } from "redux-loop"
import { v4 } from "uuid"
import { userCloudActionsCmd, radarCloudActionsCmd, adminCloudActionsCmd } from "../../utils/reduxLoop"
import {
    CloudActions,
    radarCloudActions,
    userCloudActions,
    adminCloudActions
} from "../../../functions/src/actions/actionCreators"
import { getFirebase } from "../../services/firebase"
import { call } from "../../../functions/src/utils"
import { validateActionResult } from "../../../functions/src/actions/validators"
import { Err } from "../../../functions/src/utils/validators"
import { getPerf } from "../../services/performance"
import { ImportableItem, PriorityImportPayload } from "../../../functions/src/models/importing.types"

type SecureActionsSchema = Pick<ActionsSchema, "adminActions" | "userActions" | "radarActions">
type SecureResultsSchema = Pick<ActionsSchema, "adminActionsResults" | "userActionsResults" | "radarActionsResults">

const resultNodesMap: Casted<SecureActionsSchema, keyof SecureResultsSchema> = {
    adminActions: "adminActionsResults",
    radarActions: "radarActionsResults",
    userActions: "userActionsResults"
}

type RunCloudFunctionFn = (
    node: keyof SecureActionsSchema,
    nodeId: string,
    resultNodeId: string,
    actionCreator: F1<string, CloudActions>,
    t?: number
) => Promise<string>
export const runCloudFunc: RunCloudFunctionFn = async (node, nodeId, resultNodeId, actionCreator, t = 120 * 1000) =>
    new Promise((resolve, reject) => {
        const actionId = v4()
        const { firestore } = getFirebase()
        const timeout = setTimeout(() => {
            call(unsubscribe)
            reject("timeout")
        }, t)
        const actionsRef = firestore.ref(node, nodeId, "actions", actionId)
        const action = actionCreator(actionId)
        getPerf().startTraceById("CLOUD_ACTION", actionId, action?.type)
        firestore.setDoc(actionsRef, action)
        const resultRef = firestore.ref(resultNodesMap[node], resultNodeId, "results", actionId)
        const onSubscribe = (res: Result<any>) => {
            clearTimeout(timeout)
            call(unsubscribe)
            return !res || res.type === "Err" ? reject(res.value) : resolve()
        }
        const unsubscribe = firestore.subscribeOnDoc(resultRef, validateActionResult, onSubscribe, err =>
            onSubscribe(Err(err.message))
        )
    })

const debounceState: SMap<NodeJS.Timeout> = {}
export const runCloudFuncDebounced = (
    objectId: string,
    nodes: keyof SecureActionsSchema,
    id: string,
    resultId: string,
    actionCreator: F1<string, CloudActions>
) => {
    if (debounceState[objectId]) clearTimeout(debounceState[objectId])
    debounceState[objectId] = setTimeout(() => runCloudFunc(nodes, id, resultId, actionCreator), 1500) as any
}

export const createRadar = ({ radar, hubId, ...p }: OnAction<OnUser<{ radar: RadarDetailsPayload; hubId: HubId }>>) =>
    userCloudActionsCmd(p, userCloudActions.createRadar(radar, hubId))

export const mutateDecorator = (p: OnAction<OnSender<DecoratorMutationPayload>>) =>
    radarCloudActionsCmd(p, radarCloudActions.mutateDecorator(p))

export const mutateDecoratorDebounced = (p: OnSender<DecoratorMutationPayload>) =>
    Cmd.run(async () =>
        runCloudFuncDebounced(p.objectId, "radarActions", p.senderId, p.radarId, radarCloudActions.mutateDecorator(p))
    )

export const mutatePipelineValue = (p: OnAction<OnSender<PipelineValueMutationPayload>>) =>
    radarCloudActionsCmd(p, radarCloudActions.mutatePipelineStageValue(p))

export const updateRadar = ({ radar, radarId, ...p }: OnAction<OnUser<OnRadar<{ radar: RadarDetailsPayload }>>>) =>
    userCloudActionsCmd(p, userCloudActions.updateRadar(radarId, radar))

export const mutateByRadarConfig = (p: OnAction<OnSender<OnRadar<ByRadarConfig>>>) =>
    radarCloudActionsCmd(p, radarCloudActions.mutateByRadarConfig(p))

export const mutateRadarCollectionItem = <T extends CName>(
    p: OnAction<OnSender<OnRadar<{ item: ImportableItem<T>; cname: T; type: MutationType }>>>
) => adminCloudActionsCmd(p, adminCloudActions.mutateRadarCollectionItem(p))

export const removeRadarCollectionItems = <T extends CName>(p: OnAction<OnSender<RemoveCollectionItemsPayload<T>>>) =>
    adminCloudActionsCmd(p, adminCloudActions.removeCollectionItems(p))

export const createHub = (actionId: string, userId: string, hub: HubPayload) =>
    userCloudActionsCmd({ userId, actionId }, userCloudActions.createHub(hub))

export const updateHub = (actionId: string, userId: string, hub: HubPayload, hubId: string) =>
    userCloudActionsCmd({ userId, actionId }, userCloudActions.updateHub(hubId, hub))

export const updateUser = (actionId: string, userId: string, payload: RadarUser) =>
    userCloudActionsCmd({ userId, actionId }, userCloudActions.updateUser(payload))

export const updateNewsfeedVisit = (actionId: string, userId: string, p: OnRadar<{ timestamp: number }>) =>
    userCloudActionsCmd({ userId, actionId }, userCloudActions.updateNewsfeedTimestamp(p))

export const updateProfile = (actionId: string, user: Pick<User, "displayName" | "userId">) =>
    userCloudActionsCmd({ userId: user.userId!, actionId }, userCloudActions.updateUserProfile(user))

export const inviteUser = (p: OnSender<OnHost<RadarUserRequest & CloudActionMeta>>) =>
    adminCloudActionsCmd(p, adminCloudActions.inviteUser(p))

export const resendInvitation = (p: OnSender<OnAction<RadarUserActionPayload>>) =>
    adminCloudActionsCmd(p, adminCloudActions.resendUserInvite(p))

export const revokeInvitation = (p: OnSender<OnAction<RadarUserActionPayload>>) =>
    adminCloudActionsCmd(p, adminCloudActions.revokeUserInvite(p))

export const inviteToPortal = (p: OnSender<OnAction<any>>) => adminCloudActionsCmd(p, adminCloudActions.portalInvite(p))

export const acceptRequest = (p: OnSender<OnAction<RadarUserActionPayload>>) =>
    adminCloudActionsCmd(p, adminCloudActions.acceptAccessRequest(p))

export const declineRequest = (p: OnSender<OnAction<RadarUserActionPayload>>) =>
    adminCloudActionsCmd(p, adminCloudActions.denyAccessRequest(p))

export const addRelation = (p: OnAction<OnSender<OnRadar<RelationTuple>>>) =>
    radarCloudActionsCmd(p, radarCloudActions.addRelation(p))

export const importPriorityPayload = (p: OnAction<OnSender<OnRadar<PriorityImportPayload>>>) =>
    adminCloudActionsCmd(p, adminCloudActions.importPriorityChunk(p))

export const removeRelation = (p: OnAction<OnSender<OnRadar<RelationTuple>>>) =>
    radarCloudActionsCmd(p, radarCloudActions.removeRelation(p))

export const createComment = (p: OnAction<OnSender<CommentCreatePayload>>) =>
    radarCloudActionsCmd(p, radarCloudActions.createComment(p))

export const editComment = (p: OnAction<OnSender<CommentEditPayload>>) =>
    radarCloudActionsCmd(p, radarCloudActions.editComment(p))

export const deleteComment = (p: OnAction<OnSender<CommentDeletePayload>>) =>
    radarCloudActionsCmd(p, radarCloudActions.deleteComment(p))

export const mutateSearchArea = (p: OnAction<OnSender<SearchAreaMutationPayload>>) =>
    radarCloudActionsCmd(p, radarCloudActions.mutateSearchArea(p))

export const assignSearchArea = (p: OnAction<OnSender<SearchAreaAssignmentPayload>>) =>
    radarCloudActionsCmd(p, radarCloudActions.assignSearchArea(p))

export const mutateTag = (p: OnAction<OnSender<TagMutationPayload>>) =>
    radarCloudActionsCmd(p, radarCloudActions.mutateTag(p))

export const assignTag = (p: OnAction<OnSender<TagAssignmentPayload>>) =>
    radarCloudActionsCmd(p, radarCloudActions.assignTag(p))

export const createAndAssignTag = (
    p: OnAction<OnSender<TagMutationPayload & OmitStrict<TagAssignmentPayload, "tagId" | "assign">>>
) => radarCloudActionsCmd(p, radarCloudActions.createAndAssignTag(p))

export const mutateSegmentTag = (p: OnAction<OnSender<SegmentTagMutationPayload>>) =>
    radarCloudActionsCmd(p, radarCloudActions.mutateSegmentTag(p))
export const createBatchSegmentTags = (p: OnAction<OnSender<SegmentTagBatchMutationPayload>>) =>
    radarCloudActionsCmd(p, radarCloudActions.createBatchSegmentTags(p))

export const assignSegmentTag = (p: OnAction<OnSender<SegmentTagAssignmentPayload>>) =>
    radarCloudActionsCmd(p, radarCloudActions.assignSegmentTag(p))

export const createAndAssignSegmentTag = (
    p: OnAction<OnSender<SegmentTagMutationPayload & OmitStrict<SegmentTagAssignmentPayload, "segmentId" | "assign">>>
) => radarCloudActionsCmd(p, radarCloudActions.createAndAssignSegmentTag(p))

export const uploadPrioritization = (p: any) => radarCloudActionsCmd(p, radarCloudActions.uploadPrioritization(p))
