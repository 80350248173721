import * as React from "react"
import { Filters } from "../../../../functions/src/models/filtering"
import { _RangePickerWrapper } from "./DatePicker.styles"
import AntDDatePicker from "antd/lib/date-picker"
import { RangePickerProps } from "antd/lib/date-picker/interface"
import "antd/lib/date-picker/style/index.css"
import { fromDateRange } from "../../../models/dateTime"

const { RangePicker } = AntDDatePicker

export type DatePickerProps = {
    column?: boolean
    onChange: F1<{ type: "dateRange"; v: Pick<Filters, "date"> }>
    value: RangePickerProps["value"]
}

export const DatePicker = (p: DatePickerProps) => (
    <_RangePickerWrapper minWidth={!p.column}>
        <RangePicker
            value={p.value}
            onChange={e => p.onChange({ type: "dateRange", v: { date: fromDateRange(e) } })}
            separator=""
            placeholder={p.column ? ["From", "To"] : undefined}
            suffixIcon={p.column ? <></> : undefined}
            allowClear={false}
        />
    </_RangePickerWrapper>
)
