import { pathParts, userPaths, radarPaths, allPaths, adminPaths, demoPaths } from "../paths"
import { matchPathParams, materialize, getMatchingPath, matchesPath } from "../utils/router"
import { matchOnValue, toRegExp, values } from "../../functions/src/utils/map"
import {
    isValidLocation,
    Processing,
    None,
    InvalidLocation,
    InvalidRadarLocation,
    RadarLocation,
    UserLocation,
    AdminLocation,
    mkWaiting,
    mkNotAllowed,
    mkAllowed,
    isDemoRadarLocation,
    isRadarDisabled,
    isRadarLocation,
    isUserLocation,
    StaticLocation
} from "../models/LocationType"
import { assertNever } from "../../functions/src/utils"
import { isLoggedIn, isRoot } from "../models/LoginStatus"
import { isNotFetched, isFetching, isFetchedError, isFetched } from "../../functions/src/utils/types"
import { isPG } from "../../functions/src/models/Radar"
import { AuthRule, Rules, AuthReason, PathRule, TrmLocation, AuthConfig } from "./middlewares/authMiddleware.types"
import { RootState, AuthState } from "./store"
import { AppLocationState, ValidLocationState, AuthenticatedState } from "../models/auth"
import { NavigationParams } from "../utils/router.types"
import { Action } from "../utils/redux.types"
import { isDemo } from "../../functions/src/models/common"
import { SIGN_IN_RADAR } from "../views/user/SignInWithEmailLink"

type Rule = AuthRule<RootState, AppLocationState>

export const isConfigurationFetchedRule: Rule = (_, { auth }) => {
    return !isFetched(auth.configs) ? mkWaiting() : mkAllowed()
}

export const isValidLocationRule: Rule = (_, _2, params) =>
    isValidLocation(params) ? mkAllowed() : mkNotAllowed("NotFound")

export const isLoggedInRule: Rule = (_, { auth: { authentication: loginStatus } }, params) => {
    if (loginStatus.type === "ProcessingLogin") return mkWaiting()
    if (isDemoRadarLocation(params)) return mkAllowed()
    return loginStatus.type === "LoggedIn" ? mkAllowed() : mkNotAllowed("NotAuthenticated")
}

export const isAuthorizedRule: Rule = (_, { auth: { authentication } }, params: ValidLocationState) => {
    if (isRadarDisabled(params)) return mkNotAllowed("RadarDisabled")
    if (isDemoRadarLocation(params)) return mkAllowed()
    if (!isLoggedIn(authentication)) return mkNotAllowed("NoAccessGranted")
    const currentRadarId = params.locationParams.radarId
    const { authorization } = authentication
    switch (authorization) {
        case "AccessDeclined":
        case "AccessNotRequested":
        case "AccessRequested":
            return mkNotAllowed("NoAccessGranted")
        case "AccessPending":
            return mkWaiting()
        case "AccessDeactivated":
            return mkNotAllowed("Deactivated")
        // eslint-disable-next-line no-fallthrough
        case "RadarAccess":
        case "AdminAccess":
            const userRadarIds = new Set(authentication.user.radarIds.concat(authentication.user.adminRadarIds))
            if (!currentRadarId || userRadarIds.has(currentRadarId)) return mkAllowed()
            else return mkNotAllowed("NoAccessGranted")
        case "RootAccess":
            return mkAllowed()
    }
    assertNever(authorization)
}

export const isDashboardAllowedRule: Rule = (_, _2, params: ValidLocationState) =>
    isDemoRadarLocation(params) && !params.locationParams.withDashboard ? mkNotAllowed("NotAuthorized") : mkAllowed()

export const isReportsAllowedRule: Rule = (_, _2, params) =>
    isRadarLocation(params) && !isDemo(params.locationParams) && params.locationParams.withPipelinesSummary
        ? mkAllowed()
        : mkNotAllowed("NotFound")

export const isNotAuthorizedRule: Rule = (_, { auth: { authentication: loginStatus } }) => {
    if (loginStatus.type === "ProcessingLogin") return mkWaiting()
    if (!isLoggedIn(loginStatus)) return mkAllowed()
    return mkNotAllowed("Authenticated")
}

export const isSuperAdminRule: Rule = (_, { auth: { authentication: loginStatus } }) =>
    isLoggedIn(loginStatus) && loginStatus.authorization === "RootAccess" ? mkAllowed() : mkNotAllowed("NotAuthorized")

export const isNotSuperAdminRule: Rule = (...args) =>
    isSuperAdminRule(...args).status === "Allowed" ? mkNotAllowed("NotAuthorized") : mkAllowed()

const isNonPGImport = (path: string, params: ValidLocationState) =>
    !isPG(params.locationParams.hubSlug!) && matchesPath(adminPaths["admin/importRadar"].path, path)

export const isAdminRule: Rule = (path, state, params: ValidLocationState) => {
    const { auth } = state
    const loginStatus = auth.authentication as AuthenticatedState
    const rootAuthResult = isSuperAdminRule(path, state, params)
    if (rootAuthResult.status === "Allowed") return rootAuthResult
    if (isUserLocation(params)) return rootAuthResult
    if (isNonPGImport(path.pathname, params)) return rootAuthResult
    if (loginStatus.authorization === "AdminAccess") return mkAllowed()

    return rootAuthResult
}

export const hasRadarAccess: Rule = (_, { auth }, params: ValidLocationState) => {
    if (!isLoggedIn(auth.authentication) || isFetching(auth.configs)) return mkWaiting()
    if (isFetchedError(auth.configs) || isNotFetched(auth.configs)) return mkNotAllowed("ConfigsError")

    const paramsMap = auth.configs.value
    const radarSlug = params.locationParams.radarSlug
    const root = isRoot(auth.authentication)
    if (
        !root &&
        radarSlug &&
        paramsMap[radarSlug] &&
        !auth.authentication.user.adminRadarIds.includes(paramsMap[radarSlug].radarId)
    )
        return mkNotAllowed("NotAuthorized")
    return mkAllowed()
}

export const mkPathRule = <T1 = RootState, T2 = AppLocationState>(
    suffix: string,
    rules: Rules<T1, T2>
): PathRule<T1, T2> => {
    const pattern = `/${pathParts.app}${suffix}`
    return {
        pattern,
        patternRegExp: toRegExp(pattern),
        rules
    }
}

export const rulesMap = {
    demo: mkPathRule("/demo/.*", [isNotAuthorizedRule]),
    all: mkPathRule(".*", [isConfigurationFetchedRule, isValidLocationRule]),
    users: mkPathRule(
        `/${pathParts.user}/(.*/${pathParts.federated}|.*/${pathParts.login}|${pathParts.login}|${pathParts.signInWithEmailLink}|.*/${pathParts.logout})$`,
        [isNotAuthorizedRule]
    ),
    reports: mkPathRule(`.*/${pathParts.reports}`, [isReportsAllowedRule, isNotSuperAdminRule]),
    authorized: mkPathRule(`/(?!${pathParts.user})/*`, [isLoggedInRule, isAuthorizedRule]),
    dashboard: mkPathRule(`.*/dashboard$`, [isDashboardAllowedRule]),
    admin: mkPathRule(`/${pathParts.admin}/*`, [isLoggedInRule, isAdminRule]),
    adminRadars: mkPathRule(`/${pathParts.admin}/${pathParts.radars}/*`, [hasRadarAccess]),
    newRadar: mkPathRule(`/${pathParts.admin}/${pathParts.radar}/${pathParts.new}`, [isSuperAdminRule]),
    superAdmin: mkPathRule(`/${pathParts.radicle}/*`, [isSuperAdminRule])
}

export const getRadarSlug = ({ configs, authentication }: AuthState, radarSlug?: string | null): string | null => {
    if (radarSlug) return radarSlug
    if (!isFetched(configs) || !isLoggedIn(authentication)) return null
    return configs.value[[...authentication.user.radarIds, ...authentication.user.adminRadarIds][0]]?.radarSlug || null
}

type NavigateAction = F1<NavigationParams, Action>
export const getMapReasonToActions = (navigate?: NavigateAction) => (
    params: AppLocationState,
    reason: AuthReason,
    state: RootState
): Action[] => {
    if (!isValidLocation(params)) return []
    const delay = 100

    const { "user/login": login, "user/adminLogin": adminLogin } = userPaths
    switch (reason) {
        case "NotAuthenticated": {
            // known bug: since history actions are not synchronous, when users clicks 'back' on browser
            // to get back to a site where he is not allowed to be, our navigate action is faster than users
            // 'back' action therefore user gets back even if we dont allow him to. A small timeout fixes the case
            const newPath =
                isRadarLocation(params) || params.locationParams.radarId
                    ? materialize(login.path, params.locationParams as any)
                    : adminLogin.path
            return newPath !== params.pathname && navigate ? [navigate({ path: newPath, delay })] : []
        }
        case "Authenticated":
            const signInRadarSlug = localStorage.getItem(SIGN_IN_RADAR)
            localStorage.removeItem(SIGN_IN_RADAR)
            const radarSlug = getRadarSlug(state.auth, signInRadarSlug || params.locationParams.radarSlug)
            const actions = []

            if (navigate) {
                if (radarSlug) {
                    if (radarSlug === "ventures") {
                        actions.push(
                            navigate({ path: radarPaths["radar/dashboard"].path, slugs: { radarSlug }, delay })
                        )
                    } else {
                        actions.push(navigate({ path: radarPaths["radar/radar"].path, slugs: { radarSlug }, delay }))
                    }
                } else if (isRoot(state.auth.authentication)) {
                    actions.push(navigate({ path: adminPaths["admin/dashboard"].path, delay }))
                }
            }

            return actions
    }

    return []
}

const hasRadar = matchOnValue<LocationParams>("radarSlug")

const getLocationParams = (l: TrmLocation, { auth }: RootState): AppLocationState => {
    if (isFetching(auth.configs)) return Processing()
    if (isNotFetched(auth.configs)) return None()
    if (isFetchedError(auth.configs)) return InvalidLocation(l)
    const paramsMap = auth.configs.value
    const path = getMatchingPath(l.pathname, allPaths, p => p.path)
    if (!path) return InvalidLocation(l)

    const params = matchPathParams(path.path, l.pathname, false)
    const { radarSlug = "", ...rest } = (params || {}) as SMap<string>
    switch (path.type) {
        case "StaticLocation": {
            return StaticLocation(l)
        }
        case "UserLocation": {
            if ([userPaths["user/adminLogin"], userPaths["user/linkLogin"]].includes(path)) return UserLocation(l)

            const locationParams = hasRadar(paramsMap, radarSlug)
            return locationParams ? UserLocation(l, locationParams) : InvalidRadarLocation(l)
        }
        case "AdminLocation": {
            if (values(demoPaths).includes(path)) return AdminLocation(l)
            return AdminLocation(l, hasRadar(paramsMap, radarSlug) || {})
        }
        case "RadarLocation": {
            const locationParams = hasRadar(paramsMap, radarSlug)
            return locationParams ? RadarLocation(l, locationParams, rest) : InvalidRadarLocation(l)
        }
    }
}

type Config = AuthConfig<AppLocationState, RootState, AuthReason>

export const getConfig = (skipActions: string[] = [], navigate?: NavigateAction): Config => ({
    skipActionTypes: skipActions,
    defaultPolicy: { status: "NotAllowed", reason: "NotFound" },
    rulesMap,
    getLocationParams,
    getPreviousAuthPayload: ({ auth: { params, permissions } }) => ({ params, permissions }),
    mapReasonToActions: getMapReasonToActions(navigate),
    getCurrentLocation: ({ router }: RootState) => (router ? router.location : { pathname: "", search: "" })
})
