import styled from "styled-components"
import { _IconButton } from "../styles/buttons"
import { _h3 } from "../styles/typography"
import { NotificationType } from "../store/store"

export const notificationBg: Record<NotificationType, string> = {
    Ok: "#0093ee",
    Err: "#ff151f"
}

export const _Notification = styled.div<{ type: NotificationType }>`
    background: ${({ type }) => notificationBg[type]};
    height: 45px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 11px 0;
    pointer-events: all;
    * {
        display: flex;
        justify-content: center;
        flex: 1 1 auto;
    }
`

export const _Spacer = styled.div`
    flex: 0 1 auto;
    width: 36px;
    content: "";
`

export const _NotificationTitle = styled(_h3)`
    display: flex;
`

export const _NotificationClose = styled(_IconButton)`
    display: flex;
    flex: 0 1 auto;
`
