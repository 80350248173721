import { validate, validNumber, validString, validStringDef, validNumberDef } from "../utils/validators"
import { mapToDisplay } from "./common"
import { RFields } from "./RFields"
import {
    DEFAULT_PLACEHOLDER,
    sharedRequiredRadarFieldsValidationMap,
    sharedOptionalRadarFieldsValidationMap,
    sharedVMMetaValidationMap
} from "./shared"
import { CompanyVM } from "./ViewModels"

const companyRequiredFieldsValidation: ValidationMap<Required<CompanyFieldsRequired>> = {
    product_technology_name: validString,
    company_name: validString,
    product_technology_url: validStringDef,
    ...sharedRequiredRadarFieldsValidationMap
}

const companyOptionalFieldsValidation: ValidationMap<Required<CompanyFieldsOptional>> = {
    ...sharedOptionalRadarFieldsValidationMap,
    company_url: validStringDef,
    company_type: validStringDef,
    active_ingredient: validStringDef,
    other_ingredients: validStringDef,
    efficacy_ranking: validNumberDef,
    efficacy_source: validStringDef,
    efficacy_source_url: validStringDef,
    moa: validStringDef,
    crunchbase_url: validStringDef,
    safety_ranking: validStringDef,
    safety_source_url: validStringDef,
    logo_url: validStringDef
}

export const companyRelationsValidationMap: ValidationMap<Required<CompanyRelations>> = {}

export const companyRelations: RFields<"research_papers"> = RFields<unknown, CompanyRelations>(
    {},
    companyRelationsValidationMap
)

export const companyFieldsValidation: ValidationMap<Required<CompanyFields>> = {
    ...companyRequiredFieldsValidation,
    ...companyOptionalFieldsValidation
}

export const companyValidationMap: ValidationMap<Required<Company>> = {
    ...companyFieldsValidation,
    companyId: validString,
    createdTs: validNumber
}
export const companyFields: CFields<"companies"> = RFields<CompanyFieldsRequired, CompanyFieldsOptional>(
    companyRequiredFieldsValidation,
    companyOptionalFieldsValidation
)

export const validateCompanyRelations = validate<CompanyRelations>(companyRelationsValidationMap)
export const validateCompanyFields = validate<CompanyFields>(companyFieldsValidation)
export const validateCompany = validate<Company>(companyValidationMap)
export const validateCompanyVM = validate<CompanyVM>({
    ...companyValidationMap,
    ...sharedVMMetaValidationMap
})

export const displayCompany = (p: CompanyVM, ph: string = DEFAULT_PLACEHOLDER): Displayable<CompanyVM> =>
    mapToDisplay(p, ph)

export const CompanyFieldsDisplay: Displayable<CompanyVM> = {
    product_technology_name: "Product & Technology name",
    company_name: "Company name",
    description: "Description",
    active_ingredient: "Main Active ingredients",
    company_type: "Company type",
    company_url: "Company URL",
    efficacy_ranking: "Efficacy ranking",
    efficacy_source: "Efficacy source",
    efficacy_source_url: "Efficacy source URL",
    moa: "Mechanism of action",
    other_ingredients: "Other ingredients",
    product_technology_url: "Product & Technology URL",
    source: "Source",
    blurOnListing: "",
    createdTs: "Created At",
    commentsCount: "No. of comments",
    contactedLeadsCount: "No. of contacted leads",
    companyId: "ID",
    crunchbase_url: "Crunchbase URL",
    safety_ranking: "Safety ranking",
    safety_source_url: "Safety source URL",
    logo_url: "Logo URL",
    filterBounds: ""
}
