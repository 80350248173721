import {
    AppLocationState,
    BaseLocationParams,
    UserLocationState,
    AdminLocationState,
    RadarLocationState,
    NoLocationState,
    InvalidLocationState,
    ValidLocationState,
    StaticLocationState
} from "./auth"
import { TrmLocation, AuthReason, NotAllowed, Waiting, Allowed } from "../store/middlewares/authMiddleware.types"
import { AuthState } from "../store/store"
import { isDemo } from "../../functions/src/models/common"
import { isLoggedIn, isRoot, isNotLoggedIn } from "./LoginStatus"
import { isFetched } from "../../functions/src/utils/types"
import { keys, values } from "../../functions/src/utils/map"

export const None = (): AppLocationState => ({ type: "None" })
export const Processing = (): AppLocationState => ({ type: "Processing" })
export const InvalidLocation = (location: TrmLocation): AppLocationState => ({ type: "InvalidLocation", ...location })

export const UserLocation = (location: TrmLocation, locationParams: BaseLocationParams = {}): UserLocationState => ({
    locationParams,
    type: "UserLocation",
    ...location
})

export const StaticLocation = (
    location: TrmLocation,
    locationParams: BaseLocationParams = {}
): StaticLocationState => ({
    locationParams,
    type: "StaticLocation",
    ...location
})

export const AdminLocation = (
    location: TrmLocation,
    locationParams: BaseLocationParams | LocationParams = {}
): AdminLocationState => ({
    type: "AdminLocation",
    ...location,
    locationParams
})

export const RadarLocation = (
    location: TrmLocation,
    locationParams: LocationParams,
    params: SMap<string> = {}
): RadarLocationState => ({
    type: "RadarLocation",
    ...location,
    locationParams,
    params
})

export const InvalidRadarLocation = (location: TrmLocation): AppLocationState => ({
    type: "InvalidRadarLocation",
    ...location
})

export const InvalidAdminLocation = (location: TrmLocation): AppLocationState => ({
    type: "InvalidAdminLocation",
    ...location
})

export const isNoLocation = (p: AppLocationState): p is NoLocationState => p.type === "None" || p.type === "Processing"

export const isInvalidLocation = (p: AppLocationState): p is InvalidLocationState =>
    p.type === "InvalidLocation" || p.type === "InvalidRadarLocation"
export const isRadarLocation = (p: AppLocationState): p is RadarLocationState => p.type === "RadarLocation"
export const isAdminLocation = (p: AppLocationState): p is AdminLocationState => p.type === "AdminLocation"
export const isUserLocation = (p: AppLocationState): p is UserLocationState => p.type === "UserLocation"
export const isStaticLocation = (p: AppLocationState): p is StaticLocationState => p.type === "StaticLocation"

export const isValidLocation = (p: AppLocationState): p is ValidLocationState =>
    isRadarLocation(p) || isAdminLocation(p) || isUserLocation(p) || isStaticLocation(p)

export const mkNotAllowed = (reason: AuthReason): NotAllowed<AuthReason> => ({ status: "NotAllowed", reason })
export const mkWaiting = (): Waiting => ({ status: "Waiting" })
export const mkAllowed = (): Allowed => ({ status: "Allowed" })

export const isDemoRadarLocation = (p: AppLocationState): p is RadarLocationState =>
    isRadarLocation(p) && isDemo(p.locationParams)

export const isRadarDisabled = (params: AppLocationState) => isRadarLocation(params) && params.locationParams.isDisabled

export const getCurrentRadarId = (state: AuthState): string | null => {
    if (!isRadarLocation(state.params) && !isAdminLocation(state.params)) return null
    return state.params.locationParams.radarId || null
}

export const getCurrentRadar = (state: AuthState): Partial<LocationParams> | null => {
    if (!isRadarLocation(state.params) && !isAdminLocation(state.params)) return null
    return state.params.locationParams || null
}

export const getUserRadars = (state: AuthState): LocationParams[] => {
    if (isNotLoggedIn(state.authentication) && state.authentication.emailGiven && isDemoRadarLocation(state.params))
        return [state.params.locationParams]
    if (!isLoggedIn(state.authentication)) return []
    if (!isFetched(state.configs)) return []
    const { user } = state.authentication

    if (isRoot(state.authentication)) return values(state.configs.value)

    const radars = state.configs.value
    const userRadarIds = new Set(user.radarIds.concat(user.adminRadarIds))
    return keys(radars).reduce<LocationParams[]>(
        (acc, rid) =>
            userRadarIds.has(rid) || (isDemoRadarLocation(state.params) && state.params.locationParams.radarId === rid)
                ? acc.concat(radars[rid])
                : acc,
        []
    )
}

export const getCurrentPathname = (state: AuthState): string =>
    isNoLocation(state.params) ? "" : state.params.pathname
