import * as React from "react"
import { connect } from "react-redux"
import * as Papa from "papaparse"
import { v4 } from "uuid"
import styled from "styled-components"
import { MapState, MapDispatch } from "../../utils/redux.types"
import { isDone, isProcessing } from "../../../functions/src/utils/types"
import { Err, Ok } from "../../../functions/src/utils/validators"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { getCurrentRadarId } from "../../models/LocationType"
import { actions } from "../../store/cloud/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { useDropzone } from "react-dropzone"
import { Popup } from "../../components/Popup"
import { PopupInner, PopupContent } from "../../components/Popup.styles"
import { Loader } from "../common/Loader"
import { IconSvg } from "../../components/IconSvg"
import { _h1, _TagFont, _BodyFont } from "../../styles/typography"
import { Flex, _VerticalSpace, _HorizontalSpace } from "../../styles/common"
import { Button, SecondaryButton } from "../../styles/buttons"
import { SimpleError, AnyError } from "./validators"
import {
    validateRequiredColumns,
    validateSeparators,
    validateDuplicates,
    validateInterleave,
    validateBlanks
} from "./validators"

type ImportPayload = {
    radarId: string
    sectorId: string
    rows: string[]
}

type ImportActions = {
    uploadPrioritization: (actionId: string, payload: ImportPayload) => void
    queueNotification: F1<Result<string>>
}

type ImportState = {
    radarId: string
    results: SMap<CloudActionResult>
}

type ImportModalProps = {
    onClose: F0
    meta: UploadPrioritizationMeta
} & ImportActions &
    ImportState

export type UploadPrioritizationMeta = {
    sectorId: string
    onDone: F0
}

const mapDispatch: MapDispatch<ImportActions> = dispatch => ({
    uploadPrioritization: (actionId: string, { radarId, sectorId, rows }: ImportPayload) => {
        return dispatch(actions.uploadPrioritization(actionId, { radarId, sectorId, rows }))
    },
    queueNotification: (msg: Result<string>) =>
        dispatch(uiActions.queueNotification({ type: msg.type, text: msg.value, id: v4() }))
})

const mapState: MapState<ImportState> = ({ auth, cloud }) => {
    return {
        radarId: getCurrentRadarId(auth) || "",
        results: cloud.actionsResults
    }
}

const _UploadTarget = styled.div`
    width: 398px;
    height: 182px;
    background: #f6f7fb;
    position: relative;

    /* Generated by: https://kovart.github.io/dashed-border-generator/ */
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='black' stroke-width='2' stroke-dasharray='5%2c5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
    border-radius: 15px;
`

const _ErrorList = styled.div`
    width: 398px;
    ul {
        padding-left: 18px;
    }
`

const formatError = (err: SimpleError | Papa.ParseError) => {
    const row = (err as any)?.row
    if (row !== undefined)
        if (row === 0)
            // Row is present only on ParseErrors
            return <li>{err.message}</li>
        else
            return (
                <li>
                    Row {(err as any).row}: {err.message}
                </li>
            )
    else return <li>Header: {err.message}</li>
}
const validateResults = (results: Papa.ParseResult): AnyError[] => {
    let errs: AnyError[] = []

    // 1. results.meta.fields must contain required fields. These are `Startup Name` and `Crunchbase URL` and must come first.
    errs = errs.concat(validateRequiredColumns(results))

    // 3. All other .fields must contain `::`
    errs = errs.concat(validateSeparators(results))

    // 4. No duplicate column names
    errs = errs.concat(validateDuplicates(results))

    // 5. Hierarchies must be contiguous.
    // This means that Asia::Tigers,Asia::Elephants,India::Elephants are ok
    // but Asia::Tigers,India::Elephants,Asia::Elephants are not
    errs = errs.concat(validateInterleave(results))

    // 5. No blank values are allowed
    errs = errs.concat(validateBlanks(results))

    return errs
}

export const UploadPrioritization = connect(
    mapState,
    mapDispatch
)((p: ImportModalProps) => {
    const [errors, setErrors] = React.useState([] as AnyError[])
    const { uploadPrioritization, radarId, results, onClose } = p
    const { onDone, sectorId } = p.meta
    const [pznState, doUpload] = useCloudAction(uploadPrioritization, results)
    const [ready, setReady] = React.useState(false)
    const [resultsToUpload, setResults] = React.useState({} as Papa.ParseResult)
    const startUpload = () => {
        const res = resultsToUpload
        // This is the format we have in firebase: an array of csv-rows.
        // We unparse each row (including the header) back into csv and send that
        const head = Papa.unparse([res.meta.fields])
        const rows = res.data.map(row => Papa.unparse([row], { header: false }))

        doUpload({ radarId, sectorId, rows: [head, ...rows] })
    }

    const onParseComplete = (res: Papa.ParseResult) => {
        setResults({} as Papa.ParseResult)
        setReady(false)
        setErrors([])
        const errs: AnyError[] = validateResults(res)

        if (errs.length != 0) {
            p.queueNotification(Err("This file contains errors, please fix them and upload again."))
            setErrors(errs)
        } else {
            setResults(res)
            setReady(true)
        }
    }

    const onParseError = (err: Papa.ParseError) => {
        p.queueNotification(Err("This file contains errors, please fix them and upload again."))
        setErrors([err])
        setReady(false)
        setResults({} as Papa.ParseResult)
    }

    const onDrop = React.useCallback((acceptedFiles: File[]) => {
        setErrors([])
        Papa.parse(acceptedFiles[0], {
            header: true,
            skipEmptyLines: true,
            error: onParseError,
            complete: onParseComplete
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (!isDone(pznState)) return

        p.queueNotification(Ok("Uploading complete."))
        onDone()
    }, [pznState.type]) // eslint-disable-line react-hooks/exhaustive-deps

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
        accept: ["text/csv", ".csv"]
    })

    const processing = isProcessing(pznState)
    const uploadBox = processing ? (
        <_UploadTarget>
            <_VerticalSpace base="64px" />
            <Loader loadingText="Processing upload…" size="regular" />
        </_UploadTarget>
    ) : (
        <_UploadTarget {...getRootProps()}>
            <input {...getInputProps()} />
            <Flex direction="column">
                <_VerticalSpace base="39px" />
                <IconSvg name="download-box" height={59} />
                <_BodyFont s16 align="center">
                    {isDragActive ? (
                        <p>Drop a CSV file here</p>
                    ) : (
                        <p>
                            <strong>Attach</strong> or <strong>drag</strong> a file here!
                        </p>
                    )}
                </_BodyFont>
            </Flex>
        </_UploadTarget>
    )

    return (
        <Popup logo onClose={onClose}>
            <PopupInner column>
                <_TagFont bold fontSize={14}>
                    Import prioritization data from CSV
                </_TagFont>
                <_VerticalSpace base="25px" />
                <PopupContent>
                    {uploadBox}
                    {ready && errors.length == 0 && (
                        <_BodyFont align="left" style={{ paddingTop: "25px" }}>
                            The CSV file appears to be valid.
                        </_BodyFont>
                    )}
                    {errors.length > 0 && (
                        <_ErrorList>
                            <_BodyFont align="left" style={{ paddingTop: "25px" }}>
                                Errors were detected in the file:
                                <ul className="errors">{errors.map(formatError)}</ul>
                            </_BodyFont>
                        </_ErrorList>
                    )}
                    <_VerticalSpace base="36px" />
                    <Flex>
                        <Button disabled={!ready || processing} onClick={startUpload}>
                            Upload
                        </Button>
                        <_HorizontalSpace base="23px" />
                        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
                    </Flex>
                </PopupContent>
            </PopupInner>
        </Popup>
    )
})
