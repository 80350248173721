import * as React from "react"
import { _FlexContainer } from "../../../styles/common"
import { LazyImage } from "../../LazyImage"
import { _CardSectionContainer, _CardSectionTitle } from "./CardSection.styles"

export type ImageCardSectionProps = {
    title: string
    src: string
}

export const ImageCardSection = (p: ImageCardSectionProps) => {
    return (
        <_FlexContainer>
            <_CardSectionContainer>
                <_CardSectionTitle>{p.title}</_CardSectionTitle>
                <LazyImage src={p.src} style={{ objectFit: "contain" }} height={200} />
            </_CardSectionContainer>
        </_FlexContainer>
    )
}
