import * as React from "react"
import { ListParams, ListDependencies } from "./Lists"
import { pickObject, values } from "../../../functions/src/utils/map"
import { schemas } from "./ListSchemas"
import { findIndex } from "../../../functions/src/utils"
import { getHeaderRow, getTooltipContents, getRow } from "./utils"
import { UIState } from "../../store/store"
import { ViewModels, ViewModelsMap } from "../../../functions/src/models/ViewModels"
import { Row, RowSchema, TableProps } from "../../components/table/TableView.types"
import { getSortingParams, Sorting, SortingParams } from "../../../functions/src/models/sorting"
import { mapAsyncVMStoVMS } from "../../store/async/utils"
import { isFetching, Loaded } from "../../../functions/src/utils/types"
import { getCollectionSchema } from "../../../functions/src/models/schemas"

interface UseListCollectionToRowMappingProps<C extends CName> {
    rowSchema: RowSchema<ViewModelsMap[C]>
    isRoot: boolean
    config: LocationParams
    cname: C
    deps: ListDependencies<C>
    ui: UIState
    preparedCollection: ViewModelsMap[C][]
    searchAreaId?: string
    withDecorators?: boolean
    withComments?: boolean
}

const useListCollectionToRowMapping = <C extends CName>({
    rowSchema,
    isRoot,
    config,
    cname,
    deps,
    ui,
    preparedCollection,
    searchAreaId,
    withDecorators,
    withComments
}: UseListCollectionToRowMappingProps<C>) =>
    React.useMemo(() => {
        const decoratorsToAttach: ListDecorators[] = []
        const isDemo = config.restriction === "demo"
        if (!isDemo && !isRoot && config.withAssignments) decoratorsToAttach.push("assignment")
        if (searchAreaId && !isDemo && !isRoot) decoratorsToAttach.push("pipelineStage")
        if (searchAreaId && config.withPriorityRanks && !isDemo) decoratorsToAttach.push("priorityRank")
        if (config.withStars && !isDemo) decoratorsToAttach.push("star")
        return preparedCollection.map(
            getRow({
                cname,
                decoratorsToAttach: withDecorators ? decoratorsToAttach : [],
                deps,
                rowSchema,
                searchAreaId,
                isDemo,
                isRoot,
                withComments
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isRoot,
        rowSchema,
        preparedCollection,
        ui.filters.searchArea,
        deps.decorators,
        deps.pipelines,
        deps.users,
        deps.comments,
        deps.searchAreas,
        withDecorators,
        withComments
    ])

export const useList = <C extends CName>(
    p: ListParams<C>,
    options?: {
        schema?: RowSchema<ViewModelsMap[C]>
        sorting?: Sorting
        pinnedColumns?: { left?: (keyof ViewModelsMap[C] & string)[]; right?: (keyof ViewModelsMap[C] & string)[] }
    }
): TableProps<
    (keyof ViewModelsMap[C] & string) | "assignment" | "star" | "comments" | "pipelineStage" | "priorityRank"
> => {
    const cleanedProps = pickObject(p, ["cname", "deps", "config", "isRoot", "ui", "withComments", "withDecorators"])
    const { cname, ui, deps, isRoot, config, withComments = true, withDecorators = true } = cleanedProps
    const isDemo = deps.radar && deps.radar.demo
    const searchAreaId = React.useMemo(
        () => values(deps.searchAreas).find(sa => sa.name === ui.filters.searchArea || "")?.areaId,
        [deps.searchAreas, ui.filters.searchArea]
    )
    const collection = deps[cname] as SMap<ViewModelsMap[C]>
    const preparedCollection = React.useMemo(() => values(collection), [collection])
    const csorting = (options?.sorting || ui.sorting)[cname] as SortingParams<C>[] | undefined
    const se = getSortingParams({ cname, searchAreaId, config, sorting: csorting })

    const tooltipContents = React.useMemo(() => getTooltipContents(cname, collection, deps, isRoot, isDemo, config), [
        cname,
        collection,
        deps,
        isRoot,
        isDemo,
        config
    ])

    const rowSchema = options?.schema || (schemas[cname].schema as RowSchema<ViewModelsMap[C]>)
    const headerRow = React.useMemo(
        () =>
            getHeaderRow({
                isDemo,
                isRoot,
                flags: config,
                cname,
                ui,
                rowSchema,
                withDecorators,
                withComments
            }),
        [isDemo, isRoot, config, cname, ui, rowSchema, withDecorators, withComments]
    )
    const sortingColumn = findIndex(headerRow, v => v.sortingKey === se[0]?.sortingKey, -1)
    const rows = useListCollectionToRowMapping({
        rowSchema,
        isRoot,
        config,
        cname,
        deps,
        ui,
        preparedCollection,
        searchAreaId,
        withDecorators,
        withComments
    }) as Row<
        (keyof ViewModelsMap[C] & string) | "assignment" | "star" | "comments" | "pipelineStage" | "priorityRank"
    >[]

    const nameField = getCollectionSchema(cname).nameField
    const defaultPinnedColumns = React.useMemo(() => ({ left: [nameField] }), [nameField])

    return {
        rows,
        headerRow,
        loading: p.loadingMore || false,
        tooltipContents,
        sortingAsc: se[0]?.sortingAsc,
        sortingKey: se[0]?.sortingKey as string,
        sortingColumn,
        pinnedColumns: options?.pinnedColumns || (defaultPinnedColumns as any)
    }
}

export const getUseListData = <C extends CName>(
    cname: C,
    config: LocationParams,
    radarConfig: ByRadarConfig,
    vms: ViewModels,
    ui: UIState,
    isRoot: boolean,
    withDecorators?: boolean,
    withComments?: boolean
): ListParams<C> => {
    const deps = mapAsyncVMStoVMS(vms)

    return Loaded({
        cname,
        loadingMore: isFetching<any>(vms[cname]),
        deps,
        config,
        radarConfig,
        isRoot,
        ui,
        withDecorators,
        withComments
    })
}
