/* eslint-disable max-lines */
import { factory, pickIntersect } from "../utils/map"
import {
    ResearchPaperVM,
    StartupVM,
    SectorVM,
    InvestorVM,
    InfluencerVM,
    ResearchHubVM,
    TechExpertVM,
    TechTransferVM,
    PatentVM,
    PatentHolderVM,
    GrantVM,
    ClinicalTrialVM,
    CompanyVM,
    CollectionVMMeta
} from "./ViewModels"

export const ThumbnailFixture = factory<Thumbnail>({ height: 1, url: "url", width: 1 })

export const FileMetadataFixture = factory<FileMetadata>({
    filename: "filename",
    id: "id",
    size: 30,
    thumbnails: { full: ThumbnailFixture(), large: ThumbnailFixture(), small: ThumbnailFixture() },
    type: "type",
    url: "url"
})

export const VMMetaFixture = factory<CollectionVMMeta>({
    commentsCount: 0,
    contactedLeadsCount: 0,
    blurOnListing: false
})

const collectionFixtureRequiredFields = {
    source: "source"
}

const collectionFixtureOptionalFields = {
    description: "description"
}

const collectionRelations: CrossRadarConnections<CName> = {
    startups: ["startups"],
    influencers: ["influencers"],
    investors: ["investors"],
    patents: ["patents"],
    research_hubs: ["research_hubs"],
    research_papers: ["research_papers"],
    sectors: ["sectors"],
    tech_experts: ["tech_experts"],
    tech_transfers: ["tech_transfers"],
    patent_holders: ["patent_holders"],
    grants: ["grants"],
    clinical_trials: ["clinical_trials"],
    companies: ["companies"]
}

export const ItemClassifiersFixture = factory<ItemClassifiersInput>({
    primary_search_areas: ["primary_search_areas"],
    secondary_search_areas: ["secondary_search_areas"],
    tags: ["tag"],
    segment_tags: ["search_areas: segment_tags"]
})

export const ResearchPaperRequiredFixture = factory<ResearchPaperFieldsRequired>({
    paper_title: "paper_title",
    paper_url: "paper_url",
    ...collectionFixtureRequiredFields
})

export const ResearchPaperOptionalFixture = factory<Required<ResearchPaperFieldsOptional>>({
    actively_involved: "actively_involved",
    location: "location",
    publication_year: "2019",
    research_group: "research_group",
    university: "university",
    ...collectionFixtureOptionalFields
})

export const ResearchPaperFixture = factory<ResearchPaper>({
    ...ResearchPaperRequiredFixture(),
    ...ResearchPaperOptionalFixture(),
    researchPaperId: "researchPaperId",
    createdTs: 0
})

export const ResearchPaperRelationsFixture = factory<Required<ResearchPaperRelations>>({
    ...pickIntersect<ResearchPaperRelations>()(collectionRelations, [
        "startups",
        "companies",
        "sectors",
        "influencers",
        "investors",
        "patents",
        "research_hubs",
        "tech_experts",
        "tech_transfers"
    ])
})

export const ResearchPaperVMFixture = factory<ResearchPaperVM>({
    ...ResearchPaperFixture(),
    ...VMMetaFixture()
})

export const ResearchPaperFixtures: RFixtures<"research_papers"> = {
    required: ResearchPaperRequiredFixture(),
    optional: ResearchPaperOptionalFixture(),
    relation: ResearchPaperRelationsFixture(),
    all: ResearchPaperFixture()
}

export const StartupRequiredFixture = factory<StartupFieldsRequired>({
    startup_name: "startup_name",
    ...collectionFixtureRequiredFields
})

export const StartupOptionalFixture = factory<Required<StartupFieldsOptional>>({
    startup_completed: 1,
    stage: "stage",
    funding: 123123,
    funding_momentum: 12,
    // values can be set to special which is needed for proper displaying.
    // See getInputSchema() toInputState()
    logo_url: "logo_url",
    investor_quality: 1,
    headquarters: "headquarters",
    homepage_url: "homepage_url",
    lead_investors: ["lead_investor"],
    img_fallback: "https://www.img_fallback.com",
    founders_and_executives: ["founders_and_executives"],
    crunchbase_url: "crunchbase_url",
    ...collectionFixtureOptionalFields
})

export const StartupFixture = factory<Startup>({
    ...StartupRequiredFixture(),
    ...StartupOptionalFixture(),
    startupId: "startupId",
    createdTs: 123
})

export const StartupRelationsFixture = factory<Required<StartupRelationsRequired & StartupRelationsOptional>>({
    ...pickIntersect<StartupRelationsRequired & StartupRelationsOptional>()(collectionRelations, [
        "influencers",
        "investors",
        "patents",
        "research_hubs",
        "research_papers",
        "tech_experts",
        "tech_transfers",
        "sectors"
    ])
})

export const StartupVMFixture = factory<StartupVM>({
    ...StartupFixture(),
    ...VMMetaFixture(),
    sectors: ["sectors"],
    all_investors: ["all_investors"]
})

export const StartupFixtures: RFixtures<"startups"> = {
    required: StartupRequiredFixture(),
    optional: StartupOptionalFixture(),
    relation: StartupRelationsFixture(),
    all: StartupFixture()
}

export const SectorRequiredFixture = factory<SectorFieldsRequired>({
    sector_name: "sector_name",
    company_hex_display: "company_hex_display",
    column: 1,
    row: 1,
    ...collectionFixtureRequiredFields
})

export const SectorOptionalFixture = factory<Required<SectorFieldsOptional>>({
    avg_company_age: 1,
    funding: 10000,
    recent_funding: 125000,
    priority_score: 1,
    investor_quality: 1,
    market_size: "market_size",
    sector_completed: 1,
    sector_funding_momentum: 1,
    funding_momentum: 1,
    market_size_url: "market_size_url",
    capital_traction_url: "capital_traction_url",
    ...collectionFixtureOptionalFields,
    customer_type: ["customer_type"],
    business_model: ["business_model"],
    product: ["product"],
    geography: ["geography"],
    incumbents: ["incumbent"],
    sub_verticals: ["sub_vertical"],
    primary_customer: ["primary_customer"],
    sector_concentration: 100,
    jobs_to_be_done: "job_to_be_done",
    recommended: 0,
    recommended_description: "recommended_description",
    num_rounds: 10,
    prioritization: [""]
})

export const SectorFixture = factory<Sector>({
    ...SectorRequiredFixture(),
    ...SectorOptionalFixture(),
    sectorId: "sectorId",
    createdTs: 1
})

export const SectorVMFixture = factory<SectorVM>({
    ...SectorFixture(),
    ...VMMetaFixture(),
    num_startups: 5,
    prioritization: [] // Why is this needed if it's also in SectorOptionalFixture above?
})

export const SectorRelationsFixture = factory<Required<SectorRelationsOptional & SectorRelationsRequired>>({
    ...pickIntersect<SectorRelationsOptional & SectorRelationsRequired>()(collectionRelations, [
        "startups",
        "companies",
        "influencers",
        "investors",
        "patents",
        "research_papers",
        "research_hubs",
        "tech_experts",
        "tech_transfers"
    ])
})

export const SectorFixtures: RFixtures<"sectors"> = {
    required: SectorRequiredFixture(),
    optional: SectorOptionalFixture(),
    relation: SectorRelationsFixture(),
    all: SectorFixture()
}

export const InvestorRequiredFixture = factory<InvestorFieldsRequired>({
    investor_name: "investor_name",
    ...collectionFixtureRequiredFields
})

export const InvestorOptionalFixture = factory<Required<InvestorFieldsOptional>>({
    amount_invested: 1,
    amount_per_investment: 1,
    investor_completed: 1,
    investor_quality: 1,
    total_unique_investments: 1,
    number_of_investment_rounds: 1,
    investor_url: "investor_url",
    investments: "investments",
    ...collectionFixtureOptionalFields
})

export const InvestorFixture = factory<Investor>({
    ...InvestorRequiredFixture(),
    ...InvestorOptionalFixture(),
    investorId: "investorId",
    createdTs: 1
})

export const InvestorVMFixture = factory<InvestorVM>({
    ...InvestorFixture(),
    ...VMMetaFixture()
})

export const InvestorRelationsFixture = factory<Required<InvestorRelations>>({
    ...pickIntersect<InvestorRelations>()(collectionRelations, [
        "startups",
        "companies",
        "sectors",
        "influencers",
        "research_hubs",
        "tech_experts"
    ])
})

export const InvestorFixtures: RFixtures<"investors"> = {
    required: InvestorRequiredFixture(),
    optional: InvestorOptionalFixture(),
    relation: InvestorRelationsFixture(),
    all: InvestorFixture()
}

export const InfluencerRequiredFixture = factory<InfluencerFieldsRequired>({
    influencer_name: "influencer_name",
    ...collectionFixtureRequiredFields
})

export const InfluencerOptionalFixture = factory<Required<InfluencerFieldsOptional>>({
    google_ranking: 1,
    num_news_sources: 1,
    type: "type",
    location: "location",
    influencer_org: "influencer_org",
    influencer_url: "influencer_url",
    num_instagram: 1,
    num_patents: 1,
    num_publications: 1,
    num_twitter: 1,
    ...collectionFixtureOptionalFields
})

export const InfluencerFixture = factory<Influencer>({
    ...InfluencerRequiredFixture(),
    ...InfluencerOptionalFixture(),
    influencerId: "influencerId",
    createdTs: 1
})

export const InfluencerVMFixture = factory<InfluencerVM>({
    ...InfluencerFixture(),
    ...VMMetaFixture()
})

export const InfluencerRelationsFixture = factory<Required<InfluencerRelations>>({
    ...pickIntersect<InfluencerRelations>()(collectionRelations, [
        "startups",
        "companies",
        "sectors",
        "research_hubs",
        "tech_transfers",
        "investors",
        "patents",
        "research_papers"
    ])
})

export const InfluencerFixtures: RFixtures<"influencers"> = {
    required: InfluencerRequiredFixture(),
    optional: InfluencerOptionalFixture(),
    relation: InfluencerRelationsFixture(),
    all: InfluencerFixture()
}

export const ResearchHubRequiredFixture = factory<ResearchHubFieldsRequired>({
    research_hub_name: "research_hub_name",
    research_hub_url: "research_hub_url",
    ...collectionFixtureRequiredFields
})

export const ResearchHubOptionalFixture = factory<Required<ResearchHubFieldsOptional>>({
    num_citations: 1,
    num_patents: 1,
    num_publications: 1,
    grant_funding: 1,
    active_funding: 1,
    citations_per_publication: 1,
    location: "location",
    university: "university",
    num_experts: 1,
    num_clinical_trials: 1,
    num_patent_families: 1,
    ...collectionFixtureOptionalFields
})

export const ResearchHubFixture = factory<ResearchHub>({
    ...ResearchHubRequiredFixture(),
    ...ResearchHubOptionalFixture(),
    researchHubId: "researchHubId",
    createdTs: 1
})
export const ResearchHubVMFixture = factory<ResearchHubVM>({
    ...ResearchHubFixture(),
    ...VMMetaFixture()
})

export const ResearchHubRelationsFixture = factory<Required<ResearchHubRelations>>({
    ...pickIntersect<ResearchHubRelations>()(collectionRelations, [
        "startups",
        "companies",
        "sectors",
        "tech_transfers",
        "investors",
        "patents",
        "research_papers",
        "tech_experts",
        "influencers"
    ])
})

export const ResearchHubFixtures: RFixtures<"research_hubs"> = {
    required: ResearchHubRequiredFixture(),
    optional: ResearchHubOptionalFixture(),
    relation: ResearchHubRelationsFixture(),
    all: ResearchHubFixture()
}

export const TechExpertRequiredFixture = factory<TechExpertFieldsRequired>({
    tech_expert_name: "tech_expert_name",
    ...collectionFixtureRequiredFields
})

export const TechExpertOptionalFixture = factory<Required<TechExpertFieldsOptional>>({
    num_citations: 1,
    num_patents: 1,
    num_publications: 1,
    grant_funding: 1,
    active_funding: 1,
    citations_per_publication: 1,
    association: "association",
    funding_type: "funding_type",
    sector_option: "sector_option",
    tech_expert_url: "tech_expert_url",
    num_clinical_trials: 1,
    num_patent_families: 1,
    ...collectionFixtureOptionalFields
})

export const TechExpertFixture = factory<TechExpert>({
    ...TechExpertRequiredFixture(),
    ...TechExpertOptionalFixture(),
    techExpertId: "techExpertId",
    createdTs: 1
})
export const TechExpertVMFixture = factory<TechExpertVM>({
    ...TechExpertFixture(),
    ...VMMetaFixture()
})

export const TechExpertRelationsFixture = factory<Required<TechExpertRelations>>({
    ...pickIntersect<TechExpertRelations>()(collectionRelations, [
        "startups",
        "companies",
        "sectors",
        "tech_transfers",
        "investors",
        "patents",
        "research_papers",
        "influencers",
        "research_hubs"
    ])
})

export const TechExpertFixtures: RFixtures<"tech_experts"> = {
    required: TechExpertRequiredFixture(),
    optional: TechExpertOptionalFixture(),
    relation: TechExpertRelationsFixture(),
    all: TechExpertFixture()
}

export const TechTransferRequiredFixture = factory<TechTransferFieldsRequired>({
    tech_of_interest: "tech_of_interest",
    ...collectionFixtureRequiredFields
})

export const TechTransferOptionalFixture = factory<Required<TechTransferFieldsOptional>>({
    location: "location",
    research_hub: "research_hub",
    tech_transfer_url: "tech_transfer_url",
    ...collectionFixtureOptionalFields
})

export const TechTransferFixture = factory<TechTransfer>({
    ...TechTransferRequiredFixture(),
    ...TechTransferOptionalFixture(),
    techTransferId: "techTransferId",
    createdTs: 1
})

export const TechTransferVMFixture = factory<TechTransferVM>({
    ...TechTransferFixture(),
    ...VMMetaFixture()
})

export const TechTransferRelationsFixture = factory<Required<TechTransferRelations>>({
    ...pickIntersect<TechTransferRelations>()(collectionRelations, [
        "startups",
        "companies",
        "sectors",
        "patents",
        "research_papers",
        "influencers",
        "research_hubs",
        "tech_experts"
    ])
})

export const TechTransferFixtures: RFixtures<"tech_transfers"> = {
    required: TechTransferRequiredFixture(),
    optional: TechTransferOptionalFixture(),
    relation: TechTransferRelationsFixture(),
    all: TechTransferFixture()
}

export const PatentRequiredFixture = factory<PatentFieldsRequired>({
    patent_title: "patent_title",
    publication_number: "publication_number",
    ...collectionFixtureRequiredFields
})

export const PatentOptionalFixture = factory<Required<PatentFieldsOptional>>({
    num_citations: 1,
    holder: "holder",
    patent_url: "patent_url",
    patent_url_2: "patent_url_2",
    filing_location: "fillng_location",
    application_date: "application_date",
    current_assignee: "current_assignee",
    estimated_expiration_date: "estimated_expiration_date",
    original_assignee: "original_assignee",
    patent_type: "patent_type",
    publication_date: "publication_date",
    ...collectionFixtureOptionalFields
})

export const PatentFixture = factory<Patent>({
    ...PatentRequiredFixture(),
    ...PatentOptionalFixture(),
    patentId: "patentId",
    createdTs: 1
})

export const PatentVMFixture = factory<PatentVM>({
    ...PatentFixture(),
    ...VMMetaFixture()
})

export const PatentRelationsFixture = factory<Required<PatentRelations>>({
    ...pickIntersect<PatentRelations>()(collectionRelations, [
        "startups",
        "companies",
        "influencers",
        "research_hubs",
        "research_papers",
        "tech_experts",
        "tech_transfers"
    ])
})

export const PatentFixtures: RFixtures<"patents"> = {
    required: PatentRequiredFixture(),
    optional: PatentOptionalFixture(),
    relation: PatentRelationsFixture(),
    all: PatentFixture()
}

export const CrossRadarConnectionFixture = factory<CrossRadarConnections<CName>>(collectionRelations)

export const PatentHolderOptionalFixture = factory<Required<PatentHolderFieldsOptional>>({
    ...collectionFixtureOptionalFields,
    patent_links: ["patent_link"],
    industry: "industry",
    patent_holder_url: "patent_holder_url",
    num_patent_families: 1,
    num_patent_family_citations: 1
})

export const PatentHolderRequiredFixture = factory<PatentHolderFieldsRequired>({
    patent_holder: "patent_holder",
    num_citations: 1,
    num_patents: 1,
    ...collectionFixtureRequiredFields
})

export const PatentHolderFixture = factory<PatentHolder>({
    ...PatentHolderRequiredFixture(),
    ...PatentHolderOptionalFixture(),
    patentHolderId: "patentHolderId",
    createdTs: 1
})

export const PatentHolderVMFixture = factory<PatentHolderVM>({
    ...PatentHolderFixture(),
    ...VMMetaFixture()
})
export const PatentHolderRelationsFixture = factory<Required<PatentHolderRelations>>({
    ...pickIntersect<PatentHolderRelations>()(collectionRelations, ["patents"])
})

export const PatentHolderFixtures: RFixtures<"patent_holders"> = {
    required: PatentHolderRequiredFixture(),
    optional: PatentHolderOptionalFixture(),
    relation: PatentHolderRelationsFixture(),
    all: PatentHolderFixture()
}

export const GrantOptionalFixture = factory<Required<GrantFieldsOptional>>({
    ...collectionFixtureOptionalFields,
    grant_url: "grant_url",
    group_url: "group_url",
    location: "location",
    research_group: "research_group",
    university: "university",
    funding: 0,
    years_funded: 0
})

export const GrantRequiredFixture = factory<GrantFieldsRequired>({
    ...collectionFixtureRequiredFields,
    grant_title: "grant_title"
})

export const GrantFixture = factory<Grant>({
    ...GrantRequiredFixture(),
    ...GrantOptionalFixture(),
    grantId: "grantId",
    createdTs: 1
})

export const GrantVMFixture = factory<GrantVM>({
    ...GrantFixture(),
    ...VMMetaFixture()
})
export const GrantRelationsFixture = factory<Required<GrantRelations>>({})

export const GrantFixtures: RFixtures<"grants"> = {
    required: GrantRequiredFixture(),
    optional: GrantOptionalFixture(),
    relation: GrantRelationsFixture(),
    all: GrantFixture()
}

export const ClinicalTrialOptionalFixture = factory<Required<ClinicalTrialFieldsOptional>>({
    description: "description",
    trial_url: "trial_url",
    location: "location",
    sponsor: "sponsor",
    start_date: "start_date",
    end_date: "end_date",
    affiliations: "affiliations",
    authors: "authors",
    study_phase: "study_phase"
})

export const ClinicalTrialRequiredFixture = factory<ClinicalTrialFieldsRequired>({
    ...collectionFixtureRequiredFields,
    trial_title: "trial_title"
})

export const ClinicalTrialFixture = factory<ClinicalTrial>({
    ...ClinicalTrialRequiredFixture(),
    ...ClinicalTrialOptionalFixture(),
    trialId: "trialId",
    createdTs: 1
})

export const ClinicalTrialVMFixture = factory<ClinicalTrialVM>({
    ...ClinicalTrialFixture(),
    ...VMMetaFixture()
})
export const ClinicalTrialRelationsFixture = factory<Required<ClinicalTrialRelations>>({})

export const ClinicalTrialFixtures: RFixtures<"clinical_trials"> = {
    required: ClinicalTrialRequiredFixture(),
    optional: ClinicalTrialOptionalFixture(),
    relation: ClinicalTrialRelationsFixture(),
    all: ClinicalTrialFixture()
}

export const CompanyRequiredFixture = factory<CompanyFieldsRequired>({
    product_technology_name: "product_technology_name",
    company_name: "company_name",
    product_technology_url: "product_technology_url",

    ...collectionFixtureRequiredFields
})

export const CompanyOptionalFixture = factory<Required<CompanyFieldsOptional>>({
    company_type: "company_type",
    company_url: "company_url",
    efficacy_ranking: 1,
    efficacy_source: "efficacy_source",
    active_ingredient: "active_ingredient",
    efficacy_source_url: "efficacy_source_url",
    moa: "moa",
    other_ingredients: "other_ingredients",
    crunchbase_url: "crunchbase_url",
    safety_ranking: "safety_ranking",
    safety_source_url: "safety_source_url",
    logo_url: "logo_url",
    ...collectionFixtureOptionalFields
})

export const CompanyFixture = factory<Company>({
    ...CompanyRequiredFixture(),
    ...CompanyOptionalFixture(),
    companyId: "companyId",
    createdTs: 1
})
export const CompanyVMFixture = factory<CompanyVM>({
    ...CompanyFixture(),
    ...VMMetaFixture()
})

export const CompanyRelationsFixture = factory<Required<CompanyRelations>>({})

export const CompanyFixtures: RFixtures<"companies"> = {
    required: CompanyRequiredFixture(),
    optional: CompanyOptionalFixture(),
    relation: CompanyRelationsFixture(),
    all: CompanyFixture()
}
