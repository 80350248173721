import * as React from "react"

import { connect } from "react-redux"
import { Button } from "../../styles/buttons"
import {
    validString,
    validateNotMemberOf,
    validateNotEmpty,
    validateAZ,
    isEmpty,
    isErr
} from "../../../functions/src/utils/validators"
import { matchOnValue, values } from "../../../functions/src/utils/map"
import { _Center } from "../../styles/common"
import { FormView } from "../../components/form/FormView"
import { useFormHook } from "../../utils/hooks/useFormHook"
import { _FormViewWrapper } from "../../styles/forms"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { actions as uiActions } from "../../store/ui/actions"
import { actions as cloudActions } from "../../store/cloud/actions"
import { actions as authActions } from "../../store/auth/actions"
import { Msg } from "../../models/notifications"
import { Loaded, LoadingWithPayload, isFetched } from "../../../functions/src/utils/types"
import { Loader } from "../../components/common/Loader"
import { NotificationMsg } from "../../store/store"
import { MapState, MapDispatch } from "../../utils/redux.types"
import { isAdminLocation } from "../../models/LocationType"
import { Popup } from "../../components/Popup"
import { PopupInner } from "../../components/Popup.styles"

export type OwnProps = { onClose: F0; meta: HubPayload }

export type StateProps = Loadable<{
    hub: HubPayload
    hubId: string
    actionName: string
    title: string
    schema: FormSchema<HubPayload>
    results: SMap<CloudActionResult>
}>

export type ActionProps = {
    onUpdate: F3<ActionId, HubId, HubPayload>
    queueNotification: F1<NotificationMsg>
    onFinished: F1<string>
}

export type HubEditProps = StateProps & ActionProps & OwnProps

export const hubSchema: FormSchema<HubPayload> = {
    name: { sectionTitle: "Hub information", name: "Name", type: "text", validators: validString },
    hubSlug: { name: "Slug (unique hub identifier)", type: "text", validators: [validateNotEmpty, validateAZ] }
}
export const EditHub: React.FC<HubEditProps> = p => {
    if (p.loading) return <Loader />
    const [isSubmitting, setIsSubmitting] = React.useState(false)
    const [state, update] = useCloudAction<HubId, HubPayload>(
        (actionId, hubId, hub) => {
            setIsSubmitting(true)
            return p.onUpdate(actionId, hubId, hub)
        },
        p.results,
        ({ result: res }) => {
            const msgType: MutationType = isEmpty(p.hubId) ? "create" : "update"

            p.onFinished(res.value)
            p.queueNotification(Msg(msgType, { name: "Hub" }, res))
        }
    )
    const { formViewProps, onSubmitClick, result } = useFormHook<HubPayload>({
        schema: p.schema,
        onSubmit: v => update(p.hubId, v),
        initialValue: p.hub
    })

    return (
        <Popup logo {...p}>
            <PopupInner>
                {isSubmitting ? (
                    <Loader loadingText="Submitting" />
                ) : (
                    <_FormViewWrapper>
                        <Loader fill show={state.type !== "NotStarted"} />
                        <FormView {...formViewProps} />
                        <_Center>
                            <Button data-cy="createOrganization" disabled={isErr(result)} onClick={onSubmitClick}>
                                {p.actionName}
                            </Button>
                        </_Center>
                    </_FormViewWrapper>
                )}
            </PopupInner>
        </Popup>
    )
}

export const getHubSchema = (
    hubSlug: string,
    locationParamsMap: Async<SMap<LocationParams>>
): FormSchema<HubPayload> => {
    if (!isFetched(locationParamsMap)) return hubSchema

    const hubSlugs = values(locationParamsMap.value)
        .filter(p => p.hubSlug !== hubSlug)
        .map(p => p.hubSlug)

    const validators = [
        ...hubSchema.hubSlug.validators!,
        validateNotMemberOf(hubSlugs, "Slug is already in use, please type different one")
    ]

    return { ...hubSchema, hubSlug: { ...hubSchema.hubSlug, validators } }
}

const mapState: MapState<StateProps, OwnProps> = ({ auth, cloud: { actionsResults } }, op) => {
    if (!isAdminLocation(auth.params)) return LoadingWithPayload({ state: "Wrong location" }) // never
    const hubSlug = op.meta.hubSlug
    if (!hubSlug || !isFetched(auth.hubs)) return LoadingWithPayload({ state: "Hubs" })
    const hub = matchOnValue<ExtHub>("hubSlug")(auth.hubs.value, hubSlug)
    if (!hub) return LoadingWithPayload({ state: "Hub" })

    return Loaded({
        hub,
        schema: getHubSchema(hubSlug, auth.configs),
        hubId: hub.hubId,
        actionName: "Save",
        title: "Edit hub",
        results: actionsResults
    })
}

const mapDispatch: MapDispatch<ActionProps> = d => ({
    onUpdate: (actionId: string, hubId: string, hub: HubPayload) => d(cloudActions.updateHub(actionId, hubId, hub)),
    queueNotification: msg => d(uiActions.queueNotification(msg)),
    onFinished: () => {
        d(authActions.fetchHubs())
        d(uiActions.closePopup("hubEdit"))
    }
})

export const EditHubView = connect(mapState, mapDispatch)(EditHub)
