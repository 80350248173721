import * as React from "react"
import { identity, keys, mapObject } from "../../../functions/src/utils/map"
import { Fetched, FetchError } from "../../../functions/src/utils/types"
import { ActionType, ActionParams, allActions, ActionDispatchFn, Actions } from "../actions"
import { isResolved, isCancelled, Cancellable } from "../../utils/cancellable"
import { AsyncState, CancellableAsyncType, RootState } from "../store"
import { useSelector, useDispatch } from "react-redux"
import { DataResolverName, DataResolveFn, dataResolvers } from "./resolvers"
import { DataSelectorName, DataSelectorState, dataSelectors } from "./selectors"
import { usePrevious } from "../../utils/hooks/usePrevious"
import { isEqual } from "../../utils"
import { actions as asyncActions } from "./actions"
import { Dispatch } from "redux"

/*
 TODO
1. how to update data (optimistic ui)?
2. Move async state to redux & adjust selectors to get only changed data for the object
3. useCloudAction should queue async request updates & refresh the state properly. Optimistic state should be
    "global" -> all actions with the same type should update proper state in the whole app
4. Wrong Error -> "title is missing", but should be "topObjects is missing"
   asyncConnect({
       data: ["topObjects"]
   })((p: { title: string }) => <>{p.title}</>)
5. Retry request if failed with different error than in app (e.g. CORS or 500 from function)
 */

type FetchHandler<OP extends Record<string, unknown>> = {
    resolver: DataResolverName
    getStateKey: (op: OP) => keyof AsyncState
}

type AsyncConnectConfig<
    OP extends Record<string, unknown>,
    A extends ActionType[],
    FH extends Record<string, FetchHandler<OP>>,
    DS extends DataSelectorName[]
> = {
    data?: DS
    actions?: A
    fetchHandlers?: FH
}

/**
 * Connects data, actions & data resolvers to the components.
 * See ./selectors.tsx (Data retrieval from the store) & ./resolvers.tsx (Data fetching).
 *
 * Async connect example usage:
 * (testDataSelected & testDataResolver must be created beforehand + testAction must exist in the system)
 *
 * type OwnProps = { title: string }
 * asyncConnect<OwnProps>()({
 *     data: ["testDataSelected"],
 *     actions: ["testAction"],
 *     fetchHandlers: {
 *         testDataResolverProp: {
 *             resolver: "testDataResolver",
 *             getStateKey: op => "testData"
 *         }
 *     }
 * })(p => {
 *     React.useEffect(() => {
 *         p.testDataResolverProp()
 *     }, [])
 *
 *     return (
 *         <>
 *             <h1>{p.title}</h1>
 *             {p.testDataSelected.map(td => <TestDataDisplay {...td} onClick={() => p.testAction()} />)}
 *         <>
 *     )
 * })
 *
 * @returns Component wrapped with data, actions & resolvers
 */
export const asyncConnect = <OP extends Record<string, unknown>>() => <
    A extends ActionType[],
    FH extends Record<string, FetchHandler<any>>,
    DS extends DataSelectorName[]
>(
    config: AsyncConnectConfig<OP, A, FH, DS>
) => <
    Props extends {
        [K in ArrayItem<DS>]: DataSelectorState<K>
    } &
        {
            [K in ArrayItem<A>]: ActionDispatchFn<K>
        } &
        {
            [K in keyof FH]: DataResolveFn<FH[K]["resolver"]>
        } &
        OP
>(
    Component: React.FunctionComponent<Props>
) => (op: OP) => {
    const dispatch = useDispatch()
    const rootState = useSelector<RootState, RootState>(identity)

    const newState = config.data?.reduce<Record<ArrayItem<DS>, DataSelectorState<ArrayItem<DS>>>>(
        (acc, sname) => ({
            ...acc,
            [sname]: (dataSelectors[sname] as any)(rootState, op as any)
        }),
        {} as any
    )

    const oldState = usePrevious(newState)

    // TODO think about more modular state updates & better (faster) checking states
    const state = mapObject(newState, (k, v) => (oldState ? (isEqual(oldState[k], v) ? oldState[k] : v) : v))

    // TODO Maybe it's possible to add better go to "action"?
    const actions = React.useMemo(
        () =>
            (config.actions || (([] as unknown) as A)).reduce<Record<ArrayItem<A>, ActionDispatchFn<ArrayItem<A>>>>(
                (acc, name) => ({
                    ...acc,
                    [name]: (...args: ActionParams<ArrayItem<A>>) => dispatch((allActions[name] as any)(...args))
                }),
                {} as any
            ),
        [dispatch]
    )
    const fetchHandlers = React.useMemo(
        () =>
            keys(config.fetchHandlers).reduce<Record<keyof FH, DataResolveFn<FH[keyof FH]["resolver"]>>>(
                (acc, fhname) => ({
                    ...acc,
                    [fhname]: (...args) => {
                        const handler = config.fetchHandlers![fhname]
                        const resolveTask = (dataResolvers[handler.resolver] as any)(rootState, args)
                        handleAsyncStateUpdate(dispatch, handler.getStateKey(op), resolveTask)
                    }
                }),
                {} as any
            ),
        [rootState, op, dispatch]
    )
    const props = {
        ...state,
        ...actions,
        ...fetchHandlers,
        ...op
    } as Props

    return <Component {...props} />
}

// Sequential loading experiment
let currentRequest: Promise<unknown> = Promise.resolve()

// TODO Test this
export const handleAsyncStateUpdate = async <K extends keyof AsyncState>(
    dispatch: Dispatch<Actions>,
    key: K,
    resolveTask: Cancellable<CancellableAsyncType<AsyncState[K]>>
) => {
    let resolve: (value?: unknown) => void

    const handleUpdate = async () => {
        dispatch(
            asyncActions.setAsyncFetching({
                key,
                task: resolveTask
            })
        )
        ;(resolveTask.start() as any)
            .then((d: any) => {
                resolve()
                if (isResolved(d))
                    dispatch(
                        asyncActions.updateAsyncState({
                            key,
                            value: Fetched(d.value)
                        } as any)
                    )
            })
            .catch((e: any) => {
                resolve()
                if (!isCancelled(e))
                    dispatch(
                        asyncActions.updateAsyncState({
                            key,
                            value: FetchError(e.message || e)
                        } as any)
                    )
            })
    }
    currentRequest.then(handleUpdate).catch(handleUpdate)
    currentRequest = new Promise(res => {
        resolve = res
    })
}
