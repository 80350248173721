import { createCloudAction } from "../utils/actions"
import {
    RadarChunk,
    PriorityImportPayload,
    RadarCollectionItemPayload,
    ImportChunkActionTypeResult
} from "../models/importing.types"

// Actions
//  Read: -
//  Write: User
// Results
//  Read: User(Author)
//  Write: -
// TODO Move createRadar & maybe some other actions to admin/superadmin
export const userCloudActions = {
    logUserToken: (userId: string) => createCloudAction("@logUserToken", { userId }),

    requestAccess: (p: RadarRequestAccessPayload) => createCloudAction("requestAccess", p),
    updateUserProfile: (payload: Pick<User, "displayName">) => createCloudAction("updateUserProfile", payload),

    updateUser: (payload: RadarUser) => createCloudAction("@updateUser", payload),
    updateNewsfeedTimestamp: (p: OnRadar<{ timestamp: number }>) => createCloudAction("updateNewsfeedTimestamp", p),

    createRadar: (radar: RadarDetailsPayload, hubId: HubId) => createCloudAction("@createRadar", { radar, hubId }),
    updateRadar: (radarId: string, radar: RadarDetailsPayload) => createCloudAction("@updateRadar", { radar, radarId }),
    createHub: (hub: HubPayload) => createCloudAction("@createHub", { hub }),
    updateHub: (hubId: string, hub: HubPayload) => createCloudAction("@updateHub", { hub, hubId })
}

// Actions
//  Read: -
//  Write: RadarUser, RadarAdmin, SuperAdmin
// Results
//  Read: RadarUser, RadarAdmin, SuperAdmin
//  Write: -
export const radarCloudActions = {
    mutateDecorator: (p: DecoratorMutationPayload) => createCloudAction("mutateDecorator", p),
    mutatePipelineStageValue: (p: PipelineValueMutationPayload) => createCloudAction("mutatePipelineStageValue", p),

    mutateTag: (p: TagMutationPayload) => createCloudAction("mutateTag", p),
    assignTag: (p: TagAssignmentPayload) => createCloudAction("assignTag", p),
    createAndAssignTag: (p: TagMutationPayload & OmitStrict<TagAssignmentPayload, "tagId" | "assign">) =>
        createCloudAction("createAndAssignTag", p),

    mutateSegmentTag: (p: SegmentTagMutationPayload) => createCloudAction("mutateSegmentTag", p),
    createBatchSegmentTags: (p: SegmentTagBatchMutationPayload) => createCloudAction("createBatchSegmentTags", p),
    assignSegmentTag: (p: SegmentTagAssignmentPayload) => createCloudAction("assignSegmentTag", p),
    createAndAssignSegmentTag: (
        p: SegmentTagMutationPayload & OmitStrict<SegmentTagAssignmentPayload, "segmentId" | "assign">
    ) => createCloudAction("createAndAssignSegmentTag", p),

    mutateSearchArea: (p: SearchAreaMutationPayload) => createCloudAction("mutateSearchArea", p),
    assignSearchArea: (p: SearchAreaAssignmentPayload) => createCloudAction("assignSearchArea", p),

    createComment: (p: CommentCreatePayload) => createCloudAction("createComment", p),
    editComment: (p: CommentEditPayload) => createCloudAction("editComment", p),
    deleteComment: (p: CommentDeletePayload) => createCloudAction("deleteComment", p),

    addRelation: (p: OnRadar<RelationTuple>) => createCloudAction("addRelation", p),
    removeRelation: (p: OnRadar<RelationTuple>) => createCloudAction("removeRelation", p),

    mutateByRadarConfig: (p: ByRadarConfigPayload) => createCloudAction("mutateByRadarConfig", p),

    uploadPrioritization: (p: any) => createCloudAction("uploadPrioritization", p)
}

// Actions
//  Read: -
//  Write: RadarAdmin, SuperAdmin
// Results
//  Read: RadarAdmin, SuperAdmin
//  Write: -
export const adminCloudActions = {
    importChunk: (chunk: RadarChunk) => createCloudAction("@importChunk", chunk),
    importPriorityChunk: (chunk: PriorityImportPayload) => createCloudAction("@importPriorityChunk", chunk),

    inviteUser: (payload: OnHost<RadarUserRequest>) => createCloudAction("@inviteUser", payload),
    revokeUserInvite: (payload: RadarUserActionPayload) => createCloudAction("@revokeUserInvite", payload),
    resendUserInvite: (payload: RadarUserActionPayload) => createCloudAction("@resendUserInvite", payload),
    acceptAccessRequest: (payload: RadarUserActionPayload) => createCloudAction("@acceptAccessRequest", payload),
    denyAccessRequest: (payload: RadarUserActionPayload) => createCloudAction("@denyAccessRequest", payload),
    portalInvite: (payload: any) => createCloudAction("@portalInvite", payload),

    mutateRadarCollectionItem: <T extends CName>(p: RadarCollectionItemPayload<T>) =>
        createCloudAction("@mutateRadarCollectionItem", p),

    removeCollectionItems: <T extends CName>(p: RemoveCollectionItemsPayload<T>) =>
        createCloudAction("@removeColItems", p)
}

export const publicCloudActions = {
    signInViaEmail: (payload: SignInViaEmailPayload) => createCloudAction("signInViaEmail", payload),
    checkDeliverableEmail: (payload: CheckEmailPayload) => createCloudAction("checkDeliverableEmail", payload)
}

const cloudActions = {
    ...adminCloudActions,
    ...publicCloudActions,
    ...radarCloudActions,
    ...userCloudActions
}

export type ActionsType = typeof cloudActions
export type Action<K extends keyof ActionsType> = ReturnType<ReturnType<ActionsType[K]>>

export type CloudActions = ReturnType<ReturnType<ValueOf<ActionsType>>>

export type UserActionsType = typeof userCloudActions
export type UserAction<K extends keyof UserActionsType> = ReturnType<ReturnType<UserActionsType[K]>>
export type UserActionPayload<K extends keyof UserActionsType> = PayloadOf<UserAction<K>>
export type UserCloudActions = ReturnType<ReturnType<ValueOf<UserActionsType>>>

export type AdminActionsType = typeof adminCloudActions
export type AdminAction<K extends keyof AdminActionsType> = ReturnType<ReturnType<AdminActionsType[K]>>
export type AdminActionPayload<K extends keyof AdminActionsType> = PayloadOf<AdminAction<K>>
export type ImportActionShortened = TypePayloadAction<
    AdminAction<"importChunk">["type"],
    ImportChunkActionTypeResult,
    CloudActionMeta
>
export type AdminCloudActions = ReturnType<ReturnType<ValueOf<AdminActionsType>>>

export type RadarActionsType = typeof radarCloudActions
export type RadarAction<K extends keyof RadarActionsType> = ReturnType<ReturnType<RadarActionsType[K]>>
export type RadarActionPayload<K extends keyof RadarActionsType> = PayloadOf<RadarAction<K>>
export type RadarCloudActions = ReturnType<ReturnType<ValueOf<RadarActionsType>>>

export type PublicActionsType = typeof publicCloudActions
export type PublicAction<K extends keyof PublicActionsType> = ReturnType<ReturnType<PublicActionsType[K]>>
export type PublicActionPayload<K extends keyof PublicActionsType> = PayloadOf<PublicAction<K>>
export type PublicCloudActions = ReturnType<ReturnType<ValueOf<PublicActionsType>>>
