/* eslint-disable max-lines */
import * as React from "react"
import {
    validString,
    validateNotEmpty,
    validateAZ,
    validateNotMemberOf,
    isErr,
    isOk,
    validBoolean,
    Err,
    Ok
} from "../../../functions/src/utils/validators"
import { SubpageLayout } from "../../components/Page"
import { Button } from "../../styles/buttons"
import { _VerticalSpace, _Right, _ScrolledWrapper } from "../../styles/common"
import { actions as cloudActions, RadarMutationPayload } from "../../store/cloud/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { Msg } from "../../models/notifications"
import { Loader } from "../../components/common/Loader"
import { copiesTuple, getCopy } from "../../../functions/src/services/copy"
import { NotificationMsg } from "../../store/store"
import { getDataByDeps } from "../../store/data/dataSelectors"
import { RadarViewFetchMap } from "../../dependencies"
import { isAdminLocation } from "../../models/LocationType"
import { actions as authActions } from "../../store/auth/actions"
import {
    LoadingWithPayload,
    isFetched,
    Loading,
    Loaded,
    isDone,
    isProcessing,
    isNotStarted
} from "../../../functions/src/utils/types"
import { values } from "../../../functions/src/utils/map"
import { MapDispatch } from "../../utils/redux.types"
import { connect, ConnectedComponent } from "react-redux"
import { LoadableView } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import { adminPaths } from "../../paths"
import { Prompt } from "react-router-dom"
import { usePrevious } from "../../utils/hooks/usePrevious"
import { _DataContainer } from "./AdminDashboard.styles"
import { cnames, getCollectionSchema } from "../../../functions/src/models/schemas"
import { useFormHook, FormSchema, StyledFormSchema, StyledFormView } from "@react-formless/core"
import { customRenderMap, CustomFields, getStyledInputsRenderMap } from "../../components/form/FormlessInputs"
import { _RadarEditFormWrapper } from "./RadarEdit.styles"

export const radarTypes: Tuples<RadarRestriction> = [
    ["Demo", "demo"],
    ["Client", "client"]
]
const labelSchema: FormSchema<Label> = {
    name: { name: "Label name", type: "text", validators: validString }
}

// TODO Migrate to active
const radarStatuses: Tuples<boolean> = [
    ["Radar published and available online.", false],
    ["Radar not published and not available online.", true]
]

const relationshipsTabStatuses: Tuples<boolean> = [
    ["Relationships tab is disabled.", false],
    ["Relationships tab is enabled.", true]
]

export const dashboardState: Tuples<boolean> = [
    ["No dashboard included in this Radar.", false],
    ["Dashboard feature included in this Radar.", true]
]

export const starState: Tuples<boolean> = [
    ["Use star ranking.", true],
    ["No star ranking enabled.", false]
]
export const priorityState: Tuples<boolean> = [
    ["Use High, Medium, Low priority ranking.", true],
    ["No priority ranking enabled.", false]
]

export const assignmentState: Tuples<boolean> = [
    ["Use PRM assignments.", true],
    ["No PRM assignments.", false]
]

export const pipelinesSummaryState: Tuples<boolean> = [
    ["Use pipeline summary reports.", true],
    ["No pipeline summary reports.", false]
]
export const contactedLeadState: Tuples<boolean> = [
    ["Use contacted lead.", true],
    ["No contacted lead feature enabled.", false]
]

export const segmentTagsState: Tuples<boolean> = [
    ["Use segment tags.", true],
    ["No segment tags feature enabled.", false]
]

export const cnamesTuples: Tuples<CName> = cnames.map(cname => [getCollectionSchema(cname).displayName, cname])

const validateNotUrl = (v: string, msg?: string) =>
    /^https?:/.test(v) ? Err(msg || "Links are not valid Portal identifiers", v) : Ok(v)

// TODO toValue & fromValue in Formless
export const radarSchema: FormSchema<RadarDetailsPayload> = {
    name: {
        type: "text",
        validators: validString,
        name: "Title (will be displayed in the header)"
    },
    relationshipsEnabled: {
        type: "customOption",
        name: "Relationships tab",
        validators: validBoolean,
        toValue: v => Boolean(v),
        values: relationshipsTabStatuses
    },
    radarSlug: {
        type: "text",
        validators: [validateNotEmpty, validateAZ],
        name: "Radar URL suffix (rad.report/*slug*)"
    },
    /* Formless is too strict about what it allows in the FormSchema, permitting only
       valid HTML attributes, value processing and validation.
       At the same time, it is also too strict in the StyledFormSchema, not allowing us
       to add and use custom fields.
       Its examples also only show how to *add* extra content to a Row, but not
       how to customize existing elements.
       Therefore, we cheat a little. We use `as any` here to silence errors about `hint`
       not being allowed in an InputSchema specification. But it's safely passed onwards,
       and available in BasicInput, where we use it.
    */
    portalOrganizationId: {
        type: "text",
        validators: [validateNotUrl],
        name: "Radicle Portal identifier",
        hint: (
            <span>
                Find this identifier on your Portal organization's user administration page. Copy and paste it here.
                Filling this field allows inviting <em>existing, approved Radar users </em> to your Portal directly from
                the Users page.
            </span>
        )
    } as any,
    restriction: {
        type: "radio",
        values: radarTypes
    },
    isDisabled: {
        type: "customOption",
        name: "Deactivated",
        validators: validBoolean,
        toValue: v => Boolean(v),
        values: radarStatuses
    },
    collections: {
        type: "multiselect",
        name: "Available object types",
        values: cnamesTuples
    },
    withJobClusters: {
        type: "select",
        name: `${getCopy("searchArea")} naming`,
        validators: validBoolean,
        toValue: v => Boolean(v),
        values: copiesTuple
    },
    withDashboard: {
        type: "customOption",
        name: "Dashboard",
        validators: validBoolean,
        toValue: v => Boolean(v),
        values: dashboardState
    },
    withStars: {
        type: "customOption",
        name: "Star ranking",
        validators: validBoolean,
        toValue: v => Boolean(v),
        values: starState
    },
    withAssignments: {
        type: "customOption",
        name: "PRM Assignments",
        validators: validBoolean,
        toValue: v => Boolean(v),
        values: assignmentState
    },
    withPriorityRanks: {
        type: "customOption",
        name: "Priority ranking",
        validators: validBoolean,
        toValue: v => Boolean(v),
        values: priorityState
    },
    withPipelinesSummary: {
        type: "customOption",
        name: "Pipeline Stage summary",
        validators: validBoolean,
        toValue: v => Boolean(v),
        values: pipelinesSummaryState
    },
    withContactedLead: {
        type: "customOption",
        name: "Contacted lead",
        validators: validBoolean,
        toValue: v => Boolean(v),
        values: contactedLeadState
    },
    withSegmentTags: {
        type: "customOption",
        name: "Segment tags",
        validators: validBoolean,
        toValue: v => Boolean(v),
        values: segmentTagsState
    },
    xAxisLabels: {
        name: "",
        type: "collection",
        fields: labelSchema,
        mutate: { addNextLabel: "Add new label", createValue: { name: "" }, removeLabel: "Delete label" }
    },
    yAxisLabels: {
        name: "",
        type: "collection",
        fields: labelSchema,
        mutate: { addNextLabel: "Add new label", createValue: { name: "" }, removeLabel: "Delete label" }
    }
}

// TODO Add constructors
const styledSchema: StyledFormSchema<RadarDetailsPayload, CustomFields<RadarDetailsPayload>> = [
    {
        type: "Custom",
        value: {
            type: "section",
            title: "Radar visibility",
            content: [
                {
                    type: "Row",
                    value: ["isDisabled", "restriction"]
                }
            ]
        }
    },
    {
        type: "Custom",
        value: {
            type: "section",
            title: "Basic info",
            content: ["name", "radarSlug", "portalOrganizationId"]
        }
    },
    {
        type: "Custom",
        value: {
            type: "section",
            title: "Settings",
            content: [
                "withDashboard",
                "withPriorityRanks",
                "withStars",
                "withAssignments",
                "withPipelinesSummary",
                "withContactedLead",
                "withSegmentTags",
                "relationshipsEnabled",
                "withJobClusters",
                "collections"
            ]
        }
    },
    {
        type: "Custom",
        value: {
            type: "section",
            title: "X axis labels",
            content: ["xAxisLabels"]
        }
    },
    { type: "Custom", value: { type: "section", title: "Y axis labels", content: ["yAxisLabels"] } }
]

const styledInputsRenderMap = getStyledInputsRenderMap(radarSchema)

export const EditRadar: React.FC<StateProps & ActionProps> = p => {
    const { formViewProps, handleSubmit, result, resetState, submitted, touched } = useFormHook({
        schema: getRadarSchema(p.takenSlugs),
        initialValue: p.radar,
        onSubmit: radar => update(p.radarId!, { radar, hubId: p.hubId })
    })
    const [state, update] = useCloudAction(p.onUpdate, p.results, ({ result: res }) => {
        p.queueNotification(Msg(p.radarId ? "update" : "create", { name: "Radar" }, res))
        p.onFinished(isOk(res) ? res.value : p.radarId!)
    })

    React.useEffect(() => {
        resetState()
    }, [p.radar.radarSlug, p.hubSlug]) // eslint-disable-line react-hooks/exhaustive-deps

    const prevRadarSlug = usePrevious(p.radar.radarSlug)
    const leaveMessage = "If you leave the page then the data will be lost. Are you sure?"

    return (
        <_DataContainer overflowHidden={true}>
            <SubpageLayout>
                <Prompt
                    when={(prevRadarSlug === p.radar.radarSlug || !p.radarId) && !submitted && touched}
                    message={l => (p.hubSlug && l.search.includes(p.hubSlug) ? true : leaveMessage)}
                />
                <Loader show={isProcessing(state)} size="regular" />
                {(isNotStarted(state) || isDone(state)) && (
                    <_RadarEditFormWrapper>
                        <_Right>
                            <Button data-cy="CreateRadar" disabled={isErr(result)} onClick={handleSubmit}>
                                {p.actionName}
                            </Button>
                        </_Right>
                        <_VerticalSpace base="16px" />
                        <_ScrolledWrapper height={"calc(100vh - 223px)"}>
                            <StyledFormView
                                {...formViewProps}
                                styledSchema={styledSchema}
                                styledInputsRenderMap={styledInputsRenderMap}
                                inputsRenderMap={customRenderMap}
                            />
                        </_ScrolledWrapper>
                    </_RadarEditFormWrapper>
                )}
            </SubpageLayout>
        </_DataContainer>
    )
}

export type StateProps = {
    radar: RadarDetailsPayload
    results: SMap<CloudActionResult>
    radarId?: string
    hubId: string
    hubSlug?: string
    takenSlugs: string[]
    actionName: string
}

export type ActionProps = {
    onUpdate: F3<string, string, RadarMutationPayload>
    queueNotification: F1<NotificationMsg>
    onFinished: F1<string>
}

export const getRadarSchema = (takenSlugs: string[]): FormSchema<RadarDetailsPayload> => {
    const schema = radarSchema
    const validators = [
        ...(schema.radarSlug as any).validators,
        validateNotMemberOf(takenSlugs, "Slug is already in use, please type different one")
    ]

    return { ...schema, radarSlug: { ...schema.radarSlug, validators } as any }
}

const mapState = getDataByDeps<StateProps>()(
    RadarViewFetchMap({}, {}),
    (deps, { cloud, auth: { configs, params } }) => {
        if (!isAdminLocation(params)) return LoadingWithPayload({ state: "Wrong location" }) // never
        if (!isFetched(configs)) return Loading()
        const { radar } = deps
        const {
            radarSlug,
            restriction,
            isDisabled = false,
            collections,
            withStars,
            withPriorityRanks,
            withJobClusters,
            withDashboard,
            withAssignments,
            withPipelinesSummary,
            withContactedLead,
            withSegmentTags
        } = params.locationParams as LocationParams

        const radarParams = {
            ...radar,
            radarSlug,
            restriction,
            isDisabled,
            collections,
            withStars,
            withPriorityRanks,
            withJobClusters,
            withDashboard,
            withAssignments,
            withPipelinesSummary,
            withContactedLead,
            withSegmentTags
        }
        return Loaded({
            radar: radarParams,
            results: cloud.actionsResults,
            takenSlugs: values(configs.value)
                .filter(p => p.radarSlug !== radarSlug)
                .map(p => p.radarSlug),
            hubId: params.locationParams.hubId!, // TODO Check this
            radarId: radar.radarId,
            actionName: "Save"
        })
    }
)

const mapDispatch: MapDispatch<ActionProps> = d => ({
    onUpdate: (actionId, radarId, data) => d(cloudActions.updateRadar(actionId, { ...data, radarId })),
    queueNotification: msg => d(uiActions.queueNotification(msg)),
    onFinished: radarSlug => {
        d(authActions.navigate({ ...adminPaths["admin/radarDashboard"], slugs: { radarSlug } }))
        d(authActions.fetchConfigs())
    }
})
const LoadableEditRadar = LoadableView(ViewLoader, EditRadar)

export const EditRadarView: ConnectedComponent<typeof LoadableEditRadar, unknown> = connect(
    mapState,
    mapDispatch
)(LoadableEditRadar)
