import JSZip from "jszip"
import { browserDownloadByUrl } from "./urlService"
import {
    ExportIdsRequest,
    ExportAllRequest,
    ExportFile,
    ExportFormatExt,
    ExportFormat
} from "../../functions/src/services/httpEndpoint/export"
import { getFirebase } from "./firebase"
import { actions } from "../../functions/src/services/httpEndpoint/actions"
import { isErr } from "../../functions/src/utils/validators"
import { assertNever, str2ab } from "../../functions/src/utils"
import { toXLSX, toCSV } from "../../functions/src/services/httpEndpoint/export/exportUtils"
import { Reports } from "../../functions/src/services/httpEndpoint/reports"
import { prepareReportToExport } from "../../functions/src/models/reports"
import { Filters } from "../../functions/src/models/filtering"

export const getCSVBlob = (content: string) => new Blob([content], { type: "text/csv" })
export const getXLSXBlob = (content: any) => new Blob([content], { type: "application/octet-stream" })

export const downloadBlob = (blob: Blob, name: string) => browserDownloadByUrl(window.URL.createObjectURL(blob), name)

const downloadFile = async (format: ExportFormatExt, data: ExportFile[]) => {
    switch (format) {
        case "zip":
            const zip = new JSZip()
            data.forEach(r => zip.file(`${r.cname}.csv`, r.file))
            const blob = await zip.generateAsync({ type: "blob" })
            return downloadBlob(blob, "collections.zip")
        case "csv":
            return data.forEach(r => {
                downloadBlob(getCSVBlob(r.file), `${r.cname}.csv`)
            })
        case "xlsx":
            return data.forEach(r => {
                downloadBlob(getXLSXBlob(str2ab(r.file)), `${r.cname}.xlsx`)
            })
    }
    assertNever(format)
}

export const exportCollection = async (
    format: ExportFormatExt,
    radarId: string,
    payload: ExportIdsRequest | ExportAllRequest
) => {
    const res = await getFirebase().functions.callFunctions(actions.export({ radarId, ...payload, format }))
    if (isErr(res)) {
        // eslint-disable-next-line no-console
        console.error("Something went wrong during export", res.value)
        return
    }
    await downloadFile(format, res.value.data)
}

export const exportReport = (
    format: ExportFormat,
    report: Loadable<Reports>,
    searchAreas: SMap<SearchArea>,
    pipelines: SMap<PipelineStageValue>,
    filters: Filters
) => {
    if (report.loading) {
        // eslint-disable-next-line no-console
        console.log("Cannot generate report")
        return
    }
    const { schema, data } = prepareReportToExport(report, searchAreas, pipelines, filters)
    return getExportedFile({ format, schema, data, filename: "reports" })
}

export const exportContactedLeads = async (
    format: ExportFormatExt,
    radarId: string,
    payload: ExportIdsRequest | ExportAllRequest
) => {
    const res = await getFirebase().functions.callFunctions(
        actions.exportContactedLeads({ radarId, ...payload, format })
    )
    if (isErr(res)) {
        // eslint-disable-next-line no-console
        console.error("Something went wrong during export", res.value)
        return
    }
    await downloadFile(format, res.value.data)
}

export const getExportedFile = <T extends SMap<string | number>>({
    format,
    schema,
    data,
    filename = "export"
}: {
    format: ExportFormat
    schema: Required<T>
    data: T[]
    filename: string
}) => {
    switch (format) {
        case "csv":
            return downloadBlob(getCSVBlob(toCSV(schema, data)), `${filename}.csv`)
        case "xlsx":
            return downloadBlob(getXLSXBlob(toXLSX(schema, data)), `${filename}.xlsx`)
    }
    assertNever(format)
}
