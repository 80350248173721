import * as React from "react"
import { connect } from "react-redux"
import {
    detailsPaths,
    getListPathName,
    editPaths,
    getCollectionEditPathName,
    getCollectionDetailsPathName
} from "../../paths"
import { mapObject, toMap, keys } from "../../../functions/src/utils/map"
import { actions as authActions } from "../../store/auth/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { actions as cfActions } from "../../store/cloud/actions"
import { actions as dataActions } from "../../store/data/actions"
import { List } from "./List"
import { collections, getIdField, getNameField, getCollectionSchema } from "../../../functions/src/models/schemas"
import { Loaded, isFetching } from "../../../functions/src/utils/types"
import { ValueCellMeta } from "../../components/table/TableViewCell"
import { isRoot } from "../../models/LoginStatus"
import { getCurrentRadar, getCurrentRadarId, getUserRadars, isDemoRadarLocation } from "../../models/LocationType"
import { LoadableView } from "../../utils/reactUtils"
import { ViewLoader } from "../../containers/ViewRenderer"
import { getDataByPath } from "../../store/data/dataSelectors"
import { getDependenciesForPath } from "../../dependencies"
import { PageCursor } from "../../store/data/dataUtils"
import { Dispatch } from "../../store/middlewares/authMiddleware.types"
import {
    ViewModelsBase,
    ViewModelsMap,
    RadarCollectionsVMs,
    SectorVM,
    DependencySchema
} from "../../../functions/src/models/ViewModels"
import { UIState, NotificationMsg, Popup } from "../../store/store"
import { AuthenticationState, RadarLocationState } from "../../models/auth"
import { SortingParams, SortingKey } from "../../../functions/src/models/sorting"
import { NavigationParams } from "../../utils/router.types"
import { MapDispatch } from "../../utils/redux.types"

export type ListDependencies<C extends CName> = Pick<
    ViewModelsBase,
    | C
    | "decorators"
    | "pipelines"
    | "comments"
    | "radar"
    | "tags"
    | "tagsAssignments"
    | "segmentTags"
    | "segmentTagsAssignments"
    | "searchAreas"
    | "searchAreasAssignments"
    | "users"
>
export type ListParams<C extends CName> = {
    cname: C
    ui: UIState
    deps: ListDependencies<C>
    current?: LocationParams
    isRoot: boolean
    loadingMore?: boolean
    config: LocationParams
    radarConfig: ByRadarConfig
    withDecorators?: boolean
    withComments?: boolean
}

type StateProps<C extends CName> = ListParams<C> & {
    title: string
    loginStatus: AuthenticationState
    itemsCount: number
    actionResults: SMap<CloudActionResult>
    onRowClick: F3<string, Dispatch, React.MouseEvent>
    onLoadMore: F1<Dispatch>
    onDeleteItems: F1<Dispatch, F2<string, string[]>>
}

type ActionProps<C extends CName> = {
    dispatch: Dispatch
    onCellContentClick: F1<ValueCellMeta<string>>
    onItemEdit: F2<C, string>
    onSort: F2<SortingKey<C>, boolean>
    queueNotification: F1<NotificationMsg>
    openPopup: F2<Type<Popup>, Value<Popup>>
}

export type ListProps<C extends CName> = StateProps<C> & ActionProps<C>

const getListProps = <C extends CName>(title: string, cname: C, key: keyof ViewModelsMap[C]) =>
    getDataByPath<StateProps<C>>()(getListPathName(cname), (deps, state, _, models) => {
        const radarId = getCurrentRadarId(state.auth)!
        const config = (state.auth.params as RadarLocationState).locationParams
        const { radar } = deps
        const col = deps[cname] as RadarCollectionsVMs[C]
        const colModel = models[cname]

        const radars = getUserRadars(state.auth)
        const current = getCurrentRadar(state.auth)
        const isDemo = isDemoRadarLocation(state.auth.params)

        return Loaded({
            actionResults: state.cloud.actionsResults,
            title,
            cname,
            current: current
                ? isDemo
                    ? (current as LocationParams)
                    : radars.find(r => r.radarId === current.radarId)
                : undefined,
            config,
            radarConfig: deps.config,
            loginStatus: state.auth.authentication,
            loadingMore: isFetching(colModel),
            itemsCount: (colModel as any).itemsCount || 0,
            onRowClick: (cid: string, d: Dispatch, event: React.MouseEvent) => {
                if ((window.getSelection() ?? "").toString() !== "") return
                const isRestricted =
                    cname === "sectors" && radar.demo && !((deps as any).sectors[cid] as SectorVM).sector_completed
                // Accel is formally deprecated, but may still work. It's true if the per-platform key (cmd or meta for mac, ctrl for others) is pressed.
                // Without having rules for `navigator.platform` it's impossible to tell which one should be actually used, so we test for both
                const newTab =
                    event.getModifierState("Accel") ||
                    event.getModifierState("Control") ||
                    event.getModifierState("Meta")
                const action = isRestricted
                    ? uiActions.openPopup("showContact")
                    : authActions.navigate({
                          path: detailsPaths[getCollectionDetailsPathName(cname)].path,
                          slugs: { [key]: cid },
                          preserveSearchParams: true,
                          newTab: newTab
                      })
                d(action)
            },
            onLoadMore: (d: Dispatch) => {
                const cursor = state.ui.cursor
                if (isFetching(colModel) || (colModel as any).fetchLevel === "all") return

                const schema = getDependenciesForPath(getListPathName(cname)) as DependencySchema
                const newCursor = PageCursor(
                    cursor.type === "page" ? (cursor.value.startingAfter || 0) + cursor.value.limit : 0
                )
                d(
                    dataActions._fetchMerge(
                        radarId,
                        current?.radarName || null,
                        current?.hubName || null,
                        schema,
                        newCursor
                    )
                )
            },
            onDeleteItems: (d: Dispatch) => (actionId: string, ids: string[]) => {
                const items = ids.map(id => col[id] as RCollection<typeof cname>).filter(Boolean)
                const namesById = toMap(
                    items,
                    item => item[getIdField(cname)] as any,
                    item => item[getNameField(cname)] as any
                )
                const searchAreasIds = Array.from(new Set(ids.flatMap(id => keys(deps.searchAreasAssignments[id]))))

                const searchAreas = searchAreasIds.map(id => deps.searchAreas[id]?.name).filter(Boolean)
                d(dataActions._removeData(radarId, cname, ids))
                return d(cfActions.removeRadarCollectionItems(actionId, { cname, namesById, searchAreas, radarId }))
            },
            deps: deps as any,
            ui: state.ui,
            isRoot: isRoot(state.auth.authentication)
        })
    })

const getListActions = <C extends CName>(collectionName: C): MapDispatch<ActionProps<C>> => d => ({
    dispatch: d,
    onSort: (key, asc) => {
        const params: SortingParams<C>[] = [
            {
                sortingAsc: asc,
                sortingKey: key
            }
        ]
        d(uiActions.updateSortingKey(collectionName, params))
    },
    onItemEdit: (cname, cid) => {
        const navParams: NavigationParams = {
            path: editPaths[getCollectionEditPathName(cname)].path,
            slugs: { [getCollectionSchema(cname).idField]: cid },
            preserveSearchParams: true
        }
        d(authActions.navigate(navParams))
    },
    onCellContentClick: meta => {
        if (!meta.actions) return
        switch (meta.actions.type) {
            case "star":
                return d(
                    cfActions.mutateDecoratorDebounced({
                        type: "star",
                        objectId: meta.rowId as string,
                        newValue: meta.actions.value
                    })
                )
            case "dropdown":
                if (meta.actions.value.type === "pipelineStage")
                    return d(uiActions.openPopup("mandatoryComment", { type: "pipeline", value: meta.actions.value }))
                return d(cfActions.mutateDecoratorDebounced(meta.actions.value))
        }
    },
    queueNotification: msg => d(uiActions.queueNotification(msg)),
    openPopup: (type, value) => d(uiActions.openPopup(type, value))
})

export const ListViews: { [C in CName]: React.FC } = mapObject(collections, (_, v) => {
    return connect(
        getListProps(v.displayName, v.collectionName, v.idField as any),
        getListActions(v.collectionName)
    )(LoadableView(ViewLoader, List as any))
})
