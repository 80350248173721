import { validateSegmentTagCreateMutation } from "../actions/radar/radarValidators"
import { getCopy } from "../services/copy"
import { keys, remap, identity, toArray, values } from "../utils/map"
import { toOption, MutationState, toStringOption } from "../utils/types"
import { runValidatorsRaw, validString, isErr, Err, Ok } from "../utils/validators"
import { mapAreasByName } from "./searchAreas"

export type ExtSegmentTag = ExtTag<SegmentTag> & { kind: SearchAreaKind }
const prefixes: TMap<SearchAreaKind, string> = {
    business: "JTBD: ",
    techPlatform: "End Use: ",
    no_decorator: ""
}

export const segmentTagSectionDisplayMap: Record<SearchAreaKind, string> = {
    business: "Job to be done",
    techPlatform: "End use",
    no_decorator: "Not assigned"
}

export const JTBD_PREFIX = prefixes.business

export const displayTag = (t: Tag) => t.name

export const tagToOption = (useNameAsId: boolean) => (tag: Tag): ROption =>
    toOption(displayTag(tag), useNameAsId ? displayTag(tag) : tag.tagId)

export const getTagFromString = (name: string): OmitStrict<Tag, "tagId"> => {
    return {
        name
    }
}

export const getTagMutationPayloadFromName = (name: string): ValueState<MutationTypeCreate, Pick<Tag, "name">> =>
    MutationState("create", { name })

export const HASH_TAG_NAMESPACE = "a2f4b0a9-ea38-4dfb-a713-b25205d74cf6"
export const mapTagsIdsByName = (tags: SMap<Tag>): SMap<string> =>
    remap(
        tags,
        (_, v) => v.name,
        (_, key) => key
    )

export const tagsAssignmentsByTagName = (tags: SMap<Tag>, tagsAssignments: TMap<ObjectId, Assignments>) =>
    remap(tagsAssignments, identity, tagMap =>
        remap(tagMap, tid => (tags[tid] ? displayTag(tags[tid]) : tid), identity)
    )

export const getTagNamesFromAssignments = (tags: SMap<Tag>, assignments: Assignments<Tag["tagId"]>) =>
    toArray(assignments, (v, tid) => (v && tags[tid] ? displayTag(tags[tid]) : null)).filter<string>(
        (v): v is string => !!v
    )

export const getTagNamesByObjectIdMap = (
    tags: SMap<Tag>,
    tagsAssignments: TMap<ObjectId, Assignments>
): SMap<string[]> => remap(tagsAssignments, identity, tagMap => getTagNamesFromAssignments(tags, tagMap))

export const assignmentsByTagId = (assignments: SMap<Assignments<TagId>>): SMap<Assignments<ObjectId>> => {
    const result: SMap<Assignments<ObjectId>> = {}
    keys(assignments).forEach(objId => {
        keys(assignments[objId]).forEach(tagId => {
            if (!result[tagId]) result[tagId] = {}
            result[tagId][objId] = assignments[objId][tagId]
        })
    })
    return result
}

export const byCompareName = <T extends { name: string }>(t1: T, t2: T) => t1.name.localeCompare(t2.name)

export type ExtTag<T = Tag> = T & { useCnt: number }
export const TagWithUsage = <T = Tag>(tag: T, assignmentsMap: SMap<boolean> = {}): ExtTag<T> => ({
    ...tag,
    useCnt: values(assignmentsMap).filter(Boolean).length
})

// ============ SEGMENT TAGS ============

export const parseSegmentTag = (name: string) => {
    const withoutPrefixes = values(prefixes).reduce((acc, p) => (p ? acc.replace(p, "") : acc), name)
    const [rawSA, ...rest] = withoutPrefixes.split(":")
    return {
        searchArea: rawSA.trim(),
        name: rest.join(":").trim()
    }
}

export type GetSegmentTagMutationError =
    | State<"noSA", { searchArea: string }>
    | State<"noObjectSA", { searchArea: string }>

export const getSegmentTagMutationPayloadFromName = (p: {
    name: string
    searchAreas: TMap<AreaId, SearchArea>
    objectSearchAreas: string[]
}): Result<ValueState<MutationTypeCreate, Pick<SegmentTag, "name" | "areaId">>, string, GetSegmentTagMutationError> => {
    const areasByName = mapAreasByName(p.searchAreas)
    const segmentTag = parseSegmentTag(p.name)
    const areaId = areasByName[segmentTag.searchArea]?.areaId
    if (!areaId)
        return Err(`Wrong ${getCopy("searchArea")} - ${segmentTag.searchArea}`, {
            type: "noSA",
            searchArea: segmentTag.searchArea
        })
    if (!p.objectSearchAreas.includes(areaId))
        return Err(`Object does not have ${getCopy("searchArea")} ${segmentTag.searchArea}`, {
            type: "noObjectSA",
            searchArea: segmentTag.searchArea
        })
    return Ok(MutationState("create", { name: segmentTag.name, areaId }))
}

export const getAssignableSegmentTag = (
    areaObjectAssignments: Assignments<AreaId, SearchAreaAssignmentKind>,
    segmentTags: SMap<SegmentTag>
): SegmentTag[] =>
    values(segmentTags)
        .filter(tag => tag.areaId in areaObjectAssignments)
        .sort(byCompareName)

export const displaySegmentTag = (searchAreas: TMap<AreaId, SearchArea>) => (tag: SegmentTag): string => {
    const area = searchAreas[tag.areaId]
    if (!area) return tag.name
    return `${prefixes[area.typeId]}${area.name}: ${tag.name}`
}

export const getAreaKindFromTag = (searchAreas: TMap<AreaId, SearchArea>) => (tag: SegmentTag): SearchAreaKind =>
    searchAreas[tag.areaId]?.typeId || "no_decorator"

export const segmentTagToOptionRelations = (c: { searchAreas: TMap<AreaId, SearchArea>; useNameAsId: boolean }) => (
    segmentTag: SegmentTag
): ROption =>
    toOption(displaySegmentTag(c.searchAreas)(segmentTag), c.useNameAsId ? segmentTag.name : segmentTag.segmentId)

export const segmentTagToOption = (searchAreas: TMap<AreaId, SearchArea>) => (tag: SegmentTag): ROption =>
    toStringOption(displaySegmentTag(searchAreas)(tag))

export const validateSegmentTagDuplicate = (c: { segmentTags: SMap<SegmentTag> }) => (
    tag: SegmentTagCreatePayload & Partial<Pick<SegmentTag, "segmentId">>
): Result<SegmentTagCreatePayload & Partial<Pick<SegmentTag, "segmentId">>, string> => {
    const vstr = validateSegmentTagCreateMutation(tag)
    if (isErr(vstr)) return Err("Wrong segment tag payload")

    const std = values(c.segmentTags).find(st => st.areaId === vstr.value.areaId && st.name === vstr.value.name)
    if (std && (!tag.segmentId || std.segmentId !== tag.segmentId))
        return Err(`Segment tag with this name and ${getCopy("searchArea")} already exists`)

    return vstr
}

export const validateSegmentTagName: Validator<string> = v => {
    const stringValidation = runValidatorsRaw(validString, v)
    if (isErr(stringValidation)) return stringValidation

    const prefix = values(prefixes).find(p => stringValidation.value.startsWith(p))
    if (prefix) return Err(`Tag cannot contain "${prefix}" prefix. It will be added automatically`)

    return stringValidation
}

export const segmentTagsAssignmentsBySegmentName = (
    segmentTags: SMap<SegmentTag>,
    segmentTagsAssignments: TMap<ObjectId, Assignments<SegmentTag["segmentId"]>>,
    searchAreas: TMap<AreaId, SearchArea>
) =>
    remap(segmentTagsAssignments, identity, tagMap =>
        remap(tagMap, stid => (segmentTags[stid] ? displaySegmentTag(searchAreas)(segmentTags[stid]) : stid), identity)
    )

// TODO Test
export const mapSegmentTagsByName = (
    segmentTags: SMap<SegmentTag>,
    searchAreas: TMap<AreaId, SearchArea>
): SMap<SegmentTag> =>
    remap(
        segmentTags,
        (_, v) => displaySegmentTag(searchAreas)(v),
        (v, _) => v
    )

export const getSegmentTagNamesFromAssignments = (
    segmentTags: SMap<SegmentTag>,
    assignments: Assignments<SegmentTag["segmentId"]>,
    searchAreas: TMap<AreaId, SearchArea>
) =>
    toArray(assignments, (v, tid) =>
        v && segmentTags[tid] ? displaySegmentTag(searchAreas)(segmentTags[tid]) : null
    ).filter<string>((v): v is string => !!v)
