import * as React from "react"
import { _h1 } from "../../../styles/typography"
import { _VerticalSpace, _Spacer, Flex } from "../../../styles/common"
import { Popup } from "../../Popup"
import { PopupInner } from "../../Popup.styles"
import { Button, ErrorButton, SecondaryButton } from "../../../styles/buttons"
import { useFormHook } from "../../../utils/hooks/useFormHook"
import { validString } from "../../../../functions/src/utils/validators"
import { pickObject } from "../../../../functions/src/utils/map"
import { FormView } from "../../form/FormView"

export type LinkPopupMeta = {
    text: string
    url: string
    onEdit: F1<Pick<LinkPopupMeta, "url" | "text">>
    onAdd: F1<Pick<LinkPopupMeta, "url" | "text">>
    onRemove: F0
}
type OwnProps = { onClose: F0; meta: LinkPopupMeta }
type LinkPopupMode = "edit" | "preview"

export const linkSchema: FormSchema<Pick<LinkPopupMeta, "url" | "text">> = {
    text: {
        type: "text",
        name: "Text",
        placeholder: "Link text",
        validators: validString
    },
    url: {
        type: "text",
        name: "Link URL",
        placeholder: "Link URL",
        validators: validString
    }
}

const EditLink = (p: LinkPopupMeta & { onClose: F0 }) => {
    const [formRef, setFormRef] = React.useState<HTMLElement | null>(null)
    const { formViewProps, onSubmitClick } = useFormHook({
        schema: linkSchema,
        initialValue: pickObject(p, ["url", "text"]),
        onSubmit: v => {
            if (p.url) p.onEdit(v)
            else p.onAdd(v)
            p.onClose()
        }
    })

    React.useEffect(() => {
        if (p.text) formRef?.querySelector<HTMLInputElement>("#Link_URL")?.focus()
        else formRef?.querySelector<HTMLInputElement>("#Text")?.focus()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formRef])

    return (
        <>
            <_h1>{p.url ? "Edit link" : "Add link"}</_h1>
            <_VerticalSpace base="16px" />
            <div ref={setFormRef}>
                <FormView {...formViewProps} />
            </div>
            <_VerticalSpace base="25px" />
            <Flex>
                <Button onClick={onSubmitClick}>{p.url ? "Save" : "Add link"}</Button>
                <_Spacer />
                <SecondaryButton onClick={p.onClose}>Cancel</SecondaryButton>
            </Flex>
        </>
    )
}

const PreviewLink = (p: LinkPopupMeta & { onEdit: F0; onRemove: F0; onClose: F0 }) => (
    <>
        <_h1>{p.text}</_h1>
        <_VerticalSpace base="16px" />
        <div>
            <a href={p.url} target="_blank" rel="noreferrer noopener" style={{ wordBreak: "break-word" }}>
                {p.url}
            </a>
        </div>
        <_VerticalSpace base="25px" />
        <Flex>
            <ErrorButton
                onClick={() => {
                    p.onRemove()
                    p.onClose()
                }}>
                Remove
            </ErrorButton>
            <_Spacer />
            <SecondaryButton onClick={p.onEdit}>Edit</SecondaryButton>
        </Flex>
    </>
)

export const LinkPopup: React.FC<OwnProps> = p => {
    const [mode, setMode] = React.useState<LinkPopupMode>(!p.meta.url ? "edit" : "preview")

    return (
        <Popup logo onClose={p.onClose}>
            <PopupInner column>
                {mode === "edit" ? (
                    <EditLink {...p.meta} onClose={p.onClose} />
                ) : (
                    <PreviewLink {...p.meta} onEdit={() => setMode("edit")} onClose={p.onClose} />
                )}
            </PopupInner>
        </Popup>
    )
}
