import { Paths } from "./paths"
import {
    DecoratingDataTypes,
    DependencySchema,
    SubscriptionDataTypes,
    DependencyDataTypes
} from "../functions/src/models/ViewModels"

export const DecoratingDataFetchMap = <T extends DecoratingDataTypes>(m: DependencySchema<T>): DependencySchema<T> => m

export const getDecoratingFetchMap = (fl: FetchLevel): DependencySchema<DecoratingDataTypes> => ({
    comments: fl,
    decorators: fl,
    relations: fl,

    searchAreasAssignments: fl,
    tagsAssignments: fl,
    segmentTagsAssignments: fl
})

const SubscriptionFetchMap: DependencySchema<SubscriptionDataTypes> = {
    pipelines: "all",
    radar: "all",
    searchAreas: "all",
    secureKeys: "all",
    subscribers: "all",
    tags: "all",
    segmentTags: "all",
    users: "all"
}

export const RadarViewFetchMap = <T extends DependencyDataTypes, D extends DecoratingDataTypes>(
    m: DependencySchema<T>,
    d: DependencySchema<D>
): DependencySchema<T | D | SubscriptionDataTypes> => ({
    ...SubscriptionFetchMap,
    ...d,
    ...m
})

const dependencySchema = {
    "admin/dashboard": {},
    "admin/importRadar": RadarViewFetchMap({}, getDecoratingFetchMap("all")),
    "admin/importPriorities": RadarViewFetchMap({}, { decorators: "all" }),
    "admin/radarDashboard": RadarViewFetchMap({ config: "all" }, getDecoratingFetchMap("all")),
    "admin/newRadar": {},

    "dashboard/startups": RadarViewFetchMap({ startups: "single", config: "all" }, getDecoratingFetchMap("single")),
    "dashboard/influencers": RadarViewFetchMap(
        { influencers: "single", config: "all" },
        getDecoratingFetchMap("single")
    ),
    "dashboard/investors": RadarViewFetchMap({ investors: "single", config: "all" }, getDecoratingFetchMap("single")),
    "dashboard/patents": RadarViewFetchMap({ patents: "single", config: "all" }, getDecoratingFetchMap("single")),
    "dashboard/patent_holders": RadarViewFetchMap(
        { patent_holders: "single", config: "all" },
        getDecoratingFetchMap("single")
    ),
    "dashboard/research_hubs": RadarViewFetchMap(
        { research_hubs: "single", config: "all" },
        getDecoratingFetchMap("single")
    ),
    "dashboard/research_papers": RadarViewFetchMap(
        { research_papers: "single", config: "all" },
        getDecoratingFetchMap("single")
    ),
    "dashboard/tech_experts": RadarViewFetchMap(
        { tech_experts: "single", config: "all" },
        getDecoratingFetchMap("single")
    ),
    "dashboard/tech_transfers": RadarViewFetchMap(
        { tech_transfers: "single", config: "all" },
        getDecoratingFetchMap("single")
    ),
    "dashboard/grants": RadarViewFetchMap({ grants: "single", config: "all" }, getDecoratingFetchMap("single")),
    "dashboard/clinical_trials": RadarViewFetchMap(
        { clinical_trials: "single", config: "all" },
        getDecoratingFetchMap("single")
    ),
    "dashboard/companies": RadarViewFetchMap({ companies: "single", config: "all" }, getDecoratingFetchMap("single")),
    "dashboard/sectors": RadarViewFetchMap({ sectors: "single", config: "all" }, getDecoratingFetchMap("all")),

    "demo/demo": {},
    "demo/demoDrops": {},
    "demo/demoForms": {},

    "details/startups": RadarViewFetchMap({ startups: "single", config: "all" }, getDecoratingFetchMap("single")),
    "details/influencers": RadarViewFetchMap({ influencers: "single", config: "all" }, getDecoratingFetchMap("single")),
    "details/investors": RadarViewFetchMap({ investors: "single", config: "all" }, getDecoratingFetchMap("single")),
    "details/patents": RadarViewFetchMap({ patents: "single", config: "all" }, getDecoratingFetchMap("single")),
    "details/patent_holders": RadarViewFetchMap(
        { patent_holders: "single", config: "all" },
        getDecoratingFetchMap("single")
    ),
    "details/research_hubs": RadarViewFetchMap(
        { research_hubs: "single", config: "all" },
        getDecoratingFetchMap("single")
    ),
    "details/research_papers": RadarViewFetchMap(
        { research_papers: "single", config: "all" },
        getDecoratingFetchMap("single")
    ),
    "details/sectors": RadarViewFetchMap({ sectors: "single", config: "all" }, getDecoratingFetchMap("single")),
    "details/sectorsMap": RadarViewFetchMap({ sectors: "single", config: "all" }, getDecoratingFetchMap("single")),
    "details/tech_experts": RadarViewFetchMap(
        { tech_experts: "single", config: "all" },
        getDecoratingFetchMap("single")
    ),
    "details/tech_transfers": RadarViewFetchMap(
        { tech_transfers: "single", config: "all" },
        getDecoratingFetchMap("single")
    ),
    "details/grants": RadarViewFetchMap({ grants: "single", config: "all" }, getDecoratingFetchMap("single")),
    "details/clinical_trials": RadarViewFetchMap(
        { clinical_trials: "single", config: "all" },
        getDecoratingFetchMap("single")
    ),
    "details/companies": RadarViewFetchMap({ companies: "single", config: "all" }, getDecoratingFetchMap("single")),

    "list/startups": RadarViewFetchMap({ startups: "page", config: "all" }, getDecoratingFetchMap("page")),
    "list/influencers": RadarViewFetchMap({ influencers: "page", config: "all" }, getDecoratingFetchMap("page")),
    "list/investors": RadarViewFetchMap({ investors: "page", config: "all" }, getDecoratingFetchMap("page")),
    "list/patents": RadarViewFetchMap({ patents: "page", config: "all" }, getDecoratingFetchMap("page")),
    "list/research_hubs": RadarViewFetchMap({ research_hubs: "page", config: "all" }, getDecoratingFetchMap("page")),
    "list/research_papers": RadarViewFetchMap(
        { research_papers: "page", config: "all" },
        getDecoratingFetchMap("page")
    ),
    "list/sectors": RadarViewFetchMap({ sectors: "page", config: "all" }, getDecoratingFetchMap("page")),
    "list/tech_experts": RadarViewFetchMap({ tech_experts: "page", config: "all" }, getDecoratingFetchMap("page")),
    "list/tech_transfers": RadarViewFetchMap({ tech_transfers: "page", config: "all" }, getDecoratingFetchMap("page")),
    "list/patent_holders": RadarViewFetchMap({ patent_holders: "page", config: "all" }, getDecoratingFetchMap("page")),
    "list/grants": RadarViewFetchMap({ grants: "page", config: "all" }, getDecoratingFetchMap("page")),
    "list/clinical_trials": RadarViewFetchMap(
        { clinical_trials: "page", config: "all" },
        getDecoratingFetchMap("page")
    ),
    "list/companies": RadarViewFetchMap({ companies: "page", config: "all" }, getDecoratingFetchMap("page")),

    "radar/dashboard": RadarViewFetchMap({ config: "all" }, getDecoratingFetchMap("all")),
    "radar/radar": RadarViewFetchMap({ sectors: "all" }, getDecoratingFetchMap("all")),
    "radar/newsfeed": RadarViewFetchMap({ news: "page", config: "all" }, getDecoratingFetchMap("all")),
    "radar/reports": RadarViewFetchMap({}, {}),

    "edit/sectors": RadarViewFetchMap({ sectors: "single" }, getDecoratingFetchMap("single")),
    "edit/startups": RadarViewFetchMap({ startups: "single" }, getDecoratingFetchMap("single")),
    "edit/investors": RadarViewFetchMap({ investors: "single" }, getDecoratingFetchMap("single")),
    "edit/patent_holders": RadarViewFetchMap({ patent_holders: "single" }, getDecoratingFetchMap("single")),
    "edit/patents": RadarViewFetchMap({ patents: "single" }, getDecoratingFetchMap("single")),
    "edit/tech_transfers": RadarViewFetchMap({ tech_transfers: "single" }, getDecoratingFetchMap("single")),
    "edit/tech_experts": RadarViewFetchMap({ tech_experts: "single" }, getDecoratingFetchMap("single")),
    "edit/research_hubs": RadarViewFetchMap({ research_hubs: "single" }, getDecoratingFetchMap("single")),
    "edit/research_papers": RadarViewFetchMap({ research_papers: "single" }, getDecoratingFetchMap("single")),
    "edit/influencers": RadarViewFetchMap({ influencers: "single" }, getDecoratingFetchMap("single")),
    "edit/grants": RadarViewFetchMap({ grants: "single" }, getDecoratingFetchMap("single")),
    "edit/clinical_trials": RadarViewFetchMap({ clinical_trials: "single" }, getDecoratingFetchMap("single")),
    "edit/companies": RadarViewFetchMap({ companies: "single" }, getDecoratingFetchMap("single")),

    "user/adminLogin": {},
    "user/login": {},
    "user/federated": {},
    "user/logout": {},
    "user/linkLogin": {},

    "static/howToImportData": {}
}

export const getDependenciesForPath = (path: keyof Paths) => dependencySchema[path]

export type DependencyMap = typeof dependencySchema
