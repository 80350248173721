import { CloudActionsValidationMap } from "../validators"
import { AdminCloudActions, AdminActionPayload } from "../actionCreators"
import {
    validate,
    validString,
    validateMemberOf,
    validNumber,
    validateString,
    validMapDef,
    validArrayString,
    validateMap,
    isErr,
    Err,
    Ok,
    validArrayStringDef
} from "../../utils/validators"
import { keys } from "../../utils/map"
import { collections } from "../../models/schemas"
import { validateImportableCollections } from "../../models/Radar"
import { radarUserRequestValidators } from "../../models/User"
import { RadarChunk } from "../../models/importing.types"

const requestRelatedValidationMap = {
    radarId: validString,
    userId: validString,
    senderId: validString,
    hostname: validString
}

const validateAcceptAccessRequest = validate<AdminActionPayload<"acceptAccessRequest">>(requestRelatedValidationMap)
const validateDenyAccessRequest = validate<AdminActionPayload<"denyAccessRequest">>(requestRelatedValidationMap)
const validateRevokeUserInvite = validate<AdminActionPayload<"revokeUserInvite">>(requestRelatedValidationMap)
const validateResendUserInvite = validate<AdminActionPayload<"resendUserInvite">>(requestRelatedValidationMap)

const validateMutateRadarCollectionItem = validate<AdminActionPayload<"mutateRadarCollectionItem">>({
    radarId: validString,
    cname: [validateMemberOf(keys(collections))],
    type: [validateMemberOf<MutationType>(["create", "update"])],
    item: null
})

const validateImportChunkMeta = validate<AdminActionPayload<"importChunk">>({
    chunkNo: validNumber,
    radarId: validString,
    type: [validateMemberOf<MutationType>(["create", "update"])],
    data: []
})

const validatePriorityImportChunk = validate<AdminActionPayload<"importPriorityChunk">>({
    radarId: validString,
    priorities: [] // TODO
})

export const validateImportChunkResult = validate<AdminActionPayload<"importChunk"> & { searchAreas?: string[] }>({
    chunkNo: validNumber,
    radarId: validString,
    type: [validateMemberOf<MutationType>(["create", "update"])],
    data: [validateMap(validArrayStringDef)],
    searchAreas: validArrayStringDef
})

export const validateImportChunk: Validator<AdminActionPayload<"importChunk">> = v => {
    const metaResult = validateImportChunkMeta(v)
    if (isErr(metaResult)) return metaResult
    const chunk: RadarChunk = v
    const dataValidationResult = validateImportableCollections(chunk.type === "update")(chunk.data)
    return isErr(dataValidationResult) ? (Err({ data: dataValidationResult.value }) as Result<RadarChunk>) : Ok(v)
}

const validateInvitation = validate<AdminActionPayload<"inviteUser">>({
    ...radarUserRequestValidators,
    hostname: validString
})

const validateRemoveColItems = validate<AdminActionPayload<"removeCollectionItems">>({
    cname: [validateString],
    namesById: validMapDef(validString),
    searchAreas: validArrayString,
    radarId: [validateString]
})

export const adminActionsPayloadValidators: CloudActionsValidationMap<AdminCloudActions> = {
    "@importChunk": [validateImportChunk],
    "@importPriorityChunk": [validatePriorityImportChunk],

    "@inviteUser": [validateInvitation],
    "@acceptAccessRequest": [validateAcceptAccessRequest],
    "@denyAccessRequest": [validateDenyAccessRequest],
    "@revokeUserInvite": [validateRevokeUserInvite],
    "@resendUserInvite": [validateResendUserInvite],
    "@portalInvite": [],

    "@mutateRadarCollectionItem": [validateMutateRadarCollectionItem],
    "@removeColItems": [validateRemoveColItems]
}
