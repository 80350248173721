export const selectCreateDiv = (id: string) => {
    let d: any = document.getElementById(id)
    if (d) return d
    d = document.createElement("div")
    document.body.appendChild(d)
    d.setAttribute("id", id)
    return d
}

export const getOnScreenPosition = (ref: HTMLElement | null): Bounds => {
    if (!ref) return { left: 0, top: 0, bottom: 0, right: 0 }
    return ref.getBoundingClientRect()
}

export const scrollToY = (y: number, anchor?: HTMLElement | null, scrollDuration = 1000) => {
    const base = anchor || window
    const getPos = () => {
        if (anchor) return anchor.scrollTop
        return window.scrollY
    }
    const from = getPos()
    const to = y
    const distance = to - from
    let timestamp = 0
    let progress = 0
    function ease(p: number) {
        return p < 0.5 ? 2 * p * p : 1 + 16 * --p * p * p * p * p
    }

    function step(newTimestamp: number) {
        if (!timestamp) timestamp = newTimestamp
        progress = (newTimestamp - timestamp) / scrollDuration
        if (progress > 1 || getPos() === to) return
        base.scrollTo(0, Math.round(from + distance * ease(progress)))
        window.requestAnimationFrame(step)
    }

    window.requestAnimationFrame(step)
}

export const cbNoPropagation = (cb: F1<React.SyntheticEvent>) => (e: React.SyntheticEvent) => {
    e.stopPropagation()
    cb(e)
}

// Cannot use { preventScroll: true } - Safari bug...
export const focusWithoutScrolling = (ref: HTMLElement) => {
    const docScrollingElement = document.scrollingElement || document.body
    const scrollTop = docScrollingElement.scrollTop
    const scrollLeft = docScrollingElement.scrollLeft
    ref.focus()
    setTimeout(() => window.scrollTo(scrollTop, scrollLeft))
}
