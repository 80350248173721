import * as React from "react"
import { _CardSectionContainer, _CardSectionTitle } from "./CardSection.styles"
import { FieldsList, FieldsListItem } from "./FieldsList"
import { _InfoCardSectionContainer } from "./InfoCardSection.styles"

export type InfoCardSectionProps = {
    title: string
    description?: React.ReactNode
    recommended?: React.ReactNode
    values: FieldsListItem[]
}

export const InfoCardSection = (p: InfoCardSectionProps) => {
    return (
        <_InfoCardSectionContainer>
            <_CardSectionContainer>
                <_CardSectionTitle>{p.title}</_CardSectionTitle>
                {p.recommended ? <div>{p.recommended}</div> : null}
                {p.description ? <div>{p.description}</div> : null}
                <div>
                    <FieldsList items={p.values} />
                </div>
            </_CardSectionContainer>
        </_InfoCardSectionContainer>
    )
}
