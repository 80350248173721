import { InlineIconListItem } from "./IconList"
import { getRowSchema } from "../table/TableViewController"
import { collections, getCollectionSchema } from "../../../functions/src/models/schemas"
import { getDisplayNames } from "../../../functions/src/models/collections"
import { keys, values, filterObject } from "../../../functions/src/utils/map"
import { isOk, isErr } from "../../../functions/src/utils/validators"
import { specialValues } from "../../../functions/src/models/converters"
import { ItemClassifiersFixture } from "../../../functions/src/models/collectionFixtures"
import { PreloadedCollection } from "../../../functions/src/models/importing.types"
import { compareSingleCollectionField } from "../../../functions/src/models/sorting"
import { isOverwrite, isCollectionItemInvalid } from "../../../functions/src/models/itemValidationUtils"

export const isOkPI = <C extends CName>(pi: CollectionItem<C>) =>
    !isOverwrite(pi) && isOk(pi.fields) && isOk(pi.relations) && isOk(pi.classifiers)
export const getOkPIs = <C extends CName>(pc: PreloadedCollection<C>): CollectionItem<C>[] => pc.value.filter(isOkPI)

export const isErrPI = <C extends CName>(pi: CollectionItem<C>) => isCollectionItemInvalid(pi, "import")
export const getErrPIs = <C extends CName>(pc: PreloadedCollection<C>): CollectionItem<C>[] =>
    pc.value.filter(v => isErrPI(v))

export const isOverwritePI = <C extends CName>(pi: CollectionItem<C>) =>
    isOverwrite(pi) && !isCollectionItemInvalid(pi, "import")
export const getOverwritePIs = <C extends CName>(pc: PreloadedCollection<C>): CollectionItem<C>[] =>
    pc.value.filter(v => isOverwritePI(v))

export type PreloadStats = {
    valid: InlineIconListItem
    overwrite: InlineIconListItem
    invalid: InlineIconListItem
}
export const getPreloadStatistics = <T extends CName>(pc: PreloadedCollection<T>): PreloadStats => ({
    valid: { title: `${getOkPIs(pc).length} Correct`, icon: { name: "selected", width: 15, height: 15 } },
    overwrite: {
        title: `${getOverwritePIs(pc).length} To overwrite`,
        icon: { name: "duplicated", width: 15, height: 15 }
    },
    invalid: { title: `${getErrPIs(pc).length} Invalid`, icon: { name: "wrong", width: 15, height: 15 } }
})

export const getCollectionRowSchema = <T extends CName>(
    cname: T,
    config: LocationParams,
    availableCollections: CName[]
) => {
    const cschema = getCollectionSchema(cname)
    const cfixture = {
        ...cschema.fixture.all,
        ...ItemClassifiersFixture(),
        ...cschema.fixture.relation
    }
    const filteredCFixture = filterObject(cfixture as any, k => {
        if (k === "segment_tags") return !!config.withSegmentTags
        if (k === "createdTs") return false
        if (k in collections)
            return availableCollections.includes(k as CName) && config.collections.includes(k as CName)
        return true
    })
    return getRowSchema(filteredCFixture, getDisplayNames(cname)({ mode: "list" }) as any, keys(filteredCFixture))
}

export const sortPreloadedCollection = <C extends CName>(key: keyof CollectionItem<C>["fields"], asc: boolean) => {
    const lastValuesStr = keys(specialValues)
    const lastValuesNr = values(specialValues)
    const comparator = compareSingleCollectionField(lastValuesStr, lastValuesNr, asc)

    return (a: CollectionItemVM<C>, b: CollectionItemVM<C>): number => {
        const [left, right] = [a, b].map(v => {
            return (isErr(v.fields) ? v.fields.obj : v.fields.value)[key]
        })
        return comparator(left, right)
    }
}
