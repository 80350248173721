import * as React from "react"
import { useLocation } from "react-router"
import { isNotLoggedIn } from "../models/LoginStatus"
import { getIntercomService } from "../services/intercom"
import { asyncConnect } from "../store/async/asyncConnect"
import { DEMO_EMAIL_GIVEN_LS_KEY } from "../store/auth/authCmds"

export const IntercomHandler = asyncConnect()({
    data: ["user", "authentication"]
})(p => {
    const location = useLocation()
    const intercomService = React.useMemo(() => getIntercomService(), [])

    React.useEffect(() => {
        const a = p.authentication
        const demoEmail = localStorage.getItem(DEMO_EMAIL_GIVEN_LS_KEY)
        if (isNotLoggedIn(a) && a.emailGiven && demoEmail) intercomService.boot({ name: demoEmail, email: demoEmail })
    }, [intercomService, p.authentication])

    React.useEffect(() => {
        intercomService.boot(p.user ? { name: p.user.displayName!, email: p.user.email } : undefined)
    }, [intercomService, p.user])

    React.useEffect(() => {
        intercomService.update()
    }, [intercomService, location])

    return null
})
