import { CmdType, loop, Loop, Cmd, ListCmd } from "redux-loop"
import { Action, AnyAction } from "redux"
import { extend, arrify, mapObject, pickObject } from "../../functions/src/utils/map"
import { isFunction, isArray } from "../../functions/src/utils/validators"
import { ContextReducer } from "../store/combineContextReducers"
import { MapDispatch } from "./redux.types"
import { RootState } from "../store/store"
import { _anything } from "../../functions/src/utils"

export type LoopReducer<S, A extends Action> = (state: S, action: AnyAction, ...args: any[]) => S | Loop<S, A>

export type Ext<A extends TypedAction<any> | TypePayloadAction<any, any> = any, TState = RootState> = (
    delta: Partial<TState>,
    cmd?: CmdType<A>
) => Loop<TState, any> | TState

export const loopExtend = <A extends TypedAction<A> | TypePayloadAction<A, any>, TState>(
    state: TState
): Ext<A, TState> => (delta, c) =>
    c ? (loop(extend(state)(delta), c) as Loop<TState, any>) : (extend(state)(delta) as TState)
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isCmd = (a: any): a is CmdType<any> =>
    (a.type && a.type === "RUN") || a.type === "LIST" || a.type === "ACTION" || a.type === "MAP"

export type CmdArg = CmdType<any> | TypedAction | null | undefined
export const cmdList = (conditions: (F0<CmdArg> | CmdArg)[] | F0<CmdArg> | CmdArg, sequence = false): ListCmd<any> =>
    Cmd.list(
        arrify(conditions)
            .map(f => (isFunction(f) ? f() : f))
            .reduce((acc, cmd) => {
                if (!cmd) return acc
                if (isCmd(cmd)) return [...acc, cmd]
                return [...acc, Cmd.action(cmd)]
            }, []),
        { sequence }
    )

export const cmdListExt = <T>(
    state: T,
    conditions: F1<T, CmdType<any> | TypedAction | null | undefined>[]
): ListCmd<any> => cmdList(conditions.map(f => () => f(state)))

export type ReducerDelta<TState, TActions extends TypedAction, TContext> = (
    state: TState,
    action: TActions,
    context: TContext
) => Partial<TState> | CmdType<TypedAction<any>> | [Partial<TState>, CmdType<TypedAction<any>>] | undefined

export const getReducer: <TState, TActions extends TypedAction, TContext>(
    reducerBase: ReducerDelta<TState, TActions, TContext>
) => ContextReducer<TContext, TState, TActions> = getDelta => (state, action, context) => {
    const delta = getDelta(state, action, context)
    if (!delta) return state
    const ext = loopExtend(state)
    if (isArray(delta)) return ext(delta[0], delta[1])
    if (isCmd(delta)) return ext({}, delta)
    return ext(delta)
}

// eslint-disable-next-line @typescript-eslint/ban-types
type Actions = SMap<Function>
type ActionsProps<T extends Actions> = { [P in keyof T]: FMapped<T[P], void> }

export const getMapDispatch = <T extends Actions, K extends keyof T>(
    actions: T,
    keys: K[]
): MapDispatch<ActionsProps<Pick<T, K>>> => dispatch =>
    mapObject(pickObject(actions, keys), (_, value) => (...args: any) => dispatch(value(...args)))

export const getMapDispatch2 = <T extends Actions, K extends keyof T, T2 extends Actions, K2 extends keyof T2>(
    actions: T,
    keys: K[],
    actions2: T2,
    keys2: K2[]
) => getMapDispatch({ ...actions, ...actions2 }, [...keys, ...keys2])

export const getMapDispatch3 = <
    T extends Actions,
    K extends keyof T,
    T2 extends Actions,
    K2 extends keyof T2,
    T3 extends Actions,
    K3 extends keyof T3
>(
    actions: T,
    keys: K[],
    actions2: T2,
    keys2: K2[],
    actions3: T3,
    keys3: K3[]
) => getMapDispatch({ ...actions, ...actions2, ...actions3 }, [...keys, ...keys2, ...keys3])

export const impossibleStateBreaker = <T>(context: string, msg: string, retValue: T = _anything) => {
    // eslint-disable-next-line no-console
    console.error("Impossible state error in ", context, msg)
    return retValue
}
