// TODO Fix this
/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react"
import { _PreviewListPanel, _ListPanel } from "./SelectFiles.styles"
import { keys } from "../../../functions/src/utils/map"
import { Err, isEmpty, isOk, Ok } from "../../../functions/src/utils/validators"
import { IconList, IconListItemProps } from "./IconList"
import { assertNever } from "../../../functions/src/utils"
import { LoadableView } from "../../utils/reactUtils"
import { EditCollectionItemModal } from "../edit/EditCollectionItem"
import { PreloadedCollectionList } from "./PreloadedCollectionList"
import { Loader } from "../common/Loader"
import { connect } from "react-redux"
import { Loaded } from "../../../functions/src/utils/types"
import { getMapDispatch3 } from "../../utils/redux"
import { actions } from "../../store/importing/actions"
import { actions as cloudActions } from "../../store/cloud/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { ViewLoader } from "../../containers/ViewRenderer"
import {
    GetSegmentTagMutationError,
    getSegmentTagMutationPayloadFromName,
    getTagMutationPayloadFromName
} from "../../../functions/src/models/tags"
import { useCloudAction } from "../../utils/hooks/useCloudAction"
import { getDataByPath } from "../../store/data/dataSelectors"
import { getPCVMfromPCSVM } from "../../../functions/src/models/relations/relations"
import { PreloadedCollections, PreloadedCollectionsVM } from "../../../functions/src/models/importing.types"
import { ViewModelsBase } from "../../../functions/src/models/ViewModels"
import { getCollectionSchema } from "../../../functions/src/models/schemas"
import { Msg } from "../../models/notifications"
import { getCopy } from "../../../functions/src/services/copy"
import { isRoot } from "../../models/LoginStatus"

const mapGetSegmentTagMutationError = (err: GetSegmentTagMutationError) => {
    switch (err.type) {
        case "noObjectSA":
            return `Could not add segment tag - object is not assigned to ${getCopy("searchArea")} (${
                err.searchArea
            }), which is assigned to segment tag`
        case "noSA":
            return `Could not add segment tag - segment tag ${getCopy("searchArea")} (${err.searchArea}) does not exist`
    }
    assertNever(err)
}

export const PreloadedCollectionsListPanel: React.FC<{
    onSelect: F1<keyof PreloadedCollections>
    collections: PreloadedCollections
}> = p => (
    <_ListPanel>
        <IconList
            title="Preloaded file entries"
            items={keys(p.collections)
                .filter(Boolean)
                .map<IconListItemProps>(k => {
                    const col = p.collections[k]!
                    return {
                        header: getCollectionSchema(k).displayName,
                        subHeader: col.filename,
                        description: col.isValid ? "All entries correct" : "Incorrect entries found",
                        type: col.isValid ? "positive" : "negative",
                        icon: col.isValid
                            ? {
                                  name: "tick",
                                  width: 30,
                                  height: 30
                              }
                            : { name: "cross", width: 30, height: 30 },
                        onSelect: () => p.onSelect(k)
                    }
                })}
        />
    </_ListPanel>
)

export type PreloadedCollectionListProps = {
    pcs: PreloadedCollectionsVM
}

type StateProps = {
    radarId: string
    results: SMap<CloudActionResult>
    vms: Pick<
        ViewModelsBase,
        | "radar"
        | "tags"
        | "tagsAssignments"
        | "searchAreas"
        | "searchAreasAssignments"
        | "segmentTags"
        | "segmentTagsAssignments"
    >
    config: LocationParams
    isRoot: boolean
}
type ActionProps = ReturnType<typeof mapDispatch>
const PreloadedCollectionsList: React.FC<StateProps & ActionProps & PreloadedCollectionListProps> = p => {
    if (isEmpty(p.pcs)) return <Loader fill />
    const [addItem, setAddItem] = React.useState(false)
    const [selectedCName, setSelectedCName] = React.useState<keyof PreloadedCollections>(keys(p.pcs).sort()[0])
    const [, onAddTag] = useCloudAction(p.mutateTag, p.results, p.revalidateCollections)
    const [, onAddSegmentTag] = useCloudAction(p.mutateSegmentTag, p.results, p.revalidateCollections)
    const pcvm = getPCVMfromPCSVM(p.pcs)(selectedCName)

    return (
        <>
            <EditCollectionItemModal
                vms={p.vms}
                mode="import"
                isRoot={p.isRoot}
                queueNotification={p.queueNotification}
                isOpen={addItem}
                config={p.config}
                onClose={() => setAddItem(false)}
                cname={selectedCName}
                onSave={i => p.addItem(selectedCName, i)}
            />
            <PreloadedCollectionsListPanel onSelect={setSelectedCName} collections={p.pcs} />
            <_PreviewListPanel>
                <PreloadedCollectionList
                    vms={p.vms}
                    cname={selectedCName}
                    config={p.config}
                    isRoot={p.isRoot}
                    pc={pcvm}
                    pcs={p.pcs}
                    onAddItem={() => setAddItem(true)}
                    onAddTag={tagName => onAddTag({ ...getTagMutationPayloadFromName(tagName), radarId: p.radarId })}
                    onRemoveTag={p.removeTagFromItems}
                    onAddSegmentTag={(segmentTagName, item) => {
                        const classifiers = item?.classifiers
                        const objectPrimarySearchAreas =
                            (isOk(classifiers)
                                ? classifiers.value.primary_search_areas
                                : classifiers.obj.primary_search_areas) || []
                        const objectSecondarySearchAreas =
                            (isOk(classifiers)
                                ? classifiers.value.secondary_search_areas
                                : classifiers.obj.secondary_search_areas) || []
                        const payload = getSegmentTagMutationPayloadFromName({
                            name: segmentTagName,
                            searchAreas: p.vms.searchAreas,
                            objectSearchAreas: [...objectPrimarySearchAreas, ...objectSecondarySearchAreas]
                        })
                        if (isOk(payload)) {
                            onAddSegmentTag({
                                ...payload.value,
                                radarId: p.radarId
                            })
                            return Ok("")
                        }
                        p.queueNotification(
                            Msg("custom", { error: mapGetSegmentTagMutationError(payload.obj) }, payload)
                        )
                        return Err("Wrong payload")
                    }}
                    onRemoveSegmentTag={p.removeSegmentTagFromItems}
                    onDeleteSelected={indices => p.removeItems(selectedCName, indices)}
                    onItemSaved={(i, v) => p.updateItem(selectedCName, i, v)}
                    queueNotification={p.queueNotification}
                />
            </_PreviewListPanel>
        </>
    )
}

const mapState = getDataByPath<StateProps, PreloadedCollectionListProps>()("admin/importRadar", (deps, s) =>
    Loaded({
        vms: deps,
        config: (s.auth.configs as AsyncFetched<TMap<string, LocationParams>>).value[deps.radar.radarId],
        radarId: deps.radar.radarId,
        results: s.cloud.actionsResults,
        isRoot: isRoot(s.auth.authentication)
    })
)

const mapDispatch = getMapDispatch3(
    actions,
    [
        "updateItem",
        "addItem",
        "removeItems",
        "revalidateCollections",
        "removeTagFromItems",
        "startImport",
        "removeSegmentTagFromItems"
    ],
    cloudActions,
    ["mutateTag", "mutateSegmentTag"],
    uiActions,
    ["queueNotification"]
)

export const PreloadedCollectionsListView = connect<Loadable<StateProps>, ActionProps, PreloadedCollectionListProps>(
    mapState,
    mapDispatch
)(LoadableView(ViewLoader, PreloadedCollectionsList))
