import * as React from "react"
import { _h1 } from "../../styles/typography"
import { _VerticalSpace } from "../../styles/common"
import { Popup } from "../Popup"
import { PopupHeader, PopupContent, PopupInner } from "../Popup.styles"
import { EditProfile } from "../../views/user/EditProfile"

type OwnProps = { onClose: F0 }

export const EditProfilePopup: React.FC<OwnProps> = p => {
    return (
        <Popup logo onClose={p.onClose}>
            <PopupInner column>
                <PopupHeader>
                    <_h1>Edit user</_h1>
                </PopupHeader>
                <PopupContent>
                    <_VerticalSpace base="8px" />
                    <EditProfile />
                </PopupContent>
            </PopupInner>
        </Popup>
    )
}
