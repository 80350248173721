import { Icon } from "../../functions/src/models/icons"
import { _noop } from "../../functions/src/utils"
import { Nothing, Just } from "../../functions/src/utils/types"
import { ThemeColors } from "./styled"

// --------

type ButtonSize = "unset" | "stretch"
type Color = keyof ThemeColors
type Content =
    | State<"Text&Icon", { text: string; icon: Icon }>
    | State<"Icon", { icon: Icon }>
    | State<"Text", { text: string }>
    | State<"ReactNode", { value: React.ReactNode }>
type Dimensions = { width: number; height: number }
type BorderStyle = "borderless" | "primary"
type TextDecoration = "underline" | "bold" | "italic"

export type Button<T = unknown> = {
    text: string
    size: Maybe<ButtonSize>
    onClick: F0
    btnColor: Maybe<Color>
    content: Maybe<Content>
    iconSize: Maybe<Dimensions>
    fontSize: Maybe<number>
    disabled: boolean
    fontColor: Maybe<Color>
    hoverColor: Maybe<Color>
    border: Maybe<BorderStyle>
    textDecoration: Maybe<TextDecoration>
} & T

const Button = <T>(p: Partial<Button<T>>): Button<T> => {
    const b: Button = {
        text: "text",
        size: Nothing(),
        onClick: _noop,
        fontColor: Nothing(),
        btnColor: Nothing(),
        iconSize: Nothing(),
        fontSize: Nothing(),
        disabled: false,
        content: Nothing(),
        hoverColor: Nothing(),
        border: Nothing(),
        textDecoration: Nothing(),
        ...p
    }
    return b as Button<T>
}

type ButtonOptionType =
    | "canAddBackground"
    | "canAddSize"
    | "canAddContent"
    | "canAddIconSize"
    | "canAddFontSize"
    | "canAddHoverColor"
    | "canAddBorder"
    | "canAddTextDecoration"
type ButtonOptions = TMap<ButtonOptionType, never>
export const mkButton = (onClick: F0): Button<ButtonOptions> => Button({ onClick }) as Button<ButtonOptions>

export const withBackgroundColor = (color: Color) => <T>(
    config: Button<T & { canAddBackground: never }>
): Button<Omit<T, "canAddBackground">> => ({ ...config, btnColor: Just(color) })

export const withSize = (size: ButtonSize) => <T>(
    config: Button<T & { canAddSize: never }>
): Button<Omit<T, "canAddSize">> => ({
    ...config,
    size: Just(size)
})

export const withIconSize = (size: Dimensions) => <T>(
    config: Button<T & { canAddIconSize: never }>
): Button<Omit<T, "canAddIconSize">> => ({
    ...config,
    iconSize: Just(size)
})

export const withFontSize = (size: number) => <T>(
    config: Button<T & { canAddFontSize: never }>
): Button<Omit<T, "canAddFontSize">> => ({
    ...config,
    fontSize: Just(size)
})
export const withColor = (color: Color) => <T>(
    config: Button<T & { canAddColor: never }>
): Button<Omit<T, "canAddColor">> => ({
    ...config,
    fontColor: Just(color)
})

export const withContent = (content: Content) => <T>(
    config: Button<T & { canAddContent: never }>
): Button<Omit<T, "canAddContent">> => ({
    ...config,
    content: Just(content)
})

export const withHover = (color: Color) => <T>(
    config: Button<T & { canAddHoverColor: never }>
): Button<Omit<T, "canAddHoverColor">> => ({
    ...config,
    hoverColor: Just(color)
})

export const withBorder = (border: BorderStyle) => <T>(
    config: Button<T & { canAddBorder: never }>
): Button<Omit<T, "canAddBorder">> => ({
    ...config,
    border: Just(border)
})

export const withTextDecoration = (textDecoration: TextDecoration) => <T>(
    config: Button<T & { canAddTextDecoration: never }>
): Button<Omit<T, "canAddTextDecoration">> => ({
    ...config,
    textDecoration: Just(textDecoration)
})
