import * as React from "react"
import { RouteChildrenProps } from "react-router"
import { mapObject, keys } from "../../../functions/src/utils/map"
import { connect } from "react-redux"
import { Card, CardActionProps, CardLoader, CardStateProps } from "./Card"
import { collections, getCollectionSchema } from "../../../functions/src/models/schemas"
import { Loaded, LoadingWithPayload } from "../../../functions/src/utils/types"
import {
    getCollectionDetailsPathName,
    getCollectionEditPathName,
    editPaths,
    collectionPaths,
    getListPathName
} from "../../paths"
import { actions as authActions } from "../../store/auth/actions"
import { actions as uiActions } from "../../store/ui/actions"
import { actions as cfActions } from "../../store/cloud/actions"
import { LoadableView } from "../../utils/reactUtils"
import { getContactedLeadsCount } from "../../../functions/src/models/comments"
import { getAssignedUserName } from "../../../functions/src/models/decoratorValues"
import { getDataByPath } from "../../store/data/dataSelectors"
import { RadarLocationState } from "../../models/auth"
import { MapDispatch } from "../../utils/redux.types"
import { omitContactedLeads } from "../../../functions/src/models/contactedLeads"
import { Popup } from "../../store/store"
import { isEmpty } from "../../../functions/src/utils/validators"

type OwnProps<T> = RouteChildrenProps<{ [key in keyof T]: string }>

const getCardProps = <C extends CName, T extends RCollection<C>>(cname: C, idKey: keyof T) =>
    getDataByPath<CardStateProps, OwnProps<T>>()(
        getCollectionDetailsPathName(cname),
        (deps, { auth, cloud }, { match }) => {
            const { radar, comments, decorators, users, searchAreas, searchAreasAssignments } = deps
            const objectId = (match ? match.params[idKey] : "") as string
            if (!deps[cname]![objectId]) return LoadingWithPayload({ type: "NotFound" })

            const assignedUserId = decorators[objectId]?.assignment?.userId || ""
            const assignedUser = getAssignedUserName(users, assignedUserId)
            const { nameField } = getCollectionSchema(cname)
            const displayName = (deps[cname][objectId] as any)[nameField] || ""
            const areas = keys(searchAreasAssignments[objectId])
                .map(areaId => searchAreas[areaId]?.name)
                .filter(a => !isEmpty(a))

            return Loaded({
                type: "Ok",
                cname,
                objectId,
                displayName,
                loading: false,
                commentsLength: omitContactedLeads(comments[objectId] || []).length,
                attachmentsLength: keys(decorators[objectId]?.attachment || {}).length,
                contactedLeadsLength: getContactedLeadsCount(comments[objectId]),
                config: (auth.params as RadarLocationState).locationParams,
                radarConfig: deps.config,
                authentication: auth.authentication,
                demo: radar.demo,
                radarId: radar.radarId,
                relationshipsEnabled: radar.relationshipsEnabled,
                isStarred: Boolean(decorators[objectId]?.star?.value),
                assignedUser,
                actionsResults: cloud.actionsResults,
                searchAreas: areas
            })
        }
    )

const mapDispatch: MapDispatch<CardActionProps> = d => ({
    onStarClick: (objectId, newValue) => d(cfActions.mutateDecoratorDebounced({ type: "star", objectId, newValue })),
    onEditItem: (cname, id) =>
        d(
            authActions.navigate({
                path: editPaths[getCollectionEditPathName(cname)].path,
                slugs: { [getCollectionSchema(cname).idField]: id },
                preserveSearchParams: false
            })
        ),
    goToListing: cname => d(authActions.navigate({ path: collectionPaths[getListPathName(cname)].path })),
    queueNotification: msg => d(uiActions.queueNotification(msg)),
    openPopup: (key: Type<Popup>, value: Value<Popup>) => d(uiActions.openPopup(key, value)),
    closePopup: (key: Type<Popup>) => d(uiActions.closePopup(key)),
    onDeleteItem: (actionId, p) => d(cfActions.removeRadarCollectionItems(actionId, p))
})

export const CardViews: { [C in CName]: React.FC } = mapObject(
    collections,
    (k, v) => connect(getCardProps(k, v.idField), mapDispatch)(LoadableView(CardLoader, Card)) as any
)
