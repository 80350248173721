import { connect } from "react-redux"
import { StateProps, ActionProps, EditHub, getHubSchema } from "./HubEdit"
import { actions as uiActions } from "../../store/ui/actions"
import { actions as authActions } from "../../store/auth/actions"
import { actions as cloudActions } from "../../store/cloud/actions"
import { Loaded } from "../../../functions/src/utils/types"
import { MapDispatch, MapState } from "../../utils/redux.types"

export const hub: HubPayload = {
    name: "",
    hubSlug: ""
}

const mapState: MapState<StateProps> = ({ auth: { configs }, cloud: { actionsResults } }) =>
    Loaded({
        hub,
        hubId: "",
        actionName: "Create",
        title: "Create Hub",
        schema: getHubSchema("", configs),
        results: actionsResults
    })

const mapDispatch: MapDispatch<ActionProps> = d => ({
    onUpdate: (actionId: string, _, data: HubPayload) => d(cloudActions.createHub(actionId, data)),
    queueNotification: msg => d(uiActions.queueNotification(msg)),
    onFinished: hubSlug => {
        d(authActions.fetchHubs())
        d(uiActions.closePopup("hubAdd"))
        d(authActions.navigate({ slugs: { hubSlug } }))
    }
})

export const AddHubView = connect(mapState, mapDispatch)(EditHub)
