import * as React from "react"
import {
    _MenuItems,
    _MenuItemContainer,
    _MenuItem,
    _MenuItemLabel,
    _MenuActions,
    _MenuAction,
    _MenuContainer,
    _MenuItemItemsCount,
    _MenuItemLabelText
} from "./Menu.styles"
import { _VerticalSpace } from "../styles/common"
import { Join } from "../utils/reactUtils"
import { IconSvg } from "./IconSvg"
import { Icon } from "../../functions/src/models/icons"

type MenuItemProps = {
    config: MenuConfig
    expandAllToggleState?: boolean | null
    depth?: number
    onUnfoldItem?: F3<string, string[], number>
}

type MenuItemAction = {
    render: (i: MenuItem, action: MenuItemAction) => React.ReactNode
    onClick: (i: MenuItem, action: MenuItemAction) => void
}

type MenuItemMeta = {
    actions?: MenuItemAction[]
    initialUnfolded?: string[]
    renderAfter?: (i: MenuItem) => React.ReactNode
}

export type MenuItem = {
    label: string
    key: string
    onClick?: (i: MenuItem) => void
    isSelected?: (i: MenuItem) => boolean
    meta?: MenuItemMeta
    items?: MenuItem[]
    iconName?: Icon | undefined
    isDisabled?: boolean
}

export type MenuConfig = Require<Omit<MenuItem, "label" | "key">, "items">

const hasItems = (item: MenuItem): item is Require<MenuItem, "items"> => Boolean(item.items && item.items.length)

const MenuItems = ({ config, depth = 0, onUnfoldItem, expandAllToggleState }: MenuItemProps) => {
    const [unfolded, setUnfolded] = React.useState<string[]>(config.meta?.initialUnfolded || [])

    const toggleFolded = (key: string) => {
        let newValue
        if (unfolded.includes(key)) {
            newValue = unfolded.filter(k => k !== key)
        } else {
            newValue = [...unfolded, key]
        }
        setUnfolded(newValue)
        if (onUnfoldItem) onUnfoldItem(key, newValue, depth)
    }

    const isFolded = (key: string) => !unfolded.includes(key)

    React.useEffect(() => {
        if (expandAllToggleState !== null) {
            if (expandAllToggleState) {
                setUnfolded(config.items.map((item: MenuItem) => item.key))
            } else {
                setUnfolded([])
            }
        }
    }, [expandAllToggleState, config.items])

    return (
        <_MenuItems>
            {config.items.map(i => {
                return (
                    <_MenuItemContainer key={i.key}>
                        <_MenuItem data-cy="menu-item">
                            <_MenuItemLabel
                                data-active={i.isSelected && i.isSelected(i)}
                                data-folded={depth === 0 && isFolded(i.key)}
                                data-disabled={i.isDisabled || false}
                                onClick={() => {
                                    if (depth === 0 || config.meta?.renderAfter) toggleFolded(i.key)
                                    i.onClick && i.onClick(i)
                                }}>
                                {depth === 0 ? <IconSvg name="arrow-sort-down" width={12} height={8} /> : null}
                                {i.iconName && (
                                    <IconSvg name={i.iconName} width={12} height={12} alt={i.iconName}></IconSvg>
                                )}
                                <_MenuItemLabelText>{i.label}</_MenuItemLabelText>
                                {i.items?.length ? (
                                    <_MenuItemItemsCount>({i.items?.length})</_MenuItemItemsCount>
                                ) : null}
                            </_MenuItemLabel>
                            {config.meta?.actions && (
                                <_MenuActions>
                                    <Join
                                        renderJoining={() => <_VerticalSpace base="8px" />}
                                        items={config.meta.actions}>
                                        {(a, k) => {
                                            return (
                                                <_MenuAction key={k} onClick={() => a.onClick(i, a)}>
                                                    {a.render(i, a)}
                                                </_MenuAction>
                                            )
                                        }}
                                    </Join>
                                </_MenuActions>
                            )}
                        </_MenuItem>
                        {!isFolded(i.key) && (
                            <>
                                {hasItems(i) && <MenuItems config={i} depth={depth + 1} />}
                                {config.meta?.renderAfter && config.meta.renderAfter(i)}
                            </>
                        )}
                    </_MenuItemContainer>
                )
            })}
        </_MenuItems>
    )
}

export const Menu = (p: MenuItemProps) => {
    return (
        <_MenuContainer data-cy="menu-container">
            <MenuItems {...p} />
        </_MenuContainer>
    )
}
