import * as React from "react"

import { _CardDetailsContainer } from "../../components/newCards/Card.styles"
import { _VerticalSpace, Flex, _HorizontalSpace, _AbsPosElement } from "../../styles/common"
import { keys } from "../../../functions/src/utils/map"
import { CardSchemas, SnapshotElement } from "./CardSchemas"
import { asyncWithDefault, isFetching } from "../../../functions/src/utils/types"

import { collectionsVMConverters } from "../../../functions/src/models/collections"
import { ViewModelsMap, DisplayModelsMap } from "../../../functions/src/models/ViewModels"
import {
    _CardSectionContainer,
    _CardSectionTableContainer,
    _CardSectionTitle
} from "../../components/newCards/sections/CardSection.styles"
import { InfoCardSection } from "../../components/newCards/sections/InfoCardSection"
import { assertNever } from "../../../functions/src/utils"
import { ImageCardSection } from "../../components/newCards/sections/ImageCardSection"
import { ColumnLayout } from "../../components/layout/ColumnLayout"
import { AssignedUserCardSection } from "../../components/newCards/sections/AssignedUserCardSection"
import { SearchAreasCardSection } from "../../components/newCards/sections/SearchAreasCardSection"
import { TagsCardSection } from "../../components/newCards/sections/TagsCardSection"
import { SegmentTagsCardSection } from "../../components/newCards/sections/SegmentTagsCardSection"
import { asyncConnect } from "../../store/async/asyncConnect"
import { Loader } from "../../components/common/Loader"
import { _CardSource } from "../../components/newCards/Card.styles"
import { IconSvg } from "../../components/IconSvg"
import { _BodyFont } from "../../styles/typography"
import { RichTextDescription } from "../../components/form/RichEditor"
import { RadicleInsights } from "../../components/newCards/sections/RadicleInsights"

const getSnapshotElement = <C extends CName>(
    e: SnapshotElement<C>,
    cname: C,
    objectId: ObjectId,
    flags: FeatureFlags,
    item: ViewModelsMap[C]
): React.ReactNode => {
    switch (e.type) {
        case "info":
            // TODO Get rid of 2 different converters & make it more granular/declarative (not for whole object, but for every field separately)
            const displayItem = (collectionsVMConverters[cname] as F1<ViewModelsMap[C], DisplayModelsMap[C]>)(item)
            return (
                <InfoCardSection
                    title={e.getTitle(item)}
                    description={e.getDescription && e.getDescription(item)}
                    recommended={e.getRecommended && e.getRecommended(item)}
                    values={keys(e.fields).map(k => {
                        const f = e.fields[k]!
                        return {
                            name: f.name,
                            value: f.valueDisplay(displayItem[k as keyof DisplayModelsMap[C]] as any),
                            info: f.getInfo && f.getInfo(item),
                            icon: f.icon,
                            vertical:
                                !!f.isVertical && f.isVertical(displayItem[k as keyof DisplayModelsMap[C]] as any),
                            visible: f.visible(displayItem[k as keyof DisplayModelsMap[C]] as any)
                        }
                    })}
                />
            )
        case "image":
            const src = e.getSrc(item)
            return src && <ImageCardSection src={src} title={e.getTitle(item)} />
        case "assignedUser":
            return flags.withAssignments && <AssignedUserCardSection objectId={objectId} />
        case "searchAreas":
            return <SearchAreasCardSection cname={cname} objectId={objectId} />
        case "tags":
            return flags.withTags && <TagsCardSection objectId={objectId} />
        case "segmentTags":
            return flags.withSegmentTags && <SegmentTagsCardSection objectId={objectId} />
        case "radicleInsights":
            return <RadicleInsights />
    }
    assertNever(e)
}

export type CardDetailsProps<C extends CName> = {
    objectId: string
    cname: C
}

// TODO Map own props fn (better typing) - get rid of anys
export const DetailsCardView = asyncConnect<CardDetailsProps<CName>>()({
    data: ["viewModels", "flags"]
})(p => {
    const collection = p.viewModels[p.cname] as Async<any>
    const item = React.useMemo(() => asyncWithDefault<any>(collection, {})[p.objectId], [collection, p.objectId])
    // TODO titleIconUrl -> logo url for companies & startups
    return (
        <_CardDetailsContainer>
            {isFetching(collection) ? (
                <Loader />
            ) : (
                <>
                    <ColumnLayout
                        items={CardSchemas[p.cname] as any}
                        renderElement={(e: any) => getSnapshotElement(e, p.cname, p.objectId, p.flags, item)}
                    />
                    <_CardSource>Source: {item.source}</_CardSource>
                </>
            )}
        </_CardDetailsContainer>
    )
})

export const DisplayRecommendedField = (o: ViewModelsMap["sectors"]): React.ReactNode =>
    o.recommended ? (
        <Flex direction="column">
            <Flex align="center">
                <IconSvg name="recommended" height={16} width={16} inline />
                <_HorizontalSpace base="10px" />
                <_BodyFont color="theme11">Sector is recommended</_BodyFont>
            </Flex>
            <_VerticalSpace base="5px" />
            {o.recommended_description ? (
                <RichTextDescription value={o.recommended_description} color="theme11" />
            ) : null}
        </Flex>
    ) : null
