import { Reports, PipelineReports } from "../../services/httpEndpoint/reports"
import { assertNever } from "../../utils"
import { mapObject, identity } from "../../utils/map"
import { getMonthDisplay } from "../dateTime"
import { DEFAULT_PLACEHOLDER } from "../shared"
import { getCollectionSchema, getCollectionFromId } from "../schemas"
import { Filters } from "../filtering"
import { ARCHIVED_PSV, NO_DECORATOR, getPipelineDisplay } from "../decoratorValues"
import { isEmpty } from "../../utils/validators"
import { UNKNOWN } from "../comments"

type ReportsCounterMeta = {
    total: number
    [ARCHIVED_PSV]: number
    [NO_DECORATOR]: number
}

export type CountBy<T extends string> = TMap<T, number>

export type PipelineStageCounter = Nullify<CountBy<PipelineStageId>> & ReportsCounterMeta

export type PositiveNegativeCounter = {
    positive: number
    negative: number
}

export type LeadProgressionCounter = TMap<PipelineStageId, PositiveNegativeCounter | null> &
    Casted<ReportsCounterMeta, PositiveNegativeCounter>

export type ReportsObjectItem = {
    objectName: string
    objectId: ObjectId
    createdTs: number
}

export type ByPipelineStageCount = {
    data: PipelineStageCounter
    total: number
}

export type PipelineStageVsCollectionItem = {
    id: CName
} & ByPipelineStageCount

export type PipelineStageVsMonthItem = {
    id: string // Timestamp
} & ByPipelineStageCount

export type PipelineStageVsSearchAreaItem = {
    id: AreaId
} & ByPipelineStageCount

export type LeadProgressionVsCollectionItem = {
    id: CName
    data: LeadProgressionCounter
    total: PositiveNegativeCounter
}

type ReportExportResult = { schema: SMap<string>; data: SMap<string | number>[] }

const filterReportData = <T extends PipelineReports>(report: T, filters: Filters, searchAreas: SMap<SearchArea>): T => {
    switch (report.type) {
        case "pipelineVsCollection":
        case "leadProgression":
            const collection = filters.collections?.[0]
            return collection
                ? {
                      ...report,
                      items: (report.items as any[]).filter(i => i.id === collection)
                  }
                : report
        case "pipelineVsSearchArea":
            const sa = filters.searchAreas
            return sa
                ? {
                      ...report,
                      items: (report.items as any[]).filter(i => sa.includes(searchAreas[i.id].name))
                  }
                : report
        case "leadMonthlyProgression":
            return report
    }
}

const defaultMapValue = (v: number | null) => (v === null ? DEFAULT_PLACEHOLDER : v)

const prepareCounterReport = (
    report: PipelineReports,
    title: string,
    filters: Filters,
    searchAreas: SMap<SearchArea>,
    mapValue: F1<any, string | number> = defaultMapValue,
    mapId: F1<string, string> = identity
): ReportExportResult => {
    const filteredReport = filterReportData(report, filters, searchAreas)

    const schema = {
        id: title,
        ...mapObject(filteredReport.summary.data, k => k),
        total: "Total Leads"
    }
    return {
        schema,
        data: [
            ...(filteredReport.items as any[]).map((i: any) => ({
                id: mapId(i.id),
                ...mapObject(i.data, (_, v) => mapValue(v)),
                total: mapValue(i.total)
            })),
            {
                id: "Total",
                ...mapObject(filteredReport.summary.data, (_, v) => mapValue(v)),
                total: mapValue(filteredReport.summary.total)
            }
        ]
    }
}

export const prepareReportToExport = (
    report: Reports,
    searchAreas: SMap<SearchArea>,
    pipelines: SMap<PipelineStageValue>,
    filters: Filters
): ReportExportResult => {
    switch (report.type) {
        case "pipelineVsCollection":
            return prepareCounterReport(
                report,
                "Object types",
                filters,
                searchAreas,
                undefined,
                (id: CName) => getCollectionSchema(id).displayName
            )
        case "leadMonthlyProgression":
            return prepareCounterReport(report, "Months", filters, searchAreas, undefined, id =>
                getMonthDisplay(new Date(+id))
            )
        case "pipelineVsSearchArea":
            return prepareCounterReport(
                report,
                "Collections",
                filters,
                searchAreas,
                undefined,
                id => searchAreas[id].name
            )
        case "leadProgression":
            return prepareCounterReport(
                report,
                "Object types",
                filters,
                searchAreas,
                v => {
                    if (isEmpty(v)) return DEFAULT_PLACEHOLDER
                    const p = v.positive ? `+${v.positive}` : DEFAULT_PLACEHOLDER
                    const n = v.negative ? `-${v.negative}` : DEFAULT_PLACEHOLDER
                    return `${p} / ${n}`
                },
                (id: CName) => getCollectionSchema(id).displayName
            )
        case "archiveRationale":
        case "movedAlongThePipeline":
            return {
                schema: {
                    objectType: "Object type",
                    objectName: "Object name",
                    collection: "Collection",
                    pipelineStage: "Pipeline stage",
                    priorityRank: "Priority rank",
                    priorPipelineStage: "Prior pipeline stage",
                    comment: "Comment",
                    changedBy: "Changed By",
                    date: "Date"
                },
                data: report.items.map(i => {
                    const cname = getCollectionFromId(i.objectId)
                    const meta = i.meta?.value as AreaDecoratorMeta | undefined
                    return {
                        objectType: cname ? getCollectionSchema(cname).displayName : UNKNOWN,
                        objectName: i.objectName,
                        collection: meta ? searchAreas[meta.searchAreaId]?.name || UNKNOWN : UNKNOWN,
                        pipelineStage: meta ? getPipelineDisplay(meta.newValue, pipelines) || UNKNOWN : UNKNOWN,
                        priorityRank: i.priorityRank,
                        priorPipelineStage: meta ? getPipelineDisplay(meta.oldValue, pipelines) || UNKNOWN : UNKNOWN,
                        comment: i.text,
                        changedBy: `${i.userName}, ${i.userEmail}`,
                        date: new Date(i.createdTs).toLocaleDateString()
                    }
                })
            }
    }
    assertNever(report)
}
