/* eslint-disable max-lines */
import { Err, errors, Ok, validateCollection } from "../../utils/validators"
import { firestore } from "firebase-admin"
import { asyncForEachObject, asyncForEach, call } from "../../utils"
import { toMap, arrify, iterateMap } from "../../utils/map"
import { Logger } from "../logging"

const { error } = Logger("Firestore")

export type DocRef<T> = firestore.DocumentReference & { __type: T }
export type ColRef<T> = firestore.CollectionReference & { __type: T }

type SubscriptionOptions = { onlyNew?: boolean }
export const QueryEqual = <T>(key: keyof T, val: any): QueryWhere => ({ key: key as string, compare: "==", val })
export const QueryWhere = <T>(key: keyof T, compare: QueryComparator, val: any): QueryWhere => ({
    key: key as string,
    compare,
    val
})

export const BATCH_LIMIT = 499 // Not 500 just to be sure there is no error like counting from 0 vs 1
export const getQuery = <T>(r: ColRef<SMap<T>>, options: QueryOptions = {}) => {
    let query: firestore.Query | typeof r = r
    if (options.orderBy) query = query.orderBy(`${options.orderBy}`, options.orderDir)
    if (options.startAt) query = query.startAt(options.startAt)
    if (options.limit) query = query.limit(options.limit)
    if (options.where)
        arrify(options.where).forEach(w => {
            query = query.where(w.key as string, w.compare, w.val)
        })

    return query
}

export interface FSService<TSchema> {
    ref<TKey extends keyof TSchema>(node: TKey): ColRef<TSchema[TKey]>
    ref<TKey extends keyof TSchema>(node: TKey, doc: string): DocRef<ValueOf<TSchema[TKey]>>
    ref<TKey extends keyof TSchema, TNode extends keyof ValueOf<TSchema[TKey]>>(
        node: TKey,
        doc: string,
        subNode: TNode
    ): ColRef<ValueOf<TSchema[TKey]>[TNode]>
    ref<TKey extends keyof TSchema, TNode extends keyof ValueOf<TSchema[TKey]>>(
        node: TKey,
        doc: string,
        subNode: TNode,
        subDoc: string
    ): DocRef<ValueOf<ValueOf<TSchema[TKey]>[TNode]>>
    ref<
        TKey extends keyof TSchema,
        TNode extends keyof ValueOf<TSchema[TKey]>,
        TNode2 extends keyof ValueOf<ValueOf<ValueOf<TSchema[TKey]>[TNode]>>
    >(
        node: TKey,
        doc: string,
        subNode: TNode,
        subDoc: string,
        subNode2: TNode2,
        subDoc2: string
    ): DocRef<ValueOf<ValueOf<ValueOf<ValueOf<TSchema[TKey]>[TNode]>>[TNode2]>>

    subDb: F1<firestore.DocumentReference, FSService<TSchema>>
    rootDb: F0<FSService<TSchema>>

    getInstance: F0<firestore.Firestore>
    flushSubscriptions: F1<string[]>
    isSubscribedOnRef<T>(ref: ColRef<T> | DocRef<T>): boolean

    fetchDoc<T>(ref: DocRef<T>, validator: Validator<T>): Promise<Result<T>>
    fetchDocTran<T>(t: firestore.Transaction, ref: DocRef<T>, validator: Validator<T>): Promise<Result<T>>
    getColSize<T>(ref: ColRef<SMap<T>>): Promise<number>
    fetchCol<T>(ref: ColRef<SMap<T>>, validator: Validator<T>, options?: QueryOptions): Promise<ValidatedCollection<T>>
    fetchColTran<T>(
        t: firestore.Transaction,
        ref: ColRef<SMap<T>>,
        validator: Validator<T>,
        options?: QueryOptions
    ): Promise<ValidatedCollection<T>>
    addDoc<T>(ref: ColRef<T>, doc: Partial<ValueOf<T>>): Promise<DocRef<ValueOf<T>> | null>
    addDocTran<T>(t: firestore.Transaction, ref: ColRef<T>, doc: Partial<ValueOf<T>>): Promise<DocRef<T>>
    setDoc<T>(ref: DocRef<T>, document: Partial<T>, merge?: boolean): Promise<void>
    setDocTran<T>(t: firestore.Transaction, ref: DocRef<T>, document: Partial<T>, merge?: boolean): Promise<void>
    updateDoc<T>(ref: DocRef<T>, payload: Partial<T>): Promise<void>
    updateDocTran<T>(t: firestore.Transaction, ref: DocRef<T>, payload: Partial<T>): Promise<void>
    deleteDoc<T>(ref: DocRef<T>): Promise<void>
    deleteDocWhere<T>(ref: ColRef<SMap<T>>, options: QueryOptions): Promise<void>
    deleteDocTran<T>(t: firestore.Transaction, ref: DocRef<T>): Promise<void>
    deleteCol<T>(ref: ColRef<T>): Promise<Result<unknown>>
    setCol<T>(ref: ColRef<SMap<T>>, value: SMap<T>, mergeInItem?: boolean): Promise<Result<unknown>>
    subscribeOnCol<T>(
        ref: ColRef<SMap<T>>,
        validator: Validator<T>,
        onChange: F2<SMap<T>, SMap<Err<ExtErrors<T>>>>,
        onError: F1<Error>,
        options?: QueryOptions & SubscriptionOptions
    ): F0
    subscribeOnDoc<T>(ref: DocRef<T>, validator: Validator<T>, onWrite: F1<Result<T>>, onError: F1<Error>): F0
}

export const initOfType = <TSchema>(
    instance: firestore.Firestore,
    type: "web" | "admin",
    rootRef?: firestore.DocumentReference
): FSService<TSchema> => {
    // if (type === "web")
    //     (instance as any).enablePersistence().catch((err: any) => {
    //         // eslint-disable-next-line no-console
    //         console.log("Enabling persistence failed", err)
    //     })
    function ref<
        TKey extends keyof TSchema,
        TNode extends keyof ValueOf<TSchema[TKey]>,
        TNode2 extends keyof ValueOf<ValueOf<ValueOf<TSchema[TKey]>[TNode]>>
    >(node: TKey, doc?: string, subNode?: TNode, subDoc?: string, subNode2?: TNode2, subDoc2?: string) {
        let r: any = (rootRef || instance).collection(node as string) as ColRef<TSchema[TKey]>

        type Doc1 = ValueOf<TSchema[TKey]>
        type Doc2 = ValueOf<Doc1[TNode]>
        type Doc3 = ValueOf<ValueOf<Doc2>[TNode2]>
        if (doc) r = r.doc(doc) as DocRef<Doc1>
        if (subNode) r = r.collection(subNode) as ColRef<Doc1[TNode]>
        if (subDoc) r = r.doc(subDoc) as DocRef<Doc2>
        if (subNode2) r = r.collection(subNode2) as ColRef<ValueOf<Doc2>[TNode2]>
        if (subDoc2) r = r.doc(subDoc2) as DocRef<Doc3>
        return r
    }

    const fetchDoc = async <T>(r: DocRef<T>, validator: Validator<T>): Promise<Result<T>> => {
        try {
            const snap = await r.get()
            return snap.exists ? validator(snap.data()) : Err(errors.notFound, r.path)
        } catch (e) {
            error("fetchDoc() error:", "docRef:", r, "error message:", e.message, "error obj: ", e)
            return Err(errors.notFound, r.path)
        }
    }
    const fetchDocTran = async <T>(
        t: firestore.Transaction,
        r: DocRef<T>,
        validator: Validator<T>
    ): Promise<Result<T>> => {
        try {
            const snap = await t.get(r)
            return snap.exists ? validator(snap.data()) : Err(errors.notFound, r.path)
        } catch (e) {
            error("fetchDocTran() error:", "docRef:", r, "tran:", t, "error message:", e.message, "error obj: ", e)
            return Err(errors.notFound, r.path)
        }
    }

    const fetchCol = async <T>(
        r: ColRef<SMap<T>>,
        validator: Validator<T>,
        options: QueryOptions = {}
    ): Promise<ValidatedCollection<T>> => {
        try {
            const snap = await getQuery(r, options).get()
            const { valid, invalid } = validateCollection(
                toMap(
                    snap.docs,
                    v => v.id,
                    v => v.data()
                ),
                validator
            )
            return { valid, invalid }
        } catch (e) {
            error("fetchCol() error:", "colRef:", r, "options", options, "error message:", e.message, "error obj: ", e)
            return { valid: {}, invalid: {} }
        }
    }

    const fetchColTran = async <T>(
        t: firestore.Transaction,
        r: ColRef<SMap<T>>,
        validator: Validator<T>,
        options: QueryOptions = {}
    ): Promise<ValidatedCollection<T>> => {
        try {
            const snap = await t.get(getQuery(r, options))
            const { valid, invalid } = validateCollection(
                toMap(
                    snap.docs,
                    v => v.id,
                    v => v.data()
                ),
                validator
            )
            return { valid, invalid }
        } catch (e) {
            error(
                "fetchColTran() error:",
                "colRef:",
                r,
                "trans:",
                t,
                "options",
                options,
                "error message:",
                e.message,
                "error obj: ",
                e
            )
            return { valid: {}, invalid: {} }
        }
    }

    // TODO It's not performant - r.get() in delete?
    const deleteCol = async <T>(r: ColRef<T>) => {
        if (type === "web") {
            // eslint-disable-next-line no-console
            console.error("Deleting collections on web is not recommended")
            return Err("Deleting collections on web is not recommended")
        }

        try {
            let batch = instance.batch()
            let checkpoint = 0

            const col = await r.get()
            await asyncForEach(col.docs, async (v, i) => {
                if (i - checkpoint >= BATCH_LIMIT) {
                    await batch.commit()
                    batch = instance.batch()
                    checkpoint = i
                }
                batch.delete(v.ref)
            })
            await batch.commit()
            return Ok({})
        } catch (e) {
            error("deleteCol() error:", "colRef:", r, "error message:", e.message, "error obj: ", e)
            return Err(e.message, e)
        }
    }

    const deleteDocWhere = async <T>(r: ColRef<SMap<T>>, opts: QueryOptions) => {
        try {
            const snap = await getQuery(r, opts).get()
            let batch = instance.batch()
            let checkpoint = 0
            await asyncForEach(snap.docs, async (v, i) => {
                if (i - checkpoint >= BATCH_LIMIT) {
                    await batch.commit()
                    batch = instance.batch()
                    checkpoint = i
                }
                batch.delete(v.ref)
            })

            return batch.commit() as any
        } catch (e) {
            error("deleteDocWhere() error:", "colRef:", r, "opts:", opts, "error message:", e.message, "error obj: ", e)
            return Err(e.message, e)
        }
    }

    const setCol = async <T>(r: ColRef<SMap<T>>, value: SMap<T>, mergeInItem = false) => {
        try {
            let batch = instance.batch()
            let checkpoint = 0 // since batch can handle only 500 records
            await asyncForEachObject(value, async (k, v, i) => {
                if (i - checkpoint >= BATCH_LIMIT) {
                    await batch.commit()
                    batch = instance.batch()
                    checkpoint = i
                }
                const doc = r.doc(k)
                batch.set(doc, v, { merge: mergeInItem })
            })
            await batch.commit()
            return Ok({})
        } catch (e) {
            error(
                "setCol() error:",
                "colRef:",
                r,
                "value:",
                value,
                "mergeInItem:",
                mergeInItem,
                "error message:",
                e.message,
                "error obj: ",
                e
            )
            return Err(e.message, e)
        }
    }

    const subscriptions: SMap<F0 | undefined> = {}

    const subscribeOnCol = <T>(
        r: ColRef<SMap<T>>,
        validator: Validator<T>,
        onChange: F2<SMap<T>, SMap<Err<ExtErrors<T>>>>,
        onError: F1<any>,
        options: QueryOptions & SubscriptionOptions = {}
    ) => {
        if (subscriptions[r.path]) call(subscriptions[r.path])
        const unsubscribe = getQuery(r, options).onSnapshot(snap => {
            let data = snap.docs
            if (options.onlyNew)
                data = snap
                    .docChanges()
                    .filter(doc => doc.type === "added")
                    .map(d => d.doc)
            const { valid, invalid } = validateCollection(
                toMap(
                    data,
                    v => v.id,
                    v => v.data()
                ),
                validator
            )
            onChange(valid, invalid)
        }, onError)
        subscriptions[r.path] = unsubscribe
        return unsubscribe
    }

    const subscribeOnDoc = <T>(r: DocRef<T>, validator: Validator<T>, onChange: F1<Result<T>>, onError: F1<any>) => {
        if (subscriptions[r.path]) call(subscriptions[r.path])
        const unsubscribe = r.onSnapshot(doc => (doc.exists ? onChange(validator(doc.data())) : null), onError)
        subscriptions[r.path] = unsubscribe
        return unsubscribe
    }

    return {
        subDb: r => initOfType<TSchema>(instance, type, r),
        rootDb: () => initOfType<TSchema>(instance, type),
        ref,
        isSubscribedOnRef: r => Boolean(subscriptions[r.path]),
        fetchDoc,
        fetchDocTran,
        fetchCol,
        getColSize: async r => {
            try {
                return (await r.listDocuments()).length
            } catch (e) {
                error("getColSize() error:", "colRef:", r, "error message:", e.message, "error obj: ", e)
                return -1
            }
        },
        fetchColTran,
        deleteCol,
        addDoc: async <T>(r: ColRef<T>, doc: Partial<ValueOf<T>>) => {
            try {
                return r.add(doc) as Promise<DocRef<ValueOf<T>>>
            } catch (e) {
                error("addDoc() error:", "colRef:", r, "doc:", doc, "error message:", e.message, "error obj: ", e)
                return null
            }
        },
        addDocTran: async (t, r, doc) => {
            try {
                return t.create(r.doc(), doc) as any
            } catch (e) {
                error(
                    "addDocTran() error:",
                    "colRef:",
                    "tran:",
                    t,
                    r,
                    "doc:",
                    doc,
                    "error message:",
                    e.message,
                    "error obj: ",
                    e
                )
                return null
            }
        },
        setDoc: async (r, doc, merge = false) => {
            try {
                return r.set(doc, { merge }) as any
            } catch (e) {
                error(
                    "setDoc() error:",
                    "docRef:",
                    r,
                    "doc:",
                    doc,
                    "merge:",
                    merge,
                    "error message:",
                    e.message,
                    "error obj: ",
                    e
                )
                return null
            }
        },
        setDocTran: async (t, r, doc, merge = false) => {
            try {
                return t.set(r, doc, { merge }) as any
            } catch (e) {
                error(
                    "setDocTran() error:",
                    "docRef:",
                    r,
                    "tran:",
                    t,
                    "doc:",
                    doc,
                    "merge:",
                    merge,
                    "error message:",
                    e.message,
                    "error obj: ",
                    e
                )
                return null
            }
        },
        updateDoc: async (r, payload) => {
            try {
                return r.update(payload) as any
            } catch (e) {
                error(
                    "updateDoc() error:",
                    "docRef:",
                    r,
                    "payload:",
                    payload,
                    "error message:",
                    e.message,
                    "error obj: ",
                    e
                )
                return null
            }
        },
        updateDocTran: async (t, r, payload) => {
            try {
                return t.update(r, payload) as any
            } catch (e) {
                error(
                    "updateDocTran() error:",
                    "docRef:",
                    r,
                    "tran:",
                    t,
                    "payload:",
                    payload,
                    "error message:",
                    e.message,
                    "error obj: ",
                    e
                )
                return null
            }
        },
        deleteDoc: async r => {
            try {
                return r.delete() as any
            } catch (e) {
                error("deleteDoc() error:", "docRef:", r, "error message:", e.message, "error obj: ", e)
                return null
            }
        },
        deleteDocWhere,
        deleteDocTran: async (t, r) => {
            try {
                return t.delete(r) as any
            } catch (e) {
                error("addDoc() error:", "docRef:", r, "tran:", t, "error message:", e.message, "error obj: ", e)
                return null
            }
        },
        subscribeOnCol,
        subscribeOnDoc,
        setCol,
        getInstance: () => instance,
        flushSubscriptions: (keysToPreserve: string[]) => {
            iterateMap(subscriptions, (k, v) => {
                if (!v || keysToPreserve.includes(k)) return
                v()
                subscriptions[k] = undefined
            })
        }
    }
}
