import * as React from "react"
import { capitalize } from "../../../functions/src/utils"
import { _BodyFont } from "../../styles/typography"
import { _RadarBreadcrumbsContainer } from "./RadarBreadcrumbs.styles"

type RadarBreadcrumbsProps = {
    data: string[]
}

export const RadarBreadcrumbs = ({ data }: RadarBreadcrumbsProps) => {
    return (
        <_RadarBreadcrumbsContainer>
            {data.map((b, i) => (
                <_BodyFont key={i} bold s20>
                    {capitalize(b)}
                </_BodyFont>
            ))}
        </_RadarBreadcrumbsContainer>
    )
}
