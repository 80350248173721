import * as React from "react"
import { _Stepper, _StepWrapper, _StepTitle } from "./Stepper.styles"
import { _noop } from "../../../functions/src/utils"
import { IconSvg } from "../IconSvg"

export type StepDescription = {
    title: string
    stepNumber: number
}

export type StepProps = {
    title: string // TODO: Probably remove & replace with icon
    done: boolean
    selected: boolean
    isSelectable: boolean
    onClick: F0
}

export const Step: React.FC<StepProps> = ({ onClick, ...p }) => (
    <_StepWrapper onClick={p.isSelectable ? onClick : _noop}>
        <IconSvg name={p.done ? "tick-active" : "tick-inactive"} width={20} height={20} onClick={_noop} />
        <_StepTitle selected={p.selected}>{p.title}</_StepTitle>
    </_StepWrapper>
)
export type StepperProps = {
    steps: StepDescription[]
    currentStepNumber: number
    availableSteps: number[]
    selectability?: SMap<number[]>
    onStepClicked: F1<StepDescription>
}

export const Stepper: React.FC<StepperProps> = p => (
    <_Stepper>
        {p.steps.map(step => (
            <Step
                key={step.stepNumber}
                {...step}
                done={step.stepNumber < p.currentStepNumber}
                selected={step.stepNumber === p.currentStepNumber}
                isSelectable={p.availableSteps.includes(step.stepNumber)}
                onClick={() => p.onStepClicked(step)}
            />
        ))}
    </_Stepper>
)
