import * as React from "react"
import styled from "styled-components"
import { _BodyFont } from "../styles/typography"
import { Flex, _FlexColumn } from "../styles/common"

const _StyledHeader = styled.div`
    display: flex;
    width: 100%;
    flex-shrink: 0;
`

const Align = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
`

const AlignRight = styled(Align)`
    align-items: flex-end;
`

const AlignLeft = styled(Align)`
    align-items: flex-start;
`

export const SubpageH1 = styled.h1`
    font-size: 16px;
    font-weight: 800;
    line-height: 1.5;
    margin: 0;
    letter-spacing: 1px;
    color: ${({ theme }) => theme.colors.primary};
    text-transform: uppercase;
`

const AfterTitle = styled(SubpageH1)`
    font-weight: normal;
`

export const _Layout = styled(Flex)<{ width?: string }>`
    position: relative;
    overflow: auto;
    width: ${p => p.width || "100%"};
    flex-grow: 1;
    flex-direction: column;
    outline: none;
`

export const _ScrollingContainer = styled.div<{ fullwidth?: boolean }>`
    max-width: ${p => (p.fullwidth ? "100%" : "calc(1440px + 32px)")};
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
`

export const _XScrollingContainer = styled(_FlexColumn)`
    flex: 1 1 auto;
    overflow-x: auto;
`

export const SubpageLayout = React.forwardRef<any, React.PropsWithChildren<{ wide?: boolean }>>((p, ref) => (
    <_Layout ref={ref} tabIndex={-1}>
        <_ScrollingContainer data-cy="scrolling-container" fullwidth={p.wide}>
            {p.children}
        </_ScrollingContainer>
    </_Layout>
))

export const _LayoutContainer = styled(_FlexColumn)<{ relative?: boolean }>`
    flex: 1 1 auto;
    overflow: auto;
    ${p => p.relative && "position: relative;"};

    @media print {
        overflow: visible;
    }
`

type Props = { title: string; label?: string; afterTitle?: string }

export const SubHeader: React.FC<Props> = p => (
    <_StyledHeader>
        <AlignLeft>
            {p.label && <_BodyFont s14>{p.label}</_BodyFont>}
            <SubpageH1>
                {p.title}
                {p.afterTitle && (
                    <>
                        {": "}
                        <AfterTitle as="span">{p.afterTitle}</AfterTitle>
                    </>
                )}
            </SubpageH1>
        </AlignLeft>

        {p.children && <AlignRight>{p.children}</AlignRight>}
    </_StyledHeader>
)
