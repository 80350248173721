import { DateTime } from "luxon"

export const goBackByMonths = (ts: number, amount: number, timezone: string = "utc") =>
    DateTime.fromMillis(ts, { zone: timezone }).minus({ months: amount }).toMillis()

export const forwardMonths = (ts: number, amount: number, timezone: string = "utc") =>
    DateTime.fromMillis(ts, { zone: timezone }).plus({ months: amount }).toMillis()

export const getLastDayOfMonth = (ts: number, timezone: string = "utc") =>
    DateTime.fromMillis(ts, { zone: timezone }).endOf("month").toMillis()

export const isSameMonth = (ts1: number, ts2: number, timezone: string = "utc") =>
    DateTime.fromMillis(ts1, { zone: timezone }).hasSame(DateTime.fromMillis(ts2, { zone: timezone }), "month")

export const getLastMonthsDaysInRange = (from: number, to: number, timezone: string = "utc") => {
    const result: number[] = []
    let date: number = getLastDayOfMonth(from, timezone)
    while (date <= to) {
        result.push(date)
        date = getLastDayOfMonth(forwardMonths(date, 1, timezone), timezone)
    }
    return result
}

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
]

export const getMonthDisplay = (d: Date) =>
    `${isSameMonth(d.getTime(), Date.now()) ? "(current) " : ""}${months[d.getMonth()]} ${d.getFullYear()}`
