import { DropdownProps, DropdownBase } from "../Dropdown"
import React from "react"
import {
    _NavigationDropdownTrigger,
    _NavigationDropdownOption,
    _NavigationDropdownContainer
} from "./NavigationDropdown.styles"
import { cbNoPropagation } from "../../utils/html5"
import { _DropdownText, _DropdownOption } from "../Dropdown.styles"
import { capitalize } from "../../../functions/src/utils"
import { _HorizontalSpace } from "../../styles/common"

export const NavigationDropdown = <T extends any>(p: DropdownProps<T>): React.ReactElement<DropdownProps<T>> => (
    <_NavigationDropdownContainer>
        <DropdownBase
            {...p}
            renderTrigger={({ onClick, open, value }) => (
                <_NavigationDropdownTrigger
                    onClick={p.options.length > 1 ? cbNoPropagation(onClick) : undefined}
                    open={open}>
                    <_DropdownText>{capitalize(value)}</_DropdownText>
                    <_HorizontalSpace base="20px" />
                </_NavigationDropdownTrigger>
            )}
            renderOption={({ onSelect, isSelected, value }) => (
                <_DropdownOption onClick={cbNoPropagation(onSelect)} key={value.label}>
                    <_NavigationDropdownOption href={value.value} isSelected={isSelected}>
                        {capitalize(value.label)}
                    </_NavigationDropdownOption>
                </_DropdownOption>
            )}
        />
    </_NavigationDropdownContainer>
)
