import * as React from "react"
import { _StatusBar } from "./StatusBar.styles"
import { Button } from "../../styles/buttons"

export type StatusBarProps = { stepDescription: string; onNextClick: F0; onPreviousClick: F0 }
export const StatusBar: React.FC<StatusBarProps> = p => (
    <_StatusBar>
        <Button onClick={p.onPreviousClick}>Back</Button>
        <span>{p.stepDescription}</span>
        <Button onClick={p.onNextClick}>Next</Button>
    </_StatusBar>
)
