import * as React from "react"
import { filterObject, values } from "../../../../functions/src/utils/map"
import { CellSize } from "../TableView.types"

export type PinnedColumnsProperties = {
    left?: string
    right?: string
    size: number
    classNames: string[]
}
export type PinnedColumnsStyles = {
    [id: string]: PinnedColumnsProperties
}
export type PinnedColumnsSide = "left" | "right"
export type PinnedColumns = {
    all: PinnedColumnsStyles
    left: PinnedColumnsStyles
    right: PinnedColumnsStyles
}
type PinnedColumnsInternalState = { left: PinnedColumnsStyles; right: PinnedColumnsStyles }

const markLastPinned = <T extends string>(columns: T[], pinnedColumns: PinnedColumnsInternalState) => {
    const reversedColumns = [...columns].reverse()
    const lastRight = columns.find(c => pinnedColumns.right[c])
    const lastLeft = reversedColumns.find(c => pinnedColumns.left[c])

    values(pinnedColumns.right).forEach(pc => (pc.classNames = pc.classNames.filter(c => c !== "pinned-last")))
    values(pinnedColumns.left).forEach(pc => (pc.classNames = pc.classNames.filter(c => c !== "pinned-last")))

    if (lastRight) pinnedColumns.right[lastRight].classNames.push("pinned-last")
    if (lastLeft) pinnedColumns.left[lastLeft].classNames.push("pinned-last")

    return pinnedColumns
}

type PinAction<T extends string> = { type: "pin"; side: PinnedColumnsSide; id: T }
type UnpinAction<T extends string> = { type: "unpin"; side: PinnedColumnsSide; id: T }

const mkPinAction = <ID extends string>(side: PinnedColumnsSide, id: ID): PinAction<ID> => ({
    type: "pin",
    side,
    id
})

const mkUnpinAction = <ID extends string>(side: PinnedColumnsSide, id: ID): UnpinAction<ID> => ({
    type: "unpin",
    side,
    id
})

// TODO Test
export const usePinnedColumns = <ID extends string, T extends { id: ID; size: CellSize }>(
    columns: T[],
    initialValue: { left: ID[]; right: ID[] } = { left: [], right: [] }
) => {
    const reducer = React.useCallback(
        (s: PinnedColumnsInternalState, action: PinAction<ID> | UnpinAction<ID>): PinnedColumnsInternalState => {
            switch (action.type) {
                case "pin": {
                    if (action.id in s[action.side]) return s
                    const newPinnedColumns = {
                        ...s,
                        [action.side]: {
                            ...s[action.side],
                            [action.id]: {
                                [action.side]: `${columns.reduce(
                                    (acc, c) => (s[action.side][c.id] ? c.size.min + acc : acc),
                                    0
                                )}px`,
                                size: columns.find(c => c.id === action.id)?.size.min || 0,
                                classNames: [`pinned-${action.side}`]
                            }
                        }
                    }
                    markLastPinned(
                        columns.map(c => c.id),
                        newPinnedColumns
                    )
                    return newPinnedColumns
                }
                case "unpin": {
                    if (!(action.id in s[action.side])) return s
                    const newPinnedColumns = {
                        ...s,
                        [action.side]: filterObject(s[action.side], k => k !== action.id)
                    }
                    markLastPinned(
                        columns.map(c => c.id),
                        newPinnedColumns
                    )
                    return newPinnedColumns
                }
            }
            return s
        },
        [columns]
    )

    const [pinnedColumns, dispatch] = React.useReducer(reducer, { left: {}, right: {} })

    const pinColumn = React.useCallback((side: PinnedColumnsSide, id: ID) => dispatch(mkPinAction(side, id)), [
        dispatch
    ])

    const unpinColumn = React.useCallback((side: PinnedColumnsSide, id: ID) => dispatch(mkUnpinAction(side, id)), [
        dispatch
    ])

    React.useEffect(() => {
        initialValue?.left.forEach(id => pinColumn("left", id))
        initialValue?.right.forEach(id => pinColumn("right", id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue])

    return {
        pinnedColumns: React.useMemo(
            () => ({
                all: { ...pinnedColumns.left, ...pinnedColumns.right },
                left: pinnedColumns.left,
                right: pinnedColumns.right
            }),
            [pinnedColumns]
        ),
        pinColumn,
        unpinColumn
    }
}
