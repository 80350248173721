// TODO Fix this
/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react"
import {
    _ResultsContainer,
    _Wrapper,
    _SearchResultWrapper,
    _ResultLink,
    _ResultGroupTitle,
    _MoreLink
} from "./SearchBar.styles"
import { connect } from "react-redux"
import { collections, CollectionSchema } from "../../functions/src/models/schemas"
import { _HorizontalSpace, _Spacer, _Center, _VerticalSpace } from "../styles/common"
import {
    AccordionItemButton,
    AccordionItemHeading,
    Accordion,
    AccordionItem,
    AccordionItemPanel
} from "react-accessible-accordion"
import { Loading, Loaded } from "../../functions/src/utils/types"
import { ImageWithFallback } from "./common"
import { _BodyFont } from "../styles/typography"
import { SearchInput } from "./form/Input"
import { isEmpty, isOk } from "../../functions/src/utils/validators"
import { Loader } from "./common/Loader"
import { getPerf } from "../services/performance"
import { getFirebase } from "../services/firebase"
import { actions } from "../../functions/src/services/httpEndpoint/actions"
import { SearchResponse } from "../../functions/src/services/httpEndpoint/search"
import { CollectionSearchResult } from "../../functions/src/models/search"
import { useDebounce } from "../utils/hooks/useDebounce"
import { materialize } from "../utils/router"
import { detailsPaths, getCollectionDetailsPathName } from "../paths"
import { MapState } from "../utils/redux.types"
import { isRadarLocation } from "../models/LocationType"
import { getUserRadars, isDemoRadarLocation, getCurrentRadar } from "../models/LocationType"
import { track, identify } from "../tracking"

type CollectionSearchResultWithLink = { cname: CName; results: SearchResultProps[] }
type SearchResultProps = CollectionSearchResult["results"][0] & { href: string }

const materializeLinksToObject = <C extends CName>(radarSlug: string) => (
    result: CollectionSearchResult
): CollectionSearchResultWithLink => {
    const schema: CollectionSchema<C, any> = collections[result.cname] as any
    return {
        cname: result.cname,
        results: result.results.map(v => ({
            ...v,
            href: materializePath(schema.idField, radarSlug, result.cname as any, v)
        }))
    }
}

const materializePath = <C extends CName>(
    idField: keyof RCollection<C>,
    radarSlug: string,
    cname: C,
    v: CollectionSearchResult["results"][0]
) =>
    materialize(detailsPaths[getCollectionDetailsPathName(cname)].path, {
        radarSlug,
        [idField]: `${v.id}`
    })

const SearchResultButton: React.FC<{ name: string; onClick: F0 }> = p => (
    <_SearchResultWrapper>
        <_HorizontalSpace base="10px" />
        <_MoreLink onClick={p.onClick}>{p.name}</_MoreLink>
    </_SearchResultWrapper>
)

const SearchResult: React.FC<SearchResultProps> = p => (
    <_SearchResultWrapper>
        {p.icon !== null ? <ImageWithFallback src={p.icon} width={32} height={32} /> : null}
        <_HorizontalSpace base="10px" />
        <_ResultLink data-cy="search-result-name" to={p.href}>
            {p.name}
        </_ResultLink>
    </_SearchResultWrapper>
)

const SearchResults: React.FC<CollectionSearchResultWithLink> = p => (
    <>
        {p.results.map(v => (
            <SearchResult {...v} key={v.id} />
        ))}
    </>
)

type SearchResultTitleProps = { icon?: string; name: string; totalCount: number; count: number }
const SearchResultTitle: React.FC<SearchResultTitleProps> = ({ totalCount, count, name }) => {
    const cntTitle = count < totalCount ? `${count} out of ${totalCount} results` : `${count} results`
    return (
        <AccordionItemHeading>
            <AccordionItemButton>
                <_ResultGroupTitle>{name}</_ResultGroupTitle>
                <_Spacer />
                <span>{cntTitle}</span>
            </AccordionItemButton>
        </AccordionItemHeading>
    )
}

const RESULTS_CNT = 5
type Props = { results: Loadable<{ data: CollectionSearchResultWithLink[] }>; show: boolean }
const SearchBarResults: React.FC<Props> = p => {
    if (!p.show) return null

    const [cntMap, setCntMap] = React.useState<SMap<number>>({})
    const incCnt = (cname: string, totalCount: number) =>
        setCntMap({ ...cntMap, [cname]: Math.min((cntMap[cname] || RESULTS_CNT) + RESULTS_CNT, totalCount) })
    const firstNotEmpty = !p.results.loading && p.results.data.find(r => r.results.length > 0)
    const preExpanded = firstNotEmpty ? [firstNotEmpty.cname] : []
    return (
        <_ResultsContainer>
            {p.results.loading || !p.results.data.length ? (
                <_Center>
                    <_VerticalSpace base="8px" />
                    {p.results.loading ? <Loader size="small" /> : <_BodyFont>No results found</_BodyFont>}
                    <_VerticalSpace base="8px" />
                </_Center>
            ) : (
                <Accordion preExpanded={preExpanded}>
                    {p.results.data
                        .map(r => {
                            const count = cntMap[r.cname] || RESULTS_CNT
                            const totalCount = r.results.length
                            const results = r.results.slice(0, count)
                            const name = collections[r.cname].displayName
                            return { ...r, count: Math.min(count, results.length), totalCount, results, name }
                        })
                        .map(r => (
                            <AccordionItem key={r.cname} uuid={r.cname}>
                                <SearchResultTitle {...r} />
                                <AccordionItemPanel>
                                    <SearchResults {...r} />
                                    {r.count < r.totalCount ? (
                                        <SearchResultButton
                                            name="Show More"
                                            onClick={() => incCnt(r.cname, r.totalCount)}
                                        />
                                    ) : null}
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                </Accordion>
            )}
        </_ResultsContainer>
    )
}

const doSearchPhrase = async (
    phrase: string,
    radarId: string,
    radarName: string,
    hubName: string
): Promise<Result<SearchResponse>> => {
    const { callFunctions } = getFirebase().functions
    identify(radarName, hubName)
    track("searchbar", "search", { phrase: phrase })
    return callFunctions(actions.search({ radarId, phrase }))
}

type SearchBarStateProps = Loadable<{ radarSlug: string; radarId: string; current?: LocationParams }>
export const SearchBar: React.FC<SearchBarStateProps> = p => {
    if (p.loading) return null
    const [valueRaw, setValueRaw] = React.useState("")
    const [results, setResults] = React.useState<Loadable<{ data: CollectionSearchResultWithLink[] }>>(Loading())
    const [open, setOpen] = React.useState(false)
    const ref = React.useRef(null)

    const apiCall = useDebounce((sp: string, radarName: string, hubName: string) => {
        setResults(Loading())
        return doSearchPhrase(sp, p.radarId, radarName, hubName)
    }, 500)

    React.useEffect(() => {
        const body = document.querySelector("body")
        if (open && body) {
            const handler = ({ target }: Event) =>
                (target as Element).closest(".search") !== ref.current && setOpen(false)
            body.addEventListener("click", handler)
            return () => body.removeEventListener("click", handler)
        }
    }, [open])

    const setValue = async (v: string) => {
        setResults(Loading())
        apiCall.cancel()
        getPerf().startSingleInstanceTrace("SEARCH")
        setValueRaw(v)

        if (isEmpty(v)) {
            setOpen(false)
            return
        } else {
            setOpen(true)
            if (p.current) {
                const resp = await apiCall.run(v, p.current.radarName, p.current.hubName)
                setResults(
                    Loaded({
                        data: isOk(resp) ? resp.value.searchedObjects.map(materializeLinksToObject(p.radarSlug)) : []
                    })
                )
            }
        }

        getPerf().stopSingleInstanceTrace("SEARCH")
    }

    return (
        <_Wrapper ref={ref} className="search">
            <SearchInput
                data-cy="search-input"
                value={valueRaw}
                onFocus={() => setOpen(Boolean(valueRaw))}
                onChange={setValue}
                onClear={() => {
                    setOpen(false)
                    setValueRaw("")
                }}
            />
            <SearchBarResults show={open} results={results} />
        </_Wrapper>
    )
}

const mapState: MapState<SearchBarStateProps> = ({ auth }) => {
    const radars = getUserRadars(auth)
    const current = getCurrentRadar(auth)
    const isDemo = isDemoRadarLocation(auth.params)

    if (!isRadarLocation(auth.params)) return Loading()
    const { radarId, radarSlug } = auth.params.locationParams
    return Loaded({
        radarSlug,
        radarId,
        current: current
            ? isDemo
                ? (current as LocationParams)
                : radars.find(r => r.radarId === current.radarId)
            : undefined
    })
}
export const SearchBarView = connect(mapState)(SearchBar)
