import styled from "styled-components"
import { styleIfProp, Flex, _FlexColumn } from "../../styles/common"
import { _TabContentWrapper } from "../tabs/Tabs.styles"

export const _SelectFilesWrapper = styled(Flex)`
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const _ListPanel = styled.div`
    height: 100%;
    width: 350px;
    border-right: solid 1px #edeff2;
`
export const _PreviewListPanel = styled(_FlexColumn)`
    flex: 1 0 auto;
    overflow: auto;
    width: calc(100% - 350px);

    ${_TabContentWrapper} {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
    }
`

export const _Header = styled(Flex)`
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    border-bottom: solid 1px #edeff2;
    background: #fcfdff;
`

export const _Column = styled(Flex)<{ width?: number }>`
    flex-direction: column;
    ${({ width }) => styleIfProp("width", `width: ${width}%`, `width: 50%`)}
    > * + * {
        margin-top: 20px;
    }
`
export const _ColumnWrapper = styled(Flex)`
    margin-top: 24px;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    & > * + * {
        margin-left: 16px;
    }
`

export const _CenterMiddle = styled(Flex)<{ direction?: "row" | "column" }>`
    height: 100%;
    width: 100%;
    flex-direction: ${({ direction = "row" }) => direction};
    justify-content: center;
    align-items: center;
`
export const _DoneWrapper = styled(Flex)`
    height: 100%;
    width: 100%;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > svg {
        margin: 16px;
    }
    & > * + * {
        margin-top: 8px;
    }
`

export const _ListsWrapper = styled(Flex)`
    > * + * {
        margin-left: 10px;
    }
`
// TODO: Make obvious flexbox layouts global
