import styled, { DefaultTheme } from "styled-components"
import { BaseButton } from "../styles/buttons"
import { styleIfProp, getTextColorForBackground, InlineFlex, Flex, _FlexColumn } from "../styles/common"
import { _TagFont } from "../styles/typography"

type BaseProps = {
    theme: DefaultTheme
    full?: boolean
}
const base = ({ theme, full }: BaseProps) => `
  padding: 0px 13px;
  font-size: 14px;
  border: 1px solid ${theme.colors.theme2};
  ${styleIfProp("full", "min-width: 180px;")({ full })}
  line-height: 1.7;
`

type DropdownProps = {
    open: boolean
    optionsXLocation?: "left" | "right"
    optionsYLocation?: "bottom" | "top"
    full?: boolean
}

export const _DropdownText = styled.span`
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
`

export const _DropdownBadge = styled(InlineFlex)`
    justify-content: center;
    letter-spacing: normal;
    line-height: 1.1;
    background-color: ${p => p.theme.colors.primary};
    color: #fff;
    border-radius: 6px;
    padding: 1px 1px 1px;
    font-size: 11px;
    margin-left: 6px;
`

export const _DropdownOptions = styled(_FlexColumn).attrs(() => ({
    "data-cy": "_DropdownOptions"
}))<DropdownProps & { position: { left: number; top: number } }>`
    ${base}
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 4px;
    position: absolute;
    left: ${p => `${p.position.left}px`};
    top: ${p => `${p.position.top}px`};
    background: #fff;
    max-height: 600px;
    overflow-y: auto;
    z-index: 1002;
`

export const _DropdownOption = styled(Flex).attrs(() => ({
    "data-cy": "_DropdownOption"
}))<{ checkbox?: boolean }>`
    ${styleIfProp("checkbox", "min-width: 220px;")}
    padding: 6px 0;
    align-items: center;
    cursor: pointer;
    flex-shrink: 0;
    &:first-child {
        padding-top: 4px;
    }
    input {
        flex-shrink: 0;
        cursor: pointer;
    }
`

export const _DropdownContainer = styled(Flex)``
export const _DropdownWrapper = styled(Flex).attrs(() => ({
    "data-cy": "_DropdownWrapper"
}))<{ alignToLeft?: boolean }>`
    max-width: 100%;
    position: relative;
    align-items: center;
    ${styleIfProp("alignToLeft", "justify-content: flex-start", "justify-content: center")};
`

export const _DropdownLabel = styled(Flex)<{ background: string }>`
    align-items: center;
    background: ${({ background }) => `${background}`};
    color: ${({ background, theme }) => getTextColorForBackground(background, theme.colors.primary)};
    font-size: 14px;
    border-radius: 12.5px;
`
export const _DropdownSublabel = styled(_DropdownLabel)`
    font-size: 12px;
    margin-top: -4px;
    color: ${({ background, theme }) => getTextColorForBackground(background, theme.colors.secondary)};
`

export const _DropdownSection = styled(_FlexColumn)`
    ${_TagFont} {
        padding: 15px 13px;
        margin: 0 -13px;
        background-color: #fafbfc;
    }
`

export const _IconDropdownIconContainer = styled(Flex)`
    padding: 8px 16px;
`

export const _DropdownTrigger = styled(BaseButton).withConfig<DropdownProps>({
    shouldForwardProp: (p, d) => !["full"].includes(p) && d(p)
})`
    ${base}
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 2;
    cursor: pointer;
    border-radius: 4px;
    outline: none;
    position: relative;
    min-height: 36px;
    :after {
        content: "";
        width: 0;
        height: 0;
        right: 8px;
        position: absolute;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        ${({ open, theme }) =>
            open ? `border-bottom: 5px solid ${theme.colors.theme2};` : `border-top: 5px solid ${theme.colors.theme2};`}
        clear: both;
    }

    ${_DropdownLabel} {
        max-width: 100%;
    }
`
