import styled, { css } from "styled-components"
import { keys } from "../../functions/src/utils/map"
import { range } from "../utils"
import { isEmpty } from "../../functions/src/utils/validators"
import { ThemeColor } from "./styled"

// TODO: clean this file
type MarginProps = {
    top?: string
    left?: string
    right?: string
    bottom?: string
}

export const absoluteCenter = css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
`

const defaultMargin = { top: 0, right: 0, left: 0, bottom: 0 }

export const styleFromProp = (flag: any, style: any, def: any = "") => (flag ? style : def)
export const styleIfProp = <T>(key: keyof T, style: any, def: any = "") => (props: T) => (props[key] ? style : def)

export const getItemWidth = (wide?: boolean, width?: number) => {
    if (wide) return "100%"
    if (width) return `${width}px`
    return "auto"
}

export const stringToRGB = (hex: string): [number, number, number] => {
    let vhex = hex.replace(/[^0-9A-Fa-f]/gi, "")

    if (vhex.length < 6) vhex = vhex[0] + vhex[0] + vhex[1] + vhex[1] + vhex[2] + vhex[2]

    return range(0, 2).map(v => parseInt(vhex.substr(v * 2, 2), 16)) as [number, number, number]
}

export const hexWithAlpha = (hex: string, alpha: number = 0): [number, number, number, number] => {
    let vhex = hex.replace(/[^0-9A-Fa-f]/gi, "")

    if (vhex.length < 6) {
        vhex = vhex[0] + vhex[0] + vhex[1] + vhex[1] + vhex[2] + vhex[2]
    }
    const color = range(0, 3).map(v => parseInt(vhex.substr(v * 2, 2), 16)) as [number, number, number]
    return [...color, alpha] as [number, number, number, number]
}

export const getTextColorForBackground = (hex: string, def?: string) => {
    if (!hex) return def || "#000"
    const rgb = stringToRGB(hex)
    return rgb.reduce((acc, c) => acc + c, 0) > 350 ? "#000000" : "#ffffff"
}

export type FlexProps = {
    noshrink?: boolean
    grow?: number
    align?: "center" | "baseline" | "flex-start" | "flex-end"
    justify?: "center" | "flex-start" | "flex-end" | "space-between" | "space-around"
    direction?: "column" | "row" | "column-reverse" | "row-reverse"
    wrap?: boolean
}
export const Flex = styled.div<FlexProps>`
    display: flex;
    ${styleIfProp("noshrink", "flex-shrink: 0;")};
    ${p => p.align && `align-items: ${p.align};`};
    ${p => p.direction && `flex-direction: ${p.direction};`};
    ${p => p.justify && `justify-content: ${p.justify};`};
    ${p => !isEmpty(p.grow) && `flex-grow: ${p.grow};`};
    ${p => p.wrap && `flex-wrap: wrap;`};
`

export type GridProps = {
    columns: string
    rows?: string
    gap?: string
    rowGap?: string
    columnGap?: string
    grow?: number
    noshrink?: boolean
}
export const Grid = styled.div<GridProps>`
    display: grid;
    grid-template-columns: ${p => p.columns};
    grid-template-rows: ${p => p.rows};
    grid-gap: ${p => p.gap};
    grid-row-gap: ${p => p.rowGap};
    grid-column-gap: ${p => p.columnGap};
    flex-grow: ${p => p.grow};
    ${styleIfProp("noshrink", "flex-shrink: 0;")};
`

export const _FlexContainer = styled(Flex).attrs<FlexProps>(() => ({
    direction: "column"
}))`
    max-width: 100%;
`

export const InlineFlex = styled.span`
    display: inline-flex;
`

export const _Center = styled(Flex)<{ grow?: boolean }>`
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${styleIfProp("grow", "flex-grow: 1;")}
`

export const _Right = styled(Flex)`
    justify-content: flex-end;
`

export const _Left = styled(Flex)`
    width: 100%;
    justify-content: flex-start;
`

export const _ScrolledWrapper = styled(Flex)<{ height?: string }>`
    height: ${p => p.height || "auto"};
    overflow-y: auto;
    flex-direction: column;
`

export const Margin = styled.div<MarginProps>`
    margin: ${p => {
        const { top, left, bottom, right } = { ...defaultMargin, ...p }
        return `${top} ${right} ${bottom} ${left}`
    }};
`

export const FlexInline = styled.div`
    display: flex;
    align-items: stretch;
`

export const BlurredStyle = css`
    filter: blur(5px);
    user-select: none;
    pointer-events: none;
    word-break: break-all;
`

export const _BlurredSpan = styled.span`
    filter: blur(5px);
`

export const _FlexRow = styled(Flex)<{ spaceBetween?: boolean; alignCenter?: boolean }>`
    flex-direction: row;
    ${styleIfProp("spaceBetween", "justify-content: space-between;")}
    ${styleIfProp("alignCenter", "align-items: center;")}
`

export const _FlexColumn = styled(Flex)`
    flex-direction: column;
`

// TODO Replace this with simple Flex
export const _FlexBaseItem = styled.div<{
    direction?: "row" | "column"
    xAlign?: "flex-start" | "flex-end" | "center"
    yAlign?: "flex-start" | "flex-end" | "center"
}>`
    display: flex;
    flex-direction: ${p => styleFromProp(p.direction, p.direction, "row")};
    ${p =>
        styleFromProp(
            p.xAlign,
            (p.direction || "row") === "row" ? `justify-content: ${p.xAlign};` : `align-items: ${p.xAlign};`
        )}
    ${p =>
        styleFromProp(
            p.yAlign,
            (p.direction || "row") === "row" ? `align-items: ${p.yAlign};` : `justify-content: ${p.yAlign};`
        )}
    flex: 1;
`

const breakpoints = {
    xl: 1920, // large desktop
    l: 1440, // small desktop
    m: 980, // tablet
    s: 600 // phone
}

type Breakpoints = typeof breakpoints

export type MediaQuery = { [key in "min" | "max"]: MediaCSS }

export type MediaCSS = Casted<Breakpoints, typeof css>

const mediaFactory = (operator: keyof MediaQuery): MediaCSS =>
    keys(breakpoints).reduce((acc: MediaCSS, label) => {
        acc[label] = (styles: TemplateStringsArray, ...args: any[]) => css`
            @media (${operator}-width: ${breakpoints[label]}px) {
                ${css(styles, ...args)};
            }
        `
        return acc
    }, {} as any) as MediaCSS

export const media: MediaQuery = {
    min: mediaFactory("min"),
    max: mediaFactory("max")
}

export const _VerticalSpace = styled.div<SCasted<Breakpoints, string> & { base: string }>`
    min-width: 1px;
    flex-shrink: 0;
    flex-grow: 0;
    width: 100%;
    ${p => `height: ${p.base};`}
    ${p => `
        ${styleFromProp(p.xl, media.max.xl`height: ${p.xl};`)}
        ${styleFromProp(p.l, media.max.l`height: ${p.l};`)}
        ${styleFromProp(p.m, media.max.m`height: ${p.m};`)}
        ${styleFromProp(p.s, media.max.s`height: ${p.s};`)}
    `}
`

export const _HorizontalSpace = styled.div<SCasted<Breakpoints, string> & { base: string }>`
    display: inline-block;
    min-height: 1px;
    flex-shrink: 0;
    flex-grow: 0;
    ${p => `width: ${p.base};`}
    ${p => `
        ${styleFromProp(p.xl, media.max.xl`width: ${p.xl};`)}
        ${styleFromProp(p.l, media.max.l`width: ${p.l};`)}
        ${styleFromProp(p.m, media.max.m`width: ${p.m};`)}
        ${styleFromProp(p.s, media.max.s`width: ${p.s};`)}
    `}
`

export const _HRLine = styled.div`
    border-bottom: 1px solid #edeff2;
`

export const _Spacer = styled.div`
    flex: 1 1 auto;
    min-height: 1px;
`

// TODO Make AbsoluteLayout component instead of those 2
export const _AbsPosElement = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    max-width: 100%;
`

export const _AbsPosContainer = styled(_FlexContainer).attrs({ grow: 1 })`
    position: relative;
    overflow: auto;
`

type ArrowDirection = "up" | "down" | "left" | "right"
export const _Arrow = styled.div<{ direction: ArrowDirection; color?: ThemeColor }>`
    width: 0;
    height: 0;
    right: 8px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${p => p.theme.colors[p.color || "theme2"]};
    transition: transform 0.15s ease-in-out;
    transform: rotateZ(
        ${p => {
            switch (p.direction) {
                case "down":
                    return "180deg"
                case "left":
                    return "270deg"
                case "right":
                    return "90deg"
            }
            return "0"
        }}
    );
`
