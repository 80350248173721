export const Maybe = <T>(v?: T): Maybe<T> => (v !== undefined && v !== null ? Just(v) : Nothing())
export const Nothing = (): Nothing => ({ type: "Nothing" })
export const Just = <T>(value: T): Just<T> => ({ type: "Just", value })
export const equal = <T>(l: Maybe<T>, r: Maybe<T>) =>
    l.type === r.type && l.type === "Just" && r.type === "Just" && l.value === r.value
export const isJust = <T>(v: any): v is Just<T> => v && v.type === "Just"
export const isNothing = (v: any): v is Nothing => v && v.type === "Nothing"

export const Loading = (): Loading => ({ loading: true })
export const LoadingWithPayload = <T>(v: T): Loading<T> => ({ loading: true, ...v })
export const Loaded = <T>(v: T): Loaded<T> => ({ loading: false, ...v })

export const Fetched = <T>(value: T, fetchMeta?: FetchMeta): AsyncFetched<T> => ({
    type: "Fetched",
    value,
    fetchLevel: fetchMeta?.fetchLevel || "all",
    itemsCount: fetchMeta?.itemsCount,
    filterBounds: fetchMeta?.filterBounds
})
export const NotFetched = (): AsyncNotFetched => ({ type: "NotFetched" })
export const Fetching = <T>(value: T): AsyncFetching<T> => ({ type: "Fetching", value })
export const FetchError = (value: string): AsyncFetchError => ({ type: "FetchError", value })

export const isNotFetched = (v: any): v is AsyncNotFetched => !v || v.type === "NotFetched"
export const isFetching = <T>(v: Async<T> | undefined): v is AsyncFetching<T> => Boolean(v && v?.type === "Fetching")

export const isFinishedFetching = <T>(v: Async<T> | undefined): v is AsyncFetchError | AsyncFetched<T> =>
    Boolean(v && (v.type === "Fetched" || v.type === "FetchError"))

export const isFetched = <T>(v: Async<T> | undefined): v is AsyncFetched<T> => Boolean(v && v.type === "Fetched")
export const isFetchingOrFetched = <T>(v: Async<T> | undefined): v is AsyncFetched<T> | AsyncFetching<T> =>
    Boolean(v && (v.type === "Fetched" || v.type === "Fetching"))
export const isFetchedError = (v: any): v is AsyncFetchError => Boolean(v && v.type === "FetchError")

const getBroaderFetchLevel = (v1: FetchLevel, v2: FetchLevel): FetchLevel => {
    if (v1 === "all" || v2 === "all") return "all"
    if (v1 === "page" || v2 === "page") return "page"
    return "single"
}

export const mergeAsync = <T extends SMap<any>>(prev?: Async<T>, next?: Async<T>): Async<T> => {
    if (!isFetchingOrFetched(prev) && !isFetchingOrFetched(next)) return next || NotFetched()
    if (!isFetchingOrFetched(next) && isFetchingOrFetched(prev)) return prev
    if (isFetchingOrFetched(next) && !isFetchingOrFetched(prev)) return next
    const v1 = prev as AsyncFetched<T>
    const v2 = next as AsyncFetched<T>
    return Fetched(
        { ...v1.value, ...v2.value },
        {
            fetchLevel: getBroaderFetchLevel(v1.fetchLevel, v2.fetchLevel),
            itemsCount: v2.itemsCount,
            filterBounds: v1.filterBounds ?? v2.filterBounds
        }
    )
}

export const mergeAsyncArray = <T extends any[]>(prev?: Async<T>, next?: Async<T>): Async<T> => {
    if (!isFetchingOrFetched(prev) && !isFetchingOrFetched(next)) return next || NotFetched()
    if (!isFetchingOrFetched(next) && isFetchingOrFetched(prev)) return prev
    if (isFetchingOrFetched(next) && !isFetchingOrFetched(prev)) return next
    const v1 = prev as AsyncFetched<T>
    const v2 = next as AsyncFetched<T>
    return Fetched([...v1.value, ...v2.value] as T, {
        fetchLevel: getBroaderFetchLevel(v1.fetchLevel, v2.fetchLevel),
        itemsCount: v2.itemsCount,
        filterBounds: v1.filterBounds ?? v2.filterBounds
    })
}

export const asyncWithDefault = <V extends any>(a: Async<V>, loadingDefault: V, errorDefault?: V): V =>
    isFetched(a)
        ? a.value
        : isFetchedError(a)
        ? errorDefault !== undefined
            ? errorDefault
            : loadingDefault
        : loadingDefault

export const NotStarted = (): AsyncActionNotStarted => ({ type: "NotStarted" })
export const Processing = (progress?: number): AsyncActionProcessing => ({ type: "Processing", progress })
export const Done = <T>(value: T): AsyncActionDone<T> => ({ type: "Done", value })
export const isDone = <T>(v: AsyncAction<T>): v is AsyncActionDone<T> => v.type === "Done"
export const isProcessing = <T>(v: AsyncAction<T>): v is AsyncActionProcessing => v.type === "Processing"
export const isNotStarted = <T>(v: AsyncAction<T>): v is AsyncActionNotStarted => v.type === "NotStarted"

export const toOption = <T>(label: string, value: T, sublabel?: string): ROption<T> => ({
    label,
    value,
    sublabel
})
export const toStringOption = (v: string) => toOption(v, v)
export const toOrderOption = <T>(label: string, value: T, order = -1): OrderOption<T> => ({
    label,
    value,
    order
})
export const toColorOption = <T>(label: string, value: T, background: string): ColorOption<T> => ({
    label,
    value,
    background
})
export const getOptionsValues = <T extends ROptions>(os: T) => os.map(o => o.value)

export const MutationState = <T extends MutationType, V>(type: T, value: V) => ({ type, value })

export const exhaustiveStringTuple = <T extends string>() => <L extends T[]>(
    ...x: L & ([T] extends [L[number]] ? L : [Error, "You are missing ", Exclude<T, L[number]>])
) => x
