import { LOCATION_CHANGE, LocationChangeAction } from "connected-react-router"
import { v4 } from "uuid"
import { isNil } from "lodash/fp"

import { getReducer } from "../../utils/redux"
import { mapObject, extend, extendSkipNulls, keys } from "../../../functions/src/utils/map"
import { collections } from "../../../functions/src/models/schemas"
import { toggleArray } from "../../../functions/src/utils"
import { Nothing, Just, exhaustiveStringTuple } from "../../../functions/src/utils/types"
import { pathToSortingParams, pathToFilterParams } from "./sortingParams"
import { updateURLActionCmd, hideMsgCmd, updateURLCmd } from "./uiCmds"
import { Filters } from "../../../functions/src/models/filtering"
import { UIState, Popup, NotificationMsg, StatefulPopup, SimplePopup } from "../store"
import { LocationState } from "history"
import { Actions } from "./actions"
import { track } from "../../tracking"

export const initialFilters: Required<Filters> = {
    tags: [],
    searchArea: null,
    searchAreas: [],
    stars: null,
    pipelines: [],
    priorities: [],
    date: null,
    collections: [],
    mutationType: [],
    assignments: [],
    segmentTags: [],
    startups: null,
    sectors: null,
    patents: null,
    tech_transfers: null,
    tech_experts: null,
    investors: null,
    research_hubs: null,
    research_papers: null,
    influencers: null,
    patent_holders: null,
    grants: null,
    clinical_trials: null,
    companies: null
}

export const initialState: UIState = {
    visitedPaths: [],
    filters: initialFilters,
    cursor: { type: "none" },
    sorting: {},
    popups: [],
    currentMsg: Nothing(),
    msgQueue: []
}

const isStatefulPopupType = (type: Type<Popup>): type is Type<StatefulPopup> => {
    const popups = exhaustiveStringTuple<Type<StatefulPopup>>()(
        "linkPopup",
        "attachmentInfo",
        "mandatoryComment",
        "pipelineRemoval",
        "hubEdit",
        "selectExportType",
        "byIdsList",
        "deleteConfirmation",
        "prioritizationUpload"
    )
    return popups.includes(type as any)
}

const mkSimplePopup = (type: Type<SimplePopup>): SimplePopup => ({ type })
const mkStatefulPopup = <T extends StatefulPopup>(type: Type<T>, value: Value<T>): T => ({ type, value } as any)
const mkPopup = (type: Type<Popup>, value?: Value<Popup>): Popup =>
    isStatefulPopupType(type) ? mkStatefulPopup(type, value!) : mkSimplePopup(type)

export const reduceVisitedPaths = (state: UIState, action: LocationChangeAction): string[] => {
    const { visitedPaths } = state
    const { pathname } = action.payload.location

    switch (action.payload.action) {
        case "PUSH":
            if (visitedPaths.length > 0 && visitedPaths[visitedPaths.length - 1] === pathname) return visitedPaths
            return [...visitedPaths, pathname]

        case "POP":
            if (visitedPaths.length === 0) return [...visitedPaths, pathname]
            if (visitedPaths.length > 0 && visitedPaths[visitedPaths.length - 1] === pathname)
                return visitedPaths.slice(0, visitedPaths.length - 1)
            break
    }

    return visitedPaths
}

export const reducer = getReducer<UIState, Actions, LocationState>((state, action) => {
    const extFilters = extendSkipNulls(state.filters)
    switch (action.type) {
        case LOCATION_CHANGE: {
            const searchParams = action.payload.location.search
            const filters = pathToFilterParams(searchParams, state.filters)
            const sorting = mapObject(collections, c => pathToSortingParams(searchParams, c))
            const visitedPaths = reduceVisitedPaths(state, action)
            track("navigation", "location-change", {
                dest: action.payload.location.pathname,
                search: action.payload.location.search
            })
            return { filters, sorting, visitedPaths }
        }
        case "toggleFilter":
            return [
                {
                    filters: extFilters({
                        [action.payload.key]: toggleArray(
                            state.filters[action.payload.key] || [],
                            action.payload.filter
                        )
                    })
                },
                updateURLActionCmd()
            ]

        case "updateFilters":
            const changed = keys(action.payload)
            const key = changed[0]
            // Case 1: reset a top-level filter. Only one key is set and its value is null
            // We want to unset only this particular key, not anything deeper
            if (changed.length === 1 && isNil(action.payload[key])) {
                return [{ filters: { ...state.filters, [key]: null } }, updateURLActionCmd({ replace: true })]
            } else return [{ filters: extFilters(action.payload) }, updateURLActionCmd({ replace: true })]

        case "resetFilters":
            return [{ filters: action.payload }, updateURLActionCmd({ replace: true })]

        case "updateParamsAndNavigate":
            return [{ filters: extFilters(action.payload.delta) }, updateURLActionCmd(action.payload.params)]

        case "openPopup":
            return {
                popups: state.popups
                    .filter(p => p.type !== action.payload.key)
                    .concat(mkPopup(action.payload.key, action.payload.value))
            }

        case "closePopup":
            return { popups: state.popups.filter(p => p.type !== action.payload) }

        case "updateSortingKey": {
            const { collectionName, sortingParams } = action.payload
            const extSorting = extend(state.sorting)
            return [{ sorting: extSorting({ [collectionName]: sortingParams }) }, updateURLActionCmd()]
        }

        case "queueNotification": {
            const msg: NotificationMsg = { ...action.payload, id: v4() }
            const msgQueue = [...state.msgQueue, msg]
            return state.currentMsg.type === "Just" ? { msgQueue } : [{ currentMsg: Just(msg) }, hideMsgCmd(msg)]
        }

        case "hideMsg": {
            if (state.msgQueue.length === 0) return { currentMsg: Nothing() }
            const [msg, ...msgQueue] = state.msgQueue
            return [{ msgQueue, currentMsg: Just(msg) }, hideMsgCmd(msg)]
        }

        case "_updateURL":
            return updateURLCmd(state, action.payload.params)

        case "_setCursor":
            return { cursor: action.payload }
    }
})
