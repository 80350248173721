import styled from "styled-components"
import { _FlexRow, _FlexColumn, _AbsPosContainer } from "../../styles/common"

export const ACTION_BAR_HEIGHT = 42

export const _ActionBarContainer = styled.div`
    padding: 0 16px;
    background: #fff;
    height: ${`${ACTION_BAR_HEIGHT + 1}px`};

    ${_FlexRow} {
        align-items: center;
    }
`

export const _ItemsCount = styled.span`
    text-align: right;
    color: #aaa;
    padding: 0 6px;
`

export const _ListContainer = styled(_FlexColumn)`
    flex-shrink: 0;
`

export const _ListTableContainer = styled(_FlexColumn)`
    flex: 1 1 auto;

    & > ${_AbsPosContainer} {
        outline: none;
    }
`
