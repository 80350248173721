import { Cmd } from "redux-loop"
import { paramsToPath } from "./sortingParams"
import { actions as authActions } from "../auth/actions"
import { setPromiseTimeout, usleep } from "../../../functions/src/utils"
import { actions } from "./actions"
import { UIState, NotificationMsg } from "../store"
import { NavigationParams } from "../../utils/router.types"

export const updateURLCmd = (state: UIState, params: NavigationParams = {}) =>
    Cmd.run(() => paramsToPath(state), {
        successActionCreator: (searchParams: string) => authActions.navigate({ ...params, searchParams })
    })

export const updateURLActionCmd = (params?: NavigationParams) =>
    Cmd.run(() => usleep(0), { successActionCreator: () => actions._updateURL(params) })

export const hideMsgCmd = (msg: NotificationMsg) =>
    Cmd.run(() => setPromiseTimeout(() => msg, 4000), { successActionCreator: actions.hideMsg })
