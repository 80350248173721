import * as React from "react"
import { Section } from "./Sections.types"
import { useOnScroll } from "../../styles/hooks"
import { debounce } from "../../utils"

export type UseTooltipControllerData = ReturnType<typeof useTooltipController>

export const useTooltipController = (anchor?: HTMLElement | null) => {
    const [isTooltipVisible, setTooltipVisible] = React.useState(false)
    const tooltipItems = React.useRef<Section[]>([])
    const tooltipPosition = React.useRef({ top: 0, left: 0 })
    const timeoutRef = React.useRef<number>()

    useOnScroll(
        // eslint-disable-next-line react-hooks/exhaustive-deps
        React.useCallback(
            debounce(() => setTooltipVisible(false), 150, { leading: true }),
            []
        ),
        anchor
    )
    const showTooltip = React.useCallback((items: Section[], position: { top: number; left: number }) => {
        tooltipItems.current = items
        tooltipPosition.current = position
        clearTimeout(timeoutRef.current)
        timeoutRef.current = window.setTimeout(() => {
            setTooltipVisible(true)
        }, 800)
    }, [])

    // We want always set it to false, cause focus/focus lost can be done mulitple times in one render
    const hideTooltip = React.useCallback(() => {
        clearTimeout(timeoutRef.current)
        setTooltipVisible(false)
    }, [])

    return {
        tooltipPosition,
        tooltipItems,
        isTooltipVisible,
        showTooltip,
        hideTooltip
    }
}
