import { CloudActionsValidationMap } from "../validators"
import { PublicCloudActions, PublicActionPayload } from "../actionCreators"
import { validate, validString } from "../../utils/validators"

const validateSignInViaEmail = validate<PublicActionPayload<"signInViaEmail">>({
    email: validString,
    hostname: validString
})

export const publicActionsPayloadValidators: CloudActionsValidationMap<PublicCloudActions> = {
    signInViaEmail: [validateSignInViaEmail],
    checkDeliverableEmail: []
}
