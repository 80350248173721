import * as React from "react"

import {
    _TableCell,
    _TableRow,
    TableRowStyleProps,
    _MeatballMenuContainer,
    _TableCellContent,
    _RowScrollingFade
} from "./TableView.styles"

import { Flex } from "../../styles/common"
import { IconDropdown } from "../Dropdown"
import { cbNoPropagation } from "../../utils/html5"
import { HeaderCellView, ValueCellView, CellContext } from "./TableViewCell"
import { Cell, RowEffect, HeaderCell, Row, ContentCell } from "./TableView.types"
import { decoratorCellSizes } from "../../models/collections"
import { TABLE_HEADER_HEIGHT } from "./TableView"
import { mapCellSizes } from "./utils"

type HeaderRowMeta = State<"header">
const HeaderRowMeta = (): HeaderRowMeta => ({ type: "header" })
type ValueRowMeta = State<"value", { id: React.ReactText; index: number }>
const ValueRowMeta = (id: React.ReactText, index: number): ValueRowMeta => ({ type: "value", id, index })

export type RowMeta = HeaderRowMeta | ValueRowMeta
type RowCallback<T = void> = (meta: RowMeta) => T
type RowEventCallback<T = void> = (meta: RowMeta, event: React.SyntheticEvent) => T

export const isHeaderRow = (v: RowMeta): v is HeaderRowMeta => v && v.type === "header"
export const isValueRow = (v: RowMeta): v is ValueRowMeta => v && v.type === "value"

export type RowContext = {
    onClick: RowEventCallback
    onFocus: (meta: RowMeta, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    onFocusLost: RowCallback
    onSelect: RowEventCallback
    isLast: RowCallback<boolean>
    isSelectable: boolean
    rowHeight: number
    isSelected: RowCallback<boolean>
    getRowOptions: RowCallback<ROption<F0>[] | null>
}

type HeaderRowProps<ID extends string> = {
    cells: Cell[]
    style?: React.CSSProperties
    hasOptions?: boolean
    effects?: RowEffect[]
    ctx: RowContext
    cellCtx: CellContext<ID>
} & OmitStrict<TableRowStyleProps, "rowHeight" | "colSizes">
export const HeaderRowView = React.memo(
    <ID extends string>({ hasOptions, cells, ctx, cellCtx, ...p }: HeaderRowProps<ID>) => {
        const meta = HeaderRowMeta()
        const colSizes = React.useMemo(() => {
            const cellSizes = cells.map(c => c.size)
            if (ctx.isSelectable) cellSizes.unshift(decoratorCellSizes.select)
            if (hasOptions) cellSizes.push(decoratorCellSizes.menu)
            return mapCellSizes(cellSizes)
        }, [cells, ctx.isSelectable, hasOptions])

        return (
            <_TableRow
                {...p}
                colSizes={colSizes}
                hasOptions={hasOptions}
                rowHeight={TABLE_HEADER_HEIGHT}
                onClick={e => ctx.onClick(meta, e)}
                onMouseEnter={e => ctx.onFocus(meta, e)}
                onMouseLeave={() => ctx.onFocusLost(meta)}>
                <_RowScrollingFade side="left" pinnedColumns={cellCtx.pinnedColumns} background="#ecf5fd" />
                {ctx.isSelectable && (
                    <_TableCell
                        clickable
                        onClick={cbNoPropagation(e => ctx.onSelect(meta, e))}
                        background="#ecf5fd"
                        className={
                            cellCtx.pinnedColumns.all["select"]?.left
                                ? "pinned-left"
                                : cellCtx.pinnedColumns.all["select"]?.right
                                ? "pinned-right"
                                : ""
                        }
                        pinnedStyles={cellCtx.pinnedColumns.all["select"]}>
                        <_TableCellContent rowHeight={TABLE_HEADER_HEIGHT}>
                            <input type="checkbox" readOnly checked={ctx.isSelected(meta)} />
                        </_TableCellContent>
                    </_TableCell>
                )}
                {cells.map((c: HeaderCell<ID>, index) => (
                    <HeaderCellView
                        effects={p.effects}
                        key={index}
                        {...c}
                        index={index}
                        isLast={index === cells.length - 1}
                        ctx={cellCtx}
                        rowHeight={TABLE_HEADER_HEIGHT}
                    />
                ))}
                {hasOptions && (
                    <_TableCell
                        background="#ecf5fd"
                        pinnedStyles={cellCtx.pinnedColumns.all["menu"]}
                        className={
                            cellCtx.pinnedColumns.all["menu"]?.left
                                ? "pinned-left"
                                : cellCtx.pinnedColumns.all["menu"]?.right
                                ? "pinned-right"
                                : ""
                        }>
                        <_TableCellContent nopadding rowHeight={TABLE_HEADER_HEIGHT}></_TableCellContent>
                    </_TableCell>
                )}
                <_RowScrollingFade side="right" pinnedColumns={cellCtx.pinnedColumns} background="#ecf5fd" />
            </_TableRow>
        )
    }
)

type ValueRowProps<ID extends string> = Row & {
    index: number
    style?: React.CSSProperties
    ctx: RowContext
    cellCtx: CellContext<ID>
} & OmitStrict<TableRowStyleProps, "rowHeight" | "colSizes">
export const ValueRowView = React.memo(<ID extends string>({ id, ctx, cellCtx, style, ...p }: ValueRowProps<ID>) => {
    const meta = ValueRowMeta(id, p.index)
    const isDisabled = p.effects.includes("disabled")
    const isBlurred = p.effects.includes("blurred")
    const isError = p.effects.includes("error")
    const isWarning = p.effects.includes("warning")
    const isHighlighted = p.effects.includes("highlight")
    const topSeparator = p.effects.includes("top-separator")
    const options = ctx.getRowOptions(meta)

    const background = isError ? "#ffe6e6" : isWarning ? "#fff9e6" : isHighlighted ? "#dff9ec" : undefined
    const colSizes = React.useMemo(() => {
        const cellSizes = p.cells.map(c => c.size)
        if (ctx.isSelectable) cellSizes.unshift(decoratorCellSizes.select)
        if (options && !isDisabled) cellSizes.push(decoratorCellSizes.menu)
        return mapCellSizes(cellSizes)
    }, [p.cells, ctx.isSelectable, options, isDisabled])

    return (
        <Flex style={style}>
            <_TableRow
                {...p}
                background={background}
                colSizes={colSizes}
                rowHeight={ctx.rowHeight}
                hasOptions={!!options && !isDisabled}
                blurred={isBlurred}
                clickable={!isDisabled}
                topSeparator={topSeparator}
                onClick={e => !isDisabled && ctx.onClick(meta, e)}
                onMouseEnter={e => ctx.onFocus(meta, e)}
                onMouseLeave={() => ctx.onFocusLost(meta)}>
                <_RowScrollingFade side="left" pinnedColumns={cellCtx.pinnedColumns} background={background} />
                {ctx.isSelectable && (
                    <_TableCell
                        clickable
                        onClick={cbNoPropagation((event: React.MouseEvent) => ctx.onSelect(meta, event))}
                        background={background}
                        className={
                            cellCtx.pinnedColumns.all["select"]?.left
                                ? "pinned-left"
                                : cellCtx.pinnedColumns.all["select"]?.right
                                ? "pinned-right"
                                : ""
                        }
                        pinnedStyles={cellCtx.pinnedColumns.all["select"]}>
                        <_TableCellContent rowHeight={ctx.rowHeight}>
                            <input type="checkbox" readOnly checked={ctx.isSelected(meta)} />
                        </_TableCellContent>
                    </_TableCell>
                )}
                {p.cells.map((c: ContentCell<ID>, index) => (
                    <ValueCellView
                        isLast={index === p.cells.length - 1}
                        effects={p.effects}
                        key={c.id}
                        {...c}
                        index={index}
                        rowId={id}
                        rowHeight={ctx.rowHeight}
                        rowIndex={p.index}
                        clickable={c.clickable}
                        ctx={cellCtx}
                        background={background}
                    />
                ))}
                {options && !isDisabled && (
                    <_TableCell
                        onMouseEnter={() => ctx.onFocusLost(meta)}
                        background={background}
                        pinnedStyles={cellCtx.pinnedColumns.all["menu"]}
                        className={
                            cellCtx.pinnedColumns.all["menu"]?.left
                                ? "pinned-left"
                                : cellCtx.pinnedColumns.all["menu"]?.right
                                ? "pinned-right"
                                : ""
                        }>
                        <_TableCellContent nopadding justify="center" rowHeight={ctx.rowHeight}>
                            <_MeatballMenuContainer size={18}>
                                <IconDropdown
                                    icon={{
                                        name: "more",
                                        width: 18,
                                        height: 18,
                                        color: "secondary",
                                        rotate: 90
                                    }}
                                    data-cy="moreHorizontal"
                                    optionsXLocation="left"
                                    optionsYLocation={ctx.isLast(meta) ? "top" : "bottom"}
                                    full
                                    options={options}
                                    onSelect={({ value }) => value()}
                                />
                            </_MeatballMenuContainer>
                        </_TableCellContent>
                    </_TableCell>
                )}
                <_RowScrollingFade side="right" pinnedColumns={cellCtx.pinnedColumns} background={background} />
            </_TableRow>
        </Flex>
    )
})
