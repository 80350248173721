import React from "react"
import styled from "styled-components"
import { theme } from "../styles/themes"

const AnimatedFog = styled.div`
    &.invisible {
        opacity: 0%;
        transition: opacity 1s;
    }
    &.hidden {
        display: none;
    }
    .right-arrow {
        position: absolute;
        right: 29px;
        top: 0;
        bottom: 0;
        height: 49px;
        margin: auto;
        transform: translateY(-103px);
    }
    .down-arrow {
        position: absolute;
        right: 0;
        left: 0;
        width: 49px;
        margin: auto;
        transform: translateX(-53px);
        bottom: 21px;
    }
`
export type FogState = "visible" | "invisible"
type FogProps = {
    mode: "horizontal" | "vertical"
    state?: FogState
    // All are CSS sizes with unit, and may be used in CSS calc()
    size: string
}

export const Fog: React.FC<FogProps> = ({ mode, state = "visible", size, children }) => {
    let style: any = {
        position: "fixed",
        pointerEvents: "none",
        zIndex: 100
    }
    if (mode === "horizontal")
        style = {
            bottom: 0,
            width: `100vw`,
            height: size,
            background: "linear-gradient(to bottom, #fff0, #ffff)",
            ...style
        }
    else if (mode === "vertical")
        style = {
            right: 0,
            width: size,
            height: `100vh`,
            background: "linear-gradient(to right, #fff0, #ffff)",
            ...style
        }
    return (
        <AnimatedFog style={style} className={`fog-${mode} ${state}`}>
            {children}
        </AnimatedFog>
    )
}

export function configureFog(setDisplayFog: F1<boolean>) {
    const hexmap = document.querySelector(".hex-map")!
    if (!hexmap) return
    const fogOnboarded = window.localStorage.getItem("radar-show-fog")
    const fogShown = fogOnboarded ? JSON.parse(fogOnboarded) : true
    const hpad = theme.sizes.sidebarWidth + 78 // 78 is YAxis width, as set in styles. It's not in theme.
    const vpad = 61 + 67 + 57 // XAxis height plus header bar and controls. Also not in theme
    const oversized = hexmap.clientWidth - hpad > window.innerWidth || hexmap.clientHeight - vpad > window.innerHeight
    setDisplayFog(fogShown && oversized)
}
