import * as React from "react"
import {
    _LoaderContainerOverlay,
    _LoadingInfo,
    _LoaderLabel,
    _LoaderSvg,
    _LoaderContent,
    _LoaderContainer
} from "./Loader.styles"

export const Hexagon: React.FC<{ center?: boolean; small?: boolean }> = props => {
    return (
        <_LoaderSvg data-cy="loader_svg" viewBox="0 0 64 64" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
            <path
                className="track"
                d="M55.2679 29C56.3397 30.8564 56.3397 33.1436 55.2679 35L46.232 50.6506C45.1603 52.507 43.1795 53.6506 41.0359 53.6506L22.9641 53.6506C20.8205 53.6506 18.8397 52.507 17.768 50.6506L8.73205 35C7.66026 33.1436 7.66026 30.8564 8.73205 29L17.768 13.3494C18.8397 11.493 20.8205 10.3494 22.9641 10.3494L41.0359 10.3494C43.1795 10.3494 45.1603 11.493 46.2321 13.3494L55.2679 29Z"
                stroke="#ECF5FD"
                strokeWidth="3"
                vectorEffect="non-scaling-stroke"
            />
            <path
                className="fill"
                d="M55.2679 29C56.3397 30.8564 56.3397 33.1436 55.2679 35L46.232 50.6506C45.1603 52.507 43.1795 53.6506 41.0359 53.6506L22.9641 53.6506C20.8205 53.6506 18.8397 52.507 17.768 50.6506L8.73205 35C7.66026 33.1436 7.66026 30.8564 8.73205 29L17.768 13.3494C18.8397 11.493 20.8205 10.3494 22.9641 10.3494L41.0359 10.3494C43.1795 10.3494 45.1603 11.493 46.2321 13.3494L55.2679 29Z"
                stroke="#8DABC4"
                strokeWidth="3"
                strokeLinecap="round"
                vectorEffect="non-scaling-stroke"
            />
        </_LoaderSvg>
    )
}
export type LoaderProps = {
    loadingText?: string | false
    loadingSubtext?: string | false
    startWithAnimation?: boolean
    show?: boolean
    fill?: boolean
    size?: "small" | "regular"
}
export const Loader: React.FC<LoaderProps> = props => {
    const showDescription = Boolean(props.loadingText || props.loadingSubtext)
    const isHidden = !props.show
    const showText = props.show && showDescription

    const Info = (
        <_LoadingInfo show={props.startWithAnimation ? showText : props.show && showDescription}>
            <_LoaderLabel head>Loading</_LoaderLabel>
            {props.loadingText && <_LoaderLabel>{props.loadingText}</_LoaderLabel>}
            {props.loadingSubtext && <_LoaderLabel>{props.loadingSubtext}</_LoaderLabel>}
        </_LoadingInfo>
    )
    const Overlay = props.fill ? _LoaderContainerOverlay : _LoaderContainer

    return (
        <Overlay hidden={isHidden} show={props.show}>
            <_LoaderContent data-cy="loader-content" show={props.show} small={props.size === "small"}>
                <Hexagon center={!showDescription} small={props.size === "small"} />
                {Info}
            </_LoaderContent>
        </Overlay>
    )
}

Loader.defaultProps = {
    show: true,
    startWithAnimation: true,
    size: "regular"
}
