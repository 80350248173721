import { Cmd } from "redux-loop"
import { getStore } from ".."
import { AsyncState } from "../store"
import { handleAsyncStateUpdate } from "./asyncConnect"
import { stateDataResolversMap } from "./resolvers"

export const refreshData = (p: { key: keyof AsyncState }) =>
    Cmd.run(async () => {
        const { dispatch, getState } = getStore()
        const resolveTask = stateDataResolversMap[p.key](getState())
        handleAsyncStateUpdate(dispatch, p.key, resolveTask)
    })
