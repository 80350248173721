/* eslint-disable max-lines */
import * as React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemPanel,
    AccordionItemHeading,
    AccordionItemButton
} from "react-accessible-accordion"
import { isErr, isOk } from "../../../functions/src/utils/validators"
import { OnIndex } from "./PreloadedCollectionList"
import { iterateMap, keys, mapObject, remap, identity, values, filterObject } from "../../../functions/src/utils/map"
import { Modal } from "../Modal"
import { Join } from "../../utils/reactUtils"
import { _VerticalSpace, _FlexRow, Flex, _HorizontalSpace, _Spacer } from "../../styles/common"
import { _BodyFont, Prominent, _TagFont } from "../../styles/typography"
import { cnames, collections, getCollectionSchema } from "../../../functions/src/models/schemas"
import { DATA_SEPARATOR, classifiersFields, DATA_PRESENTER } from "../../../functions/src/models/shared"
import { ItemClassifiersFixture } from "../../../functions/src/models/collectionFixtures"
import { Loader } from "../common/Loader"
import {
    RECOGNITION_ERROR_MESSAGE,
    SEGMENT_TAG_WRONG_SA_MESSAGE
} from "../../../functions/src/models/relations/relations"
import { RootState, UnrecognizedFilePayload } from "../../store/store"
import { toCSV } from "../../../functions/src/services/httpEndpoint/export/exportUtils"
import { mapToDisplay } from "../../../functions/src/models/common"
import { downloadBlob, getCSVBlob } from "../../services/export"
import { isCollectionItemInvalid } from "../../../functions/src/models/itemValidationUtils"
import { getAvailableCollections, getDisplayNames } from "../../../functions/src/models/collections"
import {
    _FieldsTable,
    _HowToImportDataPageContainer,
    _HowToImportDataContainer,
    _Separator
} from "./ErrorhintModals.styles"
import { _LayoutContainer, SubpageLayout } from "../Page"
import { Header } from "../Header"
import { Button } from "../../styles/buttons"
import { staticPaths } from "../../paths"
import { connect } from "react-redux"
import { getCurrentRadarId } from "../../models/LocationType"
import { isFetched } from "../../../functions/src/utils/types"
import { isRoot } from "../../models/LoginStatus"

export const exportObject = async (name: string, object: SMap<string | number | string[] | undefined | null>) => {
    const file = toCSV(
        remap(object, identity, (_, k) => k),
        [mapToDisplay(object, "")]
    )
    downloadBlob(getCSVBlob(file), name)
}

export const getErrorReports = (key: string, errMsg: string) => {
    if (errMsg.startsWith(RECOGNITION_ERROR_MESSAGE) || errMsg.startsWith(SEGMENT_TAG_WRONG_SA_MESSAGE)) {
        const [errorRoot, ...rest] = errMsg.split(DATA_PRESENTER)
        const singleValues = rest.join(DATA_PRESENTER).split(DATA_SEPARATOR)
        return singleValues.map(v => `${errorRoot}${DATA_PRESENTER}${v}`)
    }
    return [`${errMsg}${DATA_PRESENTER}${key}`]
}
const getValueFromErrorReport = (report: string) => report.split(DATA_PRESENTER).slice(1).join(DATA_PRESENTER)

type ErrorDigest<C extends CName> = { count: number; key: string; value: string; item: OnIndex<CollectionItemVM<C>> }
const errorDigest = <C extends CName>(
    key: string,
    value: string,
    count: number,
    item: OnIndex<CollectionItemVM<C>>
): ErrorDigest<C> => ({
    key,
    value,
    count,
    item
})
export const prepareErrorMap = <C extends CName>(items: OnIndex<CollectionItemVM<C>>[]): SMap<ErrorDigest<C>> =>
    items.reduce<SMap<ErrorDigest<C>>>((acc, item) => {
        if (!isCollectionItemInvalid(item, "import")) return acc
        mapObject(item, (_, itemValue) => {
            if (isErr(itemValue))
                iterateMap(itemValue.value as SMap<string>, (k, v) => {
                    const itemReports = getErrorReports(k, v)
                    itemReports.forEach(
                        report =>
                            (acc[report] = errorDigest(
                                k,
                                getValueFromErrorReport(report),
                                acc[report] ? acc[report].count + 1 : 1,
                                item
                            ))
                    )
                })
        })
        return acc
    }, {})

export const ErrorDigestModal = (p: {
    isOpen: boolean
    onClose: F0
    onAddTag: F1<string>
    onIgnoreTag: F1<string>
    onAddSegmentTag: F2<string, OnIndex<CollectionItemVM<CName>>, Result<string>>
    onIgnoreSegmentTag: F1<string>
    errors: SMap<ErrorDigest<CName>>
    cname: CName
}) => {
    const [loading, setLoading] = React.useState<string | null>(null)
    return (
        <Modal isOpen={p.isOpen} onClose={p.onClose} title={`Errors for ${getCollectionSchema(p.cname).displayName}`}>
            <_BodyFont>
                <_BodyFont as="span" color="theme6" bold>
                    Explanation:
                </_BodyFont>{" "}
                Below are aggregated errors from this object type.
            </_BodyFont>
            <_VerticalSpace base="24px" />
            <Join
                items={keys(p.errors).sort((a, b) => p.errors[b].count - p.errors[a].count)}
                renderJoining={() => <_Separator />}>
                {k =>
                    k === loading ? (
                        <Loader size="small" />
                    ) : (
                        <_FlexRow spaceBetween>
                            <_BodyFont bold style={{ maxWidth: "400px" }}>
                                {k}
                            </_BodyFont>
                            {p.errors[k].key === "tags" && (
                                <_FlexRow>
                                    <Prominent
                                        onClick={() => {
                                            setLoading(k)
                                            p.onAddTag(p.errors[k].value)
                                        }}>
                                        Add tag
                                    </Prominent>
                                    <_HorizontalSpace base="16px" />
                                    <Prominent
                                        onClick={() => {
                                            setLoading(k)
                                            p.onIgnoreTag(p.errors[k].value)
                                        }}>
                                        Ignore tag
                                    </Prominent>
                                </_FlexRow>
                            )}
                            {p.errors[k].key === "segment_tags" && k.startsWith(RECOGNITION_ERROR_MESSAGE) && (
                                <_FlexRow>
                                    <Prominent
                                        data-cy="add-segment-tag"
                                        onClick={() => {
                                            if (isOk(p.onAddSegmentTag(p.errors[k].value, p.errors[k].item)))
                                                setLoading(k)
                                        }}>
                                        Add segment tag
                                    </Prominent>
                                    <_HorizontalSpace base="16px" />
                                    <Prominent
                                        data-cy="ignore-segment-tag"
                                        onClick={() => {
                                            setLoading(k)
                                            p.onIgnoreSegmentTag(p.errors[k].value)
                                        }}>
                                        Ignore segment tag
                                    </Prominent>
                                </_FlexRow>
                            )}
                            <_BodyFont bold color="danger">
                                {p.errors[k].count} {p.errors[k].count > 1 ? "records" : "record"}
                            </_BodyFont>
                        </_FlexRow>
                    )
                }
            </Join>
        </Modal>
    )
}

type CollectionFieldsInfoTableSchema = Record<
    string,
    {
        display: string
        getValue: <C extends CName>(p: {
            fname: keyof RCollection<C> | keyof ItemClassifiersInput
            cname: C
            required: boolean
        }) => string
    }
>

const getFieldTypeDisplay = <C extends CName>(fname: keyof RCollection<C> | keyof ItemClassifiersInput, cname: C) => {
    if ((collections[cname].relations.all as any).includes(fname)) return "relation"
    const fixtureFields = { ...collections[cname].fixture.all, ...ItemClassifiersFixture() } as any
    if (Array.isArray(fixtureFields[fname])) return "list"
    return typeof fixtureFields[fname]
}

const collectionFieldsInfoTableSchema: CollectionFieldsInfoTableSchema = {
    name: {
        display: "Field name",
        getValue: ({ fname }) => fname as string
    },
    type: {
        display: "Field type",
        getValue: ({ fname, cname }) => getFieldTypeDisplay(fname, cname)
    },
    display: {
        display: "Display name",
        getValue: ({ fname, cname }) => getDisplayNames(cname)({ mode: "edit" })[fname]
    },
    required: {
        display: "Required",
        getValue: ({ required }) => (required ? "✓" : "")
    }
}

const getRow = <C extends CName>(cname: C, required: boolean) => (fname: keyof RCollection<C>) => (
    <tr key={`${cname}=${fname}`}>
        {keys(collectionFieldsInfoTableSchema).map(f => (
            <td key={`${cname}-${f}-${fname}`}>
                {collectionFieldsInfoTableSchema[f].getValue({ fname, cname, required })}
            </td>
        ))}
    </tr>
)

// TODO Adjust example files (remove segment_tags when no flag set in admin)
export const HowToImportData = (p: { config: LocationParams; isRoot: boolean }) => {
    const availableCollections = getAvailableCollections(p.config)
    const requiredClassifiers: typeof classifiersFields = ["primary_search_areas"]
    const optionalClassifiers: typeof classifiersFields = classifiersFields.filter(
        c => !requiredClassifiers.includes(c)
    )

    return (
        <_HowToImportDataContainer>
            <_BodyFont>
                <_BodyFont as="span" color="theme6" bold>
                    Hint:
                </_BodyFont>{" "}
                For multiple values in a single field (list and relation types), use comma as a separator. In case one
                of the values already has a comma inside, use double quotes ("") to avoid it being split.
            </_BodyFont>
            <_VerticalSpace base="24px" />

            {availableCollections.length ? (
                <Accordion allowZeroExpanded allowMultipleExpanded>
                    {availableCollections.map(k => (
                        <AccordionItem key={k} uuid={k}>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    <_BodyFont s17 bold>
                                        {collections[k].displayName}
                                    </_BodyFont>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <_FieldsTable>
                                    <thead>
                                        <tr>
                                            {values(collectionFieldsInfoTableSchema).map((v, i) => (
                                                <th key={i}>{v.display}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {[
                                            ...collections[k].fields.required,
                                            ...requiredClassifiers,
                                            ...collections[k].relations.required.filter(v =>
                                                availableCollections.includes(v)
                                            )
                                        ].map(getRow(k, true) as any)}
                                        {[
                                            ...optionalClassifiers,
                                            ...collections[k].fields.optional,
                                            ...collections[k].relations.optional.filter(v =>
                                                availableCollections.includes(v)
                                            )
                                        ].map(getRow(k, false) as any)}
                                    </tbody>
                                </_FieldsTable>
                                <Button
                                    onClick={() =>
                                        exportObject(`${k}.csv`, {
                                            ...getCollectionSchema(k).fixture.required,
                                            ...ItemClassifiersFixture(),
                                            ...getCollectionSchema(k).fixture.optional,
                                            ...filterObject(getCollectionSchema(k).fixture.relation as any, r =>
                                                availableCollections.includes(r as CName)
                                            )
                                        })
                                    }>
                                    Download example file
                                </Button>
                            </AccordionItemPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            ) : (
                <Flex justify="center">
                    <_TagFont>No object types available to import</_TagFont>
                </Flex>
            )}
        </_HowToImportDataContainer>
    )
}

export const HowToModal = (p: { isOpen: boolean; onClose: F0; config: LocationParams; isRoot: boolean }) => (
    <Modal isOpen={p.isOpen} onClose={p.onClose} title="Creating import CSVs">
        {p.isRoot && (
            <>
                <Flex>
                    <_Spacer />
                    <a
                        href={staticPaths["static/howToImportData"].path}
                        target="_blank"
                        rel="noopener noreferrer nofollow">
                        Open in a new tab
                    </a>
                </Flex>
                <_VerticalSpace base="16px" />
            </>
        )}
        <HowToImportData config={p.config} isRoot={p.isRoot} />
    </Modal>
)

export const HowToPageComponent = (p: { config: LocationParams; isRoot: boolean }) => (
    <_LayoutContainer>
        <Header />
        <SubpageLayout>
            <_HowToImportDataPageContainer>
                <HowToImportData config={p.config} isRoot={p.isRoot} />
            </_HowToImportDataPageContainer>
        </SubpageLayout>
    </_LayoutContainer>
)

export const HowToPage = connect(({ auth }: RootState) => {
    const configs = auth.configs
    const radarId = getCurrentRadarId(auth)
    const config = isFetched(configs) && radarId ? configs.value[radarId] : { collections: cnames }
    return { config, isRoot: isRoot(auth.authentication) }
})(HowToPageComponent)

type ReasonModalProps = { isOpen: boolean; onClose: F0; reason: UnrecognizedFilePayload }
export const ReasonModal: React.FC<ReasonModalProps> = ({ isOpen, onClose, reason }) => {
    const ReasonBody = () => {
        switch (reason.type) {
            case "Fields":
                return (
                    <>
                        <_BodyFont s14>Possible missing fields</_BodyFont>
                        <ol>
                            {reason.missingFields.map((f, i) => (
                                <_BodyFont key={i}>{f}</_BodyFont>
                            ))}
                        </ol>
                        <_BodyFont s14>Possible unrecognized fields</_BodyFont>
                        <ol>
                            {reason.unknownFields.map((f, i) => (
                                <_BodyFont key={i}>{f}</_BodyFont>
                            ))}
                        </ol>
                    </>
                )
            case "Msg":
                return <_BodyFont>{reason.msg}</_BodyFont>
        }
    }
    return (
        <Modal isOpen={isOpen} onClose={onClose} title="What's wrong with my file?">
            <_BodyFont>Unfortunately we were unable to match this file to any object type in this app</_BodyFont>
            <ReasonBody />
        </Modal>
    )
}
